import React from 'react';
import { logout } from 'src/scripts/actions/user/logoutActions';
import Navigation from 'src/scripts/components/Navigation';

const loginFailed = () => (
  <div>
    <header>
      <Navigation isLoggedIn={false} />
    </header>
    <div className="container text-center">
      <div className="container-fluid text-center">
        <i className="fa fa-exclamation-triangle fa-5x text-danger" aria-hidden="true">
          {' '}
        </i>
        <div className="login-failed-container">
          <h4>We appear to be having trouble logging you in. If the problem persists, contact IT Support.</h4>
          <h4>
            If you want to log in using another user, click
            <button
              type="link"
              className="btn btn-link login-button btn-lg logout-link"
              ref="loginButton"
              id="home-login-button"
              onClick={logout}
            >
              Log out
            </button>
          </h4>
        </div>
      </div>
    </div>
  </div>
);

export default loginFailed;
