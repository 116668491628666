import _ from 'lodash';
import * as dateFormatter from 'src/scripts/lib/dateFormatter';
import {
  humanizeString,
  getCaptionFileNameFromCaptionsS3Key,
  getContentOrDashes,
  getBoolDisplayValue,
} from 'src/scripts/lib/stringFormatter';
import { formatTimeInMiliseconds } from 'src/scripts/lib/timeFormatter';
import { secondsToHoursMinutesSeconds } from 'src/scripts/lib/cuepointsConverter';
import {
  ORIGINAL_ORIGIN_TYPE,
  FAIR_DEALING_ORIGIN_TYPE,
  THIRD_PARTY_ORIGIN_TYPE,
  TV_ORIGIN_TYPE,
} from 'src/scripts/lib/contentOriginTypes';
import { DASH_FOR_EMPTY_CONTENT, IMAGE_NOT_FOUND_URL } from '../../constants/common';
import { transcodingProfileCodeNameMap } from 'src/scripts/constants/transcodingProfileCodes.js';

function getAutoPublishRegionsDisplayValue(fieldValue) {
  if (!fieldValue || fieldValue.length === 0) return DASH_FOR_EMPTY_CONTENT;
  return fieldValue.map((regionShortname) => regionShortname).join(', ');
}

function setDefaultsToModel(model, params) {
  const modelWithDefaults = JSON.parse(JSON.stringify(model));
  if (!model.video) {
    modelWithDefaults.video = {};
  }
  if (!model.image) {
    modelWithDefaults.image = {};
  }
  if (params.partOfSeries && !model.partOfSeries) {
    modelWithDefaults.partOfSeries = { genre: {} };
  }
  if (params.partOfEpisode && !model.partOfEpisode) {
    modelWithDefaults.partOfEpisode = {};
  }
  if (params.partOfCategory && !model.partOfCategory) {
    modelWithDefaults.partOfCategory = {};
  }
  if (params.partOfSubcategory && !model.partOfSubcategory) {
    modelWithDefaults.partOfSubcategory = {};
  }
  if (model.tags) {
    const tags = _.map(model.tags, 'name');
    modelWithDefaults.tags = tags.join(', ');
  }
  if (params.drm) {
    modelWithDefaults.video.drm = modelWithDefaults.video.drm ? 'Y' : 'N';
  }
  if (params.geoBlocking) {
    modelWithDefaults.geoBlocking = model.geoBlocking ? 'Y' : 'N';
  }
  if (params.preRoll) {
    modelWithDefaults.preRoll = model.preRoll ? 'Y' : 'N';
  }
  if (params.broadcastAirDate && model.broadcastAirDate) {
    modelWithDefaults.broadcastAirDate = dateFormatter.format(model.broadcastAirDate);
  }
  if (model.availability) {
    modelWithDefaults.availability = dateFormatter.format(model.availability);
  }
  if (model.expiry) {
    modelWithDefaults.expiry = dateFormatter.format(model.expiry);
  }

  if (model.isZypeMrssFeedEnabled) {
    modelWithDefaults.isZypeMrssFeedEnabled = getBoolDisplayValue(model.isZypeMrssFeedEnabled);
  }

  if (model.fastChannelLicensingStartDate) {
    modelWithDefaults.fastChannelLicensingStartDate = dateFormatter.format(
      model.fastChannelLicensingStartDate
    );
  }
  if (model.fastChannelLicensingEndDate) {
    modelWithDefaults.fastChannelLicensingEndDate = dateFormatter.format(model.fastChannelLicensingEndDate);
  }

  if (model.video && model.video.cuePoints) {
    modelWithDefaults.video.cuePoints = secondsToHoursMinutesSeconds(model.video.cuePoints)
      .map(({ time }) => time)
      .join(', ');
  }
  if (model.video && model.video.duration) {
    modelWithDefaults.video.duration = formatTimeInMiliseconds(model.video.duration);
  }
  if (model.video) {
    modelWithDefaults.videoStatus = model.video.status;
    modelWithDefaults.videoId = model.video.id;
  }
  if (!model.image) {
    modelWithDefaults.image.url = IMAGE_NOT_FOUND_URL;
  }
  modelWithDefaults.name = model.name;
  modelWithDefaults.state = model.state;
  modelWithDefaults.seasonId = model.seasonId;
  modelWithDefaults.id = model.id;

  return modelWithDefaults;
}

function createMetadata(model, params, resource) {
  const metadata = [];
  if (params.workflow) {
    metadata.push({ label: 'Workflow Status', value: getContentOrDashes(humanizeString(model.state)) });
  }
  if (params.requireReview) {
    metadata.push({ label: 'Require Review', value: getBoolDisplayValue(model.requireReview) });
  }
  metadata.push({ label: 'Name', value: getContentOrDashes(model.name) });
  if (params.displayName) {
    metadata.push({ label: 'Display Name', value: getContentOrDashes(model.displayName) });
  }
  metadata.push({ label: 'Short Description', value: getContentOrDashes(model.description) });
  if (params.broadcastAirDate) {
    metadata.push({ label: 'Broadcast Air Date', value: getContentOrDashes(model.broadcastAirDate) });
  }
  metadata.push({ label: 'Tags', value: getContentOrDashes(model.tags) });
  if (params.fullSlug) {
    metadata.push({ label: 'Full Slug', value: getContentOrDashes(model.fullSlug) });
  }
  if (params.materialKey) {
    metadata.push({ label: 'Material Key', value: getContentOrDashes(model.materialKey) });
  }
  if (params.oztamMediaId) {
    metadata.push({ label: 'Oztam Media Id', value: getContentOrDashes(model.oztamMediaId) });
  }
  metadata.push({ label: 'Video (VMS) Reference ID', value: getContentOrDashes(model.video.referenceId) });
  if (params.contentSeries) {
    metadata.push({ label: 'Content Series', value: getContentOrDashes(model.contentSeries) });
  }
  if (params.episodeIdentifier) {
    metadata.push({ label: 'Episode Identifier', value: getContentOrDashes(model.phoenixEpisodeIdentifier) });
  }
  if (params.partOfSeries && model.partOfSeries) {
    metadata.push({ label: 'Genre', value: getContentOrDashes(model.partOfSeries.genre.name) });
  }
  if (params.partOfCategory) {
    metadata.push({ label: 'Category', value: getContentOrDashes(model.partOfCategory.name) });
  }
  if (params.partOfSubcategory) {
    metadata.push({ label: 'Subcategory', value: getContentOrDashes(model.partOfSubcategory.name) });
  }
  if (params.classificationCode) {
    metadata.push({ label: 'Classification', value: getContentOrDashes(model.classificationCode) });
  }
  if (params.number) {
    metadata.push({ label: 'Episode #', value: getContentOrDashes(model.number) });
  }
  if (params.partOfEpisode && model.partOfEpisode) {
    metadata.push({ label: 'Episode #', value: getContentOrDashes(model.partOfEpisode.number) });
    metadata.push({
      label: 'Episode Relationship',
      value: getContentOrDashes(humanizeString(model.oztamEpisodeRelationship)),
    });
  }
  if (params.producerNotes && resource === 'networkClip') {
    metadata.push({ label: 'Producer Notes', value: getContentOrDashes(model.producerNotes) });
  }

  if (params.autoPublishRegions) {
    metadata.push({
      label: 'Auto Publish to 9News Regions',
      value: getAutoPublishRegionsDisplayValue(model.autoPublishRegions),
    });
  }

  if (params.importSource) {
    metadata.push({ label: 'Import Source', value: getContentOrDashes(model.importSource) });
  }

  if (params.sourceReferenceId) {
    metadata.push({ label: 'Source Reference ID', value: getContentOrDashes(model.sourceReferenceId) });
  }
  return metadata;
}

function createSeoMetadata(model) {
  const seoMetadata = [];
  seoMetadata.push({ label: 'SEO Title', value: getContentOrDashes(model.seoTitle) });
  seoMetadata.push({ label: 'URL Title', value: getContentOrDashes(model.urlTitle) });
  seoMetadata.push({ label: 'Headline', value: getContentOrDashes(model.headline) });
  seoMetadata.push({ label: 'Long Description', value: getContentOrDashes(model.longDescription) });
  return seoMetadata;
}

function createScheduling(model, params) {
  const scheduling = [];
  if (params.geoBlocking) {
    scheduling.push({ label: 'Available only in Australia', value: model.geoBlocking });
  }
  if (model.availability) {
    scheduling.push({ label: 'Publishing Start Date', value: getContentOrDashes(model.availability) });
  }
  if (model.expiry) {
    scheduling.push({ label: 'Publishing End Date', value: getContentOrDashes(model.expiry) });
  }
  return scheduling;
}

function createLicensingDetails(model) {
  const licensingDetails = [];
  licensingDetails.push({
    label: 'MRSS Feed Enabled',
    value: getBoolDisplayValue(model.isZypeMrssFeedEnabled),
  });

  licensingDetails.push({
    label: 'Fast Channel Licensing Start Date',
    value: getContentOrDashes(model.fastChannelLicensingStartDate),
  });
  licensingDetails.push({
    label: 'Fast Channel Licensing End Date',
    value: getContentOrDashes(model.fastChannelLicensingEndDate),
  });
  return licensingDetails;
}

function createContentOrigin(model, params) {
  if (params.contentOrigin) {
    const contentOriginDetails = [];
    switch (model.contentOriginType) {
      case ORIGINAL_ORIGIN_TYPE:
        contentOriginDetails.push({ label: 'Content Origin Type', value: 'Digital Original' });
        break;
      case FAIR_DEALING_ORIGIN_TYPE:
        contentOriginDetails.push({ label: 'Content Origin Type', value: 'Fair Dealing' });
        break;
      case THIRD_PARTY_ORIGIN_TYPE:
        contentOriginDetails.push(
          { label: 'Content Origin Type', value: '3rd Party' },
          { label: '3rd Party Provider', value: model.thirdPartyProvider }
        );
        break;
      case TV_ORIGIN_TYPE:
        contentOriginDetails.push(
          { label: 'Content Origin Type', value: 'TV' },
          { label: 'TV Series', value: model.contentOriginSeason.partOfSeries.name },
          { label: 'Season', value: model.contentOriginSeason.name }
        );
        break;
      default:
        contentOriginDetails.push({
          label: 'Content Origin Type',
          value: getContentOrDashes(model.contentOriginType),
        });
    }
    return contentOriginDetails;
  }
  return null;
}

function createProducerNotes(model) {
  const producerNotes = [];
  producerNotes.push({ label: 'Producer Notes', value: getContentOrDashes(model.producerNotes) });
  return producerNotes;
}

function createVideo(model, params, resource) {
  const videoCloudError = model.video.transcodingFailureMessage
    ? ` : ${model.video.transcodingFailureMessage}`
    : '';
  const videoData = [
    {
      label: 'Brightcove Video Cloud Status',
      value: `${getContentOrDashes(
        model.video.status === 'TRANSCODED' ? 'Complete' : humanizeString(model.video.status)
      )}${videoCloudError}`,
    },
  ];
  if (params.drm) {
    videoData.push({ label: 'DRM', value: model.video.drm });
  }
  videoData.push(
    { label: 'Video Brightcove ID', value: getContentOrDashes(model.video.brightcoveId) },
    {
      label: 'Video Source File',
      value: getContentOrDashes(model.video.videoFileName),
      download: model.video.videoFileName ? true : undefined,
    },
    { label: 'Video Duration', value: getContentOrDashes(model.video.duration) },
    { label: 'Video Cue Points', value: getContentOrDashes(model.video.cuePoints) }
  );
  if (model.transcodingProfile) {
    const profileName = transcodingProfileCodeNameMap[model.transcodingProfile];
    videoData.push({
      label: 'Transcoding Profile',
      value: `${model.transcodingProfile.toUpperCase()} - ${profileName}`,
    });
  }
  if (resource === 'episode' && model.video.videoFileName) {
    videoData.push({
      label: 'Video Preview Enabled',
      value: model.videoPreviewEnabled ? 'Y' : 'N',
    });
  }
  if (params.preRoll) {
    videoData.push({ label: 'Pre-roll', value: model.preRoll });
  }
  if (params.captions) {
    videoData.push({
      label: 'Captions Source File',
      value: getContentOrDashes(getCaptionFileNameFromCaptionsS3Key(model.video.captionsS3Key)),
      downloadCaptions: model.video.captionsS3Key ? true : undefined,
    });
    videoData.push({
      label: 'Captions Enabled',
      value: model.video.captionsIngestEnabled ? 'Y' : 'N',
    });
  }
  if (params.watermarkEnabled) {
    videoData.push({ label: 'Watermark Enabled', value: model.watermarkEnabled ? 'Y' : 'N' });
  }
  return videoData;
}

function getParams(resource) {
  const baseParams = {
    workflow: false,
    requireReview: false,
    drm: false,
    geoBlocking: false,
    contentSeries: false,
    episodeIdentifier: false,
    number: false,
    partOfEpisode: false,
    classificationCode: false,
    partOfSeries: false,
    partOfCategory: false,
    partOfSubcategory: false,
    producerNotes: false,
    source: false,
    preRoll: false,
    displayName: false,
    broadcastAirDate: false,
    fullSlug: false,
    materialKey: false,
    contentOrigin: false,
  };
  switch (resource) {
    case 'clip':
      return {
        ...baseParams,
        ...{
          workflow: true,
          drm: true,
          geoBlocking: true,
          contentSeries: true,
          partOfEpisode: true,
          partOfSeries: true,
          fullSlug: true,
          materialKey: true,
          watermarkEnabled: true,
          producerNotes: true,
        },
      };
    case 'episode':
      return {
        ...baseParams,
        ...{
          workflow: true,
          drm: true,
          geoBlocking: true,
          episodeIdentifier: true,
          number: true,
          classificationCode: true,
          partOfSeries: true,
          displayName: true,
          broadcastAirDate: true,
          fullSlug: true,
          materialKey: true,
          oztamMediaId: true,
          captions: true,
          watermarkEnabled: true,
          producerNotes: true,
        },
      };
    case 'networkClip':
      return {
        ...baseParams,
        ...{
          workflow: true,
          requireReview: true,
          geoBlocking: true,
          classificationCode: true,
          partOfCategory: true,
          partOfSubcategory: true,
          producerNotes: true,
          contentSeries: true,
          fullSlug: true,
          source: true,
          preRoll: true,
          autoPublishRegions: true,
          contentOrigin: true,
          importSource: true,
          sourceReferenceId: true,
        },
      };
    default:
      return null;
  }
}

export function createViewModel(model, resource) {
  const params = getParams(resource);
  const modelWithDefaults = setDefaultsToModel(model, params);

  const viewModel = {
    id: modelWithDefaults.id,
    image: modelWithDefaults.image,
  };

  viewModel.metadata = createMetadata(modelWithDefaults, params, resource);
  viewModel.scheduling = createScheduling(modelWithDefaults, params);
  viewModel.licensingDetails = createLicensingDetails(modelWithDefaults);
  viewModel.contentOrigin = createContentOrigin(modelWithDefaults, params);
  if (resource !== 'networkClip') {
    viewModel.producerNotes = createProducerNotes(modelWithDefaults, params);
  }
  viewModel.video = createVideo(modelWithDefaults, params, resource);
  if (modelWithDefaults.images && modelWithDefaults.images.length) {
    viewModel.images = modelWithDefaults.images;
  }
  if (resource === 'networkClip' || resource === 'clip') {
    viewModel.seoMetadata = createSeoMetadata(modelWithDefaults);
  }
  return viewModel;
}
