import { OPEN_MODAL, CLOSE_MODAL } from 'src/scripts/actions/modalItem';

export const initialState = {
  openedModalId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLOSE_MODAL:
      return { ...state, openedModalId: null };

    case OPEN_MODAL:
      return { ...state, openedModalId: action.id };

    default:
      return state;
  }
}
