/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ItemList from '../ItemList/ItemList';
import ItemListHeader from '../ItemList/ItemListHeader';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import { Modal, Button, Input, Tooltip, OverlayTrigger, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import ToggleButtonInternal from '../ToggleButton';
import ErrorMessageAlert from 'src/scripts/components/ErrorMessageAlert';
import environment from 'config/environment';

const toggleButtonOptions = {
  toggledOnClassName: 'ml-channel-on',
  toggledOffClassName: 'ml-channel-off',
  toggledOnText: 'Turn Channel Off',
  toggledOffText: 'Turn Channel On',
  toggledOnTooltipText: 'Turn MediaLive Channel OFF',
  toggledOffTooltipText: 'Turn MediaLive Channel ON',
  disabledText: 'Working...',
};

const alertToggleButtonOptions = {
  toggledOnClassName: 'ml-channel-on',
  toggledOffClassName: 'ml-channel-off',
  toggledOnText: 'Turn Alerts Off',
  toggledOffText: 'Turn Alerts On',
  toggledOnTooltipText: 'Turn TouchStream Alerts OFF',
  toggledOffTooltipText: 'Turn TouchStream Alerts ON',
  disabledText: 'Unavailable',
};

const initialState = {
  modalOpen: false,
  modalErrorMessage: null,
  modalData: {},
  modalMetadata: {},
  modalReason: '',
};

const statusIconGuide = [
  {
    key: 'state',
    conditions: [
      { value: 'RUNNING', className: 'ml-stream-running' },
      { value: 'STOPPED', className: 'ml-stream-idle' },
      { value: 'CREATING', className: 'ml-stream-creating' },
      { value: 'RECOVERING', className: 'ml-stream-recovering' },
      { value: 'STARTING', className: 'ml-stream-starting' },
      { value: 'STOPPING', className: 'ml-stream-stopping' },
      { value: 'DELETING', className: 'ml-stream-deleting' },
      { value: 'UPDATING', className: 'ml-stream-updating' },
    ],
  },
];

export default class MediaLiveChannelView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  getUnusedChannelStreams = (channel) => {
    const currentStream = channel.currentStream;
    const otherStreams = channel.availableStreams.filter(
      (e) => currentStream && e.name !== currentStream.name
    );

    return otherStreams;
  };

  renderBreadcrumb = () => (
    <ol className={'mi9-breadcrumb breadcrumb'}>
      <li>{'Live Streams'}</li>
    </ol>
  );

  renderName = () => <ItemListHeader label={'Name'} dataField={'name'} />;

  renderPipelinesRunning = () => (
    <ItemListHeader label={'Streams Available'} dataField={'pipelinesRunningCount'} />
  );

  renderState = () => <ItemListHeader label={'Stream Status'} dataField={'state'} />;

  renderStreamStatus = () => <ItemListHeader label={'Stream Status'} dataField={'streamStatus'} />;

  renderLabel = () => <ItemListHeader label={'Stream Label'} dataField={'label'} />;

  renderSwitchInputAction = () => (
    <ItemListHeader
      label={'Switch Input'}
      dataFormat={(channel) => this.renderChannelActionDropdown(channel)}
    />
  );

  renderChannelActionButtonGroup = () => (
    <ItemListHeader label={'Channel Actions'} dataFormat={(channel) => this.renderChannelButtons(channel)} />
  );

  // In future, this should be it's own re-usable modal. I didn't use ModalItem because it's Redux, and trying to steer away from it.
  renderToggleConfirmationModal = () => {
    return (
      <div>
        <Modal show={this.state.modalOpen} backdrop="static">
          <Modal.Header>
            <Modal.Title ref="modalTitle">{this.state.modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body ref="modalBody">
            <p>{this.state.modalData.question}</p>

            <Input
              labelClassName="required"
              id="seo-title-input"
              type="text"
              ref="reason"
              label="Reason"
              maxLength="200"
              placeholder="Please provide a reason for this change"
              onChange={(e) => this.onReasonTextChange(e)}
              value={this.state.modalReason}
            />

            {this.state.modalData.selectOptions && (
              <div>
                <label className="required">{this.state.modalData.selectOptions.question}</label>
                {this.state.modalData.selectOptions.options.map((option) => {
                  return (
                    <div key={option}>
                      <input
                        id={`radio-${option}`}
                        type="radio"
                        name="reason"
                        value={option}
                        checked={this.state.modalMetadata.selectedBug === option}
                        onChange={(e) =>
                          this.setState({
                            modalMetadata: { ...this.state.modalMetadata, selectedBug: e.target.value },
                          })
                        }
                      />
                      <label className="medialive-bug-label" htmlFor={`radio-${option}`}>
                        {option.replace('enabled-medialive-bugs/', '')}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}

            {this.state.modalData.selectOptions && (
              <div>
                <img className="ml-bug-preview" src={this.getBugPreview()} alt="error" />
              </div>
            )}

            {this.state.modalErrorMessage && (
              <ErrorMessageAlert>{this.state.modalErrorMessage}</ErrorMessageAlert>
            )}

            <div className="form__submit-button-bar">
              <Button
                className="form__button cancel"
                type="button"
                ref="cancelButton"
                disabled={this.props.modalButtonLoading}
                onClick={() => this.closeModal()}
              >
                {this.props.modalButtonLoading ? (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  'Cancel'
                )}
              </Button>
              <Button
                className={'form__button ok'}
                type="button"
                ref="okButton"
                bsStyle="btn-warning"
                disabled={this.props.modalButtonLoading}
                onClick={() => this.confirmToggleModal()}
              >
                {this.props.modalButtonLoading ? (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : this.state.modalData.title ? (
                  this.state.modalData.title
                ) : (
                  'OK'
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  getBugPreview() {
    if (!this.state.modalMetadata) return '';

    const selectedBug = this.state.modalMetadata.selectedBug;

    return `${environment.bugPreviewUrlPrefix}${selectedBug}`;
  }

  renderInputSwitchOption(channel, streamOption) {
    return (
      <ModalItem
        title={streamOption.normalisedOption}
        className={'media-live-input-option-link'}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you want to change the ${channel.label} to '${streamOption.normalisedOption}'?`}
            ok={(reason) => this.props.switchStreamTo(streamOption.name, channel.channelId, reason)}
            buttonClass="warning"
            buttonLabel={streamOption.normalisedOption || streamOption.name}
            reasonRequired
          />
        }
      />
    );
  }

  renderStreamCategory = () => {
    return (
      <ButtonToolbar className="stream-filter-buttons">
        <ButtonGroup>
          <Button
            className={this.props.selectedChannelCategoryString === 'TBS' ? 'toggled-button' : ''}
            key={'TBS'}
            onClick={() => this.props.filterStreamsByCategory('TBS')}
          >
            TBS Events
          </Button>
          <Button
            className={this.props.selectedChannelCategoryString === 'OLYMPIC_DX' ? 'toggled-button' : ''}
            key={'OLYMPIC_DX'}
            onClick={() => this.props.filterStreamsByCategory('OLYMPIC_DX')}
          >
            Olympic Events
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  renderStreamFilter = () => {
    return (
      <ButtonToolbar className="stream-filter-buttons">
        <ButtonGroup>
          {this.props.selectedChannelCategoryString &&
            this.props.liveStreamMappings[this.props.selectedChannelCategoryString].map((mapping) => {
              return (
                <Button
                  className={`ml-button-smaller ${
                    mapping === this.props.selectedChannelMappingString ? 'toggled-button-l' : ''
                  }`}
                  key={mapping}
                  onClick={() => this.props.filterStreamsByMapping(mapping)}
                >
                  {mapping}
                </Button>
              );
            })}
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  renderList = (loading) => {
    return (
      <ItemList
        id={'media-live-stream-list'}
        list={{
          pageData: this.props.data.channels,
          totalCount: this.props.data.count,
        }}
        getItems={this.props.listChannels}
        statusIconStylingGuide={statusIconGuide}
        invisible={loading}
      >
        {this.renderLabel()}
        {this.renderName()}
        {this.renderState()}
        {this.renderChannelActionButtonGroup()}
        {this.renderPipelinesRunning()}
        {this.renderStreamStatus()}
        {this.renderSwitchInputAction()}
      </ItemList>
    );
  };

  renderLoadingGif = (loading) => {
    return (
      loading && (
        <div className="loading-spinner-container">
          <img src="https://media.tenor.com/szH2qsISnzMAAAAi/emoji-thinking.gif" alt="loading" />
        </div>
      )
    );
  };

  renderChannelActionDropdown(channel) {
    const unusedStreams = this.getUnusedChannelStreams(channel);

    return (
      <NavDropdownMenu
        component="div"
        buttonClass="btn btn-default media-live-input-stream-btn"
        title={<i className="fa fa-ellipsis-v"></i>}
        disabled={!unusedStreams || !unusedStreams.length}
      >
        {unusedStreams.length &&
          unusedStreams.map((streamOption) => {
            return this.renderInputSwitchOption(channel, streamOption);
          })}
      </NavDropdownMenu>
    );
  }

  renderChannelButtons(channel) {
    const buttonDisabled = !(channel.state === 'RUNNING' || channel.state === 'STOPPED');
    const shouldClickMLButton = false; // off and alerts are off. on and alerts are off.
    const shouldClickAlertsButton = !(channel.state === 'RUNNING');
    const shouldClickYoSpaceButton = !(channel.state === 'RUNNING');
    const shouldClickAddImageButton = !(channel.state === 'RUNNING');

    return (
      <div className={'ml-button-container'}>
        <ToggleButtonInternal
          updateToggle={(buttonState) => this.openConfirmationModalForMediaLive(buttonState, channel)}
          toggled={channel.mediaLiveButtonState}
          icon={'fa fa-power-off'}
          disabled={buttonDisabled}
          fakeDisabled={shouldClickMLButton}
          stateOptions={toggleButtonOptions}
        />

        <OverlayTrigger placement="top" overlay={this.renderToolTip('Add the bug (logo) to stream')}>
          <Button
            id="toggle-check"
            className={`ml-channel-ys-csm${shouldClickAddImageButton ? ' toggle-button-fake-disabled' : ''}`}
            onClick={(event) => this.handleEnableBugButtonClick(event, channel)}
          >
            <i className={'toggle-button-icon-left fa fa-image'}></i>
            Add Logo
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={this.renderToolTip('Remove the bug (logo) from this stream')}
        >
          <Button
            id="toggle-check"
            className={`ml-channel-ys-csm${shouldClickAddImageButton ? ' toggle-button-fake-disabled' : ''}`}
            onClick={(event) => this.handleDisableBugButtonClick(event, channel)}
          >
            <i className={'toggle-button-icon-left fa fa-image'}></i>
            Remove Logo
          </Button>
        </OverlayTrigger>

        <OverlayTrigger placement="top" overlay={this.renderToolTip('Restart the YoSpace Stream CSM')}>
          <Button
            id="toggle-check"
            className={`ml-channel-ys-csm${shouldClickYoSpaceButton ? ' toggle-button-fake-disabled' : ''}`}
            onClick={(event) => this.handleYoSpaceButtonClick(event, channel)}
          >
            <i className={'toggle-button-icon-left fa fa-refresh'}></i>
            Reset CSM
          </Button>
        </OverlayTrigger>

        <ToggleButtonInternal
          updateToggle={(buttonState) => this.openConfirmationModalForTouchStreamAlerts(buttonState, channel)}
          toggled={channel.touchStreamAlertButtonState}
          icon={'fa fa-bell'}
          disabled={!channel.touchStream || !channel.touchStream.length}
          disabled={!channel.touchStream || !channel.touchStream.length}
          fakeDisabled={shouldClickAlertsButton}
          stateOptions={alertToggleButtonOptions}
        />
      </div>
    );
  }

  renderToolTip(text) {
    return (
      <Tooltip id="future-inactive-tv-series-tooltip">
        <strong>{text}</strong>
      </Tooltip>
    );
  }

  handleYoSpaceButtonClick(event, channel) {
    event.preventDefault();

    this.openConfirmationModalForYoSpaceCSM(channel);
  }

  handleEnableBugButtonClick(event, channel) {
    event.preventDefault();

    this.openConfirmationForBugEnabling(channel);
  }

  handleDisableBugButtonClick(event, channel) {
    event.preventDefault();

    this.openConfirmationForBugDisabling(channel);
  }

  // -- Generic Modal --------------------------------------

  /**
   * @param {object} modalData - The data to show in the Modal.
   * @param {object} metadata - metadata to store, to use once the modal closes.
   */
  openToggleConfirmation(modalData, metadata) {
    const modalDataObject = {
      question: modalData.question,
      title: modalData.title,
      selectOptions: modalData.selectOptions,
    };

    this.setState({
      modalMetadata: metadata,
      modalData: modalDataObject,
      modalOpen: true,
    });
  }

  onReasonTextChange(event) {
    this.setState({ modalReason: event.target.value });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  resetModalData() {
    this.setState({ ...initialState });
  }

  confirmToggleModal() {
    const modalContext = this.state.modalMetadata.context;

    switch (modalContext) {
      case 'MediaLivePower':
        this.handleModalCloseForMediaLive();
        break;
      case 'TouchStreamAlerts':
        this.handleModalCloseForTouchStreamAlerts();
        break;
      case 'YoSpaceCSM':
        this.handleModalCloseForYoSpaceCSM();
        break;
      case 'BugLogoEnable':
        this.handleModalCloseForBugEnable();
        break;
      case 'BugLogoDisable':
        this.handleModalCloseForBugDisable();
        break;
      default:
        break;
    }
  }

  // -- MediaLive Modal ------------------------------------

  openConfirmationModalForMediaLive(buttonState, channel) {
    const onOrOffText = buttonState ? 'on' : 'off';
    const modalDataToSet = {
      question: `Are you sure you want to turn this Court Channel ${onOrOffText}?`,
      title: `Turn ${channel.label} channel ${onOrOffText}`,
    };

    const modalMetadataToStore = {
      channelId: channel.channelId,
      buttonState,
      context: 'MediaLivePower',
    };

    this.openToggleConfirmation(modalDataToSet, modalMetadataToStore);
  }

  handleModalCloseForMediaLive() {
    const metadata = this.state.modalMetadata;
    const reason = this.state.modalReason;

    if (reason.length) {
      this.props.turnOnOrOffMediaLiveChannel(metadata.channelId, metadata.buttonState, reason);

      this.setState({ modalErrorMessage: null });
    } else {
      this.setState({ modalErrorMessage: 'Reason is required' });
    }
  }

  openConfirmationForBugEnabling(channel) {
    const modalDataToSet = {
      question: `Are you sure you want to enable the bug (logo) overlay on the stream for Court Channel ${channel.label}?`,
      title: `Enable bug (logo) for ${channel.label} channel`,
      selectOptions: {
        question: 'Which bug would you like to overlay on this stream?',
        options: this.props.mediaLiveAvailableBugs,
      },
    };

    const modalMetadataToStore = {
      channelId: channel.channelId,
      context: 'BugLogoEnable',
      selectedBug: this.props.mediaLiveAvailableBugs[0],
    };

    this.openToggleConfirmation(modalDataToSet, modalMetadataToStore);
  }

  openConfirmationForBugDisabling(channel) {
    const modalDataToSet = {
      question: `Are you sure you want to enable the bug (logo) overlay on the stream for Court Channel ${channel.label}?`,
      title: `Enable bug (logo) for ${channel.label} channel`,
    };

    const modalMetadataToStore = {
      channelId: channel.channelId,
      context: 'BugLogoDisable',
    };

    this.openToggleConfirmation(modalDataToSet, modalMetadataToStore);
  }

  openConfirmationModalForYoSpaceCSM(channel) {
    const modalDataToSet = {
      question: `Are you sure you want to restart then YoSpace CSM for Court Channel ${channel.label}?`,
      title: `Restart CSM for ${channel.label} channel`,
    };

    const modalMetadataToStore = {
      channelId: channel.name,
      context: 'YoSpaceCSM',
    };

    this.openToggleConfirmation(modalDataToSet, modalMetadataToStore);
  }

  handleModalCloseForYoSpaceCSM() {
    const metadata = this.state.modalMetadata;
    const reason = this.state.modalReason;

    if (reason.length) {
      this.props.resetYoSpaceCSM(metadata.channelId, reason);

      this.setState({ modalErrorMessage: null });
    } else {
      this.setState({ modalErrorMessage: 'Reason is required' });
    }
  }

  handleModalCloseForBugEnable() {
    const metadata = this.state.modalMetadata;
    const reason = this.state.modalReason;
    const bugToEnable = metadata.selectedBug;

    if (reason.length) {
      this.props.enableBugForChannel(metadata.channelId, bugToEnable, reason);

      this.setState({ modalErrorMessage: null });
    } else {
      this.setState({ modalErrorMessage: 'Reason is required' });
    }
  }

  handleModalCloseForBugDisable() {
    const metadata = this.state.modalMetadata;
    const reason = this.state.modalReason;

    if (reason.length) {
      this.props.disableBugForChannel(metadata.channelId, reason);

      this.setState({ modalErrorMessage: null });
    } else {
      this.setState({ modalErrorMessage: 'Reason is required' });
    }
  }

  // -- TouchStream Modal ----------------------------------

  // eslint-disable-next-line consistent-return
  openConfirmationModalForTouchStreamAlerts(buttonState, channel) {
    if (!channel.touchStream) {
      return alert(`TouchStream Alerts unavailable. Data: ${JSON.stringify(channel.touchStream)}`);
    }

    const onOrOffText = buttonState ? 'on' : 'off';
    const modalDataToSet = {
      question: `Are you sure you want to turn this Court Channel's TouchStream alerts ${onOrOffText}?`,
      title: `Turn ${channel.label} channel TouchStream alerts ${onOrOffText}`,
    };

    const modalMetadataToStore = {
      streamKeys: channel.touchStream.map((e) => e.key),
      buttonState,
      context: 'TouchStreamAlerts',
    };

    this.openToggleConfirmation(modalDataToSet, modalMetadataToStore);
  }

  handleModalCloseForTouchStreamAlerts() {
    const metadata = this.state.modalMetadata;
    const reason = this.state.modalReason;

    if (reason.length) {
      this.props.turnOnOrOffTouchStreamAlerts(metadata.streamKeys, metadata.buttonState, reason);

      this.setState({ modalErrorMessage: null });
    } else {
      this.setState({ modalErrorMessage: 'Reason is required' });
    }
  }

  // -- Render ---------------------------------------------

  render = () => (
    <div>
      {this.renderBreadcrumb()}
      {this.renderStreamCategory()}
      {this.renderStreamFilter()}
      {this.renderLoadingGif(this.props.loading)}
      {this.renderList(this.props.loading)}
      {this.renderToggleConfirmationModal()}
    </div>
  );
}

MediaLiveChannelView.propTypes = {
  data: PropTypes.object,
  listChannels: PropTypes.func,
  filterStreamsByMapping: PropTypes.func,
  filterStreamsByCategory: PropTypes.func,
  liveStreamMappings: PropTypes.array,
  selectedChannelMappingString: PropTypes.string,
  selectedChannelCategoryString: PropTypes.string,
  switchStreamTo: PropTypes.func,
  turnOnOrOffMediaLiveChannel: PropTypes.func,
  turnOnOrOffTouchStreamAlerts: PropTypes.func,
  resetYoSpaceCSM: PropTypes.func,
  modalButtonLoading: PropTypes.bool,
  enableBugForChannel: PropTypes.bool,
  disableBugForChannel: PropTypes.bool,
  mediaLiveAvailableBugs: PropTypes.array,
  loading: PropTypes.bool,
};
