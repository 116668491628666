import * as adminVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/admin';

import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';

import { closeModal } from 'src/scripts/actions/modalItem';

export const GET_ROLES_SUCCEEDED = 'GET_ROLES_SUCCEEDED';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';
export const GET_ROLE_SUCCEEDED = 'GET_ROLE_SUCCEEDED';
export const GET_ROLE_FAILED = 'GET_ROLE_FAILED';
export const ROLE_UPDATED = 'ROLE_UPDATED';
export const SAVE_ROLE_SUCCEEDED = 'SAVE_ROLE_SUCCEEDED';
export const SAVE_ROLE_FAILED = 'SAVE_ROLE_FAILED';
export const GET_AZURE_ROLES_STARTED = 'GET_AZURE_ROLES_STARTED';
export const GET_AZURE_ROLES_SUCCEEDED = 'GET_AZURE_ROLES_SUCCEEDED';
export const GET_AZURE_ROLES_FAILED = 'GET_AZURE_ROLES_FAILED';
export const GET_ROLE_FORM_INITIAL_DATA_SUCCEEDED = 'GET_ROLE_FORM_INITIAL_DATA_SUCCEEDED';

export function getRoles() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_ROLES_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ROLES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return adminVmsApi.getRoles().then(onSuccess, onError);
  };
}

export function getRoleById(roleId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_ROLE_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ROLE_FAILED,
        error: response.responseJSON.message,
      });
    };
    return adminVmsApi.getRoleById(roleId).then(onSuccess, onError);
  };
}

export function updateRole(role) {
  return {
    type: ROLE_UPDATED,
    role,
  };
}

export function saveRole(payload) {
  return (dispatch) => {
    const saveMethod = payload.id ? adminVmsApi.updateRole : adminVmsApi.createRole;
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_ROLE_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_ROLE_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(payload).then(onSuccess, onError);
  };
}

export function getAzureRoles() {
  return (dispatch) => {
    const onSuccess = (data) => {
      if (data) {
        dispatch({
          type: GET_AZURE_ROLES_SUCCEEDED,
          data,
        });
      } else {
        dispatch({
          type: GET_AZURE_ROLES_FAILED,
          error: 'Azure roles not found',
        });
      }
    };

    const onError = (response) => {
      dispatch({
        type: GET_AZURE_ROLES_FAILED,
        error: response.responseJSON.message,
      });
    };
    dispatch({
      type: GET_AZURE_ROLES_STARTED,
    });
    return adminVmsApi.getAzureRoles().then(onSuccess, onError);
  };
}

export function deleteRoleAndCloseConfirmation(roleId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(getRoles());
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return adminVmsApi.deleteRole(roleId).then(onSuccess, onError);
  };
}

export function getRoleFormInitialData(roleId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: GET_ROLE_FORM_INITIAL_DATA_SUCCEEDED,
      });
    };
    if (roleId) {
      return dispatch(getRoleById(roleId))
        .then(() => {
          return dispatch(getAzureRoles());
        })
        .then(onSuccess);
    }
    return dispatch(getAzureRoles()).then(onSuccess);
  };
}
