import moment from 'moment';
import isValidURL from 'validator/lib/isURL';
import { VALID_DATE_FORMATS } from 'src/scripts/lib/formValidation/constants';

export const required = () => (rule, formState) => {
  const fieldValue = formState[rule.field];
  if (fieldValue !== 0 && !fieldValue) {
    return `${rule.label} is a required field.`;
  }
  return null;
};

export const combinationRequired = (requiredFields) => (rule, formState) => {
  const fieldValue = formState[rule.field];
  if (!fieldValue) {
    for (const requiredField of requiredFields) {
      const requiredFieldValue = formState[requiredField.field];
      if (requiredFieldValue) {
        return `${rule.label} is a required field.`;
      }
    }
  }
  return null;
};

export const isCurrentEventStartDateTimeValid =
  (dateFormatsArray = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    if (formState.programStartTime && !moment(formState.programStartTime, dateFormatsArray, true).isValid()) {
      return 'Current start date and time is in invalid format';
    }
    if (!formState.programEndTime) {
      return null;
    }
    if (!formState.programStartTime && formState.programEndTime) {
      return 'Current event start date and time is required';
    }
    if (formState.programStartTime && formState.programEndTime) {
      if (moment(formState.programStartTime).isSameOrAfter(moment(formState.programEndTime))) {
        return 'Current event start date should not be after current event end date';
      }
    }
    return null;
  };

export const isCurrentEventEndDateTimeValid =
  (dateFormatsArray = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    if (formState.programEndTime && !moment(formState.programEndTime, dateFormatsArray, true).isValid()) {
      return 'Current end date and time is in invalid format';
    }
    if (!formState.programStartTime) {
      return null;
    }
    if (formState.programStartTime && !formState.programEndTime) {
      return 'Current event end date and time is required';
    }

    if (formState.programStartTime && formState.programEndTime) {
      if (moment(formState.programEndTime).isSameOrBefore(moment(formState.programStartTime))) {
        return 'Current event end date should not be before current event start date';
      }
    }
    return null;
  };

export const isUpNextEventStartDateTimeValid =
  (dateFormatsArray = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    const upNextEvent = formState.upNextEvent;
    if (
      upNextEvent.programStartTime &&
      !moment(upNextEvent.programStartTime, dateFormatsArray, true).isValid()
    ) {
      return 'Up next start date and time is in invalid format';
    }
    if (!upNextEvent || !upNextEvent.programEndTime) {
      return null;
    }
    if (!upNextEvent.programStartTime && upNextEvent.programEndTime) {
      return 'Up next event start date and time is required';
    }
    if (!moment(upNextEvent.programStartTime, dateFormatsArray, true).isValid()) {
      return 'Up next start date and time is in invalid format';
    }
    if (upNextEvent.programStartTime && upNextEvent.programEndTime) {
      if (moment(upNextEvent.programStartTime).isSameOrAfter(moment(upNextEvent.programEndTime))) {
        return 'Up next event start date should not be after Up next event end date';
      }
    }
    return null;
  };

export const isUpNextEventEndDateTimeValid =
  (dateFormatsArray = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    const upNextEvent = formState.upNextEvent;
    if (upNextEvent.programEndTime && !moment(upNextEvent.programEndTime, dateFormatsArray, true).isValid()) {
      return 'Up next end date and time is in invalid format';
    }
    if (!upNextEvent || !upNextEvent.programStartTime) {
      return null;
    }
    if (upNextEvent.programStartTime && !upNextEvent.programEndTime) {
      return 'Up next event end date and time is required';
    }
    if (upNextEvent.programStartTime && upNextEvent.programEndTime) {
      if (moment(upNextEvent.programEndTime).isSameOrBefore(moment(upNextEvent.programStartTime))) {
        return 'Up next event end date should not be before Up next event start date';
      }
    }
    return null;
  };

export const isHttpOrHttpsURL = () => (rule, formState) => {
  const fieldValue = formState[rule.field];
  const options = { protocols: ['http', 'https'], require_protocol: true };
  if (!isValidURL(fieldValue, options)) {
    return `${rule.label} must be a valid URL.`;
  }
  return null;
};

export const isHttpsURL = () => (rule, formState) => {
  const fieldValue = formState[rule.field];
  const options = { protocols: ['https'], require_protocol: true };
  if (!isValidURL(fieldValue, options)) {
    return `${rule.label} must be a valid HTTPS URL.`;
  }
  return null;
};

export const stringEndsWith = (string) => (rule, formState) => {
  if (formState[rule.field].endsWith(string)) return null;
  return `${rule.label} must end with ${string}.`;
};

export const stringEndsWithAnySpecifiedString = (stringArray) => (rule, formState) => {
  const endsInASpecifiedString =
    stringArray.filter((string) => formState[rule.field].endsWith(string)).length > 0;

  return endsInASpecifiedString
    ? null
    : `${rule.label} must end with one of the specified values: (${stringArray.join(', ')}).`;
};

export const stringHasNoSpaces = () => (rule, formState) => {
  if (!formState[rule.field].includes(' ')) return null;
  return `${rule.label} must not contain any spaces.`;
};

const isValidNonRequiredField = (fieldName, formState) =>
  !formState.hasOwnProperty(fieldName) || (formState[fieldName] !== 0 && !formState[fieldName]);

const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);

export const isPositiveNumber = () => (rule, formState) => {
  const value = formState[rule.field];
  if (isNumeric(value) && value > 0) return null;
  return `${rule.label} must be a positive number.`;
};

export const isBetween = (lowerBound, upperBound) => (rule, formState) => {
  const value = formState[rule.field];
  if (isNumeric(value) && lowerBound < value && value < upperBound) return null;
  return `${rule.label} must be within ${lowerBound} and ${upperBound}.`;
};

export const isFrom = (lowerBound, upperBound) => (rule, formState) => {
  const value = formState[rule.field];
  if (isNumeric(value) && lowerBound <= value && value <= upperBound) return null;
  return `${rule.label} must be from ${lowerBound} - ${upperBound}.`;
};

export const dateFormat =
  (dateFormatsArray = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    if (isValidNonRequiredField(rule.field, formState)) return null;
    if (moment(formState[rule.field], dateFormatsArray, true).isValid()) return null;
    return `${rule.label} is not a valid date format.`;
  };

export const notBefore =
  (comparison, notRequiredFields = [], validDateFormatsArr = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    for (let i = 0; i < notRequiredFields.length; i++) {
      if (isValidNonRequiredField(notRequiredFields[i], formState)) return null;
    }
    const momentSelectedDate = moment(formState[rule.field], validDateFormatsArr);
    const momentComparisonDate = moment(formState[comparison.field], validDateFormatsArr);
    if (!momentSelectedDate.isSameOrAfter(momentComparisonDate)) {
      return `${rule.label} cannot be before ${comparison.label}.`;
    }
    return null;
  };

export const notBeforeMoment =
  (comparison, notRequiredFields = [], validDateFormatsArr = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    for (let i = 0; i < notRequiredFields.length; i++) {
      if (isValidNonRequiredField(notRequiredFields[i], formState)) return null;
    }
    const momentSelectedDate = moment(formState[rule.field], validDateFormatsArr);
    const momentComparisonDate = comparison.momentDate;
    if (!momentSelectedDate.isSameOrAfter(momentComparisonDate)) {
      return `${rule.label} cannot be before ${comparison.label}.`;
    }
    return null;
  };

export const notBeforeToday = (nonRequiredFields = []) =>
  notBeforeMoment({ momentDate: moment().startOf('day'), label: 'Current Date' }, nonRequiredFields);

export const notBeforeFiveMinutesFromNow = () => {
  const currentTime = moment().startOf('minute');
  const fiveMinutesFromNow = currentTime.add(5, 'minutes');

  return notBeforeMoment({ momentDate: fiveMinutesFromNow, label: 'Five Minutes from Now' });
};

export const isAfter =
  (comparison, notRequiredFields = [], validDateFormatsArr = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    for (let i = 0; i < notRequiredFields.length; i++) {
      if (isValidNonRequiredField(notRequiredFields[i], formState)) return null;
    }
    const momentSelectedDate = moment(formState[rule.field], validDateFormatsArr);
    const momentComparisonDate = moment(formState[comparison.field], validDateFormatsArr);
    if (!momentSelectedDate.isAfter(momentComparisonDate)) {
      return `${rule.label} must be after ${comparison.label}.`;
    }
    return null;
  };

export const notAfter =
  (comparison, notRequiredFields = [], validDateFormatsArr = VALID_DATE_FORMATS) =>
  (rule, formState) => {
    for (let i = 0; i < notRequiredFields.length; i++) {
      if (isValidNonRequiredField(notRequiredFields[i], formState)) return null;
    }
    const momentSelectedDate = moment(formState[rule.field], validDateFormatsArr);
    const momentComparisonDate = moment(formState[comparison.field], validDateFormatsArr);
    if (!momentSelectedDate.isSameOrBefore(momentComparisonDate)) {
      return `${rule.label} cannot be after ${comparison.label}.`;
    }
    return null;
  };

export const forbiddenValues = (forbiddenValuesArray) => (rule, formState) => {
  const currentFieldValue = formState[rule.field];
  for (let i = 0; i < forbiddenValuesArray.length; i++) {
    if (currentFieldValue === forbiddenValuesArray[i]) {
      return `${rule.label} cannot be ${forbiddenValuesArray[i]}.`;
    }
  }
  return null;
};

const hasOnlySpaces = (str) => str.split('').every((char) => char === ' ');

export const atLeastOneNonSpace = () => (rule, formState) => {
  const currentFieldValue = formState[rule.field];
  if (hasOnlySpaces(currentFieldValue)) {
    return `${rule.label} should contain at least one non-space character.`;
  }
  return null;
};

export const notEmptyArray = () => (rule, formState) => {
  const currentFieldValue = formState[rule.field];
  if (currentFieldValue instanceof Array && currentFieldValue.length === 0) {
    return `${rule.label} should contain at least one value.`;
  }
  return null;
};

export const mustMatchRegex = (regex) => (rule, formState) => {
  const currentFieldValue = formState[rule.field];
  if (!currentFieldValue) return null;
  if (!(currentFieldValue instanceof String || typeof currentFieldValue === 'string')) {
    return `${rule.label} should be a string.`;
  }
  if (regex.test(currentFieldValue)) return null;
  return rule.customErrorMessage || `${rule.label} does not satisfy the validation conditions.`;
};

export const isEmptyOrIsValidUrl = () => (rule, formState) => {
  const currentFieldValue = formState[rule.field];
  if (!currentFieldValue) return null;
  if (!(currentFieldValue instanceof String || typeof currentFieldValue === 'string')) {
    return `${rule.label} should be a string.`;
  }
  try {
    const url = new URL(currentFieldValue);
    if (!['http:', 'https:'].includes(url.protocol)) {
      return rule.customErrorMessage || `${rule.label} is not a valid URL.`;
    }
    return null;
  } catch (error) {
    return rule.customErrorMessage || `${rule.label} is not a valid URL.`;
  }
};
