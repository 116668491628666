import _ from 'lodash';
import moment from 'moment';
import { DASH_FOR_EMPTY_CONTENT } from '../constants/common';

function getLastCharIfNotAlphaNumeric(unformattedString) {
  const regExp = /^[A-Za-z0-9]+$/;
  const lastChar = unformattedString.charAt(unformattedString.length - 1);
  return lastChar.match(regExp) ? null : lastChar;
}

export function humanizeString(unformattedString) {
  if (!unformattedString) return '';
  const fomattedString = _.startCase(unformattedString.toLowerCase());
  const stringSufix = getLastCharIfNotAlphaNumeric(unformattedString);
  return stringSufix ? fomattedString.concat(stringSufix) : fomattedString;
}

export function getContentOrDashes(content) {
  return content || DASH_FOR_EMPTY_CONTENT;
}

export function getBoolDisplayValue(fieldValue) {
  return fieldValue ? 'Y' : 'N';
}

export function getCaptionFileNameFromCaptionsS3Key(captionsS3Key) {
  if (captionsS3Key && typeof captionsS3Key === 'string') {
    const captionFileName = `${_.last(captionsS3Key.split('/'))}`;
    return captionFileName;
  }
  return null;
}

function getClipPropertyToCopy(clipItem, propertyString) {
  if (propertyString === 'availability' || propertyString === 'expiry') {
    return _.get(clipItem, propertyString)
      ? moment(_.get(clipItem, propertyString)).format('lll')
      : 'Not available';
  }
  return _.get(clipItem, propertyString) || 'Not available';
}

export function formatClipPropertiesToCopy(clipItem) {
  const name = getClipPropertyToCopy(clipItem, 'name');
  const description = getClipPropertyToCopy(clipItem, 'description');
  const brightcoveId = getClipPropertyToCopy(clipItem, 'video.brightcoveId');
  const referenceId = getClipPropertyToCopy(clipItem, 'video.referenceId');
  const startDate = getClipPropertyToCopy(clipItem, 'availability');
  const endDate = getClipPropertyToCopy(clipItem, 'expiry');
  return `Name: ${name}\nDescription: ${description}\nBrightcove ID: ${brightcoveId}\nVMS Reference ID: ${referenceId}\nStart Date: ${startDate}\nEnd Date: ${endDate}`;
}

export const formatStringToRemoveUnderscore = (string) => {
  return string.split('_').join(' ');
};
