export const REGISTER_VIDEO_PLAYER = 'REGISTER_VIDEO_PLAYER';
export const UPDATE_VIDEO_DURATION = 'UPDATE_VIDEO_DURATION';

export function registerVideoPlayer(videoPlayer) {
  return {
    type: REGISTER_VIDEO_PLAYER,
    player: videoPlayer,
  };
}

export function updateVideoDuration(videoDuration) {
  return {
    type: UPDATE_VIDEO_DURATION,
    duration: videoDuration,
  };
}
