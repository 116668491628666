import * as requestHandler from './requestHandler';

const PREFIX = 'api/v1/libraries/tv/zype';

export function getChannels() {
  return requestHandler.send('GET', `${PREFIX}/channels`);
}

export function getChannelDetails(channelId) {
  return requestHandler.send('GET', `${PREFIX}/channels/${channelId}`);
}
