import PropTypes from 'prop-types';
import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

class ToggleButtonInternal extends React.Component {
  handleButtonClick(event) {
    event.preventDefault();

    this.toggleState();
  }

  toggleState() {
    this.props.updateToggle(!this.props.toggled);
  }

  renderButton() {
    const iconClassNames = `toggle-button-icon-${this.props.iconPosition === 'right' ? 'right' : 'left'} ${
      this.props.icon
    }`;
    const stateText = this.props.disabled
      ? this.props.stateOptions.disabledText
      : this.props.toggled
        ? this.props.stateOptions.toggledOnText
        : this.props.stateOptions.toggledOffText;

    let stateClass = this.props.toggled
      ? this.props.stateOptions.toggledOnClassName
      : this.props.stateOptions.toggledOffClassName;

    if (this.props.fakeDisabled) {
      stateClass += ' toggle-button-fake-disabled';
    }

    return (
      <Button
        id="toggle-check"
        className={stateClass}
        disabled={this.props.disabled}
        onClick={(event) => this.handleButtonClick(event)}
      >
        {this.props.icon && (!this.props.iconPosition || this.props.iconPosition === 'left') && (
          <i className={iconClassNames}></i>
        )}
        {stateText}
        {this.props.icon && this.props.iconPosition === 'right' && <i className={iconClassNames}></i>}
      </Button>
    );
  }

  renderButtonWithTooltip() {
    return (
      <OverlayTrigger placement="top" overlay={this.renderToolTip()}>
        {this.renderButton()}
      </OverlayTrigger>
    );
  }

  renderToolTip() {
    return (
      <Tooltip id="future-inactive-tv-series-tooltip">
        <strong>
          {this.props.toggled
            ? this.props.stateOptions.toggledOnTooltipText
            : this.props.stateOptions.toggledOffTooltipText}
        </strong>
      </Tooltip>
    );
  }

  render() {
    const hasToolTip = !!(
      !!this.props.stateOptions.toggledOnTooltipText || !!this.props.stateOptions.toggledOffTooltipText
    );

    if (hasToolTip) {
      return this.renderButtonWithTooltip();
    } else {
      return this.renderButton();
    }
  }
}

ToggleButtonInternal.propTypes = {
  updateToggle: PropTypes.func,
  toggled: PropTypes.bool,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  stateOptions: PropTypes.object,
  disabled: PropTypes.bool,
  fakeDisabled: PropTypes.bool,
};

export default ToggleButtonInternal;
