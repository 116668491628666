import environment from 'config/environment';
import { getCachedToken } from '../../actions/apiWrappers/authService';

let socket;

export function connect() {
  if (!socket) {
    const accessToken = getCachedToken();
    if (!accessToken) {
      return;
    }

    try {
      socket = new WebSocket(
        `${environment.websocket.endpoint}?channel=${
          environment.websocket.channel
        }&access_token=${getCachedToken()}`
      );
    } catch (e) {
      /* eslint-disable no-console */
      console.error(e.message);
    }
  }
}

export function disconnect() {
  if (socket) {
    socket.close();
    socket = null;
  }
}

export function subscribe(action) {
  if (socket) {
    socket.onmessage = function (event) {
      try {
        const data = JSON.parse(event.data);
        action(data);
      } catch (e) {
        console.error(e.message);
      }
    };

    socket.onerror = function (event) {
      console.error('Websocket error:', event);
    };

    socket.onclose = function (event) {
      if (event.code !== 1000) {
        console.error(`websocket is closed due to an error(${event.code}). Please refresh the page.`);
      }
    };
  }
}
