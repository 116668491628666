import PropTypes from 'prop-types';
import React from 'react';
import Cropper from 'cropperjs';
import { Modal, Button } from 'react-bootstrap';
import InputRange from 'react-input-range';
import { ASPECT_RATIO_THRESHOLD, IMAGE_MIN_WIDTH, IMAGE_MIN_HEIGHT } from 'src/scripts/lib/keyArtFileUtility';
let croppedData;
let cropImage;

export class ImageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalClosed: true,
      showImageEditingModal: true,
      cropInProgress: false,
      brightnessValue: 0,
      hueValue: 0,
      contrastValue: 0,
      saturationValue: 0,
      vibranceValue: 0,
      exposureValue: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.previewUrl === this.props.previewUrl && !nextProps.imageSrc) {
      this.setState({
        showImageEditingModal: false,
        isModalClosed: true,
      });
    }
    if (this.props.imageFile) {
      if (nextProps.imageFile.lastModified !== this.props.imageFile.lastModified) {
        this.setState({
          showImageEditingModal: true,
          isModalClosed: true,
        });
      }
    }
  }

  onEdit = () => {
    this.setState({
      showImageEditingModal: true,
    });
  };

  onCrop = () => {
    const image = document.getElementById('edit-image');
    this.setState({
      cropInProgress: true,
    });
    cropImage = new Cropper(image, {
      preview: '.preview',
      aspectRatio: ASPECT_RATIO_THRESHOLD,
      viewMode: 1,
      background: false,
      zoomOnWheel: true,
      cropBoxMovable: true,
      cropBoxResizable: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      crop(event) {
        croppedData = event.detail;
      },
    });
  };

  onCropDone = () => {
    this.setState({
      cropInProgress: false,
    });
    cropImage.destroy();
    window.Caman('#edit-image', function () {
      this.crop(croppedData.width, croppedData.height, croppedData.x, croppedData.y).render();
    });
  };

  onBack = () => {
    this.setState({
      cropInProgress: false,
    });
    croppedData = null;
    cropImage.destroy();
  };

  onUndo = () => {
    this.setState({
      brightnessValue: 0,
      hueValue: 0,
      contrastValue: 0,
      saturationValue: 0,
      vibranceValue: 0,
      exposureValue: 0,
    });
    window.Caman('#edit-image', function () {
      this.reset();
    });
  };

  onSave = () => {
    this.setState({
      isModalClosed: true,
    });
    const canvas = document.getElementById('edit-image');
    if (canvas instanceof HTMLImageElement) {
      this.props.imageUpload(this.props.imageFile);
      return;
    }
    if (canvas.width < IMAGE_MIN_WIDTH || canvas.height < IMAGE_MIN_HEIGHT) {
      this.resizeImageAndUpload(canvas);
      return;
    }
    canvas.toBlob(
      (blob) => {
        const file = new File([blob], 'pickerImage.jpg', { type: 'image/jpeg' });
        this.props.imageUpload(file);
      },
      'image/jpeg',
      1
    );
  };

  resizeImageAndUpload = (canvas) => {
    const imagSourceFromCanvas = canvas.toDataURL('image/jpeg');
    const image = new Image();
    image.src = imagSourceFromCanvas;
    image.onload = () => {
      const canvasElement = document.createElement('canvas');
      canvasElement.width = IMAGE_MIN_WIDTH;
      canvasElement.height = IMAGE_MIN_HEIGHT;
      const ctx = canvasElement.getContext('2d');
      ctx.drawImage(image, 0, 0, IMAGE_MIN_WIDTH, IMAGE_MIN_HEIGHT);
      ctx.canvas.toBlob(
        (blob) => {
          const file = new File([blob], 'pickerImage.jpg', { type: 'image/jpeg' });
          this.props.imageUpload(file);
        },
        'image/jpeg',
        1
      );
    };
  };
  updateImage = () => {
    const { brightnessValue, hueValue, contrastValue, saturationValue, vibranceValue, exposureValue } =
      this.state;
    window.Caman('#edit-image', function () {
      this.revert(false);
      if (hueValue) {
        this.hue(hueValue);
      }
      if (contrastValue) {
        this.contrast(contrastValue);
      }
      if (brightnessValue) {
        this.brightness(brightnessValue);
      }
      if (exposureValue) {
        this.exposure(exposureValue);
      }
      if (saturationValue) {
        this.saturation(saturationValue);
      }
      if (vibranceValue) {
        this.vibrance(vibranceValue);
      }
      this.render();
    });
  };

  handleClose = () => {
    this.setState({
      isModalClosed: false,
      cropInProgress: false,
      showImageEditingModal: false,
      brightnessValue: 0,
      hueValue: 0,
      contrastValue: 0,
      saturationValue: 0,
      vibranceValue: 0,
      exposureValue: 0,
    });
  };

  renderImageEditingOptions = () => {
    return (
      <div className="input">
        <div className="image-edit-options">
          <label className="image-edit-label">CONTRAST</label>
          <InputRange
            className="image-contrast"
            maxValue={100}
            minValue={-100}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.contrastValue}
            onChange={(event) => this.setState({ contrastValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
        <div className="image-edit-options">
          <label className="image-edit-label">BRIGHTNESS</label>
          <InputRange
            className="image-brightness"
            maxValue={100}
            minValue={-100}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.brightnessValue}
            onChange={(event) => this.setState({ brightnessValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
        <div className="image-edit-options">
          <label className="image-edit-label">VIBRANCE</label>
          <InputRange
            className="image-vibrance"
            maxValue={100}
            minValue={-100}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.vibranceValue}
            onChange={(event) => this.setState({ vibranceValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
        <div className="image-edit-options">
          <label className="image-edit-label">SATURATION</label>
          <InputRange
            className="image-saturation"
            maxValue={100}
            minValue={-100}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.saturationValue}
            onChange={(event) => this.setState({ saturationValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
        <div className="image-edit-options">
          <label className="image-edit-label">EXPOSURE</label>
          <InputRange
            className="image-exposure"
            maxValue={100}
            minValue={-100}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.exposureValue}
            onChange={(event) => this.setState({ exposureValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
        <div className="image-edit-options">
          <label className="image-edit-label">HUE</label>
          <InputRange
            className="image-hue"
            maxValue={100}
            minValue={0}
            step={1}
            disabled={this.state.cropInProgress}
            value={this.state.hueValue}
            onChange={(event) => this.setState({ hueValue: event })}
            onChangeComplete={(event) => this.updateImage(event)}
          />
        </div>
      </div>
    );
  };

  renderButtonGroup = () => (
    <div>
      {this.state.cropInProgress && (
        <div>
          <Button className="btn-xs back" onClick={this.onBack}>
            {<i className="fa fa-arrow-left"></i>}
          </Button>
          <Button className="btn-xs crop" onClick={this.onCropDone}>
            <i className="fa fa-check"></i>
          </Button>
        </div>
      )}
      {!this.state.cropInProgress && (
        <div>
          <Button className="btn-xs crop-image" onClick={this.onCrop}>
            {<i className="fa fa-crop"></i>}
          </Button>
          <Button className="btn-xs undo" onClick={this.onUndo}>
            {<i className="fa fa-undo"></i>}
          </Button>
        </div>
      )}
    </div>
  );

  renderImageEditingModal = () => (
    <div className="image-editor">
      <Modal
        className={this.state.cropInProgress ? 'image-editor-crop' : 'image-editor'}
        show
        onHide={this.handleClose}
        backdrop="static"
      >
        <Modal.Header className="image-editor-header" closeButton></Modal.Header>
        <Modal.Body ref="modalBody">
          {this.renderButtonGroup()}
          <div className="image-edit-preview">
            {!this.state.cropInProgress && this.renderImageEditingOptions()}
            <div className="image-preview">
              <img
                id="edit-image"
                className="image-edit"
                src={
                  this.props.imageSrc && this.state.isModalClosed
                    ? this.props.imageSrc
                    : this.props.previewUrl
                }
              />
            </div>
          </div>
          {this.state.cropInProgress && <div className="preview"></div>}
          {!this.state.cropInProgress && (
            <div>
              <Button className="btn-sm save" onClick={this.onSave}>
                SAVE IMAGE
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );

  render() {
    return (
      <div>
        {this.props.previewUrl && (
          <Button className="btn-xs edit-image" onClick={this.onEdit}>
            Edit Image
          </Button>
        )}
        {this.state.showImageEditingModal && this.renderImageEditingModal()}
      </div>
    );
  }
}

ImageEditor.propTypes = {
  previewUrl: PropTypes.string,
  imageSrc: PropTypes.string,
  imageFile: PropTypes.object,
  imageUpload: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ImageEditor;
