export const TV_SERIES_CONTENT_FLAGS = [
  '9Now exclusive',
  'Complete season',
  'Complete seasons',
  'Every episode ever',
  'Every episode',
  'Exclusive',
  'Just added',
  'Last chance',
  'Latest episodes',
  'Live',
  'New',
  'New episodes',
  'New episode',
  'New episodes weekly',
  'New season',
  'New season added',
  'New series',
  'New to 9Now',
  'Recently added',
  'Season final',
  'Season finale',
  'Series premiere',
];

export const SEASON_CONTENT_FLAGS = [
  '9Now exclusive',
  'Complete season',
  'Every episode ever',
  'Every episode',
  'Exclusive',
  'Just added',
  'Last chance',
  'Latest episodes',
  'New',
  'New episodes',
  'New episode',
  'New episodes weekly',
  'New season',
  'New season added',
  'New series',
  'Recently added',
  'Season final',
  'Season finale',
  'Series premiere',
];
