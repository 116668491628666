import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

class RecommendedCharacterCountPopover extends React.Component {
  render() {
    const popover = <Popover>{this.props.text}</Popover>;

    return (
      <OverlayTrigger ref="overlayTrigger" placement="right" overlay={popover}>
        <i className="fa fa-info-circle recommended-character-count-i"></i>
      </OverlayTrigger>
    );
  }
}

RecommendedCharacterCountPopover.propTypes = {
  text: PropTypes.string,
};

RecommendedCharacterCountPopover.defaultProps = {
  text: 'Recommended Character Count',
};

export default RecommendedCharacterCountPopover;
