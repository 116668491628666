import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  CREATE_COLLECTION_FAILED,
  CREATE_COLLECTION_SUCCEEDED,
  UPDATE_ADD_COLLECTION_FORM,
  GET_AVAILABLE_GENRES_SUCCEEDED,
  GET_AVAILABLE_GENRES_FAILED,
} from 'src/scripts/actions/addCollection';

import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/addCollectionForm';

export const initialState = {
  collection: {},
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  availableGenres: [],
};

const updateCollection = (state, collection) => ({
  ...state,
  collection: {
    ...state.collection,
    ...collection,
  },
});

const updateValidationErrors = (state, validationErrors) => ({
  ...state,
  validationErrors,
});

const updateErrorMessage = (state, errorMessage) => ({
  ...state,
  errorMessage,
});

const showValidationErrors = (state) => ({
  ...state,
  showValidationErrors: true,
});

const updateAllErrors = (state, validationErrors, errorMessage) =>
  updateErrorMessage(updateValidationErrors(state, validationErrors), errorMessage);

const replaceAvailableGenres = (state, availableGenres) => ({
  ...state,
  availableGenres,
});

const addCollectionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COLLECTION_FAILED:
      return updateErrorMessage(state, action.error);

    case CREATE_COLLECTION_SUCCEEDED:
      return initialState;

    case GET_AVAILABLE_GENRES_SUCCEEDED:
      return replaceAvailableGenres(state, action.data);

    case GET_AVAILABLE_GENRES_FAILED:
      return updateErrorMessage(state, action.error);

    case UPDATE_ADD_COLLECTION_FORM:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.collection, ...action.collection },
          getValidationRules()
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        const updatedState = updateCollection(state, action.collection);
        return updateAllErrors(updatedState, validationErrors, errorMessage);
      }
      return updateCollection(state, action.collection);

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.collection, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      const updatedState = updateAllErrors(state, validationErrors, errorMessage);
      return showValidationErrors(updatedState);

    case RESET_FORM:
      return initialState;

    default:
      return state;
  }
};

export default addCollectionFormReducer;
