import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_GENRE_FAILED,
  SAVE_GENRE_SUCCEEDED,
  GENRE_UPDATED,
  GET_GENRE_SUCCEEDED,
  GET_GENRE_FAILED,
} from 'src/scripts/actions/genre';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/genreForm';

export const initialState = {
  genre: {},
  errorMessage: null,
  validationErrors: {},
  showValidationErrors: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GENRE_SUCCEEDED:
      return {
        ...state,
        ...action.data,
      };

    case GET_GENRE_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case GENRE_UPDATED:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.genre, ...action.genre },
          getValidationRules()
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          genre: {
            ...state.genre,
            ...action.genre,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        genre: {
          ...state.genre,
          ...action.genre,
        },
      };

    case SAVE_GENRE_FAILED:
      return { ...state, errorMessage: action.error };

    case SAVE_GENRE_SUCCEEDED:
      return { ...state, errorMessage: null };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.genre, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
