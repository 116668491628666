import {
  required,
  dateFormat,
  isAfter,
  notBeforeToday,
} from 'src/scripts/lib/formValidation/generalValidators';
import { THIRD_PARTY_ORIGIN_TYPE, TV_ORIGIN_TYPE } from 'src/scripts/lib/contentOriginTypes';

export const isAfterStartDate = () =>
  isAfter({ field: 'availability', label: 'Start Date & Time' }, ['expiry']);

export const mustSelectDropdownOption = () => (rule, formState) =>
  formState[rule.field] === 0 ? `${rule.label} requires a selection` : null;

export const requiredSelectIfThirdPartyOrigin = () => (rule, formState) =>
  formState.contentOriginType === THIRD_PARTY_ORIGIN_TYPE ? required()(rule, formState) : null;

export const requiredSelectIfTvOrigin = () => (rule, formState) =>
  formState.contentOriginType === TV_ORIGIN_TYPE ? required()(rule, formState) : null;

const getValidationRules = (state) => {
  return [
    { field: 'name', label: 'Name', customValidators: required() },
    { field: 'categoryId', label: 'Category', customValidators: [required(), mustSelectDropdownOption()] },
    {
      field: 'subcategoryId',
      label: 'Subcategory',
      customValidators: [required(), mustSelectDropdownOption()],
    },
    { field: 'availability', label: 'Start Date & Time', customValidators: [required(), dateFormat()] },
    {
      field: 'expiry',
      label: 'End Date & Time',
      customValidators: [
        dateFormat(),
        isAfterStartDate(),
        // We don't want to force the user to enter a new end date when updating an archived record.
        state !== 'ARCHIVED' ? notBeforeToday(['expiry']) : null,
      ].filter((x) => x !== null),
    },
    { field: 'contentOriginType', label: 'Content Origin', customValidators: required() },
    { field: 'thirdPartyProvider', label: '3rd Party', customValidators: requiredSelectIfThirdPartyOrigin() },
    { field: 'contentOriginTVSeriesId', label: 'TV Series', customValidators: requiredSelectIfTvOrigin() },
    { field: 'contentOriginSeasonId', label: 'Season', customValidators: requiredSelectIfTvOrigin() },
  ];
};

export default getValidationRules;
