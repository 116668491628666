import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'react-bootstrap';
import PermissionsPanel from 'src/scripts/components/Admin/PermissionsPanel';
import { updateRole, saveRole } from 'src/scripts/actions/role';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/roleForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

export class RoleForm extends React.Component {
  constructor(props) {
    super(props);

    this.saveRole = this.saveRole.bind(this);
    this.onAzureRoleChange = this.onAzureRoleChange.bind(this);
  }

  onAzureRoleChange(event) {
    const roleValue = event.target.value;
    const roleName = event.target.childNodes[event.target.selectedIndex].text;
    this.props.role.azureRoleValue = roleValue !== '0' ? roleValue : null;
    this.props.role.name = roleValue !== '0' ? roleName : null;
    this.props.updateRole(this.props.role);
  }

  saveRole(event) {
    event.preventDefault();
    const role = {
      id: this.props.role.id,
      name: this.props.role.name,
      azureRoleValue: this.props.role.azureRoleValue,
      permissions: this.props.role.permissions,
    };
    if (!isFormValid(role, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveRole(role);
  }

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.saveRole} ref="roleForm">
          {this.props.role.id ? (
            <Input type="text" ref="azureRoleName" value={this.props.role.name} disabled="true" />
          ) : (
            <Input
              type="select"
              label="Available Azure Roles"
              ref="selectAzureRole"
              labelClassName="required"
              onChange={this.onAzureRoleChange}
              value={this.props.role.azureRoleValue}
              disabled={this.props.role.id}
              bsStyle={this.props.validationErrors.azureRoleValue ? 'error' : null}
            >
              <option value="0">Select a role</option>
              {this.props.availableAzureRoles.map((azureRole) => {
                return (
                  <option key={azureRole.displayName} value={azureRole.value}>
                    {azureRole.displayName}
                  </option>
                );
              })}
            </Input>
          )}
          <PermissionsPanel
            ref="permissionsPanel"
            permissions={this.props.role.permissions}
            onChange={(permissions) => this.props.updateRole({ permissions })}
          />
          {this.props.errorMessage && (
            <FormErrorMessage className="role-server-error-message" message={this.props.errorMessage} />
          )}
          <div className="modal-footer">
            <Button
              id="video-modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

RoleForm.propTypes = {
  close: PropTypes.func,
  role: PropTypes.object,
  availableAzureRoles: PropTypes.array,
  errorMessage: PropTypes.string,
  updateRole: PropTypes.func,
  saveRole: PropTypes.func,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
};

function mapStateToProps(state) {
  const roleForm = state.roleForm;
  return {
    role: roleForm.role,
    availableAzureRoles: roleForm.availableAzureRoles,
    errorMessage: roleForm.errorMessage,
    validationErrors: roleForm.validationErrors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateRole: (role) => dispatch(updateRole(role)),
    saveRole: (role) => dispatch(saveRole(role)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);
