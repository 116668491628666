import PropTypes from 'prop-types';
import React from 'react';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';

export default class ConfirmableActionButton extends React.Component {
  render() {
    return (
      <ModalItem
        ref="confirmationDialog"
        component="span"
        title={this.props.buttonLabel}
        className={`${this.props.buttonClass}`}
        form={
          <ConfirmationDialog
            className="confirmation-dialog"
            invitation={this.props.confirmationText}
            ok={this.props.action}
            buttonClass={this.props.confirmButtonClass}
            buttonLabel={this.props.confirmButtonLabel}
            immediateCloseOnConfirm={this.props.immediateCloseOnConfirm}
            isConfirmDisabled={this.props.isConfirmDisabled}
          />
        }
      />
    );
  }
}

ConfirmableActionButton.defaultProps = {
  disabled: false,
  buttonLabel: 'Some Action',
  buttonClass: 'btn btn-default',
  confirmButtonLabel: 'Confirm',
  confirmationText: 'Are you sure you want to do that?',
  confirmButtonClass: 'danger',
  immediateCloseOnConfirm: false,
  isConfirmDisabled: false,
};

ConfirmableActionButton.propTypes = {
  disabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonClass: PropTypes.string,
  action: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  confirmButtonClass: PropTypes.string,
  confirmationText: PropTypes.string,
  immediateCloseOnConfirm: PropTypes.bool,
  isConfirmDisabled: PropTypes.bool,
};
