import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { liveEventStatus, liveEventStatusColours } from '../../../lib/liveEventStatus';

class LiveEventsField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    availabilityStartDate: PropTypes.string,
    status: PropTypes.string,
    className: PropTypes.string,
    domRef: PropTypes.func,
    index: PropTypes.number,
    moveToTop: PropTypes.func,
    moveToBottom: PropTypes.func,
    liveEventsLength: PropTypes.number,
  };

  static defaultProps = {
    className: '',
  };

  constructor() {
    super();
    this.state = {
      isDragging: false,
    };
  }

  onMouseDown = () => {
    this.setState({ isDragging: true }, () => {
      document.addEventListener('dragend', this.handleDragEnd);
    });
  };

  handleDragEnd = () => {
    this.setState({ isDragging: false }, document.removeEventListener('dragend', this.handleDragEnd));
  };

  handleClick = () => {
    this.setState({ isDragging: false });
  };

  addClassIfDragging = () => {
    return this.state.isDragging ? 'dragging' : '';
  };

  renderAvailabilityStartDate = () => {
    let localDate = '';

    if (this.props.availabilityStartDate && this.props.status !== liveEventStatus.PRE_LAUNCH) {
      const utcDate = new Date(this.props.availabilityStartDate);
      const options = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      // Windows needs specific language to be set otherwise it will override computer system
      localDate = new Intl.DateTimeFormat('en-AU', options).format(utcDate);
    } else {
      localDate = 'Instant Event';
    }

    return (
      this.props.status &&
      this.props.status !== liveEventStatus.ERROR && (
        <span data-pw="availabilityStartDate" className="live-event-status-availability-start-date">
          {localDate}
        </span>
      )
    );
  };

  renderArrows = () => {
    const { index } = this.props;
    const statusColour = liveEventStatusColours[this.props.status];
    return (
      <div className="arrow-parent">
        {index !== 0 && (
          <div data-pw="arrow-up" className="arrow-child" onClick={() => this.props.moveToTop(index)}>
            <i className="fa fa-angle-double-up fa-3x"></i>
          </div>
        )}
        {index !== this.props.liveEventsLength - 1 && (
          <div data-pw="arrow-down" className="arrow-child" onClick={() => this.props.moveToBottom(index)}>
            <i className="fa fa-angle-double-down fa-3x"></i>
          </div>
        )}
        {this.props.status && this.props.status !== liveEventStatus.ERROR ? (
          <span
            data-pw="status-icon"
            className="live-event-status-icon"
            style={{ backgroundColor: `${statusColour}` }}
          ></span>
        ) : (
          <span data-pw="error-icon" className="live-event-status-button live-event-status-button--disabled">
            {liveEventStatus.ERROR}
          </span>
        )}
      </div>
    );
  };

  render() {
    return (
      <div
        onClick={this.handleClick}
        ref={this.props.domRef}
        data-pw="liveEventField"
        className={`live-event-group-form-live-events-field ${
          this.props.className
        } ${this.addClassIfDragging()}`}
        onMouseDown={this.onMouseDown}
      >
        <div className="name">{this.props.name}</div>
        <div className="live-event-status-container">
          {this.renderArrows()}
          {this.renderAvailabilityStartDate()}
        </div>
      </div>
    );
  }
}

export default LiveEventsField;
