import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Panel } from 'react-bootstrap';
import { Input } from 'react-bootstrap';
import {
  MODULES,
  READ_ONLY_ACCESS_ACTIONS,
  FULL_ACCESS_ACTIONS,
  hasReadyOnlyAccess,
} from 'src/scripts/lib/accessController';

export class PermissionsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.onModuleChange = this.onModuleChange.bind(this);
    this.onReadOnlyChange = this.onReadOnlyChange.bind(this);
  }

  onModuleChange(event) {
    let permissions;
    if (event.target.checked) {
      permissions = this.addModuleToPermissions(event.target.id);
    } else {
      permissions = this.getPermissionsWithoutModule(event.target.id);
    }
    this.props.onChange(permissions);
  }

  onReadOnlyChange(event) {
    let permissions;
    const referenceId = event.target.value;
    if (event.target.checked) {
      permissions = this.addActionsToModule(referenceId, READ_ONLY_ACCESS_ACTIONS);
    } else {
      permissions = this.addActionsToModule(referenceId, FULL_ACCESS_ACTIONS);
    }
    this.props.onChange(permissions);
  }

  getPermissionsWithoutModule(referenceId) {
    return {
      modules: this.props.permissions.modules.filter((module) => {
        return module.referenceId !== referenceId;
      }),
    };
  }

  // getReadOnlyId(referenceId) {
  //   return `read-only-${referenceId}`;
  // }

  addActionsToModule(referenceId, actions) {
    return {
      modules: this.props.permissions.modules.map((module) => {
        if (module.referenceId === referenceId) {
          return {
            ...module,
            actions,
          };
        }
        return module;
      }),
    };
  }

  addModuleToPermissions(referenceId) {
    const referenceIds = [referenceId];
    this.props.permissions.modules.forEach((module) => {
      if (!_.startsWith(module.referenceId, referenceId)) {
        // Not parent module
        referenceIds.push(module.referenceId);
      }
    });
    const modules = _.uniq(referenceIds).map((refId) => {
      return {
        referenceId: refId,
        actions: FULL_ACCESS_ACTIONS,
      };
    });
    return { modules };
  }

  isModuleChecked(referenceId) {
    return !!_.find(this.props.permissions.modules, { referenceId });
  }

  isModuleReadOnlyChecked(referenceId) {
    const module = _.find(this.props.permissions.modules, { referenceId });
    if (!module) return false;
    return hasReadyOnlyAccess(module);
  }

  isParentSelected(module) {
    let parentSelected = false;
    MODULES.forEach((potentialParent) => {
      if (
        this.isPotentialParent(potentialParent, module.referenceId) &&
        this.isModuleChecked(potentialParent.referenceId)
      ) {
        parentSelected = true;
        return;
      }
    });
    return parentSelected;
  }

  isParentReadOnlySelected(module) {
    let parentSelected = false;
    MODULES.forEach((potentialParent) => {
      if (
        this.isPotentialParent(potentialParent, module.referenceId) &&
        this.isModuleReadOnlyChecked(potentialParent.referenceId)
      ) {
        parentSelected = true;
        return;
      }
    });
    return parentSelected;
  }

  isPotentialParent(module, referenceId) {
    return module.referenceId !== referenceId && _.startsWith(referenceId, module.referenceId);
  }

  render() {
    return (
      <Panel header="Permissions" bsStyle="primary">
        {MODULES.map((module) => {
          const groupClassName = module.level ? `permission-level-${module.level}` : 'form-inline';
          return (
            <div className="roles-group">
              <Input
                type="checkbox"
                label={module.name}
                className="module-checkbox"
                groupClassName={groupClassName}
                id={module.referenceId}
                ref={module.referenceId}
                key={module.referenceId}
                onChange={this.onModuleChange}
                checked={this.isModuleChecked(module.referenceId) || this.isParentSelected(module)}
                disabled={this.isParentSelected(module)}
              />
              <Input
                type="checkbox"
                label="Read Only"
                className="read-only-checkbox"
                groupClassName="read-only-checkbox-parent"
                value={module.referenceId}
                // id={this.getReadOnlyId(module.referenceId)}
                ref={`read-only-${module.referenceId}`}
                onChange={this.onReadOnlyChange}
                checked={
                  this.isModuleReadOnlyChecked(module.referenceId) || this.isParentReadOnlySelected(module)
                }
                disabled={this.isParentReadOnlySelected(module) || !this.isModuleChecked(module.referenceId)}
              />
            </div>
          );
        })}
      </Panel>
    );
  }
}

PermissionsPanel.propTypes = {
  permissions: PropTypes.object,
  onChange: PropTypes.func,
};

export default PermissionsPanel;
