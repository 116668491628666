export const RESET_FORM = 'RESET_FORM';
export const SHOW_FORM_VALIDATION = 'SHOW_FORM_VALIDATION';
export const INIT_FORM = 'INIT_FORM';

export function reset() {
  return {
    type: RESET_FORM,
  };
}

export function showFormValidation() {
  return {
    type: SHOW_FORM_VALIDATION,
  };
}
