import PropTypes from 'prop-types';
import React from 'react';
import { Input, ProgressBar } from 'react-bootstrap';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

class CaptionUpload extends React.Component {
  isCaptionUploadingOrSuccessfullyUploaded = (isUploading, uploadPercentage) =>
    isUploading || uploadPercentage === 100;
  isCaptionUploadFailed = (errorMessage) => !!errorMessage;
  renderProgressBar = (uploadPercentage) => (
    <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />
  );
  renderError = (errorMessage) => (
    <FormErrorMessage className="caption-upload-error" message={errorMessage} />
  );

  render() {
    return (
      <div>
        <Input
          type="file"
          label="Caption"
          id="caption-file"
          accept=".vtt"
          className="captionFile"
          disabled={this.props.isDisabled()}
          onChange={this.props.onChange}
          groupClassName="form-inline"
        />
        {this.isCaptionUploadingOrSuccessfullyUploaded(this.props.isUploading, this.props.uploadPercentage) &&
          this.renderProgressBar(this.props.uploadPercentage)}
        {this.isCaptionUploadFailed(this.props.errorMessage) && this.renderError(this.props.errorMessage)}
      </div>
    );
  }
}

CaptionUpload.propTypes = {
  isDisabled: PropTypes.func.isRequired, // FIXME: Hooks into video disabled states
  onChange: PropTypes.func.isRequired,
  uploadPercentage: PropTypes.number,
  isUploading: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default CaptionUpload;
