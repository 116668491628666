import PropTypes from 'prop-types';
import React from 'react';

const SuccessMessageAlert = function ({ children }) {
  return <div className="alert alert-success">{children}</div>;
};

SuccessMessageAlert.propTypes = {
  children: PropTypes.any,
};

export default SuccessMessageAlert;
