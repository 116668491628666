export const CONFIRMATION_ACTION_FAILED = 'CONFIRMATION_ACTION_FAILED';
export const CONFIRMATION_ACTION_STARTED = 'CONFIRMATION_ACTION_STARTED';
export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL';
export const UPDATE_CONFIRMATION_REASON = 'UPDATE_CONFIRMATION_REASON';

export function confirmationActionStarted() {
  return {
    type: CONFIRMATION_ACTION_STARTED,
  };
}

export function showConfirmationModal(descriptions, ok, reasonRequired) {
  return {
    type: SHOW_CONFIRMATION_MODAL,
    descriptions,
    ok,
    reasonRequired: !!reasonRequired,
  };
}

export function updateConfirmationReason(reason) {
  return {
    type: UPDATE_CONFIRMATION_REASON,
    reason,
  };
}

export function hideConfirmationModal() {
  return {
    type: HIDE_CONFIRMATION_MODAL,
  };
}
