import { LOGIN_SUCCESSFUL, LOAD_USER_SUCCESSFUL, LOAD_USER_FAILED } from 'src/scripts/actions/user';

export const initialState = {
  loggedInUser: null,
  accessToken: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        accessToken: action.accessToken,
      };

    case LOAD_USER_SUCCESSFUL:
      return {
        ...state,
        loggedInUser: action.loggedInUser,
      };

    case LOAD_USER_FAILED:
      return {
        ...state,
        loggedInUser: null,
      };

    default:
  }

  return state;
}
