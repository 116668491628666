import moment from 'moment';
export const validateLicensingPeriodStartDate = (_, formState) => {
  const { fastChannelLicensingStartDate, fastChannelLicensingEndDate, isZypeMrssFeedEnabled } = formState;
  // Validate if start date is required for Zype MRSS feed and handle missing or invalid start date.
  if (isZypeMrssFeedEnabled) {
    if (!fastChannelLicensingStartDate) {
      return 'License period start date is required for the Zype MRSS feed.';
    }
    if (
      fastChannelLicensingStartDate &&
      (typeof fastChannelLicensingStartDate !== 'string' || !moment(fastChannelLicensingStartDate).isValid())
    ) {
      return 'Invalid license period start date';
    }
  }

  // Check if start date is required when an end date is provided but Zype MRSS feed is disabled.
  if (!isZypeMrssFeedEnabled && fastChannelLicensingEndDate && !fastChannelLicensingStartDate) {
    return 'License Period Start Date is required when License Period End Date is specified.';
  }

  return null;
};

export const validateLicensingPeriodEndDate = (_, formState) => {
  const { fastChannelLicensingStartDate, fastChannelLicensingEndDate, isZypeMrssFeedEnabled } = formState;

  const isEndDateInvalid =
    fastChannelLicensingEndDate &&
    (typeof fastChannelLicensingEndDate !== 'string' || !moment(fastChannelLicensingEndDate).isValid());

  // Check if end date is required for Zype MRSS feed and handle missing or invalid end date.
  if (isZypeMrssFeedEnabled) {
    if (!fastChannelLicensingEndDate) {
      return 'License period end date is required for Zype MRSS feed';
    }
    if (isEndDateInvalid) {
      return 'Invalid license period end date';
    }
  }

  // Check if end date is required when a start date is provided but Zype MRSS feed is disabled.
  if (!isZypeMrssFeedEnabled && fastChannelLicensingStartDate && !fastChannelLicensingEndDate) {
    return 'License Period End Date is required when License Period Start Date is specified.';
  }

  // Validate that end date is not earlier than start date.
  if (
    fastChannelLicensingEndDate &&
    fastChannelLicensingStartDate &&
    moment(fastChannelLicensingEndDate).isBefore(moment(fastChannelLicensingStartDate))
  ) {
    return 'License Period End Date and time cannot be earlier than the License Period Start Date and time. Please adjust.';
  }

  return null;
};
