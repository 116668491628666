import _ from 'lodash';
import moment from 'moment';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  NETWORK_CLIP_UPDATED,
  GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED,
  GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_FAILED,
  SAVE_NETWORK_CLIP_STARTED,
  SAVE_NETWORK_CLIP_SUCCEEDED,
  SAVE_NETWORK_CLIP_FAILED,
  GET_NETWORK_CLIP_FORM_INITIAL_DATA_SUCCEEDED,
  GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_SUCCEEDED,
  GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_FAILED,
} from 'src/scripts/actions/networkClip';
import { GET_CATEGORIES_SUCCEEDED, GET_CATEGORIES_FAILED } from 'src/scripts/actions/category';
import { FORM_GET_SUBCATEGORIES_SUCCEEDED } from 'src/scripts/actions/subcategory';
import { FORM_GET_SEASONS_SUCCEEDED } from 'src/scripts/actions/season';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/networkClipForm';
import {
  shallowFormatValidDatesToISOString,
  shallowSetEmptyDateFieldsToNull,
} from 'src/scripts/reducers/helpers';

export const externalFeedOptions = [
  {
    label: 'News',
    value: 'AUnews_AUnationalninenews',
    checked: false,
  },
  {
    label: 'WWOS',
    value: 'ausport_ausportsgeneral',
    checked: false,
  },
  {
    label: 'ACA',
    value: 'au_aca',
    checked: false,
  },
  {
    label: 'Today',
    value: 'au_today',
    checked: false,
  },
];

export const initialState = {
  clip: {
    name: '',
    preRoll: true,
    tags: [],
    externalFeedOptions: _.cloneDeep(externalFeedOptions),
    availability: null,
    geoBlocking: false,
    seoTitle: '',
    urlTitle: '',
    headline: '',
    longDescription: '',
    requireReview: false,
  },
  thirdPartyProviders: [],
  categories: [],
  subcategories: [],
  seasons: [],
  classifications: [{ code: 'MA', name: 'Mature Audience' }],
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  saving: false,
  initialFormDataLoaded: false,
};

function setAvailabilityToNow(state) {
  return {
    ...state,
    clip: {
      ...state.clip,
      availability: moment().format(),
    },
  };
}

function getCategoryId(clip) {
  let categoryId;
  if (clip.partOfCategory) {
    categoryId = clip.partOfCategory.id;
    delete clip.partOfCategory;
  }
  if (clip.categoryId) {
    categoryId = clip.categoryId;
  }
  return categoryId ? parseInt(categoryId, 10) : undefined;
}

function getSubcategoryId(clip) {
  let subcategoryId;
  if (clip.partOfSubcategory) {
    subcategoryId = clip.partOfSubcategory.id;
    delete clip.partOfSubcategory;
  }
  if (clip.subcategoryId) {
    subcategoryId = clip.subcategoryId;
  }
  return subcategoryId ? parseInt(subcategoryId, 10) : undefined;
}

function preprocessNetworkClip(clip) {
  const categoryId = getCategoryId(clip);
  const subcategoryId = getSubcategoryId(clip);
  if (categoryId) {
    clip.categoryId = categoryId;
  }
  if (subcategoryId) {
    clip.subcategoryId = subcategoryId;
  }
  clip.externalFeedOptions = _.cloneDeep(externalFeedOptions);
  if (clip.tags) {
    const tagValues = _.map(clip.tags, 'name');
    clip.externalFeedOptions.forEach((option) => {
      if (_.includes(tagValues, option.value)) {
        option.checked = true;
      }
    });
    clip.tags = tagValues.filter((tag) => {
      return _.findIndex(clip.externalFeedOptions, { value: tag }) === -1;
    });
  }
  if (clip.contentOriginSeason && clip.contentOriginSeason.partOfSeries) {
    clip.partOfSeries = clip.contentOriginSeason.partOfSeries;
    clip.contentOriginTVSeriesId = clip.contentOriginSeason.tvSeriesId;
    delete clip.contentOriginSeason;
  }
  return clip;
}

const preprocessNetworkClipDateFields = (networkClip, validationErrorsObject) => {
  const dateFields = ['availability', 'expiry'];
  let updatedIncomingNetworkClip = shallowFormatValidDatesToISOString(
    networkClip,
    dateFields,
    validationErrorsObject
  );
  updatedIncomingNetworkClip = shallowSetEmptyDateFieldsToNull(updatedIncomingNetworkClip, dateFields);
  return updatedIncomingNetworkClip;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED:
      return {
        ...state,
        clip: preprocessNetworkClip(action.data.clip),
        categories: action.data.categories,
        subcategories: action.data.subcategories,
        thirdPartyProviders: action.data.thirdPartyProviders ? action.data.thirdPartyProviders : [],
        seasons: action.data.seasons ? action.data.seasons : [],
      };

    case GET_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        categories: action.data.categories,
      };

    case FORM_GET_SUBCATEGORIES_SUCCEEDED:
      return {
        ...state,
        subcategories: action.data.subcategories,
      };

    case NETWORK_CLIP_UPDATED:
      const incomingNetworkClip = { ...state.clip, ...action.networkClip };
      const validationErrorsObject = getValidationErrors(
        incomingNetworkClip,
        getValidationRules(incomingNetworkClip.state)
      );
      const updatedIncomingNetworkClip = preprocessNetworkClipDateFields(
        incomingNetworkClip,
        validationErrorsObject
      );

      if (state.showValidationErrors) {
        const errorMessage = getValidationErrorMessage(validationErrorsObject);
        return {
          ...state,
          clip: updatedIncomingNetworkClip,
          validationErrors: validationErrorsObject,
          errorMessage,
        };
      }
      return {
        ...state,
        clip: updatedIncomingNetworkClip,
      };

    case SAVE_NETWORK_CLIP_STARTED:
      return { ...state, saving: true };

    case SAVE_NETWORK_CLIP_SUCCEEDED:
      return { ...state, saving: false };

    case SAVE_NETWORK_CLIP_FAILED:
      return { ...state, errorMessage: action.error, saving: false };

    case GET_NETWORK_CLIP_FORM_INITIAL_DATA_SUCCEEDED:
      return { ...state, initialFormDataLoaded: true };

    case FORM_GET_SEASONS_SUCCEEDED:
      return { ...state, seasons: action.data.seasons };

    case GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_FAILED:
    case GET_CATEGORIES_FAILED:
    case GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_FAILED:
      return { ...state, errorMessage: action.error };
    case GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_SUCCEEDED:
      return { ...state, thirdPartyProviders: action.data.thirdPartyProviders };
    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.clip, getValidationRules(state.clip.state));
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return setAvailabilityToNow(initialState);

    default:
      return state;
  }
}
