import moment from 'moment';
import { INSTANT_EVENT } from './liveEventScheduleTypes';

export const liveEventStatus = {
  PRE_LAUNCH: 'Pre-Launch',
  CREATED: 'Created',
  PROMOTING: 'Promoting',
  STOPPED: 'Stopped',
  STREAMING: 'Streaming',
  ARCHIVED: 'Archived',
  ERROR: 'Error',
};

export const liveEventStatusColours = {
  [liveEventStatus.PRE_LAUNCH]: '#337ab7',
  [liveEventStatus.CREATED]: '#337ab7',
  [liveEventStatus.PROMOTING]: '#f39c11',
  [liveEventStatus.STOPPED]: '#c0392a',
  [liveEventStatus.STREAMING]: '#34ae60',
  [liveEventStatus.ARCHIVED]: '#c0392a',
  [liveEventStatus.ERROR]: '#c0392a',
};

export function calculateLiveEventStatus(liveEvent) {
  let status;
  const timeNow = moment();
  if (liveEvent.errorMessage) {
    status = liveEventStatus.ERROR;
  } else if (
    liveEvent.scheduleType === INSTANT_EVENT &&
    !liveEvent.promoStartDate &&
    !liveEvent.availablityStartDate &&
    !liveEvent.availabilityEndDate
  ) {
    status = liveEventStatus.PRE_LAUNCH;
  } else if (timeNow.isBefore(liveEvent.promoStartDate)) {
    status = liveEventStatus.CREATED;
  } else if (timeNow.isAfter(liveEvent.promoStartDate) && timeNow.isBefore(liveEvent.availabilityStartDate)) {
    status = liveEventStatus.PROMOTING;
  } else if (
    timeNow.isAfter(liveEvent.availabilityStartDate) &&
    timeNow.isBefore(liveEvent.availabilityEndDate)
  ) {
    if (liveEvent.stopped) {
      status = liveEventStatus.STOPPED;
    } else {
      status = liveEventStatus.STREAMING;
    }
  } else if (timeNow.isAfter(liveEvent.availabilityEndDate)) {
    status = liveEventStatus.ARCHIVED;
  }
  return status;
}

export function includeLiveEventStatuses(liveEvents) {
  const liveEventsWithStatus = liveEvents.map((liveEvent) => {
    liveEvent.status = calculateLiveEventStatus(liveEvent);
    return liveEvent;
  });
  return liveEventsWithStatus;
}
