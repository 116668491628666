import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Input } from 'react-bootstrap';
import ErrorMessageAlert from 'src/scripts/components/ErrorMessageAlert';
import { confirmationActionStarted, updateConfirmationReason } from 'src/scripts/actions/confirmation';

export class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.doAction = this.doAction.bind(this);
    this.onReasonChange = this.onReasonChange.bind(this);
  }

  doAction() {
    if (this.props.reasonRequired) this.props.updateConfirmationReason(this.props.reason);
    if (!this.props.reasonRequired || (this.props.reason && this.props.reason.trim())) {
      this.props.confirmationActionStarted();
      this.props.ok(this.props.reason);
    }
  }

  onReasonChange(event) {
    this.props.updateConfirmationReason(event.target.value);
  }

  handleClick() {
    this.doAction();
    if (this.props.immediateCloseOnConfirm) {
      this.props.close();
    }
  }

  render() {
    const errorMessage = this.props.errorMessage || this.props.errorMessageFromState || '';

    return (
      <div>
        <p ref="description">
          {Array.isArray(this.props.invitation)
            ? this.props.invitation.map((invite, index) => {
                return <li key={index}>{invite}</li>;
              })
            : this.props.invitation}
        </p>
        {this.props.reasonRequired ? (
          <Input
            labelClassName="required"
            type="text"
            label="Reason"
            placeholder="Please provide a reason for this change"
            ref="reason"
            maxLength="200"
            onChange={this.onReasonChange}
            value={this.props.reason}
            data-pw="confirmation-dialog-reason-input"
          />
        ) : null}
        {errorMessage && <ErrorMessageAlert>{errorMessage}</ErrorMessageAlert>}
        <div className="form__submit-button-bar">
          <Button
            className="form__button cancel"
            type="button"
            ref="cancelButton"
            onClick={this.props.close}
            data-pw="confirmation-dialog-cancel"
          >
            {this.props.cancelButtonLabel}
          </Button>

          {this.props.showConfirmationButton && (
            <Button
              className={'form__button ok'}
              type="button"
              ref="okButton"
              bsStyle={this.props.buttonClass}
              disabled={this.props.actionInProgress || this.props.isConfirmDisabled}
              onClick={this.handleClick.bind(this)}
              data-pw="confirmation-dialog-ok"
            >
              {this.props.buttonLabel ? this.props.buttonLabel : 'OK'}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ConfirmationDialog.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  ok: PropTypes.func,
  close: PropTypes.func,
  updateConfirmationReason: PropTypes.func,
  buttonLabel: PropTypes.string,
  buttonClass: PropTypes.string,
  confirmationActionStarted: PropTypes.func,
  errorMessageFromState: PropTypes.string,
  errorMessage: PropTypes.string,
  actionInProgress: PropTypes.bool,
  immediateCloseOnConfirm: PropTypes.bool,
  isConfirmDisabled: PropTypes.bool,
  reasonRequired: PropTypes.bool,
  showConfirmationButton: PropTypes.bool,
  reason: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  isConfirmDisabled: false,
  showConfirmationButton: true,
  cancelButtonLabel: 'Cancel',
};

function mapStateToProps(state) {
  return {
    actionInProgress: state.confirmation.actionInProgress,
    errorMessageFromState: state.confirmation.errorMessage,
    reason: state.confirmation.reason,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    confirmationActionStarted: () => dispatch(confirmationActionStarted()),
    updateConfirmationReason: (reason) => dispatch(updateConfirmationReason(reason)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationDialog);
