import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import environment from 'config/environment';
import { loadScript } from 'src/scripts/lib/scriptLoader';
import { registerVideoPlayer, updateVideoDuration } from 'src/scripts/actions/videoPlayer';

export class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.getVideoPlayerEl = this.getVideoPlayerEl.bind(this);
    this.markAsLoaded = this.markAsLoaded.bind(this);

    this.state = {
      loaded: false,
    };
  }

  componentWillMount() {
    loadScript(environment.brightcove.playerUrl, this.markAsLoaded);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loaded && !prevState.loaded) {
      this.initPlayer();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
      this.props.registerVideoPlayer(null);
      this.props.updateVideoDuration(null);
    }
  }

  getCuePointsString(cuePoints) {
    let cuePointsArray;
    if (!cuePoints) {
      cuePointsArray = [];
    } else {
      cuePointsArray = cuePoints.map(({ time }) => Math.round(time));
    }
    return cuePointsArray.length > 0 ? cuePointsArray.join(',') : null;
  }

  getVideoPlayerEl() {
    return ReactDOM.findDOMNode(this.refs.videoPlayer);
  }

  markAsLoaded() {
    this.setState({
      loaded: true,
    });
  }

  initPlayer() {
    const defaultOptions = {
      preload: 'auto',
      controls: true,
    };
    /* global bc */
    bc(this.getVideoPlayerEl());
    /* global videojs */
    this.player = videojs(this.getVideoPlayerEl(), defaultOptions);
    this.player.src({
      type: this.props.type,
      src: this.props.url,
    });
    this.player.autoplay(this.props.autoplay);
    this.player.on('loadedmetadata', () => this.props.updateVideoDuration(this.player.duration()));
    this.props.registerVideoPlayer(this.player);
  }

  renderTrackIfCaptionUrlExists = () => {
    if (this.props.captionUrl) {
      return <track src={this.props.captionUrl} kind="captions" srclang="en" label="English" default />;
    }
    return null;
  };

  renderCrossOriginAttributeIfCaptionUrlExists = () => {
    if (this.props.captionUrl) {
      return { crossOrigin: 'anonymous' };
    }
    return {};
  };

  render() {
    if (!this.state.loaded) return <div className="video-js"></div>;

    const playerClasses = ['video-js', 'vjs-default-skin', 'vjs-big-play-centered'];
    if (!this.props.url) {
      playerClasses.push('hidden');
    }

    return (
      <div>
        <video
          ref="videoPlayer"
          data-embed="default"
          data-param-cuepoints={this.getCuePointsString(this.props.cuePoints)}
          className={playerClasses.join(' ')}
          controls
          {...this.renderCrossOriginAttributeIfCaptionUrlExists()}
        >
          {this.renderTrackIfCaptionUrlExists()}
        </video>
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  type: 'video/mp4',
  autoplay: false,
  captionUrl: null,
};

VideoPlayer.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  cuePoints: PropTypes.array,
  autoplay: PropTypes.bool,
  captionUrl: PropTypes.string,
  updateVideoDuration: PropTypes.func,
  registerVideoPlayer: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    registerVideoPlayer: (videoPlayer) => dispatch(registerVideoPlayer(videoPlayer)),
    updateVideoDuration: (videoDuration) => dispatch(updateVideoDuration(videoDuration)),
  };
}

export default connect(null, mapDispatchToProps)(VideoPlayer);
