export const SQUARE = 'square';
export const HORIZONTAL_A = 'horizontal_a';
export const BACKGROUND = 'background';
export const LOGO = 'logo';
export const CARD = 'card';
export const POSTER = 'poster';
export const FEATURED_CARD = 'featured_card';
export const FEATURED_BACKGROUND = 'featured_background';
export const CTV_CAROUSEL = 'ctv_carousel';
export const FEATURED_THUMBNAIL = 'featured_thumbnail';
export const EPISODE_THUMBNAIL = 'episode_thumbnail';
export const LIVE_EVENT_GROUP_IMAGE = 'live_event_group_image';
export const LIVE_EVENT_RATIO_LOGO = 'live_event_ratio_logo';
export const LIVE_EVENT_GROUP_BACKGROUND_IMAGE = 'live_event_group_background_image';
export const LIVE_EVENT_GROUP_LOGO = 'live_event_group_logo';
export const GENRE_IMAGE = 'genre_image';
export const GENRE_FEATURED_IMAGE = 'genre_featured_image';
export const GENRE_FEATURED_POSTER_IMAGE = 'genre_featured_poster_image';
export const CROPPED = 'cropped_logo';
export const CENTERED_IMAGE = 'centered_image';
export const CLIP_FEATURED_IMAGE = 'clip_featured_image';
export const LIVE_EVENT_GROUP_SWITCHER_LOGO = 'live_event_group_switcher_logo';
