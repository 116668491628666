import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import moment from 'moment';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import classificationTypes from 'src/scripts/constants/classificationTypes';

const VALID_DATE_FORMATS = ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY', moment.ISO_8601];

class ClassificationInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classifications: this.props.classifications || [],
      type: 'Longtail',
      digitalExclusive: false,
      incrementalSpend: false,
      startDate: this.getNewStartDate(this.props.classifications),
      endDate: null,
      formValidationErrors: {},
      errorMessages: [],
    };

    this.onTypeChange = this.onTypeChange.bind(this);
    this.onDigitalExclusiveChange = this.onDigitalExclusiveChange.bind(this);
    this.onIncrementalSpendChange = this.onIncrementalSpendChange.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.updateClassification = this.updateClassification.bind(this);
    this.isInEditMode = this.isInEditMode.bind(this);
    this.addClassification = this.addClassification.bind(this);
    this.populateAddForm = this.populateAddForm.bind(this);
    this.populateEditForm = this.populateEditForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const currentClassificationIndex = nextProps.currentClassificationIndex;

    if (this.currentClassificationIndexExists(currentClassificationIndex)) {
      this.populateEditForm(nextProps.classifications, currentClassificationIndex);
    } else {
      this.populateAddForm(nextProps.classifications);
    }
  }

  onTypeChange(event) {
    this.setState({
      type: event.target.value,
    });
  }

  onDigitalExclusiveChange(event) {
    this.setState({
      digitalExclusive: event.target.checked,
    });
  }

  onIncrementalSpendChange(event) {
    this.setState({
      incrementalSpend: event.target.checked,
    });
  }

  onStartDateChange(date) {
    this.setState({
      startDate: date,
    });
  }

  getValidationErrors() {
    const validationErrors = {};

    if (!this.state.startDate) {
      validationErrors.startDateRequired = 'Classification must have a Start Date.';
    } else {
      const momentStartDate = this.convertToMomentObject(this.state.startDate);
      const classifications = this.getClassifications();
      const previousClassificationIndex = this.getPreviousClassificationIndex(classifications);

      if (this.isStartDateBeforeCreationDate(momentStartDate)) {
        validationErrors.startDateBeforeCreationDate =
          'Start Date cannot be before the season creation date.';
      }

      if (this.isInEditMode()) {
        const startDateIsBeforeOrSameAsCurrentEndDate = this.isBeforeOrSameAsCurrentEndDate(momentStartDate);
        if (!startDateIsBeforeOrSameAsCurrentEndDate) {
          validationErrors.startDateAfterEndDate = 'Start Date must be before the End Date.';
        }
      }

      if (previousClassificationIndex || previousClassificationIndex === 0) {
        const startIsAfterOrSameAsPreviousStartDate = this.isAfterOrSameAsPreviousStartDate(
          momentStartDate,
          previousClassificationIndex
        );
        if (!startIsAfterOrSameAsPreviousStartDate) {
          validationErrors.startDateOverlap = 'Start Date overlaps with another classification Start Date.';
        }
      }

      if (this.hasClassificationPreviouslyBeenUsed(classifications)) {
        validationErrors.classificationPreviouslyUsed =
          'The classification you are trying to add has previously been used.  Please review.';
      }
    }

    return validationErrors;
  }

  isInEditMode() {
    return this.currentClassificationIndexExists(this.getClassificationIndex());
  }

  hasClassificationPreviouslyBeenUsed(classifications) {
    if (classifications.length < 1) {
      return false;
    }

    const currentClassificationIndex = this.getClassificationIndex();
    for (let i = 0; i < classifications.length; i++) {
      // if the current currentClassificationIndex exists, it should not include the current classification in this duplicate check.
      if (currentClassificationIndex !== i) {
        const classification = classifications[i];
        const classificationsHaveSameCode = classification.code === this.state.code;
        const classificationsHaveSameType = classification.type === this.state.type;
        const classificationsHaveSameDigitalExclusive =
          classification.digitalExclusive === this.state.digitalExclusive;
        const classificationsHaveSameIncrementalSpend =
          classification.incrementalSpend === this.state.incrementalSpend;

        if (
          classificationsHaveSameCode &&
          classificationsHaveSameType &&
          classificationsHaveSameDigitalExclusive &&
          classificationsHaveSameIncrementalSpend
        ) {
          return true;
        }
      }
    }

    return false;
  }

  getPreviousClassificationIndex(classifications) {
    if (this.isInEditMode()) {
      const currentClassificationIndex = this.getClassificationIndex();
      if (currentClassificationIndex !== 0) {
        return currentClassificationIndex - 1;
      }
    } else {
      if (classifications.length > 0) {
        return this.getClassifications().length - 1;
      }
    }
    return null;
  }

  getClassifications() {
    return this.state.classifications;
  }

  getClassificationIndex() {
    return this.props.currentClassificationIndex;
  }

  getNewStartDate(classifications) {
    const classificationListLength = classifications.length;
    const today = moment(new Date());
    let previousClassificationStartDate;
    let previousClassificationEndDate;

    if (classificationListLength > 0) {
      const previousClassification = classifications[classificationListLength - 1];
      previousClassificationStartDate = previousClassification.startDate;
      previousClassificationEndDate = previousClassification.endDate;
    }

    let newStartDate;
    if (previousClassificationEndDate) {
      newStartDate = moment(previousClassificationEndDate).add(1, 'day');
    } else if (
      previousClassificationStartDate &&
      this.convertToMomentObject(previousClassificationStartDate).isSameOrAfter(today, 'day')
    ) {
      newStartDate = moment(previousClassificationStartDate).add(1, 'day');
    } else {
      newStartDate = today;
    }

    return newStartDate;
  }

  setPreviousClassificationEndDate(classifications, currentClassificationIndex) {
    if (classifications.length > 0 && currentClassificationIndex !== 0) {
      // toISOString must be used to get the state's startDate as a string or else the state's start date itself will be altered by the moment's subtract function
      const momentStartDateString = this.convertToMomentObject(this.state.startDate).toISOString();
      const startDate = this.convertToMomentObject(momentStartDateString);
      const dayBeforeStartDate = this.convertToMomentObject(momentStartDateString).subtract(1, 'day');

      let previousClassification;
      if (currentClassificationIndex) {
        previousClassification = classifications[currentClassificationIndex - 1];
      } else {
        previousClassification = classifications[classifications.length - 1];
      }

      if (this.convertToMomentObject(previousClassification.startDate).isSame(startDate, 'day')) {
        previousClassification.endDate = startDate;
      } else {
        previousClassification.endDate = dayBeforeStartDate;
      }
    }
  }

  convertToMomentObject(date) {
    return date._isAMomentObject ? date : moment(date, VALID_DATE_FORMATS, true);
  }

  populateAddForm(classificationList) {
    this.setState({
      classifications: classificationList || [],
      startDate: this.getNewStartDate(classificationList),
      code: '',
      type: 'Longtail',
      digitalExclusive: false,
      incrementalSpend: false,
      endDate: null,
      formValidationErrors: {},
      errorMessages: [],
    });
  }

  populateEditForm(classificationList, currentClassificationIndex) {
    const classificationToEdit = classificationList[currentClassificationIndex];
    this.setState({
      classifications: classificationList,
      code: classificationToEdit.code,
      type: classificationToEdit.type,
      digitalExclusive: classificationToEdit.digitalExclusive,
      incrementalSpend: classificationToEdit.incrementalSpend,
      startDate: classificationToEdit.startDate,
      endDate: classificationToEdit.endDate,
      formValidationErrors: {},
      errorMessages: [],
    });
  }

  currentClassificationIndexExists(index) {
    return !!index || index === 0;
  }

  isStartDateBeforeCreationDate(startDate) {
    let creationDate;
    if (this.props.seasonCreatedAt) {
      creationDate = this.convertToMomentObject(this.props.seasonCreatedAt);
    } else {
      creationDate = moment(new Date());
    }
    return startDate.isBefore(creationDate, 'day');
  }

  addClassification() {
    const validationErrors = this.getValidationErrors();

    if (_.isEmpty(validationErrors)) {
      const startDate = this.state.startDate;
      const classifications = this.getClassifications();
      const momentStartDate = this.convertToMomentObject(startDate);
      const updatedClassifications = classifications.slice();

      this.setPreviousClassificationEndDate(classifications);

      updatedClassifications.push({
        code: this.state.code,
        type: this.state.type,
        digitalExclusive: this.state.digitalExclusive,
        incrementalSpend: this.state.incrementalSpend,
        startDate: momentStartDate,
        endDate: this.state.endDate,
      });
      this.populateAddForm(updatedClassifications);
      this.props.updateClassifications(updatedClassifications);
    } else {
      this.setState({
        formValidationErrors: validationErrors,
        errorMessages: Object.values(validationErrors),
      });
    }
  }

  isAfterOrSameAsPreviousStartDate(date, previousClassificationIndex) {
    const classifications = this.getClassifications();
    const previousClassification = classifications[previousClassificationIndex];
    const previousStartDate = previousClassification.startDate;

    return date.isSameOrAfter(previousStartDate, 'day');
  }

  isBeforeOrSameAsCurrentEndDate(date) {
    const currentEndDate = this.state.endDate;
    if (currentEndDate) {
      return date.isSameOrBefore(currentEndDate, 'day');
    }
    return true;
  }

  updateClassification() {
    const validationErrors = this.getValidationErrors();
    const classifications = this.getClassifications();
    const currentClassificationIndex = this.getClassificationIndex();

    if (_.isEmpty(validationErrors)) {
      const momentStartDate = this.convertToMomentObject(this.state.startDate);
      const updatedClassifications = classifications.slice();

      this.setPreviousClassificationEndDate(classifications, currentClassificationIndex);

      updatedClassifications[currentClassificationIndex] = {
        code: this.state.code,
        type: this.state.type,
        digitalExclusive: this.state.digitalExclusive,
        incrementalSpend: this.state.incrementalSpend,
        startDate: momentStartDate,
        endDate: this.state.endDate,
      };
      this.props.updateClassifications(updatedClassifications);
    } else {
      this.setState({
        formValidationErrors: validationErrors,
        errorMessages: Object.values(validationErrors),
      });
    }
  }

  renderEndDate() {
    if (this.isInEditMode()) {
      return (
        <div className="form-group">
          <label className="control-label">Classification End Date</label>
          <div>
            <span> {this.state.endDate ? moment(this.state.endDate).format('ll') : 'Ongoing'} </span>
          </div>
        </div>
      );
    }
    return null;
  }

  renderFormButtons() {
    if (this.isInEditMode()) {
      return (
        <div className="form-group">
          <Button className="update-button" onClick={this.updateClassification}>
            Update
          </Button>

          <Button className="cancel-button" onClick={this.props.cancel}>
            Cancel
          </Button>
        </div>
      );
    }
    return (
      <div>
        <div className="form-group">
          <Button className="add-button" onClick={this.addClassification}>
            Add
          </Button>
        </div>
      </div>
    );
  }

  renderValidationWarning() {
    if (this.state.errorMessages.length) {
      return <FormErrorMessage message={this.state.errorMessages.join('\n')} />;
    }
    return null;
  }

  render() {
    return (
      <form className="form" ref="classification-input-form">
        <div className="form-group">
          <label className="control-label required">Classification Type</label>
          <select
            ref="type"
            name="classification-type"
            className="form-control required"
            value={this.state.type}
            onChange={this.onTypeChange}
          >
            {classificationTypes.map((classificationType) => (
              <option value={classificationType}>{classificationType}</option>
            ))}
          </select>
        </div>

        <div className="form-group form-inline">
          <Input
            type="checkbox"
            label="Digital Exclusive"
            ref="digitalExclusive"
            id="digital-exclusive"
            groupClassName="form-inline"
            checked={this.state.digitalExclusive}
            onChange={this.onDigitalExclusiveChange}
          />
        </div>

        <div className="form-group form-inline">
          <Input
            type="checkbox"
            label="Incremental Spend"
            ref="incrementalSpend"
            id="incremental-spend"
            groupClassName="form-inline"
            checked={this.state.incrementalSpend}
            onChange={this.onIncrementalSpendChange}
          />
        </div>

        <div
          className={`form-group ${
            this.state.formValidationErrors.startDateRequired ||
            this.state.formValidationErrors.startDateAfterEndDate ||
            this.state.formValidationErrors.startDateOverlap
              ? 'error'
              : ''
          }`}
        >
          <label className="control-label required">Classification Start Date</label>
          <DateTimePicker
            ref="classificationStart"
            id="classification-start"
            dateFormatForDisplay="DD-MM-YYYY"
            onChange={this.onStartDateChange}
            value={this.state.startDate}
          />
        </div>

        {this.renderEndDate()}
        {this.renderFormButtons()}
        {this.renderValidationWarning()}
      </form>
    );
  }
}

ClassificationInputForm.propTypes = {
  classifications: PropTypes.array,
  currentClassificationIndex: PropTypes.number,
  seasonCreatedAt: PropTypes.string,
  updateClassifications: PropTypes.func,
  cancel: PropTypes.func,
};
export default ClassificationInputForm;
