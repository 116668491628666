import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { openModal, closeModal } from 'src/scripts/actions/modalItem';

export class ModalItem extends React.Component {
  constructor(props) {
    super(props);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onClickHandlers = this.onClickHandlers.bind(this);
    this.id = _.uniqueId('ModalItem');
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.open();
    }
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.isOpen && this.props.isOpen) {
      this.open();
    } else if (oldProps.isOpen && !this.props.isOpen) {
      this.close();
    }
  }

  onClickHandlers(event) {
    this.open();
    if (this.props.onClickHandlers.length) {
      this.props.onClickHandlers.forEach((onClickHandler) => {
        onClickHandler(event);
      });
    }
  }

  close() {
    this.props.closeModal();
    if (this.props.onClose) this.props.onClose();
  }

  open() {
    this.props.openModal(this.id);
  }

  render() {
    const modalOpen = this.props.openedModalId === this.id;
    const icon = this.props.iconClass ? <i className={`fa ${this.props.iconClass}`}></i> : '';
    const title = this.props.label || this.props.title;
    const Component = this.props.component || 'li';
    return (
      <Component>
        <a
          id={this.props.id}
          key={this.id}
          className={this.props.className}
          onClick={this.onClickHandlers}
          data-pw={`modal-trigger${title ? `-${title.toLowerCase().replace(/ /g, '-')}` : ''}`}
        >
          <span>
            {icon} {title}
          </span>
        </a>
        <Modal show={modalOpen} onHide={this.close} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title ref="modalTitle">
              {icon} {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body ref="modalBody">
            {this.props.form && React.cloneElement(this.props.form, { close: this.close })}
          </Modal.Body>
        </Modal>
      </Component>
    );
  }
}

ModalItem.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  iconClass: PropTypes.string,
  title: PropTypes.node,
  label: PropTypes.node,
  form: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  openedModalId: PropTypes.string,
  id: PropTypes.string,
  onClickHandlers: PropTypes.arrayOf(PropTypes.func),

  // isOpen and onClose provide control of open state via props.
  isOpen: PropTypes.boolean,
  onClose: PropTypes.func,
};

ModalItem.defaultProps = {
  onClickHandlers: [],
};

function mapStateToProps(state) {
  return {
    openedModalId: state.modalItem.openedModalId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openModal: (id) => dispatch(openModal(id)),
    closeModal: () => dispatch(closeModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalItem);
