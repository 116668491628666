import Rx from 'rx';
import _ from 'lodash';
import * as s3Uploads from 'src/scripts/actions/apiWrappers/vmsApi/s3Uploads';
import * as s3Api from 'src/scripts/actions/apiWrappers/s3Api';

export function createUploadJob(resourceGroup, assetId, fileName, uploadType) {
  return s3Uploads.getCreateUploadSignedUrl(resourceGroup, assetId, fileName, uploadType).flatMap((arg) => {
    const signedUrl = arg.signedUrl;
    const bucket = arg.bucket;
    const s3Path = arg.s3Path;
    return s3Api.createUpload(signedUrl).map((arg1) => {
      const uploadId = arg1.uploadId;
      return {
        uploadId,
        bucket,
        s3Path,
      };
    });
  });
}

export function uploadOnePart(resourceGroup, s3Path, uploadId, file, partNum, partSize, uploadType) {
  const partBlob = file.slice((partNum - 1) * partSize, Math.min(partNum * partSize, file.size));
  return s3Uploads
    .getUploadPartSignedUrl(resourceGroup, s3Path, uploadId, partNum, uploadType)
    .flatMap((arg) => {
      const signedUrl = arg.signedUrl;
      return s3Api.uploadPart(signedUrl, partBlob);
    })
    .map((eTag) => {
      return {
        PartNumber: partNum,
        ETag: eTag,
      };
    });
}

export function uploadParts(
  resourceGroup,
  s3Path,
  uploadId,
  file,
  partSize,
  uploadConcurrency,
  uploadType,
  progressCallback,
  uploadOnePartArgument
) {
  const uploadOnePartFunction = uploadOnePartArgument || uploadOnePart;
  const partCount = Math.ceil(file.size / partSize);
  const replayedPartsUpload = Rx.Observable.range(1, partCount)
    .map((partNum) => {
      return uploadOnePartFunction(resourceGroup, s3Path, uploadId, file, partNum, partSize, uploadType);
    })
    .merge(uploadConcurrency)
    .replay();
  replayedPartsUpload.subscribe((replay) => {
    return progressCallback(partCount, replay.PartNumber);
  });
  replayedPartsUpload.connect();
  return replayedPartsUpload.toArray().map((partsInfo) => {
    return _.sortBy(partsInfo, 'PartNumber');
  });
}

export function completeUploadJob(resourceGroup, s3Path, uploadId, partsInfo, uploadType) {
  return s3Uploads.getCompleteUploadSignedUrl(resourceGroup, s3Path, uploadId, uploadType).flatMap((arg) => {
    const signedUrl = arg.signedUrl;
    return s3Api.completeUpload(signedUrl, partsInfo);
  });
}
