import PropTypes from 'prop-types';
import React from 'react';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import SeasonSelect from './SeasonSelect';

export class TVSeriesSeasonSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onTVSeriesChange = this.onTVSeriesChange.bind(this);
    this.onSeasonChange = this.onSeasonChange.bind(this);
  }

  onTVSeriesChange(tvSeriesId) {
    this.props.onChange(tvSeriesId, null);
    tvSeriesId && this.props.getSeasons({ tvSeriesId });
  }

  onSeasonChange(seasonId) {
    this.props.onChange(this.props.tvSeriesId, seasonId || null);
  }

  renderTvSeriesSelect = () => (
    <TVSeriesSelect
      ref="selectTVSeries"
      required={this.props.required}
      initialTvSeries={this.props.initialTvSeries}
      onTVSeriesChange={this.onTVSeriesChange}
      hasValidationErrors={!!this.props.validationErrors.tvSeriesId}
    />
  );

  renderSeasonSelect = () => (
    <SeasonSelect
      ref="selectSeason"
      onSeasonChange={this.onSeasonChange}
      disabled={!this.props.tvSeriesId}
      hasValidationErrors={!!this.props.validationErrors.seasonId}
      tvSeriesId={this.props.tvSeriesId}
      seasonId={this.props.seasonId}
      seasons={this.props.seasons}
      required={this.props.required}
    />
  );

  render() {
    return (
      <div>
        {this.renderTvSeriesSelect()}
        {this.renderSeasonSelect()}
      </div>
    );
  }
}

TVSeriesSeasonSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  getSeasons: PropTypes.func.isRequired,
  initialTvSeries: PropTypes.object,
  seasonId: PropTypes.number.isRequired,
  tvSeriesId: PropTypes.number.isRequired,
  seasons: PropTypes.array.isRequired,
  required: PropTypes.bool,
  validationErrors: PropTypes.object,
};

export const defaultProps = {
  validationErrors: {},
  required: false,
};

TVSeriesSeasonSelect.defaultProps = defaultProps;

export default TVSeriesSeasonSelect;
