import { GET_TAGS_STARTED, GET_TAGS_FAILED, GET_TAGS_SUCCEEDED, CLEAR_TAGS } from 'src/scripts/actions/tag';

export const initialState = {
  tags: [],
  errorMessage: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TAGS_STARTED:
      return {
        ...state,
        loading: true,
      };

    case GET_TAGS_SUCCEEDED:
      return { ...state, tags: action.data.tags, errorMessage: null, loading: false };

    case GET_TAGS_FAILED:
      return { ...state, errorMessage: action.error, loading: false };
    case CLEAR_TAGS:
      return { ...state, tags: [] };

    default:
      return state;
  }
}
