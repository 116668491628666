import PropTypes from 'prop-types';
import React from 'react';
import ConfirmableActionButton from 'src/scripts/components/ConfirmableActionButton';
import {
  canDownloadEpisodeVideoFromS3,
  canDownloadClipVideoFromS3,
} from 'src/scripts/lib/bulkWorkflowActionsProvider';
import { IN_PROGRESS } from 'src/scripts/reducers/bulkActions';
import { EPISODE, CLIP } from 'src/scripts/lib/modelNames';
import { BULK_ACTION_TYPE_DOWNLOAD } from '../../../actions/bulkAction';

export class DownloadButton extends React.Component {
  formatConfirmationString(actionName, modelName, selectedCount) {
    if (this.props.bulkActionStatus === IN_PROGRESS) {
      return 'There is currently a bulk action in progress. Please wait until the current action completes before starting a new one.';
    }
    const mapModelNameToString = {
      [CLIP]: 'clip',
      [EPISODE]: 'episode',
    };
    const pluralisedModelName = `${mapModelNameToString[modelName]}${selectedCount > 1 ? 's' : ''}`;
    const downloadableVideoCount = this.downloadableVideos().length;
    const messages = [];

    if (downloadableVideoCount !== selectedCount) {
      messages.push(
        `You selected ${selectedCount} videos. But only ${downloadableVideoCount} can be downloaded.`
      );
    }

    messages.push(
      `You are about to ${actionName} ${downloadableVideoCount} ${pluralisedModelName}. Are you sure you want to continue?`
    );

    return messages.join('\n');
  }

  downloadableVideos() {
    return this.props.selectedItems.filter(
      this.props.modelName === EPISODE ? canDownloadEpisodeVideoFromS3 : canDownloadClipVideoFromS3
    );
  }

  isDisabled() {
    if (this.props.selectedItems.length === 0 || this.downloadInProgress()) {
      return true;
    }

    return this.downloadableVideos().length === 0;
  }

  downloadInProgress() {
    /**
     * This method ignores the processingModelName as
     * only one bulk download action is allowed at a time.
     */
    return this.props.processingActionType === BULK_ACTION_TYPE_DOWNLOAD && this.props.unprocessed.length > 0;
  }

  getDownloadLabel() {
    /**
     * Show "Download" label on clips' page even when episodes are being bulk downloaded.
     */
    if (!this.downloadInProgress() || this.props.processingModelName !== this.props.modelName) {
      return 'Download';
    }

    const totalNumber = this.props.processed.length + this.props.unprocessed.length;
    const currentNumber = Math.min(this.props.processed.length + 1, totalNumber);

    return `Downloading (${currentNumber}/${totalNumber})`;
  }

  render() {
    const mapDownloadAction = {
      [EPISODE]: this.props.downloadEpisodes,
      [CLIP]: this.props.downloadClips,
    };

    return (
      <ConfirmableActionButton
        ref="downloadButton"
        buttonClass={`bulk-download-btn btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        buttonLabel={this.getDownloadLabel()}
        confirmationText={this.formatConfirmationString(
          'download',
          this.props.modelName,
          this.props.selectedItems.length
        )}
        action={mapDownloadAction[this.props.modelName].bind(
          this,
          this.downloadableVideos().map((item) => item.video.id)
        )}
        immediateCloseOnConfirm
        isConfirmDisabled={this.isDisabled()}
      />
    );
  }
}

DownloadButton.propTypes = {
  downloadEpisodes: PropTypes.func,
  downloadClips: PropTypes.func,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  bulkActionStatus: PropTypes.string,
  processed: PropTypes.array,
  unprocessed: PropTypes.array,
  processingActionType: PropTypes.string,
  processingModelName: PropTypes.string,
};

export default DownloadButton;
