export const EPISODE_DISPLAY_NAME_EXAMPLES = {
  NUMBER_AND_NAME: 'Example: "Ep 3 Walking On The Air"',
  NUMBER: 'Example: "Ep 3"',
  NAME: 'Example: "Walking On The Air"',
};

export const getEpisodeDisplayNameExample = (episodeDisplayNameFormat) => {
  if (episodeDisplayNameFormat) {
    return EPISODE_DISPLAY_NAME_EXAMPLES[episodeDisplayNameFormat];
  }
  return EPISODE_DISPLAY_NAME_EXAMPLES.NUMBER_AND_NAME;
};
