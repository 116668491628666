import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from '../../ConfirmationDialog';
import { deleteGenreAndCloseConfirmation } from '../../../actions/genre';

export class Delete extends React.Component {
  getClassName() {
    return this.props.displayType === 'button' ? 'btn btn-default' : '';
  }

  getComponent() {
    return this.props.displayType === 'button' ? 'span' : '';
  }

  render() {
    return (
      <ModalItem
        title={'Delete Genre'}
        className={this.getClassName()}
        component={this.getComponent()}
        form={
          <ConfirmationDialog
            invitation={`Clicking Delete will permanently delete "${this.props.genre.name}" genre and it's associated genre collection. Are you sure you wish to proceed?`}
            ok={this.props.deleteGenreAndCloseConfirmation.bind(this, this.props.genre.id)}
            buttonClass="danger"
            buttonLabel="Delete"
          />
        }
      />
    );
  }
}

Delete.propTypes = {
  genre: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  deleteGenreAndCloseConfirmation: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    deleteGenreAndCloseConfirmation: (genreId) => dispatch(deleteGenreAndCloseConfirmation(genreId)),
  };
}

export default connect(null, mapDispatchToProps)(Delete);
