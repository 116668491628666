import React from 'react';
import { Panel, Input } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as liveEventGroupsVmsApi from '../../../actions/apiWrappers/vmsApi/common/liveEventGroups';

class LiveEventGroupPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveEventGroupOptions: [],
    };
  }

  componentDidMount() {
    this.fetchLiveEventGroups();
  }

  onLiveEventGroupChange = (event) => {
    this.props.onLiveEventGroupChange(event.target.value === 'unselected' ? null : event.target.value);
  };

  fetchLiveEventGroups = async () => {
    const liveEventGroupOptions = await liveEventGroupsVmsApi.getLiveEventGroups().then((res) =>
      res.liveEventGroups.map((liveEventGroup) => ({
        label: liveEventGroup.name,
        value: liveEventGroup.id,
      }))
    );
    this.setState({ liveEventGroupOptions });
  };

  getInputBsStyle = () => (this.props.validationErrors.liveEventGroupId ? 'error' : null);

  render() {
    return (
      <Panel bsStyle="primary" header="Event Group">
        <Input
          type="select"
          ref="liveEventGroup"
          label="Event Group"
          labelClassName="required"
          defaultValue="default"
          value={this.props.liveEvent && this.props.liveEvent.liveEventGroupId}
          onChange={this.onLiveEventGroupChange}
          bsStyle={this.getInputBsStyle()}
          data-pw="event-group"
        >
          <option value="unselected">Select a Live Event Group</option>
          {this.state.liveEventGroupOptions.map((option) => {
            return (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Input>
      </Panel>
    );
  }
}

LiveEventGroupPanel.propTypes = {
  liveEvent: PropTypes.object,
  onLiveEventGroupChange: PropTypes.func,
  validationErrors: PropTypes.object.isRequired,
};

export default LiveEventGroupPanel;
