import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import SubcategoryForm from 'src/scripts/components/Subcategory/internal/SubcategoryForm';
import { getSubcategoryFormInitialData } from 'src/scripts/actions/subcategory';

export class SubcategoryFormLoader extends React.Component {
  componentDidMount() {
    this.props.getSubcategoryFormInitialData(this.props.subcategoryId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <SubcategoryForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

SubcategoryFormLoader.propTypes = {
  subcategoryId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getSubcategoryFormInitialData: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const subcategoryForm = state.subcategoryForm;
  return {
    initialFormDataLoaded: subcategoryForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubcategoryFormInitialData: (subcategoryId) => dispatch(getSubcategoryFormInitialData(subcategoryId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubcategoryFormLoader);
