import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'react-bootstrap';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import { deleteTVSeries } from 'src/scripts/actions/tvSeries';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import { connect } from 'react-redux';
import TVSeriesFormLoader from './TVSeriesFormLoader';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class TVSeriesActions extends React.Component {
  getModalItemClassName = () => {
    return this.props.displayType === 'button-bar' ? 'btn btn-default' : '';
  };

  getModalItemComponent = () => {
    return this.props.displayType === 'button-bar' ? 'span' : '';
  };

  renderViewAction = () => {
    return (
      <li>
        <a className="view-tv-series-list-item" href={`#/${TV_PATH}/tv-series/${this.props.tvSeries.id}`}>
          View TV Series
        </a>
      </li>
    );
  };

  renderEditAction = () => {
    return (
      <ModalItem
        key={this.props.tvSeries.id}
        title="Edit TV Series"
        className={this.getModalItemClassName()}
        component={this.getModalItemComponent()}
        form={<TVSeriesFormLoader tvSeriesId={this.props.tvSeries.id} />}
      />
    );
  };

  renderDeleteAction = () => {
    return (
      <ModalItem
        title="Delete TV Series"
        className={this.getModalItemClassName()}
        component={this.getModalItemComponent()}
        form={
          <ConfirmationDialog
            invitation={`Clicking Delete will permanently delete "${this.props.tvSeries.name}" TV Series. Are you sure you wish to proceed?`}
            ok={async () => {
              await this.props.deleteTVSeries(this.props.tvSeries.id);
              this.props.afterDeletion();
            }}
            buttonClass="danger"
            buttonLabel="Delete"
          />
        }
      />
    );
  };

  render() {
    if (this.props.displayType === 'dropdown') {
      return (
        <NavDropdownMenu
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          id={`tv-series-item-button-${this.props.tvSeries.id}`}
        >
          {this.renderViewAction()}
          {this.renderEditAction()}
          {this.renderDeleteAction()}
        </NavDropdownMenu>
      );
    }
    if (this.props.displayType === 'button-bar') {
      return (
        <ButtonToolbar className="pull-right toolbar-buttons">
          {this.renderEditAction()}
          {this.renderDeleteAction()}
        </ButtonToolbar>
      );
    }
    return null;
  }
}

export const mapDispatchToProps = (dispatch) => ({
  deleteTVSeries: (tvSeriesId) => dispatch(deleteTVSeries(tvSeriesId)),
});

TVSeriesActions.propTypes = {
  afterDeletion: PropTypes.func,
  tvSeries: PropTypes.object,
  displayType: PropTypes.oneOf(['dropdown', 'button-bar']),
  deleteTVSeries: PropTypes.func,
};

TVSeriesActions.defaultProps = {
  displayType: 'dropdown',
  afterDeletion: () => {},
};

export default connect(null, mapDispatchToProps)(TVSeriesActions);
