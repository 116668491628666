import { FEATURED_THUMBNAIL, EPISODE_THUMBNAIL } from 'src/scripts/lib/imageTypes';

export const episodeImageTypeData = [
  {
    type: EPISODE_THUMBNAIL,
    header: 'Episode Image',
    wrapperClassName: 'episode-detail-image-wrapper',
  },
  {
    type: FEATURED_THUMBNAIL,
    header: 'Featured Image',
    wrapperClassName: 'episode-detail-image-wrapper',
  },
];
