import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import {
  HORIZONTAL_A,
  CARD,
  SQUARE,
  BACKGROUND,
  LOGO,
  POSTER,
  CROPPED,
  CENTERED_IMAGE,
} from 'src/scripts/lib/imageTypes';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import { isDefaultImage } from 'src/scripts/lib/util';

const imageTypesData = [
  {
    type: HORIZONTAL_A,
    header: 'Horizontal Image',
    wrapperClassName: 'season-detail-image-wrapper',
  },
  {
    type: CARD,
    header: 'Card Image',
    wrapperClassName: 'season-detail-image-wrapper',
  },
  {
    type: SQUARE,
    header: 'Square Image',
    wrapperClassName: 'season-detail-image-wrapper season-image-square',
  },
  {
    type: BACKGROUND,
    header: 'Background Image',
    wrapperClassName: 'season-detail-image-wrapper',
  },
  {
    type: LOGO,
    header: 'Logo Image',
    wrapperClassName: 'season-detail-image-wrapper season-image-logo',
  },
  {
    type: POSTER,
    header: 'Poster Image',
    wrapperClassName: 'season-detail-image-wrapper season-image-poster',
  },
  {
    type: CROPPED,
    header: 'Cropped Logo',
    wrapperClassName: 'season-detail-image-wrapper season-image-cropped',
  },
  {
    type: CENTERED_IMAGE,
    header: 'Centered Image',
    wrapperClassName: 'season-detail-image-wrapper season-image-centered',
  },
];

const ArtworkPanels = ({ season }) => {
  const getImageUrl = (imageType) => {
    const matchedImageForType = season.images.find((image) => image.type === imageType);
    if (!matchedImageForType || isDefaultImage(matchedImageForType)) return IMAGE_NOT_FOUND_URL;
    return matchedImageForType.url;
  };

  return (
    <div>
      {imageTypesData.map((imageTypeData) => {
        return (
          <Panel header={imageTypeData.header}>
            <div className={imageTypeData.wrapperClassName}>
              <img className="season-image" src={getImageUrl(imageTypeData.type)} />
            </div>
          </Panel>
        );
      })}
    </div>
  );
};

ArtworkPanels.propTypes = {
  season: PropTypes.object,
};

export default ArtworkPanels;
