import { CdnCollection } from '../../../lib/cdn';

export function getIsCheckedBooleanField(device, field) {
  return device[device[`${field}Status`].field];
}

export function getIsCheckedCdnField(cdnName) {
  return (device) => {
    const cdn = new CdnCollection(device.cdn).find(cdnName);
    return cdn && cdn.isActivated();
  };
}
