import PropTypes from 'prop-types';
import React from 'react';
import ModalItem from 'src/scripts/components/ModalItem';
import LoadingIcon from 'src/scripts/components/LoadingIcon';
import ExportCatalogForm from 'src/scripts/components/ItemList/ExportCatalogForm';

export class ExportCatalogButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    };

    this.updateExportStatus = this.updateExportStatus.bind(this);
  }

  updateExportStatus(isExportInProgress) {
    this.setState({
      isExporting: isExportInProgress,
    });
  }

  render() {
    return (
      <div className="export-button-div">
        <LoadingIcon loading={this.state.isExporting} className="fa-lg" />
        <ModalItem
          title="Export Catalog"
          component={'span'}
          className={`export-btn btn btn-sm btn-default${this.state.isExporting ? ' disabled' : ''}`}
          form={
            <ExportCatalogForm
              modelName={this.props.modelName}
              updateExportStatus={this.updateExportStatus}
            />
          }
        />
      </div>
    );
  }
}

ExportCatalogButton.propTypes = {
  modelName: PropTypes.string,
};

export default ExportCatalogButton;
