import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import ClipForm from 'src/scripts/components/Clip/internal/ClipForm';
import { getClipFormInitialData } from 'src/scripts/actions/clip';

export class ClipFormLoader extends React.Component {
  componentDidMount() {
    this.props.getClipFormInitialData(this.props.clipId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <ClipForm close={this.props.close} isReschedule={this.props.isReschedule} />
      </ViewLoader>
    );
  }
}

ClipFormLoader.propTypes = {
  clipId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getClipFormInitialData: PropTypes.func,
  close: PropTypes.func,
  isReschedule: PropTypes.bool, // This should be remove when we have proper video states and a save method should be passed
};

function mapStateToProps(state) {
  const clipForm = state.clipForm;
  return {
    initialFormDataLoaded: clipForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClipFormInitialData: (clipId) => dispatch(getClipFormInitialData(clipId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClipFormLoader);
