import React from 'react';
import PropTypes from 'prop-types';
import ModalItem from 'src/scripts/components/ModalItem';
import AssignEpisodeForm from 'src/scripts/components/BulkAction/buttons/AssignEpisodeButton/AssignEpisodeForm';

export class AssignEpisodeButton extends React.Component {
  isDisabled = () => {
    return !this.props.selectedItems.length;
  };

  render() {
    return (
      <ModalItem
        ref="bulkActionBarAssignEpisodeButton"
        className={`editModal btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        component={'span'}
        title="Assign Episode"
        form={
          <AssignEpisodeForm
            seasonId={this.props.seasonId}
            selectedItems={this.props.selectedItems}
            assignClipsToEpisode={this.props.assignClipsToEpisode}
          />
        }
      />
    );
  }
}

AssignEpisodeButton.propTypes = {
  seasonId: PropTypes.number,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  assignClipsToEpisode: PropTypes.func,
};

export default AssignEpisodeButton;
