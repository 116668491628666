import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import environment from 'config/environment';
import { saveTVSeries, updateTvSeries } from 'src/scripts/actions/tvSeries';
import { Button, Input } from 'react-bootstrap';
import Select from 'react-select';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';
import CountriesSelect from 'src/scripts/components/TVSeries/CountriesSelect';
import ContentSegmentSelect from 'src/scripts/components/TVSeries/internal/ContentSegmentSelect.jsx';
import ProductionCompanySelect from 'src/scripts/components/TVSeries/internal/ProductionCompanySelect.jsx';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/tvSeriesForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import { LABELS } from '../../../constants/common';
import ContentFlagSelect from '../../ContentFlagSelect';
import CmsSourceCheckbox from './CmsSourceCheckbox';
import PreviewCollectionItems from './PreviewCollectionItems';
import RecommendedCharacterCountPopover from '../../RecommendedCharacterCountPopover';
import { TAGS } from 'src/scripts/lib/resourceGroups';
import TagList from 'src/scripts/components/TagList';

export class TVSeriesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewCollection: false,
    };
    this.saveTVSeries = this.saveTVSeries.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onMarketingDescriptionChange = this.onMarketingDescriptionChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onGenreChange = this.onGenreChange.bind(this);
    this.onShowSiteLinkChange = this.onShowSiteLinkChange.bind(this);
  }

  handleClick = () => {
    this.setState({ previewCollection: true });
  };

  onNameChange(event) {
    this.props.updateTvSeries({
      name: event.target.value,
    });
  }

  onDescriptionChange(event) {
    this.props.updateTvSeries({
      description: event.target.value || null,
    });
  }

  onMarketingDescriptionChange(event) {
    this.props.updateTvSeries({
      marketingDescription: event.target.value || null,
    });
  }

  onCountryChange(country) {
    this.props.updateTvSeries({
      countryOfOrigin: country,
    });
  }

  onGenreChange(event) {
    this.props.updateTvSeries({
      genreId: !isDefaultOptionSelected(this.refs.selectGenre) ? parseInt(event.target.value, 10) : null,
    });
  }

  onRecommendedCollectionChange = (event) => {
    this.setState({ previewCollection: false });
    this.props.updateTvSeries({
      recommendedCollectionId: !isDefaultOptionSelected(this.refs.selectRecommendedCollection)
        ? parseInt(event.target.value, 10)
        : null,
    });
  };

  onSecondaryGenreChange = (selectedSecondaryGenres) => {
    const secondaryGenresIdsArray = selectedSecondaryGenres.map((secondaryGenreId) => secondaryGenreId.value);
    this.props.updateTvSeries({
      secondaryGenresIds: secondaryGenresIdsArray,
    });
  };

  onContentSegmentChange = (contentSegment) => {
    this.props.updateTvSeries({
      contentSegment,
    });
  };

  onProductionCompanyChange = (productionCompany) => {
    this.props.updateTvSeries({
      productionCompany,
    });
  };

  onContentFlagChange = (contentFlag) => {
    const value = contentFlag ? contentFlag.value : null;
    this.props.updateTvSeries({
      contentFlag: value,
    });
  };

  onTagsChange = (tags) => {
    this.props.updateTvSeries({
      tags,
    });
  };

  onCmsSourceChange = () => {
    this.props.updateTvSeries({
      useSitecoreCmsSource: !this.props.tvSeries.useSitecoreCmsSource,
    });
  };

  onShowSiteLinkChange = (event) => {
    this.props.updateTvSeries({
      showSiteLink: event.target.value || null,
    });
  };

  onProducerNotesInput = (event) => {
    this.props.updateTvSeries({ producerNotes: event.target.value });
  };

  saveTVSeries(event) {
    event.preventDefault();
    const tvSeries = {
      ...this.props.tvSeries,
      useSitecoreCmsSource: !!this.props.tvSeries.useSitecoreCmsSource,
      name: this.props.tvSeries.name && this.props.tvSeries.name.trim(),
    };
    if (!isFormValid(tvSeries, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    if (tvSeries.containsSeason) {
      delete tvSeries.containsSeason;
    }
    this.props.saveTVSeries(tvSeries);
  }

  renderCollectionsPreview = () => {
    return (
      <PreviewCollectionItems
        collectionId={this.props.tvSeries.recommendedCollectionId}
        previewMode={'small'}
      />
    );
  };

  recommendedNameCharacterCount = 30;
  recommendedDescriptionCharacterCount = 90;
  maximumMarketingDescriptionCharacterCountPerLine = 30;

  render() {
    const nameCharacterCount = this.props.tvSeries.name ? this.props.tvSeries.name.length : 0;
    const descriptionCharacterCount = this.props.tvSeries.description
      ? this.props.tvSeries.description.length
      : 0;
    const marketingDescriptionLineLengths = (this.props.tvSeries.marketingDescription || '')
      .split('\n')
      .map((line) => line.length);

    return (
      <div>
        <form className="form" onSubmit={this.saveTVSeries} ref="tvSeriesForm" data-pw="tv-series-form">
          <div className="form-group">
            <Input
              type="select"
              label="Library Name"
              disabled
              className="select-library"
              ref="selectLibrary"
              id="select-library"
              labelClassName="required"
            >
              <option value="TV">TV</option>
            </Input>
          </div>
          <div className="form-group">
            <label ref="nameLabel">Series Name</label>
            <div
              id="name-character-limit-label"
              ref="characterLimitLabel"
              className={`pull-right ${
                nameCharacterCount > this.recommendedNameCharacterCount ? 'character-count-warning' : ''
              }`}
            >
              <span ref="charCount">
                {nameCharacterCount} / {this.recommendedNameCharacterCount}
              </span>
              <RecommendedCharacterCountPopover />
            </div>
            <Input
              maxLength="200"
              labelClassName="required"
              ref="name"
              id="tv-series-name"
              type="text"
              className="form-control"
              onChange={this.onNameChange}
              placeholder="TV series name"
              value={this.props.tvSeries.name}
              bsStyle={this.props.validationErrors.name ? 'error' : null}
              data-pw="tv-series-name"
            />
          </div>
          <div className="form-group">
            <label ref="descriptionLabel">Featured Description</label>
            <div
              id="description-character-limit-label"
              ref="descriptionCharacterLimitLabel"
              className={`pull-right ${
                descriptionCharacterCount > this.recommendedDescriptionCharacterCount
                  ? 'character-count-warning'
                  : ''
              }`}
            >
              <span ref="charCount">
                {descriptionCharacterCount} / {this.recommendedDescriptionCharacterCount}
              </span>
              <RecommendedCharacterCountPopover />
            </div>
            <Input
              maxLength="2000"
              ref="description"
              id="tv-series-description"
              type="textarea"
              className="form-control"
              onChange={this.onDescriptionChange}
              placeholder="TV series description"
              value={this.props.tvSeries.description}
              bsStyle={this.props.validationErrors.description ? 'error' : null}
              data-pw="tv-series-featured-description"
            />
          </div>
          <div className="form-group">
            <label ref="marketingDescriptionLabel">Marketing Description</label>
            <div
              id="marketing-description-character-limit-label"
              ref="descriptionCharacterLimitLabel"
              className="pull-right"
            >
              {
                <span
                  ref="charCount"
                  className={marketingDescriptionLineLengths.length > 4 ? 'character-count-warning' : ''}
                >
                  {marketingDescriptionLineLengths.length} / 4 lines,{' '}
                </span>
              }
              {marketingDescriptionLineLengths.map((len, index) => (
                <span
                  ref="charCount"
                  className={
                    len > this.maximumMarketingDescriptionCharacterCountPerLine
                      ? 'character-count-warning'
                      : ''
                  }
                >
                  {len} / {this.maximumMarketingDescriptionCharacterCountPerLine}
                  {index < marketingDescriptionLineLengths.length - 1 ? ', ' : ''}
                </span>
              ))}
              {<span ref="charCount"> characters</span>}
              <RecommendedCharacterCountPopover text="Maximum 30 Characters Per Line - Maximum 4 lines" />
            </div>
            <Input
              maxLength="2000"
              ref="marketingDescription"
              id="tv-series-marketing-description"
              type="textarea"
              rows={4}
              className="form-control"
              onChange={this.onMarketingDescriptionChange}
              placeholder="TV series marketing description"
              value={this.props.tvSeries.marketingDescription}
              bsStyle={this.props.validationErrors.marketingDescription ? 'error' : null}
              data-pw="tv-series-marketing-description"
            />
          </div>
          <TagList
            ref="tagList"
            tags={this.props.tvSeries.tags}
            onChange={this.onTagsChange}
            resourceGroup={TAGS.TV}
          />
          <div className="form-group">
            <Input
              type="select"
              label="Genre"
              ref="selectGenre"
              id="select-genre"
              labelClassName="required"
              onChange={this.onGenreChange}
              value={this.props.tvSeries.genreId}
              bsStyle={this.props.validationErrors.genreId ? 'error' : null}
              data-pw="select-genre"
            >
              <option value="0">Select a genre</option>
              {this.props.genres.map((genre) => {
                return (
                  <option key={genre.id} value={genre.id}>
                    {genre.name}
                  </option>
                );
              })}
            </Input>
          </div>
          <div className="secondary-genres">
            <label className="control-label">Secondary Genres</label>
            <Select
              multi
              label="Secondary Genres"
              name="secondary-genres-field-name"
              placeholder="Select Secondary Genres"
              ref="secondaryGenres"
              onChange={this.onSecondaryGenreChange}
              value={this.props.tvSeries.secondaryGenresIds}
              options={this.props.genres.map((genre) => {
                return { value: genre.id, label: genre.name };
              })}
            />
          </div>
          <div className="form-group">
            <CountriesSelect
              ref="countriesSelect"
              onChange={this.onCountryChange}
              country={this.props.tvSeries.countryOfOrigin}
            />
          </div>
          <div className="content-segment-select">
            <label classsName="control-label">Content Segment</label>
            <ContentSegmentSelect
              ref="contentSegmentSelect"
              onChange={this.onContentSegmentChange}
              contentSegments={this.props.contentSegments}
              selected={this.props.tvSeries.contentSegment}
            />
          </div>
          <div className="production-company-select">
            <label classsName="control-label">Production Company</label>
            <ProductionCompanySelect
              ref="productionCompanySelect"
              onChange={this.onProductionCompanyChange}
              productionCompanies={this.props.productionCompanies}
              selected={this.props.tvSeries.productionCompany}
            />
          </div>
          <div className="form-group">
            <Input
              type="select"
              label={LABELS.RECOMMENDED_COLLECTION}
              ref="selectRecommendedCollection"
              id="select-recommended-collection"
              onChange={this.onRecommendedCollectionChange}
              value={this.props.tvSeries.recommendedCollectionId}
              bsStyle={this.props.validationErrors.recommendedCollectionId ? 'error' : null}
            >
              <option value="0">Select a Custom Collection</option>
              {this.props.availableCollections.map((cx) => {
                return (
                  <option key={cx.id} value={cx.id}>
                    {cx.name}
                  </option>
                );
              })}
            </Input>
          </div>
          {this.props.tvSeries.recommendedCollectionId && (
            <Button className="collection-preview-button" bsStyle="primary" onClick={this.handleClick}>
              Preview Collection
            </Button>
          )}
          {this.state.previewCollection && this.renderCollectionsPreview()}
          {environment.featureFlags.nineVideoFeaturesEnabled ? (
            <div className="content-flag-select">
              <label classsName="control-label">Content Flag</label>
              <ContentFlagSelect
                ref="contentFlagSelect"
                contentFlagType={'tvSeries'}
                onChange={this.onContentFlagChange}
                selected={this.props.tvSeries.contentFlag}
              />
            </div>
          ) : null}
          <div className="form-group">
            <label ref="showSiteLinkLabel">Showsite Link</label>
            <Input
              maxLength="2000"
              ref="showSiteLink"
              id="tv-series-show-site-link"
              type="text"
              className="form-control"
              onChange={this.onShowSiteLinkChange}
              placeholder="https://9now.nine.com.au/show-site"
              value={this.props.tvSeries.showSiteLink}
              bsStyle={this.props.validationErrors.showSiteLink ? 'error' : null}
            />
          </div>
          {environment.featureFlags.nineVideoFeaturesEnabled ? (
            <div className="cms-source-radio">
              <label classsName="control-label">CMS Source</label>
              <CmsSourceCheckbox
                ref="useSitecoreCmsCheckbox"
                onChange={this.onCmsSourceChange}
                selected={this.props.tvSeries.useSitecoreCmsSource}
              />
            </div>
          ) : null}
          <Input
            id="producer-notes-input"
            type="textarea"
            label="Producer Notes"
            ref="producerNotes"
            onInput={(e) => this.onProducerNotesInput(e)}
            value={this.props.tvSeries.producerNotes}
          />
          {this.props.errorMessage && (
            <FormErrorMessage className="tv-series-server-error-message" message={this.props.errorMessage} />
          )}
          <div className="modal-footer">
            <Button
              id="video-modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

TVSeriesForm.propTypes = {
  tvSeries: PropTypes.object,
  genres: PropTypes.array,
  contentSegments: PropTypes.array,
  productionCompanies: PropTypes.array,
  errorMessage: PropTypes.string,
  saveTVSeries: PropTypes.func,
  updateTvSeries: PropTypes.func,
  close: PropTypes.func,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
  availableCollections: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    tvSeries: state.tvSeriesForm.tvSeries,
    genres: state.tvSeriesForm.genres,
    contentSegments: state.tvSeriesForm.contentSegments,
    productionCompanies: state.tvSeriesForm.productionCompanies,
    availableCollections: state.tvSeriesForm.customCollections,
    errorMessage: state.tvSeriesForm.errorMessage,
    validationErrors: state.tvSeriesForm.validationErrors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveTVSeries: (tvSeries) => dispatch(saveTVSeries(tvSeries)),
    updateTvSeries: (tvSeries) => dispatch(updateTvSeries(tvSeries)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TVSeriesForm);
