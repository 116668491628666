import { workflowErrorNotification } from 'src/scripts/actions/eventNotification';

export const BULK_ACTIONS_ADD_TO_SELECTED = 'BULK_ACTIONS_ADD_TO_SELECTED';
export const BULK_ACTIONS_REMOVE_FROM_SELECTED = 'BULK_ACTIONS_REMOVE_FROM_SELECTED';
export const BULK_ACTIONS_REPLACE_SELECTED = 'BULK_ACTIONS_REPLACE_SELECTED';
export const BULK_ACTIONS_DESELECT_ALL = 'BULK_ACTIONS_DESELECT_ALL';
export const BULK_ACTION_SUCCEEDED = 'BULK_ACTION_SUCCEEDED';
export const BULK_ACTION_FAILED = 'BULK_ACTION_FAILED';
export const BULK_ACTION_PROCESSING_STARTED = 'BULK_ACTION_PROCESSING_STARTED';
export const BULK_ACTION_PROCESSING_COMPLETED = 'BULK_ACTION_PROCESSING_COMPLETED';
export const BULK_ACTION_CLIP_UPDATED = 'BULK_ACTION_CLIP_UPDATED';
export const BULK_ACTION_EPISODE_UPDATED = 'BULK_ACTION_EPISODE_UPDATED';
export const BULK_ACTION_NETWORK_CLIP_UPDATED = 'BULK_ACTION_NETWORK_CLIP_UPDATED';
export const BULK_ACTION_CLIP_DELETED = 'BULK_ACTION_CLIP_DELETED';
export const BULK_ACTION_EPISODE_DELETED = 'BULK_ACTION_EPISODE_DELETED';
export const BULK_ACTION_NETWORK_CLIP_DELETED = 'BULK_ACTION_NETWORK_CLIP_DELETED';
export const BULK_ACTION_INGEST_JOB_DELETED = 'BULK_ACTION_INGEST_JOB_DELETED';
export const BULK_ACTION_CLIP_DOWNLOADED = 'BULK_ACTION_CLIP_DOWNLOADED';
export const BULK_ACTION_EPISODE_DOWNLOADED = 'BULK_ACTION_EPISODE_DOWNLOADED';

export const BULK_ACTION_TYPE_DOWNLOAD = 'download';

const SLEEP_PERIOD = 1000;

export function doBulkAction(ids, processItem, actionType, modelName, onItemSuccessAction) {
  return (dispatch) => {
    if (ids.length) {
      dispatch({
        type: BULK_ACTION_PROCESSING_STARTED,
        actionType,
        modelName,
        ids,
      });
      let index = 0;

      const onError = (id) => (response) => {
        dispatch({
          type: BULK_ACTION_FAILED,
          id,
          error: response.responseJSON.message,
        });

        dispatch(workflowErrorNotification(response.responseJSON.message, actionType, modelName, id));
      };

      const onSuccess = (id) => (result) => {
        dispatch({
          type: BULK_ACTION_SUCCEEDED,
          id,
        });

        dispatch({
          type: onItemSuccessAction,
          data: result,
        });
      };

      const processLoop = () => {
        processItem(ids[index], onSuccess(ids[index]), onError(ids[index])).always(() => {
          if (++index < ids.length) {
            setTimeout(processLoop, SLEEP_PERIOD);
          } else {
            dispatch({
              type: BULK_ACTION_PROCESSING_COMPLETED,
            });
          }
        });
      };
      processLoop();
    }
  };
}

export function addToSelected(item) {
  return {
    type: BULK_ACTIONS_ADD_TO_SELECTED,
    selected: item,
  };
}

export function replaceSelected(items) {
  return {
    type: BULK_ACTIONS_REPLACE_SELECTED,
    selected: items,
  };
}

export function removeFromSelected(id) {
  return {
    type: BULK_ACTIONS_REMOVE_FROM_SELECTED,
    id,
  };
}

export function deselectAll() {
  return {
    type: BULK_ACTIONS_DESELECT_ALL,
  };
}
