import _map from 'lodash/map';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_EPISODE_STARTED,
  SAVE_EPISODE_SUCCEEDED,
  SAVE_EPISODE_FAILED,
  GET_EPISODE_AND_FORM_LISTS_SUCCEEDED,
  GET_EPISODE_AND_FORM_LISTS_FAILED,
  EPISODE_UPDATED,
  GET_EPISODE_FORM_INITIAL_DATA_SUCCEEDED,
  CLEAR_DUPLICATE_EPISODE,
} from 'src/scripts/actions/episode';
import {
  GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_SUCCEEDED,
  IMPORT_EPISODE_STARTED,
  IMPORT_EPISODE_SUCCEEDED,
  IMPORT_EPISODE_FAILED,
  OVERWRITEABLE_DUPLICATE_EPISODE_FOUND,
  REPLACE_EPISODE_IN_PROGRESS,
} from 'src/scripts/actions/ingestJobs';
import { FORM_GET_SEASONS_SUCCEEDED } from 'src/scripts/actions/season';
import { secondsToHoursMinutesSeconds } from 'src/scripts/lib/cuepointsConverter';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/episodeForm';
import classificationCodes from '../constants/classificationCodes';
import {
  shallowFormatValidDatesToISOString,
  shallowSetEmptyDateFieldsToNull,
} from 'src/scripts/reducers/helpers';

const DEFAULT_TRANSCODING_PROFILE = 'hd';
export const initialState = {
  episode: {
    cuePoints: [{ time: '0:0:0' }],
    video: {
      drm: false,
      captionsIngestEnabled: true,
    },
    geoBlocking: true,
    watermarkEnabled: true,
    autoActivate: false,
    number: '',
    name: '',
    materialKey: '',
    phoenixEpisodeIdentifier: '',
    videoPreviewEnabled: true,
    adsEnabled: null,
    producerNotes: null,
    transcodingProfile: DEFAULT_TRANSCODING_PROFILE,
  },
  seasons: [],
  classifications: classificationCodes,
  duplicateEpisode: null,
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  saving: false,
  initialFormDataLoaded: false,
};

function getTvSeriesId(episode) {
  let tvSeriesId;
  if (episode.partOfSeries) {
    tvSeriesId = episode.partOfSeries.id;
  }
  if (episode.tvSeriesId) {
    tvSeriesId = episode.tvSeriesId;
  }
  return tvSeriesId ? parseInt(tvSeriesId, 10) : undefined;
}

function getSeasonId(episode) {
  let seasonId;
  if (episode.partOfSeason) {
    seasonId = episode.partOfSeason.id;
    delete episode.partOfSeason;
  }
  if (episode.seasonId) {
    seasonId = episode.seasonId;
  }
  return seasonId ? parseInt(seasonId, 10) : undefined;
}

function preprocessEpisode(episode) {
  const tvSeriesId = getTvSeriesId(episode);
  const seasonId = getSeasonId(episode);
  if (tvSeriesId) {
    episode.tvSeriesId = tvSeriesId;
  }
  if (seasonId) {
    episode.seasonId = seasonId;
  }
  if (episode.tags) {
    episode.tags = _map(episode.tags, 'name');
  }
  if (episode.video) {
    episode.cuePoints = secondsToHoursMinutesSeconds(episode.video.cuePoints);
    delete episode.video.cuePoints;
  }
  if (episode.state === 'ARCHIVED') {
    episode.autoActivate = false;
  }

  if (!episode.transcodingProfile) {
    episode.transcodingProfile = DEFAULT_TRANSCODING_PROFILE;
  }
  return episode;
}

function preprocessEpisodeFromIngestJob(episode, ingestJob) {
  if (ingestJob.tags) {
    episode.tags = ingestJob.tags;
  }
  if (ingestJob.video) {
    episode.video = ingestJob.video;
  }

  return episode;
}

const preprocessEpisodeGeoBlockingFromSeason = (episode, seasons) => {
  if (episode.seasonId) {
    const episodeSeason = seasons.find((season) => season.id === episode.seasonId);
    return {
      ...episode,
      geoBlocking: episodeSeason.episodesGeoBlocked,
    };
  }
  return episode;
};

const preprecessEpisodeContentIdFromSeason = (episode, seasons) => {
  if (episode.seasonId) {
    const episodeSeason = seasons.find((season) => season.id === episode.seasonId);
    if (
      episodeSeason &&
      episodeSeason.defaultContentIdChannel &&
      episodeSeason.defaultContentIdUsagePolicy &&
      episodeSeason.defaultContentIdMatchPolicy
    ) {
      return {
        ...episode,
        uploadToYoutube: true,
        contentIdChannel: episodeSeason.defaultContentIdChannel,
        contentIdUsagePolicy: episodeSeason.defaultContentIdUsagePolicy,
        contentIdMatchPolicy: episodeSeason.defaultContentIdMatchPolicy,
      };
    }
  }
  return episode;
};

const preprocessEpisodeDateFields = (episode, validationErrorsObject) => {
  const dateFields = ['availability', 'expiry', 'broadcastAirDate'];
  let updatedIncomingEpisode = shallowFormatValidDatesToISOString(
    episode,
    dateFields,
    validationErrorsObject
  );
  updatedIncomingEpisode = shallowSetEmptyDateFieldsToNull(updatedIncomingEpisode, dateFields);
  return updatedIncomingEpisode;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EPISODE_AND_FORM_LISTS_SUCCEEDED:
      return {
        ...state,
        episode: preprocessEpisode(action.data.episode),
        seasons: action.data.seasons,
      };

    case GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_SUCCEEDED:
      const episodeFromIngestJob = preprocessEpisodeFromIngestJob(action.data.episode, action.data.ingestJob);
      const updatedEpisode = preprocessEpisodeGeoBlockingFromSeason(
        episodeFromIngestJob,
        action.data.seasons
      );
      const updatedEpisodeWithContentId = preprecessEpisodeContentIdFromSeason(
        updatedEpisode,
        action.data.seasons
      );
      return {
        ...state,
        episode: preprocessEpisode(updatedEpisodeWithContentId),
        seasons: action.data.seasons,
      };

    case FORM_GET_SEASONS_SUCCEEDED:
      return {
        ...state,
        seasons: action.data.seasons,
      };

    case EPISODE_UPDATED:
      const incomingEpisode = { ...state.episode, ...action.episode };
      const validationErrorsObject = getValidationErrors(
        incomingEpisode,
        getValidationRules(state.episode.state)
      );
      const updatedIncomingEpisode = preprocessEpisodeDateFields(incomingEpisode, validationErrorsObject);

      if (state.showValidationErrors) {
        const errorMessage = getValidationErrorMessage(validationErrorsObject);
        return {
          ...state,
          episode: updatedIncomingEpisode,
          validationErrors: validationErrorsObject,
          errorMessage,
        };
      }
      return {
        ...state,
        episode: updatedIncomingEpisode,
      };

    case GET_EPISODE_AND_FORM_LISTS_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case SAVE_EPISODE_STARTED:
      return {
        ...state,
        saving: true,
      };

    case SAVE_EPISODE_SUCCEEDED:
      return {
        ...state,
        saving: false,
      };

    case SAVE_EPISODE_FAILED:
      return {
        ...state,
        errorMessage: action.error,
        saving: false,
      };

    case IMPORT_EPISODE_STARTED:
      return {
        ...state,
        saving: true,
      };

    case IMPORT_EPISODE_SUCCEEDED:
      return {
        ...state,
        saving: false,
      };

    case IMPORT_EPISODE_FAILED:
      return {
        ...state,
        errorMessage: action.error,
        saving: false,
      };

    case REPLACE_EPISODE_IN_PROGRESS:
      return {
        ...state,
        saving: true,
      };

    case GET_EPISODE_FORM_INITIAL_DATA_SUCCEEDED:
      return {
        ...state,
        episode: {
          ...state.episode,
          ...action.episodeSeedData,
          video: {
            ...state.episode.video,
            ...(action.episodeSeedData.video || {}),
          },
        },
        initialFormDataLoaded: true,
      };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.episode, getValidationRules(state.episode.state));
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };
    case OVERWRITEABLE_DUPLICATE_EPISODE_FOUND:
      return {
        ...state,
        duplicateEpisode: action.data.episodes[0],
      };
    case CLEAR_DUPLICATE_EPISODE:
      return {
        ...state,
        duplicateEpisode: null,
      };
    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
