import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import { getRoles, deleteRoleAndCloseConfirmation } from 'src/scripts/actions/role';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import RoleFormLoader from 'src/scripts/components/Admin/RoleFormLoader';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { getModuleName, hasReadyOnlyAccess } from 'src/scripts/lib/accessController';

export class RoleList extends React.Component {
  componentDidUpdate() {
    if (this.props.savedRole) {
      this.props.getRoles();
    }
  }

  getDisplayModuleName(module) {
    return `${getModuleName(module.referenceId)}${hasReadyOnlyAccess(module) ? ' (Read Only)' : ''}`;
  }

  render() {
    const actionList = (roleItem) => {
      return (
        <NavDropdownMenu
          key={roleItem.id}
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          id={`role-item-button-${roleItem.id}`}
        >
          <ModalItem key={roleItem.id} title="Edit Role" form={<RoleFormLoader roleId={roleItem.id} />} />
          {roleItem.id > 1 ? (
            <ModalItem
              title="Delete Role"
              form={
                <ConfirmationDialog
                  invitation={`Clicking Delete will permanently delete the "${roleItem.name}" role. Are you sure you wish to proceed?`}
                  ok={this.props.deleteRoleAndCloseConfirmation.bind(this, roleItem.id)}
                  buttonClass="danger"
                  buttonLabel="Delete"
                />
              }
            />
          ) : (
            ''
          )}
        </NavDropdownMenu>
      );
    };
    const list = {
      pageData: this.props.roles,
      totalCount: this.props.roles.length,
    };
    return (
      <div>
        <ol className="mi9-breadcrumb breadcrumb">
          <li>Roles</li>
        </ol>
        <ItemList id="list-role" list={list} getItems={this.props.getRoles}>
          <ItemListHeader dataField="name" label="Name" />
          <ItemListHeader
            dataFormat={(roleItem) => {
              return (
                roleItem.permissions.modules &&
                roleItem.permissions.modules
                  .map((module) => {
                    return this.getDisplayModuleName(module);
                  })
                  .join(', ')
              );
            }}
            label="Access"
          />
          <ItemListHeader
            dataFormat={(roleItem) => {
              return roleItem.azureRoleValue;
            }}
            label="Azure role value"
          />
          <ItemListHeader dataFormat={(roleItem) => actionList(roleItem)} label="Actions" />
        </ItemList>
      </div>
    );
  }
}

RoleList.propTypes = {
  getRoles: PropTypes.func,
  roles: PropTypes.array,
  deleteRoleAndCloseConfirmation: PropTypes.func,
  savedRole: PropTypes.object,
};

RoleList.defaultProps = {
  roles: [],
};

function mapStateToProps(state) {
  return {
    roles: state.roleList.roles,
    errorMessage: state.roleList.errorMessage,
    savedRole: state.roleList.savedRole,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteRoleAndCloseConfirmation: (roleId) => dispatch(deleteRoleAndCloseConfirmation(roleId)),
    getRoles: () => dispatch(getRoles()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);
