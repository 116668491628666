import { useRouterHistory } from 'react-router';
import { createHashHistory } from 'history';

const appHistory = useRouterHistory(createHashHistory)({ queryKey: false });

appHistory.listen((location) => {
  appHistory.location = location;
});

export default appHistory;
