import {
  GET_NETWORK_CLIP_SUCCEEDED,
  GET_NETWORK_CLIP_FAILED,
  DELETE_NETWORK_CLIP_SUCCEEDED,
  GET_NETWORK_CLIPS_SUCCEEDED,
  GET_NETWORK_CLIPS_FAILED,
  SAVE_NETWORK_CLIP_SUCCEEDED,
  SAVE_NETWORK_CLIP_FAILED,
} from 'src/scripts/actions/networkClip';
import { GET_SUBCATEGORY_SUCCEEDED, GET_SUBCATEGORY_FAILED } from 'src/scripts/actions/subcategory';
import { WORKFLOW_STATE_CHANGE_SUCCEEDED } from 'src/scripts/actions/workflow/tvWorkflow';
import { REAL_TIME_NETWORK_CLIP_UPDATED } from 'src/scripts/actions/realTimeNotification';
import {
  BULK_ACTION_NETWORK_CLIP_UPDATED,
  BULK_ACTION_NETWORK_CLIP_DELETED,
} from 'src/scripts/actions/bulkAction';
import { immutableRemoveById, immutableUpdateArrayItem } from 'src/scripts/lib/util';

export const defaultState = {
  clips: [],
  offset: 0,
  count: 0,
  errorMessage: null,
  savedClip: null,
  detailsView: {
    clip: null,
    errorMessage: null,
  },
  subcategory: null,
};

function isCurrentClipSaved(currentClip, savedClip) {
  return currentClip && savedClip.id === currentClip.id;
}

function isEqualToCurrentClipId(id, currentClip) {
  return currentClip && id === currentClip.id;
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_NETWORK_CLIP_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          clip: action.data.clip,
          errorMessage: null,
        },
        savedClip: null,
      };
    case GET_NETWORK_CLIP_FAILED:
      return {
        ...state,
        detailsView: {
          clip: null,
          errorMessage: action.error,
        },
        savedClip: null,
      };
    case DELETE_NETWORK_CLIP_SUCCEEDED:
      return {
        ...state,
        clips: immutableRemoveById(state.clips, action.id),
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.id, state.detailsView.clip) ? null : state.detailsView.clip,
        },
      };
    case GET_NETWORK_CLIPS_SUCCEEDED:
      return {
        ...state,
        clips: action.data.clips,
        count: action.data.count,
        offset: action.offset,
        savedClip: null,
      };
    case GET_NETWORK_CLIPS_FAILED:
      return { ...state, errorMessage: action.error };
    case SAVE_NETWORK_CLIP_SUCCEEDED:
      if (isCurrentClipSaved(state.detailsView.clip, action.data.clip)) {
        return {
          ...state,
          savedClip: action.data.clip,
          detailsView: {
            clip: action.data.clip,
            errorMessage: null,
          },
        };
      }
      return { ...state, savedClip: action.data.clip };
    case SAVE_NETWORK_CLIP_FAILED:
      return { ...state, errorMessage: action.error };
    case BULK_ACTION_NETWORK_CLIP_UPDATED:
      return {
        ...state,
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.data.clip.id, state.detailsView.clip)
            ? action.data.clip
            : state.detailsView.clip,
        },
        clips: immutableUpdateArrayItem(state.clips, action.data.clip),
      };
    case BULK_ACTION_NETWORK_CLIP_DELETED:
      const updatedClipsList = immutableRemoveById(state.clips, action.data.id);
      return {
        ...state,
        clips: updatedClipsList,
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.data.id, state.detailsView.clip)
            ? null
            : state.detailsView.clip,
        },
        count: state.clips.length === updatedClipsList.length ? state.count : --state.count,
      };
    case REAL_TIME_NETWORK_CLIP_UPDATED:
      return { ...state, clips: immutableUpdateArrayItem(state.clips, action.data) };
    case WORKFLOW_STATE_CHANGE_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          clip: action.data.clip,
          errorMessage: null,
        },
      };
    case GET_SUBCATEGORY_SUCCEEDED:
      return {
        ...state,
        subcategory: action.data.subcategory,
      };

    case GET_SUBCATEGORY_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };
    default:
      return state;
  }
}
