import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { get as getClips } from 'src/scripts/actions/clip';
import { getEpisodes } from 'src/scripts/actions/episode';
import constants from 'src/scripts/constants';
import { workflowErrorNotification } from 'src/scripts/actions/eventNotification';

export const WORKFLOW_STATE_CHANGE_SUCCEEDED = 'WORKFLOW_STATE_CHANGE_SUCCEEDED';
export const WORKFLOW_STATE_CHANGE_STARTED = 'WORKFLOW_STATE_CHANGE_STARTED';
export const WORKFLOW_STATE_CHANGE_FAILED = 'WORKFLOW_STATE_CHANGE_FAILED';

function refresh(resource, offset, resourceName) {
  const payload = {
    seasonId: resource.seasonId,
    offset,
    limit: constants.pagination.pageSize,
  };
  switch (resourceName.toLowerCase()) {
    case 'clip':
      return getClips(payload);
    default:
      return getEpisodes(payload);
  }
}

export const vmsApiActionEndpointMap = {
  approve: 'approved',
  activate: 'activated',
  reschedule: 'rescheduled',
  archive: 'archived',
  cancel: 'cancelled',
  expire: 'expired',
};

function executeWorkflowAction(action, resource, offset, resourceName) {
  return (dispatch) => {
    dispatch({ type: WORKFLOW_STATE_CHANGE_STARTED });
    const onSuccess = (workflowActionResult) => {
      dispatch({
        type: WORKFLOW_STATE_CHANGE_SUCCEEDED,
        data: workflowActionResult,
      });
      dispatch(refresh(resource, offset, resourceName));
    };
    const onError = (response) => {
      dispatch({
        type: WORKFLOW_STATE_CHANGE_FAILED,
        data: resource,
      });
      dispatch(
        workflowErrorNotification(
          response.responseJSON.message,
          action,
          resourceName.toLowerCase(),
          resource.id
        )
      );
    };
    tvVmsApi
      .notifyWorkflowAction(vmsApiActionEndpointMap[action], resourceName.toLowerCase(), resource.id)
      .then(onSuccess, onError);
  };
}

export function approve(resource, offset, resourceName) {
  return executeWorkflowAction('approve', resource, offset, resourceName);
}

export function activate(resource, offset, resourceName) {
  return executeWorkflowAction('activate', resource, offset, resourceName);
}

export function reschedule(resource, offset, resourceName) {
  return executeWorkflowAction('reschedule', resource, offset, resourceName);
}

export function archive(resource, offset, resourceName) {
  return executeWorkflowAction('archive', resource, offset, resourceName);
}

export function expire(resource, offset, resourceName) {
  return executeWorkflowAction('expire', resource, offset, resourceName);
}

export function cancel(resource, offset, resourceName) {
  return executeWorkflowAction('cancel', resource, offset, resourceName);
}
