import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import ClipFormLoader from 'src/scripts/components/Clip/ClipFormLoader';
import NetworkClipFormLoader from 'src/scripts/components/NetworkClip/NetworkClipFormLoader';
import { isItemInBulkActionQueue } from 'src/scripts/lib/bulkWorkflowActionsProvider';
import { getAvailableBulkWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import RescheduleModal from 'src/scripts/components/MediaItem/RescheduleModal';
import { CLIP, EPISODE, NETWORK_CLIP } from 'src/scripts/lib/modelNames';

export class RescheduleButton extends React.Component {
  getEditForm() {
    const editForms = {
      [EPISODE]: <EpisodeFormLoader episodeId={Number(this.props.selectedItems[0].id)} />,
      [CLIP]: <ClipFormLoader clipId={Number(this.props.selectedItems[0].id)} />,
      [NETWORK_CLIP]: <NetworkClipFormLoader clipId={Number(this.props.selectedItems[0].id)} />,
    };
    return editForms[this.props.modelName];
  }

  isSingleSelectedInBulkActionsQueue() {
    return isItemInBulkActionQueue(
      this.props.bulkActionStatus,
      this.props.processingActionType,
      this.props.processingModelName,
      this.props.unprocessed,
      this.props.modelName.slice(0, -1),
      this.props.selectedItems[0]
    );
  }

  isDisabled() {
    return (
      this.props.selectedItems.length !== 1 ||
      getAvailableBulkWorkflowActions(this.props.selectedItems, this.props.modelName).indexOf(
        'reschedule_and_save'
      ) === -1 ||
      this.isSingleSelectedInBulkActionsQueue()
    );
  }

  render() {
    if (this.isDisabled()) {
      return (
        <Button bsSize="small" disabled>
          Reschedule
        </Button>
      );
    }
    return <RescheduleModal form={this.getEditForm()} isButton buttonClass="btn btn-sm btn-default" />;
  }
}

RescheduleButton.propTypes = {
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  bulkActionStatus: PropTypes.string,
  unprocessed: PropTypes.array,
  processingActionType: PropTypes.string,
  processingModelName: PropTypes.string,
};

export default RescheduleButton;
