import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Input } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import Dropdown from '../../Dropdown';
import classificationCodes from 'src/scripts/constants/classificationCodes';

class UpNextSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidationErrors: {},
      errorMessages: [],
    };

    this.onUpNextEventPropertyChange = this.onUpNextEventPropertyChange.bind(this);
    this.validateAndGoLive = this.validateAndGoLive.bind(this);
  }

  onUpNextEventPropertyChange(propertyName, updatedValue) {
    this.props.onUpNextEventPropertyChange(propertyName, updatedValue);
  }

  getValidationErrors() {
    const validationErrors = {};
    if (!this.props.upNextEvent.name) {
      validationErrors.nameRequired = 'Event must have a name.';
    }

    return validationErrors;
  }

  validateAndGoLive() {
    const validationErrors = this.getValidationErrors();

    if (_.isEmpty(validationErrors)) {
      this.props.goLive({ ...this.props.upNextEvent });
      this.props.onUpNextSectionFormClear();
      this.setState({
        formValidationErrors: {},
        errorMessages: [],
      });
    } else {
      this.setState({
        formValidationErrors: validationErrors,
        errorMessages: Object.values(validationErrors),
      });
    }
  }

  hasValidationErrors() {
    return this.state.errorMessages.length > 0;
  }

  renderValidationWarning() {
    if (this.hasValidationErrors()) {
      return <FormErrorMessage message={this.state.errorMessages.join('\n')} />;
    }
    return null;
  }

  render() {
    return (
      <div>
        <Input
          labelClassName="required"
          type="text"
          label="Up Next Event Name"
          data-pw="up-next-event-name"
          placeholder="Enter name"
          maxLength="100"
          onChange={(event) => this.onUpNextEventPropertyChange('name', event.target.value)}
          value={this.props.upNextEvent.name}
        />
        <Input
          type="text"
          label="Up Next Event Subtitle"
          data-pw="up-next-event-subtitle"
          placeholder="Add a subtitle of the up next event"
          maxLength="100"
          onChange={(event) => this.onUpNextEventPropertyChange('subtitle', event.target.value)}
          value={this.props.upNextEvent.subtitle}
        />
        <Input
          type="textarea"
          label="Description"
          data-pw="up-next-event-description"
          maxLength="2000"
          placeholder="Add a description of the up next event"
          onChange={(event) => this.onUpNextEventPropertyChange('description', event.target.value)}
          value={this.props.upNextEvent.description}
        />
        <Input
          type="select"
          label="Genre"
          data-pw="up-next-event-genre"
          onChange={(event) => this.onUpNextEventPropertyChange('genreId', +event.target.value)}
          value={this.props.upNextEvent.genreId}
        >
          <option value={null}>Select a genre</option>
          {(this.props.genres || []).map((genre) => {
            return (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            );
          })}
        </Input>
        <Dropdown
          data-pw="up-next-event-classification-code"
          items={classificationCodes}
          // eslint-disable-next-line react/jsx-boolean-value
          isOptionValueNull={true}
          label="Classification Rating"
          onChange={(event) => this.onUpNextEventPropertyChange('classificationCode', event.target.value)}
          currentValue={this.props.upNextEvent.classificationCode}
        />

        <div
          className={`form-group ${this.props.validationErrors['upNextEvent.programStartTime'] && 'error'}`}
        >
          <label>Start Date & Time</label>
          <DateTimePicker
            onChange={(date) => this.onUpNextEventPropertyChange('programStartTime', date || null)}
            value={this.props.upNextEvent.programStartTime}
          />
        </div>
        <div className={`form-group ${this.props.validationErrors['upNextEvent.programEndTime'] && 'error'}`}>
          <label>End Date & Time</label>
          <DateTimePicker
            onChange={(date) => this.onUpNextEventPropertyChange('programEndTime', date || null)}
            value={this.props.upNextEvent.programEndTime}
          />
        </div>
        <Button
          disabled={!this.props.isInEditMode}
          onClick={this.validateAndGoLive}
          role="button"
          style={{ marginBottom: '15px' }}
        >
          Go Live
        </Button>
        {this.renderValidationWarning()}
      </div>
    );
  }
}

UpNextSection.propTypes = {
  isInEditMode: PropTypes.bool,
  upNextEvent: PropTypes.object,
  onUpNextEventPropertyChange: PropTypes.func,
  onUpNextSectionFormClear: PropTypes.func,
  goLive: PropTypes.func,
  genres: PropTypes.array,
  validationErrors: PropTypes.object.isRequired,
};

export default UpNextSection;
