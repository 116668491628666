import PropTypes from 'prop-types';
import React from 'react';
import LoadingIcon from 'src/scripts/components/LoadingIcon';
import StatefulFontAwesomeIcon from 'src/scripts/components/StatefulFontAwesomeIcon';

const DeleteButton = (props) => {
  const renderDeleteButton = () => (
    <button
      className="btn btn-link delete-caption"
      onClick={props.onClick}
      disabled={props.isDeleting || props.disabled}
    >
      {props.children}
    </button>
  );
  const renderStateDisplay = () => {
    if (props.isDeleting) return <LoadingIcon loading />;
    if (props.hasJustDeleted)
      return (
        <div className="btn disabled">
          <StatefulFontAwesomeIcon successful />
        </div>
      );
    if (props.hasJustFailedToDelete)
      return (
        <div className="btn disabled">
          <StatefulFontAwesomeIcon successful={false} /> {props.errorMessage}
        </div>
      );
    return null;
  };

  return (
    <div>
      {renderDeleteButton()}
      {renderStateDisplay()}
    </div>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  hasJustDeleted: PropTypes.bool,
  hasJustFailedToDelete: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

DeleteButton.defaultProps = {
  isDeleting: false,
  hasJustDeleted: false,
  hasJustFailedToDelete: false,
  errorMessage: '',
  disabled: false,
  children: null,
};

export default DeleteButton;
