import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Input } from 'react-bootstrap';

class BulkEditDrm extends React.Component {
  render() {
    return (
      <Panel
        id="panel-drm"
        header="DRM"
        bsStyle="primary"
        expanded={false}
        eventKey="4"
        className="schedulePanel"
      >
        <Input
          type="radio"
          id="apply-to-all-drm-radio"
          label="Apply To All"
          checked={!this.props.keepExistingDrmValue && !this.props.removeDrmValueForAll}
          onClick={() => this.props.handleApplyDrmValueToAll(true)}
        />
        <Input
          type="radio"
          id="remove-from-all-drm-radio"
          label="Remove From All"
          checked={!this.props.keepExistingDrmValue && !this.props.applyDrmValueToAll}
          onClick={() => this.props.handleRemoveDrmValueForAll(true)}
        />
        <Input
          type="radio"
          id="keep-existing-drm-radio"
          label="Keep existing"
          checked={this.props.keepExistingDrmValue}
          onClick={() => this.props.handleKeepExistingDrmValue(true)}
        />
      </Panel>
    );
  }
}

BulkEditDrm.propTypes = {
  keepExistingDrmValue: PropTypes.bool,
  removeDrmValueForAll: PropTypes.bool,
  applyDrmValueToAll: PropTypes.bool,
  handleApplyDrmValueToAll: PropTypes.func,
  handleRemoveDrmValueForAll: PropTypes.func,
  handleKeepExistingDrmValue: PropTypes.func,
};

export default BulkEditDrm;
