import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'react-bootstrap';
import _ from 'lodash';

function isAnyOptionSelected(options) {
  return !!_.filter(options, (option) => option.checked === true).length;
}

class ExternalFeed extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {
      anyOptionSelected: isAnyOptionSelected(this.props.options),
    };
  }

  onChange(index, event) {
    const tags = _.cloneDeep(this.props.options);
    tags[index].checked = event.target.checked;
    this.setState({
      anyOptionSelected: isAnyOptionSelected(tags),
    });
    this.props.onChange(tags);
  }

  render() {
    return (
      <div id="panel-external-feed" className="form-group">
        <label className="control-label">External Feed</label>
        <div className="option-list">
          {this.props.options.map((option, index) => {
            return (
              <span className="option-list__checkbox" key={index}>
                <Input
                  type="checkbox"
                  id={`check-external-feed-${index}`}
                  label={option.label}
                  value={option.value}
                  onChange={this.onChange.bind(null, index)}
                  checked={option.checked}
                />
              </span>
            );
          })}
          <span className={`${this.state.anyOptionSelected ? 'option-list__warning' : 'hide'}`}>
            <i className="fa fa-info-circle fa-lg" aria-hidden="true"></i> By checking this option you are
            confirming that this video was filmed by Nine and can be used in the external feed.{' '}
          </span>
        </div>
      </div>
    );
  }
}

ExternalFeed.defaultProps = {
  options: [],
};

ExternalFeed.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
};

export default ExternalFeed;
