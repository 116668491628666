import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import ViewLoader from 'src/scripts/components/ViewLoader';
import CollectionActions from 'src/scripts/components/Collection/CollectionActions';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { isDefaultImage } from 'src/scripts/lib/util';
import InactiveWarningWithTooltip from 'src/scripts/components/Collection/InactiveWarningWithTooltip';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { getCollectionById } from 'src/scripts/actions/collection';
import ActiveEpisodeState from 'src/scripts/components/Collection/ActiveEpisodeState';
import { CUSTOM_COLLECTION_TYPE } from 'src/scripts/lib/collectionTypes';
import history from 'src/scripts/lib/history';
import { TV_PATH } from 'src/scripts/lib/accessController';
import { COLLECTION_PARAMS } from './CollectionFormLoader';
import { getContentOrDashes } from 'src/scripts/lib/stringFormatter';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import {
  getCollectionWorkflow,
  hasUnpublishedTvSeriesListChanges,
  workflowColours,
} from '../../lib/collection';

const halfWidthColumn = 6;
const fullWidthColumn = 12;

function getLatestSeason(seasons) {
  return seasons.reduce((latestSeason, currentSeason) => {
    if (latestSeason.seasonNumber > currentSeason.seasonNumber) {
      return latestSeason;
    }

    return currentSeason;
  });
}

function getLatestActiveSeason(seasons) {
  const activeSeasons = seasons.filter((season) => season.episodeActiveCount > 0);
  if (activeSeasons.length) {
    return getLatestSeason(activeSeasons);
  }
  return null;
}

export class CollectionDetailsView extends React.Component {
  getCollection = async (collectionId) => this.props.getCollection(collectionId, COLLECTION_PARAMS);

  componentDidMount() {
    const { collectionId } = this.props.params;
    this.getCollection(collectionId);
  }

  getImageUrl = (tvSeriesItem) => {
    const imageNotFound = IMAGE_NOT_FOUND_URL;
    if (tvSeriesItem.containsSeason.length) {
      const tvSeriesLastSeason = getLatestActiveSeason(tvSeriesItem.containsSeason);
      if (tvSeriesLastSeason && tvSeriesLastSeason.image) {
        return isDefaultImage(tvSeriesLastSeason.image) ? imageNotFound : tvSeriesLastSeason.image.url;
      }
    }
    return imageNotFound;
  };

  renderCollectionPreviewItems = (fieldName = 'tvSeries') => {
    const tvSeries = this.props.collection[fieldName];
    if (tvSeries.every((series) => series.activeEpisodeCount === 0)) {
      return 'No Active Tv Series';
    }
    return (
      <div className="collection-preview-wrapper">
        {tvSeries.map((series) => {
          if (series.activeEpisodeCount > 0 && series.isFutureActive === true) {
            const imageUrl = this.getImageUrl(series);
            return (
              <div className="tv-series-preview">
                <img className="tv-series-preview-image" src={imageUrl} />
                <a className="tv-series-preview-link" href={`#/tv/tv-series/${series.id}/seasons`}>
                  {series.name}
                </a>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  renderListGroup = (listGroupItems, listGroupItemClassName, fallbackText) => {
    return listGroupItems.length > 0 ? (
      <ListGroup>
        {listGroupItems.map((tvSeries) => {
          return (
            <div className="series-list-wrapper">
              <ListGroupItem className={listGroupItemClassName}>
                {tvSeries.name}
                <div className="active-episode-state-wrapper">
                  <ActiveEpisodeState
                    activeEpisodeCount={tvSeries.activeEpisodeCount}
                    isFutureActive={tvSeries.isFutureActive}
                  />
                </div>
              </ListGroupItem>
            </div>
          );
        })}
      </ListGroup>
    ) : (
      fallbackText
    );
  };

  renderCollectionWorkflow = () => {
    const workflow = getCollectionWorkflow(this.props.collection);
    const workflowColour = workflowColours[workflow];

    return (
      <span
        className="collection-workflow"
        style={{ background: `${workflowColour}`, color: 'white', borderRadius: '4px', padding: '4px' }}
      >
        {workflow}
      </span>
    );
  };

  renderCollectionDetailsView = () => {
    if (this.props.collection) {
      return [
        <ContextNavigation
          breadcrumbList={[<a href="/#/tv/collections">Collections</a>, this.props.collection.name]}
        />,
        <Row className="details-toolbar">
          <CollectionActions
            displayType="button-bar"
            collection={this.props.collection}
            afterDeletion={() => {
              history.push({ state: null, pathname: `/${TV_PATH}/collections` }, { query: { page: 1 } });
            }}
            afterUpdate={() => this.getCollection(this.props.params.collectionId)}
          />
        </Row>,
        <Row>
          <Col md={this.props.collection.type === CUSTOM_COLLECTION_TYPE ? fullWidthColumn : halfWidthColumn}>
            <Panel header="Metadata" collapsible defaultExpanded>
              <DetailsRowItem id="collection-name" label="Name">
                {this.props.collection.name}
              </DetailsRowItem>
              <DetailsRowItem id="collection-type" label="Type">
                {this.props.collection.type}
              </DetailsRowItem>
              <DetailsRowItem id="collection-workflow" label="Workflow">
                {this.renderCollectionWorkflow()}
              </DetailsRowItem>
              <DetailsRowItem id={'collection-programming-notes'} label={'Programming Notes'}>
                {getContentOrDashes(this.props.collection.programmingNotes)}
              </DetailsRowItem>
              <DetailsRowItem id="collection-active-series-count" label="Active TV Series">
                {this.props.collection.activeTvSeriesCount}
                {this.props.collection.containsFutureInactiveTvSeries ? (
                  <span>
                    <InactiveWarningWithTooltip />
                  </span>
                ) : null}
              </DetailsRowItem>
              {this.props.collection.type === CUSTOM_COLLECTION_TYPE ? (
                <DetailsRowItem
                  id="collection-recommending-series-count"
                  label="TV Series Using this Collection"
                >
                  {this.props.collection.activeRecommendingTvSeriesCount}
                  {this.props.collection.containsFutureInactiveRecommendingTvSeries ? (
                    <span>
                      <InactiveWarningWithTooltip />
                    </span>
                  ) : null}
                </DetailsRowItem>
              ) : null}
            </Panel>
          </Col>
          <Col md={fullWidthColumn}>
            <Panel header="Published Collection Preview" collapsible defaultExpanded>
              {this.renderCollectionPreviewItems('tvSeries')}
            </Panel>
          </Col>
          {hasUnpublishedTvSeriesListChanges(this.props.collection) ? (
            <Col md={fullWidthColumn}>
              <Panel header="Draft Collection Preview" collapsible defaultExpanded>
                {this.renderCollectionPreviewItems('draftTvSeries')}
              </Panel>
            </Col>
          ) : null}
          <Col
            md={hasUnpublishedTvSeriesListChanges(this.props.collection) ? halfWidthColumn : fullWidthColumn}
          >
            <Panel id="published-tv-series-panel" header="Published TV Series">
              {this.renderListGroup(
                this.props.collection.tvSeries,
                'active-series-list-item',
                'No Published Tv Series'
              )}
            </Panel>
          </Col>
          {hasUnpublishedTvSeriesListChanges(this.props.collection) ? (
            <Col md={halfWidthColumn}>
              <Panel id="draft-tv-series-panel" header="Draft TV Series" className="bg-light-grey">
                {this.renderListGroup(
                  this.props.collection.draftTvSeries,
                  'active-series-list-item',
                  'No Draft Tv Series'
                )}
              </Panel>
            </Col>
          ) : null}

          {this.props.collection.type === CUSTOM_COLLECTION_TYPE ? (
            <Col md={fullWidthColumn}>
              <Panel id="recommending-series-panel" header="TV Series Using this Collection">
                {this.renderListGroup(
                  this.props.collection.recommendingTvSeries,
                  'series-using-collection-list-item',
                  'No Tv Series using this Collection'
                )}
              </Panel>
            </Col>
          ) : null}
        </Row>,
      ];
    }
    return null;
  };

  render() {
    return (
      <ViewLoader loading={!this.props.collection} error={this.props.error}>
        {this.renderCollectionDetailsView()}
      </ViewLoader>
    );
  }
}

CollectionDetailsView.propTypes = {
  params: PropTypes.shape({
    collectionId: PropTypes.string,
  }),
  collection: PropTypes.object,
  error: PropTypes.object,
  getCollection: PropTypes.func,
};

const mapStateToProps = (state) => ({
  collection: state.collectionDetailsView.collection,
  error: state.collectionDetailsView.error,
});

const mapDispatchToProps = (dispatch) => ({
  getCollection: (collectionId, params) => dispatch(getCollectionById(collectionId, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetailsView);
