import React from 'react';
import PropTypes from 'prop-types';
import environment from 'config/environment';

export default class ImageFactoryPreview extends React.Component {
  static propTypes = {
    s3Path: PropTypes.string,
  };

  render() {
    const liveEventConfig = environment.imageFactory.liveEvent;
    const baseUrl = `${environment.imageFactory.url}/${liveEventConfig.prefix}/${this.props.s3Path}`;

    if (!this.props.s3Path) {
      return null;
    }

    const imageTypes = [
      { key: liveEventConfig.liveImageKey, alt: 'Live Preview' },
      { key: liveEventConfig.upcomingImageKey, alt: 'Upcoming Preview' },
    ];

    return (
      <div className="image-factory-preview-container">
        <label className="control-label">Image factory previews</label>
        <div className="preview-images">
          {imageTypes.map((image) => (
            <img key={image.key} alt={image.alt} src={`${baseUrl}?if-image-type=${image.key}`} />
          ))}
        </div>
      </div>
    );
  }
}
