import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';

class DetailsList extends React.Component {
  render() {
    return (
      <Table bordered condensed className="details-table">
        <tbody>
          {this.props.itemList.map((item, index) => {
            return (
              <tr key={index} className="item-list-row">
                <td className="details-table-label-column">{item.label}</td>
                <td>{item.value}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}

DetailsList.propTypes = {
  itemList: PropTypes.array,
};

export default DetailsList;
