import PropTypes from 'prop-types';
import React from 'react';

export default class DetailsRowItem extends React.Component {
  render() {
    return (
      <div className="details-row row">
        <div className="details-key row">{this.props.label}</div>
        <div className="details-value row">{this.props.children}</div>
      </div>
    );
  }
}

DetailsRowItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
};
