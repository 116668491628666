import {
  GET_COLLECTIONS_SUCCEEDED,
  GET_COLLECTIONS_FAILED,
  DELETE_COLLECTION_SUCCEEDED,
} from 'src/scripts/actions/collection';

import { CREATE_COLLECTION_SUCCEEDED } from 'src/scripts/actions/addCollection';
import { PUBLISH_COLLECTION_SUCCEEDED } from '../actions/collection';

export const initialState = {
  collections: [],
  count: 0,
  errorMessage: null,
  savedCollection: null,
};

const updateCollections = (state, updatedCollections, totalCollections) => ({
  ...state,
  collections: updatedCollections,
  count: totalCollections,
});

const updateError = (state, error) => ({
  ...state,
  errorMessage: error,
});

const removeCollectionById = (state, collectionId) => ({
  ...state,
  collections: state.collections.filter((collection) => collection.id !== collectionId),
});

const updateSavedCollection = (state, newCollection) => ({
  ...state,
  savedCollection: newCollection,
});

const removeSavedCollection = (state) => ({
  ...state,
  savedCollection: null,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTIONS_SUCCEEDED:
      const updatedState = updateCollections(state, action.data.collections, action.data.count);
      return removeSavedCollection(updatedState);

    case GET_COLLECTIONS_FAILED:
      return updateError(state, action.error);

    case DELETE_COLLECTION_SUCCEEDED:
      return removeCollectionById(state, action.id);

    case CREATE_COLLECTION_SUCCEEDED:
      return updateSavedCollection(state, action.data);

    case PUBLISH_COLLECTION_SUCCEEDED:
      return updateSavedCollection(state, action.data);

    default:
      return state;
  }
}
