import PropTypes from 'prop-types';
import React from 'react';
import { Row, ButtonToolbar } from 'react-bootstrap';
import {
  isDeleteEnabled,
  isEditEnabled,
  isLaunchEnabled,
  isEndEnabled,
} from 'src/scripts/lib/liveEventWorkflowActionsProvider';
import ModalItem from 'src/scripts/components/ModalItem';
import LiveEventFormLoader from 'src/scripts/components/LiveEvent/LiveEventFormLoader';
import ConfirmableActionButton from 'src/scripts/components/ConfirmableActionButton';

export default class LiveEventDetailsToolbar extends React.Component {
  render() {
    return (
      <Row className="details-toolbar">
        <ButtonToolbar className="pull-right toolbar-buttons">
          <ConfirmableActionButton
            ref="launchModalItem"
            buttonLabel="Launch Event"
            buttonClass={`launchModalItem workflow-menu-item btn btn-success ${
              isLaunchEnabled(this.props.liveEvent) ? '' : 'hidden'
            }`}
            confirmButtonLabel="Launch Event"
            confirmationText="Click 'Launch Event' to immediately start streaming this Live Event. If not manually stopped, the stream will automatically finish after 4 hours."
            confirmButtonClass="success"
            action={this.props.launchInstantLiveEvent.bind(
              this,
              this.props.liveEvent.library,
              this.props.liveEvent.id
            )}
          />
          <ConfirmableActionButton
            ref="endModalItem"
            buttonLabel="End Event"
            buttonClass={`endModalItem workflow-menu-item btn btn-danger ${
              isEndEnabled(this.props.liveEvent) ? '' : 'hidden'
            }`}
            confirmButtonLabel="End Event"
            confirmationText="Click 'End Event' to immediately stop streaming this Live Event."
            confirmButtonClass="danger"
            action={this.props.endInstantLiveEvent.bind(
              this,
              this.props.liveEvent.library,
              this.props.liveEvent.id
            )}
          />
          <ModalItem
            title="Edit"
            ref="editModalItem"
            className={`editModalItem workflow-menu-item btn btn-default ${
              isEditEnabled(this.props.liveEvent) ? '' : 'disabled'
            }`}
            component="span"
            form={
              <LiveEventFormLoader
                liveEventId={this.props.liveEvent.id}
                library={this.props.liveEvent.library}
              />
            }
          />
          <ConfirmableActionButton
            ref="deleteModalItem"
            buttonLabel="Delete Live Event"
            buttonClass={`deleteModalItem workflow-menu-item btn btn-default ${
              isDeleteEnabled(this.props.liveEvent) ? '' : 'disabled'
            }`}
            confirmButtonLabel="Delete"
            confirmationText={`Clicking Delete will permanently delete the "${this.props.liveEvent.name}" live event, and any associated assets. Are you sure you wish to proceed?`}
            action={this.props.deleteLiveEvent.bind(
              this,
              this.props.liveEvent.library,
              this.props.liveEvent.id
            )}
          />
        </ButtonToolbar>
      </Row>
    );
  }
}

LiveEventDetailsToolbar.propTypes = {
  liveEvent: PropTypes.object,
  deleteLiveEvent: PropTypes.func,
  launchInstantLiveEvent: PropTypes.func,
  endInstantLiveEvent: PropTypes.func,
  someOtherProp: PropTypes.string,
};
