import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

const ItemTypes = { CARD: 'card' };

const dragSourceSpec = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const dragTargetSpec = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

const reactDndProps = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
};

export class DragDropCard extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.any.isRequired,
    moveCard: PropTypes.func.isRequired,
    card: PropTypes.object.isRequired,
    renderCard: PropTypes.func.isRequired,
    ...reactDndProps,
  };

  // eslint-disable-next-line no-warning-comments
  /* TODO: apply the law of demeter to the renderCard function */
  render() {
    const setTransparentIfDragging = this.props.isDragging
      ? 'sortable-drag-drop-card-underlying-drag-source'
      : '';
    return this.props.connectDragSource(
      this.props.connectDropTarget(
        <div className={`sortable-drag-drop-card ${setTransparentIfDragging}`}>
          {this.props.renderCard(this.props.index, this.props.card, this.props)}
        </div>
      )
    );
  }
}

const targetCollect = (connect) => ({
  connectDropTarget: connect.dropTarget(),
});
const sourceCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

export default DropTarget(
  ItemTypes.CARD,
  dragTargetSpec,
  targetCollect
)(DragSource(ItemTypes.CARD, dragSourceSpec, sourceCollect)(DragDropCard));
