import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { errorNotification } from 'src/scripts/actions/eventNotification';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { closeModal } from 'src/scripts/actions/modalItem';

export const GET_SEASONS_SUCCEEDED = 'GET_SEASONS_SUCCEEDED';
export const GET_SEASONS_FAILED = 'GET_SEASONS_FAILED';
export const FORM_GET_SEASONS_SUCCEEDED = 'FORM_GET_SEASONS_SUCCEEDED';
export const FORM_GET_SEASONS_FAILED = 'FORM_GET_SEASONS_FAILED';
export const SAVE_SEASON_FAILED = 'SAVE_SEASON_FAILED';
export const SAVE_SEASON_STARTED = 'SAVE_SEASON_STARTED';
export const SAVE_SEASON_SUCCEEDED = 'SAVE_SEASON_SUCCEEDED';
export const SEASON_UPDATED = 'SEASON_UPDATED';
export const GET_SEASON_AND_ASSOCIATED_DATA_SUCCEEDED = 'GET_SEASON_AND_ASSOCIATED_DATA_SUCCEEDED';
export const GET_SEASON_AND_ASSOCIATED_DATA_FAILED = 'GET_SEASON_AND_ASSOCIATED_DATA_FAILED';
export const DELETE_SEASON_SUCCEEDED = 'DELETE_SEASON_SUCCEEDED';
export const GET_CURRENT_SEASON_SUCCEEDED = 'GET_CURRENT_SEASON_SUCCEEDED';
export const GET_CURRENT_SEASON_FAILED = 'GET_CURRENT_SEASON_FAILED';
export const RESET_SEASON_DETAILS_VIEW = 'RESET_SEASON_DETAILS_VIEW';

export function getSeasons(params, successAction = GET_SEASONS_SUCCEEDED, failAction = GET_SEASONS_FAILED) {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit;
  const sort = params.sort ? params.sort : undefined;
  const request = {
    tvSeriesId: params.tvSeriesId,
    offset,
    limit,
    sort,
  };

  return (dispatch) => {
    const onSuccess = (data) => {
      return dispatch({
        type: successAction,
        data,
        offset,
      });
    };
    const onError = (response) => {
      return dispatch({
        type: failAction,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getSeasons(request).then(onSuccess, onError);
  };
}

export function getSeasonsForForm(params) {
  return getSeasons(params, FORM_GET_SEASONS_SUCCEEDED, FORM_GET_SEASONS_FAILED);
}

export function saveSeason(season) {
  return (dispatch) => {
    const saveMethod = season.id ? tvVmsApi.updateSeason : tvVmsApi.createSeason;
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_SEASON_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      return dispatch({
        type: SAVE_SEASON_FAILED,
        error: response.responseJSON.message,
      });
    };
    dispatch({
      type: SAVE_SEASON_STARTED,
    });
    return saveMethod(season).then(onSuccess, onError);
  };
}

export function updateSeason(season) {
  return {
    type: SEASON_UPDATED,
    season,
  };
}

export function getCurrentSeason(seasonId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      return dispatch({
        type: GET_CURRENT_SEASON_SUCCEEDED,
        data,
      });
    };
    const onError = () => {
      dispatch({
        type: GET_CURRENT_SEASON_FAILED,
      });
      dispatch(
        errorNotification({
          message: 'Current season information could not be retrieved. Please refresh the page.',
        })
      );
    };
    return tvVmsApi.getSeasonById(seasonId).then(onSuccess, onError);
  };
}

export function getSeasonAndAssociatedData(seasonId) {
  return (dispatch) => {
    const onError = (error) => {
      dispatch({
        type: GET_SEASON_AND_ASSOCIATED_DATA_FAILED,
        error: error.responseJSON.message,
      });
    };
    const onSuccess = (data) => {
      dispatch({
        type: GET_SEASON_AND_ASSOCIATED_DATA_SUCCEEDED,
        data,
      });
    };
    const loadChannels = (data) => {
      return tvVmsApi.getTVChannels().then((channels) => ({ ...data, ...channels }));
    };

    if (seasonId) {
      return tvVmsApi
        .getSeasonById(seasonId)
        .then((seasonData) => loadChannels(seasonData))
        .then(onSuccess, onError);
    }
    return loadChannels({}).then(onSuccess, onError);
  };
}

export function deleteSeasonAndCloseConfirmation(seasonId, successCallback) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_SEASON_SUCCEEDED,
        id: seasonId,
      });
      dispatch(closeModal());
      if (successCallback) successCallback();
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteSeason(seasonId).then(onSuccess, onError);
  };
}

export function resetDetailsView() {
  return { type: RESET_SEASON_DETAILS_VIEW };
}
