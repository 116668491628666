import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'react-bootstrap';
import CollectionFormLoader from 'src/scripts/components/Collection/CollectionFormLoader';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import {
  deleteCollectionAndCloseConfirmation,
  publishCollectionAndCloseConfirmation,
} from 'src/scripts/actions/collection';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import { connect } from 'react-redux';
import { hasUnpublishedTvSeriesListChanges } from '../../lib/collection';

export class CollectionActions extends React.Component {
  isCollectionDeletable = () => {
    switch (this.props.collection.type) {
      case 'GENRE':
        return false;
      case 'DEFAULT':
        return false;
      default:
        return true;
    }
  };

  getModalItemClassName = () => {
    if (this.props.displayType === 'button-bar') return 'btn btn-default';
    return '';
  };

  getModalItemComponent = () => {
    if (this.props.displayType === 'button-bar') return 'span';
    return '';
  };

  renderEditAction = () => {
    return (
      <ModalItem
        key={this.props.collection.id}
        title="Edit Collection"
        className={this.getModalItemClassName()}
        component={this.getModalItemComponent()}
        form={
          <CollectionFormLoader
            collectionId={this.props.collection.id}
            afterUpdate={this.props.afterUpdate}
          />
        }
      />
    );
  };

  renderDeleteAction = () => {
    if (this.isCollectionDeletable()) {
      return (
        <ModalItem
          title="Delete Collection"
          className={this.getModalItemClassName()}
          component={this.getModalItemComponent()}
          form={
            <ConfirmationDialog
              invitation={`Clicking Delete will permanently delete "${this.props.collection.name}" collection. Are you sure you wish to proceed?`}
              ok={async () => {
                await this.props.deleteCollectionAndCloseConfirmation(this.props.collection.id);
                this.props.afterDeletion();
              }}
              buttonClass="danger"
              buttonLabel="Delete"
            />
          }
        />
      );
    }
    return null;
  };

  renderPublishAction = () => {
    if (hasUnpublishedTvSeriesListChanges(this.props.collection)) {
      return (
        <ModalItem
          title="Publish Collection"
          className={this.getModalItemClassName()}
          component={this.getModalItemComponent()}
          form={
            <ConfirmationDialog
              invitation={`Publishing will make all draft changes to the tv series list for "${this.props.collection.name}" live. Are you sure you wish to proceed?`}
              ok={async () => {
                await this.props.publishCollectionAndCloseConfirmation(this.props.collection.id);
                this.props.afterUpdate();
              }}
              buttonClass="primary"
              buttonLabel="Publish"
            />
          }
        />
      );
    }
    return null;
  };

  renderViewAction = () => {
    return (
      <li>
        <a className="view-collections-list-item" href={`#/tv/collections/${this.props.collection.id}`}>
          View Collection
        </a>
      </li>
    );
  };

  render() {
    if (this.props.displayType === 'dropdown') {
      return (
        <NavDropdownMenu
          component="div"
          className="pull-left"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
        >
          {this.renderViewAction()}
          {this.renderEditAction()}
          {this.renderDeleteAction()}
          {this.renderPublishAction()}
        </NavDropdownMenu>
      );
    }
    if (this.props.displayType === 'button-bar') {
      return (
        <ButtonToolbar className="pull-right toolbar-buttons">
          {this.renderEditAction()}
          {this.renderDeleteAction()}
          {this.renderPublishAction()}
        </ButtonToolbar>
      );
    }
    return null;
  }
}

export const mapDispatchToProps = (dispatch) => ({
  deleteCollectionAndCloseConfirmation: (collectionId) =>
    dispatch(deleteCollectionAndCloseConfirmation(collectionId)),
  publishCollectionAndCloseConfirmation: (collectionId) =>
    dispatch(publishCollectionAndCloseConfirmation(collectionId)),
});

CollectionActions.propTypes = {
  afterDeletion: PropTypes.func,
  afterUpdate: PropTypes.func,
  collection: PropTypes.object,
  displayType: PropTypes.oneOf(['dropdown', 'button-bar']),
  deleteCollectionAndCloseConfirmation: PropTypes.func,
  publishCollectionAndCloseConfirmation: PropTypes.func,
};

CollectionActions.defaultProps = {
  displayType: 'dropdown',
  afterDeletion: () => {},
  afterUpdate: () => {},
};

export default connect(null, mapDispatchToProps)(CollectionActions);
