import * as requestHandler from '../requestHandler';
import { IMAGES } from 'src/scripts/lib/resourceGroups';
import { getPrefix } from './endpointPrefixResolver';

// exported only to simplify testing.
export function validateResourceGroup(resourceGroup) {
  switch (resourceGroup) {
    case IMAGES.TV:
    case IMAGES.NETWORK:
    case IMAGES.TV_LIVE_EVENTS:
    case IMAGES.NETWORK_LIVE_EVENTS:
      return;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}

export function createImage(resourceGroup) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/images`);
}

export function notifyImageUploadCompletion({ resourceGroup, imageId, s3Path }) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send(
    'PATCH',
    `${getPrefix(resourceGroup)}/images/${imageId}`,
    JSON.stringify({ s3SourceUrl: s3Path })
  );
}
