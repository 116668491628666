import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';

export const BREADCRUMB_TV_SERIES_UPDATE_FINISHED = 'BREADCRUMB_TV_SERIES_UPDATED';
export const BREADCRUMB_TV_SERIES_UPDATE_STARTED = 'BREADCRUMB_TV_SERIES_UPDATE_STARTED';
export const BREADCRUMB_SEASON_UPDATE_FINISHED = 'BREADCRUMB_SEASON_UPDATE_FINISHED';
export const BREADCRUMB_SEASON_UPDATE_STARTED = 'BREADCRUMB_SEASON_UPDATE_STARTED';
export const BREADCRUMB_CATEGORY_UPDATE_FINISHED = 'BREADCRUMB_CATEGORY_UPDATE_FINISHED';
export const BREADCRUMB_CATEGORY_UPDATE_STARTED = 'BREADCRUMB_CATEGORY_UPDATE_STARTED';
export const BREADCRUMB_SUBCATEGORY_UPDATE_STARTED = 'BREADCRUMB_SUBCATEGORY_UPDATE_STARTED';
export const BREADCRUMB_SUBCATEGORY_UPDATE_FINISHED = 'BREADCRUMB_SUBCATEGORY_UPDATE_FINISHED';

export function updateTVSeriesBreadcrumb(tvSeriesId) {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMB_TV_SERIES_UPDATE_STARTED,
    });
    tvVmsApi.getTVSeriesById(tvSeriesId).then((data) => {
      dispatch({
        type: BREADCRUMB_TV_SERIES_UPDATE_FINISHED,
        tvSeries: data.tvSeries,
      });
    });
  };
}

export function updateSeasonBreadcrumb(tvSeriesId, seasonId) {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMB_SEASON_UPDATE_STARTED,
    });
    tvVmsApi.getSeasons({ tvSeriesId }).then((data) => {
      dispatch({
        type: BREADCRUMB_SEASON_UPDATE_FINISHED,
        seasons: data.seasons,
        seasonId,
      });
    });
  };
}

export function updateCategoryBreadcrumb(categoryId) {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMB_CATEGORY_UPDATE_STARTED,
    });
    networkVmsApi.getCategoryById(categoryId).then((data) => {
      dispatch({
        type: BREADCRUMB_CATEGORY_UPDATE_FINISHED,
        category: data.category,
      });
    });
  };
}

export function updateSubcategoryBreadcrumb(subcategoryId) {
  return (dispatch) => {
    dispatch({
      type: BREADCRUMB_SUBCATEGORY_UPDATE_STARTED,
    });
    networkVmsApi.getSubcategoryById(subcategoryId).then((data) => {
      dispatch({
        type: BREADCRUMB_SUBCATEGORY_UPDATE_FINISHED,
        subcategory: data.subcategory,
      });
    });
  };
}
