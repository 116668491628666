import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { DASH_FOR_EMPTY_CONTENT } from 'src/scripts/constants/common';
import { secondsToCuePointTimeDisplayFormat } from '../../lib/timeFormatter';

class EndPointNavigator extends React.Component {
  getTimeDisplayValue = (timeInSeconds) => {
    if (timeInSeconds >= 0) {
      return secondsToCuePointTimeDisplayFormat(timeInSeconds);
    }

    return DASH_FOR_EMPTY_CONTENT;
  };

  getEndPointTimeValues = (endPointTime) => {
    const tenSecondsBeforeEndPointTime = endPointTime - 10;
    const fiveSecondsBeforeEndPointTime = endPointTime - 5;

    const endPointTimeValues = {
      tenSecondsBefore: {
        timeInSeconds: tenSecondsBeforeEndPointTime,
        displayValue: this.getTimeDisplayValue(tenSecondsBeforeEndPointTime),
      },
      fiveSecondsBefore: {
        timeInSeconds: fiveSecondsBeforeEndPointTime,
        displayValue: this.getTimeDisplayValue(fiveSecondsBeforeEndPointTime),
      },
      endPointTime: {
        timeInSeconds: endPointTime,
        displayValue: this.getTimeDisplayValue(endPointTime),
      },
    };

    return endPointTimeValues;
  };

  renderButtonOrEmptyContent(endPointTime) {
    return endPointTime.displayValue === DASH_FOR_EMPTY_CONTENT ? (
      <span>{endPointTime.displayValue}</span>
    ) : (
      <button className="btn btn-default" onClick={() => this.props.setVideoTime(endPointTime.timeInSeconds)}>
        {endPointTime.displayValue}
      </button>
    );
  }

  renderEndPointTimesRow = () => {
    const endPointTimeInSeconds = this.props.videoEndPoint;
    const endPointTimeValues = this.getEndPointTimeValues(endPointTimeInSeconds);
    return (
      <tr className="end-point-list-row">
        <td className="table-value ten-seconds-before-end-point-time">
          {this.renderButtonOrEmptyContent(endPointTimeValues.tenSecondsBefore)}
        </td>
        <td className="table-value five-seconds-before-end-point-time">
          {this.renderButtonOrEmptyContent(endPointTimeValues.fiveSecondsBefore)}
        </td>
        <td className="table-value end-point-time">
          <button
            className="btn btn-primary"
            onClick={() => this.props.setVideoTime(endPointTimeValues.endPointTime.timeInSeconds)}
          >
            {endPointTimeValues.endPointTime.displayValue}
          </button>
        </td>
      </tr>
    );
  };

  renderEmptyContent = () => {
    return (
      <tr className="end-point-list-empty-row">
        <td>
          <span>{DASH_FOR_EMPTY_CONTENT}</span>
        </td>
        <td>
          <span>{DASH_FOR_EMPTY_CONTENT}</span>
        </td>
        <td>
          <span>{DASH_FOR_EMPTY_CONTENT}</span>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="details-row row">
        <div className="details-key row">End Point Navigator</div>
        <div className="details-value row">
          <Table bordered condensed className="details-table end-point-navigation-table">
            <tbody>
              <tr className="details-table-label-column">
                <th className="time-value-header">- 10 sec</th>
                <th className="time-value-header">- 5 sec</th>
                <th className="time-value-header">End Point</th>
              </tr>
              {this.props.videoEndPoint ? this.renderEndPointTimesRow() : this.renderEmptyContent()}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

EndPointNavigator.propTypes = {
  videoEndPoint: PropTypes.number,
  setVideoTime: PropTypes.func,
};

export default EndPointNavigator;
