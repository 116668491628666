import * as imagesVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/images';
import { LIVE_EVENT_IMAGE_REF, LIVE_EVENT_LOGO_REF } from 'src/scripts/lib/imageRefs';
import { LIVE_EVENT_UPDATED } from 'src/scripts/actions/liveEvent';
import { upload as s3Upload } from 'src/scripts/actions/s3Upload';
export const IMAGE_UPLOAD_STARTED = 'IMAGE_UPLOAD_STARTED';
export const IMAGE_UPLOAD_COMPLETED = 'IMAGE_UPLOAD_COMPLETED';
export const IMAGE_UPLOAD_FAILED = 'IMAGE_UPLOAD_FAILED';
export const IMAGE_UPLOAD_CLEAR = 'IMAGE_UPLOAD_CLEAR';
export const IMAGE_UPLOAD_PROGRESS = 'IMAGE_UPLOAD_PROGRESS';

function dispatchFail(error, dispatch, imageUploadRef) {
  dispatch({
    type: IMAGE_UPLOAD_FAILED,
    errorMessage: error,
    imageUploadRef,
  });
}

function dispatchResourceUpdates(dispatch, imageUploadRef, imageId) {
  switch (imageUploadRef) {
    case LIVE_EVENT_IMAGE_REF:
      dispatch({
        type: LIVE_EVENT_UPDATED,
        liveEvent: {
          imageId,
        },
      });
      break;
    case LIVE_EVENT_LOGO_REF:
      dispatch({
        type: LIVE_EVENT_UPDATED,
        liveEvent: {
          logoId: imageId,
        },
      });
      break;
    default:
      break;
  }
}

export function uploadImage(
  resourceGroup,
  file,
  imageUploadRef,
  imageUploadType = 'SINGLE_FILE_UPLOAD',
  imageType
) {
  return (dispatch) => {
    dispatch({
      type: IMAGE_UPLOAD_STARTED,
      imageUploadRef,
      imageUploadType,
      imageType,
    });

    let s3Path;

    imagesVmsApi
      .createImage(resourceGroup)
      .done(({ image }) => {
        const progressCallback = (numberOfParts, partNumber) => {
          return dispatch({
            type: IMAGE_UPLOAD_PROGRESS,
            imageId: image.id,
            numberOfParts,
            partNumber,
            imageUploadType,
            imageType,
          });
        };

        s3Upload(resourceGroup, image.id, file, 'image', progressCallback).subscribe(
          (result) => {
            s3Path = result.s3Path;

            imagesVmsApi
              .notifyImageUploadCompletion({
                resourceGroup,
                imageId: image.id,
                s3Path: result.s3Path,
              })
              .done(() => {
                dispatch({
                  type: IMAGE_UPLOAD_COMPLETED,
                  imageId: image.id,
                  imageUploadRef,
                  imageUploadType,
                  imageType,
                  s3Path,
                });
                dispatchResourceUpdates(dispatch, imageUploadRef, image.id);
              });
          },
          (error) => {
            dispatchFail(error.message, dispatch, imageUploadRef);
          }
        );
      })
      .fail((error) => {
        dispatchFail(error, dispatch, imageUploadRef);
      });
  };
}

export function clearUploadedImage(imageUploadRef) {
  return {
    type: IMAGE_UPLOAD_CLEAR,
    imageUploadRef,
  };
}
