import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { TV_PATH } from 'src/scripts/lib/accessController';
import { CLIP, EPISODE, NETWORK_CLIP, INGEST_JOB } from 'src/scripts/lib/modelNames';

export class ViewButton extends React.Component {
  getViewItemUrl() {
    const viewItem = this.props.selectedItems[0];
    if (this.props.modelName === CLIP || this.props.modelName === EPISODE) {
      return `#/${TV_PATH}/tv-series/${viewItem.partOfSeries.id}/seasons/${viewItem.partOfSeason.id}/${this.props.modelName}/${viewItem.id}`;
    } else if (this.props.modelName === INGEST_JOB) {
      return `#/${TV_PATH}/videos/ingestJobs/${viewItem.id}`;
    } else if (this.props.modelName === NETWORK_CLIP) {
      return `#/network/categories/${viewItem.partOfCategory.id}/subcategories/${viewItem.partOfSubcategory.id}/clips/${viewItem.id}`;
    }
    return null;
  }

  isDisabled() {
    return this.props.selectedItems.length !== 1;
  }

  render() {
    return (
      <Button
        bsSize="small"
        key={'someKey'}
        ref="bulkActionBarViewButton"
        disabled={this.isDisabled()}
        href={this.props.selectedItems.length ? this.getViewItemUrl(this.props.selectedItems[0]) : null}
      >
        View
      </Button>
    );
  }
}

ViewButton.propTypes = {
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
};

export default ViewButton;
