import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class ContentSegmentSelect extends React.Component {
  render() {
    return (
      <div className="form-group">
        <Select
          searchable
          multi={false}
          clearable={false}
          name="tvSeries-field-name"
          simpleValue
          value={this.props.selected}
          options={this.props.contentSegments.map((contentSegment) => ({
            label: contentSegment,
            value: contentSegment,
          }))}
          onChange={(value) => this.props.onChange(value)}
          ref="selectContentSegment"
          id="select-content-segment"
          valueKey="value"
          labelKey="label"
        />
      </div>
    );
  }
}

ContentSegmentSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  contentSegments: PropTypes.array,
  selected: PropTypes.string,
};
