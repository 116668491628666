import _ from 'lodash';
import React from 'react';
import { Input } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DEBOUNCE_TIME } from '../../../constants/common';

class Text extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.noDebounce) {
      this.onFilter = this.onFilter.bind(this);
    } else {
      this.onFilter = _.debounce(this.onFilter, DEBOUNCE_TIME).bind(this);
    }
  }

  clear() {
    this.refs.filterInput.getInputDOMNode().value = '';
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.revision !== nextProps.revision) {
      this.clear();
    }
  }

  onFilter() {
    const value = this.refs.filterInput.getValue();
    this.props.applyFilter(!!value ? `like:${value}` : null);
  }

  render() {
    return (
      <Input
        ref={'filterInput'}
        className="filter-input"
        type="text"
        onChange={this.onFilter}
        data-pw="text-input"
      />
    );
  }
}

Text.propTypes = {
  revision: PropTypes.number,
  applyFilter: PropTypes.func,
  noDebounce: PropTypes.bool,
};

export default Text;
