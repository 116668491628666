export const IMAGE_RESTRICTIONS = {
  IMAGE: {
    minWidth: 0,
    minHeight: 0,
    ratioThreshold: 2.5,
    ratioLabel: '5:2',
    class: 'genre',
  },
  FEATURED: {
    minWidth: 0,
    minHeight: 0,
    ratioThreshold: 16 / 9,
    ratioLabel: '16:9',
    class: 'genre',
  },
  FEATURED_POSTER: {
    minWidth: 0,
    minHeight: 0,
    ratioThreshold: 2 / 3,
    ratioLabel: '2:3',
    class: 'genre',
  },
};
