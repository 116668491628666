import _, { isPlainObject } from 'lodash';
import {
  GET_CHANNELS_SUCCEEDED,
  GET_CHANNELS_FAILED,
  UPDATE_CHANNEL,
  UPDATE_MARKET_CHANNEL,
  TOGGLE_MARKET_EXPANDED,
  UPDATING_CHANNEL_STYLING,
  UPDATE_CHANNEL_STYLING_SUCCEEDED,
  UPDATE_CHANNEL_STYLING_FAILED,
  UPDATE_CHANNEL_STYLING_RESET,
  masqueradeAsChannel,
  expandMarkets,
} from 'src/scripts/actions/channel';
import * as browser from 'src/scripts/lib/browser';

const MARKET_EXPANDED_KEY = 'ADMIN::CHANNEL::MARKET_EXPANDED_KEY';
const MARKET_EXPANDED_DEFAULT = {
  'channel-9': false,
  gem: false,
  life: false,
  go: false,
  rush: false,
};

function saveMarketExpanded(marketExpanded) {
  browser.setItem(MARKET_EXPANDED_KEY, marketExpanded);
}

function loadMarketExpanded() {
  try {
    const marketExpanded = browser.getItem(MARKET_EXPANDED_KEY);
    if (!isPlainObject(marketExpanded)) {
      throw new Error('Invalid MarketExpanded. Use Default.');
    }

    return marketExpanded;
  } catch (e) {
    saveMarketExpanded(MARKET_EXPANDED_DEFAULT);
    return MARKET_EXPANDED_DEFAULT;
  }
}

export const CHANNEL_UPDATE_STATE_ENUM = Object.freeze({
  UPDATING: 'updating',
  SUCCEEDED: 'succeeded',
  ERROR: 'error',
  INACTIVE: 'inactive',
});

export const initialState = {
  channels: [],
  errorMessage: null,
  marketExpanded: loadMarketExpanded(),
  channelUpdateState: CHANNEL_UPDATE_STATE_ENUM.INACTIVE,
  updateChannelError: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MARKET_EXPANDED:
      const marketExpanded = {
        ...state.marketExpanded,
        [action.data.slug]: !state.marketExpanded[action.data.slug],
      };
      saveMarketExpanded(marketExpanded);
      return {
        ...state,
        channels: expandMarkets(state.channels, marketExpanded),
        marketExpanded,
      };

    case GET_CHANNELS_SUCCEEDED:
      return {
        ...state,
        channels: action.data.channels,
        errorMessage: null,
      };

    case GET_CHANNELS_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };
    case UPDATE_CHANNEL:
      const id = action.channel.id;
      const channelIndex = _.findIndex(state.channels, { id });
      const channels = [...state.channels];
      const currentChannel = channels[channelIndex];
      channels.splice(channelIndex, 1, { ...currentChannel, ...action.channel });
      return {
        ...state,
        channels,
      };

    case UPDATE_MARKET_CHANNEL:
      return {
        ...state,
        channels: state.channels.map((channelOrMarketChannel) => {
          if (!channelOrMarketChannel.isMarket) {
            if (channelOrMarketChannel.id === action.marketChannel.channelId) {
              return {
                ...channelOrMarketChannel,
                marketChannels: channelOrMarketChannel.marketChannels.map((marketChannel) => {
                  if (marketChannel.id === action.marketChannel.id) {
                    return action.marketChannel;
                  }

                  return marketChannel;
                }),
              };
            }

            return channelOrMarketChannel;
          }

          if (channelOrMarketChannel.id !== action.marketChannel.id) {
            return channelOrMarketChannel;
          }

          return masqueradeAsChannel(action.marketChannel);
        }),
      };

    case UPDATING_CHANNEL_STYLING:
      return {
        ...state,
        channelUpdateState: CHANNEL_UPDATE_STATE_ENUM.UPDATING,
        updateChannelError: '',
      };

    case UPDATE_CHANNEL_STYLING_SUCCEEDED:
      return {
        ...state,
        updateChannelError: '',
        channelUpdateState: CHANNEL_UPDATE_STATE_ENUM.SUCCEEDED,
      };

    case UPDATE_CHANNEL_STYLING_FAILED:
      return {
        ...state,
        channelUpdateState: CHANNEL_UPDATE_STATE_ENUM.FAILED,
        updateChannelError: action.error,
      };

    case UPDATE_CHANNEL_STYLING_RESET:
      return {
        ...state,
        updateChannelError: '',
        channelUpdateState: CHANNEL_UPDATE_STATE_ENUM.INACTIVE,
      };
    default:
      return state;
  }
}
