import Rx from 'rx';
import * as s3UploadsVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/s3Uploads';

function getS3Signatures(params) {
  return Rx.Observable.create((subscriber) => {
    const onSuccess = (data) => {
      subscriber.onNext(data);
      return subscriber.onCompleted();
    };

    const onError = (jqXHR, textStatus, errorThrown) => {
      return subscriber.onError({ type: errorThrown, message: jqXHR.responseJSON.message });
    };

    return s3UploadsVmsApi.getS3Signatures(params).then(onSuccess, onError);
  });
}

export function getCreateUploadSignedUrl(resourceGroup, assetId, suggestedFileName, uploadType) {
  return getS3Signatures({
    operation: 'createMultipartUpload',
    resourceGroup,
    assetId,
    suggestedFileName,
    uploadType,
  });
}

export function getUploadPartSignedUrl(resourceGroup, s3Path, uploadId, partNumber, uploadType) {
  return getS3Signatures({
    operation: 'uploadPart',
    resourceGroup,
    s3Path,
    uploadId,
    partNumber,
    uploadType,
  });
}

export function getCompleteUploadSignedUrl(resourceGroup, s3Path, uploadId, uploadType) {
  return getS3Signatures({
    operation: 'completeMultipartUpload',
    resourceGroup,
    s3Path,
    uploadId,
    uploadType,
  });
}
