import React from 'react';
import PropTypes from 'prop-types';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import json2csv from 'json2csv';
import _ from 'lodash';
import { getAllClips, getAllEpisodes } from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { getAllNetworkClips } from 'src/scripts/actions/apiWrappers/vmsApi/network';
import { CLIP, EPISODE, NETWORK_CLIP } from 'src/scripts/lib/modelNames';
import { Input, Button, Panel } from 'react-bootstrap';
import { CLIP_FIELDS, EPISODE_FIELDS, NETWORK_CLIP_FIELDS } from 'src/scripts/lib/exportCatalogFields';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import { exportCatalogFile } from 'src/scripts/lib/downloadFile';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import { states, networkStates } from 'src/scripts/lib/workflowActionsProvider';
import { humanizeString } from 'src/scripts/lib/stringFormatter';
import { getValidationErrors } from '../../lib/formValidation/ExportCatalogForm';

const pageSize = 500;
const latestDownloadLimit = 15000;
const invalidModelNameErrorMessage = 'Not a valid model name to export data!';

export const exportOptions = {
  LATEST: 'LATEST',
  SELECTION: 'SELECTION',
  ALL: 'ALL',
};

export const defaultQueryParams = {
  clip: {
    offset: 0,
    sort: '-updatedAt',
    limit: pageSize,
    state: 'IN_USE',
    activeOnly: true,
  },
  episode: {
    offset: 0,
    sort: '-updatedAt',
    limit: pageSize,
    state: 'in:IN_USE,READY_FOR_REVIEW,REVIEWED',
  },
  networkClip: {
    offset: 0,
    sort: '-updatedAt',
    limit: pageSize,
    state: 'IN_USE',
    activeOnly: true,
  },
};

export default class ExportCatalogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExportOption: exportOptions.LATEST,
      filters: {
        startDate: {
          to: null,
          from: null,
        },
        endDate: {
          to: null,
          from: null,
        },
        state: 'in:IN_USE',
        tvSeriesId: null,
      },
      validationErrors: {},
      errorMessages: [],
    };
  }

  onExportOptionChange = (event) => {
    this.setState({
      selectedExportOption: event.target.value,
      validationErrors: {},
      errorMessages: [],
    });
  };

  onStartDateFromChange = (event) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: {
          ...prevState.filters.startDate,
          from: event,
        },
      },
    }));
  };

  onStartDateToChange = (event) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        startDate: {
          ...prevState.filters.startDate,
          to: event,
        },
      },
    }));
  };

  onEndDateFromChange = (event) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: {
          ...prevState.filters.endDate,
          from: event,
        },
      },
    }));
  };

  onEndDateToChange = (event) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        endDate: {
          ...prevState.filters.endDate,
          to: event,
        },
      },
    }));
  };

  onTVSeriesChange = (event) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        tvSeriesId: event,
      },
    }));
  };

  getItems = async (params, model) => {
    let result;
    switch (model) {
      case CLIP:
        result = await getAllClips(params);
        return {
          count: result.count,
          items: result.clips,
        };
      case EPISODE:
        result = await getAllEpisodes(params);
        return {
          count: result.count,
          items: result.episodes,
        };
      case NETWORK_CLIP:
        result = await getAllNetworkClips(params);
        return {
          count: result.count,
          items: result.clips,
        };
      default:
        throw Error(invalidModelNameErrorMessage);
    }
  };

  shouldApplyDownloadLimit = (totalItemCount) => {
    return this.state.selectedExportOption === exportOptions.LATEST && totalItemCount > latestDownloadLimit;
  };

  getAvailabilityFilter = () => {
    return this.getDateRangeQuery(this.state.filters.startDate.from, this.state.filters.startDate.to);
  };

  getExpiryFilter = () => {
    return this.getDateRangeQuery(this.state.filters.endDate.from, this.state.filters.endDate.to);
  };

  getSelectionExportQueryParams = () => {
    const availabilityFilter = this.getAvailabilityFilter();
    const expiryFilter = this.getExpiryFilter();
    const stateFilter = this.state.filters.state;
    const tvSeriesIdFilter = this.state.filters.tvSeriesId;

    let selectionExportQueryParams;
    switch (this.props.modelName) {
      case CLIP:
        selectionExportQueryParams = { ...defaultQueryParams.clip };
        break;
      case EPISODE:
        selectionExportQueryParams = { ...defaultQueryParams.episode };
        break;
      case NETWORK_CLIP:
        selectionExportQueryParams = { ...defaultQueryParams.networkClip };
        break;
      default:
        throw Error(invalidModelNameErrorMessage);
    }

    if (tvSeriesIdFilter) {
      selectionExportQueryParams['tvSeries.id'] = tvSeriesIdFilter;
    }
    if (availabilityFilter) {
      selectionExportQueryParams.availability = availabilityFilter;
    }
    if (expiryFilter) {
      selectionExportQueryParams.expiry = expiryFilter;
    }
    selectionExportQueryParams.state = stateFilter;
    selectionExportQueryParams.activeOnly = false;
    return selectionExportQueryParams;
  };

  getCSVQueryParams = () => {
    if (this.state.selectedExportOption === exportOptions.SELECTION) {
      return this.getSelectionExportQueryParams();
    }

    switch (this.props.modelName) {
      case CLIP:
        return defaultQueryParams.clip;
      case EPISODE:
        return defaultQueryParams.episode;
      case NETWORK_CLIP:
        return defaultQueryParams.networkClip;
      default:
        throw Error(invalidModelNameErrorMessage);
    }
  };

  buildCSV = async () => {
    let fields;
    switch (this.props.modelName) {
      case CLIP:
        fields = CLIP_FIELDS;
        break;
      case EPISODE:
        fields = EPISODE_FIELDS;
        break;
      case NETWORK_CLIP:
        fields = NETWORK_CLIP_FIELDS;
        break;
      default:
        throw Error(invalidModelNameErrorMessage);
    }

    const queryParams = this.getCSVQueryParams();
    let result = await this.getItems(queryParams, this.props.modelName);
    const resultItems = [...result.items];

    const numberOfItemsToDownload = this.shouldApplyDownloadLimit(result.count)
      ? latestDownloadLimit
      : result.count;
    const pageNo = Math.ceil(numberOfItemsToDownload / pageSize);
    if (pageNo > 1) {
      for (let i = 1; i < pageNo; i++) {
        result = await this.getItems({ ...queryParams, offset: i * pageSize }, this.props.modelName);
        resultItems.push(...result.items);
      }
    }
    const csv = json2csv.parse(resultItems, { fields, withBOM: true, header: true });
    return csv;
  };

  getValidationErrors = () => {
    return this.state.selectedExportOption === exportOptions.SELECTION
      ? getValidationErrors(this.state.filters)
      : {};
  };

  checkFiltersAndExportCatalog = (event) => {
    event.preventDefault();
    const formValidationErrors = this.getValidationErrors();

    if (_.isEmpty(formValidationErrors)) {
      this.exportCatalog();
    } else {
      this.setState({
        validationErrors: formValidationErrors,
        errorMessages: Object.values(formValidationErrors),
      });
    }
  };

  exportCatalog = async () => {
    this.props.updateExportStatus(true);
    this.props.close();
    const csv = await this.buildCSV();
    exportCatalogFile(csv, this.props.modelName);
    this.props.updateExportStatus(false);
  };

  onWorkflowStateCheckboxesChange = (state, event) => {
    const currentFilter = this.state.filters.state;
    let values = [];
    if (currentFilter) {
      const checked = event.target.checked;
      values = this.getCheckboxFilterValues(currentFilter);
      if (!checked) {
        _.pull(values, state);
      } else {
        values.push(state);
      }
    } else {
      values.push(state);
    }

    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        state: values.length > 0 ? `in:${values.join(',')}` : null,
      },
    }));
  };

  getDateRangeQuery = (fromDate, toDate) => {
    if (fromDate && toDate) {
      return `between:${fromDate},${toDate}`;
    }
    if (fromDate) {
      return `gte:${fromDate}`;
    }
    if (toDate) {
      return `lte:${toDate}`;
    }
    return null;
  };

  getCheckboxFilterValues(filter) {
    return filter.replace('in:', '').split(',');
  }

  checkInUseWorkFlow(workflowState) {
    const currentStateFilter = this.state.filters.state;
    if (currentStateFilter) {
      const currentStateFilterValues = this.getCheckboxFilterValues(currentStateFilter);
      const workflowStateChecked = currentStateFilterValues.indexOf(workflowState) > -1;
      return workflowStateChecked;
    }
    return false;
  }

  getModelNameLabel = () => {
    // the NETWORK_CLIP model name is stored as one word and humanizeString cannot separate them
    if (this.props.modelName === NETWORK_CLIP) {
      return 'Network Clips';
    }
    return humanizeString(this.props.modelName);
  };

  getAllExportOptionIncludedStatesText = () => {
    if (this.props.modelName === EPISODE) {
      return 'In Use and Ready for Review';
    }
    return 'In Use';
  };

  renderExportOptionsDropdown = () => {
    return (
      <div className="form-group">
        <Input
          type="select"
          label="Export Option"
          name="export-option-select"
          ref="exportOptionSelect"
          labelClassName="required"
          onChange={this.onExportOptionChange}
          value={this.state.selectedExportOption}
        >
          <option ref="latestExportOption" value={exportOptions.LATEST}>
            Latest (Last {latestDownloadLimit} {this.getModelNameLabel()})
          </option>
          <option ref="selectionExportOption" value={exportOptions.SELECTION}>
            Selection (Apply one or more filters)
          </option>
          {this.props.modelName !== NETWORK_CLIP ? (
            <option ref="allExportOption" value={exportOptions.ALL}>
              All ({this.getAllExportOptionIncludedStatesText()})
            </option>
          ) : null}
        </Input>
      </div>
    );
  };

  renderDateFilterTable = () => {
    return (
      <table className="date-filter-table">
        <tbody>
          <tr>
            <td>
              <span> Start Date </span>
            </td>
            <td>
              <DateTimePicker
                ref="dateFrom"
                id="date-from"
                placeholder="From"
                onChange={this.onStartDateFromChange}
                value={this.state.filters.startDate.from}
              />
            </td>
            <td>
              <span> - </span>
            </td>
            <td>
              <DateTimePicker
                ref="dateFrom"
                id="date-from"
                placeholder="To"
                onChange={this.onStartDateToChange}
                value={this.state.filters.startDate.to}
              />
            </td>
          </tr>
          <tr>
            <td>
              <span> End Date </span>
            </td>
            <td>
              <DateTimePicker
                ref="dateFrom"
                id="date-from"
                placeholder="From"
                onChange={this.onEndDateFromChange}
                value={this.state.filters.endDate.from}
              />
            </td>
            <td>
              <span> - </span>
            </td>
            <td>
              <DateTimePicker
                ref="dateFrom"
                id="date-from"
                placeholder="To"
                onChange={this.onEndDateToChange}
                value={this.state.filters.endDate.to}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  renderStatusCheckboxes = () => {
    if (this.props.modelName === NETWORK_CLIP) {
      return (
        <table className="state-filter-table">
          <tr>
            <td>
              {networkStates.map((state) => {
                return (
                  <div className="form-group form-inline">
                    <Input
                      type="checkbox"
                      label={humanizeString(state)}
                      checked={this.checkInUseWorkFlow(state)}
                      onChange={this.onWorkflowStateCheckboxesChange.bind(null, state)}
                    />
                  </div>
                );
              })}
            </td>
          </tr>
        </table>
      );
    }
    const secondColumnStart = Math.floor(states.length / 2);
    return (
      <table className="state-filter-table">
        <tr>
          <td>
            {states.slice(0, secondColumnStart).map((state) => {
              return (
                <div className="form-group form-inline">
                  <Input
                    type="checkbox"
                    label={humanizeString(state)}
                    checked={this.checkInUseWorkFlow(state)}
                    onChange={this.onWorkflowStateCheckboxesChange.bind(null, state)}
                  />
                </div>
              );
            })}
          </td>
          <td>
            {states.slice(secondColumnStart).map((state) => {
              return (
                <div className="form-group form-inline">
                  <Input
                    type="checkbox"
                    label={humanizeString(state)}
                    checked={this.checkInUseWorkFlow(state)}
                    onChange={this.onWorkflowStateCheckboxesChange.bind(null, state)}
                  />
                </div>
              );
            })}
          </td>
        </tr>
      </table>
    );
  };

  renderTvSeriesSelect = () => {
    return this.props.modelName !== NETWORK_CLIP ? (
      <div>
        <br />
        <TVSeriesSelect ref="selectTVSeries" required={false} onTVSeriesChange={this.onTVSeriesChange} />
      </div>
    ) : null;
  };

  renderExportFilters = () => {
    return this.state.selectedExportOption === exportOptions.SELECTION ? (
      <Panel ref="exportFilters">
        {this.renderDateFilterTable()}
        <br />
        <label className="control-label">Workflow Status:</label>
        {this.renderStatusCheckboxes()}
        {this.renderTvSeriesSelect()}
      </Panel>
    ) : null;
  };

  renderExportAllWarning = () => {
    return this.state.selectedExportOption === exportOptions.ALL ? (
      <div ref="exportAllWarning" className="export-all-warning">
        <span>Warning: Export file may take some time to export.</span>
      </div>
    ) : null;
  };

  renderValidationWarning = () => {
    if (this.state.errorMessages.length) {
      return <FormErrorMessage message={this.state.errorMessages.join('\n')} />;
    }
    return null;
  };

  renderFooterButtons = () => {
    return (
      <div className="modal-footer">
        <Button
          id="video-modal-close"
          type="button"
          ref="closeButton"
          className="form__button"
          onClick={this.props.close}
        >
          Close
        </Button>
        <Button
          ref="submitButton"
          id="video-modal-submit"
          type="submit"
          className="form__button"
          bsStyle="primary"
          data-pw="video-modal-submit"
        >
          Export
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.checkFiltersAndExportCatalog} ref="exportCatalogForm">
          {this.renderExportOptionsDropdown()}
          {this.renderExportFilters()}
          {this.renderExportAllWarning()}
          {this.renderValidationWarning()}
          {this.renderFooterButtons()}
        </form>
      </div>
    );
  }
}

ExportCatalogForm.propTypes = {
  close: PropTypes.func, // Prop is injected by ModalItem component
  modelName: PropTypes.string,
  updateExportStatus: PropTypes.func,
};
