import {
  GET_CURRENT_SEASON_SUCCEEDED,
  GET_CURRENT_SEASON_FAILED,
  RESET_SEASON_DETAILS_VIEW,
  SAVE_SEASON_SUCCEEDED,
} from 'src/scripts/actions/season';

export const initialState = {
  loading: true,
  season: null,
  error: null,
};

const updateSeasonStateIfRequired = (state, action) => {
  if (state.season && state.season.id === action.data.season.id) {
    return {
      ...state,
      season: action.data.season,
    };
  }
  return {
    ...state,
  };
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_SEASON_SUCCEEDED:
      return {
        ...state,
        season: action.data.season,
        loading: false,
      };
    case GET_CURRENT_SEASON_FAILED:
      return {
        ...state,
        error: 'Unable to load season data.',
        loading: false,
      };
    case SAVE_SEASON_SUCCEEDED:
      return updateSeasonStateIfRequired(state, action);
    case RESET_SEASON_DETAILS_VIEW:
      return { ...initialState };
    default:
      return state;
  }
}
