import Ajv from 'ajv';
import { pick } from 'lodash';
import {
  getAllowedPropertyKeys,
  getCdnNameList,
  getDefaultProperties,
  sanitiseWeighting,
  getCdnSchema,
  getCdnListSchema,
} from './cdnConfiguration';

export class Cdn {
  constructor(cdnName, configuration) {
    const cdnList = getCdnNameList();
    if (!cdnList.includes(cdnName)) {
      throw new Error(`"cdnName" must be one of ${cdnList}. ${cdnName} was given.`);
    }

    this.validateConfiguration(configuration);

    this.configuration = pick(
      {
        ...getDefaultProperties(),
        ...configuration,
      },
      getAllowedPropertyKeys()
    );

    this.cdnName = cdnName;
  }

  validateConfiguration(configuration) {
    const ajv = new Ajv();
    const schemaValidate = ajv.compile(getCdnSchema());
    const result = schemaValidate(configuration);
    if (result) {
      return;
    }

    if (!schemaValidate.errors) {
      throw new Error('Unknown error when validating CDN_SCHEMA.');
    }

    throw new Error(JSON.stringify(schemaValidate.errors));
  }

  getCdnName() {
    return this.cdnName;
  }

  isActivated() {
    return (
      this.configuration.activated && this.configuration.weighting > 0 && this.configuration.weighting <= 100
    );
  }

  getWeighting() {
    return this.configuration.weighting;
  }

  deactivate() {
    this.configuration.activated = false;
  }

  activate() {
    this.configuration.activated = true;
  }

  setWeighting(weighting = 100) {
    this.configuration.weighting = sanitiseWeighting(weighting);
  }

  toJson(wrappedByCdnName = true) {
    const json = getAllowedPropertyKeys().reduce((result, key) => {
      return {
        ...result,
        [key]: this.configuration[key],
      };
    }, {});

    if (wrappedByCdnName) {
      return {
        [this.cdnName]: json,
      };
    }

    return json;
  }
}

export class CdnCollection {
  constructor(configuration) {
    this.validateConfiguration(configuration);
    this.cdnList = [];

    for (const cdnName of getCdnNameList()) {
      this.cdnList.push(new Cdn(cdnName, configuration[cdnName] || {}));
    }
  }

  /**
   * @return {{next: (function(): {value: Cdn, done})}}
   */
  [Symbol.iterator]() {
    let index = -1;
    const data = this.cdnList;

    return {
      next: () => ({ value: data[++index], done: !(index in data) }),
    };
  }

  getCdnList() {
    return this.cdnList;
  }

  validateConfiguration(configuration) {
    const ajv = new Ajv();
    const schemaValidate = ajv.compile(getCdnListSchema());
    const result = schemaValidate(configuration);
    if (result) {
      return;
    }

    if (!schemaValidate.errors) {
      throw new Error('Unknown error when validating CDN_LIST_SCHEMA.');
    }

    throw new Error(JSON.stringify(schemaValidate.errors));
  }

  /**
   * @param cdn {Cdn}
   */
  replace(cdn) {
    const cdnName = cdn.getCdnName();
    this.cdnList = this.cdnList.map((entry) => (entry.getCdnName() === cdnName ? cdn : entry));
  }

  /**
   * @param cdnName {string}
   * @return {Cdn | undefined}
   */
  find(cdnName) {
    return this.cdnList.find((cdn) => cdn.getCdnName() === cdnName);
  }

  toJson(activatedOnly = true) {
    return this.cdnList.reduce((json, cdn) => {
      if (!activatedOnly || cdn.isActivated()) {
        return {
          ...json,
          ...cdn.toJson(true),
        };
      }

      return json;
    }, {});
  }

  /**
   * @returns {number}
   */
  activatedCdnNumber() {
    return this.cdnList.filter((entry) => entry.isActivated()).length;
  }
}
