import Pusher from 'pusher-js';
import environment from 'config/environment';

export function connect() {
  this.pusher = new Pusher(environment.pusher.appKey, {
    cluster: environment.pusher.cluster,
    encrypted: true,
  });
  this.vmsChannel = this.pusher.subscribe(environment.pusher.channel);
}

export function subscribe(action) {
  this.vmsChannel.bind(environment.pusher.event, function (msg) {
    action(msg);
  });
}

export function disconnect() {
  this.pusher.disconnect();
}
