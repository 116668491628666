import PropTypes from 'prop-types';
import React from 'react';

const ErrorMessageAlert = function ({ children }) {
  return (
    <div className="alert alert-danger server-error-message" data-pw="server-error-alert">
      {children}
    </div>
  );
};

ErrorMessageAlert.propTypes = {
  children: PropTypes.any,
};

export default ErrorMessageAlert;
