import _ from 'lodash';
import {
  IMAGE_SIZE_16_BY_9,
  IMAGE_SIZE_3_BY_1,
  IMAGE_SIZE_2_BY_3,
  IMAGE_SIZE_1_BY_1,
  IMAGE_SIZE_49_BY_18,
  IMAGE_SIZE_ANY,
  IMAGE_SIZE_4_BY_5,
  IMAGE_ANY,
} from 'src/scripts/constants/imageSizes';
import {
  BACKGROUND,
  LOGO,
  CARD,
  POSTER,
  FEATURED_CARD,
  FEATURED_BACKGROUND,
  CTV_CAROUSEL,
  HORIZONTAL_A,
  CROPPED,
  LIVE_EVENT_GROUP_IMAGE,
  LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
  LIVE_EVENT_GROUP_LOGO,
  LIVE_EVENT_RATIO_LOGO,
  CENTERED_IMAGE,
  CLIP_FEATURED_IMAGE,
  LIVE_EVENT_GROUP_SWITCHER_LOGO,
} from './imageTypes';

import {
  CHANNEL_CARD_LOGO_TRANSPARENT,
  CHANNEL_CARD_TRANSPARENT,
  CHANNEL_CARD_LIVE,
  MARKET_CHANNEL_SWITCHER_LOGO,
} from '../components/Channel/channelImageTypes';

const IMAGE_TYPE_VS_SIZE = [
  {
    type: BACKGROUND,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: CARD,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: LOGO,
    size: IMAGE_SIZE_3_BY_1,
  },
  {
    type: HORIZONTAL_A,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: POSTER,
    size: IMAGE_SIZE_2_BY_3,
  },
  {
    type: FEATURED_CARD,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: FEATURED_BACKGROUND,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: CTV_CAROUSEL,
    size: IMAGE_SIZE_49_BY_18,
  },
  {
    type: CROPPED,
    size: IMAGE_SIZE_ANY,
  },
  {
    type: LIVE_EVENT_GROUP_IMAGE,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: LIVE_EVENT_GROUP_LOGO,
    size: IMAGE_SIZE_3_BY_1,
  },
  {
    type: CENTERED_IMAGE,
    size: IMAGE_SIZE_4_BY_5,
  },
  {
    type: CLIP_FEATURED_IMAGE,
    size: IMAGE_SIZE_16_BY_9,
  },
  {
    type: LIVE_EVENT_RATIO_LOGO,
    size: IMAGE_SIZE_3_BY_1,
  },
  {
    type: LIVE_EVENT_GROUP_SWITCHER_LOGO,
    size: IMAGE_SIZE_1_BY_1,
  },
  {
    type: CHANNEL_CARD_LOGO_TRANSPARENT,
    size: IMAGE_SIZE_ANY,
  },
  {
    type: CHANNEL_CARD_TRANSPARENT,
    size: IMAGE_SIZE_ANY,
  },
  {
    type: CHANNEL_CARD_LIVE,
    size: IMAGE_SIZE_ANY,
  },
  {
    type: MARKET_CHANNEL_SWITCHER_LOGO,
    size: IMAGE_SIZE_1_BY_1,
  },
];

// Refactor for possible different type names that allow any ratio in the future.
export function anySizeAllowedTypeCheck(acceptedTypes) {
  return acceptedTypes.some((e) => e === CROPPED);
}

// Refactor for possible different type names that allow any ratio in the future.
export function anySizeAllowedSizeCheck(acceptedTypes) {
  return acceptedTypes.some((e) => e.name === IMAGE_ANY);
}

export function getPossibleImageTypes(imageSize, acceptedTypes) {
  const filteredItems = _.filter(IMAGE_TYPE_VS_SIZE, (item) => item.size === imageSize);
  const imageTypes = _.map(filteredItems, (item) => item.type);
  const possibleImageTypes = _.intersection(imageTypes, acceptedTypes);

  if (anySizeAllowedTypeCheck(acceptedTypes)) {
    possibleImageTypes.push(CROPPED);
  }

  return possibleImageTypes;
}

export function getSizesForImageTypes(imageTypes) {
  const filteredImageTypesAndSizes = _.filter(IMAGE_TYPE_VS_SIZE, (item) => imageTypes.includes(item.type));
  return _.map(filteredImageTypesAndSizes, (item) => item.size);
}

export function getSizeForImageType(imageType) {
  const imageTypeAndSize = _.find(IMAGE_TYPE_VS_SIZE, (item) => item.type === imageType);
  return imageTypeAndSize ? imageTypeAndSize.size : null;
}

export function determineImageType(imageSize, acceptedTypes) {
  const possibleImageTypes = getPossibleImageTypes(imageSize, acceptedTypes);
  if (possibleImageTypes.length === 1) {
    return possibleImageTypes[0];
  }
  return null;
}

export function getExistingImageRefId(image) {
  return `ref-${image.id}`;
}

export function parseImageIdFromImageRef(imageRef) {
  const isExistingImageRef = _.startsWith(imageRef, 'ref-');
  const imageId = isExistingImageRef ? parseInt(imageRef.substr(4), 10) : null;
  return imageId;
}
