import { combineReducers } from 'redux';
import { reducer as notifications } from 'reapop';

import breadcrumb from './breadcrumb';
import channels from './channels';
import clipForm from './clipForm';
import clips from './clips';
import confirmation from './confirmation';
import episodeForm from './episodeForm';
import episodes from './episodes';
import genreForm from './genreForm';
import genreList from './genreList';
import genreDetailsView from './genreDetailsView';
import imageUploads from './imageUploads';
import ingestJobs from './ingestJobs';
import seasonForm from './seasonForm';
import seasonList from './seasonList';
import seasonDetailsView from './seasonDetailsView';
import tags from './tags';
import tvSeriesDetailsView from './tvSeriesDetailsView';
import tvSeriesForm from './tvSeriesForm';
import tvSeriesList from './tvSeriesList';
import collectionDetailsView from './collectionDetailsView';
import collectionList from './collectionList';
import collectionForm from './collectionForm';
import addCollectionForm from './addCollectionForm';
import video from './video';
import videoPlayer from './videoPlayer';
import login from './login';
import modalItem from './modalItem';
import roleList from './roleList';
import roleForm from './roleForm';
import liveEventForm from './liveEventForm';
import liveEvents from './liveEvents';
import liveEventGroups from './liveEventGroups';
import columnChooser from './columnChooser';
import itemList from './itemList';
import categoryForm from './categoryForm';
import networkClipForm from './networkClipForm';
import networkClips from './networkClips';
import categoryList from './categoryList';
import subcategoryForm from './subcategoryForm';
import subcategoryList from './subcategoryList';
import mediaItemDetailsView from './mediaItemDetailsView';
import currentSeason from './currentSeason';
import multiImageUpload from './multiImageUpload';
import bulkActions from './bulkActions';
import app from './app';
import ui from './ui';

const rootReducer = combineReducers({
  breadcrumb,
  channels,
  clipForm,
  clips,
  confirmation,
  episodeForm,
  episodes,
  genreForm,
  genreList,
  genreDetailsView,
  imageUploads,
  ingestJobs,
  seasonForm,
  seasonList,
  currentSeason,
  seasonDetailsView,
  tags,
  tvSeriesDetailsView,
  tvSeriesForm,
  tvSeriesList,
  collectionDetailsView,
  collectionList,
  collectionForm,
  addCollectionForm,
  video,
  videoPlayer,
  login,
  modalItem,
  roleList,
  roleForm,
  liveEventForm,
  liveEvents,
  liveEventGroups,
  columnChooser,
  itemList,
  categoryForm,
  categoryList,
  subcategoryForm,
  subcategoryList,
  networkClipForm,
  networkClips,
  mediaItemDetailsView,
  multiImageUpload,
  bulkActions,
  notifications,
  app,
  ui,
});

export default rootReducer;
