export const keys = {
  ENTER: 13,
  COMMA: 44,
  SPACE: 32,
  ZERO_KEY: 48,
  NINE_KEY: 57,
  BACKSPACE_KEY: 8,
  LEFT: 37,
  RIGHT: 39,
  SEMICOLON: 59,
};

function isKeyPressNumeric(key) {
  return !parseInt(key, 10) || key < keys.ZERO_KEY || key > keys.NINE_KEY;
}

function isKeyPressArrowOrBackspace(key) {
  return key === keys.BACKSPACE_KEY || key === keys.LEFT || key === keys.RIGHT;
}

function parseFloatStrict(value) {
  if (/^(\-|\+)?([0-9.]+(\.[0-9.]+)?|Infinity)$/.test(value)) {
    return Number(value);
  }
  return NaN;
}

export function isPositiveValidNumber(num) {
  return Number.isFinite(num) && num > 0;
}

export function isNumberBetween(num, first, last) {
  return num >= first && num <= last;
}

export function isUserInputNumeric(event) {
  const key = event.which;
  if (isKeyPressNumeric(key) && !isKeyPressArrowOrBackspace(key)) {
    event.preventDefault();
  }
}

export function isUserInputSlug(event) {
  if (/[^a-z0-9-]/.test(event.key)) {
    event.preventDefault();
  }
}

export function validateCuePoint(cuePoint) {
  const parsedCuePoint = parseFloatStrict(cuePoint);
  const decimal = parsedCuePoint.toString().split('.');
  if (
    !isNumberBetween(parsedCuePoint, 0, 99999.999) ||
    (decimal.length > 0 && decimal[1] && decimal[1].length > 3)
  ) {
    return false;
  }
  return true;
}

export function validateContentSeries(contentSeries) {
  if (!contentSeries.length) {
    return true;
  }
  if (contentSeries.length > 40) {
    return false;
  }
  const validMatchedCharacters = contentSeries.match(/[a-z0-9-]/g);
  if (validMatchedCharacters && validMatchedCharacters.length === contentSeries.length) {
    return true;
  }
  return false;
}
