import React from 'react';
import PropTypes from 'prop-types';
import { Input, Panel } from 'react-bootstrap';

class AdditionalMetadataInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSportTypeChange = this.onSportTypeChange.bind(this);
    this.onSportTypeDisplayNameChange = this.onSportTypeDisplayNameChange.bind(this);
  }

  onSportTypeChange(event) {
    this.props.updateSeason({
      sportType: event.target.value,
    });
  }

  onSportTypeDisplayNameChange(event) {
    this.props.updateSeason({
      sportTypeDisplayName: event.target.value,
    });
  }

  render() {
    return (
      <Panel header="Additional Metadata" bsStyle="primary">
        <Input
          type="select"
          label="Sport Type"
          ref="sportType"
          id="sportType"
          onChange={this.onSportTypeChange}
          value={
            this.props.season.sportType && this.props.season.sportType.id
              ? this.props.season.sportType.id
              : this.props.season.sportType
          }
        >
          <option selected value={null}></option>

          {this.props.seasonSportTypes.map((sportType, index) => {
            return (
              <option key={index} value={sportType.id}>
                {sportType.name}
              </option>
            );
          })}
        </Input>

        <Input
          type="text"
          label="Sport Type Display Text Override"
          ref="sportTypeDisplayText"
          id="sportTypeDisplayText"
          onChange={this.onSportTypeDisplayNameChange}
          value={this.props.season.sportTypeDisplayName}
        />
      </Panel>
    );
  }
}

AdditionalMetadataInputForm.propTypes = {
  season: PropTypes.object,
  updateSeason: PropTypes.func,
  seasonSportTypes: PropTypes.array,
};

export default AdditionalMetadataInputForm;
