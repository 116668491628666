import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import { startUpload } from 'src/scripts/actions/video';
import * as pagination from 'src/scripts/lib/pagination';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { closeModal } from 'src/scripts/actions/modalItem';
import { getEpisodeForIngestJobAndAssociatedData } from 'src/scripts/actions/ingestJobs';
import { FORM_GET_SEASONS_SUCCEEDED } from 'src/scripts/actions/season';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
export const GET_EPISODES_SUCCEEDED = 'GET_EPISODES_SUCCEEDED';
export const GET_EPISODES_FAILED = 'GET_EPISODES_FAILED';
export const GET_ALL_EPISODES_SUCCEEDED = 'GET_ALL_EPISODES_SUCCEEDED';
export const GET_ALL_EPISODES_FAILED = 'GET_ALL_EPISODES_FAILED';
export const SAVE_EPISODE_STARTED = 'SAVE_EPISODE_STARTED';
export const SAVE_EPISODE_FAILED = 'SAVE_EPISODE_FAILED';
export const SAVE_EPISODE_SUCCEEDED = 'SAVE_EPISODE_SUCCEEDED';
export const GET_EPISODE_AND_FORM_LISTS_SUCCEEDED = 'GET_EPISODE_AND_FORM_LISTS_SUCCEEDED';
export const GET_EPISODE_AND_FORM_LISTS_FAILED = 'GET_EPISODE_AND_FORM_LISTS_FAILED';
export const GET_EPISODE_SUCCEEDED = 'GET_EPISODE_SUCCEEDED';
export const GET_EPISODE_FAILED = 'GET_EPISODE_FAILED';
export const EPISODE_UPDATED = 'EPISODE_UPDATED';
export const DELETE_EPISODE_SUCCEEDED = 'DELETE_EPISODE_SUCCEEDED';
export const GET_EPISODE_FORM_INITIAL_DATA_SUCCEEDED = 'GET_EPISODE_FORM_INITIAL_DATA_SUCCEEDED';
export const CLEAR_DUPLICATE_EPISODE = 'CLEAR_DUPLICATE_EPISODE';

export function getEpisodes(params) {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit;
  const sort = params.sort ? params.sort : '-number';
  const request = {
    seasonId: params.seasonId,
    offset,
    limit,
    sort,
    ...params.filters,
  };
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_EPISODES_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_EPISODES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getEpisodes(request).then(onSuccess, onError);
  };
}

export function getAllEpisodes(params = {}) {
  return (dispatch) => {
    const offset = params.offset ? params.offset : 0;
    const limit = params.limit ? params.limit : pagination.pageSize;
    const sort = params.sort ? params.sort : '-updatedAt';
    const search = params.search ? params.search : null;
    const request = {
      offset,
      limit,
      sort,
      search,
      ...params.filters,
    };

    const onSuccess = (data) => {
      dispatch({
        type: GET_ALL_EPISODES_SUCCEEDED,
        data,
        offset,
        sort,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ALL_EPISODES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getAllEpisodes(request).then(onSuccess, onError);
  };
}

export function saveEpisode(episode) {
  return (dispatch) => {
    const saveMethod = episode.id ? tvVmsApi.updateEpisode : tvVmsApi.createEpisode;
    dispatch({
      type: SAVE_EPISODE_STARTED,
    });
    return saveMethod(episode)
      .done((createdEpisodeResponse) => {
        dispatch({
          type: SAVE_EPISODE_SUCCEEDED,
          data: createdEpisodeResponse,
        });

        if (createdEpisodeResponse.episode.videoId && episode.video.file) {
          const onUploadFailed = () => {
            tvVmsApi.notifyEpisodeUploadFailed(createdEpisodeResponse.episode.id);
          };

          tvVmsApi
            .notifyWorkflowAction('beforeUploadStarted', 'episode', createdEpisodeResponse.episode.id)
            .then(() => {
              dispatch(
                startUpload(
                  VIDEOS.TV,
                  {
                    videoId: createdEpisodeResponse.episode.videoId,
                    file: episode.video.file,
                    uploadType: 'video',
                  },
                  onUploadFailed
                )
              );
            });
        }

        dispatch(closeModal());
      })
      .fail((response) => {
        dispatch({
          type: SAVE_EPISODE_FAILED,
          error: response.responseJSON.message,
        });
      });
  };
}

export function updateEpisode(episode) {
  return {
    type: EPISODE_UPDATED,
    episode,
  };
}

export function updateEpisodeWithImportData(data) {
  return async (dispatch) => {
    if (data.partOfSeason && data.partOfSeries) {
      const seasonData = await tvVmsApi.getSeasons({
        tvSeriesId: data.partOfSeries.id,
      });
      dispatch({ type: FORM_GET_SEASONS_SUCCEEDED, data: seasonData });
    }
    dispatch({ type: EPISODE_UPDATED, episode: data });
  };
}

export function getEpisodeAndAssociatedData(episodeId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_EPISODE_AND_FORM_LISTS_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_EPISODE_AND_FORM_LISTS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi
      .getEpisodeById(episodeId)
      .then((data) => {
        const tvSeriesId = data.episode.partOfSeries.id;
        return tvVmsApi.getSeasons({ tvSeriesId }).then((seasonRes) => {
          data.seasons = seasonRes.seasons;
          return data;
        });
      })
      .then((data) => {
        if (data.episode.video.status === 'NEW') return data;
        return videosVmsApi.getSignedVideoSourceUrl(VIDEOS.TV, data.episode.video.id).then((res) => {
          data.episode.video.signedS3Url = res.url;
          return data;
        });
      })
      .then(onSuccess, onError);
  };
}

export function deleteEpisodeAndCloseConfirmation(episodeId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_EPISODE_SUCCEEDED,
        id: episodeId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteEpisode(episodeId).then(onSuccess, onError);
  };
}

export function getEpisode(episodeId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_EPISODE_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_EPISODE_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getEpisodeById(episodeId).then(onSuccess, onError);
  };
}

export function rescheduleAndSaveEpisode(episode) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EPISODE_STARTED,
    });
    const onSuccess = (savedEpisode) => {
      dispatch({
        type: SAVE_EPISODE_SUCCEEDED,
        data: savedEpisode,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_EPISODE_FAILED,
        error: response.responseJSON.message,
      });
    };
    tvVmsApi.rescheduleEpisode(episode).then(onSuccess, onError);
  };
}

export function getEpisodeFormInitialData(episodeId, ingestJobId, episodeSeedData = {}) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: GET_EPISODE_FORM_INITIAL_DATA_SUCCEEDED,
        episodeSeedData,
      });
    };
    if (episodeId) {
      return dispatch(getEpisodeAndAssociatedData(episodeId)).then(onSuccess);
    }
    if (ingestJobId) {
      return dispatch(getEpisodeForIngestJobAndAssociatedData(ingestJobId)).then(onSuccess);
    }
    return onSuccess();
  };
}

export function clearDuplicateEpisode() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DUPLICATE_EPISODE,
    });
  };
}
