import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Clipboard } from 'src/scripts/lib/clipboard';

class CopyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false };
  }

  componentDidMount() {
    const clipboard = new Clipboard(this.copyButton);
    clipboard.on('success', () => {
      this.setState({ showTooltip: true });
      setTimeout(() => {
        this.setState({ showTooltip: false });
      }, 1000);
    });
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <span
          className="copy-button"
          data-clipboard-text={this.props.textToCopy}
          ref={(copyButton) => {
            this.copyButton = copyButton;
          }}
        >
          <i className="fa fa-clipboard" />
          <Overlay container={this} target={this.copyButton} show={this.state.showTooltip} placement="left">
            <Tooltip>Copied to clipboard</Tooltip>
          </Overlay>
        </span>
      </div>
    );
  }
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string,
};

export default CopyButton;
