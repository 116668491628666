import {
  GET_GENRES_SUCCEEDED,
  GET_GENRES_FAILED,
  DELETE_GENRE_SUCCEEDED,
  SAVE_GENRE_SUCCEEDED,
} from 'src/scripts/actions/genre';
import { immutableRemoveById } from 'src/scripts/lib/util';
export const initialState = {
  genres: [],
  totalCount: 0,
  errorMessage: null,
  savedGenre: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GENRES_SUCCEEDED:
      return {
        ...state,
        genres: action.data.genres,
        totalCount: action.data.count,
        offset: action.offset,
        savedGenre: null,
      };

    case GET_GENRES_FAILED:
      return { ...state, errorMessage: action.error, savedGenre: null };

    case SAVE_GENRE_SUCCEEDED:
      return { ...state, savedGenre: action.data.genre };

    case DELETE_GENRE_SUCCEEDED:
      return { ...state, genres: immutableRemoveById(state.genres, action.id) };

    default:
      return state;
  }
}
