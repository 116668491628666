import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import ModalItem from 'src/scripts/components/ModalItem';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import CategoryFormLoader from 'src/scripts/components/Category/CategoryFormLoader';
import { getCategories, deleteCategoryAndCloseConfirmation } from 'src/scripts/actions/category';

export class CategoryList extends React.Component {
  componentDidUpdate() {
    if (this.props.savedCategory) {
      this.loadData();
    }
  }

  loadData() {
    this.props.getCategories();
  }

  render() {
    const actionList = (categoryItem) => {
      return (
        <NavDropdownMenu
          key={categoryItem.id}
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          className="pull-left"
          id={`category-item-button-${categoryItem.id}`}
        >
          <ModalItem
            key={categoryItem.id}
            title="Edit Category"
            form={<CategoryFormLoader categoryId={categoryItem.id} />}
          />
          <ModalItem
            title="Delete Category"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${categoryItem.name}" category. Are you sure you wish to proceed?`}
                ok={this.props.deleteCategoryAndCloseConfirmation.bind(this, categoryItem.id)}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        </NavDropdownMenu>
      );
    };
    const list = {
      pageData: this.props.categories,
      totalCount: this.props.totalCount,
    };

    const breadcrumbs = ['Categories'];

    return (
      <div>
        <ContextNavigation breadcrumbList={breadcrumbs} />
        <ItemList id="list-category" list={list} disableColumnChooser getItems={this.props.getCategories}>
          <ItemListHeader
            label="Name"
            dataField="name"
            dataFormat={(categoryItem) => {
              return (
                <a href={`#/network/categories/${categoryItem.id}/subcategories`}>{categoryItem.name}</a>
              );
            }}
          />
          <ItemListHeader label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader label="Actions" dataFormat={(categoryItem) => actionList(categoryItem)} />
        </ItemList>
      </div>
    );
  }
}

CategoryList.propTypes = {
  totalCount: PropTypes.number,
  categories: PropTypes.array,
  savedCategory: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getCategories: PropTypes.func,
  deleteCategoryAndCloseConfirmation: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    categories: state.categoryList.categories,
    totalCount: state.categoryList.totalCount,
    savedCategory: state.categoryList.savedCategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategories: () => dispatch(getCategories()),
    deleteCategoryAndCloseConfirmation: (categoryId) =>
      dispatch(deleteCategoryAndCloseConfirmation(categoryId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
