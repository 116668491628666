import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';

const LicensePeriod = ({
  onFastChannelLicensingStartDateChange,
  onFastChannelLicensingEndDateChange,
  fastChannelLicensingStartDate,
  fastChannelLicensingEndDate,
  validationErrors = {},
  episode,
}) => (
  <Panel header="License Period" bsStyle="primary" expanded={false}>
    <div className={`form-group ${validationErrors.fastChannelLicensingStartDate ? 'error' : ''}`}>
      <label>Start Date & Time</label>
      <DateTimePicker
        onChange={onFastChannelLicensingStartDateChange}
        bsStyle={validationErrors.fastChannelLicensingStartDate ? 'error' : null}
        value={fastChannelLicensingStartDate}
        placeholder="Enter license period start date"
        disabled={!episode.id}
      />
    </div>
    <div className={`form-group ${validationErrors.fastChannelLicensingEndDate ? 'error' : ''}`}>
      <label>End Date & Time</label>
      <DateTimePicker
        value={fastChannelLicensingEndDate}
        bsStyle={validationErrors.fastChannelLicensingEndDate ? 'error' : null}
        onChange={onFastChannelLicensingEndDateChange}
        placeholder="Enter license period end date"
        disabled={!episode.id}
      />
    </div>
    {episode.id ? (
      <div>Update license dates for the episode</div>
    ) : (
      <div>
        During creation these values are inherit from Season. It can be edited while udpating existing
        episode.
      </div>
    )}
  </Panel>
);

LicensePeriod.propTypes = {
  onFastChannelLicensingStartDateChange: PropTypes.func,
  onFastChannelLicensingEndDateChange: PropTypes.func,
  fastChannelLicensingStartDate: PropTypes.string,
  fastChannelLicensingEndDate: PropTypes.string,
  validationErrors: PropTypes.object,
  episode: PropTypes.object,
};

export default LicensePeriod;
