import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Input } from 'react-bootstrap';
import { allCountries, commonCountries } from 'src/scripts/lib/countries';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';

class CountriesSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const selectedCountry = !isDefaultOptionSelected(this.refs.selectCountry) ? event.target.value : null;
    this.props.onChange(selectedCountry);
  }

  render() {
    const allExceptCommonCountries = _.filter(allCountries, (country) => {
      return _.indexOf(_.map(commonCountries, 'name'), country.name) === -1;
    });

    const commonCountriesOptions = commonCountries.map((country) => {
      return (
        <option value={country.name} key={country.alpha3 || country.alpha2}>
          {country.name}
        </option>
      );
    });

    const allExceptCommonCountriesOptions = allExceptCommonCountries.map((country) => {
      return (
        <option value={country.name} key={country.alpha3 || country.alpha2}>
          {country.name}
        </option>
      );
    });

    return (
      <Input
        type="select"
        label="Country of Origin"
        ref="selectCountry"
        id="select-country-of-origin"
        onChange={this.onChange}
        value={this.props.country}
        data-pw="select-country-of-origin"
      >
        <option value="0">Select a country of origin</option>
        {commonCountriesOptions}
        {allExceptCommonCountriesOptions}
      </Input>
    );
  }
}

CountriesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  country: PropTypes.string,
};

export default CountriesSelect;
