import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Col, Row } from 'react-bootstrap';
import { format as formatDate } from 'src/scripts/lib/dateFormatter';
import { formatDurationFromMiliseconds } from 'src/scripts/lib/timeFormatter';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import LiveEventDetailsToolbar from 'src/scripts/components/LiveEvent/internal/LiveEventDetailsToolbar';
import VideoPlayer from 'src/scripts/components/Video/VideoPlayer';
import { isRemoteEvent, isBroadcastEvent, isFastChannel } from 'src/scripts/lib/liveEventTypes';
import { SCHEDULED_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import { getContentOrDashes } from 'src/scripts/lib/stringFormatter';
import { liveEventImageTypeData } from '../../../lib/liveEventImageTypes.js';
import * as zypeVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/zype';
import { calculateLiveEventStatus } from '../../../lib/liveEventStatus';

export default class LiveEventDetails extends React.Component {
  constructor() {
    super();
    this.renderAvailabilityPanel = this.renderAvailabilityPanel.bind(this);
  }

  componentDidMount() {
    this.statusUpdateTimer = setInterval(() => {
      this.props.updateLiveEventStatus();
    }, 5000);
    if (this.props.liveEvent.isFastChannel) this.fetchFastChannelName(this.props.liveEvent.fastChannel);
  }

  componentWillUnmount() {
    clearInterval(this.statusUpdateTimer);
    this.statusUpdateTimer = undefined;
  }

  async fetchFastChannelName(channelId) {
    const fastChannel =
      channelId === 'external' ? { title: 'External' } : await zypeVmsApi.getChannelDetails(channelId);
    this.setState({ fastChannelName: fastChannel.title });
  }

  getEventTypeName() {
    if (isRemoteEvent(this.props.liveEvent)) return 'Remote';
    if (isBroadcastEvent(this.props.liveEvent)) return 'Broadcast';
    return '---';
  }

  renderAvailabilityPanel() {
    if (this.props.liveEvent.library === NETWORK) {
      return (
        <Panel className="details-section-panel availability-panel" header="Availability">
          <DetailsRowItem label="Hide Strap">
            {this.props.liveEvent.hideStrap ? 'True' : 'False'}
          </DetailsRowItem>
        </Panel>
      );
    }
    return null;
  }

  renderRemoteUrlField() {
    if (isRemoteEvent(this.props.liveEvent)) {
      return <DetailsRowItem label="Remote URL">{this.props.liveEvent.remoteURL}</DetailsRowItem>;
    }
    return null;
  }

  renderPhoenixIdentifierField() {
    const label = this.props.liveEvent.catalogCodes ? 'Catalog Codes' : 'Material Keys';
    const value = this.props.liveEvent.catalogCodes || this.props.liveEvent.materialKeys;
    return <DetailsRowItem label={label}>{value.join(', ')}</DetailsRowItem>;
  }

  renderPositionField() {
    if (this.props.liveEvent.library === NETWORK) {
      return <DetailsRowItem label="Position">{this.props.liveEvent.position}</DetailsRowItem>;
    }
    return null;
  }

  renderSsaiRemoteUrlField() {
    if (isRemoteEvent(this.props.liveEvent) && this.props.liveEvent.library !== NETWORK) {
      return <DetailsRowItem label="SSAI Remote URL">{this.props.liveEvent.ssaiRemoteURL}</DetailsRowItem>;
    }
    return null;
  }

  renderFastChannelField() {
    if (isFastChannel(this.props.liveEvent)) {
      return (
        <DetailsRowItem label="FAST Channel">
          {this.state ? this.state.fastChannelName : 'retrieving ...'}
        </DetailsRowItem>
      );
    }
    return null;
  }

  renderTrackingPanel() {
    if (this.props.liveEvent.library === TV && isRemoteEvent(this.props.liveEvent)) {
      return (
        <Panel className="details-section-panel tracking-panel" header="Tracking">
          <DetailsRowItem label="Oztam Publisher ID">{this.props.liveEvent.oztamPublisherId}</DetailsRowItem>
        </Panel>
      );
    }
    return null;
  }

  renderMonetisationPanel() {
    if (isRemoteEvent(this.props.liveEvent)) {
      return (
        <Panel className="details-section-panel monetisation-panel" header="Monetisation">
          <DetailsRowItem label="Video Group">{this.props.liveEvent.videoGroupId}</DetailsRowItem>
          <DetailsRowItem label="Full Slug">{this.props.liveEvent.fullSlug}</DetailsRowItem>
          {this.renderPreRollField()}
          {this.renderAdInsertionEnabledField()}
          {this.renderContentTargetingCategoriesField()}
        </Panel>
      );
    }
    return null;
  }

  renderContentTargetingCategoriesField() {
    return (
      <DetailsRowItem label="Content Targeting Categories">
        {getContentOrDashes(
          this.props.liveEvent.contentTargetingCategories &&
            this.props.liveEvent.contentTargetingCategories.join(', ')
        )}
      </DetailsRowItem>
    );
  }

  renderSubtitleField() {
    if (this.props.liveEvent.library === TV) {
      return (
        <DetailsRowItem label="Subtitle">{getContentOrDashes(this.props.liveEvent.subtitle)}</DetailsRowItem>
      );
    }
    return null;
  }

  renderIdentifiersPanel = () => (
    <Panel className="details-section-panel identifiers-panel" header="Identifiers">
      <DetailsRowItem label="VMS Reference ID">{this.props.liveEvent.referenceId}</DetailsRowItem>
      {this.renderBrightcoveIdentifiers()}
      {this.props.liveEvent.library === TV ? (
        <DetailsRowItem label="External ID">
          {getContentOrDashes(this.props.liveEvent.externalId)}
        </DetailsRowItem>
      ) : null}
    </Panel>
  );

  renderNotesPanel = () => {
    return (
      <Panel className="details-section-panel notes-panel" header="Notes">
        <DetailsRowItem id="producer-notes" label="Producer Notes">
          {getContentOrDashes(this.props.liveEvent.producerNotes)}
        </DetailsRowItem>
      </Panel>
    );
  };

  renderLiveEventGroupField() {
    if (this.props.liveEvent.library === TV) {
      return (
        <DetailsRowItem label="Live Event Group">
          {getContentOrDashes(
            this.props.liveEvent.liveEventGroup && this.props.liveEvent.liveEventGroup.name
          )}
        </DetailsRowItem>
      );
    }
    return null;
  }

  renderPreRollField() {
    if (this.props.liveEvent.library === NETWORK) {
      return <DetailsRowItem label="Pre-roll">{this.props.liveEvent.preRoll ? 'Yes' : 'No'}</DetailsRowItem>;
    }
    return null;
  }

  renderAdInsertionEnabledField() {
    if (this.props.liveEvent.library === TV) {
      return (
        <DetailsRowItem label="Ad Insertion Enabled">
          {this.props.liveEvent.ssaiEnabled ? 'Yes' : 'No'}
        </DetailsRowItem>
      );
    }
    return null;
  }

  renderBrightcoveIdentifiers() {
    if (isRemoteEvent(this.props.liveEvent)) {
      return (
        <div>
          <DetailsRowItem label="Brightcove ID">
            {getContentOrDashes(this.props.liveEvent.brightcoveId)}
          </DetailsRowItem>
          {this.props.liveEvent.library === TV && (
            <DetailsRowItem label="SSAI Brightcove ID">
              {getContentOrDashes(this.props.liveEvent.ssaiBrightcoveId)}
            </DetailsRowItem>
          )}
        </div>
      );
    }
    return null;
  }

  renderLiveEventImages() {
    const getImageUrl = (imageType) => {
      const matchedImageForType = this.props.liveEvent.images.find((image) => image.type === imageType);

      if (!matchedImageForType) return IMAGE_NOT_FOUND_URL;

      return matchedImageForType.url;
    };

    return (
      <Panel className="details-section-panel artwork-panel" header={'Artwork'}>
        <h4>Image</h4>
        <img
          className="details-image"
          src={(this.props.liveEvent.image && this.props.liveEvent.image.url) || IMAGE_NOT_FOUND_URL}
        />
        <hr />
        <h4>Logo</h4>
        <img
          className="details-image"
          src={(this.props.liveEvent.logo && this.props.liveEvent.logo.url) || IMAGE_NOT_FOUND_URL}
        />
        <hr />
        {liveEventImageTypeData.map((imageType) => {
          return (
            <div>
              <h4>{imageType.header}</h4>
              <img className="details-image" src={getImageUrl(imageType.type)} />
              <hr />
            </div>
          );
        })}
      </Panel>
    );
  }

  renderUpNextPanel() {
    if (this.props.liveEvent.library.toLowerCase() === TV.toLowerCase()) {
      const { name, subtitle, description, genre, classificationCodeName, programStartTime, programEndTime } =
        this.props.liveEvent.upNextEvent || {};
      return (
        <Panel
          data-pw="up-next-panel"
          className="details-section-panel up-next-panel"
          header="Metadata For Up Next"
        >
          <DetailsRowItem label="Name">{getContentOrDashes(name)}</DetailsRowItem>
          <DetailsRowItem label="Subtitle">{getContentOrDashes(subtitle)}</DetailsRowItem>
          <DetailsRowItem label="Description">{getContentOrDashes(description)}</DetailsRowItem>
          <DetailsRowItem label="Genre">{getContentOrDashes(genre)}</DetailsRowItem>
          <DetailsRowItem label="Classification Rating">
            {getContentOrDashes(classificationCodeName)}
          </DetailsRowItem>
          <DetailsRowItem label="Start Date & Time">
            {getContentOrDashes(formatDate(programStartTime))}
          </DetailsRowItem>
          <DetailsRowItem label="End Date & Time">
            {getContentOrDashes(formatDate(programEndTime))}
          </DetailsRowItem>
        </Panel>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <ol className="mi9-breadcrumb breadcrumb">
          <li>
            <a href={`/#/live/${this.props.liveEvent.library.toLowerCase()}/events`}>
              {this.props.liveEvent.library} Live Events
            </a>
          </li>
          <li>{this.props.liveEvent.name}</li>
        </ol>
        <LiveEventDetailsToolbar
          ref="liveEventDetailsToolbar"
          liveEvent={this.props.liveEvent}
          deleteLiveEvent={this.props.deleteLiveEvent}
          launchInstantLiveEvent={this.props.launchInstantLiveEvent}
          endInstantLiveEvent={this.props.endInstantLiveEvent}
        />
        <Row>
          <Col md={6}>
            <Panel className="details-section-panel metadata-panel" header="Metadata">
              <DetailsRowItem label="Name">{this.props.liveEvent.name}</DetailsRowItem>
              {this.renderSubtitleField()}
              <DetailsRowItem label="Description">{this.props.liveEvent.description}</DetailsRowItem>
              <DetailsRowItem label="Library">{this.props.liveEvent.library}</DetailsRowItem>
              {this.renderPositionField()}
              <DetailsRowItem label="Genre">{getContentOrDashes(this.props.liveEvent.genre)}</DetailsRowItem>
              <DetailsRowItem label="Classification Rating">
                {getContentOrDashes(this.props.liveEvent.classificationCodeName)}
              </DetailsRowItem>
              <DetailsRowItem label="Start Date & Time">
                {getContentOrDashes(formatDate(this.props.liveEvent.programStartTime))}
              </DetailsRowItem>
              <DetailsRowItem label="End Date & Time">
                {getContentOrDashes(formatDate(this.props.liveEvent.programEndTime))}
              </DetailsRowItem>
              {this.props.liveEvent.partOfSeries ? (
                <DetailsRowItem label="TV Series">{this.props.liveEvent.partOfSeries.name}</DetailsRowItem>
              ) : (
                ''
              )}
              {this.props.liveEvent.partOfSeason ? (
                <DetailsRowItem label="Season">{this.props.liveEvent.partOfSeason.name}</DetailsRowItem>
              ) : (
                ''
              )}
              <DetailsRowItem label="Status">{calculateLiveEventStatus(this.props.liveEvent)}</DetailsRowItem>
              {this.renderLiveEventGroupField()}
            </Panel>
            {this.renderUpNextPanel()}
            <Panel className="details-section-panel scheduling-panel" header="Scheduling">
              <DetailsRowItem label="Event Type">{this.getEventTypeName()}</DetailsRowItem>
              <DetailsRowItem label="Schedule Type">
                {this.props.liveEvent.scheduleType === SCHEDULED_EVENT ? 'Scheduled Event' : 'Instant Event'}
              </DetailsRowItem>
              {isBroadcastEvent(this.props.liveEvent) && this.renderPhoenixIdentifierField()}
              {this.renderRemoteUrlField()}
              {this.renderSsaiRemoteUrlField()}
              {this.renderFastChannelField()}
              <DetailsRowItem label="Promotion Start Date">
                {formatDate(this.props.liveEvent.promoStartDate)}
              </DetailsRowItem>
              <DetailsRowItem label="Availability Start Date">
                {formatDate(this.props.liveEvent.availabilityStartDate)}
              </DetailsRowItem>
              <DetailsRowItem label="Availability End Date">
                {formatDate(this.props.liveEvent.availabilityEndDate)}
              </DetailsRowItem>
              <DetailsRowItem label="Duration (dd:hh:mm:ss)">
                {formatDurationFromMiliseconds(this.props.liveEvent.duration)}
              </DetailsRowItem>
              <DetailsRowItem label={'Preview'}>{this.props.liveEvent.preview ? 'Yes' : 'No'}</DetailsRowItem>
            </Panel>
            {this.renderAvailabilityPanel()}
            {this.renderTrackingPanel()}
            {this.renderMonetisationPanel()}
            {this.renderIdentifiersPanel()}
            {this.renderNotesPanel()}
          </Col>
          <Col md={6}>
            {isRemoteEvent(this.props.liveEvent) && (
              <Row className="details-row">
                <VideoPlayer
                  ref="videoPlayer"
                  url={this.props.liveEvent.remoteURL}
                  type="application/vnd.apple.mpegurl"
                  autoplay
                />
              </Row>
            )}
            {this.renderLiveEventImages()}
          </Col>
        </Row>
      </div>
    );
  }
}

LiveEventDetails.propTypes = {
  deleteLiveEvent: PropTypes.func,
  launchInstantLiveEvent: PropTypes.func,
  endInstantLiveEvent: PropTypes.func,
  errorMessage: PropTypes.string,
  liveEvent: PropTypes.object,
  updateLiveEventStatus: PropTypes.func,
};
