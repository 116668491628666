import PropTypes from 'prop-types';
import React from 'react';

export class LoadingIcon extends React.Component {
  render() {
    if (!this.props.loading) {
      return null;
    }
    return <i className={`fa fa-spinner fa-spin${this.props.className ? ` ${this.props.className}` : ''}`} />;
  }
}

LoadingIcon.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default LoadingIcon;
