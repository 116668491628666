import { isEmpty, isArray } from 'lodash';

export const STATUS_REQUESTED = 'REQUESTED';
export const STATUS_ERROR = 'ERROR';
export const STATUS_DONE = 'DONE';
export const STATUS_PASSED = 'PASSED';
export const STATUS_FAILED = 'FAILED';

const STATUS_LABELS = {
  [STATUS_REQUESTED]: 'Requested',
  [STATUS_ERROR]: 'Error',
  [STATUS_PASSED]: 'Passed',
  [STATUS_FAILED]: 'Failed',
};

const STATUS_COLOUR_DEFAULT = 'black';
const STATUS_COLOURS = {
  [STATUS_REQUESTED]: STATUS_COLOUR_DEFAULT,
  [STATUS_ERROR]: 'red',
  [STATUS_PASSED]: 'green',
  [STATUS_FAILED]: 'red',
};

const DURATION_COMPATIBLE_THRESHOLD = 1;

export class Duration {
  constructor({ start, end, duration }, label) {
    this.start = start;
    this.end = end;
    this.duration = duration;
    this.label = label;
  }

  // using the original data or the floor value
  getStart(usingOriginal = false) {
    return usingOriginal ? this.start : Math.floor(this.start);
  }

  // using the original data or the ceiling value
  getEnd(usingOriginal = false) {
    return usingOriginal ? this.end : Math.ceil(this.end);
  }

  // using the original data or the difference between start(floor) and end(ceil)
  getDuration(usingOriginal = false) {
    return usingOriginal ? this.duration : this.getEnd(usingOriginal) - this.getStart(usingOriginal);
  }

  getLabel() {
    return this.label;
  }
}

class VideoAutoQcBlack extends Duration {
  constructor(data) {
    super(data, 'video');
  }
}

class VideoAutoQcSilent extends Duration {
  constructor(data) {
    super(data, 'audio');
  }
}

class VideoAutoQcMerged {
  /**
   * @param durations{Duration[]}
   */
  constructor(durations) {
    this.durations = durations;
  }

  getStart(usingOriginal = false) {
    const allStarts = this.durations.map((duration) => duration.getStart(usingOriginal));
    return Math.min(...allStarts);
  }

  getEnd(usingOriginal = false) {
    const allEnds = this.durations.map((duration) => duration.getEnd(usingOriginal));
    return Math.max(...allEnds);
  }

  getDuration(usingOriginal = false) {
    return this.getEnd(usingOriginal) - this.getStart(usingOriginal);
  }

  getLabel() {
    return this.durations.map((duration) => duration.getLabel()).join(' / ');
  }
}

/**
 * @param aDuration{Duration}
 * @param bDuration{Duration}
 * @returns {boolean}
 */
function isDurationCompatible(aDuration, bDuration) {
  return (
    Math.abs(aDuration.getStart() - bDuration.getStart()) <= DURATION_COMPATIBLE_THRESHOLD &&
    Math.abs(aDuration.getEnd() - bDuration.getEnd()) <= DURATION_COMPATIBLE_THRESHOLD
  );
}

/**
 * @param aDuration{Duration}
 * @param bDuration{Duration}
 * @returns {boolean}
 */
function isProceeding(aDuration, bDuration) {
  return aDuration.getStart() < bDuration.getStart();
}

/**
 * @param aDurations{Duration[]}
 * @param bDurations{Duration[]}
 * @returns {(Duration|VideoAutoQcMerged)[]}
 */
export function mergeCompatibleDurations(aDurations, bDurations) {
  if (aDurations.length === 0) {
    return bDurations;
  }

  if (bDurations.length === 0) {
    return aDurations;
  }

  if (isDurationCompatible(aDurations[0], bDurations[0])) {
    return [new VideoAutoQcMerged([aDurations[0], bDurations[0]])].concat(
      mergeCompatibleDurations(aDurations.slice(1), bDurations.slice(1))
    );
  }

  if (isProceeding(aDurations[0], bDurations[0])) {
    return [aDurations[0]].concat(mergeCompatibleDurations(aDurations.slice(1), bDurations));
  }

  return [bDurations[0]].concat(mergeCompatibleDurations(aDurations, bDurations.slice(1)));
}

export default class VideoAutoQc {
  constructor(videoAutoQc) {
    this.videoAutoQc = videoAutoQc;
  }

  getStatus() {
    if (this.videoAutoQc.status === STATUS_ERROR) {
      return STATUS_ERROR;
    }

    if (this.videoAutoQc.status === STATUS_REQUESTED) {
      return STATUS_REQUESTED;
    }

    if (this.videoAutoQc.status === STATUS_DONE) {
      return isEmpty(this.videoAutoQc.violations) ? STATUS_PASSED : STATUS_FAILED;
    }

    return '';
  }

  getStatusLabel() {
    return STATUS_LABELS[this.getStatus()] || '';
  }

  getStatusColour() {
    return STATUS_COLOURS[this.getStatus()] || STATUS_COLOUR_DEFAULT;
  }

  isFailed() {
    return this.getStatus() === STATUS_FAILED;
  }

  getBlacks() {
    if (!this.videoAutoQc.violations || !isArray(this.videoAutoQc.violations.black)) {
      return [];
    }

    return this.videoAutoQc.violations.black.map((entry) => new VideoAutoQcBlack(entry));
  }

  getSilents() {
    if (!this.videoAutoQc.violations || !isArray(this.videoAutoQc.violations.silent)) {
      return [];
    }

    return this.videoAutoQc.violations.silent.map((entry) => new VideoAutoQcSilent(entry));
  }
}
