import { BULK_ACTION_INGEST_JOB_DELETED } from 'src/scripts/actions/bulkAction';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { doBulkAction } from 'src/scripts/actions/bulkAction';
import { INGEST_JOB } from 'src/scripts/lib/modelNames';

function deleteIngestJob(ingestJobId, onSuccess, onError) {
  return tvVmsApi.deleteIngestJob(ingestJobId).then(onSuccess.bind(null, { id: ingestJobId }), onError);
}

export function deleteIngestJobs(ingestJobIds) {
  return doBulkAction(ingestJobIds, deleteIngestJob, 'delete', INGEST_JOB, BULK_ACTION_INGEST_JOB_DELETED);
}
