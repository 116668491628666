import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { ButtonToolbar, Col, Panel, Row } from 'react-bootstrap';
import { TV_PATH } from 'src/scripts/lib/accessController';
import ContextNavigation from '../ContextNavigation';
import DetailsRowItem from '../MediaItem/DetailsRowItem';
import ActionEditGenre from './actions/Edit';
import ActionDeleteGenre from './actions/Delete';
import { getGenreById } from '../../actions/genre';
import ViewLoader from '../ViewLoader';
import history from 'src/scripts/lib/history';
import { getContentOrDashes } from 'src/scripts/lib/stringFormatter';
import { IMAGE_NOT_FOUND_URL } from '../../constants/common';
import { genreImageTypeData } from '../../lib/genreImageTypes.js';
import { GENRE_IMAGE, GENRE_FEATURED_IMAGE, GENRE_FEATURED_POSTER_IMAGE } from '../../lib/imageTypes.js';

function navigateToGenreList() {
  const pathname = `/${TV_PATH}/genres`;
  history.replace({ state: null, pathname, query: { page: 1 } });
}

export class GenreDetailsView extends React.Component {
  componentDidMount() {
    if (this.props.params.genreId) {
      this.props.getGenre(this.props.params.genreId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(nextProps.genre) && !_.isEmpty(this.props.genre)) {
      navigateToGenreList();
    }
  }

  getBreadcrumbList() {
    return [<a href={`/#/${TV_PATH}/genres`}>Genres</a>, this.props.genre.name];
  }

  getActionsRow() {
    return (
      <Row className="details-toolbar">
        <ButtonToolbar className="pull-right toolbar-buttons">
          <ActionEditGenre displayType={'button'} genre={this.props.genre} />
          <ActionDeleteGenre displayType={'button'} genre={this.props.genre} />
        </ButtonToolbar>
      </Row>
    );
  }

  getImages() {
    const getImageUrl = (imageType) => {
      if (imageType === GENRE_IMAGE && this.props.genre.image) {
        return this.props.genre.image.url;
      } else if (
        (imageType === GENRE_FEATURED_IMAGE || imageType === GENRE_FEATURED_POSTER_IMAGE) &&
        this.props.genre.images &&
        this.props.genre.images.length
      ) {
        const imageResult = this.props.genre.images.find((storedImage) => storedImage.type === imageType);
        if (!imageResult) {
          return IMAGE_NOT_FOUND_URL;
        }

        return imageResult.url;
      }

      return IMAGE_NOT_FOUND_URL;
    };

    return (
      <div>
        {genreImageTypeData.map((imageTypeData) => {
          return (
            <Panel header={imageTypeData.header}>
              <div className={imageTypeData.wrapperClassName}>
                <img
                  alt={this.props.genre.image ? this.props.genre.image.alt : 'Genre-Fixture'}
                  className="genre-image"
                  src={getImageUrl(imageTypeData.type)}
                />
              </div>
            </Panel>
          );
        })}
      </div>
    );
  }

  getDetailsRow() {
    return (
      <Row>
        <Col md={6}>
          <Panel header={'Metadata'} collapsible defaultExpanded>
            <DetailsRowItem id={'genre-name'} label={'Genre Name'}>
              {getContentOrDashes(this.props.genre.name)}
            </DetailsRowItem>
            <DetailsRowItem id={'slug'} label={'Slug'}>
              {getContentOrDashes(this.props.genre.slug)}
            </DetailsRowItem>
            <DetailsRowItem id={'description'} label={'Genre Description'}>
              {getContentOrDashes(this.props.genre.description)}
            </DetailsRowItem>
            <DetailsRowItem id={'date-created'} label={'Date Created'}>
              {moment(this.props.genre.createdAt).format('YYYY-MM-DD hh:mm:ss')}
            </DetailsRowItem>
            <DetailsRowItem id={'date-updated'} label={'Date Updated'}>
              {moment(this.props.genre.updatedAt).format('YYYY-MM-DD hh:mm:ss')}
            </DetailsRowItem>
          </Panel>

          <Panel header={'SEO Metadata'} collapsible defaultExpanded>
            <DetailsRowItem id={'seo-title'} label={'Seo Title'}>
              {getContentOrDashes(this.props.genre.seoTitle)}
            </DetailsRowItem>
            <DetailsRowItem id={'seo-description'} label={'Seo Description'}>
              {getContentOrDashes(this.props.genre.seoDescription)}
            </DetailsRowItem>
          </Panel>
        </Col>
        <Col md={6}>{this.getImages()}</Col>
      </Row>
    );
  }

  isLoading() {
    return _.isEmpty(this.props.genre);
  }

  showDetails() {
    if (this.isLoading()) {
      return null;
    }

    return (
      <div>
        <ContextNavigation breadcrumbList={this.getBreadcrumbList()} />
        {this.getActionsRow()}
        {this.getDetailsRow()}
      </div>
    );
  }

  render() {
    return <ViewLoader loading={this.isLoading()}>{this.showDetails()}</ViewLoader>;
  }
}

const mapStateToProps = (state) => ({
  genre: state.genreDetailsView.genre,
  error: state.genreDetailsView.error,
});

const mapDispatchToProps = (dispatch) => ({
  getGenre: (genreId) => dispatch(getGenreById(genreId)),
});

GenreDetailsView.propTypes = {
  params: PropTypes.object.isRequired,
  genre: PropTypes.object,
  error: PropTypes.object,
  getGenre: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenreDetailsView);
