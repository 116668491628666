import moment from 'moment';
import {
  BULK_ACTION_CLIP_UPDATED,
  BULK_ACTION_CLIP_DELETED,
  BULK_ACTION_CLIP_DOWNLOADED,
  BULK_ACTION_TYPE_DOWNLOAD,
} from 'src/scripts/actions/bulkAction';
import { TRANSCODE_VIDEOCLOUD_SUCCEEDED } from 'src/scripts/actions/video';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videoVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import { doBulkAction } from 'src/scripts/actions/bulkAction';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { CLIP } from 'src/scripts/lib/modelNames';
import { downloadStreamFromUrl } from 'src/scripts/lib/downloadFile';

function expireClip(clipId, onSuccess, onError) {
  return tvVmsApi.updateClip({ id: clipId, expiry: moment().format() }).then(onSuccess, onError);
}

export function expireClips(clipIds) {
  return doBulkAction(clipIds, expireClip, 'expire', CLIP, BULK_ACTION_CLIP_UPDATED);
}

function getEditClipScheduleFn(clipScheduleData) {
  return (clipId, onSuccess, onError) => {
    return tvVmsApi.updateClip({ id: clipId, ...clipScheduleData }).then(onSuccess, onError);
  };
}

export function editClipSchedules(clipIds, clipScheduleData) {
  return doBulkAction(
    clipIds,
    getEditClipScheduleFn(clipScheduleData),
    'edit',
    CLIP,
    BULK_ACTION_CLIP_UPDATED
  );
}

function getAssignEpisodeFn(episodeData) {
  return (clipId, onSuccess, onError) => {
    return tvVmsApi.updateClip({ id: clipId, ...episodeData }).then(onSuccess, onError);
  };
}

export function assignClipsToEpisode(clipIds, episodeData) {
  return doBulkAction(clipIds, getAssignEpisodeFn(episodeData), 'edit', CLIP, BULK_ACTION_CLIP_UPDATED);
}

function getRescheduleAndSaveClipFn(clipScheduleData) {
  return (clipId, onSuccess, onError) => {
    return tvVmsApi.rescheduleClip({ id: clipId, ...clipScheduleData }).then(onSuccess, onError);
  };
}

export function rescheduleAndSaveClips(clipIds, clipScheduleData) {
  return doBulkAction(
    clipIds,
    getRescheduleAndSaveClipFn(clipScheduleData),
    'reschedule',
    CLIP,
    BULK_ACTION_CLIP_UPDATED
  );
}

function deleteClip(clipId, onSuccess, onError) {
  return tvVmsApi.deleteClip(clipId).then(onSuccess.bind(null, { id: clipId }), onError);
}

export function deleteClips(clipIds) {
  return doBulkAction(clipIds, deleteClip, 'delete', CLIP, BULK_ACTION_CLIP_DELETED);
}

function downloadClip(videoId, onSuccess, onError) {
  return videoVmsApi
    .getSignedVideoSourceUrl(VIDEOS.TV, videoId)
    .then((response) => downloadStreamFromUrl(response.url, null))
    .then(onSuccess.bind(null, { id: videoId }), onError);
}

export function downloadClips(videoIds) {
  return doBulkAction(videoIds, downloadClip, BULK_ACTION_TYPE_DOWNLOAD, CLIP, BULK_ACTION_CLIP_DOWNLOADED);
}

function transcodeClip(videoId, onSuccess, onError) {
  return videoVmsApi
    .transcodeBrightcoveVideoCloud(VIDEOS.TV, videoId)
    .then(onSuccess.bind(null, { id: videoId }), onError);
}

export function transcodeClips(videoIds) {
  return doBulkAction(videoIds, transcodeClip, 'transcode', CLIP, TRANSCODE_VIDEOCLOUD_SUCCEEDED);
}
