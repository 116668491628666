import PropTypes from 'prop-types';
import React from 'react';

class NETWORK extends React.Component {
  render() {
    return this.props.children;
  }
}

NETWORK.propTypes = {
  children: PropTypes.object,
};

export default NETWORK;
