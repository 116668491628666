import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Panel } from 'react-bootstrap';
import { updateSubcategory, saveSubcategory } from 'src/scripts/actions/subcategory';
import { validateContentSeries } from 'src/scripts/lib/validation';
import { CONTENT_GROUPS } from 'src/scripts/lib/videoAdMetadataGroups';
import MultiInputList from 'src/scripts/components/MultiInputList';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/subcategoryForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

export class SubcategoryForm extends React.Component {
  constructor(props) {
    super(props);
    const mode = this.props.subcategory.id ? 'edit' : 'add';
    this.state = { mode };
    this.saveSubcategory = this.saveSubcategory.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onGeoBlockedChange = this.onGeoBlockedChange.bind(this);
    this.onContentGroupChange = this.onContentGroupChange.bind(this);
  }

  onNameChange(event) {
    this.props.updateSubcategory({
      name: event.target.value,
    });
  }

  onCategoryChange(event) {
    const categoryId = parseInt(event.target.value, 10);
    this.props.updateSubcategory({
      categoryId: categoryId > 0 ? categoryId : null,
    });
  }

  onAvailableContentSeriesChange(availableContentSeries) {
    this.props.updateSubcategory({
      availableContentSeries,
    });
  }

  onGeoBlockedChange(event) {
    this.props.updateSubcategory({
      geoBlocked: event.target.checked,
    });
  }

  onContentGroupChange(event) {
    this.props.updateSubcategory({
      contentGroup: event.target.value,
    });
  }

  isSubmitDisabled() {
    return this.props.saving;
  }

  saveSubcategory(event) {
    event.preventDefault();
    const nonEmptyContentSeries = this.props.subcategory.availableContentSeries
      ? this.props.subcategory.availableContentSeries.filter((contentSeries) => contentSeries)
      : null;
    const subcategory = {
      id: this.props.subcategory.id,
      name: this.props.subcategory.name,
      categoryId: this.props.subcategory.categoryId,
      geoBlocked: this.props.subcategory.geoBlocked,
      availableContentSeries: nonEmptyContentSeries,
      contentGroup: this.props.subcategory.contentGroup,
    };
    if (!isFormValid(subcategory, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveSubcategory(subcategory);
  }

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.saveSubcategory} ref="subcategoryForm">
          <Input
            type="select"
            label="Category"
            ref="selectCategory"
            id="select-category"
            labelClassName="required"
            disabled={this.state.mode === 'edit'}
            onChange={this.onCategoryChange}
            value={this.props.subcategory.categoryId}
            bsStyle={this.props.validationErrors.categoryId ? 'error' : null}
          >
            <option value="0">Select a Category</option>
            {this.props.categories.map((category) => {
              return (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              );
            })}
          </Input>
          <Input
            labelClassName="required"
            type="text"
            label="Subcategory Name"
            placeholder="Subcategory name"
            ref="subcategoryName"
            id="text-subcategory-name"
            maxLength="100"
            onChange={this.onNameChange}
            value={this.props.subcategory.name}
            bsStyle={this.props.validationErrors.name ? 'error' : null}
          />
          <Panel header="Ad Configuration" bsStyle="primary" eventKey="1">
            <Input
              type="select"
              label="Ad Content Group"
              ref="contentGroup"
              id="contentGroup"
              labelClassName="required"
              onChange={this.onContentGroupChange}
              value={this.props.subcategory.contentGroup}
            >
              {CONTENT_GROUPS.map((group) => {
                return (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                );
              })}
            </Input>
            <MultiInputList
              ref="availableContentSeries"
              id="multi-list-available-content-series"
              validate={validateContentSeries}
              title="Available Content Series for Clips"
              items={
                this.props.subcategory.availableContentSeries &&
                this.props.subcategory.availableContentSeries.map((contentSeries) => ({
                  value: contentSeries,
                }))
              }
              addButtonText="Add"
              emptyItemMessage="Enter content series eg. some-content-series"
              onChange={(items) => this.onAvailableContentSeriesChange(items.map(({ value }) => value))}
            />
          </Panel>
          <Panel id="panel-settings" header="Settings" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label="Available only in Australia"
              ref="geoBlocked"
              id="check-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onGeoBlockedChange}
              checked={this.props.subcategory.geoBlocked}
            />
          </Panel>
          {this.props.errorMessage && (
            <FormErrorMessage
              className="sub-category-server-error-message"
              message={this.props.errorMessage}
            />
          )}
          <div className="modal-footer">
            <Button
              id="modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

SubcategoryForm.propTypes = {
  close: PropTypes.func,
  subcategory: PropTypes.object,
  errorMessage: PropTypes.string,
  saveSubcategory: PropTypes.func,
  updateSubcategory: PropTypes.func,
  categories: PropTypes.array,
  saving: PropTypes.bool,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
};

function mapStateToProps(state) {
  const subcategoryForm = state.subcategoryForm;
  return {
    subcategory: subcategoryForm.subcategory,
    errorMessage: subcategoryForm.errorMessage,
    categories: subcategoryForm.categories,
    saving: subcategoryForm.saving,
    validationErrors: subcategoryForm.validationErrors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSubcategory: (subcategory) => dispatch(updateSubcategory(subcategory)),
    saveSubcategory: (subcategory) => dispatch(saveSubcategory(subcategory)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubcategoryForm);
