import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Panel, Button, Input } from 'react-bootstrap';
import ModalItem from '../ModalItem.jsx';
import { closeChannelModal, updateChannelStyling } from '../../actions/channel.js';
import MultiImageUpload from '../MultiImageUpload.jsx';
import {
  CHANNEL_CARD_LOGO_TRANSPARENT,
  CHANNEL_CARD_TRANSPARENT,
  CHANNEL_CARD_LIVE,
  MARKET_CHANNEL_SWITCHER_LOGO,
} from './channelImageTypes.js';
import { IMAGES } from '../../lib/resourceGroups.js';
import isEmpty from 'lodash/isEmpty';
import { ViewLoader } from '../ViewLoader.jsx';
import { CHANNEL_UPDATE_STATE_ENUM } from '../../reducers/channels.js';

export class ChannelStylingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelColour: props.channel.colour,
    };
  }

  imageTypesOptions = [
    MARKET_CHANNEL_SWITCHER_LOGO,
    CHANNEL_CARD_LOGO_TRANSPARENT,
    CHANNEL_CARD_TRANSPARENT,
    CHANNEL_CARD_LIVE,
  ];

  shouldDisableActionButtons = () => {
    return Object.keys(this.props.multiImageUpload).some(
      (p) => this.props.multiImageUpload[p].uploading && !this.props.multiImageUpload[p].isImageRemoved
    );
  };

  getImagesToUpload = (images = {}) => {
    const imagesToUpload = [];
    for (const key of Object.keys(images)) {
      if (!images[key].isImageRemoved) {
        imagesToUpload.push({
          id: images[key].imageId,
          type: images[key].type,
        });
      }
    }
    return imagesToUpload;
  };

  getRemovedImagesIds = (images = {}) => {
    const removedImagesIds = [];

    for (const key of Object.keys(images)) {
      if (images[key].isImageRemoved && images[key].imageId) {
        removedImagesIds.push(images[key].imageId);
      }
    }
    return removedImagesIds;
  };

  getChannelImagesToUpdate = (channel) => {
    const existingImages = channel.images;

    const imagesToUpload = this.getImagesToUpload(this.props.multiImageUpload);
    const removedImagesIds = this.getRemovedImagesIds(this.props.multiImageUpload);
    const existingImagesNotRemoved = [];

    for (const property in existingImages) {
      if (!removedImagesIds.includes(existingImages[property].id)) {
        existingImagesNotRemoved.push({
          id: existingImages[property].id,
          type: existingImages[property].type,
        });
      }
    }
    return [...imagesToUpload, ...existingImagesNotRemoved];
  };

  updateChannelStyling = () => {
    const channel = this.props.channel;
    if (!isEmpty(this.props.multiImageUpload)) {
      const totalImagesToUpload = this.getChannelImagesToUpdate(channel) || [];
      this.props.updateChannelStyling(channel.id, {
        images: totalImagesToUpload,
        colour: this.state.channelColour,
      });
    } else if (this.state.channelColour !== channel.colour) {
      const existingImages = channel.images.map(({ id, type }) => ({ id, type }));
      this.props.updateChannelStyling(channel.id, {
        images: existingImages,
        colour: this.state.channelColour,
      });
    }
  };

  onColourChange = (event) => {
    const value = event.target ? event.target.value : event;
    this.setState((prevState) => ({
      ...prevState,
      channelColour: value,
    }));
  };

  renderChannelColourPanel = () => {
    return (
      <Panel header="Channel Colour" bsStyle="primary">
        <div>
          <Input
            type="text"
            label="Channel Colour"
            placeholder="Add a HEX colour code for the Channel"
            ref="channelColour"
            onChange={this.onColourChange}
            defaultValue={this.props.channel.colour}
            bsStyle={this.props.updateChannelError ? 'error' : null}
            data-pw="channelColour"
          />
          <span className="text-hint">Default Channel colour HEX is #02C7E1</span>
        </div>
      </Panel>
    );
  };

  renderChannelImagesPanel = () => {
    return (
      <Panel header="Channel Images" bsStyle="primary">
        <MultiImageUpload
          resourceGroup={IMAGES.TV}
          imageTypes={this.imageTypesOptions}
          limit={this.imageTypesOptions.length}
          existingImages={this.props.channel.images}
        />
      </Panel>
    );
  };

  renderUpdateChannelOption() {
    const uploading = this.props.channelUpdateState === CHANNEL_UPDATE_STATE_ENUM.UPDATING;
    const succeeded = this.props.channelUpdateState === CHANNEL_UPDATE_STATE_ENUM.SUCCEEDED;
    const disableButtons = this.shouldDisableActionButtons() || uploading;
    return (
      <ModalItem
        onClose={this.props.closeChannelModal}
        title="Update Channel Styling"
        form={
          <div>
            {this.renderChannelColourPanel()}
            {this.renderChannelImagesPanel()}
            <div className="channel-styling-loader-footer">
              <div className="channel-styling-loader">
                <ViewLoader loading={uploading} errorMessage={this.props.updateChannelError}>
                  {succeeded && (
                    <span className="channel-styling-successful">
                      <i className="fa fa-check-circle"></i>
                    </span>
                  )}
                </ViewLoader>
              </div>
              <div className="channel-styling-footer-buttons">
                <Button
                  type="button"
                  className="form__button"
                  onClick={this.props.closeChannelModal}
                  disabled={disableButtons}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="form__button"
                  bsStyle="primary"
                  disabled={disableButtons}
                  onClick={() => this.updateChannelStyling()}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        }
      />
    );
  }

  render() {
    return this.renderUpdateChannelOption();
  }
}

ChannelStylingModal.propTypes = {
  updateChannelStyling: PropTypes.func,
  closeChannelModal: PropTypes.func,
  channel: PropTypes.object,
  multiImageUpload: PropTypes.object,
  channelUpdateState: PropTypes.oneOf(Object.values(CHANNEL_UPDATE_STATE_ENUM)),
  updateChannelError: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    multiImageUpload: state.multiImageUpload,
    channelUpdateState: state.channels.channelUpdateState,
    updateChannelError: state.channels.updateChannelError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeChannelModal: () => dispatch(closeChannelModal()),
    updateChannelStyling: (channelId, { images, colour }) =>
      dispatch(updateChannelStyling(channelId, { images, colour })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelStylingModal);
