export default [
  { code: 'hd', name: 'High Definition' },
  { code: 'fhd', name: 'Full High Definition' },
];

const transcodingProfileCodeNameMap = Object.freeze({
  hd: 'High Definition',
  fhd: 'Full High Definition',
});

export { transcodingProfileCodeNameMap };
