import moment from 'moment';
import { VALID_DATE_FORMATS } from 'src/scripts/lib/formValidation/constants';

export const shallowFormatValidDatesToISOString = (
  obj,
  dateFields,
  validationErrors,
  validDateFormats = VALID_DATE_FORMATS
) => {
  const updatedObj = { ...obj };
  dateFields.forEach((dateField) => {
    if (
      updatedObj.hasOwnProperty(dateField) &&
      updatedObj[dateField] &&
      !validationErrors.hasOwnProperty(dateField)
    ) {
      updatedObj[dateField] = moment(updatedObj[dateField], validDateFormats, true).toISOString();
    }
  });
  return updatedObj;
};

export const shallowSetEmptyDateFieldsToNull = (obj, dateFields) => {
  const updatedObj = { ...obj };
  dateFields.forEach((dateField) => {
    const dateValue = updatedObj[dateField];
    if (typeof dateValue === 'string' && dateValue.trim() === '') {
      updatedObj[dateField] = null;
    }
  });
  return updatedObj;
};
