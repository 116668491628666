import { SORT_UPDATED, FILTER_UPDATED, RESET_ITEM_LIST } from 'src/scripts/actions/itemList';

export const initialState = {
  ascending: false,
  sortBy: '',
  sort: null,
  filters: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SORT_UPDATED:
      return {
        ...state,
        sortBy: action.sortBy,
        ascending: action.ascending,
        sort: `${action.ascending ? '+' : '-'}${action.sortBy}`,
      };
    case FILTER_UPDATED:
      const filters = {
        ...state.filters,
        [action.name]: action.value || null,
      };
      return {
        ...state,
        filters,
      };
    case RESET_ITEM_LIST:
      return {
        ...initialState,
      };
    default:
  }

  return state;
}

export const getDateFilterActiveState = (filterName, state) =>
  state.itemList.filters.hasOwnProperty(filterName) && state.itemList.filters[filterName] !== null;

export const isFilterActive = (filterName, state) =>
  state.itemList.filters.hasOwnProperty(filterName) && state.itemList.filters[filterName] !== null;
