import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CollectionActions from 'src/scripts/components/Collection/CollectionActions';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { getCollections } from 'src/scripts/actions/collection';
import * as pagination from 'src/scripts/lib/pagination';
import InactiveWarningWithTooltip from 'src/scripts/components/Collection/InactiveWarningWithTooltip';
import ExportCollectionCatalog from 'src/scripts/components/Collection/internal/ExportCollectionCatalog';
import { collectionTypes } from '../../lib/collectionTypes';
import {
  collectionStatuses,
  collectionStatusColours,
  getCollectionStatus,
  getCollectionWorkflow,
  filterableWorkflows,
  workflowColours,
} from '../../lib/collection';

export const mapStateToProps = (state) => ({
  collections: state.collectionList.collections,
  count: state.collectionList.count,
  errorMessage: state.collectionList.errorMessage,
  savedCollection: state.collectionList.savedCollection,
  filters: state.itemList.filters,
});

export const mapDispatchToProps = (dispatch) => ({
  getCollections: (params) => dispatch(getCollections(params)),
});

export class CollectionList extends Component {
  static propTypes = {
    collections: PropTypes.array,
    count: PropTypes.number,
    getCollections: PropTypes.func,
    savedCollection: PropTypes.object,
    deleteCollectionAndCloseConfirmation: PropTypes.func,
    page: PropTypes.number,
    filters: PropTypes.object,
  };

  static defaultProps = {
    getCollections: () => {},
  };

  constructor() {
    super();
    this.getCollections = this.getCollections.bind(this);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.page && prevProps.page !== this.props.page) || !!this.props.savedCollection) {
      this.loadData();
    }
  }

  getCollections(params = {}) {
    const offset = pagination.calculateOffset(this.props.page);
    this.props.getCollections({
      offset,
      limit: pagination.pageSize,
      includeTvSeriesState: true,
      includeRecommendingTvSeriesState: true,
      status: params.status,
      workflow: params.workflow,
      filters: params.filters ? params.filters : {},
    });
  }

  loadData = () => {
    this.getCollections({ filters: this.props.filters });
  };

  actionList = (collection) => {
    return (
      <CollectionActions
        key={collection.id}
        collection={collection}
        displayType="dropdown"
        afterUpdate={this.loadData}
      />
    );
  };

  renderActiveTvSeriesCountAndInactiveTvSeriesWarning = (collection) => (
    <span>
      <span className="active-tv-series-count">{collection.activeTvSeriesCount}</span>{' '}
      <span>{collection.containsFutureInactiveTvSeries && <InactiveWarningWithTooltip />}</span>
    </span>
  );

  renderTvSeriesUsingCollectionCountAndWarning = (collection) => (
    <span className="active-recommending-tv-series-count">
      <span>{collection.activeRecommendingTvSeriesCount}</span>{' '}
      <span>{collection.containsFutureInactiveRecommendingTvSeries && <InactiveWarningWithTooltip />}</span>
    </span>
  );

  renderCollectionStatus = (collection) => {
    const status = getCollectionStatus(collection);
    const colors = collectionStatusColours;
    const statusColour = colors[status];

    return (
      <span
        className="collection-status"
        style={{ background: `${statusColour}`, color: 'white', borderRadius: '4px', padding: '4px' }}
      >
        {status}
      </span>
    );
  };

  renderCollectionWorkflow = (collection) => {
    const workflow = getCollectionWorkflow(collection);
    const workflowColour = workflowColours[workflow];

    return (
      <span
        className="collection-workflow"
        style={{ background: `${workflowColour}`, color: 'white', borderRadius: '4px', padding: '4px' }}
      >
        {workflow}
      </span>
    );
  };

  // temporary workaround to sort collections on collections page, will be reworked when pagination implemented
  // Default > Genre > Active > Inactive
  getSortedCollections = () => {
    const collections = this.props.collections;
    if (!collections || collections.length === 0) return [];

    const sortedCollections = [];
    const defaultCollection = collections.filter((collection) => collection.type === 'DEFAULT')[0];
    const genreCollections = collections.filter((collection) => collection.type === 'GENRE');
    const activeCollections = collections.filter(
      (collection) => collection.type === 'CUSTOM' && getCollectionStatus(collection) === 'ACTIVE'
    );
    const inactiveCollections = collections.filter(
      (collection) => collection.type === 'CUSTOM' && getCollectionStatus(collection) === 'INACTIVE'
    );

    if (defaultCollection) sortedCollections.push(defaultCollection);
    if (genreCollections) sortedCollections.push(...genreCollections);
    if (activeCollections) sortedCollections.push(...activeCollections);
    if (inactiveCollections) sortedCollections.push(...inactiveCollections);

    return sortedCollections;
  };

  render() {
    const sortedCollections = this.getSortedCollections();
    const list = {
      pageData: sortedCollections,
      totalCount: this.props.count,
    };
    return (
      <div>
        <ol className="mi9-breadcrumb breadcrumb">
          <li>Collections</li>
          <ExportCollectionCatalog collections={this.props.collections} />
        </ol>
        <ItemList id="list-collections" list={list} getItems={this.getCollections} disableColumnChooser>
          <ItemListHeader
            id="collection-name-header"
            label="Name"
            dataField="name"
            filter
            dataFormat={(collection) => <a href={`#/tv/collections/${collection.id}`}>{collection.name}</a>}
          />
          <ItemListHeader
            filter={{ type: 'dropdown', options: collectionTypes }}
            id="collection-type-header"
            label="Type"
            dataField="type"
          />
          <ItemListHeader
            id="collection-active-tv-series-header"
            label="Active TV Series"
            dataFormat={(collection) => this.renderActiveTvSeriesCountAndInactiveTvSeriesWarning(collection)}
          />
          <ItemListHeader
            id="collections-tv-series-using-collection"
            label="TV Series Using Collection"
            dataFormat={(collection) =>
              collection.type === 'CUSTOM'
                ? this.renderTvSeriesUsingCollectionCountAndWarning(collection)
                : null
            }
          />
          <ItemListHeader
            id="collections-status-header"
            label="Status"
            dataField="status"
            dataFormat={(collection) => this.renderCollectionStatus(collection)}
            filter={{ type: 'dropdown', options: Object.keys(collectionStatuses) }}
          />
          <ItemListHeader
            id="collections-workflow-header"
            label="Workflow"
            dataField="workflow"
            dataFormat={(collection) => this.renderCollectionWorkflow(collection)}
            filter={{ type: 'dropdown', options: Object.keys(filterableWorkflows) }}
          />
          <ItemListHeader
            id="collections-last-updated-at"
            label="Last Updated"
            dataField="updatedAt"
            dataType="date"
          />
          <ItemListHeader
            id="collection-actions-header"
            label="Actions"
            dataFormat={(collection) => this.actionList(collection)}
          />
        </ItemList>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionList);
