import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SimpleErrorMessage } from 'src/scripts/components/SimpleMessage/SimpleErrorMessage';
import { SimpleInfoMessage } from 'src/scripts/components/SimpleMessage/SimpleInfoMessage';
import { DeviceStatusError, DeviceStatusUpdating } from 'src/scripts/lib/DeviceStatus';
import { Controller as Toggle } from '../../@Bricks/Toggle/Controller';

export class DeviceToggle extends Component {
  renderError = () =>
    this.props.status instanceof DeviceStatusError ? (
      <SimpleErrorMessage message={this.props.status.message} />
    ) : null;

  renderInfo = () =>
    this.props.status instanceof DeviceStatusUpdating ? (
      <SimpleInfoMessage message={this.props.status.message} />
    ) : null;

  render = () => (
    <div>
      <Toggle
        checked={this.props.isChecked}
        disabled={this.props.status instanceof DeviceStatusUpdating}
        onSwitch={() => this.props.onChange(this.props.device, this.props.status)}
      />
      {this.renderError()}
      {this.renderInfo()}
    </div>
  );
}

DeviceToggle.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  status: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
