import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { logout } from 'src/scripts/actions/user/logoutActions';
import { NavDropdown, MenuItem, Navbar, Nav } from 'react-bootstrap';
import history from 'src/scripts/lib/history';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ModalItem from 'src/scripts/components/ModalItem';
import ClipFormLoader from 'src/scripts/components/Clip/ClipFormLoader';
import CategoryFormLoader from 'src/scripts/components/Category/CategoryFormLoader';
import SubcategoryFormLoader from 'src/scripts/components/Subcategory/SubcategoryFormLoader';
import NetworkClipFormLoader from 'src/scripts/components/NetworkClip/NetworkClipFormLoader';
import GenreFormLoader from 'src/scripts/components/Genre/GenreFormLoader';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import TVSeriesFormLoader from 'src/scripts/components/TVSeries/TVSeriesFormLoader';
import SeasonFormLoader from 'src/scripts/components/Season/SeasonFormLoader';
import RoleFormLoader from 'src/scripts/components/Admin/RoleFormLoader';
import LiveEventFormLoader from 'src/scripts/components/LiveEvent/LiveEventFormLoader';
import LiveEventGroupFormLoader from './LiveEvent/LiveEventGroupFormLoader';
import AddCollectionForm from 'src/scripts/components/Collection/internal/AddCollectionForm';
import * as accessController from 'src/scripts/lib/accessController';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import {
  TV_PATH,
  LIVE_PATH,
  TV_LIVE_EVENTS_PATH,
  TV_LIVE_EVENT_GROUPS_PATH,
  NETWORK_LIVE_EVENTS_PATH,
  ADMIN_PATH,
  NETWORK_PATH,
  DEVICES_PATH,
  STREAMS_PATH,
  MEDIA_LIVE_CHANNELS_PATH,
} from 'src/scripts/lib/accessController';

import { getHamburgerNavbarOpenState } from 'src/scripts/reducers/ui/navigation';
import { toggleHamburgerNavbar, closeHamburgerNavbar } from 'src/scripts/actions/ui/navigation';
import { isSmallScreen } from 'src/scripts/reducers/ui/screen';

const mapStateToProps = (state) => ({
  isHamburgerNavbarOpen: getHamburgerNavbarOpenState(state),
  isSmallScreen: isSmallScreen(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleHamburgerNavbar: () => dispatch(toggleHamburgerNavbar),
  closeHamburgerNavbar: () => dispatch(closeHamburgerNavbar),
});

export class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.initModuleAccessPermissions();
  }

  componentWillReceiveProps(nextProps) {
    const screenTransformSmallToLarge = (incomingProps, currentProps) =>
      currentProps.isSmallScreen && !incomingProps.isSmallScreen;
    if (screenTransformSmallToLarge(nextProps, this.props)) {
      this.props.closeHamburgerNavbar();
    }
  }

  getNavSectionFromPath() {
    const sections = [TV_PATH, LIVE_PATH, ADMIN_PATH, NETWORK_PATH];
    let res = null;
    sections.forEach((section) => {
      if (_.startsWith(history.location.pathname, `/${section}`)) {
        res = section;
      }
    });
    return res;
  }

  initModuleAccessPermissions() {
    if (this.props.loggedInUser) {
      this.accessToTvLiveEvents = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_TV_LIVE_EVENTS
      );
      this.accessToTvLiveEventGroups = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_TV_LIVE_EVENT_GROUPS
      );
      this.accessToLiveTennisChannels = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_MEDIA_LIVE_CHANNELS
      );
      this.accessToNetworkLiveEvents = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_NETWORK_LIVE_EVENTS
      );
      this.accessToLibraries = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_LIBRARIES_TV
      );
      this.accessToNetwork = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_LIBRARIES_NETWORK
      );
      this.accessToAdmin = accessController.checkAccessToModule(
        this.props.loggedInUser,
        accessController.MODULE_ADMIN
      );
    }
  }

  logout(e) {
    e.preventDefault();
    return logout();
  }

  renderTV(active) {
    return (
      <NavDropdown
        noCaret
        title="TV"
        ref="tvMenu"
        className={active ? 'active' : ''}
        id="tv-list-menu"
        data-pw="tv-list-menu"
      >
        <MenuItem onClick={this.props.closeHamburgerNavbar} href="/#/" data-pw="tv-list-menu-item-all-videos">
          All Videos
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          id="tv-series-link-id"
          className="tv-series-link"
          href={`/#/${TV_PATH}/tv-series`}
          data-pw="tv-list-menu-item-tv-series"
        >
          TV Series
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          href={`/#/${TV_PATH}/genres`}
          data-pw="tv-list-menu-item-genres"
        >
          Genres
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          href={`/#/${TV_PATH}/collections`}
          data-pw="tv-list-menu-item-collections"
        >
          Collections
        </MenuItem>
      </NavDropdown>
    );
  }

  renderNetwork(active) {
    return (
      <NavDropdown
        noCaret
        title="NETWORK"
        ref="networkMenu"
        className={active ? 'active' : ''}
        id="network-list-menu"
        data-pw="network-list-menu"
      >
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          id="network-list-menu-all-videos"
          href={`/#/${NETWORK_PATH}/videos`}
        >
          All Videos
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          id="network-list-menu-categories"
          href={`/#/${NETWORK_PATH}/categories`}
        >
          Categories
        </MenuItem>
      </NavDropdown>
    );
  }

  renderEvents(active) {
    return (
      <NavDropdown
        noCaret
        title="EVENTS"
        ref="eventsNavItem"
        className={active ? 'active' : ''}
        id="events-list-menu"
        data-pw="events-list-menu"
      >
        {this.accessToTvLiveEvents ? (
          <MenuItem
            onClick={this.props.closeHamburgerNavbar}
            href={`/#/${TV_LIVE_EVENTS_PATH}`}
            ref="tvEventsMenuItem"
            data-pw="events-menu-item-tv-live-events"
          >
            TV Live Events
          </MenuItem>
        ) : (
          ''
        )}
        {this.accessToTvLiveEventGroups ? (
          <MenuItem
            onClick={this.props.closeHamburgerNavbar}
            href={`/#/${TV_LIVE_EVENT_GROUPS_PATH}`}
            ref="tvEventGroupsMenuItem"
            data-pw="events-menu-item-tv-live-event-groups"
          >
            TV Live Event Groups
          </MenuItem>
        ) : (
          ''
        )}
        {this.accessToNetworkLiveEvents ? (
          <MenuItem
            onClick={this.props.closeHamburgerNavbar}
            href={`/#/${NETWORK_LIVE_EVENTS_PATH}`}
            ref="networkEventsMenuItem"
          >
            Network Live Events
          </MenuItem>
        ) : (
          ''
        )}
        {this.accessToLiveTennisChannels ? (
          <MenuItem
            onClick={this.props.closeHamburgerNavbar}
            href={`/#/${LIVE_PATH}/${MEDIA_LIVE_CHANNELS_PATH}`}
          >
            Live Stream Management
          </MenuItem>
        ) : (
          ''
        )}
      </NavDropdown>
    );
  }

  renderAdmin(active) {
    return (
      <NavDropdown
        noCaret
        title="ADMIN"
        ref="adminMenu"
        className={active ? 'active' : ''}
        data-pw="admin-list-menu"
        id="admin-list-menu"
      >
        <MenuItem onClick={this.props.closeHamburgerNavbar} href={`/#/${ADMIN_PATH}/roles`}>
          User Roles
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          href={`/#/${ADMIN_PATH}/channels`}
          data-pw="admin-menu-item-channels"
        >
          Channels
        </MenuItem>
        <MenuItem
          onClick={this.props.closeHamburgerNavbar}
          href={`/#/${ADMIN_PATH}/${DEVICES_PATH}`}
          data-pw="admin-menu-item-devices"
        >
          Devices
        </MenuItem>
        <MenuItem onClick={this.props.closeHamburgerNavbar} href={`/#/${ADMIN_PATH}/${STREAMS_PATH}`}>
          Streams
        </MenuItem>
      </NavDropdown>
    );
  }

  renderAdminActions() {
    return (
      <NavDropdownMenu iconClass="fa-plus" ref="adminActionsMenu">
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Role"
          ref="addRole"
          form={<RoleFormLoader />}
        />
      </NavDropdownMenu>
    );
  }

  renderLiveActions() {
    return (
      <NavDropdownMenu iconClass="fa-plus" ref="liveActionsMenu">
        {this.accessToTvLiveEvents ? (
          <ModalItem
            onClickHandlers={[this.props.closeHamburgerNavbar]}
            title="Add TV Live Event"
            ref="addTVLiveEvent"
            form={<LiveEventFormLoader library={TV} />}
          />
        ) : (
          ''
        )}
        {this.accessToTvLiveEventGroups ? (
          <ModalItem
            onClickHandlers={[this.props.closeHamburgerNavbar]}
            title="Add TV Live Event Group"
            ref="addTVLiveEventGroup"
            // TO DO: This forced reload is so that the LiveEventGroupList page knows to re gather the data upload adding a new LiveEvent Group.
            // This is a temp solution until further refactoring can be done.
            form={<LiveEventGroupFormLoader onSuccess={() => window.location.reload()} />}
          />
        ) : (
          ''
        )}
        {this.accessToNetworkLiveEvents ? (
          <ModalItem
            onClickHandlers={[this.props.closeHamburgerNavbar]}
            title="Add Network Live Event"
            ref="addLiveEvent"
            form={<LiveEventFormLoader library={NETWORK} />}
          />
        ) : (
          ''
        )}
      </NavDropdownMenu>
    );
  }

  renderNetworkActions() {
    return (
      <NavDropdownMenu iconClass="fa-plus" ref="networkActionsMenu">
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Category"
          ref="addCategory"
          form={<CategoryFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Subcategory"
          ref="addSubcategory"
          form={<SubcategoryFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Network Clip"
          ref="addNetworkClip"
          form={<NetworkClipFormLoader />}
        />
      </NavDropdownMenu>
    );
  }

  renderTVActions() {
    return (
      <NavDropdownMenu iconClass="fa-plus" ref="tvActionsMenu" data-pw="add-tv-actions-menu">
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add TV Series"
          iconClass="fa-television"
          ref="addTVSeries"
          data-pw="add-tv-series"
          form={<TVSeriesFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Season"
          iconClass="fa-television"
          ref="addSeason"
          data-pw="add-season"
          form={<SeasonFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Episode"
          iconClass="fa-file-video-o"
          ref="addEpisode"
          data-pw="add-episode"
          form={<EpisodeFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Clip"
          iconClass="fa-paperclip"
          ref="addClip"
          data-pw="add-clip"
          form={<ClipFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Genre"
          iconClass="fa-tags"
          ref="addGenre"
          data-pw="add-genre"
          form={<GenreFormLoader />}
        />
        <ModalItem
          onClickHandlers={[this.props.closeHamburgerNavbar]}
          title="Add Collection"
          iconClass="fa-list-alt"
          data-pw="add-collection"
          form={<AddCollectionForm />}
        />
      </NavDropdownMenu>
    );
  }

  renderActionsForSection(section) {
    switch (section) {
      case TV_PATH:
        return this.renderTVActions();
      case ADMIN_PATH:
        return this.renderAdminActions();
      case NETWORK_PATH:
        return this.renderNetworkActions();
      case LIVE_PATH:
        return this.renderLiveActions();
      default:
        return '';
    }
  }

  renderUserSection() {
    return (
      <NavDropdown title={this.props.loggedInUser ? this.props.loggedInUser.userName : ' '} id="user-menu">
        <MenuItem href="/#/" ref="logout" onClick={this.logout} id="logout">
          Logout
        </MenuItem>
      </NavDropdown>
    );
  }

  renderNavigationItems() {
    const section = this.getNavSectionFromPath();

    return (
      <Nav pullRight>
        {this.renderActionsForSection(section)}
        {this.accessToLibraries ? this.renderTV(section === TV_PATH) : ''}
        {this.accessToNetwork ? this.renderNetwork(section === NETWORK_PATH) : ''}
        {this.accessToTvLiveEvents ||
        this.accessToNetworkLiveEvents ||
        this.accessToTvLiveEventGroups ||
        this.accessToLiveTennisChannels
          ? this.renderEvents(section === LIVE_PATH)
          : ''}
        {this.accessToAdmin ? this.renderAdmin(section === ADMIN_PATH) : ''}
        {this.renderUserSection()}
      </Nav>
    );
  }

  renderReadOnlyMessage() {
    // eslint-disable-next-line
    if (envVars.APP_ENV && envVars.APP_ENV === 'stag') {
      return (
        <span className="staging_readonly_title">
          This is a READ ONLY Environment. Please don't ADD or EDIT any items.
        </span>
      );
    }
    return null;
  }

  render() {
    if (!this.props.isLoggedIn) return null;
    return (
      <Navbar
        inverse
        className="main-navbar"
        fixedTop
        fluid
        expanded={this.props.isHamburgerNavbarOpen}
        onToggle={this.props.toggleHamburgerNavbar}
        data-pw="main-navbar"
      >
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/#/">
              <img className="navbar-logo" src={'/images/vms_logo_small.png'} />
            </a>
            {this.renderReadOnlyMessage()}
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>{this.renderNavigationItems()}</Navbar.Collapse>
      </Navbar>
    );
  }
}

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool,
  loggedInUser: PropTypes.object,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func,
  params: PropTypes.object,
  isHamburgerNavbarOpen: PropTypes.bool,
  toggleHamburgerNavbar: PropTypes.func,
  closeHamburgerNavbar: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
