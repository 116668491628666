import * as requestHandler from './requestHandler';

const PREFIX = 'api/v1/libraries/tv/youtube';

export function getChannels() {
  return requestHandler.send('GET', `${PREFIX}/channels`);
}

export function getPolicies() {
  return requestHandler.send('GET', `${PREFIX}/policies`);
}
