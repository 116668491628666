import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';

class GenerateThumbnails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      label: 'Generate Thumbnails',
      disableButton: false,
    };
  }

  notifyRegenerateThumbnails = async () => {
    this.setState({
      label: 'Generating Thumbnails...',
      disableButton: true,
    });
    await tvVmsApi.notifyGenerateVideoPreviewImages(this.props.episode);
  };

  render() {
    return (
      <div>
        <Button
          className="generate-thumbnails"
          disabled={this.state.disableButton}
          onClick={this.notifyRegenerateThumbnails}
        >
          {this.state.label}
        </Button>
      </div>
    );
  }
}

GenerateThumbnails.propTypes = {
  episode: PropTypes.object,
};

export default GenerateThumbnails;
