import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

const NotesPanel = ({ season }) => {
  return (
    <Panel header="Notes">
      <DetailsRowItem label="Producer Notes">
        <FormatText>{season.producerNotes}</FormatText>
      </DetailsRowItem>
    </Panel>
  );
};

NotesPanel.propTypes = {
  season: PropTypes.object,
};

export default NotesPanel;
