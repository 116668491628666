import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import { closeModal } from 'src/scripts/actions/modalItem';

import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';

export const SAVE_CATEGORY_FAILED = 'SAVE_CATEGORY_FAILED';
export const SAVE_CATEGORY_SUCCEEDED = 'SAVE_CATEGORY_SUCCEEDED';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const GET_CATEGORIES_SUCCEEDED = 'GET_CATEGORIES_SUCCEEDED';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';
export const DELETE_CATEGORY_SUCCEEDED = 'DELETE_CATEGORY_SUCCEEDED';
export const GET_CATEGORY_SUCCEEDED = 'GET_CATEGORY_SUCCEEDED';
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';

export function getCategories() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CATEGORIES_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_CATEGORIES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getCategories().then(onSuccess, onError);
  };
}

export function saveCategory(payload) {
  return (dispatch) => {
    const saveMethod = payload.id ? networkVmsApi.updateCategory : networkVmsApi.createCategory;
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_CATEGORY_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_CATEGORY_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(payload).then(onSuccess, onError);
  };
}

export function updateCategory(category) {
  return {
    type: UPDATE_CATEGORY,
    category,
  };
}

export function getCategoryById(categoryId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CATEGORY_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_CATEGORY_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getCategoryById(categoryId).then(onSuccess, onError);
  };
}

export function deleteCategoryAndCloseConfirmation(categoryId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_CATEGORY_SUCCEEDED,
        id: categoryId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.deleteCategory(categoryId).then(onSuccess, onError);
  };
}
