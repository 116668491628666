import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DeviceView } from 'src/scripts/components/Admin/DeviceView';
import { connect } from 'react-redux';
import {
  listDevices,
  updateDeviceSsai,
  updateDeviceDrm,
  updateDeviceFullHd,
  updateDeviceStartOver,
  updateDeviceCdn,
  updateDeviceTokenisation,
  updateDeviceManifestFiltering,
} from 'src/scripts/actions/device';
import { showConfirmationModal } from 'src/scripts/actions/confirmation';
import { ViewLoader } from 'src/scripts/components/ViewLoader';
import { DeviceStatusReady } from 'src/scripts/lib/DeviceStatus';
import { CdnCollection } from '../../lib/cdn';
import { getCdnNameList } from '../../lib/cdnConfiguration';

export class DeviceController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devices: { count: 0, rows: [] },
      loadingError: null,
    };
  }

  installStatuses = (device) => ({
    ...device,
    enabledSsaiForChannelsStatus: new DeviceStatusReady('enabledSsaiForChannels'),
    enabledSsaiForLiveEventsStatus: new DeviceStatusReady('enabledSsaiForLiveEvents'),
    drmEnabledStatus: new DeviceStatusReady('drmEnabled'),
    fullHdEnabledStatus: new DeviceStatusReady('fullHdEnabled'),
    startOverEnabledStatus: new DeviceStatusReady('startOverEnabled'),
    tokenisationEnabledStatus: new DeviceStatusReady('tokenisationEnabled'),
    ...getCdnNameList().reduce(
      (result, cdnName) => ({
        ...result,
        [`cdn${cdnName}EnabledStatus`]: new DeviceStatusReady(`cdn${cdnName}Enabled`),
      }),
      {}
    ),
    manifestFilteringEnabledStatus: new DeviceStatusReady('manifestFilteringEnabled'),
  });

  loadData = async () => {
    try {
      const devices = await listDevices();

      devices.rows.sort((a, b) => a.name.localeCompare(b.name));
      devices.rows = devices.rows.map(this.installStatuses);

      this.setState({ devices });
    } catch (e) {
      this.setState({ loadingError: e.toString() });
    }
  };

  /**
   * Find the index of the device to be updated, and replace it.
   * Otherwise, ignore the update request by returning the current state.
   */
  doUpdateStateDevices = (devices, device) => {
    const updateIndex = devices.rows.findIndex((row) => row.id === device.id);
    if (updateIndex === -1) {
      return devices;
    }

    return {
      count: devices.count,
      rows: [...devices.rows.slice(0, updateIndex), device, ...devices.rows.slice(updateIndex + 1)],
    };
  };

  /**
   * Before actually updating the state devices, a new device
   * needs to be created with the new status.
   */
  doUpdateStateDevicesWrapper = (device, newStatus) => {
    this.setState((prevState) => ({
      devices: this.doUpdateStateDevices(prevState.devices, {
        ...device,
        [`${newStatus.field}Status`]: newStatus,
      }),
      loadingError: prevState.loadingError,
    }));
  };

  doUpdateDeviceCdn = async (deviceWithStatus, currentStatus, cdnName, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceCdn(deviceWithStatus.id, cdnName, !isEnabled, reason);

      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  /**
   * Blocking further operations on the DAI toggle box when there is an outstanding one.
   */
  doUpdateDeviceSsai = async (deviceWithStatus, currentStatus, target, isEnabled, reason) => {
    /**
     * Do not show the status unless the network is relatively slow.
     */
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceSsai(deviceWithStatus.id, target, !isEnabled, reason);

      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  doUpdateDeviceDrm = async (deviceWithStatus, currentStatus, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceDrm(deviceWithStatus.id, !isEnabled, reason);
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  doUpdateDeviceFullHd = async (deviceWithStatus, currentStatus, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceFullHd(deviceWithStatus.id, !isEnabled, reason);
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  doUpdateDeviceStartOver = async (deviceWithStatus, currentStatus, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceStartOver(deviceWithStatus.id, !isEnabled, reason);
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  doUpdateDeviceTokenisation = async (deviceWithStatus, currentStatus, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceTokenisation(deviceWithStatus.id, !isEnabled, reason);
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  doUpdateDeviceManifestFiltering = async (deviceWithStatus, currentStatus, isEnabled, reason) => {
    const timeoutId = setTimeout(
      this.doUpdateStateDevicesWrapper,
      100,
      deviceWithStatus,
      currentStatus.getUpdating('Updating...')
    );

    try {
      const { device } = await updateDeviceManifestFiltering(deviceWithStatus.id, !isEnabled, reason);
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(this.installStatuses(device), currentStatus.getReady());
    } catch (e) {
      clearTimeout(timeoutId);
      this.doUpdateStateDevicesWrapper(deviceWithStatus, currentStatus.getError(e.responseJSON.message));
    }
  };

  updateDeviceCdn = (cdnName) => (device, status) => {
    const cdnCollection = new CdnCollection(device.cdn);
    const cdn = cdnCollection.find(cdnName);

    if (!cdn) {
      return this.props.showConfirmationModal(
        `cdn(${cdnName}) is not supported. Please contact admin for help.`,
        () => null,
        false
      );
    }

    const activated = cdn.isActivated();
    const description = `You are about to
      ${activated ? 'disable' : 'enable'} cdn(${cdnName}) for ${device.name}.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceCdn(device, status, cdnName, activated, reason),
      true
    );
  };

  updateDeviceSsai = (device, status) => {
    const isEnabled = device[status.field];
    const target = /Channels/.test(status.field) ? 'channel' : 'event';

    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} Ad Insertion for
      ${device.name}'s ${target}s.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceSsai(device, status, target, isEnabled, reason),
      true
    );
  };

  updateDeviceDrm = (device, status) => {
    const isEnabled = device[status.field];
    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} DRM for
      ${device.name}'s channels.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceDrm(device, status, isEnabled, reason),
      true
    );
  };

  updateDeviceFullHd = (device, status) => {
    const isEnabled = device[status.field];
    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} Full HD for
      ${device.name}'s channels.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceFullHd(device, status, isEnabled, reason),
      true
    );
  };

  updateDeviceStartOver = (device, status) => {
    const isEnabled = device[status.field];
    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} Start Over for
      ${device.name}'s channels.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceStartOver(device, status, isEnabled, reason),
      true
    );
  };

  updateDeviceTokenisation = (device, status) => {
    const isEnabled = device[status.field];
    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} Tokenisation for
      ${device.name}'s channels.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceTokenisation(device, status, isEnabled, reason),
      true
    );
  };

  updateDeviceManifestFiltering = (device, status) => {
    const isEnabled = device[status.field];
    const description = `You are about to
      ${isEnabled ? 'disable' : 'enable'} Manifest Filtering for
      ${device.name}'s channels.
      Do you wish to proceed?`;

    return this.props.showConfirmationModal(
      description,
      (reason) => this.doUpdateDeviceManifestFiltering(device, status, isEnabled, reason),
      true
    );
  };

  render = () => (
    <ViewLoader errorMessage={this.state.loadingError}>
      <DeviceView
        devices={this.state.devices}
        listDevices={this.loadData}
        updateDeviceSsai={this.updateDeviceSsai}
        updateDeviceDrm={this.updateDeviceDrm}
        updateDeviceFullHd={this.updateDeviceFullHd}
        updateDeviceStartOver={this.updateDeviceStartOver}
        updateDeviceTokenisation={this.updateDeviceTokenisation}
        updateDeviceManifestFiltering={this.updateDeviceManifestFiltering}
        updateDeviceCdn={getCdnNameList().reduce(
          (callbacks, cdnName) => ({
            ...callbacks,
            [cdnName]: this.updateDeviceCdn(cdnName),
          }),
          {}
        )}
      />
    </ViewLoader>
  );
}

DeviceController.propTypes = {
  showConfirmationModal: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    showConfirmationModal: (...args) => dispatch(showConfirmationModal(...args)),
  };
}

export default connect(null, mapDispatchToProps)(DeviceController);
