import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { closeModal } from 'src/scripts/actions/modalItem';

export const GET_GENRES_SUCCEEDED = 'GET_GENRES_SUCCEEDED';
export const GET_GENRES_FAILED = 'GET_GENRES_FAILED';
export const GET_GENRE_SUCCEEDED = 'GET_GENRE_SUCCEEDED';
export const GET_GENRE_FAILED = 'GET_GENRE_FAILED';
export const SAVE_GENRE_FAILED = 'SAVE_GENRE_FAILED';
export const SAVE_GENRE_SUCCEEDED = 'SAVE_GENRE_SUCCEEDED';
export const GENRE_UPDATED = 'GENRE_UPDATED';
export const DELETE_GENRE_SUCCEEDED = 'DELETE_GENRE_SUCCEEDED';

export function getGenres(params = {}) {
  return (dispatch) => {
    const offset = params.offset ? params.offset : 0;
    const limit = params.limit;
    const request = {
      offset,
      limit,
    };
    const onSuccess = (data) => {
      dispatch({
        type: GET_GENRES_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_GENRES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getGenres(request).then(onSuccess, onError);
  };
}

export function getGenreById(genreId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_GENRE_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_GENRE_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getGenreById(genreId).then(onSuccess, onError);
  };
}

export function updateGenre(genre) {
  return {
    type: GENRE_UPDATED,
    genre,
  };
}

export function saveGenre(payload) {
  return (dispatch) => {
    const saveMethod = payload.id ? tvVmsApi.updateGenre : tvVmsApi.createGenre;
    const onSuccess = (savedGenre) => {
      dispatch({
        type: SAVE_GENRE_SUCCEEDED,
        data: savedGenre,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_GENRE_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(payload).then(onSuccess, onError);
  };
}

export function deleteGenreAndCloseConfirmation(genreId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_GENRE_SUCCEEDED,
        id: genreId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteGenre(genreId).then(onSuccess, onError);
  };
}
