export const REAL_TIME_EPISODE_UPDATED = 'REAL_TIME_EPISODE_UPDATED';
export const REAL_TIME_CLIP_UPDATED = 'REAL_TIME_CLIP_UPDATED';
export const REAL_TIME_INGEST_JOB_UPDATED = 'REAL_TIME_INGEST_JOB_UPDATED';
export const REAL_TIME_NETWORK_CLIP_UPDATED = 'REAL_TIME_NETWORK_CLIP_UPDATED';
export const REAL_TIME_VMS_UPDATED = 'REAL_TIME_VMS_UPDATED';
export function processRealTimeNotification(message) {
  return (dispatch) => {
    return dispatch({
      type: message.type,
      data: message.data,
    });
  };
}
