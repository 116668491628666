import {
  CONFIRMATION_ACTION_FAILED,
  CONFIRMATION_ACTION_STARTED,
  SHOW_CONFIRMATION_MODAL,
  HIDE_CONFIRMATION_MODAL,
  UPDATE_CONFIRMATION_REASON,
} from 'src/scripts/actions/confirmation';
import {
  WORKFLOW_STATE_CHANGE_SUCCEEDED,
  WORKFLOW_STATE_CHANGE_FAILED,
} from 'src/scripts/actions/workflow/tvWorkflow';
import { CLOSE_MODAL } from 'src/scripts/actions/modalItem';

export const initialState = {
  errorMessage: null,
  actionInProgress: false,
  confirmationModalDisplayed: false,
  descriptions: [],
  ok: null,
  reason: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CONFIRMATION_ACTION_FAILED:
      return {
        ...state,
        errorMessage: action.error,
        actionInProgress: false,
      };

    case CONFIRMATION_ACTION_STARTED:
      return {
        ...state,
        actionInProgress: true,
        errorMessage: null,
      };

    case WORKFLOW_STATE_CHANGE_SUCCEEDED:
      return {
        ...state,
        actionInProgress: false,
        errorMessage: null,
      };

    case WORKFLOW_STATE_CHANGE_FAILED:
      return {
        ...state,
        actionInProgress: false,
      };

    case SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalDisplayed: true,
        descriptions: action.descriptions,
        ok: action.ok,
        actionInProgress: false,
        reasonRequired: action.reasonRequired,
      };

    case UPDATE_CONFIRMATION_REASON:
      return {
        ...state,
        reason: action.reason,
        errorMessage: action.reason && action.reason.trim() ? null : 'Please provide a reason',
      };

    case HIDE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModalDisplayed: false,
        actionInProgress: false,
        descriptions: [],
      };

    case CLOSE_MODAL:
      return { ...initialState };

    default:
      return {
        ...state,
      };
  }
}
