import Joi from 'joi';
import moment from 'moment';
import { SCHEDULED_EVENT } from '../../../lib/liveEventScheduleTypes';
import { REMOTE_EVENT } from '../../../lib/liveEventTypes';

const VALID_CLASSIFICATION_CODES = ['P', 'C', 'G', 'PG', 'M', 'MA', 'AV', 'X', 'NA', 'U', 'TB'];
const STRING_WITHOUT_SPACES_REGEX = /^\S*$/;
// csv input date format: 24/07/2024 23:20:00
const DATE_REGEX = /^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2}$/;
const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss';
export const INVALID_FILE_ERROR = 'Invalid file type';
export const FETCH_GENRE_ERROR = 'Something went wrong please try again later';
export const INVALID_CSV_FILE_CONTENT_ERROR = 'CSV file content is invalid';

export const CSV_HEADER = {
  mandatoryfields: [
    'external id',
    'event name',
    'subtitle',
    'description',
    'video group',
    'ssai remote url',
    'remote asset url',
    'enable ssai',
    'enable live sports',
    'enable multi url',
    'oztam id',
    'event group',
    'promo start date/time',
    'avalibility start date/time',
    'avalibility end date/time',
    'image',
    'logo',
    'akamai cdn clear url',
    'akamai cdn tokenisation url',
    'akamai cdn encryption url',
    'akamai cdn tokenisation encryption url',
    'cloudfront cdn clear url',
    'cloudfront cdn tokenisation url',
    'cloudfront cdn encryption url',
    'cloudfront cdn tokenisation encryption url',
    'ssai Clear Url',
    'ssai encryption Url',
  ],
  optionalfields: [
    'genre name',
    'classification',
    'season',
    'up next genre name',
    'up next classification',
    'up next name',
    'up next description',
    'up next subtitle',
    'up next program start time',
    'up next program end time',
    'fast channel',
    'fast channel Id',
    'program start time',
    'program end time',
    'preRoll',
    'producer notes',
    'content targeting categories',
  ],
};

function getValidDate(dateTimeString) {
  if (!DATE_REGEX.test(dateTimeString)) {
    return null;
  }
  return moment(dateTimeString, DATE_TIME_FORMAT).toISOString();
}

const validateRemoteUrl = (value, helper, message) => {
  if ((value && value.endsWith('.m3u8')) || value.endsWith('.mpd')) {
    return true;
  }
  return helper.message(message);
};

export const LIVE_EVENT_SCHEMA = Joi.object({
  externalId: Joi.string().required(),
  name: Joi.string().required(),
  subtitle: Joi.string().required(),
  description: Joi.string().required(),
  scheduleType: Joi.string().valid('scheduled_event', 'instant_event').required(),
  videoGroupId: Joi.string()
    .valid('ShortformSport', 'ShortformEntertainment', 'ShortformNews', 'manuallyMapped')
    .required(),
  eventType: Joi.string().valid('remote_event').required(),
  remoteURL: Joi.string()
    .required()
    .custom((value, helper) => validateRemoteUrl(value, helper, 'Invalid remote URL extension')),
  ssaiRemoteURL: Joi.string()
    .required()
    .custom((value, helper) => validateRemoteUrl(value, helper, 'Invalid ssai remote URL extension')),
  isSports: Joi.boolean().required(),
  ssaiEnabled: Joi.boolean().required(),
  multiUrlEnabled: Joi.boolean().required(),
  oztamPublisherId: Joi.string().required(),
  liveEventGroupId: Joi.number().integer().required().min(0).options({ convert: false }),
  promoStartDate: Joi.date().required(),
  availabilityStartDate: Joi.date().required(),
  availabilityEndDate: Joi.date().required(),
  imageFileName: Joi.string()
    .required()
    .regex(STRING_WITHOUT_SPACES_REGEX)
    .message('Image file name should not contain spaces'),
  logoFileName: Joi.string()
    .required()
    .regex(STRING_WITHOUT_SPACES_REGEX)
    .message('Logo file name should not contain spaces'),
  liveEventUrls: Joi.object({
    akamaiCdnUrls: Joi.object({
      clearUrl: Joi.string().uri().required(),
      tokenisationUrl: Joi.string().uri().required(),
      encryptionUrl: Joi.string().uri().required(),
      tokenisationEncryptionUrl: Joi.string().uri().required(),
    }).required(),
    cloudfrontCdnUrls: Joi.object({
      clearUrl: Joi.string().uri().required(),
      tokenisationUrl: Joi.string().uri().required(),
      encryptionUrl: Joi.string().uri().required(),
      tokenisationEncryptionUrl: Joi.string().uri().required(),
    }).required(),
    ssaiClearUrl: Joi.string().uri().required(),
    ssaiEncryptionUrl: Joi.string().uri().required(),
  }).required(),

  // Optional fields
  contentTargetingCategories: Joi.array().items(Joi.string().required()).allow(null),
  genreId: Joi.number().integer().optional().allow(null).min(0).options({ convert: false }),
  genreName: Joi.string().optional().allow(null),
  programStartTime: Joi.string().optional().allow(null),
  programEndTime: Joi.string().optional().allow(null),
  classificationCode: Joi.string()
    .valid(...VALID_CLASSIFICATION_CODES)
    .optional()
    .allow(null),
  seasonId: Joi.number().optional().allow(null).options({ convert: false }),
  preRoll: Joi.boolean().optional(),
  isFastChannel: Joi.boolean().optional(),
  // Zype channel Id eg: "10ab4dab-910f-4d19-b1bc-f2fdd72d0fa3"
  fastChannel: Joi.string().optional().allow(null),
  producerNotes: Joi.string().optional().allow(null),
  upNextEvent: Joi.object({
    name: Joi.string().allow('').required(),
    subtitle: Joi.string().allow('').required(),
    description: Joi.string().allow('').required(),
    classificationCode: Joi.string()
      .valid(...VALID_CLASSIFICATION_CODES)
      .optional()
      .allow(''),
    genreId: Joi.number().integer().optional().allow(null).min(0).options({ convert: false }),
    genreName: Joi.string().allow(null).optional(),
    programStartTime: Joi.date().allow(null).optional(),
    programEndTime: Joi.date().allow(null).optional(),
  }).optional(),
}).options({ abortEarly: false });

export const CSV_TABLE_HEADER_OFFSET = 4;
export const LIVE_EVENT_COLUMN_NAME = {
  externalId: 'external id',
  name: 'event name',
  subtitle: 'subtitle',
  description: 'description',
  videoGroupId: 'video group',
  remoteURL: 'remote asset url',
  ssaiRemoteURL: 'ssai remote url',
  isSports: 'enable live sports',
  ssaiEnabled: 'enable ssai',
  multiUrlEnabled: 'enable multi url',
  oztamPublisherId: 'oztam id',
  liveEventGroupId: 'event group',
  promoStartDate: 'promo start date/time',
  availabilityStartDate: 'avalibility start date/time',
  availabilityEndDate: 'avalibility end date/time',
  imageFileName: 'image',
  logoFileName: 'logo',
  liveEventUrls: {
    akamaiCdnUrls: {
      clearUrl: 'akamai cdn clear url',
      tokenisationUrl: 'akamai cdn tokenisation url',
      encryptionUrl: 'akamai cdn encryption url',
      tokenisationEncryptionUrl: 'akamai cdn tokenisation encryption url',
    },
    cloudfrontCdnUrls: {
      clearUrl: 'cloudfront cdn clear url',
      tokenisationUrl: 'cloudfront cdn tokenisation url',
      encryptionUrl: 'cloudfront cdn encryption url',
      tokenisationEncryptionUrl: 'cloudfront cdn tokenisation encryption url',
    },
    ssaiClearUrl: 'ssai Clear Url',
    ssaiEncryptionUrl: 'ssai encryption Url',
  },
  genreName: 'genre name',
  programStartTime: 'program start time',
  programEndTime: 'program end time',
  classificationCode: 'classification',
  seasonId: 'season',
  preRoll: 'preRoll',
  isFastChannel: 'fast channel',
  fastChannel: 'fast channel Id',
  producerNotes: 'producer notes',
  upNextEvent: {
    name: 'up next genre name',
    subtitle: 'up next subtitle',
    description: 'up next description',
    classificationCode: 'up next classification',
    genreName: 'up next genre name',
    programStartTime: 'up next program start time',
    programEndTime: 'up next program end time',
  },
  contentTargetingCategories: 'content targeting categories',
};

const getUpNextEventMetadata = (columnNameToIndicesMap, row) => {
  return {
    name: row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.name]] || '',
    subtitle: row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.subtitle]] || '',
    description:
      row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.description]] || '',
    classificationCode:
      row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.classificationCode]] || '',
    genreName:
      row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.genreName]] || null,
    programStartTime:
      getValidDate(
        row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.programStartTime]]
      ) || null,
    programEndTime:
      getValidDate(
        row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME.upNextEvent.programEndTime]]
      ) || null,
  };
};
const getOptionalFields = (columnNameToIndicesMap, row) => {
  const result = {};
  const optionalFields = [
    'genreName',
    'classificationCode',
    'seasonId',
    'preRoll',
    'isFastChannel',
    'fastChannel',
    'producerNotes',
  ];
  const optionalDateFields = ['programStartTime', 'programEndTime'];
  const contentTargetingCategoriesField = 'contentTargetingCategories';
  const contentTargetingCategoriesFieldValue =
    row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME[contentTargetingCategoriesField]]];
  if (contentTargetingCategoriesFieldValue) {
    const valueWithoutQuotes = contentTargetingCategoriesFieldValue.replace(/"/g, '');
    result[contentTargetingCategoriesField] = valueWithoutQuotes.split(',');
  }
  optionalFields.forEach((field) => {
    const rowValue = row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME[field]]];
    if (rowValue) {
      result[field] = rowValue;
    }
  });

  optionalDateFields.forEach((field) => {
    const rowValue = getValidDate(row[columnNameToIndicesMap.optionalfields[LIVE_EVENT_COLUMN_NAME[field]]]);
    if (rowValue) {
      result[field] = rowValue;
    }
  });
  result.upNextEvent = getUpNextEventMetadata(columnNameToIndicesMap, row);
  return result;
};

export function getLiveEventsMetadataFromCsvData(columnNameToIndicesMap, liveEventsMetadataRows) {
  if (!columnNameToIndicesMap || !liveEventsMetadataRows) {
    return [];
  }
  const liveEventsMetadata = liveEventsMetadataRows.map((row) => ({
    scheduleType: SCHEDULED_EVENT,
    externalId: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.externalId]],
    name: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.name]],
    subtitle: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.subtitle]],
    description: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.description]],
    videoGroupId: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.videoGroupId]],
    eventType: REMOTE_EVENT,
    remoteURL: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.remoteURL]],
    ssaiRemoteURL: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.ssaiRemoteURL]],
    isSports: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.isSports]],
    ssaiEnabled: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.ssaiEnabled]],
    multiUrlEnabled: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.multiUrlEnabled]],
    oztamPublisherId: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.oztamPublisherId]],
    liveEventGroupId: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.liveEventGroupId]],
    promoStartDate: getValidDate(
      row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.promoStartDate]]
    ),
    availabilityStartDate: getValidDate(
      row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.availabilityStartDate]]
    ),
    availabilityEndDate: getValidDate(
      row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.availabilityEndDate]]
    ),
    imageFileName: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.imageFileName]],
    logoFileName: row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.logoFileName]],
    liveEventUrls: {
      akamaiCdnUrls: {
        clearUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.clearUrl
            ]
          ],
        tokenisationUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.tokenisationUrl
            ]
          ],
        encryptionUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.encryptionUrl
            ]
          ],
        tokenisationEncryptionUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.akamaiCdnUrls.tokenisationEncryptionUrl
            ]
          ],
      },
      cloudfrontCdnUrls: {
        clearUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.clearUrl
            ]
          ],
        tokenisationUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.tokenisationUrl
            ]
          ],
        encryptionUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.encryptionUrl
            ]
          ],
        tokenisationEncryptionUrl:
          row[
            columnNameToIndicesMap.mandatoryfields[
              LIVE_EVENT_COLUMN_NAME.liveEventUrls.cloudfrontCdnUrls.tokenisationEncryptionUrl
            ]
          ],
      },
      ssaiClearUrl:
        row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.ssaiClearUrl]],
      ssaiEncryptionUrl:
        row[columnNameToIndicesMap.mandatoryfields[LIVE_EVENT_COLUMN_NAME.liveEventUrls.ssaiEncryptionUrl]],
    },
    ...getOptionalFields(columnNameToIndicesMap, row),
  }));
  return liveEventsMetadata;
}

export const isCsvFileHeaderValid = (csvHeader) => {
  return CSV_HEADER.mandatoryfields.length === Object.keys(csvHeader).length;
};

export const getColumnNameToIndicesMap = (csvHeader) => {
  if (!csvHeader) {
    return {};
  }
  const CSV_INDICES_TO_COLUMN_NAMES_MAP = {
    mandatoryfields: {},
    optionalfields: {},
  };

  const csvHeaderEntries = Object.entries(csvHeader);

  CSV_HEADER.mandatoryfields.forEach((field) => {
    for (const [key, value] of csvHeaderEntries) {
      if (field.toLowerCase().trim() === (value && value.toLowerCase().trim())) {
        CSV_INDICES_TO_COLUMN_NAMES_MAP.mandatoryfields[value] = key;
      }
    }
  });

  CSV_HEADER.optionalfields.forEach((field) => {
    for (const [key, value] of csvHeaderEntries) {
      if (field.toLowerCase().trim() === value && value.toLowerCase().trim()) {
        CSV_INDICES_TO_COLUMN_NAMES_MAP.optionalfields[value] = key;
      }
    }
  });

  return CSV_INDICES_TO_COLUMN_NAMES_MAP;
};

export const validateLiveEventMetadata = (liveEventsToUpload) =>
  liveEventsToUpload
    .map((liveEventMetadata, index) => ({
      index,
      externalId: liveEventMetadata.externalId,
      error: LIVE_EVENT_SCHEMA.validate(liveEventMetadata).error,
    }))
    .filter((item) => item.error !== undefined);
