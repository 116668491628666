import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { updateSort, updateFilter } from 'src/scripts/actions/itemList';
import { deselectAll } from 'src/scripts/actions/bulkAction';
import { getDateFilterActiveState, isFilterActive } from 'src/scripts/reducers/itemList';
import FilterText from './filters/Text';
import FilterDropdown from './filters/Dropdown';
import FilterMultiDropdown from './filters/MultiDropdown';
import FilterDate from './filters/Date';
import { createNewObjectNoNullValues } from '../../helpers';

export const FILTER_TYPES = {
  TEXT: 'text',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  MULTI_DROPDOWN: 'multi-dropdown',
};

const filterMap = new Map([
  [FILTER_TYPES.TEXT, FilterText],
  [FILTER_TYPES.DATE, FilterDate],
  [FILTER_TYPES.DROPDOWN, FilterDropdown],
  [FILTER_TYPES.MULTI_DROPDOWN, FilterMultiDropdown],
]);

function getSortingValue(ascending, sortBy) {
  if (sortBy && typeof ascending === 'boolean') {
    return { sort: `${ascending ? '+' : '-'}${sortBy}` };
  }
  return { sort: null };
}

export class ItemListHeader extends React.Component {
  constructor(props) {
    super(props);

    /**
     * `revision', the value of which flips between 0 and 1,
     * determines if the underlying filter values need to be cleared.
     * The underlying filter values are cleared once there is a flip.
     */
    this.state = { revision: 1 };

    this.onSortClick = this.onSortClick.bind(this);
    this.getItems = this.getItems.bind(this);
    this.applyFilter = this.applyFilter.bind(this, this.props.dataField);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.filters[this.props.dataField] &&
      !nextProps.filters[this.props.dataField] &&
      nextProps.filters[this.props.dataField] !== null
    ) {
      this.getItems(null, nextProps.filters);
      this.clearInput();
    }
  }

  clearInput() {
    this.setState((prevState) => ({ revision: prevState.revision + (1 % 2) }));
  }

  onSortClick() {
    if (this.props.sort) {
      let isAscending;
      if (this.props.sortBy === this.props.dataField) {
        isAscending = !this.props.ascending;
      } else {
        isAscending = true;
      }
      this.props.updateSort({
        sortBy: this.props.dataField,
        ascending: isAscending,
      });
      this.getItems(
        getSortingValue(isAscending, this.props.dataField).sort,
        createNewObjectNoNullValues(this.props.filters)
      );
    }
  }

  getItems(sort, filters) {
    this.props.getItems({
      sort,
      filters,
    });
  }

  applyFilter(name, value) {
    this.props.bulkActionDeselectAll();
    const filter = {
      name,
      value,
    };
    this.props.updateFilter(filter);
    const filters = { ...this.props.filters };

    if (filter.value === null) {
      delete filters[filter.name];
    } else {
      filters[filter.name] = filter.value;
    }
    this.getItems(this.props.sortValue, filters);
  }

  getCheckboxFilterValues() {
    if (this.props.filters) {
      const currentFilter = this.props.filters[this.props.dataField];
      if (currentFilter) {
        return currentFilter.replace('in:', '').split(',');
      }
    }

    return null;
  }

  getAlignLeft() {
    if (_.isObject(this.props.filter) && 'alignLeft' in this.props.filter) {
      return this.props.filter.alignLeft;
    }

    return null;
  }

  getFilter() {
    if (!this.props.filter) {
      return null;
    }

    const type = this.props.filter === true ? FILTER_TYPES.TEXT : this.props.filter.type;
    const FilterComponent = filterMap.get(type);
    if (!FilterComponent) {
      return null;
    }

    return (
      <FilterComponent
        alignLeft={this.getAlignLeft()}
        applyFilter={this.applyFilter}
        checkboxFilterValues={this.getCheckboxFilterValues()}
        filter={this.props.filter}
        filterClassName={this.props.filterClassName}
        multiDropdownSelectedItems={this.props.multiDropdownSelectedItems}
        multiDropdownOnChange={this.props.multiDropdownOnChange}
        noDebounce={this.props.noDebounce}
        isActiveDateFilter={this.props.isActiveDateFilter}
        isFilterActive={this.props.isFilterActive}
        revision={this.state.revision}
        hasSelectAllOption={this.props.hasSelectAllOption}
      />
    );
  }

  getSortingIcon() {
    if (!this.props.sort) {
      return null;
    }
    let iconClass = 'fa fa-sort';
    if (this.props.sortBy === this.props.dataField) {
      iconClass = this.props.ascending ? 'fa fa-caret-up' : 'fa fa-caret-down';
    }
    return <span className={`sortable-icon ${iconClass}`}></span>;
  }

  render() {
    const Component = this.props.component || 'th';
    return (
      <Component
        className="item-list-header capitalize"
        id={`item-list-header-${this.props.label.toLowerCase().replace(/ /g, '-')}`}
        data-pw={`item-list-header-${this.props.label.toLowerCase().replace(/ /g, '-')}`}
      >
        <div onClick={this.onSortClick} className={`sort-button ${this.props.sort ? 'sortable' : ''}`}>
          {this.props.label}
          {this.getSortingIcon()}
        </div>
        {this.getFilter()}
      </Component>
    );
  }
}

ItemListHeader.propTypes = {
  hasSelectAllOption: PropTypes.bool,
  filterClassName: PropTypes.string,
  sort: PropTypes.bool,
  noDebounce: PropTypes.bool,
  sortBy: PropTypes.string,
  sortValue: PropTypes.string,
  filter: PropTypes.any,
  ascending: PropTypes.bool,
  filters: PropTypes.object,
  getItems: PropTypes.func,
  updateSort: PropTypes.func,
  updateFilter: PropTypes.func,
  label: PropTypes.string,
  dataField: PropTypes.string,
  component: PropTypes.string,
  isActiveDateFilter: PropTypes.bool,
  isFilterActive: PropTypes.bool,
  bulkActionDeselectAll: PropTypes.func,
  multiDropdownOnChange: PropTypes.func,
  multiDropdownSelectedItems: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  return {
    sortBy: state.itemList.sortBy,
    sortValue: state.itemList.sort,
    ascending: state.itemList.ascending,
    filters: state.itemList.filters,
    isActiveDateFilter: getDateFilterActiveState(ownProps.dataField, state),
    isFilterActive: isFilterActive(ownProps.dataField, state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSort: ({ sortBy, ascending }) => dispatch(updateSort({ sortBy, ascending })),
    updateFilter: ({ name, value }) => dispatch(updateFilter({ name, value })),
    bulkActionDeselectAll: () => dispatch(deselectAll()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemListHeader);
