import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Panel } from 'react-bootstrap';
import { deleteIngestJob } from 'src/scripts/actions/ingestJobs';
import { getSignedVideoSourceUrl, getSignedCaptionSourceUrl } from 'src/scripts/actions/video';
import DownloadButton from 'src/scripts/components/DownloadButton';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { transformIngestJobForDisplay } from 'src/scripts/components/IngestJob/viewModel';
import VideoPreview from 'src/scripts/components/MediaItem/VideoPreview';
import IngestJobDetailsToolbar from 'src/scripts/components/IngestJob/internal/IngestJobDetailsToolbar';
import MatchEpisodeModal from 'src/scripts/components/IngestJob/internal/MatchEpisodeModal';
import { TV_PATH } from 'src/scripts/lib/accessController';
import CuePointNavigator from '../../MediaItem/CuePointNavigator';
import EndPointNavigator from '../../MediaItem/EndPointNavigator';
import VideoAutoQcNavigator from '../../MediaItem/VideoAutoQcNavigator';

export class IngestJobDetails extends React.Component {
  renderSectionContent(sectionData) {
    return sectionData.map((item, index) => {
      if (item.downloadCaptions) {
        return (
          <DetailsRowItem label={item.label} key={index}>
            <DownloadButton
              fileName={item.value}
              signedMediaSourceUrl={this.props.signedCaptionSourceUrl}
              getSignedMediaSourceUrl={this.props.getSignedCaptionSourceUrl.bind(
                null,
                VIDEOS.TV,
                this.props.ingestJob.video.id
              )}
            />
          </DetailsRowItem>
        );
      }
      if (item.download) {
        return (
          <DetailsRowItem label={item.label} key={index}>
            <DownloadButton
              fileName={item.value}
              signedMediaSourceUrl={this.props.signedVideoSourceUrl}
              getSignedMediaSourceUrl={this.props.getSignedVideoSourceUrl.bind(
                null,
                VIDEOS.TV,
                this.props.ingestJob.video.id
              )}
            />
          </DetailsRowItem>
        );
      }
      return (
        <DetailsRowItem label={item.label} key={index}>
          {item.value}
        </DetailsRowItem>
      );
    });
  }

  renderVideo() {
    const generateCaptionPropsIfCaptionsExist = () => {
      if (this.props.ingestJob.video && this.props.ingestJob.video.captionsS3Key) {
        return {
          signedCaptionSourceUrl: this.props.signedCaptionSourceUrl,
          getSignedCaptionSourceUrl: this.props.getSignedCaptionSourceUrl.bind(
            null,
            VIDEOS.TV,
            this.props.ingestJob.video.id
          ),
        };
      }
      return {};
    };

    if (this.props.ingestJob.video.videoFileName) {
      return (
        <VideoPreview
          videoCuePoints={this.props.ingestJob.video.cuePoints}
          signedVideoSourceUrl={this.props.signedVideoSourceUrl}
          getSignedVideoSourceUrl={this.props.getSignedVideoSourceUrl.bind(
            null,
            VIDEOS.TV,
            this.props.ingestJob.video.id
          )}
          {...generateCaptionPropsIfCaptionsExist()}
        />
      );
    }
    return null;
  }

  getVideoDuration = () => {
    if (this.props.videoPlayer) {
      const videoPlayerDuration = this.props.videoDuration;
      if (videoPlayerDuration >= 0) {
        return videoPlayerDuration;
      }
    }
    return null;
  };

  setVideoTime = (time) => {
    if (this.props.videoPlayer) {
      if (time >= 0) {
        this.props.videoPlayer.currentTime(time);
      }
    }
  };

  render() {
    const viewModels = transformIngestJobForDisplay(this.props.ingestJob, this.props.episode);
    const videoDuration = this.getVideoDuration();
    return (
      <div>
        <ol className="mi9-breadcrumb breadcrumb">
          <li>
            <a href={`/#/${TV_PATH}/videos/ingestJobs`}>Ingest Jobs</a>
          </li>
          <li>{this.props.ingestJob.name}</li>
        </ol>
        <Row className="details-toolbar">
          <IngestJobDetailsToolbar
            ingestJob={this.props.ingestJob}
            deleteIngestJob={this.props.deleteIngestJob}
          />
        </Row>
        <Col md={8} mdOffset={2}>
          <div style={{ 'margin-bottom': '20px' }}>{this.renderVideo()}</div>
          <Panel
            header="Video Navigation"
            className="details-section-panel cuePointNavigator"
            collapsible
            defaultExpanded
          >
            {this.props.ingestJob.video.videoAutoQcs.length > 0 && (
              <VideoAutoQcNavigator
                videoAutoQc={
                  this.props.ingestJob.video.videoAutoQcs[this.props.ingestJob.video.videoAutoQcs.length - 1]
                }
                setVideoTime={this.setVideoTime}
              />
            )}
            <CuePointNavigator
              videoCuePoints={this.props.ingestJob.video.cuePoints}
              setVideoTime={this.setVideoTime}
              videoDuration={videoDuration}
            />
            <EndPointNavigator videoEndPoint={videoDuration} setVideoTime={this.setVideoTime} />
            <span style={{ display: 'block', float: 'right', color: '#808080' }}>
              Hours : Minutes : Seconds . Milliseconds
            </span>
          </Panel>
          <Panel header="Video" className="details-section-panel videoDetails" collapsible defaultExpanded>
            <MatchEpisodeModal ingestJob={this.props.ingestJob} />
            {this.renderSectionContent(viewModels.videoDetails)}
          </Panel>
          <Panel header="TV Series" className="details-section-panel tvSeriesDetails" collapsible>
            {this.renderSectionContent(viewModels.tvSeriesDetails)}
          </Panel>
          <Panel header="Season" className="details-section-panel seasonDetails" collapsible>
            {this.renderSectionContent(viewModels.seasonDetails)}
          </Panel>
          <Panel
            header="Episode"
            className="details-section-panel episodeDetails"
            collapsible
            defaultExpanded
          >
            {this.renderSectionContent(viewModels.episodeDetails)}
          </Panel>
          <Panel header="Original XML" className="details-section-panel xmlDetails" collapsible>
            <pre>{this.props.xmlSource}</pre>
          </Panel>
        </Col>
      </div>
    );
  }
}

IngestJobDetails.propTypes = {
  xmlSource: PropTypes.string,
  ingestJob: PropTypes.object,
  episode: PropTypes.object,
  signedVideoSourceUrl: PropTypes.object.isRequired,
  signedCaptionSourceUrl: PropTypes.object.isRequired,
  deleteIngestJob: PropTypes.func,
  getSignedVideoSourceUrl: PropTypes.func,
  getSignedCaptionSourceUrl: PropTypes.func,
  videoPlayer: PropTypes.object,
  videoDuration: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    signedVideoSourceUrl: state.mediaItemDetailsView.signedVideoSourceUrl,
    signedCaptionSourceUrl: state.mediaItemDetailsView.signedCaptionSourceUrl,
    videoDuration: state.videoPlayer.duration,
    videoPlayer: state.videoPlayer.player,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteIngestJob: (ingestJobId) => dispatch(deleteIngestJob(ingestJobId)),
    getSignedVideoSourceUrl: (resourceGroup, videoId) =>
      dispatch(getSignedVideoSourceUrl(resourceGroup, videoId)),
    getSignedCaptionSourceUrl: (resourceGroup, videoId) =>
      dispatch(getSignedCaptionSourceUrl(resourceGroup, videoId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestJobDetails);
