import get from 'lodash/get';
import set from 'lodash/set';

export const multiUrlSkeletonObject = {
  akamaiCdnUrls: {
    clearUrl: '',
    tokenisationUrl: '',
    encryptionUrl: '',
    tokenisationEncryptionUrl: '',
  },
  cloudfrontCdnUrls: {
    clearUrl: '',
    tokenisationUrl: '',
    encryptionUrl: '',
    tokenisationEncryptionUrl: '',
  },
  ssaiClearUrl: '',
  ssaiEncryptionUrl: '',
};

export const isMultiUrlObjectValid = (multiUrlObject) => {
  return multiUrlObject && multiUrlObject.akamaiCdnUrls && multiUrlObject.cloudfrontCdnUrls;
};

const refToPathMap = {
  'ssai-def-clear': ['ssaiClearUrl'],
  'ssai-def-encrypted': ['ssaiEncryptionUrl'],

  'ak-clear': ['akamaiCdnUrls', 'clearUrl'],
  'ak-tokenised': ['akamaiCdnUrls', 'tokenisationUrl'],
  'ak-encrypted': ['akamaiCdnUrls', 'encryptionUrl'],
  'ak-encrypted-tokenised': ['akamaiCdnUrls', 'tokenisationEncryptionUrl'],

  'cf-clear': ['cloudfrontCdnUrls', 'clearUrl'],
  'cf-tokenised': ['cloudfrontCdnUrls', 'tokenisationUrl'],
  'cf-encrypted': ['cloudfrontCdnUrls', 'encryptionUrl'],
  'cf-encrypted-tokenised': ['cloudfrontCdnUrls', 'tokenisationEncryptionUrl'],
};

export const multiUrlInputOptions = [
  {
    label: 'Default',
    fields: [
      { label: 'SSAI clear URL', placeholder: 'Enter SSAI clear URL...', ref: 'ssai-def-clear' },
      { label: 'SSAI encrypted URL', placeholder: 'Enter SSAI encrypted URL...', ref: 'ssai-def-encrypted' },
    ],
  },
  {
    label: 'Akamai',
    fields: [
      { label: 'Clear URL', placeholder: 'Enter Akamai clear URL...', ref: 'ak-clear' },
      { label: 'Encrypted URL', placeholder: 'Enter Akamai encryoted URL...', ref: 'ak-encrypted' },
      {
        label: 'Tokenised URL',
        placeholder: 'Enter Akamai unencrypted tokenised URL...',
        ref: 'ak-tokenised',
      },
      {
        label: 'Encrypted & tokenised URL',
        placeholder: 'Enter Akamai encrypted tokenised URL...',
        ref: 'ak-encrypted-tokenised',
      },
    ],
  },
  {
    label: 'CloudFront',
    fields: [
      { label: 'Clear URL', placeholder: 'Enter Akamai clear URL...', ref: 'cf-clear' },
      { label: 'Encrypted URL', placeholder: 'Enter Akamai encryoted URL...', ref: 'cf-encrypted' },
      {
        label: 'Tokenised URL',
        placeholder: 'Enter Akamai unencrypted tokenised URL...',
        ref: 'cf-tokenised',
      },
      {
        label: 'Encrypted & tokenised URL',
        placeholder: 'Enter Akamai encrypted tokenised URL...',
        ref: 'cf-encrypted-tokenised',
      },
    ],
  },
];

export const updateMultiUrlObject = (multiUrlObject, ref, value) => {
  const path = refToPathMap[ref];
  if (!path) {
    throw new Error(`Invalid ref: ${ref}`);
  }

  return set({ ...multiUrlObject }, path, value);
};

export const getPropValueByRef = (liveEventUrls, ref) => {
  const path = refToPathMap[ref];
  if (!path) {
    throw new Error(`Invalid ref: ${ref}`);
  }

  return get(liveEventUrls, path);
};
