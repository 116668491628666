import { GET_CURRENT_SEASON_SUCCEEDED, GET_CURRENT_SEASON_FAILED } from 'src/scripts/actions/season';

export const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_SEASON_SUCCEEDED:
      return {
        ...action.data.season,
      };
    case GET_CURRENT_SEASON_FAILED:
      return null;
    default:
      return state;
  }
}
