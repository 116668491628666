import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

const SeoMetadataPanel = ({ season }) => {
  return (
    <Panel header="SEO Metadata">
      <DetailsRowItem label="SEO TV Series">
        <FormatText>{season.seoTvSeries}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="SEO Page Title">
        <FormatText>{season.seoPageTitle}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="SEO Description">
        <FormatText>{season.seoDescription}</FormatText>
      </DetailsRowItem>
    </Panel>
  );
};

SeoMetadataPanel.propTypes = {
  season: PropTypes.object,
};

export default SeoMetadataPanel;
