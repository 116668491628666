import _ from 'lodash';
import * as requestHandler from '../requestHandler';
import { IMAGES, VIDEOS } from 'src/scripts/lib/resourceGroups';
import { getPrefix } from './endpointPrefixResolver';

// exported only to simplify testing
export function validateResourceGroup(resourceGroup) {
  switch (resourceGroup) {
    case IMAGES.TV:
    case VIDEOS.TV:
    case IMAGES.NETWORK:
    case VIDEOS.NETWORK:
    case IMAGES.TV_LIVE_EVENTS:
    case IMAGES.NETWORK_LIVE_EVENTS:
      return;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}

export function getS3Signatures(params) {
  validateResourceGroup(params.resourceGroup);
  return requestHandler.send(
    'POST',
    `${getPrefix(params.resourceGroup)}/uploads/s3-signatures`,
    JSON.stringify(_.omit(params, 'resourceGroup'))
  );
}
