export const REMOTE_EVENT = 'remote_event';
export const BROADCAST_EVENT = 'broadcast_event';

export const PHOENIX_IDENTIFIER_TYPES = {
  CATALOG_CODES: 'catalogCodes',
  MATERIAL_KEYS: 'materialKeys',
};

export const isRemoteEvent = (liveEvent) => {
  return liveEvent.eventType === REMOTE_EVENT;
};

export const isBroadcastEvent = (liveEvent) => {
  return liveEvent.eventType === BROADCAST_EVENT;
};

export const isFastChannel = (liveEvent) => {
  return !!liveEvent.isFastChannel;
};

export const isMultiUrlEnabled = (liveEvent) => {
  return !!liveEvent.multiUrlEnabled;
};
