import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import EpisodeForm from 'src/scripts/components/Episode/internal/EpisodeForm';
import { getEpisodeFormInitialData } from 'src/scripts/actions/episode';

export class EpisodeFormLoader extends React.Component {
  componentDidMount() {
    this.props.getEpisodeFormInitialData(
      this.props.episodeId,
      this.props.ingestJobId,
      this.props.episodeSeedData
    );
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <EpisodeForm
          isEpisodeDraggedandDropped={this.props.isEpisodeDraggedandDropped}
          close={this.props.close}
          ingestJobId={this.props.ingestJobId}
          isReschedule={this.props.isReschedule}
        />
      </ViewLoader>
    );
  }
}

EpisodeFormLoader.propTypes = {
  episodeId: PropTypes.number,
  ingestJobId: PropTypes.number,
  isReschedule: PropTypes.bool, // This should be remove when we have proper video states and a save method should be passed
  initialFormDataLoaded: PropTypes.bool,
  getEpisodeFormInitialData: PropTypes.func,
  episodeSeedData: PropTypes.func,
  close: PropTypes.func,
  isEpisodeDraggedandDropped: PropTypes.bool,
};

function mapStateToProps(state) {
  const episodeForm = state.episodeForm;
  return {
    initialFormDataLoaded: episodeForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEpisodeFormInitialData: (episodeId, ingestJobId, episodeSeedData) =>
      dispatch(getEpisodeFormInitialData(episodeId, ingestJobId, episodeSeedData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeFormLoader);
