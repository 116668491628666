export const HAMBURGER_NAVBAR_TOGGLED = 'UI/HAMBURGER_NAVBAR_TOGGLED';
export const HAMBURGER_NAVBAR_CLOSED = 'UI/HAMBURGER_NAVBAR_CLOSED';

export const toggleHamburgerNavbar = {
  type: HAMBURGER_NAVBAR_TOGGLED,
};

export const closeHamburgerNavbar = {
  type: HAMBURGER_NAVBAR_CLOSED,
};
