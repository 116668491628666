import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';

const validateAndUpdate = (rule, validator, formState, validationErrors) => {
  const appendNewLine = (string) => `${string}\n`;
  const errorMessage = validator(rule, formState);
  if (errorMessage) {
    validationErrors[rule.field] = appendNewLine(errorMessage);
    return false;
  }
  return true;
};

const ruleHasGeneratedExistingError = (rule, validationErrors) => validationErrors.hasOwnProperty(rule.field);

export const getValidationErrors = (formState, validationRules) => {
  const validationErrors = {};
  validationRules.forEach((rule) => {
    if (ruleHasGeneratedExistingError(rule, validationErrors)) return;
    if (typeof rule.customValidators === 'function') {
      validateAndUpdate(rule, rule.customValidators, formState, validationErrors);
    } else if (Array.isArray(rule.customValidators)) {
      for (let i = 0; i < rule.customValidators.length; i++) {
        if (!validateAndUpdate(rule, rule.customValidators[i], formState, validationErrors)) {
          return;
        }
      }
    }
  });
  return validationErrors;
};

export const isFormValid = (formState, validationRules) => {
  const validationErrorsObject = getValidationErrors(formState, validationRules);
  return _isEmpty(validationErrorsObject);
};

export const getValidationErrorMessage = (validationErrorsObject) =>
  _reduce(
    validationErrorsObject,
    (singlecomposedErrorMessage, currentErrorMessage) => {
      return singlecomposedErrorMessage + currentErrorMessage;
    },
    ''
  );
