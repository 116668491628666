import {
  GET_SEASONS_SUCCEEDED,
  GET_SEASONS_FAILED,
  DELETE_SEASON_SUCCEEDED,
} from 'src/scripts/actions/season';
import { immutableRemoveById } from 'src/scripts/lib/util';
export const initialState = {
  seasons: [],
  seasonsCount: 0,
  offset: 0,
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SEASONS_SUCCEEDED:
      return {
        ...state,
        seasons: action.data.seasons,
        seasonsCount: action.data.count,
        offset: action.offset,
      };

    case GET_SEASONS_FAILED:
      return { ...state, errorMessage: action.error };

    case DELETE_SEASON_SUCCEEDED:
      return { ...state, seasons: immutableRemoveById(state.seasons, action.id) };

    default:
      return state;
  }
}
