import {
  LIVE_EVENT_GROUP_IMAGE,
  LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
  LIVE_EVENT_GROUP_LOGO,
  LIVE_EVENT_GROUP_SWITCHER_LOGO,
} from 'src/scripts/lib/imageTypes';

export default {
  [LIVE_EVENT_GROUP_IMAGE]: {
    type: LIVE_EVENT_GROUP_IMAGE,
    header: 'Live Event Group Image',
    wrapperClassName: 'live-event-group-detail-image-wrapper',
  },
  [LIVE_EVENT_GROUP_BACKGROUND_IMAGE]: {
    type: LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
    header: 'Live Event Group Background Image',
    wrapperClassName: 'live-event-group-detail-image-wrapper',
  },
  [LIVE_EVENT_GROUP_LOGO]: {
    type: LIVE_EVENT_GROUP_LOGO,
    header: 'Live Event Group Background Image',
    wrapperClassName: 'live-event-group-detail-image-wrapper',
  },
  [LIVE_EVENT_GROUP_SWITCHER_LOGO]: {
    type: LIVE_EVENT_GROUP_SWITCHER_LOGO,
    header: 'Live Event Group Switcher Logo',
    wrapperClassName: 'live-event-group-detail-image-wrapper',
  },
};
