import * as tagsVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/tags';

export const GET_TAGS_SUCCEEDED = 'GET_TAGS_SUCCEEDED';
export const GET_TAGS_FAILED = 'GET_TAGS_FAILED';
export const CLEAR_TAGS = 'CLEAR_TAGS';
export const GET_TAGS_STARTED = 'GET_TAGS_STARTED';

export function getTags(tagsName, resourceGroup, includeTagCount) {
  return (dispatch) => {
    dispatch({ type: GET_TAGS_STARTED });

    const onSuccess = (data) => {
      dispatch({
        type: GET_TAGS_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_TAGS_FAILED,
        error: response.responseJSON ? response.responseJSON.message : 'error',
      });
    };
    return tagsVmsApi.getTags(resourceGroup, tagsName, includeTagCount).then(onSuccess, onError);
  };
}

export function clearTags() {
  return {
    type: CLEAR_TAGS,
  };
}
