import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Input } from 'react-bootstrap';
import moment from 'moment';
import {
  ORIGINAL_ORIGIN_TYPE,
  FAIR_DEALING_ORIGIN_TYPE,
  THIRD_PARTY_ORIGIN_TYPE,
  TV_ORIGIN_TYPE,
} from 'src/scripts/lib/contentOriginTypes';
import TVSeriesSeasonSelect from 'src/scripts/components/TVSeriesSeasonSelect';

const getExpiryDate = (availability, timePeriod, type) => {
  switch (type) {
    case 'day':
    case 'month':
      return availability.add(timePeriod, type);
    case 'date':
      return timePeriod ? moment(`${timePeriod}`).format() : timePeriod;
    default:
      return null;
  }
};

const getClipAvailabilityMessage = (timePeriod, type) => {
  switch (type) {
    case 'day':
    case 'month':
      return `Licence terms ${timePeriod} ${type}s`;
    case 'date':
      return timePeriod
        ? `Licence terms until ${moment(`${timePeriod}`).format('LL')}`
        : 'Licence terms in perpetuity';
    default:
      return null;
  }
};

export class ContentOriginPanel extends React.Component {
  constructor() {
    super();
    this.onContentOriginTypeChange = this.onContentOriginTypeChange.bind(this);
    this.isOriginTypeChecked = this.isOriginTypeChecked.bind(this);
    this.onThirdPartySelect = this.onThirdPartySelect.bind(this);
    this.onTVSeriesSeasonChange = this.onTVSeriesSeasonChange.bind(this);
    this.state = {
      displayClipAvailabilityMessage: null,
    };
  }

  onContentOriginTypeChange(event) {
    const contentOriginType = event.target.value;
    this.props.onChange({ contentOriginType });
    const availability = this.props.clip.availability ? moment(this.props.clip.availability) : null;
    if (contentOriginType === THIRD_PARTY_ORIGIN_TYPE) {
      this.props.getThirdPartyProviders();
    }
    if (contentOriginType === FAIR_DEALING_ORIGIN_TYPE) {
      availability &&
        this.props.onChange({
          expiry: availability.add(3, 'day'),
        });
    }
  }

  onThirdPartySelect(event) {
    const thirdPartyProvider = event.target.value;
    const thirdPartyProviderObject = this.props.thirdPartyProviders.find((obj) => {
      return obj.name === thirdPartyProvider;
    });
    const availability = this.props.clip.availability ? moment(this.props.clip.availability) : null;
    const expiry = getExpiryDate(
      availability,
      thirdPartyProviderObject.timePeriod,
      thirdPartyProviderObject.type
    );
    const message = getClipAvailabilityMessage(
      thirdPartyProviderObject.timePeriod,
      thirdPartyProviderObject.type
    );

    this.setState({
      displayClipAvailabilityMessage: message,
    });
    this.props.onChange({
      thirdPartyProvider,
      expiry,
    });
  }

  onTVSeriesSeasonChange(tvSeriesId, seasonId) {
    this.props.onChange({
      contentOriginTVSeriesId: tvSeriesId,
      contentOriginSeasonId: seasonId,
    });
  }
  isOriginTypeChecked(originType) {
    if (!this.props.clip.contentOriginType) return false;
    return this.props.clip.contentOriginType === originType;
  }

  renderContentOriginType = (label, value) => (
    <Input
      type="radio"
      groupClassName="form__radio-input-inline"
      label={label}
      ref="originalContentType"
      value={value}
      onClick={this.onContentOriginTypeChange}
      checked={this.isOriginTypeChecked(value)}
    />
  );
  renderThirdPartyOptions = () => (
    <div>
      <Input
        type="select"
        label="3rd Party"
        ref="selectType"
        id="select-type"
        labelClassName="required"
        onChange={this.onThirdPartySelect}
        value={this.props.clip.thirdPartyProvider}
        bsStyle={this.props.validationErrors.thirdPartyProvider ? 'error' : null}
      >
        <option disabled selected>
          Select 3rd Party
        </option>
        {this.props.thirdPartyProviders.map((thirdParty) => {
          return <option value={thirdParty.name}>{thirdParty.name}</option>;
        })}
      </Input>
      <span className={`${this.state.displayClipAvailabilityMessage ? 'clip-availability-message' : 'hide'}`}>
        <i className="fa fa-info-circle"></i> {this.state.displayClipAvailabilityMessage}
      </span>
    </div>
  );

  renderTVSeriesSeasonSelect = () => (
    <TVSeriesSeasonSelect
      onChange={this.onTVSeriesSeasonChange}
      getSeasons={this.props.getSeasons}
      tvSeriesId={this.props.clip.contentOriginTVSeriesId}
      seasonId={this.props.clip.contentOriginSeasonId}
      seasons={this.props.seasons}
      initialTvSeries={this.props.clip.partOfSeries}
      required
      validationErrors={{
        tvSeriesId: this.props.validationErrors.contentOriginTVSeriesId,
        seasonId: this.props.validationErrors.contentOriginSeasonId,
      }}
    />
  );

  render() {
    return (
      <Panel header="Content Origin" bsStyle="primary" className="content-origin">
        <div>
          {this.renderContentOriginType('Digital Original', ORIGINAL_ORIGIN_TYPE)}
          {this.renderContentOriginType('Fair Dealing', FAIR_DEALING_ORIGIN_TYPE)}
          {this.renderContentOriginType('3rd Party', THIRD_PARTY_ORIGIN_TYPE)}
          {this.renderContentOriginType('TV', TV_ORIGIN_TYPE)}
        </div>
        {this.props.clip.contentOriginType === THIRD_PARTY_ORIGIN_TYPE && this.renderThirdPartyOptions()}
        {this.props.clip.contentOriginType === TV_ORIGIN_TYPE && this.renderTVSeriesSeasonSelect()}
      </Panel>
    );
  }
}

ContentOriginPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  clip: PropTypes.object.isRequired,
  thirdPartyProviders: PropTypes.array.isRequired,
  validationErrors: PropTypes.object,
  getThirdPartyProviders: PropTypes.func.isRequired,
  getSeasons: PropTypes.func.isRequired,
  seasons: PropTypes.array.isRequired,
};

export default ContentOriginPanel;
