import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import json2csv from 'json2csv';
import { exportCatalogFile } from 'src/scripts/lib/downloadFile';
import { COLLECTIONS_FIELDS } from 'src/scripts/lib/exportCatalogFields';

export class ExportCollectionCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    };
  }

  exportCatalog = () => {
    this.setState({ isExporting: true });
    const result = this.props.collections;
    const csv = json2csv.parse(result, {
      fields: COLLECTIONS_FIELDS,
      unwind: 'tvSeries',
      withBOM: true,
      header: true,
    });
    exportCatalogFile(csv, 'collection');
    this.setState({ isExporting: false });
  };

  render() {
    return (
      <Button
        bsSize="small"
        className="export-btn btn-sm btn btn-default"
        disabled={this.state.isExporting || !this.props.collections}
        onClick={this.exportCatalog}
      >
        {this.state.isExporting ? 'Export in Progress' : 'Export catalog'}
      </Button>
    );
  }
}

ExportCollectionCatalog.propTypes = {
  collections: PropTypes.array,
};

export default ExportCollectionCatalog;
