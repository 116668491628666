import imageUploadItem from 'src/scripts/reducers/imageUploadItem';
import { UPDATE_SELECTED_IMAGE } from 'src/scripts/actions/multiImageUpload';
import { RESET_FORM } from 'src/scripts/actions/form';

export const initialState = {};

function updateImage(currentImage, newImageData) {
  return {
    ...currentImage,
    ...newImageData,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM:
      return {
        ...initialState,
      };
    case UPDATE_SELECTED_IMAGE:
      return {
        ...state,
        [action.imageUploadRef]: updateImage(state[action.imageUploadRef], action.data),
      };
    default:
  }
  const { imageUploadRef, imageUploadType, ...rest } = action;
  if (imageUploadRef && imageUploadType === 'MULTI_FILE_UPLOAD') {
    const uploadImageData = imageUploadItem(state[imageUploadRef], rest);
    return {
      ...state,
      [imageUploadRef]: updateImage(state[action.imageUploadRef], uploadImageData),
    };
  }
  return state;
}
