import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './View.small.scss';

export class View extends Component {
  render = () => (
    <div>
      <label
        className={'__bricks-toggle-switch'}
        data-pw={`input-switch-${this.props.checked ? 'on' : 'off'}`}
      >
        <input
          type="checkbox"
          checked={this.props.checked}
          disabled={this.props.disabled}
          onChange={this.props.onSwitch}
        />
        <span className={'__bricks-toggle-slider'} />
      </label>
    </div>
  );
}

View.defaultProps = {
  disabled: false,
};

View.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onSwitch: PropTypes.func.isRequired,
};
