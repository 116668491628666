import React from 'react';
import PropTypes from 'prop-types';
import * as dateFormatter from 'src/scripts/lib/dateFormatter';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

const LicensePeriodPanel = (fastChannelLicensingStartDate, fastChannelLicensingEndDate) => {
  const startDate =
    (fastChannelLicensingStartDate && dateFormatter.format(fastChannelLicensingStartDate)) || '';
  const endDate = (fastChannelLicensingEndDate && dateFormatter.format(fastChannelLicensingEndDate)) || '';
  return (
    <Panel header="License Period">
      <DetailsRowItem label="Start Date/Time">
        <FormatText>{startDate}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="End Date/Time">
        <FormatText>{endDate}</FormatText>
      </DetailsRowItem>
    </Panel>
  );
};

LicensePeriodPanel.propTypes = {
  fastChannelLicensingEndDate: PropTypes.string,
  fastChannelLicensingStartDate: PropTypes.string,
};

export default LicensePeriodPanel;
