import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Autocomplete from 'src/scripts/components/Autocomplete';
import Tag from 'src/scripts/components/Tag';
import { keys } from 'src/scripts/lib/validation';
import { getTags, clearTags } from 'src/scripts/actions/tag';

export class TagList extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.onPaste = this.onPaste.bind(this);
  }

  componentDidMount() {
    this.props.clearTags();
  }

  onSuggestionSelected(tagEntered) {
    this.addTags([tagEntered]);
  }

  isTagValid(tag) {
    if (!tag || _.includes(this.props.tags, tag)) {
      return false;
    }
    return true;
  }

  onPaste(event) {
    const pastedString = event.clipboardData.getData('Text');
    const formattedString = pastedString.replace(/;/g, ',');
    const tags = formattedString.split(',');

    this.addTags(tags);
    event.preventDefault();
  }

  getSuggestions(input) {
    if (!input) {
      this.props.clearTags();
    } else {
      this.props.getTags(input, this.props.resourceGroup, this.props.includeTagCount);
    }
  }

  addTags(tags) {
    const validTags = [];
    tags.forEach((tag) => {
      tag = tag.trim();
      if (this.isTagValid(tag)) {
        validTags.push(tag);
      }
    });

    if (validTags.length === 0) {
      return;
    }

    const updatedTags = this.props.tags.slice();
    updatedTags.push(...validTags);

    this.clearInput();
    this.props.onChange(updatedTags);
  }

  removeTag(index) {
    const updatedTags = this.props.tags.slice();
    updatedTags.splice(index, 1);
    this.props.onChange(updatedTags);
  }

  handleKeyPress(event) {
    const key = event.which;
    const character = String.fromCharCode(key);
    const tagEntered = event.target.value;

    if (character === ',' || character === ';' || key === keys.ENTER) {
      this.addTags([tagEntered]);
      event.preventDefault();
    }
  }

  clearInput() {
    ReactDom.findDOMNode(this.refs.tagInput.refs.input).value = '';
    this.props.clearTags();
  }

  render() {
    const inputAttributes = {
      id: 'text-tags',
      maxLength: 250,
      onKeyPress: this.handleKeyPress,
      onPaste: this.onPaste,
      type: 'text',
    };
    return (
      <div className="tag-input-list form-group">
        <label className="control-label">Tags</label>
        <div className="tag-list">
          {this.props.tags.map((tag, index) => {
            return <Tag key={index} name={tag} onDismiss={this.removeTag.bind(null, index)} />;
          })}
          <Autocomplete
            ref="tagInput"
            suggestions={this.props.suggestions.map((tagsObj) => ({
              value: tagsObj.name,
              count: tagsObj.tagCount,
            }))}
            loadSuggestions={this.getSuggestions}
            inputAttributes={inputAttributes}
            onSuggestionSelected={this.onSuggestionSelected}
            loading={this.props.loading}
          />
        </div>
      </div>
    );
  }
}

TagList.propTypes = {
  loading: PropTypes.bool,
  tags: PropTypes.array,
  onChange: PropTypes.func,
  clearTags: PropTypes.func,
  getTags: PropTypes.func,
  suggestions: PropTypes.array,
  resourceGroup: PropTypes.string,
  includeTagCount: PropTypes.bool,
};

TagList.defaultProps = {
  tags: [],
  includeTagCount: false,
};

function mapStateToProps(state) {
  return {
    suggestions: state.tags.tags,
    loading: state.tags.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTags: (input, resourceGroup, includeTagCount) =>
      dispatch(getTags(input, resourceGroup, includeTagCount)),
    clearTags: () => dispatch(clearTags()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TagList);
