import moment from 'moment';
import { TRANSCODE_VIDEOCLOUD_SUCCEEDED } from 'src/scripts/actions/video';
import {
  BULK_ACTION_NETWORK_CLIP_UPDATED,
  BULK_ACTION_NETWORK_CLIP_DELETED,
} from 'src/scripts/actions/bulkAction';
import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import * as videoVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import { doBulkAction } from 'src/scripts/actions/bulkAction';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { NETWORK_CLIP } from 'src/scripts/lib/modelNames';

function expireNetworkClip(networkClipId, onSuccess, onError) {
  return networkVmsApi
    .updateNetworkClip({ id: networkClipId, expiry: moment().format() })
    .then(onSuccess, onError);
}

export function expireNetworkClips(networkClipIds) {
  return doBulkAction(
    networkClipIds,
    expireNetworkClip,
    'expire',
    NETWORK_CLIP,
    BULK_ACTION_NETWORK_CLIP_UPDATED
  );
}

function deleteNetworkClip(networkClipId, onSuccess, onError) {
  return networkVmsApi
    .deleteNetworkClip(networkClipId)
    .then(onSuccess.bind(null, { id: networkClipId }), onError);
}

export function deleteNetworkClips(networkClipIds) {
  return doBulkAction(
    networkClipIds,
    deleteNetworkClip,
    'delete',
    NETWORK_CLIP,
    BULK_ACTION_NETWORK_CLIP_DELETED
  );
}

function transcodeNetworkClip(videoId, onSuccess, onError) {
  return videoVmsApi
    .transcodeBrightcoveVideoCloud(VIDEOS.NETWORK, videoId)
    .then(onSuccess.bind(null, { id: videoId }), onError);
}

export function transcodeNetworkClips(videoIds) {
  return doBulkAction(
    videoIds,
    transcodeNetworkClip,
    'transcode',
    NETWORK_CLIP,
    TRANSCODE_VIDEOCLOUD_SUCCEEDED
  );
}
