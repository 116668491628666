import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';

class ArchiveModal extends React.Component {
  getWarning() {
    let warning =
      'Clicking Archive will remove this entry and require re-ingestion to republish. Are you sure you wish to proceed?';
    if (!this.isExpiredMediaItem()) {
      warning =
        'The video you are archiving has not expired. Archiving will de-activate the video and require re-ingestion to republish. Are you sure you wish to proceed?';
    }
    return warning;
  }

  isExpiredMediaItem() {
    return this.props.mediaItem.expiry !== null && moment(this.props.mediaItem.expiry).isBefore(moment());
  }

  render() {
    return (
      <ModalItem
        title="Archive"
        component={this.props.isButton ? 'span' : ''}
        className={this.props.isButton ? 'btn btn-default' : ''}
        form={
          <ConfirmationDialog
            invitation={this.getWarning()}
            ok={this.props.action}
            buttonClass="danger"
            buttonLabel="Archive"
          />
        }
      />
    );
  }
}

ArchiveModal.propTypes = {
  mediaItem: PropTypes.object,
  action: PropTypes.func,
  isButton: PropTypes.bool,
};

export default ArchiveModal;
