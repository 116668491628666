import { isEqual } from 'lodash';

export const collectionStatuses = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const collectionStatusColours = {
  [collectionStatuses.ACTIVE]: '#2dba2d',
  [collectionStatuses.INACTIVE]: 'red',
};

export const getCollectionStatus = (collection) => {
  if (collection.type === 'DEFAULT' || collection.type === 'GENRE') {
    return collectionStatuses.ACTIVE;
  }

  return collection.activeRecommendingTvSeriesCount > 0
    ? collectionStatuses.ACTIVE
    : collectionStatuses.INACTIVE;
};

export const workflows = {
  CHANGED: 'CHANGED',
  READY_FOR_REVIEW: 'READY FOR REVIEW',
  IN_USE: 'IN USE',
  ARCHIVED: 'ARCHIVED',
};

export const filterableWorkflows = {
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  IN_USE: 'IN_USE',
  ARCHIVED: 'ARCHIVED',
};
export const workflowColours = {
  [workflows.CHANGED]: '#18b9ff',
  [workflows.READY_FOR_REVIEW]: '#ffa000',
  [workflows.IN_USE]: '#2dba2d',
  [workflows.ARCHIVED]: 'darkgrey',
};

export function hasUnpublishedTvSeriesListChanges(collection) {
  const publishedSeriesIds = collection.tvSeries.map((series) => series.id);
  const draftSeriesIds = collection.draftTvSeries.map((series) => series.id);
  return !isEqual(publishedSeriesIds, draftSeriesIds);
}

function isCollectionArchived(collection) {
  const publishedSeriesIds = collection.tvSeries.map((series) => series.id);
  const draftSeriesIds = collection.draftTvSeries.map((series) => series.id);
  return publishedSeriesIds.length === 0 && draftSeriesIds.length === 0;
}

export function getCollectionWorkflow(collection, hasUnsavedTvSeriesListChanges = false) {
  if (isCollectionArchived(collection)) {
    return workflows.ARCHIVED;
  }

  if (hasUnpublishedTvSeriesListChanges(collection)) {
    if (hasUnsavedTvSeriesListChanges) {
      return workflows.CHANGED;
    }

    return workflows.READY_FOR_REVIEW;
  }

  return workflows.IN_USE;
}
