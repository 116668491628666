import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { deleteSeasonAndCloseConfirmation } from 'src/scripts/actions/season';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import SeasonFormLoader from 'src/scripts/components/Season/SeasonFormLoader';
import { TV_PATH } from 'src/scripts/lib/accessController';
import history from 'src/scripts/lib/history';

const DetailsToolbar = (props) => {
  const fullWidthColumn = 12;

  return (
    <Row className="details-toolbar">
      <Col md={fullWidthColumn}>
        <ButtonToolbar className="pull-right toolbar-buttons">
          <ModalItem
            className="btn btn-default"
            component="span"
            title="Edit Season"
            form={<SeasonFormLoader seasonId={props.season.id} />}
          />
          <ModalItem
            className="btn btn-default"
            component="spn"
            title="Delete Season"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${props.season.name}" season. Are you sure you wish to proceed?`}
                ok={() => {
                  props.deleteSeasonAndCloseConfirmation(props.season.id, () =>
                    history.push(
                      {
                        state: null,
                        pathname: `/${TV_PATH}/tv-series/${props.season.partOfSeries.id}/seasons`,
                      },
                      {
                        query: { page: 1 },
                      }
                    )
                  );
                }}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        </ButtonToolbar>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteSeasonAndCloseConfirmation: (seasonId, successCallback) =>
    dispatch(deleteSeasonAndCloseConfirmation(seasonId, successCallback)),
});

export default connect(() => ({}), mapDispatchToProps)(DetailsToolbar);

DetailsToolbar.propTypes = {
  season: PropTypes.object,
  deleteSeasonAndCloseConfirmation: PropTypes.func,
};
