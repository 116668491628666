import AuthenticationContext from 'adal-angular';
import environment from 'config/environment';
let auth = null;

if (environment) {
  auth = new AuthenticationContext({
    tenant: environment.authorization.tenant,
    clientId: environment.authorization.azureClientId,
    instance: environment.authorization.instance || '',
    redirectUri: environment.endpoints.vmsClientUrl,
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: environment.endpoints.vmsClientUrl,
  });
}

const authenticationContext = auth;
export default authenticationContext;

export function getAuthenticationContext() {
  return auth;
}
