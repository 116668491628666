import _ from 'lodash';
import React from 'react';
import NavDropdownMenu from '../../NavDropdownMenu';
import { Input } from 'react-bootstrap';
import { humanizeString } from 'src/scripts/lib/stringFormatter';
import PropTypes from 'prop-types';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectAllCheckboxChange = this.onSelectAllCheckboxChange.bind(this);
  }

  onCheckboxFilterChange(option, event) {
    const values = (this.props.checkboxFilterValues || []).slice();
    if (!event.target.checked) {
      _.pull(values, option);
    } else {
      values.push(option);
    }

    this.props.applyFilter(values.length > 0 ? `in:${values.join(',')}` : undefined);
  }

  isDropdownOptionChecked(option) {
    if (!this.props.isFilterActive || this.props.checkboxFilterValues === null) {
      return false;
    }

    return _.includes(this.props.checkboxFilterValues, option);
  }

  areAllOptionsSelected() {
    if (!this.props.isFilterActive || this.props.checkboxFilterValues === null) {
      return false;
    }

    return this.props.checkboxFilterValues.length === this.props.filter.options.length;
  }

  onSelectAllCheckboxChange(event) {
    const checked = event.target.checked;
    const values = [];
    if (checked) {
      values.push(...this.props.filter.options);
    }
    this.props.applyFilter(values.length > 0 ? `in:${values.join(',')}` : undefined);
  }

  render() {
    return (
      <NavDropdownMenu
        className="checkbox-dropdown"
        buttonClass="btn btn-default btn-sm"
        eventKey={1}
        bsStyle="info"
        component="span"
        iconClass={this.props.isFilterActive ? 'fa-check icon-dropdown__active' : 'fa-filter'}
      >
        <Input
          className="select-all-options-checkbox"
          type="checkbox"
          label="(Select All)"
          checked={this.areAllOptionsSelected()}
          onChange={this.onSelectAllCheckboxChange}
        />
        {this.props.filter.options.map((option, index) => {
          return (
            <Input
              type="checkbox"
              label={humanizeString(option)}
              key={index}
              checked={this.isDropdownOptionChecked(option)}
              onChange={this.onCheckboxFilterChange.bind(this, option)}
            />
          );
        })}
      </NavDropdownMenu>
    );
  }
}

Dropdown.propTypes = {
  applyFilter: PropTypes.func,
  isFilterActive: PropTypes.bool,
  checkboxFilterValues: PropTypes.array,
  filter: PropTypes.any,
};

export default Dropdown;
