import { required } from 'src/scripts/lib/formValidation/generalValidators';

const validationRules = [
  { field: 'name', label: 'Genre Name', customValidators: required() },
  { field: 'description', label: 'Genre Description' },
  { field: 'seoTitle', label: 'Seo Title', customValidators: required() },
  { field: 'seoDescription', label: 'Seo Description', customValidators: required() },
];

const getValidationRules = () => validationRules;
export default getValidationRules;
