import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import NetworkClipForm from 'src/scripts/components/NetworkClip/internal/NetworkClipForm';
import { getNetworkClipFormInitialData } from 'src/scripts/actions/networkClip';

export class NetworkClipFormLoader extends React.Component {
  componentDidMount() {
    this.props.getNetworkClipFormInitialData(this.props.clipId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <NetworkClipForm isReschedule={this.props.isReschedule} close={this.props.close} />
      </ViewLoader>
    );
  }
}

NetworkClipFormLoader.propTypes = {
  clipId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getNetworkClipFormInitialData: PropTypes.func,
  isReschedule: PropTypes.bool, // This should be remove when we have proper video states and a save method should be passed
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const clipForm = state.networkClipForm;
  return {
    initialFormDataLoaded: clipForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNetworkClipFormInitialData: (clipId) => dispatch(getNetworkClipFormInitialData(clipId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClipFormLoader);
