import moment from 'moment';
import {
  BULK_ACTION_EPISODE_UPDATED,
  BULK_ACTION_EPISODE_DELETED,
  BULK_ACTION_EPISODE_DOWNLOADED,
  BULK_ACTION_TYPE_DOWNLOAD,
} from 'src/scripts/actions/bulkAction';
import { TRANSCODE_VIDEOCLOUD_SUCCEEDED } from 'src/scripts/actions/video';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videoVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import { doBulkAction } from 'src/scripts/actions/bulkAction';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { EPISODE } from 'src/scripts/lib/modelNames';
import { downloadStreamFromUrl } from 'src/scripts/lib/downloadFile';

function expireEpisode(episodeId, onSuccess, onError) {
  return tvVmsApi.updateEpisode({ id: episodeId, expiry: moment().format() }).then(onSuccess, onError);
}

export function expireEpisodes(episodeIds) {
  return doBulkAction(episodeIds, expireEpisode, 'expire', EPISODE, BULK_ACTION_EPISODE_UPDATED);
}

function getEditEpisodeFn(episodeData) {
  return (episodeId, onSuccess, onError) => {
    return tvVmsApi.updateEpisode({ id: episodeId, ...episodeData }).then(onSuccess, onError);
  };
}

export function editEpisodeData(episodeIds, episodeData) {
  return doBulkAction(
    episodeIds,
    getEditEpisodeFn(episodeData),
    'edit',
    EPISODE,
    BULK_ACTION_EPISODE_UPDATED
  );
}

function getRescheduleAndSaveEpisodeFn(episodeData) {
  return (episodeId, onSuccess, onError) => {
    return tvVmsApi.rescheduleEpisode({ id: episodeId, ...episodeData }).then(onSuccess, onError);
  };
}

export function rescheduleAndSaveEpisodes(episodeIds, episodeData) {
  return doBulkAction(
    episodeIds,
    getRescheduleAndSaveEpisodeFn(episodeData),
    'reschedule',
    EPISODE,
    BULK_ACTION_EPISODE_UPDATED
  );
}

function deleteEpisode(episodeId, onSuccess, onError) {
  return tvVmsApi.deleteEpisode(episodeId).then(onSuccess.bind(null, { id: episodeId }), onError);
}

export function deleteEpisodes(episodeIds) {
  return doBulkAction(episodeIds, deleteEpisode, 'delete', EPISODE, BULK_ACTION_EPISODE_DELETED);
}

function downloadEpisode(videoId, onSuccess, onError) {
  return videoVmsApi
    .getSignedVideoSourceUrl(VIDEOS.TV, videoId)
    .then((response) => downloadStreamFromUrl(response.url, null))
    .then(onSuccess.bind(null, { id: videoId }), onError);
}

export function downloadEpisodes(videoIds) {
  return doBulkAction(
    videoIds,
    downloadEpisode,
    BULK_ACTION_TYPE_DOWNLOAD,
    EPISODE,
    BULK_ACTION_EPISODE_DOWNLOADED
  );
}

function transcodeEpisode(videoId, onSuccess, onError) {
  return videoVmsApi
    .transcodeBrightcoveVideoCloud(VIDEOS.TV, videoId)
    .then(onSuccess.bind(null, { id: videoId }), onError);
}

export function transcodeEpisodes(videoIds) {
  return doBulkAction(videoIds, transcodeEpisode, 'transcode', EPISODE, TRANSCODE_VIDEOCLOUD_SUCCEEDED);
}
