import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default class ActiveEpisodeState extends React.Component {
  getEpisodeStateClass = () => {
    const baseClassname = 'episodes-state';
    if (this.props.activeEpisodeCount === 0) {
      return `${baseClassname} contains-zero-current-active-episodes`;
    }
    if (!this.props.isFutureActive) {
      return `${baseClassname} contains-current-but-zero-future-active-episodes`;
    }
    return `${baseClassname} contains-current-and-future-active-episodes`;
  };

  getTooltipText = () => {
    if (this.props.activeEpisodeCount === 0) return 'Has zero active episodes';
    if (this.props.isFutureActive) return 'Will have at least 1 active episode for the next 6 days';
    return 'Has active episode(s) but will not have any active episodes in the next 6 days';
  };

  render() {
    const tooltip = <Tooltip id="tv-series-episode-state-tooltip">{this.getTooltipText()}</Tooltip>;

    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        <div className={`${this.getEpisodeStateClass()}`}>{this.props.activeEpisodeCount}</div>
      </OverlayTrigger>
    );
  }
}

ActiveEpisodeState.propTypes = {
  activeEpisodeCount: PropTypes.number,
  isFutureActive: PropTypes.bool,
};
