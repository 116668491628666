import _ from 'lodash';
import imageUploadItem, {
  initialState as imageUploadItemInitialState,
} from 'src/scripts/reducers/imageUploadItem';
import * as imageRefs from 'src/scripts/lib/imageRefs';
import { RESET_FORM } from 'src/scripts/actions/form';

export const initialState = {};
_.forEach(imageRefs, function (type) {
  initialState[type] = imageUploadItemInitialState;
});

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_FORM:
      return {
        ...initialState,
      };
    default:
  }
  const { imageUploadRef, imageUploadType, ...rest } = action;
  if (imageUploadRef && imageUploadType === 'SINGLE_FILE_UPLOAD') {
    return {
      ...state,
      [imageUploadRef]: imageUploadItem(state[imageUploadRef], rest),
    };
  }
  return state;
}
