import PropTypes from 'prop-types';
import React from 'react';

class NavDropdownMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick(evt) {
    const container = this.refs.container;
    if (!container.contains(evt.target)) {
      if (
        this.props.classToExcludeFromClose &&
        evt.target.className &&
        evt.target.className.includes(this.props.classToExcludeFromClose)
      ) {
        return;
      }
      this.setState({
        open: false,
      });
    }
  }

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const icon = this.props.iconClass ? <i className={`fa ${this.props.iconClass}`}></i> : '';
    const Component = this.props.component || 'li';
    const caret = () => {
      const defaultCaretClass = 'caret navDropdownMenu__dropdown-caret';
      if (this.props.displayCaret) {
        return <span className={defaultCaretClass} />;
      }
      return '';
    };
    const align = this.props.alignLeft ? 'dropdown-menu-left' : 'dropdown-menu-right';
    return (
      <Component
        ref="container"
        className={`dropdown ${this.props.className} ${this.state.open ? 'open' : ''}`}
      >
        <a
          disabled={this.props.disabled}
          className={`dropdown-toggle ${this.props.buttonClass}`}
          onClick={this.toggle}
          data-pw="dropdown-action-trigger"
        >
          {this.props.title || ''} {icon} {caret()}
        </a>
        <ul className={`dropdown-menu ${align}`} data-pw="dropdown-menu">
          {this.props.children}
        </ul>
      </Component>
    );
  }
}

NavDropdownMenu.propTypes = {
  buttonClass: PropTypes.string,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  component: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.node,
  displayCaret: PropTypes.bool,
  classToExcludeFromClose: PropTypes.string, // This feature is to solve an issue with the date time component closing the dropdown menu when clicking on time selector in ItemListDateHeader
  alignLeft: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NavDropdownMenu;
