import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Col, Row, ListGroupItem, ButtonToolbar } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { getContentOrDashes } from 'src/scripts/lib/stringFormatter';
import ModalItem from '../../ModalItem';
import LiveEventGroupFormLoader from '../LiveEventGroupFormLoader';
import ConfirmableActionButton from '../../ConfirmableActionButton';
import liveEventGroupImageTypeData from './../../../lib/liveEventGroupImageTypes';

export default class LiveEventGroupDetails extends React.Component {
  renderBreadcrumb = () => (
    <ol className="mi9-breadcrumb breadcrumb">
      <li>
        <a href={'#/live/tv/event-groups'}>TV Live Event Groups</a>
      </li>
      <li>{this.props.liveEventGroup.name}</li>
    </ol>
  );

  hasAssociatedLiveEvents = () => this.props.liveEventGroup.liveEvents.length > 0;

  renderDeleteButton() {
    if (this.hasAssociatedLiveEvents()) {
      return null;
    }

    return (
      <ConfirmableActionButton
        buttonLabel="Delete Live Event Group"
        buttonClass={'stopStreamModalItem workflow-menu-item btn btn-danger'}
        confirmButtonLabel={`Delete ${this.props.liveEventGroup.name}`}
        confirmationText={`Clicking delete will permanently delete ${this.props.liveEventGroup.name}. Are you sure you want to continue?`}
        action={this.props.onDeletingSuccess}
      />
    );
  }

  renderEditButton = () => (
    <ModalItem
      title="Edit Live Event Group"
      className={'editModalItem workflow-menu-item btn btn-default'}
      component={'span'}
      form={
        <LiveEventGroupFormLoader
          liveEventGroup={this.props.liveEventGroup}
          onSuccess={this.props.onEditingSuccess}
        />
      }
    />
  );

  renderToolbar = () => (
    <Row className={'details-toolbar'}>
      <ButtonToolbar className={'pull-right toolbar-buttons'}>
        {this.renderDeleteButton()}
        {this.renderEditButton()}
      </ButtonToolbar>
    </Row>
  );

  renderImagePreviews = () => {
    const images = this.props.liveEventGroup.images;
    return (
      <div>
        {images.map((imageData) => {
          return (
            <Panel header={liveEventGroupImageTypeData[imageData.type].header}>
              <div className={liveEventGroupImageTypeData[imageData.type].wrapperClassName}>
                <img
                  alt={imageData.alt ? imageData.alt : 'LiveEventGroup Fixture'}
                  className="live-event-group-image"
                  src={imageData.url}
                />
              </div>
            </Panel>
          );
        })}
      </div>
    );
  };

  renderMetadataPanel = () => (
    <Panel className="details-section-panel metadata-panel" header="Metadata">
      <DetailsRowItem label="Name">{this.props.liveEventGroup.name}</DetailsRowItem>
      <DetailsRowItem label="Subtitle">{this.props.liveEventGroup.subtitle}</DetailsRowItem>
      <DetailsRowItem label="Position">{this.props.liveEventGroup.position}</DetailsRowItem>
      <DetailsRowItem label="CTV Position">{this.props.liveEventGroup.ctvPosition}</DetailsRowItem>
      <DetailsRowItem label="Highlight Colour">{this.props.liveEventGroup.highlightColour}</DetailsRowItem>
      <DetailsRowItem label="Description">
        {getContentOrDashes(this.props.liveEventGroup.description)}
      </DetailsRowItem>
      <DetailsRowItem label="Seo Page Title">
        {getContentOrDashes(this.props.liveEventGroup.seoPageTitle)}
      </DetailsRowItem>
      <DetailsRowItem label="Seo Description">
        {getContentOrDashes(this.props.liveEventGroup.seoDescription)}
      </DetailsRowItem>
    </Panel>
  );

  renderNotesPanel = () => {
    return (
      <Panel className="details-section-panel notes-panel" header="Notes">
        <DetailsRowItem id="producer-notes" label="Producer Notes">
          {getContentOrDashes(this.props.liveEventGroup.producerNotes)}
        </DetailsRowItem>
      </Panel>
    );
  };

  renderAssociatedLiveEventsPanel() {
    if (!this.hasAssociatedLiveEvents()) {
      return (
        <Panel header={'Associated Live Events'}>
          <i>No associated live events</i>
        </Panel>
      );
    }

    return (
      <Panel header={'Associated Live Events'}>
        {this.props.liveEventGroup.liveEvents.map((liveEvent) => (
          <ListGroupItem key={liveEvent.id}>{liveEvent.name}</ListGroupItem>
        ))}
      </Panel>
    );
  }

  render = () => (
    <div>
      {this.renderBreadcrumb()}
      {this.renderToolbar()}
      <Row>
        <Col md={6}>
          {this.renderMetadataPanel()}
          {this.renderNotesPanel()}
        </Col>
        <Col md={6}>
          {this.renderAssociatedLiveEventsPanel()}
          {this.renderImagePreviews()}
        </Col>
      </Row>
    </div>
  );
}

LiveEventGroupDetails.propTypes = {
  liveEventGroup: PropTypes.object.isRequired,
  onEditingSuccess: PropTypes.func.isRequired,
  onDeletingSuccess: PropTypes.func.isRequired,
};
