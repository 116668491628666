import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default class ProductionCompanySelect extends React.Component {
  render() {
    return (
      <div className="form-group">
        <Select
          multi={false}
          clearable
          searchable
          name="tvSeries-field-name"
          simpleValue
          value={this.props.selected}
          options={this.props.productionCompanies.map((productionCompany) => ({
            label: productionCompany,
            value: productionCompany,
          }))}
          onChange={(value) => this.props.onChange(value)}
          ref="selectProductionCompany"
          id="select-production-company"
          valueKey="value"
          labelKey="label"
        />
      </div>
    );
  }
}

ProductionCompanySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  productionCompanies: PropTypes.array,
  selected: PropTypes.string,
};
