import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class ImageDropZone extends React.Component {
  constructor(props) {
    super(props);

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      enterCounter: 0,
    };
  }

  onDragEnter(e) {
    e.preventDefault();
    this.setState({
      enterCounter: this.state.enterCounter + 1,
    });
  }

  onDragLeave(e) {
    e.preventDefault();
    this.setState({
      enterCounter: this.state.enterCounter - 1,
    });
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    e.preventDefault();
    this.setState({
      enterCounter: 0,
    });

    const files = e.dataTransfer.files;
    if (files.length === 1 && _.startsWith(files[0].type, 'image')) {
      this.props.onDrop(files[0]);
    }
  }

  render() {
    return (
      <div className="image-drop-zone-container">
        <div
          ref="imageDropZone"
          className={`image-drop-zone ${this.state.enterCounter > 0 ? 'dragging' : ''}`}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        ></div>
        <div className={`message ${this.props.imageSelected ? 'hide' : ''}`}>
          Drag and drop over image to replace
        </div>
      </div>
    );
  }
}

ImageDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  imageSelected: PropTypes.bool,
};

export default ImageDropZone;
