import PropTypes from 'prop-types';
import React from 'react';

export class ContextNavigation extends React.Component {
  render() {
    return (
      <div className="mi9-breadcrumb clearfix">
        <ol className="breadcrumb mi9-breadcrumb__list">
          {this.props.breadcrumbList.map((breadcrumb, key) => {
            return (
              <li className="breadcrumbItem" key={key}>
                {breadcrumb}
              </li>
            );
          })}
        </ol>
        <div className="rightComponents pull-right">
          {this.props.rightComponentList
            ? this.props.rightComponentList.map((rightComponent) => {
                return rightComponent;
              })
            : ''}
        </div>
      </div>
    );
  }
}

ContextNavigation.propTypes = {
  breadcrumbList: PropTypes.array,
  rightComponentList: PropTypes.array,
};

export default ContextNavigation;
