import PropTypes from 'prop-types';
import React from 'react';
import { Input, Button, Panel, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import SortableDragDropList from 'src/scripts/components/SortableDragDropList';
import LiveEventsField from '../internal/LiveEventsField';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import * as util from 'src/scripts/lib/util';
import MultiImageUpload from '../../../components/MultiImageUpload';
import { IMAGES } from '../../../lib/resourceGroups';
import {
  LIVE_EVENT_GROUP_IMAGE,
  LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
  LIVE_EVENT_GROUP_LOGO,
  LIVE_EVENT_GROUP_SWITCHER_LOGO,
} from '../../../lib/imageTypes';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  calculateLiveEventStatus,
  liveEventStatus,
  liveEventStatusColours,
} from '../../../lib/liveEventStatus';

export class LiveEventGroupForm extends React.Component {
  imageTypesOptions = [
    LIVE_EVENT_GROUP_IMAGE,
    LIVE_EVENT_GROUP_BACKGROUND_IMAGE,
    LIVE_EVENT_GROUP_LOGO,
    LIVE_EVENT_GROUP_SWITCHER_LOGO,
  ];

  popOverText = 'Check the box to display this Group like a Channel in the Channel Switcher';

  captureSortableDragDropListRef = (ref) => {
    this.SortableDragDropListRef = ref;
  };

  collectLiveEventsFieldRefs = (liveEventId, ref) => {
    if (!this.liveEventRefs) {
      this.liveEventRefs = {};
    }
    this.liveEventRefs[liveEventId] = ref;
  };

  moveLiveEventItem = (sourceIdx, targetIdx) => {
    const updatedLiveEventList = util.moveItemInList(
      this.props.liveEventGroup.liveEvents,
      sourceIdx,
      targetIdx
    );
    this.props.onPropertyChange('liveEvents')(updatedLiveEventList);
  };

  getImagesWithTypes = (images, types) => {
    return images ? images.filter((image) => types.includes(image.type)) : [];
  };

  isSubmitDisabled() {
    const imagesUploading = _.pickBy(this.props.multiImageUpload, (image) => image.uploading);
    const areImagesUploading = imagesUploading ? _.keys(imagesUploading).length > 0 : false;

    return areImagesUploading;
  }

  moveToTop = (index) => {
    if (index === 0) return;
    this.moveLiveEventItem(index, 0);
  };

  moveToBottom = (index) => {
    if (index === this.props.liveEventGroup.liveEvents.length - 1) return;
    this.moveLiveEventItem(index, this.props.liveEventGroup.liveEvents.length - 1);
  };

  handleDragDropCardRender = (index, card) => (
    <LiveEventsField
      domRef={(ref) => this.collectLiveEventsFieldRefs(card.id, ref)}
      className={''}
      name={card.name}
      availabilityStartDate={card.availabilityStartDate}
      status={calculateLiveEventStatus(card)}
      index={index}
      moveToTop={this.moveToTop}
      moveToBottom={this.moveToBottom}
      liveEventsLength={this.props.liveEventGroup.liveEvents.length}
    />
  );

  renderName = () => (
    <Input
      labelClassName="required"
      type="text"
      label="Live Event Group Name"
      placeholder="Enter name"
      ref="liveEventGroupName"
      maxLength="200"
      onChange={this.props.onPropertyChange('name')}
      value={this.props.liveEventGroup.name}
      bsStyle={this.props.validationErrors.name ? 'error' : null}
      data-pw="live-event-group-name"
    />
  );

  renderSubtitle = () => (
    <Input
      labelClassName={'required'}
      type="textarea"
      label="Live Event Group Subtitle"
      placeholder="Add a subtitle of the live event group"
      ref="liveEventGroupSubtitle"
      maxLength="500"
      onChange={this.props.onPropertyChange('subtitle')}
      value={this.props.liveEventGroup.subtitle}
      bsStyle={this.props.validationErrors.subtitle ? 'error' : null}
      data-pw="live-event-group-subtitle"
    />
  );

  renderPosition = () => (
    <Input
      labelClassName="required"
      label="Position"
      type="number"
      min="1"
      placeholder="Enter a positive digit"
      onChange={this.props.onPropertyChange('position')}
      value={this.props.liveEventGroup.position}
      bsStyle={this.props.validationErrors.position ? 'error' : null}
      data-pw="position"
    />
  );

  renderCtvPosition = () => (
    <div className="channel-group-container">
      <strong className="required">CTV Position </strong>
      <OverlayTrigger
        placement="right"
        overlay={
          <Popover>
            This position determines the order of Live Event Groups in the Channel Switcher on CTVs
          </Popover>
        }
      >
        <span className="circle-space">
          <i className="fa fa-info-circle"></i>
        </span>
      </OverlayTrigger>
      <Input
        labelClassName="required"
        type="number"
        min="1"
        placeholder="Add a position for the live event group on CTVs"
        onChange={this.props.onPropertyChange('ctvPosition')}
        value={this.props.liveEventGroup.ctvPosition}
        bsStyle={this.props.validationErrors.ctvPosition ? 'error' : null}
      />
    </div>
  );

  renderDescription = () => (
    <Input
      type="textarea"
      label="Description"
      placeholder="Add a description of the live event group"
      ref="liveEventGroupDescription"
      onChange={this.props.onPropertyChange('description')}
      value={this.props.liveEventGroup.description}
      bsStyle={this.props.validationErrors.description ? 'error' : null}
      data-pw="description"
    />
  );

  renderHighlightColour = () => (
    <div className="highlight-colour-container">
      <Input
        type="text"
        label="Highlight Colour"
        placeholder="Add a HEX colour code of the live event group"
        ref="liveEventGroupHighlightColour"
        onChange={this.props.onPropertyChange('highlightColour')}
        value={this.props.liveEventGroup.highlightColour}
        bsStyle={this.props.validationErrors.highlightColour ? 'error' : null}
        data-pw="highlightColour"
      />
      <span className="text-hint">Default Live Event Group HEX colour is #02C7E1</span>
    </div>
  );

  renderSeoPageTitle = () => (
    <Input
      type="text"
      label="Seo Page Title"
      placeholder="Add a seo page title of the live event group"
      ref="liveEventGroupSeoPageTitle"
      maxLength="200"
      onChange={this.props.onPropertyChange('seoPageTitle')}
      value={this.props.liveEventGroup.seoPageTitle}
      bsStyle={this.props.validationErrors.seoPageTitle ? 'error' : null}
      data-pw="seo-page-title"
    />
  );

  renderSeoDescription = () => (
    <Input
      type="textarea"
      label="Seo Description"
      placeholder="Add a seo description of the live event group"
      ref="liveEventGroupSeoDescription"
      onChange={this.props.onPropertyChange('seoDescription')}
      value={this.props.liveEventGroup.seoDescription}
      bsStyle={this.props.validationErrors.seoDescription ? 'error' : null}
      data-pw="seo-description"
    />
  );

  renderIsChannelGroupOption = () => {
    return (
      <div className="form-inline channel-group-container">
        <span>
          <Input
            type="checkbox"
            className="form-inline"
            onChange={(event) =>
              this.props.onPropertyChange('isChannelGroup')({ target: { value: event.target.checked } })
            }
            checked={this.props.liveEventGroup.isChannelGroup}
            data-pw="isChannelGroup"
          />
        </span>
        <strong>Channel Group </strong>
        <OverlayTrigger placement="right" overlay={<Popover>{this.popOverText}</Popover>}>
          <i className="fa fa-info-circle"></i>
        </OverlayTrigger>
      </div>
    );
  };

  renderImageUpload = () => {
    return (
      <MultiImageUpload
        resourceGroup={IMAGES.TV}
        imageTypes={this.imageTypesOptions}
        limit={this.imageTypesOptions.length}
        existingImages={this.getImagesWithTypes(this.props.liveEventGroup.images, this.imageTypesOptions)}
      />
    );
  };

  renderCloseButton = () => (
    <Button type="button" ref="closeButton" className="form__button" onClick={this.props.close}>
      Close
    </Button>
  );

  renderSaveButton = () => (
    <Button
      ref="submitButton"
      type="submit"
      className="form__button"
      bsStyle="primary"
      disabled={this.props.saving || this.isSubmitDisabled()}
      data-pw="video-modal-submit"
    >
      Save
    </Button>
  );

  renderEmptyLiveEventsAlert = () => (
    <Alert bsStyle="warning">There are currently no Live Events in this Live Event Group.</Alert>
  );

  renderStatus = () => {
    const statusButtons = [
      { status: liveEventStatus.CREATED, label: 'Created' },
      { status: liveEventStatus.PROMOTING, label: 'Promo' },
      { status: liveEventStatus.STREAMING, label: 'Live' },
      { status: liveEventStatus.STOPPED, label: 'Ended' },
    ];

    return (
      <div>
        {statusButtons.map(({ status, label }) => (
          <span
            key={status}
            className="live-event-status-button"
            style={{ backgroundColor: `${liveEventStatusColours[status]}` }}
          >
            {label}
          </span>
        ))}
      </div>
    );
  };

  renderDraggableLiveEvents = () => (
    <Panel header="Metadata" bsStyle="primary" data-pw="live-event-group-draggable-live-events">
      <div className="draggable-live-events-container">
        <div className="draggable-live-events-container">
          <label>Live Events List</label>
          <div className="circle-space">{this.renderStatus()}</div>
        </div>
        <span>Live Event Start Time</span>
      </div>
      {this.props.liveEventGroup.liveEvents && this.props.liveEventGroup.liveEvents.length ? (
        <SortableDragDropList
          domRef={this.captureSortableDragDropListRef}
          list={this.props.liveEventGroup.liveEvents}
          renderCard={this.handleDragDropCardRender}
          moveCard={this.moveLiveEventItem}
        />
      ) : (
        this.renderEmptyLiveEventsAlert()
      )}
    </Panel>
  );

  renderProducerNotes = () => (
    <Input
      id="producer-notes-input"
      type="textarea"
      label="Producer Notes"
      ref="producerNotes"
      onChange={this.props.onPropertyChange('producerNotes')}
      value={this.props.liveEventGroup.producerNotes}
      bsStyle={this.props.validationErrors.producerNotes ? 'error' : null}
    />
  );

  renderErrors = () => (
    <FormErrorMessage className="live-event-server-error-message" message={this.props.errorMessage} />
  );

  submitForm = (event) => {
    event.preventDefault();

    const uploadedImages = {
      ...this.props.multiImageUpload,
    };

    this.props.submitForm({ uploadedImages });
  };

  render = () => {
    return (
      <div>
        <form
          className="form"
          onSubmit={this.submitForm}
          ref="liveEventGroupForm"
          data-pw="tv-live-event-group-form"
        >
          {this.renderName()}
          {this.renderSubtitle()}
          {this.renderPosition()}
          {this.renderCtvPosition()}
          {this.renderDescription()}
          {this.renderHighlightColour()}
          {this.renderSeoPageTitle()}
          {this.renderSeoDescription()}
          {this.renderIsChannelGroupOption()}
          {this.renderImageUpload()}
          {this.props.liveEventGroup.id && this.renderDraggableLiveEvents()}
          {this.renderProducerNotes()}
          {this.props.errorMessage && this.renderErrors()}
          <div className="modal-footer">
            {this.renderCloseButton()}
            {this.renderSaveButton()}
          </div>
        </form>
      </div>
    );
  };
}

LiveEventGroupForm.propTypes = {
  liveEventGroup: PropTypes.object,
  validationErrors: PropTypes.object,
  errorMessage: PropTypes.string,
  saving: PropTypes.bool,
  close: PropTypes.func,
  onPropertyChange: PropTypes.func,
  submitForm: PropTypes.func,
  multiImageUpload: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    multiImageUpload: state.multiImageUpload,
  };
}

export default connect(mapStateToProps, null)(LiveEventGroupForm);
