import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import DragDropCard from './DragDropCard';

export class SortableDragDropList extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      })
    ),
    moveCard: PropTypes.func.isRequired,
    renderCard: PropTypes.func.isRequired,
    domRef: PropTypes.func,
  };

  render() {
    return (
      <div ref={this.props.domRef} className="sortable-drag-drop-list">
        {this.props.list.map((card, index) => (
          <DragDropCard
            renderCard={this.props.renderCard}
            key={card.id}
            id={card.id}
            index={index}
            card={card}
            moveCard={this.props.moveCard}
          />
        ))}
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(SortableDragDropList);
