import {
  GET_CATEGORIES_SUCCEEDED,
  GET_CATEGORIES_FAILED,
  DELETE_CATEGORY_SUCCEEDED,
  SAVE_CATEGORY_SUCCEEDED,
} from 'src/scripts/actions/category';
import { immutableRemoveById } from 'src/scripts/lib/util';

export const initialState = {
  categories: [],
  totalCount: 0,
  errorMessage: null,
  savedCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        categories: action.data.categories,
        totalCount: action.data.count,
        savedCategory: null,
      };

    case GET_CATEGORIES_FAILED:
      return {
        ...state,
        errorMessage: action.error,
        savedCategory: null,
      };

    case DELETE_CATEGORY_SUCCEEDED:
      return {
        ...state,
        categories: immutableRemoveById(state.categories, action.id),
      };

    case SAVE_CATEGORY_SUCCEEDED:
      return {
        ...state,
        savedCategory: action.data.category,
      };

    default:
      return state;
  }
}
