import { GET_ROLES_SUCCEEDED, GET_ROLES_FAILED, SAVE_ROLE_SUCCEEDED } from 'src/scripts/actions/role';

export const initialState = {
  roles: [],
  errorMessage: null,
  savedRole: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_SUCCEEDED:
      return { ...state, roles: action.data.roles, savedRole: null };

    case GET_ROLES_FAILED:
      return { ...state, errorMessage: action.error, savedRole: null };

    case SAVE_ROLE_SUCCEEDED:
      return { ...state, savedRole: action.data.role };

    default:
      return state;
  }
}
