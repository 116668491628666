import _map from 'lodash/map';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED,
  GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED,
  TV_SERIES_UPDATED,
  SAVE_TV_SERIES_FAILED,
  GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED,
  GET_CONTENT_SEGMENTS_SUCCEEDED,
  GET_PRODUCTION_COMPANIES_SUCCEEDED,
} from 'src/scripts/actions/tvSeries';
import { GET_GENRES_SUCCEEDED } from 'src/scripts/actions/genre';
import { GET_COLLECTIONS_SUCCEEDED } from 'src/scripts/actions/collection';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/tvSeriesForm';

export const initialState = {
  tvSeries: {
    contentSegment: 'Longtail',
    useSitecoreCmsSource: false,
    producerNotes: null,
  },
  genres: [],
  customCollections: [],
  contentSegments: [],
  productionCompanies: [],
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  initialFormDataLoaded: false,
};

function preProcessTvSeries(tvSeries) {
  if (tvSeries.secondaryGenres) {
    tvSeries.secondaryGenresIds = tvSeries.secondaryGenres.map((secondaryGenre) => secondaryGenre.id);
  }

  if (tvSeries.tags) {
    tvSeries.tags = _map(tvSeries.tags, 'name');
  }
  return tvSeries;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED:
      return {
        ...state,
        ...action.data,
        tvSeries: preProcessTvSeries(action.data.tvSeries),
      };

    case GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case GET_GENRES_SUCCEEDED:
      return {
        ...state,
        genres: action.data.genres,
      };

    case GET_COLLECTIONS_SUCCEEDED:
      return {
        ...state,
        customCollections: action.data.collections,
      };

    case GET_CONTENT_SEGMENTS_SUCCEEDED:
      return {
        ...state,
        contentSegments: action.data,
      };

    case GET_PRODUCTION_COMPANIES_SUCCEEDED:
      return {
        ...state,
        productionCompanies: action.data,
      };

    case TV_SERIES_UPDATED:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.tvSeries, ...action.tvSeries },
          getValidationRules()
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          tvSeries: {
            ...state.tvSeries,
            ...action.tvSeries,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        tvSeries: {
          ...state.tvSeries,
          ...action.tvSeries,
        },
      };

    case SAVE_TV_SERIES_FAILED:
      return { ...state, errorMessage: action.error };

    case GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED:
      return {
        ...state,
        ...action.data,
        initialFormDataLoaded: true,
      };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.tvSeries, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
