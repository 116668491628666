import PropTypes from 'prop-types';
import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import { isDeleteEnabled } from 'src/scripts/lib/ingestJobWorkflowActionsProvider';

export default class IngestJobDetailsToolbar extends React.Component {
  render() {
    if (isDeleteEnabled(this.props.ingestJob.state)) {
      return (
        <ButtonToolbar className="pull-right toolbar-buttons">
          <ModalItem
            ref="confirmationDialog"
            component="span"
            title="Delete Ingest Job"
            className="deleteModalItem workflow-menu-item btn btn-default"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${this.props.ingestJob.name}" ingest job and any associated video. Are you sure you wish to proceed?`}
                ok={this.props.deleteIngestJob.bind(this, this.props.ingestJob.id)}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        </ButtonToolbar>
      );
    }
    return null;
  }
}

IngestJobDetailsToolbar.propTypes = {
  ingestJob: PropTypes.object,
  deleteIngestJob: PropTypes.func,
};
