import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import LiveEventForm from 'src/scripts/components/LiveEvent/internal/LiveEventForm';
import { getLiveEventAndAssociatedData } from 'src/scripts/actions/liveEvent';

export class LiveEventFormLoader extends React.Component {
  componentDidMount() {
    this.props.getLiveEventAndAssociatedData(this.props.library, this.props.liveEventId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded && !this.props.isFetchError}>
        <LiveEventForm close={this.props.close} library={this.props.library} />
      </ViewLoader>
    );
  }
}

LiveEventFormLoader.propTypes = {
  library: PropTypes.string.isRequired,
  initialFormDataLoaded: PropTypes.bool,
  isFetchError: PropTypes.bool,
  liveEventId: PropTypes.number,
  getLiveEventAndAssociatedData: PropTypes.func,
  updateLiveEvent: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    initialFormDataLoaded: state.liveEventForm.initialFormDataLoaded,
    isFetchError: state.liveEventForm.isFetchError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLiveEventAndAssociatedData: (liveEventId, library) =>
      dispatch(getLiveEventAndAssociatedData(liveEventId, library)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveEventFormLoader);
