import moment from 'moment';
import { VALID_DATE_FORMATS } from 'src/scripts/lib/formValidation/constants';

export function format(date) {
  if (date) {
    return moment(date).format('lll');
  }
  return '-';
}

export const formatDateStringToISOStringOrNull = (dateString, dateFormats = VALID_DATE_FORMATS) => {
  const checkEmptyStringDate = (date) => typeof date === 'string' && date.trim() === '';
  if (checkEmptyStringDate(dateString)) return null;
  const momentDate = moment(dateString, dateFormats, true);
  if (momentDate.isValid()) return momentDate.toISOString();
  return null;
};

export const composeDateFilterString = (fromDate, toDate) => {
  let value = null;
  const fromDateString = formatDateStringToISOStringOrNull(fromDate);
  const toDateString = formatDateStringToISOStringOrNull(toDate);
  if (fromDateString && toDateString) {
    value = `between:${fromDateString},${toDateString}`;
  } else if (fromDateString) {
    value = `gt:${fromDate}`;
  } else if (toDateString) {
    value = `lt:${toDateString}`;
  }
  return value;
};
