import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import MultiInputList from 'src/scripts/components/MultiInputList';

class AdCuepointsPicker extends React.Component {
  getCuePoints = () => {
    const cuePoints = this.props.cuePoints.map(({ time }) => ({ value: time }));
    return this.props.mandatoryPreRoll ? this.setPreRollCuePointToMandatory(cuePoints) : cuePoints;
  };

  setPreRollCuePointToMandatory = (cuePoints) => {
    if (_.get(cuePoints, '[0].value') === '0:0:0') {
      cuePoints[0].editable = false;
    }
    return cuePoints;
  };

  validate(cuePoint) {
    return cuePoint.match(/^[\d:.]+$/);
  }

  render() {
    return (
      <MultiInputList
        validate={this.validate}
        maxItems={15}
        title="Advertising Break Locations"
        items={this.getCuePoints()}
        addButtonText="Add"
        id="multi-input-list-cue-points"
        emptyItemMessage="Enter hours:minutes:seconds.milliseconds"
        onChange={(items) => this.props.onChange(items.map(({ value }) => ({ time: value })))}
        disabled={this.props.disabled}
      />
    );
  }
}

AdCuepointsPicker.defaultProps = {
  cuePoints: [],
};

AdCuepointsPicker.propTypes = {
  mandatoryPreRoll: PropTypes.bool,
  cuePoints: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AdCuepointsPicker;
