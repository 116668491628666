import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'react-bootstrap';

export default class CmsSourceCheckboxx extends React.Component {
  render() {
    return (
      <Input
        type="checkbox"
        label="Sitecore"
        inline
        ref="useSitecoreCmsCheckboxInput"
        checked={this.props.selected}
        onClick={this.props.onChange}
        groupClassName="form-inline"
      />
    );
  }
}

CmsSourceCheckboxx.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};
