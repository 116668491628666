/* istanbul ignore next */
export function fileReader() {
  return new FileReader();
}
/* istanbul ignore next */
export function image() {
  return new Image();
}
/* istanbul ignore next */
export function getItem(key) {
  return JSON.parse(localStorage.getItem(key));
}
/* istanbul ignore next */
export function setItem(key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}
export function href(url) {
  if (url) {
    window.location.href = url;
  }
  return window.location.href;
}

export function hash(_hash) {
  if (_hash) {
    window.location.hash = _hash;
  }
  return window.location.hash;
}

export function addWindowBeforeUnloadHandler(handler) {
  window.addEventListener('beforeunload', handler);
}

export function removeWindowBeforeUnloadHandler(handler) {
  window.removeEventListener('beforeunload', handler);
}
