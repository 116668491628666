import _ from 'lodash';

export const FULL_ACCESS_ACTIONS = ['get', 'post', 'patch', 'put', 'delete'];
export const READ_ONLY_ACCESS_ACTIONS = ['get'];

export const TV_PATH = 'tv';
export const MEDIA_ROOM_PATH = 'media-room';
export const LIVE_PATH = 'live';
export const CHANNELS_PATH = 'channels';
export const DEVICES_PATH = 'devices';
export const STREAMS_PATH = 'streams';
export const MEDIA_LIVE_CHANNELS_PATH = 'media-live';
export const EVENTS_PATH = 'events';
export const EVENT_GROUPS_PATH = 'event-groups';
export const TV_LIVE_EVENTS_PATH = `${LIVE_PATH}/tv/${EVENTS_PATH}`;
export const TV_LIVE_EVENT_GROUPS_PATH = `${LIVE_PATH}/tv/${EVENT_GROUPS_PATH}`;
export const TV_LIVE_MEDIA_LIVE_CHANNELS_PATH = `${LIVE_PATH}/${MEDIA_LIVE_CHANNELS_PATH}`;
export const NETWORK_LIVE_EVENTS_PATH = `${LIVE_PATH}/network/${EVENTS_PATH}`;
export const ADMIN_PATH = 'admin';
export const NETWORK_PATH = 'network';
export const DISPLAY_TV_PATH = 'display/tv';

export const MODULE_V1 = 'v1';
export const MODULE_TV_LIVE_EVENTS = 'v1-live-tv-events';
export const MODULE_TV_LIVE_EVENT_GROUPS = 'v1-live-tv-event-groups';
export const MODULE_NETWORK_LIVE_EVENTS = 'v1-live-network-events';
export const MODULE_MEDIA_LIVE_CHANNELS = 'v1-live-media-live-channels';
export const MODULE_LIBRARIES_TV = 'v1-libraries-tv';
export const MODULE_LIBRARIES_NETWORK = 'v1-libraries-network';
export const MODULE_LIBRARIES_MEDIA_ROOM = 'v1-libraries-media-room';
export const MODULE_ADMIN = 'admin';
export const MODULE_TV_INGEST_JOBS = 'v1-libraries-tv-ingestjobs';
export const MODULE_MEDIA_ROOM_INGEST_JOBS = 'v1-libraries-media-room-ingestjobs';
export const MODULE_CALLBACK_HANDLER = 'v1-libraries-callback-handler';
export const MODULE_DISPLAY_TV = 'v1-display-tv';
export const MODULES = [
  {
    referenceId: MODULE_V1,
    name: 'V1 API',
    path: '/',
    level: 1,
  },
  {
    referenceId: MODULE_DISPLAY_TV,
    name: 'Display TV',
    path: `${DISPLAY_TV_PATH}`, // We don't have actual vms-client routes for display tv - just a placeholder.
    level: 2,
  },
  {
    referenceId: MODULE_LIBRARIES_TV,
    name: 'Libraries TV',
    path: `/${TV_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_TV_INGEST_JOBS,
    name: 'Ingest Jobs',
    path: '/media-uploader', // This path is not used as UI wont access media uploader path
    level: 3,
  },
  {
    referenceId: MODULE_LIBRARIES_NETWORK,
    name: 'Libraries Network',
    path: `/${NETWORK_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_LIBRARIES_MEDIA_ROOM,
    name: 'Libraries Media Room',
    path: `/${MEDIA_ROOM_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_MEDIA_ROOM_INGEST_JOBS,
    name: 'Ingest Jobs',
    path: '/media-uploader', // This path is not used as UI wont access media uploader path
    level: 3,
  },
  {
    referenceId: MODULE_TV_LIVE_EVENTS,
    name: 'TV Live Events',
    path: `/${TV_LIVE_EVENTS_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_TV_LIVE_EVENT_GROUPS,
    name: 'TV Live Event Groups',
    path: `/${TV_LIVE_EVENT_GROUPS_PATH}`, // Path not currently used on client V1 client
    level: 2,
  },
  {
    referenceId: MODULE_NETWORK_LIVE_EVENTS,
    name: 'Network Live Events',
    path: `/${NETWORK_LIVE_EVENTS_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_MEDIA_LIVE_CHANNELS,
    name: 'Live Tennis Events',
    path: `/${TV_LIVE_MEDIA_LIVE_CHANNELS_PATH}`,
    level: 2,
  },
  {
    referenceId: MODULE_ADMIN,
    name: 'Admin',
    path: `/${ADMIN_PATH}`,
    level: 1,
  },
  {
    referenceId: MODULE_CALLBACK_HANDLER,
    name: 'Callback Handler',
    path: '/callback-handler', // This path is not used as UI wont access callback handler path
    level: 1,
  },
];

export function getModuleName(referenceId) {
  return _.find(MODULES, { referenceId }).name;
}

export function checkAccessToModule(user, refIdToBeChecked) {
  return !!_.find(user.modules, (userModuleRefId) => {
    return _.startsWith(refIdToBeChecked, userModuleRefId);
  });
}

export function checkAccessToPath(user, path) {
  let accessAllowed = false;
  MODULES.forEach((module) => {
    if (_.startsWith(path, module.path) && checkAccessToModule(user, module.referenceId)) {
      accessAllowed = true;
      return;
    }
  });
  return accessAllowed;
}

export function hasReadyOnlyAccess(module) {
  return _.difference(module.actions, READ_ONLY_ACCESS_ACTIONS).length === 0;
}
