import * as requestHandler from '../requestHandler';
import { LIVE_EVENTS } from 'src/scripts/lib/resourceGroups';
import { getPrefix } from './endpointPrefixResolver';

const MEDIA_LIVE_PREFIX = 'api/v1/live/media-live';

// exported only to simplify testing
export function validateResourceGroup(resourceGroup) {
  switch (resourceGroup) {
    case LIVE_EVENTS.TV_LIVE_EVENTS:
    case LIVE_EVENTS.NETWORK_LIVE_EVENTS:
      return;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}

export function getLiveEvents(resourceGroup, params) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('GET', `${getPrefix(resourceGroup)}`, params);
}

export function getLiveEventById(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('GET', `${getPrefix(resourceGroup)}/${liveEventId}`);
}

export function createLiveEvent(resourceGroup, liveEvent) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}`, JSON.stringify(liveEvent));
}

export function createOlympicLiveEvent(liveEvent) {
  return requestHandler.send(
    'POST',
    `${getPrefix(LIVE_EVENTS.TV_LIVE_EVENTS)}/olympics`,
    JSON.stringify(liveEvent)
  );
}

export function updateLiveEvent(resourceGroup, liveEvent) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send(
    'PATCH',
    `${getPrefix(resourceGroup)}/${liveEvent.id}`,
    JSON.stringify(liveEvent)
  );
}

export function deleteLiveEvent(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('DELETE', `${getPrefix(resourceGroup)}/${liveEventId}`);
}

export function stopLiveEventStream(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/${liveEventId}/stop`);
}

export function startLiveEventStream(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/${liveEventId}/start`);
}

export function launchInstantLiveEvent(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/${liveEventId}/start`);
}

export function endInstantLiveEvent(resourceGroup, liveEventId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/${liveEventId}/stop`);
}

export function getOztamPublisherIdsForTvEvents() {
  return requestHandler.send('GET', `${getPrefix(LIVE_EVENTS.TV_LIVE_EVENTS)}/oztam-publisher-ids`);
}

export function getMediaLiveChannels(params) {
  return requestHandler.send('GET', `${MEDIA_LIVE_PREFIX}/channels`, params);
}

export function setMediaLiveChannelStreamInput(data) {
  return requestHandler.send(
    'POST',
    `${MEDIA_LIVE_PREFIX}/channels/${data.channelId}/switch-stream`,
    JSON.stringify({ streamInputName: data.streamInputName, reason: data.reason })
  );
}

export function setMediaLivePowerState(data) {
  return requestHandler.send(
    'POST',
    `${MEDIA_LIVE_PREFIX}/channels/${data.channelId}/power-state`,
    JSON.stringify({ state: data.state, reason: data.reason })
  );
}

export function setTouchStreamAlertState(data) {
  return requestHandler.send(
    'POST',
    `${MEDIA_LIVE_PREFIX}/channels/${data.streamKey}/alerts`,
    JSON.stringify({ state: data.state, reason: data.reason })
  );
}

export function sendRestartPacketForYoSpaceCSM(data) {
  return requestHandler.send(
    'POST',
    `${MEDIA_LIVE_PREFIX}/channels/${data.channelId}/csm-restart`,
    JSON.stringify({ reason: data.reason })
  );
}

export function setBugForMediaLiveChannel(data) {
  return requestHandler.send(
    'POST',
    `${MEDIA_LIVE_PREFIX}/channels/${data.channelId}/bug`,
    JSON.stringify({ reason: data.reason, bug: data.bug, state: data.state })
  );
}

export function getMediaLiveChannelMappings() {
  return requestHandler.send('GET', `${MEDIA_LIVE_PREFIX}/channels/mapping`);
}

export function getAvailableMediaLiveBugs() {
  return requestHandler.send('GET', `${MEDIA_LIVE_PREFIX}/channels/bugs`);
}
