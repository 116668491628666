import _ from 'lodash';
import * as tvWorkflowActions from 'src/scripts/actions/workflow/tvWorkflow';
import * as networkWorkflowActions from 'src/scripts/actions/workflow/networkWorkflow';
import { NETWORK_CLIP } from 'src/scripts/lib/modelNames';

export const states = [
  'DRAFT',
  'VIDEO_PROCESSING',
  'READY_FOR_REVIEW',
  'REVIEWED',
  'IN_USE',
  'ARCHIVED',
  'DELETING',
  'DELETION_FAILED',
];

export const networkStateConsts = {
  DRAFT: 'DRAFT',
  TRANSCODING: 'TRANSCODING',
  TRANSCODING_FAILED: 'TRANSCODING_FAILED',
  IN_USE: 'IN_USE',
  READY_FOR_REVIEW: 'READY_FOR_REVIEW',
  ARCHIVED: 'ARCHIVED',
};

export const networkStates = [
  networkStateConsts.DRAFT,
  networkStateConsts.TRANSCODING,
  networkStateConsts.TRANSCODING_FAILED,
  networkStateConsts.IN_USE,
  networkStateConsts.READY_FOR_REVIEW,
  networkStateConsts.ARCHIVED,
];

export const DISABLE_UPLOAD_VIDEO_STATUSES = [
  'UPLOADED',
  'CREATING_IN_BRIGHTCOVE',
  'CREATED_IN_BRIGHTCOVE',
  'TRANSCODING',
  'DELETED',
];

export const tvStatesAndAllowedActions = {
  DRAFT: ['view', 'delete', 'edit'],
  VIDEO_PROCESSING: ['view', 'delete', 'edit'],
  BLOCKED_IN_VIDEO_PROCESSING: ['view', 'cancel', 'edit'],
  READY_FOR_REVIEW: ['view', 'delete', 'edit', 'approve'],
  REVIEWED: ['view', 'reschedule', 'activate'],
  IN_USE: ['view', 'edit', 'expire', 'archive'],
  ARCHIVED: ['view', 'edit', 'delete', 'reschedule_and_save'],
  DELETING: [],
  DELETION_FAILED: ['delete'],
};

export const networkStatesAndAllowedActions = {
  DRAFT: ['view', 'delete', 'edit', 'expire'],
  TRANSCODING: ['view', 'delete', 'edit', 'expire'],
  TRANSCODING_FAILED: ['view', 'delete', 'edit', 'expire'],
  READY_FOR_REVIEW: ['view', 'delete', 'edit', 'expire', 'publish'],
  IN_USE: ['view', 'edit', 'delete', 'expire', 'archive'],
  ARCHIVED: ['view', 'edit', 'delete', 'reschedule_and_save'],
};

function getTVWorkflowActions(modelName, model, className, offset = 0) {
  if (!tvStatesAndAllowedActions[model.state]) {
    return [];
  }
  return tvStatesAndAllowedActions[model.state].map((action) => {
    return {
      actionName: _.capitalize(action),
      action: tvWorkflowActions[action]
        ? tvWorkflowActions[action].bind(null, model, offset, modelName)
        : null,
    };
  });
}

function findCommonActions(availableActionsForEach, possibleActions) {
  return availableActionsForEach.reduce((prev, cur) => {
    return _.intersection(prev, cur);
  }, possibleActions);
}

function getTvAvailableBulkWorkflowActions(selectedVideos) {
  const possibleActions = [
    'view',
    'edit',
    'delete',
    'cancel',
    'approve',
    'expire',
    'reschedule',
    'reschedule_and_save',
    'activate',
    'archive',
  ];
  const availableActionsForEach = selectedVideos.map((video) => {
    return tvStatesAndAllowedActions[video.state];
  });
  return findCommonActions(availableActionsForEach, possibleActions);
}

function getNetworkAvailableBulkWorkflowActions(selectedVideos) {
  const possibleActions = ['view', 'edit', 'delete', 'expire', 'reschedule_and_save', 'archive'];
  const availableActionsForEach = selectedVideos.map((video) => {
    return networkStatesAndAllowedActions[video.state];
  });
  return findCommonActions(availableActionsForEach, possibleActions);
}

export function getAvailableBulkWorkflowActions(selectedVideos, model) {
  return model === NETWORK_CLIP
    ? getNetworkAvailableBulkWorkflowActions(selectedVideos)
    : getTvAvailableBulkWorkflowActions(selectedVideos);
}

function isModelInUse(model) {
  return model.state === 'IN_USE';
}

export function getClipWorkflowActions({ clip, offset, className }) {
  return getTVWorkflowActions('Clip', clip, className, offset);
}

export function getEpisodeWorkflowActions({ episode, offset, className }) {
  return getTVWorkflowActions('Episode', episode, className, offset);
}

export function getNetworkClipWorkflowActions({ networkClip, offset }) {
  if (!networkStatesAndAllowedActions[networkClip.state]) {
    return [];
  }
  return networkStatesAndAllowedActions[networkClip.state].map((action) => {
    return {
      actionName: _.capitalize(action),
      action: networkWorkflowActions[action]
        ? networkWorkflowActions[action].bind(null, networkClip, offset)
        : null,
    };
  });
}

export function isUploadEnabled(model) {
  const workflowState = model.state;
  const videoStatus = model.video && model.video.status;
  const notAllowedStates = ['VIDEO_PROCESSING', 'REVIEWED', 'ARCHIVED', 'DELETED'];
  return (
    notAllowedStates.indexOf(workflowState) === -1 &&
    DISABLE_UPLOAD_VIDEO_STATUSES.indexOf(videoStatus) === -1
  );
}

function isArchived(model) {
  return model.state === 'ARCHIVED';
}

export function isNetworkUploadEnabled(clip) {
  const workflowState = clip.state;
  const notAllowedStates = ['TRANSCODING', 'ARCHIVED', 'DELETED'];
  return notAllowedStates.indexOf(workflowState) === -1;
}

export function isEditEnabled(model) {
  const workflowState = model.state;
  const notAllowedStates = ['REVIEWED', 'DELETED'];
  return notAllowedStates.indexOf(workflowState) === -1;
}

export function isDrmEditable(model) {
  return isUploadEnabled(model) || isArchived(model);
}

export function isContentSeriesEditable(model) {
  return !isModelInUse(model);
}

export function isTvSeriesSelectChangeable(model) {
  return !isModelInUse(model);
}

export function isSeasonSelectChangeable(model) {
  return !isModelInUse(model);
}

export function isAutoActivateEditable(model) {
  return !isModelInUse(model);
}

export function isWatermarkEnabledEditable(model) {
  const workflowState = model.state;
  const videoStatus = model.video && model.video.status;
  const notAllowedStates = ['VIDEO_PROCESSING'];
  return (
    notAllowedStates.indexOf(workflowState) === -1 &&
    DISABLE_UPLOAD_VIDEO_STATUSES.indexOf(videoStatus) === -1
  );
}
