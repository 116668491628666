import { immutableRemoveById } from 'src/scripts/lib/util';
import {
  BULK_ACTIONS_ADD_TO_SELECTED,
  BULK_ACTIONS_REMOVE_FROM_SELECTED,
  BULK_ACTIONS_REPLACE_SELECTED,
  BULK_ACTIONS_DESELECT_ALL,
  BULK_ACTION_SUCCEEDED,
  BULK_ACTION_FAILED,
  BULK_ACTION_PROCESSING_STARTED,
  BULK_ACTION_PROCESSING_COMPLETED,
} from 'src/scripts/actions/bulkAction';

export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETED = 'COMPLETED';

export const initialState = {
  bulkActionStatus: '',
  actionType: '',
  modelName: '',
  selected: [],
  unprocessed: [],
  processed: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BULK_ACTIONS_ADD_TO_SELECTED:
      return {
        ...state,
        selected: state.selected.concat(action.selected),
      };
    case BULK_ACTIONS_REMOVE_FROM_SELECTED:
      return {
        ...state,
        selected: immutableRemoveById(state.selected, action.id),
      };
    case BULK_ACTIONS_REPLACE_SELECTED:
      return {
        ...state,
        selected: action.selected,
      };
    case BULK_ACTIONS_DESELECT_ALL:
      return {
        ...state,
        selected: [],
      };
    case BULK_ACTION_PROCESSING_STARTED:
      return {
        ...state,
        selected: [],
        bulkActionStatus: IN_PROGRESS,
        actionType: action.actionType,
        modelName: action.modelName,
        unprocessed: action.ids,
        processed: [],
      };
    case BULK_ACTION_PROCESSING_COMPLETED:
      return {
        ...state,
        bulkActionStatus: COMPLETED,
      };
    case BULK_ACTION_FAILED:
      return {
        ...state,
        unprocessed: state.unprocessed.filter((id) => id !== action.id),
        processed: state.processed.concat(action.id),
      };
    case BULK_ACTION_SUCCEEDED:
      return {
        ...state,
        unprocessed: state.unprocessed.filter((id) => id !== action.id),
        processed: state.processed.concat(action.id),
      };
    default:
      return state;
  }
}
