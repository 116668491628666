import $ from 'jquery';
import * as s3UploadHandler from 'src/scripts/actions/s3Upload';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';

export const UPLOAD_STARTED = 'UPLOAD_STARTED';
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const TRANSCODE_VIDEOCLOUD_STARTED = 'TRANSCODE_VIDEOCLOUD_STARTED';
export const TRANSCODE_VIDEOCLOUD_SUCCEEDED = 'TRANSCODE_VIDEOCLOUD_SUCCEEDED';
export const TRANSCODE_VIDEOCLOUD_FAILED = 'TRANSCODE_VIDEOCLOUD_FAILED';
export const GET_SIGNED_VIDEO_SOURCE_URL_SUCCEEDED = 'GET_SIGNED_VIDEO_SOURCE_URL_SUCCEEDED';
export const GET_SIGNED_VIDEO_SOURCE_URL_FAILED = 'GET_SIGNED_VIDEO_SOURCE_URL_FAILED';
export const GET_SIGNED_VIDEO_SOURCE_URL_STARTED = 'GET_SIGNED_VIDEO_SOURCE_URL_STARTED';
export const GET_SIGNED_CAPTION_SOURCE_URL_SUCCEEDED = 'GET_SIGNED_CAPTION_SOURCE_URL_SUCCEEDED';
export const GET_SIGNED_CAPTION_SOURCE_URL_FAILED = 'GET_SIGNED_CAPTION_SOURCE_URL_FAILED';
export const GET_SIGNED_CAPTION_SOURCE_URL_STARTED = 'GET_SIGNED_CAPTION_SOURCE_URL_STARTED';

export function getSignedVideoSourceUrl(resourceGroup, videoId) {
  return (dispatch) => {
    dispatch({
      type: GET_SIGNED_VIDEO_SOURCE_URL_STARTED,
    });

    const onSuccess = (result) => {
      dispatch({
        type: GET_SIGNED_VIDEO_SOURCE_URL_SUCCEEDED,
        url: result.url,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_SIGNED_VIDEO_SOURCE_URL_FAILED,
        error: response.responseJSON.message,
      });
    };

    return videosVmsApi.getSignedVideoSourceUrl(resourceGroup, videoId).then(onSuccess, onError);
  };
}

export function getSignedCaptionSourceUrl(resourceGroup, videoId) {
  return (dispatch) => {
    dispatch({
      type: GET_SIGNED_CAPTION_SOURCE_URL_STARTED,
    });

    const onSuccess = (result) => {
      dispatch({
        type: GET_SIGNED_CAPTION_SOURCE_URL_SUCCEEDED,
        url: result.url,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_SIGNED_CAPTION_SOURCE_URL_FAILED,
        error: response.responseJSON.message,
      });
    };

    return videosVmsApi.getSignedCaptionSourceUrl(resourceGroup, videoId).then(onSuccess, onError);
  };
}

export function startTranscode(resourceGroup, payload) {
  return videosVmsApi.startTranscode(resourceGroup, payload.videoId);
}

export function transcodeBrightcoveVideoCloud(resourceGroup, id, onComplete) {
  return (dispatch) => {
    const onSuccess = () => {
      return onComplete().then(() => dispatch({ type: TRANSCODE_VIDEOCLOUD_SUCCEEDED }));
    };
    const onError = () => {
      return onComplete().then(() => dispatch({ type: TRANSCODE_VIDEOCLOUD_FAILED }));
    };

    dispatch({ type: TRANSCODE_VIDEOCLOUD_STARTED });

    return videosVmsApi.transcodeBrightcoveVideoCloud(resourceGroup, id).then(onSuccess, onError);
  };
}

export function uploadCompleted(resourceGroup, payload) {
  if (payload.uploadType === 'video') {
    return startTranscode(resourceGroup, payload);
  }
  const deferred = new $.Deferred();
  return deferred.resolve();
}

export function startUpload(resourceGroup, payload, failureAction) {
  return (dispatch) => {
    const onNext = (uploadResult) => {
      videosVmsApi.notifyUploadCompletion(resourceGroup, payload, uploadResult.s3Path).then(() => {
        return uploadCompleted(resourceGroup, payload);
      });

      return dispatch({
        type: UPLOAD_COMPLETED,
        videoId: payload.videoId,
      });
    };

    const onError = (error) => {
      failureAction && failureAction();

      return dispatch({
        type: UPLOAD_FAILED,
        error,
        videoId: payload.videoId,
      });
    };

    const progressCallback = (numberOfParts) => {
      return dispatch({
        type: UPLOAD_PROGRESS,
        videoId: payload.videoId,
        numberOfParts,
      });
    };

    dispatch({
      type: UPLOAD_STARTED,
      videoId: payload.videoId,
    });
    return s3UploadHandler
      .upload(resourceGroup, payload.videoId, payload.file, payload.uploadType, progressCallback)
      .subscribe(onNext, onError);
  };
}
