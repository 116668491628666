import { VISIBLE_COLUMNS_UPDATED } from 'src/scripts/actions/columnChooser';

export const initialState = {
  columns: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VISIBLE_COLUMNS_UPDATED:
      return { ...state, columns: action.columns };

    default:
      return {
        ...state,
      };
  }
}
