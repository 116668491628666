import $ from 'jquery';
import moment from 'moment';
import streamSaver from 'streamsaver';
import environment from 'config/environment';
streamSaver.mitm = `${environment.endpoints.vmsClientUrl}/mitm.html`;

function getFileNameFromUrl(url) {
  try {
    return new URL(url).pathname.split('/').pop();
  } catch (e) {
    return false;
  }
}

const DEFAULT_DOWNLOAD_FILENAME = 'download';

export function downloadStreamFromUrl(url, fileName) {
  const deferred = $.Deferred();
  const fileStream = streamSaver.createWriteStream(
    fileName || getFileNameFromUrl(url) || DEFAULT_DOWNLOAD_FILENAME,
    { highWaterMark: 1024 }
  );

  const fetchStream = (response) => {
    if (window.WritableStream && response.body.pipeTo) {
      return response.body.pipeTo(fileStream).then(
        () => deferred.resolve(),
        () => deferred.reject()
      );
    }

    const writer = fileStream.getWriter();
    const reader = response.body.getReader();
    return reader.read().then(function processChunk({ done, value }) {
      if (done) {
        writer.close().then(
          () => deferred.resolve(),
          () => deferred.reject()
        );
      } else {
        writer.write(value).then(
          () => reader.read().then(processChunk),
          () => deferred.reject()
        );
      }
    });
  };

  window.fetch(url).then(fetchStream, () => deferred.reject());

  return deferred.promise();
}

export const downloadUrl = (url, fileName) => {
  const link = document.createElement('a');

  link.href = url;
  link.style = 'visibility:hidden';
  link.download = fileName || getFileNameFromUrl(url) || DEFAULT_DOWNLOAD_FILENAME;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (fileData, fileName) => {
  const blob = new Blob([fileData], {
    type: 'application/csv;charset=utf-8;',
  });
  const objectUrl = URL.createObjectURL(blob);
  downloadUrl(objectUrl, fileName);
};

export const exportCatalogFile = (fileData, prefix) => {
  const fileName = `${prefix}-catalog(${moment().format('DD-MM-YYYY HH:mm:ss')}).csv`;
  downloadFile(fileData, fileName);
};
