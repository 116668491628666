import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatBoolean, FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

function getCodeType(classification) {
  let codeType = classification.type;
  if (classification.code) {
    codeType += ` (${classification.code})`;
  }

  return codeType;
}

const ContentClassificationPanel = ({ season }) => {
  return (
    <Panel header="Content Classifications">
      {season.classifications.map((classification) => {
        return (
          <Panel header={getCodeType(classification)}>
            <DetailsRowItem label="Digital Exclusive">
              <FormatBoolean>{classification.digitalExclusive}</FormatBoolean>
            </DetailsRowItem>
            <DetailsRowItem label="Incremental Spend">
              <FormatBoolean>{classification.incrementalSpend}</FormatBoolean>
            </DetailsRowItem>
            <DetailsRowItem label="Start Date">
              <FormatText>{classification.startDate}</FormatText>
            </DetailsRowItem>
            <DetailsRowItem label="End Date">
              <FormatText>{classification.endDate}</FormatText>
            </DetailsRowItem>
          </Panel>
        );
      })}
    </Panel>
  );
};

ContentClassificationPanel.propTypes = {
  season: PropTypes.object,
};

export default ContentClassificationPanel;
