import { RESIZE_SCREEN_WIDTH } from 'src/scripts/actions/ui/screen';

export const initialState = {
  size: {
    width: typeof window === 'object' ? window.innerWidth : null,
  },
};

const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESIZE_SCREEN_WIDTH:
      return {
        ...state,
        size: {
          ...state.size,
          width: action.width,
        },
      };
    default:
      return state;
  }
};

export default screenReducer;

export const isSmallScreen = (state) => state.ui.screen.size.width < 768;
