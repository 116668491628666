import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import { getSeasons, deleteSeasonAndCloseConfirmation } from 'src/scripts/actions/season';
import { updateTVSeriesBreadcrumb } from 'src/scripts/actions/breadcrumb';
import ModalItem from 'src/scripts/components/ModalItem';
import * as pagination from 'src/scripts/lib/pagination';
import SeasonFormLoader from 'src/scripts/components/Season/SeasonFormLoader';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import NotesWithTooltip from 'src/scripts/components/NotesWithTooltip';
import history from 'src/scripts/lib/history';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { TV_PATH } from 'src/scripts/lib/accessController';
import {
  HORIZONTAL_A,
  SQUARE,
  BACKGROUND,
  CARD,
  LOGO,
  POSTER,
  CROPPED,
  CENTERED_IMAGE,
} from 'src/scripts/lib/imageTypes';
import { isDefaultImage } from 'src/scripts/lib/util';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import environment from 'config/environment';

export class Season extends React.Component {
  constructor() {
    super();
    this.getSeasons = this.getSeasons.bind(this);
  }

  componentDidMount() {
    this.loadData(Number(this.props.params.id));
  }

  componentDidUpdate(prevProps) {
    const tvSeriesId = Number(this.props.params.id);
    const previousTvSeriesId = Number(prevProps.params.id);
    if (prevProps.page !== this.props.page || tvSeriesId !== previousTvSeriesId) {
      this.loadData(tvSeriesId);
      this.getSeasons();
    }
    if (this.props.savedSeason && this.props.savedSeason.tvSeriesId === Number(this.props.params.id)) {
      this.getSeasons();
    }
  }

  getImageUrlByType(images, type) {
    const foundImage = images.find((image) => image.type === type);
    if (!foundImage) return IMAGE_NOT_FOUND_URL;
    if (foundImage && isDefaultImage(foundImage)) {
      return IMAGE_NOT_FOUND_URL;
    }
    if (foundImage.resizedImageUrls && foundImage.resizedImageUrls.w272) {
      return foundImage.resizedImageUrls.w272;
    }
    return foundImage.url;
  }

  getSeasons(params = {}) {
    const tvSeriesId = Number(this.props.params.id);
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    return this.props.getSeasons({
      tvSeriesId,
      offset,
      limit: pagination.pageSize,
      sort: params.sort || this.props.sort,
    });
  }

  checkIfImageIsTemp(images, type) {
    const foundImage = images.find((image) => image.type === type);
    return foundImage && foundImage.isImageTemp;
  }

  loadData(tvSeriesId) {
    if (!tvSeriesId) {
      return history.replace({ state: null, pathname: `/${TV_PATH}/tv-series`, query: { page: 1 } });
    }
    return this.props.updateTVSeriesBreadcrumb(tvSeriesId);
  }

  render() {
    const actionList = (seasonItem) => {
      const seasonDetailsPath = `/#/${TV_PATH}/tv-series/${seasonItem.partOfSeries.id}/seasons/${seasonItem.id}/details`;
      return (
        <NavDropdownMenu
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          id={`season-item-button-${seasonItem.id}`}
        >
          <li>
            <a href={seasonDetailsPath}>View Season</a>
          </li>
          <ModalItem title="Edit Season" form={<SeasonFormLoader seasonId={seasonItem.id} />} />
          <ModalItem
            title="Delete Season"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${seasonItem.name}" season. Are you sure you wish to proceed?`}
                ok={this.props.deleteSeasonAndCloseConfirmation.bind(this, seasonItem.id)}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        </NavDropdownMenu>
      );
    };
    const list = {
      pageData: this.props.seasons,
      totalCount: this.props.seasonsCount,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };

    const breadcrumbs = [
      <a href={`/#/${TV_PATH}/tv-series`}>TV Series</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.tvSeriesId}`}>{this.props.tvSeriesName}</a>,
      'Seasons',
    ];

    return (
      <div>
        <ContextNavigation breadcrumbList={breadcrumbs} />
        <ItemList id="list-season" list={list} getItems={this.getSeasons} defaultSort="-seasonNumber">
          <ItemListHeader
            label="Image"
            dataFormat={(season) => {
              return (
                <span className="row-image-span">
                  {this.checkIfImageIsTemp(season.images, HORIZONTAL_A) && (
                    <div className="temporary-img">TEMPORARY</div>
                  )}
                  <img
                    ref="img"
                    className="row-image"
                    src={this.getImageUrlByType(season.images, HORIZONTAL_A)}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader
            label="Square Image"
            dataFormat={(season) => {
              return (
                <div>
                  {this.checkIfImageIsTemp(season.images, SQUARE) && (
                    <div className="temporary-img">TEMP</div>
                  )}
                  <img
                    ref="img"
                    className="row-image-square"
                    src={this.getImageUrlByType(season.images, SQUARE)}
                  />
                </div>
              );
            }}
          />
          <ItemListHeader
            label="Background Image"
            dataFormat={(season) => {
              return (
                <span className="row-image-span">
                  {this.checkIfImageIsTemp(season.images, BACKGROUND) && (
                    <div className="temporary-img">TEMPORARY</div>
                  )}
                  <img
                    ref="img"
                    className="row-image"
                    src={this.getImageUrlByType(season.images, BACKGROUND)}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader
            label="Card Image"
            dataFormat={(season) => {
              return (
                <span className="row-image-span">
                  {this.checkIfImageIsTemp(season.images, CARD) && (
                    <div className="temporary-img">TEMPORARY</div>
                  )}
                  <img ref="img" className="row-image" src={this.getImageUrlByType(season.images, CARD)} />
                </span>
              );
            }}
          />
          <ItemListHeader
            label="Logo Image"
            dataFormat={(season) => {
              return (
                <div>
                  {this.checkIfImageIsTemp(season.images, LOGO) && (
                    <div className="temporary-img">TEMPORARY</div>
                  )}
                  <img
                    ref="img"
                    className="row-image-logo"
                    src={this.getImageUrlByType(season.images, LOGO)}
                  />
                </div>
              );
            }}
          />
          <ItemListHeader
            label="Poster Image"
            dataFormat={(season) => {
              return (
                <div>
                  {this.checkIfImageIsTemp(season.images, POSTER) && (
                    <div className="temporary-img">TEMPORARY</div>
                  )}
                  <img
                    ref="img"
                    className="row-image-poster"
                    src={this.getImageUrlByType(season.images, POSTER)}
                  />
                </div>
              );
            }}
          />
          <ItemListHeader
            label="Centered Image"
            dataFormat={(season) => {
              return (
                <div>
                  {this.checkIfImageIsTemp(season.images, CENTERED_IMAGE) && (
                    <div className="temporary-img">TEMP</div>
                  )}
                  <img
                    ref="img"
                    className="row-image-square"
                    src={this.getImageUrlByType(season.images, CENTERED_IMAGE)}
                  />
                </div>
              );
            }}
          />
          <ItemListHeader
            label="Cropped Logo"
            dataFormat={(season) => {
              return (
                <div>
                  {this.checkIfImageIsTemp(season.images, CROPPED) && (
                    <div className="temporary-img">TEMP</div>
                  )}
                  <img
                    ref="img"
                    className="row-image-square"
                    src={this.getImageUrlByType(season.images, CROPPED)}
                  />
                </div>
              );
            }}
          />
          <ItemListHeader
            label={'Active Episodes'}
            dataFormat={(season) => `${season.episodeActiveCount} / ${season.episodeTotalCount}`}
          />
          <ItemListHeader
            sort
            label="Season"
            dataField="name"
            dataFormat={(season) => {
              return (
                <a href={`#/${TV_PATH}/tv-series/${season.partOfSeries.id}/seasons/${season.id}`}>
                  {season.name}
                </a>
              );
            }}
          />
          <ItemListHeader sort label="Season No." dataField="seasonNumber" />
          <ItemListHeader
            sort
            label="Notes"
            dataFormat={(season) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip notes={season.producerNotes} emptyText="No Notes for this Season" />
                </div>
              );
            }}
          />
          {environment.featureFlags.nineVideoFeaturesEnabled ? (
            <ItemListHeader label="Content Flag" dataField={'contentFlag'} />
          ) : null}
          <ItemListHeader
            label="Channel"
            dataFormat={(season) => {
              return season.channel ? season.channel.name : '';
            }}
          />
          <ItemListHeader
            label="Box Set"
            dataFormat={(season) => {
              return season.isBoxSet ? '✔' : '✖';
            }}
          />
          <ItemListHeader label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader
            label="Actions"
            dataFormat={(season) => {
              return actionList(season);
            }}
          />
        </ItemList>
      </div>
    );
  }
}

Season.propTypes = {
  seasonsCount: PropTypes.number,
  seasons: PropTypes.array,
  params: PropTypes.object,
  page: PropTypes.number,
  offset: PropTypes.number,
  history: PropTypes.object,
  tvSeriesName: PropTypes.string,
  tvSeriesId: PropTypes.string,
  getSeasons: PropTypes.func,
  deleteSeasonAndCloseConfirmation: PropTypes.func,
  updateTVSeriesBreadcrumb: PropTypes.func,
  sort: PropTypes.string,
  savedSeason: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    tvSeriesName: state.breadcrumb.tvSeriesName,
    tvSeriesId: state.breadcrumb.tvSeriesId,
    offset: state.seasonList.offset,
    seasons: state.seasonList.seasons,
    seasonsCount: state.seasonList.seasonsCount,
    sort: state.itemList.sort,
    savedSeason: state.seasonForm.savedSeason,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteSeasonAndCloseConfirmation: (seasonId) => dispatch(deleteSeasonAndCloseConfirmation(seasonId)),
    getSeasons: (pageData) => dispatch(getSeasons(pageData)),
    updateTVSeriesBreadcrumb: (tvSeriesId) => dispatch(updateTVSeriesBreadcrumb(tvSeriesId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Season);
