import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatBoolean } from 'src/scripts/components/Season/DetailsView/Formatters';

const GeoblockingPanel = ({ season }) => {
  return (
    <Panel header="Geoblocking">
      <DetailsRowItem label="Clips Available Only in Australia">
        <FormatBoolean>{season.clipsGeoBlocked}</FormatBoolean>
      </DetailsRowItem>
      <DetailsRowItem label="Episodes Available Only in Australia">
        <FormatBoolean>{season.episodesGeoBlocked}</FormatBoolean>
      </DetailsRowItem>
    </Panel>
  );
};

GeoblockingPanel.propTypes = {
  season: PropTypes.object,
};

export default GeoblockingPanel;
