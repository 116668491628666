import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { deselectAll } from 'src/scripts/actions/bulkAction';
import { executeBulkWorkflowAction } from 'src/scripts/lib/bulkWorkflowActionsProvider';
import * as clipBulkActions from 'src/scripts/actions/clipBulk';
import * as episodeBulkActions from 'src/scripts/actions/episodeBulk';
import * as networkClipBulkActions from 'src/scripts/actions/networkClipBulk';
import * as ingestJobBulkActions from 'src/scripts/actions/ingestJobBulk';
import DeleteButton from 'src/scripts/components/BulkAction/buttons/DeleteButton';
import DownloadButton from 'src/scripts/components/BulkAction/buttons/DownloadButton';
import ArchiveButton from 'src/scripts/components/BulkAction/buttons/ArchiveButton';
import ExpireButton from 'src/scripts/components/BulkAction/buttons/ExpireButton';
import RetranscodeButton from 'src/scripts/components/BulkAction/buttons/RetranscodeButton';
import ViewButton from 'src/scripts/components/BulkAction/buttons/ViewButton';
import RescheduleButton from 'src/scripts/components/BulkAction/buttons/RescheduleButton';
import EditButton from 'src/scripts/components/BulkAction/buttons/EditButton';
import BulkActionProgressBar from 'src/scripts/components/BulkAction/BulkActionProgressBar';
import BulkEditButton from 'src/scripts/components/BulkAction/buttons/BulkEditButton';
import AssignEpisodeButton from 'src/scripts/components/BulkAction/buttons/AssignEpisodeButton';

export const BulkActionButtonTypes = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  RESCHEDULE: 'RESCHEDULE',
  BULK_EDIT: 'BULK_EDIT',
  RETRANSCODE: 'RETRANSCODE',
  EXPIRE: 'EXPIRE',
  ARCHIVE: 'ARCHIVE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  ASSIGN_EPISODE: 'ASSIGN_EPISODE',
};

export class BulkActionBar extends Component {
  componentWillUnmount() {
    this.props.deselectAll();
  }

  renderViewButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.VIEW) ? (
      <ViewButton modelName={this.props.modelName} selectedItems={this.props.selectedItems} />
    ) : null;
  }

  renderAssignEpisodeButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.ASSIGN_EPISODE) ? (
      <AssignEpisodeButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        seasonId={this.props.seasonId}
        assignClipsToEpisode={this.props.assignClipsToEpisode}
      />
    ) : null;
  }

  renderEditButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.EDIT) ? (
      <EditButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        unprocessed={this.props.unprocessed}
        processingActionType={this.props.processingActionType}
        processingModelName={this.props.processingModelName}
      />
    ) : null;
  }

  renderBulkEditButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.BULK_EDIT) ? (
      <BulkEditButton
        classifications={this.props.classifications}
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        unprocessed={this.props.unprocessed}
        processingActionType={this.props.processingActionType}
        processingModelName={this.props.processingModelName}
        editEpisodeData={this.props.editEpisodeData}
        editClipSchedules={this.props.editClipSchedules}
        rescheduleAndSaveEpisodes={this.props.rescheduleAndSaveEpisodes}
        rescheduleAndSaveClips={this.props.rescheduleAndSaveClips}
      />
    ) : null;
  }

  renderRescheduleButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.RESCHEDULE) ? (
      <RescheduleButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        unprocessed={this.props.unprocessed}
        processingActionType={this.props.processingActionType}
        processingModelName={this.props.processingModelName}
      />
    ) : null;
  }

  renderArchiveButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.ARCHIVE) ? (
      <ArchiveButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        executeBulkWorkflowAction={this.props.executeBulkWorkflowAction}
      />
    ) : null;
  }

  renderDeleteButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.DELETE) ? (
      <DeleteButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        deleteClips={this.props.deleteClips}
        deleteEpisodes={this.props.deleteEpisodes}
        deleteNetworkClips={this.props.deleteNetworkClips}
        deleteIngestJobs={this.props.deleteIngestJobs}
      />
    ) : null;
  }

  renderDownloadButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.DOWNLOAD) ? (
      <DownloadButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        downloadEpisodes={this.props.downloadEpisodes}
        downloadClips={this.props.downloadClips}
        processed={this.props.processed}
        unprocessed={this.props.unprocessed}
        processingActionType={this.props.processingActionType}
        processingModelName={this.props.processingModelName}
      />
    ) : null;
  }

  renderExpireButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.EXPIRE) ? (
      <ExpireButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        expireClips={this.props.expireClips}
        expireEpisodes={this.props.expireEpisodes}
        expireNetworkClips={this.props.expireNetworkClips}
      />
    ) : null;
  }

  renderRetranscodeButton() {
    return this.props.buttons.includes(BulkActionButtonTypes.RETRANSCODE) ? (
      <RetranscodeButton
        modelName={this.props.modelName}
        selectedItems={this.props.selectedItems}
        bulkActionStatus={this.props.bulkActionStatus}
        retranscodeClips={this.props.retranscodeClips}
        retranscodeEpisodes={this.props.retranscodeEpisodes}
        retranscodeNetworkClips={this.props.retranscodeNetworkClips}
      />
    ) : null;
  }

  renderBulkActionButtons() {
    return (
      <ButtonToolbar className="tv-button-toolbar">
        {this.renderViewButton()}
        {this.renderEditButton()}
        {this.renderRescheduleButton()}
        {this.renderAssignEpisodeButton()}
        <ButtonGroup>
          {this.renderBulkEditButton()}
          {this.renderRetranscodeButton()}
          {this.renderExpireButton()}
          {this.renderArchiveButton()}
          {this.renderDeleteButton()}
          {this.renderDownloadButton()}
        </ButtonGroup>
      </ButtonToolbar>
    );
  }

  render() {
    return (
      <div className="bulk-actions-container">
        <BulkActionProgressBar
          ref="bulkActionProgressBar"
          processed={this.props.processed}
          unprocessed={this.props.unprocessed}
          status={this.props.bulkActionStatus}
        />
        <div className="bulk-action-bar">{this.renderBulkActionButtons()}</div>
      </div>
    );
  }
}

BulkActionBar.propTypes = {
  classifications: PropTypes.array,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  deselectAll: PropTypes.func,
  deleteClips: PropTypes.func,
  deleteEpisodes: PropTypes.func,
  deleteNetworkClips: PropTypes.func,
  deleteIngestJobs: PropTypes.func,
  downloadEpisodes: PropTypes.func,
  downloadClips: PropTypes.func,
  expireClips: PropTypes.func,
  expireEpisodes: PropTypes.func,
  editClipSchedules: PropTypes.func,
  editEpisodeData: PropTypes.func,
  rescheduleAndSaveClips: PropTypes.func,
  assignClipsToEpisode: PropTypes.func,
  rescheduleAndSaveEpisodes: PropTypes.func,
  expireNetworkClips: PropTypes.func,
  retranscodeClips: PropTypes.func,
  retranscodeEpisodes: PropTypes.func,
  retranscodeNetworkClips: PropTypes.func,
  executeBulkWorkflowAction: PropTypes.func,
  bulkActionStatus: PropTypes.string,
  processed: PropTypes.array,
  unprocessed: PropTypes.array,
  processingModelName: PropTypes.string,
  processingActionType: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BulkActionButtonTypes))),
  seasonId: PropTypes.number,
};

BulkActionBar.defaultProps = {
  buttons: [],
};

export function mapStateToProps(state) {
  return {
    classifications: state.episodeForm.classifications,
    selectedItems: state.bulkActions.selected,
    bulkActionStatus: state.bulkActions.bulkActionStatus,
    processed: state.bulkActions.processed,
    unprocessed: state.bulkActions.unprocessed,
    processingModelName: state.bulkActions.modelName,
    processingActionType: state.bulkActions.actionType,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    deselectAll: () => dispatch(deselectAll()),
    executeBulkWorkflowAction: (action, resourceIds, resourceName) =>
      dispatch(executeBulkWorkflowAction(action, resourceIds, resourceName)),
    retranscodeClips: (clipIds) => dispatch(clipBulkActions.transcodeClips(clipIds)),
    retranscodeEpisodes: (episodeIds) => dispatch(episodeBulkActions.transcodeEpisodes(episodeIds)),
    retranscodeNetworkClips: (networkClipIds) =>
      dispatch(networkClipBulkActions.transcodeNetworkClips(networkClipIds)),
    editClipSchedules: (clipIds, clipData) => dispatch(clipBulkActions.editClipSchedules(clipIds, clipData)),
    editEpisodeData: (episodeIds, episodeData) =>
      dispatch(episodeBulkActions.editEpisodeData(episodeIds, episodeData)),
    rescheduleAndSaveClips: (clipIds, clipData) =>
      dispatch(clipBulkActions.rescheduleAndSaveClips(clipIds, clipData)),
    assignClipsToEpisode: (clipIds, clipData) =>
      dispatch(clipBulkActions.assignClipsToEpisode(clipIds, clipData)),
    rescheduleAndSaveEpisodes: (episodeIds, episodeData) =>
      dispatch(episodeBulkActions.rescheduleAndSaveEpisodes(episodeIds, episodeData)),
    expireClips: (clipIds) => dispatch(clipBulkActions.expireClips(clipIds)),
    expireEpisodes: (episodeIds) => dispatch(episodeBulkActions.expireEpisodes(episodeIds)),
    expireNetworkClips: (networkClipIds) =>
      dispatch(networkClipBulkActions.expireNetworkClips(networkClipIds)),
    deleteClips: (clipIds) => dispatch(clipBulkActions.deleteClips(clipIds)),
    deleteEpisodes: (episodeIds) => dispatch(episodeBulkActions.deleteEpisodes(episodeIds)),
    deleteNetworkClips: (networkClipIds) =>
      dispatch(networkClipBulkActions.deleteNetworkClips(networkClipIds)),
    deleteIngestJobs: (ingestJobIds) => dispatch(ingestJobBulkActions.deleteIngestJobs(ingestJobIds)),
    downloadEpisodes: (videoIds) => dispatch(episodeBulkActions.downloadEpisodes(videoIds)),
    downloadClips: (videoIds) => dispatch(clipBulkActions.downloadClips(videoIds)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkActionBar);
