import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'src/scripts/lib/history';
import { resetView } from 'src/scripts/actions/mediaItemDetailsView';
import { getNetworkClip } from 'src/scripts/actions/networkClip';
import ViewLoader from 'src/scripts/components/ViewLoader';
import NetworkClipDetailsView from 'src/scripts/components/NetworkClip/internal/NetworkClipDetailsView';

export class NetworkClipDetailsViewLoader extends React.Component {
  componentDidMount() {
    this.props.resetView();
    this.props.getNetworkClip(this.props.params.clipId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.clipDetailsView.clip && this.props.clipDetailsView.clip) {
      this.navigateToClipList();
      return;
    }
    if (nextProps.disableReingestVideoCloud !== this.props.disableReingestVideoCloud) {
      this.props.getNetworkClip(Number(this.props.params.clipId));
    }
    if (nextProps.params.clipId !== this.props.params.clipId) {
      this.props.getNetworkClip(Number(nextProps.params.clipId));
    }
  }

  navigateToClipList() {
    const categoryId = this.props.clipDetailsView.clip.partOfCategory.id;
    const subcategoryId = this.props.clipDetailsView.clip.partOfSubcategory.id;
    const pathname = `/network/categories/${categoryId}/subcategories/${subcategoryId}/clips`;
    history.replace({ state: null, pathname, query: { page: 1 } });
  }

  render() {
    return (
      <ViewLoader
        loading={
          !this.props.clipDetailsView.clip ||
          Number(this.props.params.clipId) !== this.props.clipDetailsView.clip.id
        }
        errorMessage={this.props.clipDetailsView.errorMessage}
      >
        <NetworkClipDetailsView clip={this.props.clipDetailsView.clip} />
      </ViewLoader>
    );
  }
}

NetworkClipDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  resetView: PropTypes.func,
  getNetworkClip: PropTypes.func,
  clipDetailsView: PropTypes.object,
  disableReingestVideoCloud: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    clipDetailsView: state.networkClips.detailsView,
    disableReingestVideoCloud: state.mediaItemDetailsView.disableReingestVideoCloud,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetView: () => dispatch(resetView()),
    getNetworkClip: (clipId) => dispatch(getNetworkClip(clipId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClipDetailsViewLoader);
