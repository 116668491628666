import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as pagination from 'src/scripts/lib/pagination';
import { getAllEpisodes } from 'src/scripts/actions/episode';
import VideoList from 'src/scripts/components/Video/VideoList';
import { resetItemList, updateFilter } from 'src/scripts/actions/itemList';

const EPISODES_DEFAULT_FILTER = {
  name: 'state',
  value: 'in:DRAFT,VIDEO_PROCESSING,READY_FOR_REVIEW,REVIEWED,IN_USE,DELETING,DELETION_FAILED',
};

export class EpisodeList extends React.Component {
  constructor() {
    super();
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidUpdate() {
    if (this.props.savedEpisode) {
      this.props.getAllEpisodes({
        offset: pagination.calculateOffset(this.props.page),
        sort: this.props.sort,
        filters: this.props.filters,
      });
    }
  }

  clearFilters() {
    this.props.resetItemList();
    this.props.updateFilter(EPISODES_DEFAULT_FILTER);
    return this.props.getAllEpisodes({ filter: EPISODES_DEFAULT_FILTER });
  }

  render() {
    return (
      <VideoList
        getItems={this.props.getAllEpisodes}
        modelName="episodes"
        {...this.props}
        id="list-episode"
        defaultFilter={EPISODES_DEFAULT_FILTER}
        clearFilters={this.clearFilters}
        hasSearch
      />
    );
  }
}

EpisodeList.propTypes = {
  page: PropTypes.number,
  getAllEpisodes: PropTypes.func,
  savedEpisode: PropTypes.object,
  sort: PropTypes.string,
  filters: PropTypes.object,
  updateFilter: PropTypes.func,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    savedEpisode: state.episodes.savedEpisode,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllEpisodes: (pageData) => dispatch(getAllEpisodes(pageData)),
    updateFilter: ({ name, value }) => dispatch(updateFilter({ name, value })),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeList);
