import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_SUBCATEGORY_FAILED,
  SAVE_SUBCATEGORY_SUCCEEDED,
  UPDATE_SUBCATEGORY,
  GET_SUBCATEGORY_SUCCEEDED,
  GET_SUBCATEGORY_FAILED,
  SAVE_SUBCATEGORY_STARTED,
  GET_SUBCATEGORY_FORM_INITIAL_DATA_SUCCEEDED,
} from 'src/scripts/actions/subcategory';
import { GET_CATEGORIES_SUCCEEDED } from 'src/scripts/actions/category';
import { CONTENT_GROUPS } from 'src/scripts/lib/videoAdMetadataGroups';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/subcategoryForm';

export const initialState = {
  subcategory: {
    name: '',
    geoBlocked: false,
    contentGroup: CONTENT_GROUPS[0].id,
  },
  categories: [],
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  saving: false,
  savedSubcategory: null,
  initialFormDataLoaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBCATEGORY_SUCCEEDED:
      return {
        ...state,
        ...action.data,
      };

    case GET_SUBCATEGORY_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case SAVE_SUBCATEGORY_STARTED:
      return { ...state, saving: true };

    case SAVE_SUBCATEGORY_FAILED:
      return { ...state, errorMessage: action.error, saving: false };

    case SAVE_SUBCATEGORY_SUCCEEDED:
      return { ...state, errorMessage: null, saving: false, savedSubcategory: action.data.subcategory };

    case UPDATE_SUBCATEGORY:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.subcategory, ...action.subcategory },
          getValidationRules(state.subcategory)
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          subcategory: {
            ...state.subcategory,
            ...action.subcategory,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        subcategory: {
          ...state.subcategory,
          ...action.subcategory,
        },
      };

    case GET_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        categories: action.data.categories,
      };

    case GET_SUBCATEGORY_FORM_INITIAL_DATA_SUCCEEDED:
      return {
        ...state,
        initialFormDataLoaded: true,
      };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.subcategory, getValidationRules(state.subcategory));
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
