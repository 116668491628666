import moment from 'moment';

const VALID_DATE_FORMATS = ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY', moment.ISO_8601];

function hasDateFilters(filters) {
  const hasStartDateFilters = filters.startDate.from !== null || filters.startDate.to !== null;
  const hasEndDateFilters = filters.endDate.from !== null || filters.endDate.to !== null;
  return hasStartDateFilters || hasEndDateFilters;
}

function hasStateFilters(filters) {
  return !!filters.state;
}

function hasTvSeriesFilter(filters) {
  return filters.tvSeriesId !== null;
}

function hasSpecifiedFilters(filters) {
  const filterHasDateFilters = hasDateFilters(filters);
  const filterHasStateFilters = hasStateFilters(filters);
  const filterHasTvSeriesFilter = hasTvSeriesFilter(filters);
  return filterHasDateFilters || filterHasStateFilters || filterHasTvSeriesFilter;
}

function isValidDate(dateString) {
  const date = new Date(dateString);
  return !!date.getDate();
}

function isFromDateBeforeOrSameAsToDate(fromDate, toDate) {
  const momentFromDate = moment(fromDate, VALID_DATE_FORMATS, true);
  const momentToDate = moment(toDate, VALID_DATE_FORMATS, true);
  return momentFromDate.isSameOrBefore(momentToDate, 'day');
}

function getDateValidationErrors(filters) {
  const validationErrors = {};
  const startDateFrom = filters.startDate.from;
  const startDateTo = filters.startDate.to;
  const endDateFrom = filters.endDate.from;
  const endDateTo = filters.endDate.to;

  const startDateFromValid = isValidDate(startDateFrom);
  const startDateToValid = isValidDate(startDateTo);
  const endDateFromValid = isValidDate(endDateFrom);
  const endDateToValid = isValidDate(endDateTo);

  if (startDateFrom && !startDateFromValid) {
    validationErrors.startDateFromInvalid = "Start Date 'From' is not a valid date.";
  }
  if (startDateTo && !startDateToValid) {
    validationErrors.startDateToInvalid = "Start Date 'To' is not a valid date.";
  }
  if (endDateFrom && !endDateFromValid) {
    validationErrors.endDateFromInvalid = "End Date 'From' is not a valid date.";
  }
  if (endDateTo && !endDateToValid) {
    validationErrors.endDateToInvalid = "End Date 'To' is not a valid date.";
  }

  if (startDateFrom && startDateFromValid && startDateTo && startDateToValid) {
    if (!isFromDateBeforeOrSameAsToDate(startDateFrom, startDateTo)) {
      validationErrors.startDateRangeInvalid = "Start Date 'From' must be before of the same as 'To'.";
    }
  }
  if (endDateFrom && endDateFromValid && endDateTo && endDateToValid) {
    if (!isFromDateBeforeOrSameAsToDate(endDateFrom, endDateTo)) {
      validationErrors.endDateRangeInvalid = "End Date 'From' must be before of the same as 'To'.";
    }
  }
  return validationErrors;
}

export function getValidationErrors(filters) {
  let validationErrors = {};

  if (!hasSpecifiedFilters(filters)) {
    validationErrors.noFilters = 'Filters must be specified for the SELECTION export option.';
  }

  const dateValidationErrors = getDateValidationErrors(filters);

  validationErrors = {
    ...validationErrors,
    ...dateValidationErrors,
  };

  return validationErrors;
}
