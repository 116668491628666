export const statuses = {
  NEW: 'NEW',
  UPLOADED: 'UPLOADED',
  CREATING_IN_BRIGHTCOVE: 'CREATING_IN_BRIGHTCOVE',
  CREATING_IN_BRIGHTCOVE_FAILED: 'CREATING_IN_BRIGHTCOVE_FAILED',
  CREATED_IN_BRIGHTCOVE: 'CREATED_IN_BRIGHTCOVE',
  TRANSCODING: 'TRANSCODING',
  TRANSCODED: 'TRANSCODED',
  TRANSCODING_FAILED: 'TRANSCODING_FAILED',
  DELETED: 'DELETED',
  FAILED: 'FAILED',
  COMPLETE: 'COMPLETE',
};

export function isVideoPreviouslyUploaded(status) {
  const noVideoStatuses = [
    statuses.NEW,
    statuses.CREATING_IN_BRIGHTCOVE_FAILED,
    statuses.TRANSCODING_FAILED,
    statuses.DELETED,
  ];
  return status && noVideoStatuses.indexOf(status) === -1;
}
