import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import SeasonForm from 'src/scripts/components/Season/internal/SeasonForm';
import { getSeasonAndAssociatedData } from 'src/scripts/actions/season';

export class SeasonFormLoader extends React.Component {
  componentDidMount() {
    this.props.getSeasonAndAssociatedData(this.props.seasonId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <SeasonForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

SeasonFormLoader.propTypes = {
  seasonId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getSeasonAndAssociatedData: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const seasonForm = state.seasonForm;
  return {
    initialFormDataLoaded: seasonForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSeasonAndAssociatedData: (seasonId) => dispatch(getSeasonAndAssociatedData(seasonId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonFormLoader);
