import _ from 'lodash';
import * as requestHandler from './requestHandler';

const PREFIX = 'api/v1/libraries/tv';

export function createTVSeries(tvSeries) {
  return requestHandler.send('POST', `${PREFIX}/tv-series`, JSON.stringify(tvSeries));
}

export function updateTVSeries(tvSeries) {
  return requestHandler.send('PATCH', `${PREFIX}/tv-series/${tvSeries.id}`, JSON.stringify(tvSeries));
}

export function createSeason(season) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/tv-series/${season.tvSeriesId}/seasons`,
    JSON.stringify(season)
  );
}

export function updateSeason(season) {
  return requestHandler.send('PATCH', `${PREFIX}/seasons/${season.id}`, JSON.stringify(season));
}

export function createSportType(sportType) {
  return requestHandler.send('POST', `${PREFIX}/sport-types`, JSON.stringify(sportType));
}

export function createGenre(genre) {
  return requestHandler.send('POST', `${PREFIX}/genres`, JSON.stringify(genre));
}

export function updateGenre(genre) {
  return requestHandler.send('PATCH', `${PREFIX}/genres/${genre.id}`, JSON.stringify(genre));
}

export function deleteGenre(genreId) {
  return requestHandler.send('DELETE', `${PREFIX}/genres/${genreId}`);
}

export function getGenres(params) {
  return requestHandler.send('GET', `${PREFIX}/genres`, params);
}

export function getTVChannels() {
  return requestHandler.send('GET', `${PREFIX}/channels`);
}

export function getTVSeries(params) {
  return requestHandler.send('GET', `${PREFIX}/tv-series`, params);
}

export function getTVSeriesById(id, params) {
  return requestHandler.send('GET', `${PREFIX}/tv-series/${id}`, params);
}

export function getCountriesOfOrigin() {
  return requestHandler.send('GET', `${PREFIX}/tv-series/countries-of-origin`);
}

export function getContentSegments() {
  return requestHandler.send('GET', `${PREFIX}/tv-series/content-segments`);
}

export function getProductionCompanies() {
  return requestHandler.send('GET', `${PREFIX}/tv-series/production-companies`);
}

export function deleteTVSeries(tvSeriesId) {
  return requestHandler.send('DELETE', `${PREFIX}/tv-series/${tvSeriesId}`);
}

export function getSeasonById(id) {
  return requestHandler.send('GET', `${PREFIX}/seasons/${id}`);
}

export function getSeasons(params) {
  return requestHandler.send('GET', `${PREFIX}/tv-series/${params.tvSeriesId}/seasons`, params);
}

export function getSportTypeById(id) {
  return requestHandler.send('GET', `${PREFIX}/sport-types/${id}`);
}

export function getSportTypes() {
  return requestHandler.send('GET', `${PREFIX}/sport-types`);
}

export function getCollections(params) {
  return requestHandler.send('GET', `${PREFIX}/collections`, params);
}

export function getCollectionById(collectionId, params) {
  return requestHandler.send('GET', `${PREFIX}/collections/${collectionId}`, params);
}

export function createCollection(collection) {
  return requestHandler.send('POST', `${PREFIX}/collections`, JSON.stringify(collection));
}

export function updateCollection(updatedCollection) {
  return requestHandler.send(
    'PATCH',
    `${PREFIX}/collections/${updatedCollection.id}`,
    JSON.stringify(updatedCollection)
  );
}

export function deleteCollectionById(collectionId) {
  return requestHandler.send('DELETE', `${PREFIX}/collections/${collectionId}`);
}

export function publishCollection(collectionId) {
  return requestHandler.send('POST', `${PREFIX}/collections/${collectionId}/publish`);
}

export function deleteSeason(seasonId) {
  return requestHandler.send('DELETE', `${PREFIX}/seasons/${seasonId}`);
}

export function getEpisodes(params) {
  return requestHandler.send(
    'GET',
    `${PREFIX}/seasons/${params.seasonId}/episodes`,
    _.omit(params, 'seasonId')
  );
}

export function getEpisodeById(episodeId) {
  return requestHandler.send('GET', `${PREFIX}/episodes/${episodeId}`);
}

export function getClipById(clipId) {
  return requestHandler.send('GET', `${PREFIX}/clips/${clipId}`);
}

export function getGenreById(genreId) {
  return requestHandler.send('GET', `${PREFIX}/genres/${genreId}`);
}

export function createEpisode(episode) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/seasons/${episode.seasonId}/episodes`,
    JSON.stringify(episode)
  );
}

export function updateEpisode(episode) {
  return requestHandler.send('PATCH', `${PREFIX}/episodes/${episode.id}`, JSON.stringify(episode));
}

export function deleteEpisode(episodeId) {
  return requestHandler.send('DELETE', `${PREFIX}/episodes/${episodeId}`);
}

export function getAllEpisodes(params) {
  return requestHandler.send('GET', `${PREFIX}/episodes`, params);
}

export function rescheduleEpisode(episode) {
  return requestHandler.send('POST', `${PREFIX}/episodes/${episode.id}/rescheduled`, JSON.stringify(episode));
}

export function rescheduleClip(clip) {
  return requestHandler.send('POST', `${PREFIX}/clips/${clip.id}/rescheduled`, JSON.stringify(clip));
}

export function getAllClips(params) {
  return requestHandler.send('GET', `${PREFIX}/clips`, params);
}

export function getAllIngestJobs(params) {
  return requestHandler.send('GET', `${PREFIX}/ingest-jobs`, params);
}

export function getClips(params) {
  return requestHandler.send('GET', `${PREFIX}/seasons/${params.seasonId}/clips`, _.omit(params, 'seasonId'));
}

export function getEpisodeForIngestJob(ingestJobId) {
  return requestHandler.send('GET', `${PREFIX}/ingest-jobs/${ingestJobId}/episode`);
}

export function importEpisodeForIngestJob(ingestJobId, episode) {
  return requestHandler.send('POST', `${PREFIX}/ingest-jobs/${ingestJobId}/import`, JSON.stringify(episode));
}

export function deleteIngestJob(ingestJobId) {
  return requestHandler.send('DELETE', `${PREFIX}/ingest-jobs/${ingestJobId}`);
}

export function createClip(clip) {
  return requestHandler.send('POST', `${PREFIX}/seasons/${clip.seasonId}/clips`, JSON.stringify(clip));
}

export function updateClip(clip) {
  return requestHandler.send('PATCH', `${PREFIX}/clips/${clip.id}`, JSON.stringify(clip));
}

export function deleteClip(clipId) {
  return requestHandler.send('DELETE', `${PREFIX}/clips/${clipId}`);
}

export function notifyEpisodeUploadFailed(id) {
  return requestHandler.send('POST', `${PREFIX}/episodes/${id}/uploadFailed`, JSON.stringify({}));
}

export function notifyClipUploadFailed(id) {
  return requestHandler.send('POST', `${PREFIX}/clips/${id}/uploadFailed`, JSON.stringify({}));
}

export function notifyWorkflowAction(action, resource, id) {
  return requestHandler.send('POST', `${PREFIX}/${resource}s/${id}/${action}`, JSON.stringify({}));
}

export function notifyGenerateVideoPreviewImages(episode) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/episodes/${episode.id}/generateVideoPreviewImages`,
    JSON.stringify({ episode })
  );
}

export function getPhoenixEpisodeDataByMaterialKey(materialKey) {
  return requestHandler.send('GET', `${PREFIX}/phoenix/episode-data/${materialKey}`);
}

export function updateChannel(id, data) {
  return requestHandler.send('PATCH', `${PREFIX}/channels/${id}/`, JSON.stringify({ ...data }));
}
