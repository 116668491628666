/* eslint-disable */

export default [
  'Victoria',
  'Queensland',
  'New South Wales',
  'South Australia',
  'Northern Territory',
  'Australian Capital Territory',
  'Western Australia',
  'Tasmania',
  'Border North East',
  'Canberra',
  'Central Queensland',
  'Central Victoria',
  'NSW Central West',
  'Darling Downs',
  'Far North Queensland',
  'Gippsland',
  'Gold Coast',
  'Illawarra',
  'Mackay',
  'North Queensland',
  'Riverina',
  'Sunshine Coast',
  'Western Victoria',
  'Wide Bay',
];
