import PropTypes from 'prop-types';
import React from 'react';
import ModalItem from 'src/scripts/components/ModalItem';

class RescheduleModal extends React.Component {
  render() {
    return (
      <ModalItem
        id={this.props.id}
        title="Reschedule"
        component={this.props.isButton ? 'span' : ''}
        className={this.props.isButton ? this.props.buttonClass : ''}
        form={React.cloneElement(this.props.form, { isReschedule: true })}
      />
    );
  }
}

RescheduleModal.defaultProps = {
  buttonClass: 'btn btn-default',
};

RescheduleModal.propTypes = {
  id: PropTypes.string,
  isButton: PropTypes.bool,
  form: PropTypes.object,
  buttonClass: PropTypes.string,
};

export default RescheduleModal;
