import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import ViewLoader from 'src/scripts/components/ViewLoader';
import EditCollectionForm from 'src/scripts/components/Collection/internal/EditCollectionForm';
import { getCollectionById } from 'src/scripts/actions/collection';

const mapStateToProps = (state) => ({
  collection: state.collectionForm.collection,
});

const mapDispatchToProps = (dispatch) => ({
  getCollectionById: (collectionId, params) => dispatch(getCollectionById(collectionId, params)),
});

export const COLLECTION_PARAMS = {
  includeTvSeriesStates: true,
  includeRecommendingTvSeriesStates: true,
};

export class CollectionFormLoader extends Component {
  componentDidMount() {
    this.props.getCollectionById(this.props.collectionId, COLLECTION_PARAMS);
  }

  isLoading = () => _.isEmpty(this.props.collection);

  render() {
    return (
      <ViewLoader loading={this.isLoading()}>
        <EditCollectionForm close={this.props.close} afterUpdate={this.props.afterUpdate} />
      </ViewLoader>
    );
  }
}

CollectionFormLoader.propTypes = {
  collectionId: PropTypes.number.isRequired,
  collection: PropTypes.object,
  getCollectionById: PropTypes.func,
  afterUpdate: PropTypes.func.isRequired,
  close: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionFormLoader);
