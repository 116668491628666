import { UPDATE_VIDEO_DURATION, REGISTER_VIDEO_PLAYER } from '../actions/videoPlayer';

export const initialState = {
  duration: 0,
  player: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_VIDEO_PLAYER:
      return { ...state, player: action.player };

    case UPDATE_VIDEO_DURATION:
      return { ...state, duration: action.duration };

    default:
      return state;
  }
}
