import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'src/scripts/lib/history';
import { resetView } from 'src/scripts/actions/mediaItemDetailsView';
import { getEpisodeForIngestJob } from 'src/scripts/actions/ingestJobs';
import ViewLoader from 'src/scripts/components/ViewLoader';
import IngestJobDetails from 'src/scripts/components/IngestJob/internal/IngestJobDetails';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class IngestJobDetailsViewLoader extends React.Component {
  componentDidMount() {
    this.props.resetView();
    this.props.getEpisodeForIngestJob(Number(this.props.params.ingestJobId));
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.ingestJobDetailsView.ingestJob && this.props.ingestJobDetailsView.ingestJob) {
      this.navigateToList();
      return;
    }
  }

  navigateToList() {
    history.replace({ state: null, pathname: `/${TV_PATH}/videos/ingestJobs`, query: { page: 1 } });
  }

  render() {
    return (
      <ViewLoader
        loading={
          !this.props.ingestJobDetailsView.ingestJob ||
          Number(this.props.params.ingestJobId) !== this.props.ingestJobDetailsView.ingestJob.id
        }
        errorMessage={this.props.ingestJobDetailsView.errorMessage}
      >
        <IngestJobDetails
          ingestJob={this.props.ingestJobDetailsView.ingestJob}
          episode={this.props.ingestJobDetailsView.episode}
          xmlSource={this.props.ingestJobDetailsView.xmlSource}
        />
      </ViewLoader>
    );
  }
}

IngestJobDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  resetView: PropTypes.func.isRequired,
  getEpisodeForIngestJob: PropTypes.func.isRequired,
  ingestJobDetailsView: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ingestJobDetailsView: state.ingestJobs.detailsView,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetView: () => dispatch(resetView()),
    getEpisodeForIngestJob: (ingestJobId) => dispatch(getEpisodeForIngestJob(ingestJobId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestJobDetailsViewLoader);
