import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FormErrorMessage extends Component {
  render() {
    const defaultClassName = 'alert alert-danger mi9-form-error-message';
    return (
      <div ref="errorMessage" className={`${defaultClassName} ${this.props.className}`}>
        {this.props.message}
      </div>
    );
  }
}

FormErrorMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};

FormErrorMessage.defaultProps = {
  className: '',
};

export default FormErrorMessage;
