import PropTypes from 'prop-types';
import React from 'react';
import { Button, Well, Panel } from 'react-bootstrap';
import moment from 'moment';
import ClassificationInputForm from './ClassificationInputForm';

class ClassificationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classifications: this.props.classifications,
      currentClassificationIndex: null,
    };
    this.updateClassifications = this.updateClassifications.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      classifications: nextProps.classifications,
    });
  }

  getClassifications() {
    return this.state.classifications;
  }

  updateClassifications(updatedClassifications) {
    this.setState({
      classifications: updatedClassifications,
      currentClassificationIndex: null,
    });
    this.props.onChange(updatedClassifications);
  }

  edit(index) {
    this.setState({
      currentClassificationIndex: index,
    });
  }

  cancelEdit() {
    this.setState({
      currentClassificationIndex: null,
    });
  }

  renderItems() {
    return (
      <Well ref="classificationsContainer" className="classifications-container">
        {this.state.classifications.map((classification, index) => {
          const edit = () => {
            this.edit(index);
          };

          const startDate = moment(classification.startDate).format('ll');
          const endDate = classification.endDate ? moment(classification.endDate).format('ll') : 'Ongoing';

          return (
            <div
              className={`classification-item${
                this.state.currentClassificationIndex === index ? ' classification-edit' : ''
              }`}
            >
              <span className="classification-row-number">{index + 1}</span>
              <div id={`classification-${index}`} className="classification-information">
                <div>
                  <span>{classification.type}</span>
                  <span className="classification-dates float-right">{`${startDate} - ${endDate}`}</span>
                </div>
                <div>
                  <span>Digital Exclusive - {classification.digitalExclusive ? 'Yes' : 'No'}</span>
                  <span className="float-right">
                    Incremental Spend - {classification.incrementalSpend ? 'Yes' : 'No'}
                  </span>
                </div>
              </div>
              <span className="classification-edit-button">
                <Button ref={`editClassificationButton${index}`} bsStyle="link" onClick={edit}>
                  <i className="fa fa-edit"></i>
                </Button>
              </span>
            </div>
          );
        })}
      </Well>
    );
  }

  render() {
    return (
      <div>
        <Panel header={this.props.title} collapsible defaultExpanded>
          {this.renderItems()}
        </Panel>

        <Panel
          header="Add Classification"
          id="add-classification-form"
          className={`add-classification-form ${
            this.state.currentClassificationIndex !== null ? 'hidden' : ''
          }`}
        >
          <div>
            <ClassificationInputForm
              ref="addClassificationForm"
              classifications={this.state.classifications}
              seasonCreatedAt={this.props.seasonCreatedAt}
              updateClassifications={this.updateClassifications}
            />
          </div>
        </Panel>

        <Panel
          header="Edit Classification"
          id="edit-classification-form"
          className={`edit-classification-form ${
            this.state.currentClassificationIndex === null ? 'hidden' : ''
          }`}
        >
          <div>
            <ClassificationInputForm
              ref="editClassificationForm"
              currentClassificationIndex={this.state.currentClassificationIndex}
              classifications={this.state.classifications}
              seasonCreatedAt={this.props.seasonCreatedAt}
              updateClassifications={this.updateClassifications}
              cancel={this.cancelEdit}
            />
          </div>
        </Panel>
      </div>
    );
  }
}

ClassificationSection.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  classifications: PropTypes.array,
  seasonCreatedAt: PropTypes.string,
};
export default ClassificationSection;
