import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Input } from 'react-bootstrap';
import ChannelSelect from 'src/scripts/components/Youtube/ChannelSelect';
import PolicySelect from 'src/scripts/components/Youtube/PolicySelect';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

export default class ContentIdSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      useSeasonDefaults: props.episodeIsUsingSeasonDefaults,
      hasExcludeTimes: props.episode.contentIdExcludeTimes && props.episode.contentIdExcludeTimes.length > 0,
      excludeTimeInputValidationActive: false,
      excludeTimeInput: {
        from: '',
        to: '',
      },
    };
    this.onUploadToYoutubeUpdate = this.onUploadToYoutubeUpdate.bind(this);
    this.onContentIdChannelUpdate = this.onContentIdChannelUpdate.bind(this);
    this.onContentIdUsagePolicyUpdate = this.onContentIdUsagePolicyUpdate.bind(this);
    this.onContentIdMatchPolicyUpdate = this.onContentIdMatchPolicyUpdate.bind(this);
    this.getFormGroupClassName = this.getFormGroupClassName.bind(this);
    this.renderContentIdOptions = this.renderContentIdOptions.bind(this);
    this.getExcludeTimeValidationError = this.getExcludeTimeValidationError.bind(this);
    this.onAddExcludeTime = this.onAddExcludeTime.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.onUseSeasonDefaultPolicies = this.onUseSeasonDefaultPolicies.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.episode.seasonId && this.props.episode.seasonId !== nextProps.episode.seasonId) {
      const newSeason = _.find(this.props.seasons, (season) => season.id === nextProps.episode.seasonId);
      this.onNewSeasonSelected(newSeason);
    }
  }

  onUploadToYoutubeUpdate(event) {
    this.props.updateEpisode({ uploadToYoutube: event.target.checked });
  }

  onContentIdChannelUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateEpisode({ contentIdChannel: updateValue });
  }

  onContentIdUsagePolicyUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateEpisode({ contentIdUsagePolicy: updateValue });
  }

  onContentIdMatchPolicyUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateEpisode({ contentIdMatchPolicy: updateValue });
  }

  onUseSeasonDefaultPolicies() {
    this.setState({ useSeasonDefaults: true });
    this.props.updateEpisode({
      contentIdChannel: null,
      contentIdUsagePolicy: null,
      contentIdMatchPolicy: null,
    });
  }

  onNewSeasonSelected(newSeason) {
    const newSeasonHasContentIdDefaults = newSeason.defaultContentIdChannel;
    const emptyContentIdFields = {
      contentIdChannel: null,
      contentIdMatchPolicy: null,
      contentIdUsagePolicy: null,
      contentIdExcludeTimes: [],
    };
    if (newSeasonHasContentIdDefaults) {
      this.props.updateEpisode({
        ...emptyContentIdFields,
        uploadToYoutube: true,
      });
      this.setState({ useSeasonDefaults: true });
    } else {
      this.props.updateEpisode({
        ...emptyContentIdFields,
        uploadToYoutube: false,
      });
      this.setState({ useSeasonDefaults: false });
    }
  }

  onAddExcludeTime() {
    const validationError = this.getExcludeTimeValidationError();
    if (validationError) {
      this.setState({ excludeTimeInputValidationActive: true });
    } else {
      this.props.updateEpisode({
        contentIdExcludeTimes: [
          { ...this.state.excludeTimeInput },
          ...(this.props.episode.contentIdExcludeTimes || []),
        ],
      });
      this.setState({
        excludeTimeInputValidationActive: false,
        excludeTimeInput: { from: '', to: '' },
      });
    }
  }

  onRemoveExcludeTime(index) {
    this.props.episode.contentIdExcludeTimes.splice(index, 1);
    this.props.updateEpisode({
      contentIdExcludeTimes: [...this.props.episode.contentIdExcludeTimes],
    });
  }

  getLabelClassName() {
    if (
      !this.state.useSeasonDefaults ||
      this.props.episode.contentIdChannel ||
      this.props.episode.contentIdUsagePolicy ||
      this.props.episode.contentIdMatchPolicy
    )
      return 'control-label required';
    return 'control-label';
  }

  getFormGroupClassName(fieldName) {
    return `form-group ${this.props.validationErrors[fieldName] ? 'error' : null}`;
  }

  getExcludeTimeValidationError() {
    const { from, to } = this.state.excludeTimeInput;
    if (!from) return '"From" value must be provided.';
    if (!to) return '"To" value must be provided.';
    const validFormatRegExp = RegExp('^([0-9]*[0-9]*[0-9]):([0-5][0-9])$');
    const [fromRegExpMatch, fromMinutesString, fromSecondsString] = validFormatRegExp.exec(from) || [
      undefined,
      undefined,
      undefined,
    ];
    if (!fromRegExpMatch) return '"From" value is in an invalid format, please change.';
    const [toRegExpMatch, toMinutesString, toSecondsString] = validFormatRegExp.exec(to) || [
      undefined,
      undefined,
      undefined,
    ];
    if (!toRegExpMatch) return '"To" value is in an invalid format, please change.';
    const fromDurationInSeconds = moment({
      minutes: parseInt(fromMinutesString, 10),
      seconds: parseInt(fromSecondsString, 10),
    }).valueOf();
    const toDurationInSeconds = moment({
      minutes: parseInt(toMinutesString, 10),
      seconds: parseInt(toSecondsString, 10),
    }).valueOf();
    if (toDurationInSeconds <= fromDurationInSeconds) return '"To" value must be after "From" value.';
    return null;
  }

  seasonHasDefaults() {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.episode.seasonId);
    if (selectedSeason && selectedSeason.defaultContentIdChannel) return true;
    return false;
  }

  renderExcludeTimeInputs() {
    if (this.state.hasExcludeTimes) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              width: '100%',
            }}
          >
            <Input
              ref="excludeFromInput"
              type="text"
              label="Exclude from:"
              placeholder="mm:ss"
              value={this.state.excludeTimeInput.from}
              onChange={(event) =>
                this.setState({
                  excludeTimeInput: {
                    ...this.state.excludeTimeInput,
                    from: event.target.value,
                  },
                })
              }
            />
            <Input
              ref="excludeToInput"
              type="text"
              label="Exclude to:"
              placeholder="mm:ss"
              value={this.state.excludeTimeInput.to}
              onChange={(event) =>
                this.setState({
                  excludeTimeInput: {
                    ...this.state.excludeTimeInput,
                    to: event.target.value,
                  },
                })
              }
            />
            <div className="form-group">
              <button
                ref="addExcludeTimeButton"
                onClick={(e) => {
                  e.preventDefault();
                  this.onAddExcludeTime();
                }}
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
              >
                <i className="fa fa-plus-circle" style={{ color: 'green', fontSize: '34px' }} />
              </button>
            </div>
          </div>
          {this.state.excludeTimeInputValidationActive && this.getExcludeTimeValidationError() ? (
            <FormErrorMessage
              ref="excludeTimeFormErrorMessage"
              message={this.getExcludeTimeValidationError()}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }

  renderExcludeTimes() {
    if (this.state.hasExcludeTimes && this.props.episode.contentIdExcludeTimes) {
      return (
        <div>
          {this.props.episode.contentIdExcludeTimes.map((contentIdExcludeTime, index) => {
            return (
              <div
                className="content-id-exclude-item"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div>{contentIdExcludeTime.from}</div>
                <div>{contentIdExcludeTime.to}</div>
                <div className="form-group">
                  <button
                    className="remove-exclude-time-button"
                    onClick={(e) => {
                      e.preventDefault();
                      this.onRemoveExcludeTime(index);
                    }}
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      outline: 'none',
                    }}
                  >
                    <i className="fa fa-minus-circle" style={{ color: 'red', fontSize: '34px' }} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  renderContentIdOptions() {
    if (this.props.episode.uploadToYoutube) {
      return (
        <div ref="contentIdOptions" style={{ paddingLeft: '35px' }}>
          <Input
            type="radio"
            ref="useSeasonDefaultRadio"
            label="Use default season policies"
            checked={this.state.useSeasonDefaults}
            onClick={this.onUseSeasonDefaultPolicies}
            disabled={!this.seasonHasDefaults()}
          />
          <Input
            type="radio"
            ref="setEpisodePoliciesRadio"
            label="Set episode policies"
            checked={!this.state.useSeasonDefaults}
            onClick={() => this.setState({ useSeasonDefaults: false })}
            disabled={!this.props.episode.uploadToYoutube}
          />
          {this.props.episode.uploadToYoutube && !this.state.useSeasonDefaults ? (
            <div>
              <div className={this.getFormGroupClassName('contentIdChannel')}>
                <label className={this.getLabelClassName()}>Channel</label>
                <ChannelSelect
                  ref="channelSelect"
                  placeholder="Select a channel"
                  value={this.props.episode.contentIdChannel}
                  onChange={this.onContentIdChannelUpdate}
                  disabled={!this.props.episode.uploadToYoutube}
                />
              </div>
              <div className={this.getFormGroupClassName('contentIdUsagePolicy')}>
                <label className={this.getLabelClassName()}>Usage Policy</label>
                <PolicySelect
                  ref="usagePolicySelect"
                  placeholder="Select a usage policy"
                  value={this.props.episode.contentIdUsagePolicy}
                  onChange={this.onContentIdUsagePolicyUpdate}
                  disabled={!this.props.episode.uploadToYoutube}
                />
              </div>
              <div className={this.getFormGroupClassName('contentIdMatchPolicy')}>
                <label className={this.getLabelClassName()}>Match Policy</label>
                <PolicySelect
                  ref="matchPolicySelect"
                  placeholder="Select a match policy"
                  value={this.props.episode.contentIdMatchPolicy}
                  onChange={this.onContentIdMatchPolicyUpdate}
                  disabled={!this.props.episode.uploadToYoutube}
                />
              </div>
            </div>
          ) : null}
          <label>Policy Exclusions</label>
          <Input
            ref="policyExclusionCheckbox"
            type="checkbox"
            label="Set policy exclusions"
            checked={this.state.hasExcludeTimes}
            onChange={(event) => {
              this.setState({ hasExcludeTimes: event.target.checked });
            }}
            disabled={!this.props.episode.uploadToYoutube}
          />
          {this.renderExcludeTimeInputs()}
          {this.renderExcludeTimes()}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div>
        <Input
          ref="uploadToYoutubeCheckbox"
          type="checkbox"
          label="Upload episode to YouTube for Content ID"
          checked={this.props.episode.uploadToYoutube}
          onChange={this.onUploadToYoutubeUpdate}
          disabled={!this.props.episode.seasonId}
        />
        {this.renderContentIdOptions()}
      </div>
    );
  }
}

ContentIdSection.propTypes = {
  episode: PropTypes.object,
  updateEpisode: PropTypes.func,
  validationErrors: PropTypes.object,
  seasons: PropTypes.array,
  episodeIsUsingSeasonDefaults: PropTypes.boolean,
};
