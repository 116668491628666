import { REAL_TIME_VMS_UPDATED } from 'src/scripts/actions/realTimeNotification';

export const initialState = {
  updateVms: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REAL_TIME_VMS_UPDATED:
      return {
        ...state,
        updateVms: true,
      };
    default:
      return state;
  }
}
