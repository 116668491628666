import {
  getMediaLiveChannels,
  setMediaLiveChannelStreamInput,
  setMediaLivePowerState,
  setTouchStreamAlertState,
  sendRestartPacketForYoSpaceCSM,
  setBugForMediaLiveChannel,
  getMediaLiveChannelMappings,
  getAvailableMediaLiveBugs,
} from './apiWrappers/vmsApi/common/liveEvents.js';

export function listMediaLiveChannels(params) {
  return getMediaLiveChannels(params);
}

export function listAvailableMediaLiveBugs() {
  return getAvailableMediaLiveBugs();
}

export async function setMediaLiveChannelInputStream(streamInputName, channelId, reason) {
  const togglePayload = {
    streamInputName,
    channelId,
    reason,
  };

  return await setMediaLiveChannelStreamInput(togglePayload);
}

/**
 * @param {string} channelId - The id of the media live channel.
 * @param {boolean} state - Whether to turn the media live channel on, or off.
 * @param {string} reason - Why we want to toggle the channel.
 */
export async function toggleMediaLiveChannel(channelId, state, reason) {
  const togglePayload = {
    channelId,
    state,
    reason,
  };

  return await setMediaLivePowerState(togglePayload);
}

/**
 * @param {string} channelId - The id of the media live channel.
 * @param {boolean} state - Whether to turn the touchstream alerts on, or off.
 * @param {string} reason - Why we want to toggle the channel.
 */
export async function toggleTouchStreamAlerts(streamKeys, state, reason) {
  const togglePayload = {
    state,
    reason,
  };

  const urlsToToggle = [];
  for (const streamKey of streamKeys) {
    urlsToToggle.push(setTouchStreamAlertState({ ...togglePayload, streamKey }));
  }

  return await Promise.all(urlsToToggle);
}

/**
 * @param {string} channelId - The id of the media live channel.
 * @param {string} reason - Why we want to toggle the channel.
 */
export async function restartYoSpaceCSM(channelId, reason) {
  const togglePayload = {
    channelId,
    reason,
  };

  return await sendRestartPacketForYoSpaceCSM(togglePayload);
}

/**
 * @param {string} channelId - The id of the media live channel.
 * @param {string} bug - The bug we want to enable.
 * @param {string} reason - Why we want to toggle the channel.
 */
export async function enableBugForMediaLiveChannel(channelId, bug, reason) {
  const togglePayload = {
    channelId,
    bug,
    reason,
    state: true,
  };

  return await setBugForMediaLiveChannel(togglePayload);
}

/**
 * @param {string} channelId - The id of the media live channel.
 * @param {string} reason - Why we want to toggle the channel.
 */
export async function disableBugForMediaLiveChannel(channelId, reason) {
  const togglePayload = {
    channelId,
    reason,
    state: false,
  };

  return await setBugForMediaLiveChannel(togglePayload);
}

export function getAllLiveStreamMappings() {
  return getMediaLiveChannelMappings();
}
