import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';

import { startUpload } from 'src/scripts/actions/video';
import * as pagination from 'src/scripts/lib/pagination';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { closeModal } from 'src/scripts/actions/modalItem';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';

export const GET_CLIPS_SUCCEEDED = 'GET_CLIPS_SUCCEEDED';
export const GET_CLIPS_FAILED = 'GET_CLIPS_FAILED';
export const GET_ALL_CLIPS_SUCCEEDED = 'GET_ALL_CLIPS_SUCCEEDED';
export const GET_ALL_CLIPS_FAILED = 'GET_ALL_CLIPS_FAILED';
export const GET_CLIP_SUCCEEDED = 'GET_CLIP_SUCCEEDED';
export const GET_CLIP_FAILED = 'GET_CLIP_FAILED';

export const SAVE_CLIP_STARTED = 'SAVE_CLIP_STARTED';
export const SAVE_CLIP_FAILED = 'SAVE_CLIP_FAILED';
export const SAVE_CLIP_SUCCEEDED = 'SAVE_CLIP_SUCCEEDED';
export const GET_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED = 'GET_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED';
export const GET_CLIP_AND_ASSOCIATED_DATA_FAILED = 'GET_CLIP_AND_ASSOCIATED_DATA_FAILED';
export const CLIP_UPDATED = 'CLIP_UPDATED';
export const DELETE_CLIP_SUCCEEDED = 'DELETE_CLIP_SUCCEEDED';
export const SHOW_CLIP_AUTOACTIVATE_CONFIRMATION = 'SHOW_CLIP_AUTOACTIVATE_CONFIRMATION';
export const HIDE_CLIP_AUTOACTIVATE_CONFIRMATION = 'HIDE_CLIP_AUTOACTIVATE_CONFIRMATION';
export const GET_CLIP_FORM_INITIAL_DATA_SUCCEEDED = 'GET_CLIP_FORM_INITIAL_DATA_SUCCEEDED';

export function showAutoActivateConfirmation() {
  return {
    type: SHOW_CLIP_AUTOACTIVATE_CONFIRMATION,
  };
}

export function hideAutoActivateConfirmation() {
  return {
    type: HIDE_CLIP_AUTOACTIVATE_CONFIRMATION,
  };
}

export function get(params) {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit ? params.limit : pagination.pageSize;
  const sort = params.sort ? params.sort : undefined;
  const request = {
    seasonId: params.seasonId,
    offset,
    limit,
    sort,
    ...params.filters,
  };
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CLIPS_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_CLIPS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getClips(request).then(onSuccess, onError);
  };
}

export function getAllClips(params = {}) {
  return (dispatch) => {
    const offset = params.offset || 0;
    const limit = params.limit || pagination.pageSize;
    const sort = params.sort || '-updatedAt';
    const search = params.search ? params.search : null;
    const request = {
      offset,
      limit,
      sort,
      search,
      ...params.filters,
    };

    const onSuccess = (data) => {
      dispatch({
        type: GET_ALL_CLIPS_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ALL_CLIPS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getAllClips(request).then(onSuccess, onError);
  };
}

export function saveClip(clip) {
  return (dispatch) => {
    const saveMethod = clip.id ? tvVmsApi.updateClip : tvVmsApi.createClip;
    dispatch({
      type: SAVE_CLIP_STARTED,
    });
    return saveMethod(clip)
      .done((createdClipResponse) => {
        dispatch({
          type: SAVE_CLIP_SUCCEEDED,
          data: createdClipResponse,
        });

        if (createdClipResponse.clip.videoId && clip.video.file) {
          const onUploadFailed = () => {
            tvVmsApi.notifyClipUploadFailed(createdClipResponse.clip.id);
          };
          dispatch(
            startUpload(
              VIDEOS.TV,
              {
                videoId: createdClipResponse.clip.videoId,
                file: clip.video.file,
                uploadType: 'video',
              },
              onUploadFailed
            )
          );

          tvVmsApi.notifyWorkflowAction('beforeUploadStarted', 'clip', createdClipResponse.clip.id);
        }
        dispatch(closeModal());
      })
      .fail((response) => {
        dispatch({
          type: SAVE_CLIP_FAILED,
          error: response.responseJSON.message,
        });
      });
  };
}

export function updateClip(clip) {
  return {
    type: CLIP_UPDATED,
    clip,
  };
}

export function getClipAndAssociatedData(clipId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED,
        data,
      });
    };
    const onError = (error) => {
      dispatch({
        type: GET_CLIP_AND_ASSOCIATED_DATA_FAILED,
        error: error.responseJSON.message,
      });
    };
    return tvVmsApi
      .getClipById(clipId)
      .then((data) => {
        return tvVmsApi
          .getSeasons({ tvSeriesId: data.clip.partOfSeries.id })
          .then(({ seasons }) => ({ ...data, seasons }));
      })
      .then((data) => {
        return tvVmsApi
          .getEpisodes({ seasonId: data.clip.partOfSeason.id })
          .then(({ episodes }) => ({ ...data, episodes }));
      })
      .then((data) => {
        if (data.clip.video.status === 'NEW') return data;
        return videosVmsApi.getSignedVideoSourceUrl(VIDEOS.TV, data.clip.video.id).then((res) => {
          data.clip.video.signedS3Url = res.url;
          return data;
        });
      })
      .then(onSuccess, onError);
  };
}

export function deleteClipAndCloseConfirmation(clipId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_CLIP_SUCCEEDED,
        id: clipId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteClip(clipId).then(onSuccess, onError);
  };
}

export const getClip = (clipId) => (dispatch) => {
  const onSuccess = (data) => {
    dispatch({
      type: GET_CLIP_SUCCEEDED,
      data,
    });
  };

  const onError = (response) => {
    dispatch({
      type: GET_CLIP_FAILED,
      error: response.responseJSON.message,
    });
  };

  return tvVmsApi.getClipById(clipId).then(onSuccess, onError);
};

export function rescheduleAndSaveClip(clip) {
  return (dispatch) => {
    dispatch({ type: SAVE_CLIP_STARTED });
    const onSuccess = (createdClipResponse) => {
      dispatch({
        type: SAVE_CLIP_SUCCEEDED,
        data: createdClipResponse,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_CLIP_FAILED,
        error: response.responseJSON.message,
      });
    };
    tvVmsApi.rescheduleClip(clip).then(onSuccess, onError);
  };
}

export function getClipFormInitialData(clipId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: GET_CLIP_FORM_INITIAL_DATA_SUCCEEDED,
      });
    };
    if (clipId) {
      return dispatch(getClipAndAssociatedData(clipId)).then(onSuccess);
    }
    return dispatch(onSuccess);
  };
}
