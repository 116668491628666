import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import Navigation from 'src/scripts/components/Navigation';
import Login from 'src/scripts/components/Login';
import { processRealTimeNotification } from 'src/scripts/actions/realTimeNotification';
import * as browser from 'src/scripts/lib/browser';
import environment from 'config/environment';
import * as realTimeNotification from 'src/scripts/lib/realTimeNotification';
import ConfirmationModal from 'src/scripts/components/ConfirmationModal';
import EventNotificationsSystem from 'src/scripts/components/EventNotificationSystem';
import { IN_PROGRESS } from 'src/scripts/reducers/bulkActions';

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
  }

  componentWillMount() {
    realTimeNotification.connect();
  }

  componentDidMount() {
    browser.addWindowBeforeUnloadHandler(this.onBeforeUnload);
    realTimeNotification.subscribe(this.props.processRealTimeNotification);
  }

  componentWillUnmount() {
    browser.removeWindowBeforeUnloadHandler(this.onBeforeUnload);
    realTimeNotification.disconnect();
  }

  onBeforeUnload(event) {
    if (this.props.currentlyUploadingVideos > 0) {
      const confirmationMessage =
        'There are currently video uploads in progress, if you continue they will be lost';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    } else if (this.props.bulkActionStatus === IN_PROGRESS) {
      const confirmationMessage =
        'There is currently a bulk action in progress, if you continue the action will be cancelled and any remaining actions will not be processed. Are you sure you want to continue?';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
    return undefined;
  }

  getTheme() {
    return environment.uiTheme ? environment.uiTheme : '';
  }

  reloadPage() {
    setTimeout(window.location.reload.bind(window.location), 250);
  }

  render() {
    const isLoggedIn = !!this.props.accessToken;
    let RenderedComponent;
    if (isLoggedIn) {
      RenderedComponent = this.props.children;
    } else {
      RenderedComponent = <Login />;
    }

    const version = `${environment.version.major}.${environment.version.buildCounter}.${environment.version.patch}+${environment.version.gitCommit}`;
    return (
      <div className={this.props.updateVms ? `${this.getTheme()} updateVMS` : this.getTheme()}>
        {this.props.updateVms ? (
          <div className="status">
            <Alert bsStyle="warning">
              A new version of VMS is available! If you don't have any uploads in progress,{' '}
              <a onClick={this.reloadPage}> refresh your browser </a> at your earliest convenience for the
              latest changes to take effect
            </Alert>
          </div>
        ) : null}
        <header>
          <Navigation
            location={this.props.location}
            isLoggedIn={isLoggedIn}
            loggedInUser={this.props.loggedInUser}
          />
        </header>
        <div className="container-fluid">
          {RenderedComponent}
          <ConfirmationModal />
        </div>
        {isLoggedIn && (
          <footer className="footer">
            <div className="container-fluid">
              <p className="pull-left" ref="version">
                Version: {version}
              </p>
            </div>
          </footer>
        )}
        <EventNotificationsSystem />
      </div>
    );
  }
}

App.propTypes = {
  accessToken: PropTypes.string,
  loggedInUser: PropTypes.object,
  currentlyUploadingVideos: PropTypes.number,
  location: PropTypes.object,
  children: PropTypes.object,
  processRealTimeNotification: PropTypes.func,
  updateVms: PropTypes.bool,
  bulkActionStatus: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    accessToken: state.login.accessToken,
    loggedInUser: state.login.loggedInUser,
    currentlyUploadingVideos: state.video.currentlyUploadingVideos,
    updateVms: state.app.updateVms,
    bulkActionStatus: state.bulkActions.bulkActionStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    processRealTimeNotification: (message) => dispatch(processRealTimeNotification(message)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
