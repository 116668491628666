import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, MenuItem, ButtonToolbar, Alert } from 'react-bootstrap';
import ModalItem from 'src/scripts/components/ModalItem';
import { statuses } from 'src/scripts/components/Video/videoStatus';
import ArchiveModal from 'src/scripts/components/MediaItem/ArchiveModal';
import RescheduleModal from 'src/scripts/components/MediaItem/RescheduleModal';
import ExpireModal from 'src/scripts/components/MediaItem/ExpireModal';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import { isItemInBulkActionQueue } from 'src/scripts/lib/bulkWorkflowActionsProvider';

export class WorkflowActions extends React.Component {
  render() {
    const modalClassName = this.props.isButton ? 'btn btn-default' : '';
    const component = this.props.isButton ? 'span' : '';
    const indexItem = this.props.index !== undefined ? `-${this.props.index}` : '';
    const getWorkflowActionMenuButton = (action, actionName) => {
      switch (actionName) {
        case 'View':
          return this.props.viewLink ? (
            <MenuItem
              ref="view"
              key={'view'}
              href={this.props.viewLink}
              id={`button-view-${this.props.resource}${indexItem}`}
            >
              View
            </MenuItem>
          ) : (
            ''
          );
        case 'Edit':
          return (
            <ModalItem
              id={`button-edit-${this.props.resource}${indexItem}`}
              ref="edit"
              key={'edit'}
              component={component}
              className={modalClassName}
              title="Edit"
              form={this.props.editForm}
            />
          );
        case 'Delete':
          return (
            <ModalItem
              ref="delete"
              key={'delete'}
              component={component}
              title="Delete"
              className={modalClassName}
              id={`button-delete-${this.props.resource}${indexItem}`}
              form={
                <ConfirmationDialog
                  invitation="Clicking Delete will permanently delete this entry and any associated video and image files. Are you sure you wish to proceed?"
                  ok={this.props.delete}
                  buttonClass="danger"
                  buttonLabel="Delete"
                />
              }
            />
          );
        case 'Archive':
          return (
            <ArchiveModal
              mediaItem={this.props.model}
              action={() => this.props.dispatch(action())}
              key={'archive'}
              isButton={this.props.isButton}
            />
          );
        case 'Reschedule_and_save':
          return (
            <RescheduleModal
              form={this.props.editForm}
              key={'reschedule'}
              isButton={this.props.isButton}
              id={`button-reschedule-${this.props.resource}${indexItem}`}
            />
          );
        case 'Expire':
          return (
            <ExpireModal
              key={'expire'}
              model={this.props.model}
              action={() => this.props.dispatch(action())}
              isButton={this.props.isButton}
            />
          );
        default:
          const Item = this.props.isButton ? Button : MenuItem;
          return (
            <Item
              key={`${actionName}`}
              className={`workflow-menu-item ${this.props.disableWorkflow === true ? 'disabled' : ''}`}
              ref="approveAction"
              onClick={() => {
                this.props.dispatch(action());
              }}
            >
              {actionName}
            </Item>
          );
      }
    };
    const model = {
      ...this.props.model,
    };
    if (
      this.props.model.state === 'VIDEO_PROCESSING' &&
      this.props.model.video.status === statuses.NEW &&
      !this.props.videoCurrentlyUploading
    ) {
      model.state = 'BLOCKED_IN_VIDEO_PROCESSING';
    }
    const workflowConfiguration = {
      className: `btn btn-default ${this.props.disableWorkflow === true ? 'disabled' : ''}`,
      [this.props.resource]: model,
    };
    const workflowActions = this.props
      .getWorkflowActions(workflowConfiguration)
      .map((action) => getWorkflowActionMenuButton(action.action, action.actionName));
    if (this.props.isButton) {
      const bulkActions = this.props.bulkActions;
      if (
        isItemInBulkActionQueue(
          bulkActions.bulkActionStatus,
          bulkActions.actionType,
          bulkActions.modelName,
          bulkActions.unprocessed,
          this.props.resource,
          this.props.model
        )
      ) {
        return (
          <Alert bsStyle="danger" className="button-toolbar-alert">
            <strong>Note: </strong>Editing this item is currently disabled, as it is in a bulk action queue
            awaiting processing.
          </Alert>
        );
      }
      return <ButtonToolbar className="pull-right toolbar-buttons">{workflowActions}</ButtonToolbar>;
    }
    return (
      <NavDropdownMenu
        component="div"
        buttonClass="btn btn-default"
        title={<i className="fa fa-ellipsis-v"></i>}
        id={`episode-item-button-${this.props.model.id}`}
      >
        {workflowActions}
      </NavDropdownMenu>
    );
  }
}

WorkflowActions.propTypes = {
  resource: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  videoCurrentlyUploading: PropTypes.bool,
  disableWorkflow: PropTypes.bool,
  getWorkflowActions: PropTypes.func.isRequired,
  editForm: PropTypes.object,
  dispatch: PropTypes.func,
  delete: PropTypes.func,
  isButton: PropTypes.bool,
  viewLink: PropTypes.string,
  index: PropTypes.number,
  bulkActions: PropTypes.object,
};

WorkflowActions.defaultProps = {
  bulkActions: {},
};

function mapStateToProps(state) {
  return {
    bulkActions: state.bulkActions,
  };
}

export default connect(mapStateToProps)(WorkflowActions);
