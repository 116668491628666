import _ from 'lodash';
import constants from 'src/scripts/constants';

function isValidNumeric(arg) {
  return _.isFinite(arg) && arg >= 0;
}

export function calculateActivePage(offset) {
  if (!isValidNumeric(offset) || !isValidNumeric(constants.pagination.pageSize)) {
    return 1;
  }
  return Math.ceil((offset + 1) / constants.pagination.pageSize);
}

export function calculateOffset(activePage) {
  if (activePage === 1 || !isValidNumeric(activePage) || !isValidNumeric(constants.pagination.pageSize)) {
    return 0;
  }
  return constants.pagination.pageSize * (activePage - 1);
}

export const pageSize = constants.pagination.pageSize;
