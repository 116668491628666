import {
  TRANSCODE_VIDEOCLOUD_STARTED,
  TRANSCODE_VIDEOCLOUD_SUCCEEDED,
  TRANSCODE_VIDEOCLOUD_FAILED,
  GET_SIGNED_VIDEO_SOURCE_URL_SUCCEEDED,
  GET_SIGNED_VIDEO_SOURCE_URL_FAILED,
  GET_SIGNED_VIDEO_SOURCE_URL_STARTED,
  GET_SIGNED_CAPTION_SOURCE_URL_SUCCEEDED,
  GET_SIGNED_CAPTION_SOURCE_URL_FAILED,
  GET_SIGNED_CAPTION_SOURCE_URL_STARTED,
} from 'src/scripts/actions/video';

import { CAPTION_DELETE_FLOW_COMPLETED } from 'src/scripts/actions/ui/caption';
import { DETAILS_VIEW_RESET } from 'src/scripts/actions/mediaItemDetailsView';

export const initialState = {
  disableReingestVideoCloud: false,
  signedVideoSourceUrl: {
    url: null,
    errorMessage: null,
  },
  signedCaptionSourceUrl: {
    url: null,
    errorMessage: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SIGNED_VIDEO_SOURCE_URL_STARTED:
      return {
        ...state,
        signedVideoSourceUrl: {
          url: null,
          errorMessage: null,
        },
      };

    case GET_SIGNED_VIDEO_SOURCE_URL_SUCCEEDED:
      return {
        ...state,
        signedVideoSourceUrl: {
          url: action.url,
          errorMessage: null,
        },
      };

    case GET_SIGNED_VIDEO_SOURCE_URL_FAILED:
      return {
        ...state,
        signedVideoSourceUrl: {
          url: null,
          errorMessage: action.error,
        },
      };

    case GET_SIGNED_CAPTION_SOURCE_URL_STARTED:
      return {
        ...state,
        signedCaptionSourceUrl: {
          url: null,
          errorMessage: null,
        },
      };

    case GET_SIGNED_CAPTION_SOURCE_URL_SUCCEEDED:
      return {
        ...state,
        signedCaptionSourceUrl: {
          url: action.url,
          errorMessage: null,
        },
      };

    case GET_SIGNED_CAPTION_SOURCE_URL_FAILED:
      return {
        ...state,
        signedCaptionSourceUrl: {
          url: null,
          errorMessage: action.error,
        },
      };

    case CAPTION_DELETE_FLOW_COMPLETED:
      return {
        ...state,
        signedCaptionSourceUrl: {
          url: null,
          errorMessage: null,
        },
      };

    case TRANSCODE_VIDEOCLOUD_STARTED:
      return {
        ...state,
        disableReingestVideoCloud: true,
      };

    case TRANSCODE_VIDEOCLOUD_SUCCEEDED:
    case TRANSCODE_VIDEOCLOUD_FAILED:
      return {
        ...state,
        disableReingestVideoCloud: false,
      };

    case DETAILS_VIEW_RESET:
      return initialState;

    default:
      return state;
  }
}
