import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Input } from 'react-bootstrap';

class BulkEditClassification extends React.Component {
  render() {
    return (
      <Panel
        id="panel-classification"
        header="Classification"
        bsStyle="primary"
        expanded={false}
        eventKey="4"
        className="schedulePanel"
      >
        <label>Classification</label>
        <Input
          type="radio"
          id="set-classification-radio"
          onClick={() => this.props.handleKeepExistingClassificationChange(false)}
          checked={!this.props.keepExistingClassificationsValue}
          label={
            <div style={{ marginTop: '-7px' }}>
              <Input
                type="select"
                ref="videoClassification"
                id="select-classification"
                className="videoClassification"
                disabled={this.props.keepExistingClassificationsValue}
                onChange={this.props.onClassificationCodeChange}
                value={this.props.classificationCode}
              >
                <option value="0">Select a classification</option>
                {this.props.classifications.map((classification) => {
                  return (
                    <option key={classification.code} value={classification.code}>
                      {classification.code} - {classification.name}
                    </option>
                  );
                })}
              </Input>
            </div>
          }
        />
        <Input
          type="radio"
          id="keep-existing-classification-radio"
          label="Keep existing"
          checked={this.props.keepExistingClassificationsValue}
          onClick={() => this.props.handleKeepExistingClassificationChange(true)}
        />
      </Panel>
    );
  }
}

BulkEditClassification.propTypes = {
  classifications: PropTypes.array,
  classificationCode: PropTypes.string,
  keepExistingClassificationsValue: PropTypes.bool,
  onClassificationCodeChange: PropTypes.func,
  handleKeepExistingClassificationChange: PropTypes.func,
};

export default BulkEditClassification;
