import PropTypes from 'prop-types';
import React from 'react';
import { isBulkDeleteEnabled as isIngestJobBulkDeleteEnabled } from 'src/scripts/lib/ingestJobWorkflowActionsProvider';
import ConfirmableActionButton from 'src/scripts/components/ConfirmableActionButton';
import { getAvailableBulkWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import { IN_PROGRESS } from 'src/scripts/reducers/bulkActions';
import { EPISODE, CLIP, NETWORK_CLIP, INGEST_JOB } from 'src/scripts/lib/modelNames';

export class DeleteButton extends React.Component {
  formatConfirmationString(actionName, modelName, selectedCount) {
    if (this.props.bulkActionStatus === IN_PROGRESS) {
      return 'There is currently a bulk action in progress. Please wait until the current action completes before starting a new one.';
    }
    const mapModelNameToString = {
      [CLIP]: 'clip',
      [EPISODE]: 'episode',
      [NETWORK_CLIP]: 'clip',
      [INGEST_JOB]: 'ingest job',
    };
    const pluralisedModelName = `${mapModelNameToString[modelName]}${selectedCount > 1 ? 's' : ''}`;
    return `You are about to ${actionName} ${selectedCount} ${pluralisedModelName}. Are you sure you want to continue?`;
  }

  isDisabled() {
    if ([CLIP, EPISODE, NETWORK_CLIP].includes(this.props.modelName)) {
      return (
        this.props.selectedItems.length < 1 ||
        getAvailableBulkWorkflowActions(this.props.selectedItems, this.props.modelName).indexOf('delete') ===
          -1
      );
    } else if (this.props.modelName === INGEST_JOB) {
      return this.props.selectedItems.length < 1 || !isIngestJobBulkDeleteEnabled(this.props.selectedItems);
    }
    return true;
  }

  render() {
    const mapDeleteAction = {
      [EPISODE]: this.props.deleteEpisodes,
      [CLIP]: this.props.deleteClips,
      [NETWORK_CLIP]: this.props.deleteNetworkClips,
      [INGEST_JOB]: this.props.deleteIngestJobs,
    };

    return (
      <ConfirmableActionButton
        ref="deleteButton"
        className="delete-btn"
        buttonClass={`delete-btn btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        buttonLabel="Delete"
        confirmationText={this.formatConfirmationString(
          'delete',
          this.props.modelName,
          this.props.selectedItems.length
        )}
        action={mapDeleteAction[this.props.modelName].bind(
          this,
          this.props.selectedItems.map((item) => item.id)
        )}
        immediateCloseOnConfirm
        isConfirmDisabled={this.props.bulkActionStatus === IN_PROGRESS}
      />
    );
  }
}

DeleteButton.propTypes = {
  deleteEpisodes: PropTypes.func,
  deleteClips: PropTypes.func,
  deleteNetworkClips: PropTypes.func,
  deleteIngestJobs: PropTypes.func,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  bulkActionStatus: PropTypes.string,
};

export default DeleteButton;
