import _ from 'lodash';

export const states = {
  WAITING_VIDEO: 'WAITING_VIDEO',
  VIDEO_PROCESSING: 'VIDEO_PROCESSING',
  MATCHING_REQUIRED: 'MATCHING_REQUIRED',
  IMPORTED: 'IMPORTED',
  DELETING: 'DELETING',
  DELETION_FAILED: 'DELETION_FAILED',
};

export function isMatchingEpisodeEnabled(state) {
  return state && state === states.MATCHING_REQUIRED;
}

export function isDeleteEnabled(state) {
  return (
    state && [states.WAITING_VIDEO, states.MATCHING_REQUIRED, states.DELETION_FAILED].indexOf(state) > -1
  );
}

export function isBulkDeleteEnabled(ingestJobs) {
  return _.every(ingestJobs, (ingestJob) => {
    return isDeleteEnabled(ingestJob.state);
  });
}
