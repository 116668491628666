import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';

const LicensePeriod = ({
  onFastChannelLicensingStartDateChange,
  onFastChannelLicensingEndDateChange,
  fastChannelLicensingStartDate,
  fastChannelLicensingEndDate,
  validationErrors = {},
}) => (
  <Panel header="License Period" bsStyle="primary" expanded={false}>
    <div className={`form-group ${validationErrors.fastChannelLicensingStartDate ? 'error' : ''}`}>
      <label>Start Date & Time</label>
      <DateTimePicker
        onChange={onFastChannelLicensingStartDateChange}
        bsStyle={validationErrors.fastChannelLicensingStartDate ? 'error' : null}
        value={fastChannelLicensingStartDate}
        placeholder="Enter license period start date"
      />
    </div>
    <div className={`form-group ${validationErrors.fastChannelLicensingEndDate ? 'error' : ''}`}>
      <label>End Date & Time</label>
      <DateTimePicker
        value={fastChannelLicensingEndDate}
        bsStyle={validationErrors.fastChannelLicensingEndDate ? 'error' : null}
        onChange={onFastChannelLicensingEndDateChange}
        placeholder="Enter license period end date"
      />
    </div>
    <span>
      When an Episode is created for a Season, it will inherit the dates from the Season. Updating the dates
      in the Season will not update the dates in the Episode.
    </span>
  </Panel>
);

LicensePeriod.propTypes = {
  onFastChannelLicensingStartDateChange: PropTypes.func,
  onFastChannelLicensingEndDateChange: PropTypes.func,
  fastChannelLicensingStartDate: PropTypes.string,
  fastChannelLicensingEndDate: PropTypes.string,
  validationErrors: PropTypes.object,
};

export default LicensePeriod;
