import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import FramePicker from 'react-frame-picker';
import { Row, Col, Button } from 'react-bootstrap';
import { Input, ProgressBar } from 'react-bootstrap';
import { uploadImage, clearUploadedImage } from 'src/scripts/actions/imageUpload';
import { getImage, validate, ANY_ASPECT_RATIO } from 'src/scripts/lib/keyArtFileUtility';
import { SQUARE } from 'src/scripts/lib/imageTypes';
import ImageDropZone from 'src/scripts/components/ImageDropZone';
import ImageEditor from 'src/scripts/components/ImageEditor';
import { isDefaultImage } from 'src/scripts/lib/util';

export class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

    this.onImageFileChange = this.onImageFileChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.getRatioLabel = this.getRatioLabel.bind(this);
    this.getRestrictions = this.getRestrictions.bind(this);

    const ratioLabel = this.getRatioLabel(this.props.restrictions);
    this.state = {
      imageFileFromFramePicker: null,
      imageSrc: null,
      imageIsSelected: false,
      invalidImage: false,
      invalidImageReason: null,
      previewUrl: null,
      ratioLabel,
    };
  }

  componentWillReceiveProps(nextProps) {
    const errorMessage = nextProps.imageUploads[nextProps.imageUploadRef].uploadError;
    this.setState({
      uploadError: errorMessage,
    });
  }

  onFramePickerSelection = (generatedFile) => {
    this.setState({
      imageIsSelected: true,
      invalidImageReason: null,
    });
    this.refs.imageSelect.getInputDOMNode().value = '';
    this.uploadImage(generatedFile);
  };

  onImageFileChange() {
    const selectedImageFile = this.refs.imageSelect.getInputDOMNode().files[0];
    if (!selectedImageFile) {
      this.setState({
        imageIsSelected: false,
        invalidImage: false,
        invalidImageReason: null,
        previewUrl: null,
        uploadError: null,
      });
      return this.props.clearUploadedImage(this.props.imageUploadRef);
    }

    this.setState({
      imageIsSelected: true,
      invalidImageReason: null,
    });

    this.uploadImage(selectedImageFile);

    return undefined;
  }

  getNotFoundImage() {
    return this.props.imageType && this.props.imageType === SQUARE
      ? 'images/not-found-square.png'
      : 'images/not-found.png';
  }

  getImageFile = (data) => {
    this.setState({
      imageFileFromFramePicker: data,
    });
    getImage(data).then((image) => {
      try {
        this.setState({
          imageSrc: image.src,
        });
      } catch (error) {
        this.setState({
          invalidImageReason: error.message,
        });
      }
    });
  };

  getExistingImageUrl() {
    if (this.props.existingImage) {
      return isDefaultImage(this.props.existingImage)
        ? this.getNotFoundImage()
        : this.props.existingImage.url;
    }
    return this.getNotFoundImage();
  }
  getRatioLabel(restrictions) {
    if (restrictions && restrictions.ratioThreshold === ANY_ASPECT_RATIO) {
      return 'any';
    }
    if (restrictions && restrictions.ratioLabel) {
      return restrictions.ratioLabel;
    }
    return this.props.imageType === SQUARE ? '1:1' : '16:9';
  }
  getRestrictions() {
    if (this.props.restrictions) {
      return this.props.restrictions;
    }
    return this.props.imageType === SQUARE ? { minWidth: 0, minHeight: 0, ratioThreshold: 1 } : undefined;
  }

  uploadImage(selectedImageFile) {
    getImage(selectedImageFile).then((image) => {
      try {
        validate(image, selectedImageFile.type, this.getRestrictions());
        this.setState({
          imageSrc: null,
          imageIsSelected: true,
          invalidImageReason: null,
          previewUrl: image.src,
          invalidImage: false,
        });
        this.props.uploadImage(
          this.props.resourceGroup,
          selectedImageFile,
          this.props.imageUploadRef,
          'SINGLE_FILE_UPLOAD',
          this.props.imageType
        );
      } catch (error) {
        this.setState({
          imageIsSelected: true,
          invalidImage: true,
          invalidImageReason: error.message,
          uploadError: null,
          previewUrl: null,
        });
        this.props.clearUploadedImage(this.props.imageUploadRef);
      }
    });
  }

  renderTemporaryImageMark() {
    if (this.state.imageIsSelected) return null;
    if (this.props.existingImage && this.props.existingImage.isImageTemp) {
      return <div id={this.props.existingImage.type}>TEMPORARY</div>;
    }
    return null;
  }

  render() {
    const buttonId = this.props.id ? `button-${this.props.id}` : undefined;
    const uploadPercentage =
      this.props.imageUploads && this.props.imageUploads[this.props.imageUploadRef]
        ? this.props.imageUploads[this.props.imageUploadRef].uploadPercentage
        : null;
    return (
      <div className="image-upload">
        <label className={`control-label${this.props.required ? ' required' : ''}`}>
          {this.props.label}{' '}
        </label>
        <Row>
          {this.props.showFramePicker ? (
            <div>
              <Col xs={4}>
                <FramePicker
                  src={this.props.framePickerVideoUrl}
                  action={(file) => {
                    this.onFramePickerSelection(file);
                  }}
                  imageFile={this.getImageFile}
                  buttonRenderFunction={(props) => (
                    <Button bsSize="xsmall" {...props}>
                      Select Image from Video
                    </Button>
                  )}
                  disabled={!this.props.framePickerVideoUrl}
                />
              </Col>
              <Col xs={1}>
                <strong>Or</strong>
              </Col>
            </div>
          ) : null}
          <Col xs={7}>
            <Input
              className="image-select-input"
              ref="imageSelect"
              id={buttonId}
              type="file"
              onChange={this.onImageFileChange}
              accept=".png,.jpg,.jpeg"
              data-pw="image-select-input"
            />
          </Col>
        </Row>
        <span> Supported formats are [.PNG, .JPG or .JPEG] and {this.state.ratioLabel} aspect ratio.</span>
        <div>
          {this.state.imageSrc || this.state.previewUrl ? (
            <ImageEditor
              previewUrl={this.state.previewUrl}
              imageSrc={this.state.imageSrc}
              imageFile={this.state.imageFileFromFramePicker}
              imageUpload={this.uploadImage}
            />
          ) : null}
        </div>
        <div
          className={`${
            this.props.imageType && this.props.imageType === SQUARE ? 'preview-image-square' : 'preview-image'
          }`}
        >
          {this.renderTemporaryImageMark()}
          <img
            className={`${
              this.props.restrictions && this.props.restrictions.class ? this.props.restrictions.class : ''
            }`}
            src={`${this.state.previewUrl ? this.state.previewUrl : this.getExistingImageUrl()}`}
          />
          <ImageDropZone onDrop={this.uploadImage} imageSelected={this.state.imageIsSelected} />
          {uploadPercentage === null ? null : (
            <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />
          )}
        </div>
        {this.state.imageIsSelected && (this.state.invalidImage || this.state.uploadError) ? (
          <div ref="imageError" className="alert alert-danger image-error">
            {this.state.invalidImageReason || this.state.uploadError}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  restrictions: PropTypes.object,
  resourceGroup: PropTypes.string,
  id: PropTypes.string,
  imageUploadRef: PropTypes.string,
  uploadImage: PropTypes.func,
  clearUploadedImage: PropTypes.func,
  label: PropTypes.string,
  imageUploads: PropTypes.object,
  existingImage: PropTypes.object,
  imageType: PropTypes.string,
  framePickerVideoUrl: PropTypes.string,
  showFramePicker: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node,
};

ImageUpload.defaultProps = {
  showFramePicker: false,
  required: false,
  label: 'Image',
};

function mapStateToProps(state) {
  return {
    imageUploads: state.imageUploads,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadImage: (resourceGroup, selectedImageFile, imageUploadRef, imageUploadType, imageType) =>
      dispatch(uploadImage(resourceGroup, selectedImageFile, imageUploadRef, imageUploadType, imageType)),
    clearUploadedImage: (imageUploadRef) => dispatch(clearUploadedImage(imageUploadRef)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
