/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { ViewLoader } from 'src/scripts/components/ViewLoader';
import {
  getAllLiveStreamMappings,
  listMediaLiveChannels,
  listAvailableMediaLiveBugs,
} from '../../actions/mediaLiveChannel';
import MediaLiveChannelView from './MediaLiveChannelView';
import {
  setMediaLiveChannelInputStream,
  toggleMediaLiveChannel,
  toggleTouchStreamAlerts,
  restartYoSpaceCSM,
  enableBugForMediaLiveChannel,
  disableBugForMediaLiveChannel,
} from '../../actions/mediaLiveChannel';
import history from 'src/scripts/lib/history';

const categories = {
  TBS: 'prod-event-tbs-9now',
  OLYMPIC_DX: 'prod-event-syd',
};

function channelNameCompareFunction(a, b) {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
}

class MediaLiveChannelController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { count: 0, channels: [] },
      loadingError: null,
      modalButtonLoading: false,
      selectedChannelCategoryString: '',
      selectedChannelMappingString: '',
      liveStreamMappings: [],
      loading: true,
    };
  }

  filterStreamsByMapping = (mappingString) => {
    const queryParams = new URLSearchParams(history.location.search);

    queryParams.set('event_mapping', mappingString);

    history.push(`${history.location.pathname}?${queryParams.toString()}`);

    this.setState({ selectedChannelMappingString: mappingString }, () => {
      this.loadData();
    });
  };

  filterStreamsByCategory = (categoryString) => {
    const queryParams = new URLSearchParams(history.location.search);

    queryParams.set('event_category', categoryString);
    queryParams.set('event_mapping', this.state.liveStreamMappings[categoryString][0]);

    history.push(`${history.location.pathname}?${queryParams.toString()}`);

    this.setState({ selectedChannelCategoryString: categoryString }, () => {
      this.loadData();
    });
  };

  getLiveEventStreamMappings = async () => {
    try {
      const mappings = await getAllLiveStreamMappings();

      return mappings;
    } catch (e) {
      alert(e.responseJSON.message);

      return [];
    }
  };

  loadData = async () => {
    try {
      this.setState({ loading: true });
      const mappings = await this.getLiveEventStreamMappings();

      const queryParamCategory = history.location.query.event_category || 'TBS';
      const queryParamMapping = history.location.query.event_mapping || mappings[queryParamCategory][0];

      const categoryFilter = categories[queryParamCategory] || categories.TBS;
      const mappingFilter = queryParamMapping || mappings[queryParamCategory][0];

      const data = await listMediaLiveChannels({
        channelName: categoryFilter,
        includeDetails: true,
        mappingFilter,
        mappingCategory: queryParamCategory,
      });

      const bugs = await listAvailableMediaLiveBugs();

      // Add parameter for if the channel is online or not for the button state.
      this.addButtonStatesToChannels(data.channels);

      // Sort the channels by name.
      data.channels = data.channels.sort(channelNameCompareFunction);

      this.setState({
        data,
        liveStreamMappings: mappings,
        selectedChannelMappingString: mappingFilter,
        selectedChannelCategoryString: queryParamCategory,
        mediaLiveAvailableBugs: bugs,
        loading: false,
      });
    } catch (e) {
      this.setState({ loadingError: e.message, loading: false });
    }
  };

  addButtonStatesToChannels = (channels) => {
    for (const channel of channels) {
      const mediaLiveState = channel.state;

      channel.mediaLiveButtonState = mediaLiveState === 'RUNNING';
      if (channel.touchStream && channel.touchStream.length) {
        channel.touchStreamAlertButtonState = channel.touchStream.some((e) => e.active);
      } else {
        channel.touchStreamAlertButtonState = false;
      }
    }
  };

  switchStreamTo = async (streamInputName, channelId, reason) => {
    this.setState({ modalButtonLoading: true });

    await setMediaLiveChannelInputStream(streamInputName, channelId, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  // Add loading screen, close modal after done.
  turnOnOrOffMediaLiveChannel = async (channelId, buttonState, reason) => {
    this.setState({ modalButtonLoading: true });

    await toggleMediaLiveChannel(channelId, buttonState, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  turnOnOrOffTouchStreamAlerts = async (streamKeys, buttonState, reason) => {
    this.setState({ modalButtonLoading: true });

    await toggleTouchStreamAlerts(streamKeys, buttonState, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  resetYoSpaceCSM = async (channelId, reason) => {
    this.setState({ modalButtonLoading: true });

    await restartYoSpaceCSM(channelId, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  enableBugForChannel = async (channelId, bug, reason) => {
    this.setState({ modalButtonLoading: true });

    await enableBugForMediaLiveChannel(channelId, bug, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  disableBugForChannel = async (channelId, reason) => {
    this.setState({ modalButtonLoading: true });

    await disableBugForMediaLiveChannel(channelId, reason).catch((error) => {
      alert(error);
    });

    window.location.reload(false);
  };

  render = () => (
    <ViewLoader errorMessage={this.state.loadingError}>
      <MediaLiveChannelView
        data={this.state.data}
        listChannels={this.loadData}
        filterStreamsByMapping={this.filterStreamsByMapping}
        filterStreamsByCategory={this.filterStreamsByCategory}
        liveStreamMappings={this.state.liveStreamMappings}
        selectedChannelMappingString={this.state.selectedChannelMappingString}
        selectedChannelCategoryString={this.state.selectedChannelCategoryString}
        switchStreamTo={this.switchStreamTo}
        turnOnOrOffMediaLiveChannel={this.turnOnOrOffMediaLiveChannel}
        turnOnOrOffTouchStreamAlerts={this.turnOnOrOffTouchStreamAlerts}
        resetYoSpaceCSM={this.resetYoSpaceCSM}
        enableBugForChannel={this.enableBugForChannel}
        disableBugForChannel={this.disableBugForChannel}
        modalButtonLoading={this.state.modalButtonLoading}
        mediaLiveAvailableBugs={this.state.mediaLiveAvailableBugs}
        loading={this.state.loading}
      />
    </ViewLoader>
  );
}

export default MediaLiveChannelController;
