/**
 * Declare the classes first,
 * so they can be referred to before being defined.
 */
let DeviceStatusError;
let DeviceStatusUpdating;
let DeviceStatusReady;

class DeviceStatus {
  constructor(field, message = '') {
    this.field = field;
    this.message = message;
  }

  getError(errorMessage) {
    return new DeviceStatusError(this.field, errorMessage);
  }

  getUpdating(info) {
    return new DeviceStatusUpdating(this.field, info);
  }

  getReady(message = '') {
    return new DeviceStatusReady(this.field, message);
  }
}

// eslint-disable-next-line prefer-const
DeviceStatusError = class extends DeviceStatus {};

// eslint-disable-next-line prefer-const
DeviceStatusUpdating = class extends DeviceStatus {};

// eslint-disable-next-line prefer-const
DeviceStatusReady = class extends DeviceStatus {};

export { DeviceStatusReady, DeviceStatusUpdating, DeviceStatusError };
