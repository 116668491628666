import PropTypes from 'prop-types';
import React from 'react';
import { Input } from 'react-bootstrap';
import { TV_PATH } from 'src/scripts/lib/accessController';

class SeasonSelect extends React.Component {
  renderLabel = () => (
    <label ref="seasonSelectHeaderLabel" className={`control-label ${this.props.required ? 'required' : ''}`}>
      Season
    </label>
  );

  renderSeasonViewLink = () => {
    return this.props.tvSeriesId && this.props.seasonId ? (
      <a
        ref="seasonSelectHeaderLink"
        href={`/#/${TV_PATH}/tv-series/${this.props.tvSeriesId}/seasons/${this.props.seasonId}/episodes`}
        target="_blank"
        className="hyperlink"
      >
        Go to Season View
      </a>
    ) : null;
  };

  onSeasonChange = (event) => {
    const seasonId = event.target.value ? parseInt(event.target.value, 10) : null;
    this.props.onSeasonChange(seasonId);
  };

  renderOptions = () => {
    const options = [];
    options.push(<option value="0">Select a season</option>);
    if (this.props.seasons && this.props.seasons.length > 0) {
      const seasonOptions = this.props.seasons.map((season) => {
        return (
          <option key={season.id} value={season.id}>
            {season.name}
          </option>
        );
      });
      options.push(...seasonOptions);
    }
    return options;
  };

  render() {
    return (
      <div className={`season-select form-group ${this.props.hasValidationErrors ? 'error' : ''}`}>
        {!this.props.hideHeader && this.renderLabel()}
        {!this.props.hideHeader && this.renderSeasonViewLink()}
        <Input
          type="select"
          ref="selectSeason"
          id="select-season"
          disabled={this.props.disabled}
          onChange={this.onSeasonChange}
          value={this.props.seasonId}
          bsStyle={this.props.hasValidationErrors ? 'error' : null}
          data-pw="select-season"
        >
          {this.renderOptions()}
        </Input>
      </div>
    );
  }
}

SeasonSelect.propTypes = {
  disabled: PropTypes.bool,
  hasValidationErrors: PropTypes.bool,
  required: PropTypes.bool,
  hideHeader: PropTypes.bool,
  tvSeriesId: PropTypes.number,
  seasons: PropTypes.array,
  seasonId: PropTypes.number,
  onSeasonChange: PropTypes.func,
};

export const defaultProps = {
  hasValidationErrors: false,
  required: true,
  hideHeader: false,
};

SeasonSelect.defaultProps = defaultProps;

export default SeasonSelect;
