import PropTypes from 'prop-types';
import React from 'react';
import { Label } from 'react-bootstrap';

export default class PaginationLocation extends React.Component {
  getDisplayedResultsRange(activePage, pageSize, totalCount) {
    const lowerLimit = totalCount === 0 ? 0 : (activePage - 1) * pageSize + 1;
    const upperLimit =
      totalCount < pageSize || totalCount < activePage * pageSize ? totalCount : activePage * pageSize;
    return `Results ${lowerLimit}-${upperLimit} of ${totalCount}`;
  }

  render() {
    if (!this.props.totalCount) {
      return null;
    }
    return (
      <div className="pagination-location">
        <Label bsStyle="default" className="pagination-location-label">
          {this.getDisplayedResultsRange(this.props.activePage, this.props.pageSize, this.props.totalCount)}
        </Label>
      </div>
    );
  }
}

PaginationLocation.propTypes = {
  totalCount: PropTypes.number,
  activePage: PropTypes.number,
  pageSize: PropTypes.number,
};
