import {
  GET_COLLECTION_SUCCEEDED,
  GET_COLLECTION_FAILED,
  DELETE_COLLECTION_SUCCEEDED,
  SAVE_COLLECTION_SUCCEEDED,
} from 'src/scripts/actions/collection';
import { PUBLISH_COLLECTION_SUCCEEDED } from '../actions/collection';

export const initialState = {
  collection: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COLLECTION_SUCCEEDED:
      return {
        ...state,
        collection: action.data,
      };

    case GET_COLLECTION_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case DELETE_COLLECTION_SUCCEEDED:
      return { ...initialState };

    case SAVE_COLLECTION_SUCCEEDED:
      return {
        ...state,
        collection: action.data,
      };

    case PUBLISH_COLLECTION_SUCCEEDED:
      return {
        ...state,
        collection: action.data,
      };

    default:
      return state;
  }
}
