export const VIDEOS = {
  TV: 'tv',
  NETWORK: 'network',
};

export const TAGS = {
  TV: 'tv',
  NETWORK: 'network',
};

export const IMAGES = {
  TV: 'tv',
  NETWORK: 'network',
  TV_LIVE_EVENTS: 'tv_live_events',
  NETWORK_LIVE_EVENTS: 'network_live_events',
};

export const LIVE_EVENTS = {
  TV_LIVE_EVENTS: 'tv_live_events',
  NETWORK_LIVE_EVENTS: 'network_live_events',
};
