import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import json2csv from 'json2csv';
import { exportCatalogFile } from 'src/scripts/lib/downloadFile';
import { STREAMS_FIELDS } from 'src/scripts/lib/exportCatalogFields';

class ExportStreamsCatalog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExporting: false,
    };
  }

  exportStreams = () => {
    this.setState({ isExporting: true });
    const result = this.props.streams;

    const csv = json2csv.parse(result, {
      fields: STREAMS_FIELDS,
      withBOM: true,
      header: true,
    });
    exportCatalogFile(csv, 'streams');
    this.setState({ isExporting: false });
  };

  render() {
    return (
      <Button
        alignLeft={false}
        bsSize="small"
        className="export-btn btn-sm btn btn-default"
        disabled={this.state.isExporting || !this.props.streams}
        onClick={this.exportStreams}
      >
        {this.state.isExporting ? 'Export in Progress' : 'Export Streams'}
      </Button>
    );
  }
}

ExportStreamsCatalog.propTypes = {
  streams: PropTypes.array,
};

export default ExportStreamsCatalog;
