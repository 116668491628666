import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_ROLE_FAILED,
  SAVE_ROLE_SUCCEEDED,
  ROLE_UPDATED,
  GET_ROLE_SUCCEEDED,
  GET_ROLE_FAILED,
  GET_AZURE_ROLES_STARTED,
  GET_AZURE_ROLES_SUCCEEDED,
  GET_AZURE_ROLES_FAILED,
  GET_ROLE_FORM_INITIAL_DATA_SUCCEEDED,
} from 'src/scripts/actions/role';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/roleForm';

export const initialState = {
  role: {
    permissions: {
      modules: [],
    },
  },
  availableAzureRoles: [],
  azure: {
    errorMessage: null,
    loading: false,
  },
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  initialFormDataLoaded: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROLE_SUCCEEDED:
      return {
        ...state,
        ...action.data,
      };

    case GET_ROLE_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case ROLE_UPDATED:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors({ ...state.role, ...action.role }, getValidationRules());
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          role: {
            ...state.role,
            ...action.role,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        role: {
          ...state.role,
          ...action.role,
        },
      };

    case SAVE_ROLE_FAILED:
      return { ...state, errorMessage: action.error };

    case SAVE_ROLE_SUCCEEDED:
      return { ...state, errorMessage: null };

    case GET_AZURE_ROLES_STARTED:
      return {
        ...state,
        azure: {
          loading: true,
        },
      };

    case GET_AZURE_ROLES_SUCCEEDED:
      return {
        ...state,
        role: {
          ...state.role,
        },
        availableAzureRoles: action.data,
        azure: {
          loading: false,
          errorMessage: null,
        },
      };

    case GET_AZURE_ROLES_FAILED:
      return {
        ...state,
        azure: {
          loading: false,
          errorMessage: action.error,
        },
      };

    case GET_ROLE_FORM_INITIAL_DATA_SUCCEEDED:
      return { ...state, initialFormDataLoaded: true };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.role, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
