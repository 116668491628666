import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as pagination from 'src/scripts/lib/pagination';
import { getAllNetworkClips } from 'src/scripts/actions/networkClip';
import VideoList from 'src/scripts/components/Video/VideoList';
import { resetItemList, updateFilter } from 'src/scripts/actions/itemList';

const NETWORK_CLIP_DEFAULT_FILTER = {
  name: 'state',
  value: 'in:TRANSCODING,IN_USE,DRAFT,TRANSCODING_FAILED,READY_FOR_REVIEW',
};
export class NetworkClipList extends React.Component {
  constructor() {
    super();
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidUpdate() {
    if (this.props.savedClip) {
      this.props.getAllNetworkClips({
        offset: pagination.calculateOffset(this.props.page),
        sort: this.props.sort,
        filters: this.props.filters,
      });
    }
  }

  clearFilters() {
    this.props.resetItemList();
    this.props.updateFilter(NETWORK_CLIP_DEFAULT_FILTER);
    return this.props.getAllNetworkClips({ filter: NETWORK_CLIP_DEFAULT_FILTER });
  }

  render() {
    return (
      <VideoList
        id={'list-network-clip'}
        getItems={this.props.getAllNetworkClips}
        modelName="networkClips"
        defaultFilter={NETWORK_CLIP_DEFAULT_FILTER}
        clearFilters={this.clearFilters}
        hasSearch
        {...this.props}
      />
    );
  }
}

NetworkClipList.propTypes = {
  page: PropTypes.number,
  getAllNetworkClips: PropTypes.func,
  savedClip: PropTypes.object,
  sort: PropTypes.string,
  filters: PropTypes.object,
  updateFilter: PropTypes.func,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    savedClip: state.networkClips.savedClip,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllNetworkClips: (pageData) => dispatch(getAllNetworkClips(pageData)),
    updateFilter: ({ name, value }) => dispatch(updateFilter({ name, value })),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClipList);
