import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DetailsView from 'src/scripts/components/MediaItem/DetailsView';
import { getClip, deleteClipAndCloseConfirmation, saveClip } from 'src/scripts/actions/clip';
import ClipFormLoader from 'src/scripts/components/Clip/ClipFormLoader';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import { getClipWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import { transcodeBrightcoveVideoCloud } from 'src/scripts/actions/video';
import { tvStatesAndAllowedActions } from 'src/scripts/lib/workflowActionsProvider';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class ClipDetailsView extends React.Component {
  constructor() {
    super();
    this.refreshDetails = this.refreshDetails.bind(this);
  }

  getBreadcrumbList() {
    const breadcrumbList = [
      <a href={`/#/${TV_PATH}/tv-series`}>TV Series</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.clip.partOfSeries.id}`}>
        {this.props.clip.partOfSeries && this.props.clip.partOfSeries.name}
      </a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.clip.partOfSeries.id}/seasons`}>Seasons</a>,
      <a
        href={`/#/${TV_PATH}/tv-series/${this.props.clip.partOfSeries.id}/seasons/${this.props.clip.partOfSeason.id}/clips`}
      >
        {this.props.clip.partOfSeason && this.props.clip.partOfSeason.name}
      </a>,
      this.props.clip.name,
    ];
    return breadcrumbList;
  }

  refreshDetails() {
    return this.props.getClipById(this.props.clip.id);
  }

  isEditDisabled() {
    return !_.includes(tvStatesAndAllowedActions[this.props.clip.state], 'edit');
  }

  render() {
    return (
      <DetailsView
        ref="detailsView"
        workflowActions={
          <WorkflowActions
            getWorkflowActions={getClipWorkflowActions}
            model={this.props.clip}
            resource="clip"
            disableWorkflow={this.props.disableWorkflow}
            videoCurrentlyUploading={
              !!(this.props.uploadMetaData && this.props.uploadMetaData[this.props.clip.video.id])
            }
            editForm={<ClipFormLoader clipId={this.props.clip.id} />}
            delete={this.props.deleteClipAndCloseConfirmation.bind(
              this,
              this.props.clip.id,
              this.props.clip.partOfSeason.id
            )}
            save={this.props.saveClip}
            isButton
          />
        }
        model={this.props.clip}
        resource="clip"
        resourceGroup={VIDEOS.TV}
        transcodeBrightcoveVideoCloud={this.props.transcodeBrightcoveVideoCloud.bind(
          this,
          VIDEOS.TV,
          this.props.clip.video.id,
          this.refreshDetails
        )}
        breadcrumbList={this.getBreadcrumbList()}
        disableEdit={this.isEditDisabled()}
      />
    );
  }
}

ClipDetailsView.propTypes = {
  disableWorkflow: PropTypes.bool,
  getClipById: PropTypes.func,
  deleteClipAndCloseConfirmation: PropTypes.func,
  clip: PropTypes.object.isRequired,
  uploadMetaData: PropTypes.object,
  transcodeBrightcoveVideoCloud: PropTypes.func,
  saveClip: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    disableWorkflow: state.clips.disableWorkflow,
    uploadMetaData: state.video.uploadMetaData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getClipById: (clipId) => dispatch(getClip(clipId)),
    deleteClipAndCloseConfirmation: (clipId, seasonId) =>
      dispatch(deleteClipAndCloseConfirmation(clipId, seasonId)),
    transcodeBrightcoveVideoCloud: (resourceGroup, videoId, resource) =>
      dispatch(transcodeBrightcoveVideoCloud(resourceGroup, videoId, resource)),
    saveClip: (clip) => dispatch(saveClip(clip)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClipDetailsView);
