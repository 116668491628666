import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Input } from 'react-bootstrap';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import { updateVisibleColumns } from 'src/scripts/actions/columnChooser';
import history from 'src/scripts/lib/history';
import * as browser from 'src/scripts/lib/browser';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class ColumnChooser extends React.Component {
  constructor(props) {
    super(props);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.getVisibleColumns = this.getVisibleColumns.bind(this);
    this.saveVisibleColumns = this.saveVisibleColumns.bind(this);
  }

  componentDidMount() {
    this.storageKey = `${history.location.pathname}-visible-columns`;
    let savedVisibleColumns = this.getVisibleColumns();
    if (this.columnConfigurationHasChanged(savedVisibleColumns)) {
      savedVisibleColumns = this.props.availableColumns.map((column) => {
        return { name: column, visible: true };
      });
      this.saveVisibleColumns(savedVisibleColumns);
    }
    this.props.updateVisibleColumns(savedVisibleColumns);
  }

  onCheckboxChange(column) {
    const visibleColumns = _.slice(this.props.visibleColumns);
    const visibleColumn = visibleColumns[_.findIndex(visibleColumns, { name: column.name })];
    visibleColumn.visible = !visibleColumn.visible;
    this.saveVisibleColumns(visibleColumns);
    this.props.updateVisibleColumns(visibleColumns);
  }

  getVisibleColumns() {
    return browser.getItem(this.storageKey);
  }

  columnConfigurationHasChanged(savedVisibleColumns) {
    return (
      !savedVisibleColumns ||
      !_.isEqual(
        this.props.availableColumns,
        savedVisibleColumns.map((col) => col.name)
      )
    );
  }

  saveVisibleColumns(columns) {
    browser.setItem(this.storageKey, columns);
  }

  render() {
    let className = 'pull-right visible-columns-dropdown';
    if (_.includes(history.location.pathname, `${TV_PATH}/videos`)) {
      className += '-videos';
    } else if (_.includes(history.location.pathname, '/seasons/')) {
      className += '-seasons';
    }

    return (
      <NavDropdownMenu
        className={className}
        buttonClass="btn btn-default btn-sm"
        iconClass="fa-columns"
        eventKey={1}
        bsStyle="info"
        id="column-chooser"
        component="span"
        displayCaret
      >
        {this.props.visibleColumns.map((column, index) => {
          return (
            <Input
              id={`column-chooser-${column.name.toLowerCase().replace(/ /g, '-')}`}
              type="checkbox"
              label={column.name}
              key={index}
              className="available-column"
              checked={column.visible === true}
              onChange={() => this.onCheckboxChange(column)}
            />
          );
        })}
      </NavDropdownMenu>
    );
  }
}

ColumnChooser.propTypes = {
  updateVisibleColumns: PropTypes.func,
  availableColumns: PropTypes.arrayOf(PropTypes.string),
  visibleColumns: PropTypes.arrayOf(PropTypes.object),
};

ColumnChooser.defaultProps = {
  availableColumns: [],
  visibleColumns: [],
};

function mapStateToProps(state) {
  return {
    visibleColumns: state.columnChooser.columns,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateVisibleColumns: (columns) => dispatch(updateVisibleColumns(columns)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnChooser);
