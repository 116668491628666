import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const InactiveWarningIconWithTooltip = () => {
  const tooltip = (
    <Tooltip id="future-inactive-tv-series-tooltip">
      <strong>Warning: At least 1 TV Series has or will have 0 active episodes in the next 6 days</strong>
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="right" overlay={tooltip}>
      <i className="future-inactive-tv-series-warning fa fa-exclamation-circle fa-3"></i>
    </OverlayTrigger>
  );
};

export default InactiveWarningIconWithTooltip;
