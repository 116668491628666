import Promise from 'bluebird';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import * as s3UploadHandler from 'src/scripts/actions/s3Upload';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';

export const completeCaptionDeleteDelay = 3000;

export const CAPTION_DELETE_STARTED = 'UI/CAPTION_DELETE_STARTED';
export const CAPTION_DELETE_SUCCEEDED = 'UI/CAPTION_DELETE_SUCCEEDED';
export const CAPTION_DELETE_FLOW_COMPLETED = 'CAPTION_DELETE_FLOW_COMPLETED';
export const CAPTION_DELETE_FAILED = 'UI/CAPTION_DELETE_FAILED';
export const CAPTION_STATE_RESET = 'UI/CAPTION_STATE_RESET';
export const CAPTION_UPLOAD_SUCCEEDED = 'UI/CAPTION_UPLOAD_SUCCEEDED';
export const CAPTION_UPLOAD_FAILED = 'UI/CAPTION_UPLOAD_FAILED';
export const CAPTION_UPLOAD_STARTED = 'UI/CAPTION_UPLOAD_STARTED';
export const CAPTION_UPLOAD_PROGRESS = 'UI/CAPTION_UPLOAD_PROGRESS';
export const CAPTION_UPLOAD_RESET = 'UI/CAPTION_UPLOAD_RESET';

export const deleteCaptionStart = (videoId) => ({
  type: CAPTION_DELETE_STARTED,
  payload: { videoId },
});

export const deleteCaptionSuccess = (videoId) => ({
  type: CAPTION_DELETE_SUCCEEDED,
  payload: { videoId },
});

export const deleteCaptionFailure = (videoId, errorMessage) => ({
  type: CAPTION_DELETE_FAILED,
  payload: { videoId, errorMessage },
});

export const captionStateReset = (videoId) => ({
  type: CAPTION_STATE_RESET,
  payload: { videoId },
});

export const captionDeleteFlowComplete = (videoId) => ({
  type: CAPTION_DELETE_FLOW_COMPLETED,
  payload: { videoId },
});

export const deleteCaption = (videoId) => {
  return (dispatch) => {
    return Promise.resolve().then(() => {
      dispatch(captionStateReset(videoId));
      dispatch(deleteCaptionStart(videoId));

      const onSuccess = () => {
        dispatch(deleteCaptionSuccess(videoId));
        return Promise.delay(completeCaptionDeleteDelay).then(() => {
          dispatch(captionDeleteFlowComplete(videoId));
        });
      };

      const onFailure = (error) => {
        const errorMessage = error.message || 'VideoVmsApi: Delete Caption Operation - Unknown Error';
        dispatch(deleteCaptionFailure(videoId, errorMessage));
      };

      return videosVmsApi.deleteCaption(videoId).then(onSuccess, onFailure);
    });
  };
};

export const uploadCaptionStart = () => ({
  type: CAPTION_UPLOAD_STARTED,
});

export const uploadCaptionSuccess = () => ({
  type: CAPTION_UPLOAD_SUCCEEDED,
});

export const uploadCaptionFailure = (errorMessage) => ({
  type: CAPTION_UPLOAD_FAILED,
  errorMessage,
});

export const uploadCaptionProgress = (numberOfParts, partNumber) => ({
  type: CAPTION_UPLOAD_PROGRESS,
  numberOfParts,
  partNumber,
});

export const uploadCaptionToS3 = (captionFilePointer) => {
  return (dispatch) => {
    const resourceGroup = VIDEOS.TV;
    const uploadType = 'caption';
    const uploadProgressCallback = (numberOfParts, partNumber) =>
      dispatch(uploadCaptionProgress(numberOfParts, partNumber));

    dispatch(uploadCaptionStart());

    return s3UploadHandler
      .uploadAsPromise(resourceGroup, null, captionFilePointer, uploadType, uploadProgressCallback)
      .then((uploadResult) => {
        dispatch(uploadCaptionSuccess());
        return uploadResult.s3Path;
      })
      .catch((error) => {
        const errorMessage = `Caption Upload Error: ${
          error.message || 'Error occurred while uploading file to S3'
        }`;
        dispatch(uploadCaptionFailure(errorMessage));
      });
  };
};
