import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

const YoutubeContentIdPanel = ({ season }) => {
  return (
    <Panel header="Youtube Content ID">
      <DetailsRowItem label="Channel">
        <FormatText>{season.defaultContentIdChannel}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Usage Policy">
        <FormatText>{season.defaultContentIdUsagePolicy}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Match Policy">
        <FormatText>{season.defaultContentIdMatchPolicy}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Ownership Strategy">
        <FormatText>{season.contentIdOwnershipStrategy}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Ownership Countries">
        <FormatText>
          {season.contentIdOwnershipCountries && season.contentIdOwnershipCountries.join(', ')}
        </FormatText>
      </DetailsRowItem>
    </Panel>
  );
};

YoutubeContentIdPanel.propTypes = {
  season: PropTypes.object,
};

export default YoutubeContentIdPanel;
