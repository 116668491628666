import PropTypes from 'prop-types';
import React from 'react';
import { Creatable } from 'react-select';
import { Panel, Input, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  REMOTE_EVENT,
  BROADCAST_EVENT,
  isRemoteEvent,
  isBroadcastEvent,
  isFastChannel,
  isMultiUrlEnabled,
} from 'src/scripts/lib/liveEventTypes';
import { TV } from 'src/scripts/lib/libraries';
import {
  multiUrlInputOptions,
  isMultiUrlObjectValid,
  multiUrlSkeletonObject,
  updateMultiUrlObject,
  getPropValueByRef,
} from 'src/scripts/lib/liveEventMultiUrls.js';
import * as zypeVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/zype';
import { PHOENIX_IDENTIFIER_TYPES } from '../../../lib/liveEventTypes';

const externalFastChannel = {
  id: 'external',
  title: 'External',
};

export class EventTypePanel extends React.Component {
  constructor(props) {
    super(props);
    const { materialKeys } = props.liveEvent;

    this.state = {
      fastChannels: [],
      identifierType: materialKeys
        ? PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS
        : PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES,
    };
  }

  componentDidMount() {
    this.fetchFastChannels();
  }

  fetchFastChannels = async () => {
    const fastChannels = await zypeVmsApi.getChannels();
    fastChannels.unshift(externalFastChannel);
    this.setState({ fastChannels });
  };

  onFastChannelChange = (event) => {
    this.props.onChange({
      fastChannel: event.target.value === 'unselected' ? '' : event.target.value,
    });
  };

  onRemoteURLChange = (event) => {
    this.props.onChange({
      remoteURL: event.target.value,
    });
  };

  onSsaiRemoteURLChange = (event) => {
    this.props.onChange({
      ssaiRemoteURL: event.target.value,
    });
  };

  handlePhoenixIdentifierTypeChange = (event) => {
    const identifierType = event.target.value;
    this.setState({ identifierType });
    this.resetPhoenixIdentifiers();
  };

  handlePhoenixIdentifierValueChange = (options) => {
    const optionValues = options.map((opt) => opt.value);
    this.updatePhoenixIdentifiers(optionValues);
  };

  resetPhoenixIdentifiers = () => {
    this.props.onChange({ materialKeys: null, catalogCodes: null });
  };

  updatePhoenixIdentifiers = (optionValues) => {
    const update =
      this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES
        ? { catalogCodes: optionValues }
        : { materialKeys: optionValues };
    this.props.onChange(update);
  };

  buildPhoenixIdentifierSelectValues = () => {
    const values = this.props.liveEvent[this.state.identifierType] || [];
    return values.map((value) => ({ label: value, value }));
  };

  onEventTypeChange = (event) => {
    this.props.onChange({
      eventType: event.target.value,
    });
  };

  onIsFastChannelChange = (event) => {
    const newProps = { isFastChannel: event.target.checked };
    if (!newProps.isFastChannel) newProps.fastChannel = '';
    this.props.onChange(newProps);
  };

  onIsMultiUrlEnabledChange = (event) => {
    const newProps = { multiUrlEnabled: event.target.checked };

    this.props.onChange(newProps);
  };

  onMultiUrlInputChange = (event, reference) => {
    if (isMultiUrlObjectValid(this.props.liveEvent.liveEventUrls)) {
      const newLiveEventUrls = updateMultiUrlObject(
        this.props.liveEvent.liveEventUrls,
        reference,
        event.target.value
      );
      this.props.onChange({ liveEventUrls: newLiveEventUrls });
    } else {
      const newLiveEventUrls = updateMultiUrlObject(multiUrlSkeletonObject, reference, event.target.value);
      this.props.onChange({ liveEventUrls: newLiveEventUrls });
    }
  };

  shouldShowMultiUrl = () => {
    return isRemoteEvent(this.props.liveEvent) && this.props.liveEvent.ssaiEnabled;
  };

  renderRemoteAssetUrlInput = () => {
    return (
      <Input
        labelClassName={isRemoteEvent(this.props.liveEvent) ? 'required' : ''}
        type="text"
        label="Remote Asset URL"
        placeholder="Enter remote URL"
        ref="remoteAssetUrl"
        onChange={this.onRemoteURLChange}
        value={isRemoteEvent(this.props.liveEvent) ? this.props.liveEvent.remoteURL : null}
        bsStyle={this.props.formValidationErrors.remoteURL ? 'error' : null}
        data-pw="remote-asset-url"
      />
    );
  };

  renderSsaiRemoteAssetUrlInput = () => {
    return (
      <Input
        labelClassName={
          isRemoteEvent(this.props.liveEvent) && this.props.liveEvent.ssaiEnabled ? 'required' : ''
        }
        type="text"
        label="SSAI Remote Asset URL"
        placeholder="Enter SSAI remote URL"
        ref="ssaiRemoteAssetUrl"
        onChange={this.onSsaiRemoteURLChange}
        disabled={this.props.liveEvent.ssaiEnabled === false}
        value={this.props.liveEvent.ssaiRemoteURL}
        bsStyle={this.props.formValidationErrors.ssaiRemoteURL ? 'error' : null}
        data-pw="ssai-remote-asset-url"
      />
    );
  };

  renderFastChannelInput = () => {
    return (
      <Input
        type="select"
        ref="liveEventFastChannel"
        label="FAST Channel"
        id="fast-channel-select"
        defaultValue="default"
        labelClassName="required"
        value={this.props.liveEvent && this.props.liveEvent.fastChannel}
        onChange={this.onFastChannelChange}
        bsStyle={this.props.formValidationErrors.fastChannel ? 'error' : null}
      >
        <option value="unselected">Select a FAST channel</option>
        {this.state &&
          this.state.fastChannels.map((option) => {
            return (
              <option key={option.title} value={option.id}>
                {option.title}
              </option>
            );
          })}
      </Input>
    );
  };

  renderMultiUrlInput = () => {
    return multiUrlInputOptions.map((group) => {
      return (
        <div key={group.label}>
          <h3>{group.label}</h3>
          {this.renderMultiUrlInputGroup(group)}
        </div>
      );
    });
  };

  renderMultiUrlInputGroup(group) {
    return (
      <div>
        {group.fields &&
          group.fields.map((field) => {
            return (
              <Input
                key={field.ref}
                labelClassName={'required'}
                type="text"
                label={field.label}
                placeholder={field.placeholder}
                ref={field.ref}
                value={getPropValueByRef(this.props.liveEvent.liveEventUrls, field.ref) || ''}
                onChange={(event) => this.onMultiUrlInputChange(event, field.ref)}
                bsStyle={this.props.formValidationErrors.remoteURL ? 'error' : null}
                data-pw={field.ref}
              />
            );
          })}
      </div>
    );
  }

  renderRemoteEventTypeInputs = () => {
    return (
      <div>
        {this.renderRemoteAssetUrlInput()}
        {this.props.liveEvent.ssaiEnabled ? this.renderSsaiRemoteAssetUrlInput() : null}
      </div>
    );
  };

  renderBroadcastEventTypeInputs = () => {
    return (
      <fieldset>
        <legend className={`control-label${isBroadcastEvent(this.props.liveEvent) ? ' required' : ''}`}>
          Phoenix Identifier
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover id="Phoenix Identifier">
                Enter either Catalogue Codes or Material Keys to map the Live Event to a Channel.
              </Popover>
            }
          >
            <span className="circle-space">
              <i className="fa fa-info-circle"></i>
            </span>
          </OverlayTrigger>
        </legend>
        <section
          className={`form-group ${
            this.props.formValidationErrors.phoenixIdentifier &&
            this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES
              ? 'error'
              : ''
          }`}
        >
          <Input
            type="radio"
            name="identifierType"
            value={PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES}
            label="Catalog Codes"
            checked={this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES}
            onChange={this.handlePhoenixIdentifierTypeChange}
          />
          <Creatable
            multi
            value={
              this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES
                ? this.buildPhoenixIdentifierSelectValues()
                : []
            }
            placeholder="Catalog codes"
            menuContainerStyle={{ display: 'none' }}
            onChange={this.handlePhoenixIdentifierValueChange}
            ref="catalogCodesInput"
            disabled={this.state.identifierType !== PHOENIX_IDENTIFIER_TYPES.CATALOG_CODES}
          />
        </section>
        <section
          className={`form-group ${
            this.props.formValidationErrors.phoenixIdentifier &&
            this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS
              ? 'error'
              : ''
          }`}
        >
          <Input
            type="radio"
            name="identifierType"
            value={PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS}
            label="Material Keys"
            checked={this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS}
            onChange={this.handlePhoenixIdentifierTypeChange}
          />
          <Creatable
            multi
            value={
              this.state.identifierType === PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS
                ? this.buildPhoenixIdentifierSelectValues()
                : []
            }
            placeholder="Material keys"
            menuContainerStyle={{ display: 'none' }}
            onChange={this.handlePhoenixIdentifierValueChange}
            ref="materialKeysInput"
            disabled={this.state.identifierType !== PHOENIX_IDENTIFIER_TYPES.MATERIAL_KEYS}
          />
        </section>
      </fieldset>
    );
  };

  render() {
    return (
      <Panel header="Event Type" bsStyle="primary">
        <div ref="eventTypeSection" className="event-type-section">
          <div className="flex-section">
            <div className="flex-child-grow-1">
              <span>
                <strong>Event Type</strong>
              </span>
              <Input
                type="radio"
                value={REMOTE_EVENT}
                label="Remote"
                ref="remoteEventRadio"
                checked={isRemoteEvent(this.props.liveEvent)}
                onClick={this.onEventTypeChange}
                disabled={!!this.props.liveEvent.id}
              />
              {this.props.library === TV ? (
                <Input
                  type="radio"
                  value={BROADCAST_EVENT}
                  label="Broadcast"
                  ref="broadcastEventRadio"
                  checked={isBroadcastEvent(this.props.liveEvent)}
                  onClick={this.onEventTypeChange}
                  disabled={!!this.props.liveEvent.id}
                />
              ) : null}
              {isRemoteEvent(this.props.liveEvent) && this.renderRemoteEventTypeInputs()}
              {isBroadcastEvent(this.props.liveEvent) && this.renderBroadcastEventTypeInputs()}
              {this.props.library === TV && (
                <Input
                  type="text"
                  label="External ID"
                  placeholder="Add an External ID for the live event"
                  onChange={(event) =>
                    this.props.onChange({
                      externalId: event.target.value,
                    })
                  }
                  value={this.props.liveEvent.externalId}
                  bsStyle={this.props.formValidationErrors.externalId ? 'error' : null}
                  ref="externalId"
                  data-pw="live-event-external-id"
                />
              )}
              <Input
                id={'isFastChannel'}
                type="checkbox"
                label="FAST Channel"
                className="available-column"
                checked={isFastChannel(this.props.liveEvent)}
                onChange={this.onIsFastChannelChange}
              />
              {this.shouldShowMultiUrl() && (
                <Input
                  id={'isMultiUrlEnabled'}
                  ref="multiUrlEnabledCheckbox"
                  type="checkbox"
                  label="Multi URL Enabled"
                  className="available-column"
                  checked={isMultiUrlEnabled(this.props.liveEvent)}
                  onChange={this.onIsMultiUrlEnabledChange}
                />
              )}
              {isFastChannel(this.props.liveEvent) && this.renderFastChannelInput()}
              {this.shouldShowMultiUrl() &&
                isMultiUrlEnabled(this.props.liveEvent) &&
                this.renderMultiUrlInput()}
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

EventTypePanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  liveEvent: PropTypes.object.isRequired,
  formValidationErrors: PropTypes.object.isRequired,
  library: PropTypes.string.isRequired,
};

export default EventTypePanel;
