import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import environment from 'config/environment';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import { getTVSeries, deleteTVSeries } from 'src/scripts/actions/tvSeries';
import * as pagination from 'src/scripts/lib/pagination';
import ItemListHeader, { FILTER_TYPES } from 'src/scripts/components/ItemList/ItemListHeader';
import { getGenres } from 'src/scripts/actions/genre';
import { TV_PATH } from 'src/scripts/lib/accessController';
import { isDefaultImage } from 'src/scripts/lib/util';
import { getCountriesOfOrigin } from 'src/scripts/actions/tvSeries';
import { resetItemList } from 'src/scripts/actions/itemList';
import ExportTvSeriesCatalog from 'src/scripts/components/TVSeries/internal/ExportTvSeriesCatalog';
import { LABELS, IMAGE_NOT_FOUND_URL } from '../../constants/common';
import TVSeriesActions from './TVSeriesActions';
import NotesWithTooltip from '../NotesWithTooltip';

const { CLEAR } = ActionButtonTypes;

export class TVSeries extends React.Component {
  constructor(props) {
    super(props);

    this.getTVSeries = this.getTVSeries.bind(this);
    this.loadDataForPage = this.loadDataForPage.bind(this);
    this.onCountriesOfOriginChange = this.onCountriesOfOriginChange.bind(this);
    this.onCustomCollectionChange = this.onCustomCollectionChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);

    this.state = {
      selectedCountries: null,
      selectedCustomCollections: null,
      countriesOfOrigin: [],
    };
  }

  componentDidMount() {
    this.props.getGenres();
    this.props.getCountriesOfOrigin();
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page || this.props.savedTvSeries) {
      this.loadDataForPage();
    }
  }

  getTVSeries(params = {}) {
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    const sort = typeof params.sort !== 'undefined' ? params.sort : this.props.sort;
    return this.props.getTVSeries({
      offset,
      limit: pagination.pageSize,
      filters: params.filters || this.props.filters,
      sort,
    });
  }

  onCountriesOfOriginChange(countries) {
    this.setState({
      selectedCountries: countries,
    });
  }

  onCustomCollectionChange(collections) {
    this.setState({
      selectedCustomCollections: collections,
    });
  }

  clearFilters() {
    this.setState({
      selectedCountries: null,
      selectedCustomCollections: null,
    });
    return this.props.resetItemList();
  }

  getImageUrl(tvSeriesItem) {
    if (tvSeriesItem.containsSeason[0] && tvSeriesItem.containsSeason[0].image) {
      return isDefaultImage(tvSeriesItem.containsSeason[0].image)
        ? IMAGE_NOT_FOUND_URL
        : tvSeriesItem.containsSeason[0].image.url;
    }
    return IMAGE_NOT_FOUND_URL;
  }

  getCustomCollection(recommendedCollectionId) {
    const customCollection = this.props.customCollections.find(
      (collection) => collection.id === recommendedCollectionId
    );
    return customCollection ? customCollection.name : null;
  }

  getCustomCollectionsUsingTvSeries = (tvSeriesId) => {
    const customCollectionsUsingTheTvSeries = [];
    const customCollections = this.props.customCollections;
    if (customCollections) {
      customCollections.forEach((collection) => {
        const tvSeries = collection.tvSeries
          ? collection.tvSeries.find((collectionTvSeries) => collectionTvSeries.id === tvSeriesId)
          : null;
        if (tvSeries) {
          customCollectionsUsingTheTvSeries.push(collection);
        }
      });
    }
    return customCollectionsUsingTheTvSeries;
  };

  renderCustomCollectionsNamesWithTooltip = (tvSeriesId) => {
    const collectionsName = this.getCustomCollectionsUsingTvSeries(tvSeriesId).map(
      (collection) => collection.name
    );
    const items = [];
    collectionsName.forEach((name) => {
      items.push(<li>{name}</li>);
    });
    const tooltip = (
      <Popover>{items.length ? items : 'No Custom Collection is using this TV Series'}</Popover>
    );
    return (
      <OverlayTrigger placement="right" overlay={tooltip}>
        <i className="fa fa-info-circle"></i>
      </OverlayTrigger>
    );
  };

  renderCustomCollectionsUsingTvSeries = (tvSeriesId) => {
    const collections = this.getCustomCollectionsUsingTvSeries(tvSeriesId);
    return (
      <div>
        <span className="custom-collections-count">
          <span>{collections.length}</span>{' '}
          <span>{this.renderCustomCollectionsNamesWithTooltip(tvSeriesId)}</span>
        </span>
      </div>
    );
  };

  loadDataForPage() {
    this.getTVSeries();
    this.props.getGenres();
  }

  render() {
    const genreSlugs = this.props.genres
      ? this.props.genres.map((genre) => {
          return genre.slug;
        })
      : [];
    const countriesOfOrigin = this.props.countriesOfOrigin ? this.props.countriesOfOrigin.sort() : [];
    const actionList = (tvSeriesItem) => {
      return <TVSeriesActions displayType="dropdown" tvSeries={tvSeriesItem} />;
    };
    const list = {
      pageData: this.props.tvSeries,
      totalCount: this.props.tvSeriesCount,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };
    return (
      <div id="tv-series-list">
        <ol className="mi9-breadcrumb breadcrumb">
          <li className="tv-series-breadcrumb">TV Series</li>
          <ExportTvSeriesCatalog className="export-tv-btn" tvSeries={this.props.tvSeries} />
        </ol>
        <ItemList
          id="list-tv-series"
          list={list}
          filters={this.props.filters}
          getItems={this.getTVSeries}
          defaultSort="+name"
          actionButtons={[CLEAR]}
          clearFilters={this.clearFilters}
        >
          <ItemListHeader
            label="Image"
            dataField="image"
            dataFormat={(tvSeriesItem) => {
              return (
                <span className="row-image-span">
                  {' '}
                  <img ref="img" className="row-image" src={this.getImageUrl(tvSeriesItem)} />
                </span>
              );
            }}
          />
          <ItemListHeader
            sort
            filter
            label="Name"
            dataField="name"
            dataFormat={(tvSeriesItem) => {
              return <a href={`#/${TV_PATH}/tv-series/${tvSeriesItem.id}/seasons`}>{tvSeriesItem.name}</a>;
            }}
          />
          <ItemListHeader
            label="Genre"
            dataField="genreSlugs"
            dataFormat={(tvSeriesItem) => {
              return tvSeriesItem.genre.name;
            }}
            filter={{ type: FILTER_TYPES.DROPDOWN, options: genreSlugs }}
          />
          <ItemListHeader
            label="Country of Origin"
            dataField="countryOfOrigin"
            multiDropdownSelectedItems={this.state.selectedCountries}
            multiDropdownOnChange={this.onCountriesOfOriginChange}
            filter={{
              type: FILTER_TYPES.MULTI_DROPDOWN,
              options: countriesOfOrigin.map((countryName) => [countryName, countryName]),
            }}
            filterClassName={'multi-dropdown-select'}
          />
          <ItemListHeader
            label={LABELS.RECOMMENDED_COLLECTION}
            dataField={'recommendedCollectionId'}
            multiDropdownSelectedItems={this.state.selectedCustomCollections}
            multiDropdownOnChange={this.onCustomCollectionChange}
            dataFormat={(tvSeriesItem) => {
              return (
                <a href={`#/tv/collections/${tvSeriesItem.recommendedCollectionId}`}>
                  {this.getCustomCollection(tvSeriesItem.recommendedCollectionId)}
                </a>
              );
            }}
            filter={{
              type: FILTER_TYPES.MULTI_DROPDOWN,
              options: this.props.customCollections.map((collection) => [collection.id, collection.name]),
            }}
            hasSelectAllOption
            filterClassName={'multi-dropdown-select'}
          />
          <ItemListHeader
            label="Recommended Collection Use"
            dataFormat={(tvSeriesItem) => {
              return this.renderCustomCollectionsUsingTvSeries(tvSeriesItem.id);
            }}
          />
          <ItemListHeader
            label="Notes"
            dataFormat={(tvSeriesItem) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip
                    notes={tvSeriesItem.producerNotes}
                    emptyText="No Notes for this Tv Series"
                  />
                </div>
              );
            }}
          />
          {environment.featureFlags.nineVideoFeaturesEnabled ? (
            <ItemListHeader label="Content Flag" dataField={'contentFlag'} />
          ) : null}
          {environment.featureFlags.nineVideoFeaturesEnabled ? (
            <ItemListHeader
              label="Use Sitecore CMS Source"
              dataFormat={(tvSeriesItem) => {
                return tvSeriesItem.useSitecoreCmsSource ? '✔' : '✖';
              }}
            />
          ) : null}
          <ItemListHeader label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader
            id="tv-series-actions-header"
            label="Actions"
            dataFormat={(tvSeriesItem) => {
              return actionList(tvSeriesItem);
            }}
          />
        </ItemList>
      </div>
    );
  }
}

TVSeries.propTypes = {
  getTVSeries: PropTypes.func,
  getGenres: PropTypes.func,
  getCountriesOfOrigin: PropTypes.func,
  deleteTVSeries: PropTypes.func,
  page: PropTypes.number,
  tvSeries: PropTypes.array,
  tvSeriesCount: PropTypes.number,
  genres: PropTypes.array,
  countriesOfOrigin: PropTypes.array,
  offset: PropTypes.number,
  savedTvSeries: PropTypes.object,
  sort: PropTypes.string,
  filters: PropTypes.object,
  customCollections: PropTypes.array,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    tvSeries: state.tvSeriesList.tvSeries,
    genres: state.tvSeriesList.genres,
    countriesOfOrigin: state.tvSeriesList.countriesOfOrigin,
    tvSeriesCount: state.tvSeriesList.tvSeriesCount,
    offset: state.tvSeriesList.offset,
    savedTvSeries: state.tvSeriesList.savedTvSeries,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
    customCollections: state.tvSeriesList.customCollections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteTVSeries: (tvSeriesId) => dispatch(deleteTVSeries(tvSeriesId)),
    getTVSeries: (pageData) => dispatch(getTVSeries(pageData)),
    resetItemList: () => dispatch(resetItemList()),
    getGenres: (pageData) => dispatch(getGenres(pageData)),
    getCountriesOfOrigin: (pageData) => dispatch(getCountriesOfOrigin(pageData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TVSeries);
