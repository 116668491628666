import $ from 'jquery';
import environment from 'config/environment';
import * as authService from 'src/scripts/actions/apiWrappers/authService';
import { generate as generateGuid } from 'src/scripts/lib/guidGenerator';

export function send(method, path, data) {
  return authService.getAccessToken().then(function (accessToken) {
    let cacheBustedPath = path;
    if (path.match(/\?/)) {
      cacheBustedPath = `${cacheBustedPath}&cachebuster=${generateGuid()}`;
    } else {
      cacheBustedPath = `${cacheBustedPath}?cachebuster=${generateGuid()}`;
    }
    return $.ajax({
      method,
      data,
      url: `${environment.endpoints.vmsApiUrl}/${cacheBustedPath}`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  });
}

export function getUrl(urlSuffix) {
  return `${environment.endpoints.vmsApiUrl}/${urlSuffix}`;
}

export async function sendWithFetch(method, path, params, signal, data) {
  const accessToken = await authService.getAccessToken();

  const queryParams = new URLSearchParams(
    params ? { cachebuster: generateGuid(), ...params } : { cachebuster: generateGuid() }
  );
  const url = `${environment.endpoints.vmsApiUrl}/${
    path.includes('?') ? `${path}&${queryParams}` : `${path}?${queryParams}`
  }`;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const options = {
    method,
    headers,
    signal,
  };

  if (['POST', 'PUT', 'PATCH'].includes(method.toUpperCase())) {
    options.body = JSON.stringify(data);
    options.headers = { ...options.headers, 'Content-Type': 'application/json;charset=utf-8' };
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error('Failed HTTP request', { cause: response });
  }

  return response;
}
