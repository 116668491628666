import * as requestHandler from '../requestHandler';
import { TAGS } from 'src/scripts/lib/resourceGroups';
import { getPrefix } from './endpointPrefixResolver';

// exported only to simplify testing
export function validateResourceGroup(resourceGroup) {
  switch (resourceGroup) {
    case TAGS.TV:
    case TAGS.NETWORK:
      return;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}

export function getTags(resourceGroup, tagName, includeTagCount) {
  validateResourceGroup(resourceGroup);
  const queryParm = includeTagCount ? '&includeTagCount=true' : '';
  return requestHandler.send('GET', `${getPrefix(resourceGroup)}/tags?name=like:${tagName}${queryParm}`);
}
