import PropTypes from 'prop-types';
import React from 'react';

class LIVE extends React.Component {
  render() {
    return this.props.children;
  }
}

LIVE.propTypes = {
  children: PropTypes.object,
};

export default LIVE;
