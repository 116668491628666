import Promise from 'bluebird';
import * as uploadHelper from 'src/scripts/actions/s3Upload/helper';

const PART_SIZE = 10 * 1024 * 1024;
const UPLOAD_CONCURRENCY = 2;

export function upload(
  resourceGroup,
  assetId,
  file,
  uploadType,
  progressCallback,
  createUploadJobArgument,
  completeUploadJobArgument,
  uploadOnePartArgument,
  uploadPartsArgument
) {
  const createUploadJob = createUploadJobArgument || uploadHelper.createUploadJob;
  const uploadParts = uploadPartsArgument || uploadHelper.uploadParts;
  const completeUploadJob = completeUploadJobArgument || uploadHelper.completeUploadJob;
  const uploadOnePart = uploadOnePartArgument || uploadHelper.uploadOnePart;
  return createUploadJob(resourceGroup, assetId, file.name, uploadType).flatMap((arg) => {
    const uploadId = arg.uploadId;
    const bucket = arg.bucket;
    const s3Path = arg.s3Path;
    return uploadParts(
      resourceGroup,
      s3Path,
      uploadId,
      file,
      PART_SIZE,
      UPLOAD_CONCURRENCY,
      uploadType,
      progressCallback,
      uploadOnePart
    )
      .flatMap((partsInfo) => {
        return completeUploadJob(resourceGroup, s3Path, uploadId, partsInfo, uploadType);
      })
      .map(() => {
        return {
          bucket,
          s3Path,
        };
      });
  });
}

export function uploadAsPromise(resourceGroup, assetId, captionFilePointer, uploadType, progressCallback) {
  return new Promise((resolve, reject) => {
    return upload(resourceGroup, assetId, captionFilePointer, uploadType, progressCallback).subscribe(
      resolve,
      reject
    );
  });
}
