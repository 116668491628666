import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_COLLECTION_FAILED,
  SAVE_COLLECTION_SUCCEEDED,
  UPDATE_COLLECTION_FORM,
  GET_COLLECTION_SUCCEEDED,
  GET_COLLECTION_FAILED,
  PUSH_TO_TV_SERIES_TO_USE_COLLECTION,
  REMOVE_FROM_TV_SERIES_TO_USE_COLLECTION,
} from 'src/scripts/actions/collection';

import { GET_TV_SERIES_LIST_SUCCEEDED } from 'src/scripts/actions/tvSeries';

import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/collectionForm';

export const initialState = {
  collection: {},
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  tvSeriesUsingCollection: [],
  tvSeriesToUseCollection: [],
};

const replaceCollection = (state, collection) => ({
  ...state,
  collection,
});

const updateCollection = (state, collection) => ({
  ...state,
  collection: {
    ...state.collection,
    ...collection,
  },
});

const updateValidationErrors = (state, validationErrors) => ({
  ...state,
  validationErrors,
});

const updateErrorMessage = (state, errorMessage) => ({
  ...state,
  errorMessage,
});

const showValidationErrors = (state) => ({
  ...state,
  showValidationErrors: true,
});

const updateTvSeriesUsingCollection = (state, tvSeriesUsingCollection) => ({
  ...state,
  tvSeriesUsingCollection,
});

const updateTvSeriesToUseCollection = (state, tvSeriesToUseCollection) => ({
  ...state,
  tvSeriesToUseCollection,
});

const updateAllErrors = (state, validationErrors, errorMessage) =>
  updateErrorMessage(updateValidationErrors(state, validationErrors), errorMessage);

const collectionFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COLLECTION_SUCCEEDED:
      return replaceCollection(state, action.data);

    case SAVE_COLLECTION_FAILED:
    case GET_COLLECTION_FAILED:
      return updateErrorMessage(state, action.error);

    case SAVE_COLLECTION_SUCCEEDED:
      return initialState;

    case UPDATE_COLLECTION_FORM:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.collection, ...action.collection },
          getValidationRules()
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        const updatedCollection = updateCollection(state, action.collection);
        return updateAllErrors(updatedCollection, validationErrors, errorMessage);
      }
      return updateCollection(state, action.collection);

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.collection, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      const updatedCollection = updateAllErrors(state, validationErrors, errorMessage);
      return showValidationErrors(updatedCollection);

    case GET_TV_SERIES_LIST_SUCCEEDED:
      return updateTvSeriesToUseCollection(
        updateTvSeriesUsingCollection(state, action.data.tvSeries),
        action.data.tvSeries
      );

    case PUSH_TO_TV_SERIES_TO_USE_COLLECTION: {
      const nextTvSeriesToUseCollection = [...state.tvSeriesToUseCollection];
      nextTvSeriesToUseCollection.push(action.data.tvSeries);
      return updateTvSeriesToUseCollection(state, nextTvSeriesToUseCollection);
    }

    case REMOVE_FROM_TV_SERIES_TO_USE_COLLECTION: {
      const nextTvSeriesToUseCollection = state.tvSeriesToUseCollection.filter(
        (tvSeries) => tvSeries.id !== action.data.tvSeries.id
      );
      return updateTvSeriesToUseCollection(state, nextTvSeriesToUseCollection);
    }

    case RESET_FORM:
      return initialState;

    default:
      return state;
  }
};

export default collectionFormReducer;
