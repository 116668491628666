import PropTypes from 'prop-types';
import React from 'react';
import history from 'src/scripts/lib/history';

class ResourceBrowser extends React.Component {
  constructor(props) {
    super(props);

    this.selectPage = this.selectPage.bind(this);
  }

  componentDidMount() {
    this.selectPageOneIfInvalidPage();
  }

  componentDidUpdate() {
    this.selectPageOneIfInvalidPage();
  }

  selectPage(pageNumber) {
    history.push({ state: null, pathname: this.props.location.pathname, query: { page: pageNumber } });
  }

  selectPageOneIfInvalidPage() {
    const page = parseInt(this.props.location.query.page, 10);
    if (!page) {
      return history.replace({ state: null, pathname: this.props.location.pathname, query: { page: 1 } });
    }
    return undefined;
  }

  render() {
    const page = parseInt(this.props.location.query.page, 10);
    return React.cloneElement(this.props.children, {
      ...this.props,
      selectPage: this.selectPage,
      page,
    });
  }
}

ResourceBrowser.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default ResourceBrowser;
