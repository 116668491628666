import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { DeviceToggle } from './internal/DeviceToggle';
import environment from 'config/environment';
import { getCdnNameList } from '../../lib/cdnConfiguration';
import { getIsCheckedBooleanField, getIsCheckedCdnField } from './internal/deviceViewHelper';
import { showCdnToggles } from '../../lib/util';

export class DeviceView extends Component {
  renderBreadcrumb = () => (
    <ol className={'mi9-breadcrumb breadcrumb'}>
      <li>Devices</li>
    </ol>
  );

  renderName = () => <ItemListHeader label={'Device'} dataField={'name'} />;

  renderToggle =
    (field, onChangeFn, getIsCheckedFn = getIsCheckedBooleanField) =>
    (device) => (
      <DeviceToggle
        isChecked={getIsCheckedFn(device, field)}
        status={device[`${field}Status`]}
        device={device}
        onChange={onChangeFn}
      />
    );

  renderEnabledSsaiForChannels = () => (
    <ItemListHeader
      label={'Ad Insertion Enabled for Channels'}
      dataFormat={this.renderToggle('enabledSsaiForChannels', this.props.updateDeviceSsai)}
    />
  );

  renderEnabledSsaiForLiveEvents = () => (
    <ItemListHeader
      label={'Ad Insertion Enabled for Live Events'}
      dataFormat={this.renderToggle('enabledSsaiForLiveEvents', this.props.updateDeviceSsai)}
    />
  );

  renderDrmEnabled = () => {
    if (environment.featureFlags.showDeviceDrmToggle) {
      return (
        <ItemListHeader
          label={'DRM Enabled'}
          dataFormat={this.renderToggle('drmEnabled', this.props.updateDeviceDrm)}
        />
      );
    }

    return null;
  };

  renderFullHdEnabled = () => {
    if (environment.featureFlags.showDeviceFullHdToggle) {
      return (
        <ItemListHeader
          label={'Full HD Enabled'}
          dataFormat={this.renderToggle('fullHdEnabled', this.props.updateDeviceFullHd)}
        />
      );
    }

    return null;
  };

  renderStartOverEnabled = () => {
    if (environment.featureFlags.showDeviceStartOverToggle) {
      return (
        <ItemListHeader
          label={'Start Over Enabled'}
          dataFormat={this.renderToggle('startOverEnabled', this.props.updateDeviceStartOver)}
        />
      );
    }

    return null;
  };

  renderTokenisationEnabled = () => {
    if (environment.featureFlags.showDeviceTokenisationToggle) {
      return (
        <ItemListHeader
          label={'Tokenisation Enabled'}
          dataFormat={this.renderToggle('tokenisationEnabled', this.props.updateDeviceTokenisation)}
        />
      );
    }

    return null;
  };

  renderManifestFilteringEnabled = () => {
    if (environment.featureFlags.showDeviceManifestFilteringToggle) {
      return (
        <ItemListHeader
          label={'Manifest Filtering Enabled'}
          dataFormat={this.renderToggle('manifestFilteringEnabled', this.props.updateDeviceManifestFiltering)}
        />
      );
    }

    return null;
  };

  renderCdnEnabled = () =>
    getCdnNameList().map((cdnName) => (
      <ItemListHeader
        label={`CDN(${cdnName}) Enabled`}
        dataFormat={this.renderToggle(
          `cdn${cdnName}Enabled`,
          this.props.updateDeviceCdn[cdnName],
          getIsCheckedCdnField(cdnName)
        )}
      />
    ));

  renderList = () => (
    <ItemList
      id={'list-device'}
      list={{
        pageData: this.props.devices.rows,
        totalCount: this.props.devices.count,
      }}
      getItems={this.props.listDevices}
    >
      {this.renderName()}
      {this.renderEnabledSsaiForChannels()}
      {this.renderEnabledSsaiForLiveEvents()}
      {this.renderDrmEnabled()}
      {this.renderFullHdEnabled()}
      {this.renderStartOverEnabled()}
      {this.renderTokenisationEnabled()}
      {this.renderManifestFilteringEnabled()}
      {showCdnToggles() && this.renderCdnEnabled()}
    </ItemList>
  );

  render = () => (
    <div>
      {this.renderBreadcrumb()}
      {this.renderList()}
    </div>
  );
}

DeviceView.propTypes = {
  devices: PropTypes.object,
  listDevices: PropTypes.func,
  updateDeviceSsai: PropTypes.func,
  updateDeviceDrm: PropTypes.func,
  updateDeviceFullHd: PropTypes.func,
  updateDeviceStartOver: PropTypes.func,
  updateDeviceCdn: PropTypes.object,
  updateDeviceTokenisation: PropTypes.func,
  updateDeviceManifestFiltering: PropTypes.func,
};
