/*eslint-disable */
import $ from 'jquery';
import authenticationContext, {
  getAuthenticationContext,
} from 'src/scripts/actions/apiWrappers/authenticationContextProvider';
import environment from 'config/environment';

import Promise from 'bluebird';

function isMainWindow() {
  if (!window.parent) {
    return true;
  }
  return window === window.parent;
}

const auth = authenticationContext;

export function handleAzureCallback() {
  auth.handleWindowCallback();
  return isMainWindow();
}

export function getCachedToken() {
  return auth.getCachedToken(environment.authorization.azureClientId);
}

export function getAccessToken() {
  const deferred = $.Deferred();
  environment &&
    auth.acquireToken(environment.authorization.azureClientId, function (error, data) {
      if (error === 'User login is required') {
        auth.login();
        return deferred.reject(error);
      }
      if (error) {
        return deferred.reject(error);
      }
      return deferred.resolve(data);
    });

  return deferred.promise();
}

export function loginToAzure() {
  auth.login();
}

export function logout() {
  auth.logOut();
}
