import { HAMBURGER_NAVBAR_TOGGLED, HAMBURGER_NAVBAR_CLOSED } from 'src/scripts/actions/ui/navigation';

export const initialState = {
  hamburger: {
    isOpen: false,
  },
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case HAMBURGER_NAVBAR_TOGGLED:
      return {
        ...state,
        hamburger: {
          ...state.hamburger,
          isOpen: !state.hamburger.isOpen,
        },
      };
    case HAMBURGER_NAVBAR_CLOSED:
      return {
        ...state,
        hamburger: {
          ...state.hamburger,
          isOpen: false,
        },
      };
    default:
      return state;
  }
};

export default navigationReducer;

export const getHamburgerNavbarOpenState = (state) => state.ui.navigation.hamburger.isOpen;
