import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Button, Panel } from 'react-bootstrap';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import DateTimePicker from 'src/scripts/components/DateTimePicker';

const VALID_DATE_FORMATS = ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY', moment.ISO_8601];
// Move valid date formats to a const somewhere external, as is also being used in bulk edit form

export default class ClipDropZonePromptForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().format(),
    };
  }

  handleConfirmClick = () => {
    this.props.onConfirm(this.state.startDate);
  };

  isSubmitDisabled = () => {
    const validationError = this.getValidationError();
    return !!validationError || !this.state.startDate;
  };

  isValidStartDate = (startDate) => {
    return moment(startDate, VALID_DATE_FORMATS, true).isValid();
  };

  getValidationError = () => {
    if (this.state.startDate && !this.isValidStartDate(this.state.startDate)) {
      return 'Start Date is not a valid date format.';
    }
    return null;
  };

  onDateChange = (startDate) => {
    if (!this.isValidStartDate(startDate)) {
      this.setState({ startDate });
    } else {
      const formattedStartDate = moment(startDate, VALID_DATE_FORMATS).format();
      this.setState({ startDate: formattedStartDate });
    }
  };

  render() {
    return (
      <div>
        <Modal.Header>
          <Modal.Title ref="modalTitle">Start Date & Time</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Panel header="Start Date & Time" bsStyle="primary">
            <DateTimePicker
              ref="publishStartDate"
              id="start-date-picker"
              onChange={this.onDateChange}
              value={this.state.startDate}
              placeholder="Enter start date"
            />
          </Panel>
          {this.getValidationError() ? (
            <FormErrorMessage className="clip-server-error-message" message={this.getValidationError()} />
          ) : null}
          <div className="form__submit-button-bar">
            <Button
              id="video-modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.onClose}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              onClick={this.handleConfirmClick}
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          </div>
        </Modal.Body>
      </div>
    );
  }
}

ClipDropZonePromptForm.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};
