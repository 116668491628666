import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { getTVSeries, getCollections } from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import json2csv from 'json2csv';
import { exportCatalogFile } from 'src/scripts/lib/downloadFile';
import { TVSERIES_FIELDS } from 'src/scripts/lib/exportCatalogFields';

const pageSize = 500;

// export for testing

export const getCollectionsAndMapItWithTVSeries = async (tvSeriesList) => {
  const customCollections = await getCollections({ type: 'CUSTOM' });
  if (!customCollections || !customCollections.collections) {
    return tvSeriesList;
  }
  const customCollectionMap = customCollections.collections.reduce(
    (collectionsListById, currentCollection) => {
      collectionsListById[currentCollection.id] = currentCollection;
      return collectionsListById;
    },
    {}
  );

  const tvSeriesListWithCollectionName = tvSeriesList.map((resultItem) => {
    const { recommendedCollectionId } = resultItem;
    const recommendedCollection = customCollectionMap[recommendedCollectionId];
    if (!recommendedCollectionId || !recommendedCollection) return resultItem;
    return { ...resultItem, recommendedCollectionName: recommendedCollection.name };
  });

  return tvSeriesListWithCollectionName;
};

export class ExportTvSeriesCatalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
    };
  }

  exportCatalog = async () => {
    this.setState({ isExporting: true });
    const params = {
      offset: 0,
      sort: '-updatedAt',
      limit: pageSize,
    };
    let result = await getTVSeries(params);
    const tvSeriesList = [...result.tvSeries];
    const pageNo = Math.ceil(result.count / pageSize);
    if (pageNo > 1) {
      for (let i = 1; i <= pageNo; i++) {
        result = await getTVSeries({ ...params, offset: i * pageSize });
        tvSeriesList.push(...result.tvSeries);
      }
    }
    const tvSeriesCollectionsMap = await getCollectionsAndMapItWithTVSeries(tvSeriesList);
    const csv = json2csv.parse(tvSeriesCollectionsMap, {
      fields: TVSERIES_FIELDS,
      unwind: ['tvSeries', 'containsSeason'],
      withBOM: true,
      header: true,
    });
    exportCatalogFile(csv, 'tvSeries');
    this.setState({ isExporting: false });
  };

  render() {
    return (
      <Button
        bsSize="small"
        className="export-tv-btn btn-sm btn btn-default"
        disabled={this.state.isExporting || !this.props.tvSeries}
        onClick={this.exportCatalog}
      >
        {this.state.isExporting ? 'Export in Progress' : 'Export catalog'}
      </Button>
    );
  }
}

ExportTvSeriesCatalog.propTypes = {
  tvSeries: PropTypes.array,
};

export default ExportTvSeriesCatalog;
