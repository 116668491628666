export const IMAGE_16_BY_9 = '16:9';
export const IMAGE_3_BY_1 = '3:1';
export const IMAGE_2_BY_3 = '2:3';
export const IMAGE_1_BY_1 = '1:1';
export const IMAGE_49_BY_18 = '49:18';
export const IMAGE_4_BY_5 = '4:5';
export const IMAGE_ANY = 'any';

export const IMAGE_SIZE_16_BY_9 = {
  ratio: 16 / 9,
  minHeight: 576,
  minWidth: 1024,
  cssClassName: 'horizontal-image',
  name: IMAGE_16_BY_9,
};

export const IMAGE_SIZE_3_BY_1 = {
  ratio: 3 / 1,
  minHeight: 224,
  minWidth: 672,
  name: IMAGE_3_BY_1,
  cssClassName: 'logo-image',
};

export const IMAGE_SIZE_2_BY_3 = {
  ratio: 2 / 3,
  minHeight: 1080,
  minWidth: 720,
  name: IMAGE_2_BY_3,
  cssClassName: 'poster-image',
};

export const IMAGE_SIZE_1_BY_1 = {
  ratio: 1 / 1,
  minHeight: 200,
  minWidth: 200,
  name: IMAGE_1_BY_1,
  cssClassName: 'channel-switcher-logo-image',
};

export const IMAGE_SIZE_4_BY_5 = {
  ratio: 4 / 5,
  minHeight: 1000,
  minWidth: 800,
  name: IMAGE_4_BY_5,
  cssClassName: 'centered_image',
};

export const IMAGE_SIZE_49_BY_18 = {
  ratio: 49 / 18,
  minHeight: 1080,
  minWidth: 2940,
  name: IMAGE_49_BY_18,
  cssClassName: 'carousel-image',
};

export const IMAGE_SIZE_ANY = {
  ratio: 0,
  minHeight: 0,
  minWidth: 0,
  name: IMAGE_ANY,
  cssClassName: 'any-image',
};
