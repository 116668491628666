import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Col } from 'react-bootstrap';
import DetailsFieldSet from 'src/scripts/components/MediaItem/DetailsFieldSet';

class DetailsRow extends React.Component {
  render() {
    return (
      <div
        ref="detailsSectionPanel"
        className={`details-section-panel ${this.props.showGrid === true ? 'show-grid' : ''}`}
      >
        <Col md={this.props.leftMarginColumn} />
        <Col md={this.props.centerColumn}>
          <Panel className={this.props.className} collapsible defaultExpanded header={this.props.header}>
            {this.props.children}
            {this.props.detailsList.map((row, index) => {
              return (
                <DetailsFieldSet
                  videoId={this.props.videoId}
                  className="details-field-set"
                  key={index}
                  label={row.label}
                  value={row.value}
                  url={row.url}
                  list={row.list}
                />
              );
            })}
          </Panel>
        </Col>
        <Col md={this.props.rightMarginColumn} />
      </div>
    );
  }
}

DetailsRow.defaultProps = {
  detailsList: [],
};

DetailsRow.propTypes = {
  children: PropTypes.any,
  showGrid: PropTypes.bool,
  videoId: PropTypes.number,
  leftMarginColumn: PropTypes.number,
  centerColumn: PropTypes.number,
  rightMarginColumn: PropTypes.number,
  header: PropTypes.string,
  className: PropTypes.string,
  detailsList: PropTypes.array,
};

export default DetailsRow;
