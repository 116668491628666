import {
  GET_SUBCATEGORIES_SUCCEEDED,
  GET_SUBCATEGORIES_FAILED,
  DELETE_SUBCATEGORY_SUCCEEDED,
} from 'src/scripts/actions/subcategory';
import { immutableRemoveById } from 'src/scripts/lib/util';
import _ from 'lodash';

export const initialState = {
  subcategories: [],
  totalCount: 0,
  errorMessage: null,
};

function sortSubcategories(subcategories) {
  return _.sortBy(subcategories, (item) => item.name.toLowerCase());
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBCATEGORIES_SUCCEEDED:
      return {
        ...state,
        subcategories: sortSubcategories(action.data.subcategories),
        totalCount: action.data.count,
      };

    case GET_SUBCATEGORIES_FAILED:
      return { ...state, errorMessage: action.error };

    case DELETE_SUBCATEGORY_SUCCEEDED:
      return { ...state, subcategories: immutableRemoveById(state.subcategories, action.id) };

    default:
      return state;
  }
}
