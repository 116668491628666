import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import * as pagination from 'src/scripts/lib/pagination';
import { startUpload } from 'src/scripts/actions/video';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { closeModal } from 'src/scripts/actions/modalItem';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { getCategories } from 'src/scripts/actions/category';
import { THIRD_PARTY_ORIGIN_TYPE, TV_ORIGIN_TYPE } from 'src/scripts/lib/contentOriginTypes';

export const NETWORK_CLIP_UPDATED = 'NETWORK_CLIP_UPDATED';
export const SAVE_NETWORK_CLIP_STARTED = 'SAVE_NETWORK_CLIP_STARTED';
export const SAVE_NETWORK_CLIP_FAILED = 'SAVE_NETWORK_CLIP_FAILED';
export const SAVE_NETWORK_CLIP_SUCCEEDED = 'SAVE_NETWORK_CLIP_SUCCEEDED';
export const GET_ALL_NETWORK_CLIPS_SUCCEEDED = 'GET_ALL_NETWORK_CLIPS_SUCCEEDED';
export const GET_ALL_NETWORK_CLIPS_FAILED = 'GET_ALL_NETWORK_CLIPS_FAILED';

export const GET_NETWORK_CLIP_SUCCEEDED = 'GET_NETWORK_CLIP_SUCCEEDED';
export const GET_NETWORK_CLIP_FAILED = 'GET_NETWORK_CLIP_FAILED';

export const DELETE_NETWORK_CLIP_SUCCEEDED = 'DELETE_NETWORK_CLIP_SUCCEEDED';
export const GET_NETWORK_CLIPS_SUCCEEDED = 'GET_NETWORK_CLIPS_SUCCEEDED';
export const GET_NETWORK_CLIPS_FAILED = 'GET_NETWORK_CLIPS_FAILED';
export const GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED =
  'GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED';
export const GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_FAILED = 'GET_NETWORK_CLIP_FORM_INITIAL_DATA_FAILED';
export const GET_NETWORK_CLIP_FORM_INITIAL_DATA_SUCCEEDED = 'GET_NETWORK_CLIP_FORM_INITIAL_DATA_SUCCEEDED';
export const GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_SUCCEEDED =
  'GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_SUCCEEDED';
export const GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_FAILED = 'GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_FAILED';

export function updateNetworkClip(networkClip) {
  return {
    type: NETWORK_CLIP_UPDATED,
    networkClip,
  };
}

export function saveNetworkClip(networkClip) {
  return (dispatch) => {
    const saveMethod = networkClip.id ? networkVmsApi.updateNetworkClip : networkVmsApi.createNetworkClip;
    dispatch({
      type: SAVE_NETWORK_CLIP_STARTED,
    });
    return saveMethod(networkClip)
      .done((createdClipResponse) => {
        dispatch({
          type: SAVE_NETWORK_CLIP_SUCCEEDED,
          data: createdClipResponse,
        });
        if (networkClip.video.file) {
          dispatch(
            startUpload(VIDEOS.NETWORK, {
              videoId: createdClipResponse.clip.videoId,
              file: networkClip.video.file,
              uploadType: 'video',
            })
          );
        }
        dispatch(closeModal());
      })
      .fail((response) => {
        dispatch({
          type: SAVE_NETWORK_CLIP_FAILED,
          error: response.responseJSON.message,
        });
      });
  };
}

export function rescheduleAndSaveClip(clip) {
  return (dispatch) => {
    dispatch({
      type: SAVE_NETWORK_CLIP_STARTED,
    });
    const onSuccess = (savedClip) => {
      dispatch({
        type: SAVE_NETWORK_CLIP_SUCCEEDED,
        data: savedClip,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_NETWORK_CLIP_FAILED,
        error: response.responseJSON.message,
      });
    };
    networkVmsApi.rescheduleNetworkClip(clip).then(onSuccess, onError);
  };
}

export function saveAndPublishNetworkClip(clip) {
  return async (dispatch) => {
    dispatch({ type: SAVE_NETWORK_CLIP_STARTED });
    const onSuccess = (updatedClip) => {
      dispatch({
        type: SAVE_NETWORK_CLIP_SUCCEEDED,
        data: updatedClip,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_NETWORK_CLIP_FAILED,
        error: response.responseJSON.message,
      });
    };
    try {
      await networkVmsApi.updateNetworkClip(clip);
      const updatedClip = await networkVmsApi.notifyWorkflowAction('approve-and-activate', clip.id);
      onSuccess(updatedClip);
    } catch (err) {
      onError(err);
    }
  };
}

export function getAllNetworkClips(params = {}) {
  return (dispatch) => {
    const offset = params.offset || 0;
    const limit = params.limit || pagination.pageSize;
    const sort = params.sort || '-updatedAt';
    const search = params.search ? params.search : null;
    const request = {
      offset,
      limit,
      sort,
      search,
      ...params.filters,
    };

    const onSuccess = (data) => {
      dispatch({
        type: GET_ALL_NETWORK_CLIPS_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ALL_NETWORK_CLIPS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getAllNetworkClips(request).then(onSuccess, onError);
  };
}

export function deleteNetworkClipAndCloseConfirmation(networkClipId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_NETWORK_CLIP_SUCCEEDED,
        id: networkClipId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.deleteNetworkClip(networkClipId).then(onSuccess, onError);
  };
}

export const getNetworkClip = (networkClipId) => (dispatch) => {
  const onSuccess = (data) => {
    dispatch({
      type: GET_NETWORK_CLIP_SUCCEEDED,
      data,
    });
  };

  const onError = (response) => {
    dispatch({
      type: GET_NETWORK_CLIP_FAILED,
      error: response.responseJSON.message,
    });
  };
  return networkVmsApi.getNetworkClipById(networkClipId).then(onSuccess, onError);
};

export function getNetworkClips(params = {}) {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit ? params.limit : pagination.pageSize;
  const sort = params.sort ? params.sort : '-createdAt';
  const request = {
    subcategoryId: params.subcategoryId,
    offset,
    limit,
    sort,
    ...params.filters,
  };
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_NETWORK_CLIPS_SUCCEEDED,
        data,
        offset,
      });
    };
    const onError = (response) => {
      dispatch({
        type: GET_NETWORK_CLIPS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getNetworkClips(request).then(onSuccess, onError);
  };
}

function getNetworkClipContentOriginAssociatedData(data) {
  if (data.clip.contentOriginType === THIRD_PARTY_ORIGIN_TYPE) {
    return networkVmsApi
      .getNetworkThirdPartyProviders()
      .then(({ thirdPartyProviders }) => ({ ...data, thirdPartyProviders }));
  }
  if (data.clip.contentOriginType === TV_ORIGIN_TYPE) {
    const tvSeriesId = data.clip.contentOriginSeason.tvSeriesId;
    return tvVmsApi.getSeasons({ tvSeriesId }).then(({ seasons }) => ({ ...data, seasons }));
  }
  return data;
}

export function getNetworkClipAndAssociatedData(networkClipId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED,
        data,
      });
    };
    const onError = (error) => {
      dispatch({
        type: GET_NETWORK_CLIP_AND_ASSOCIATED_DATA_FAILED,
        error: error.responseJSON.message,
      });
    };
    return networkVmsApi
      .getNetworkClipById(networkClipId)
      .then((data) => {
        return networkVmsApi.getCategories().then(({ categories }) => ({ ...data, categories }));
      })
      .then((data) => {
        return networkVmsApi
          .getSubcategories(data.clip.partOfCategory.id)
          .then(({ subcategories }) => ({ ...data, subcategories }));
      })
      .then((data) => {
        if (data.clip.video.status === 'NEW') return data;
        return videosVmsApi.getSignedVideoSourceUrl(VIDEOS.NETWORK, data.clip.video.id).then((res) => {
          data.clip.video.signedS3Url = res.url;
          return data;
        });
      })
      .then((data) => {
        return getNetworkClipContentOriginAssociatedData(data);
      })
      .then(onSuccess, onError);
  };
}

export function getNetworkClipFormInitialData(networkClipId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: GET_NETWORK_CLIP_FORM_INITIAL_DATA_SUCCEEDED,
      });
    };
    if (networkClipId) {
      return dispatch(getNetworkClipAndAssociatedData(networkClipId)).then(onSuccess);
    }
    return dispatch(getCategories()).then(onSuccess);
  };
}

export function getThirdPartyProviders() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_SUCCEEDED,
        data,
      });
    };
    const onError = (error) => {
      dispatch({
        type: GET_NETWORK_CLIP_THIRD_PARTY_PROVIDERS_FAILED,
        error: error.responseJSON.message,
      });
    };
    return networkVmsApi.getNetworkThirdPartyProviders().then(onSuccess, onError);
  };
}
