import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as addCollectionActions from 'src/scripts/actions/addCollection';
import { isFormValid } from 'src/scripts/lib/formValidation';
import * as formActions from 'src/scripts/actions/form';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/addCollectionForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

const mapStateToProps = (state) => ({
  validationErrors: state.addCollectionForm.validationErrors,
  collection: state.addCollectionForm.collection,
  errorMessage: state.addCollectionForm.errorMessage,
  availableGenres: state.addCollectionForm.availableGenres,
});

const mapDispatchToProps = (dispatch) => ({
  updateAddCollectionForm: (collection) => dispatch(addCollectionActions.updateAddCollectionForm(collection)),
  createCollection: (collection) => dispatch(addCollectionActions.createCollection(collection)),
  getAvailableGenres: () => dispatch(addCollectionActions.getAvailableGenres()),
  showFormValidation: () => dispatch(formActions.showFormValidation()),
});

export class AddCollectionForm extends Component {
  static propTypes = {
    updateAddCollectionForm: PropTypes.func,
    collection: PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      programmingNotes: PropTypes.string,
      genreId: PropTypes.number,
    }),
    validationErrors: PropTypes.object,
    createCollection: PropTypes.func,
    getAvailableGenres: PropTypes.func,
    showFormValidation: PropTypes.func,
    close: PropTypes.func,
    errorMessage: PropTypes.string,
    availableGenres: PropTypes.array,
  };

  onNameChange = (event) => {
    this.props.updateAddCollectionForm({ name: event.target.value });
  };

  onProgrammingNotesChange = (event) => {
    this.props.updateAddCollectionForm({ programmingNotes: event.target.value });
  };

  onTypeChange = (event) => {
    const selectedType = event.target.value;
    const updateFormParams = { type: selectedType };

    if (selectedType === 'GENRE') {
      this.props.getAvailableGenres();
    }
    if (selectedType === 'CUSTOM') {
      updateFormParams.genreId = null;
    }
    this.props.updateAddCollectionForm(updateFormParams);
  };

  onGenreChange = (event) => {
    this.props.updateAddCollectionForm({ genreId: Number(event.target.value) });
  };

  onClickSave = () => {
    this.validateForm().then(() => this.props.createCollection(this.props.collection));
  };

  validateForm = () => {
    if (!isFormValid(this.props.collection, getValidationRules())) {
      this.props.showFormValidation();
      return Promise.reject(new Error('invalid form'));
    }
    return Promise.resolve();
  };

  renderNameField = () => (
    <div className="form-group">
      <Input
        disabled={false}
        labelClassName="required"
        type="text"
        label="Collection Name"
        placeholder="Collection name"
        maxLength="100"
        onChange={this.onNameChange}
        value={this.props.collection.name}
        bsStyle={this.props.validationErrors.name && 'error'}
        data-pw="collection-name"
      />
    </div>
  );

  renderProgrammingNotesField = () => (
    <div className="form-group">
      <Input
        disabled={false}
        type="textarea"
        label="Programming Notes"
        placeholder="Programming notes"
        maxLength="2000"
        onChange={this.onProgrammingNotesChange}
        value={this.props.collection.programmingNotes}
        data-pw="programming-notes"
      />
    </div>
  );

  renderTypeField = () => (
    <div className="form-group">
      <Input
        type="select"
        label="Collection Type"
        labelClassName="required"
        onChange={this.onTypeChange}
        value={this.props.collection.type}
        bsStyle={this.props.validationErrors.type && 'error'}
        data-pw="collection-type"
      >
        <option value="unselected">select a type</option>
        <option value="GENRE">GENRE</option>
        <option value="CUSTOM">CUSTOM</option>
      </Input>
    </div>
  );

  renderGenreOptions = () => {
    return (
      <div className="form-group">
        <Input
          type="select"
          label="Associated Genre"
          labelClassName="required"
          onChange={this.onGenreChange}
          value={this.props.collection.genreId}
          bsStyle={this.props.validationErrors.genreId && 'error'}
        >
          <option value="unselected">select a genre</option>
          {this.props.availableGenres.map((genre) => (
            <option key={genre.id} value={genre.id}>
              {genre.name}
            </option>
          ))}
        </Input>
      </div>
    );
  };

  renderCloseAndSaveButtons = () => (
    <div className="modal-footer">
      <Button type="button" className="form__button collection-form-close-btn" onClick={this.props.close}>
        Close
      </Button>
      <Button
        className="form__button collection-form-save-btn"
        bsStyle="primary"
        onClick={this.onClickSave}
        data-pw="collection-save"
      >
        Save
      </Button>
    </div>
  );

  renderErrors = () => (
    <FormErrorMessage className="add-collection-form-save-error" message={this.props.errorMessage} />
  );

  render() {
    return (
      <div data-pw="add-collection-form">
        {this.renderNameField()}
        {this.renderTypeField()}
        {this.renderProgrammingNotesField()}
        {this.props.collection.type === 'GENRE' && this.renderGenreOptions()}
        {this.props.errorMessage && this.renderErrors()}
        {this.renderCloseAndSaveButtons()}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCollectionForm);
