import {
  required,
  isPositiveNumber,
  isFrom,
  mustMatchRegex,
} from 'src/scripts/lib/formValidation/generalValidators';

const validHexColourRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export default function getValidationRules() {
  return [
    { field: 'name', label: 'Live Event Group Name', customValidators: [required()] },
    { field: 'subtitle', label: 'Live Event Group Subtitle', customValidators: [required()] },
    {
      field: 'position',
      label: 'Position',
      customValidators: [required(), isPositiveNumber(), isFrom(1, 100)],
    },
    {
      field: 'highlightColour',
      label: 'Highlight Colour',
      customErrorMessage: 'Invalid hex code colour',
      customValidators: mustMatchRegex(validHexColourRegex),
    },
  ];
}
