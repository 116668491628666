export const GENDER_GROUPS = [
  { id: 'neutral', name: 'Neutral' },
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
];

export const CONTENT_GROUPS = [
  { name: 'Entertainment', id: 'entertainment' },
  { name: 'Lifestyle', id: 'lifestyle' },
  { name: 'News and Current Affairs', id: 'newsAndCurrentAffairs' },
  { name: 'Sport', id: 'sport' },
  { name: 'Travel', id: 'travel' },
];
