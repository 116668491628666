import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TV_SERIES_CONTENT_FLAGS, SEASON_CONTENT_FLAGS } from 'src/scripts/lib/contentFlags';
import { Creatable } from 'react-select';

const createNewOptionPromptTextCreator = (label) => `Custom content flag: ${label}`;

const compileSelectOptions = (existingSelectOptions, selectValue) => {
  const allSelectOptions = selectValue
    ? existingSelectOptions.concat([{ label: selectValue, value: selectValue }])
    : existingSelectOptions;
  return _.uniqWith(allSelectOptions, _.isEqual);
};

const mapToSelectDropdownOptions = (options) => {
  return options.map((option) => ({ label: option, value: option }));
};

export const TV_SERIES_CONTENT_FLAGS_OPTIONS = mapToSelectDropdownOptions(TV_SERIES_CONTENT_FLAGS);
export const SEASON_CONTENT_FLAGS_OPTIONS = mapToSelectDropdownOptions(SEASON_CONTENT_FLAGS);

const getContentFlagOptions = (contentFlagType) => {
  return contentFlagType === 'tvSeries' ? TV_SERIES_CONTENT_FLAGS_OPTIONS : SEASON_CONTENT_FLAGS_OPTIONS;
};

export default class ContentFlagSelect extends React.Component {
  render() {
    return (
      <div style={{ flexGrow: 1 }} className="form-group" data-pw="select-content-flag">
        <Creatable
          ref="selectContentFlag"
          placeholder={'Select Or Add...'}
          options={compileSelectOptions(
            getContentFlagOptions(this.props.contentFlagType),
            this.props.selected
          )}
          onChange={this.props.onChange}
          value={this.props.selected}
          promptTextCreator={createNewOptionPromptTextCreator}
        />
      </div>
    );
  }
}

ContentFlagSelect.propTypes = {
  contentFlagType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};
