import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ModalItem from 'src/scripts/components/ModalItem';
import PublishingScheduleForm from 'src/scripts/components/BulkAction/buttons/BulkEditButton/PublishingScheduleForm';
import { CLIP, EPISODE } from 'src/scripts/lib/modelNames';

const VALID_DATE_FORMATS = ['DD-MM-YYYY HH:mm', 'DD-MM-YYYY', moment.ISO_8601];

export default class BulkEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishStartDate: '',
      publishEndDate: '',
      broadcastAirDate: '',
      classificationCode: null,
      keepExistingStartDate: true,
      keepExistingEndDate: !this.shouldUseReschedule(props.selectedItems),
      keepExistingAirDate: true,
      keepExistingDrmValue: true,
      keepExistingClassificationsValue: true,
      applyDrmValueToAll: false,
      removeDrmValueForAll: false,
    };
    this.isDisabled = this.isDisabled.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleAirDateChange = this.handleAirDateChange.bind(this);
    this.handleClassificationCodeChange = this.handleClassificationCodeChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.shouldUseReschedule(nextProps.selectedItems)) {
      this.setState({ keepExistingEndDate: false });
    }
  }

  onResetForm = () => {
    this.setState({
      publishStartDate: '',
      publishEndDate: '',
      broadcastAirDate: '',
      classificationCode: null,
      keepExistingStartDate: true,
      keepExistingEndDate: !this.shouldUseReschedule(this.props.selectedItems),
      keepExistingAirDate: true,
      keepExistingDrmValue: true,
      keepExistingClassificationsValue: true,
      applyDrmValueToAll: false,
      removeDrmValueForAll: false,
    });
  };

  onConfirm() {
    const rescheduleAndSaveActions = {
      [EPISODE]: this.props.rescheduleAndSaveEpisodes,
      [CLIP]: this.props.rescheduleAndSaveClips,
    };
    const editActions = {
      [EPISODE]: this.props.editEpisodeData,
      [CLIP]: this.props.editClipSchedules,
    };

    const updatedData = {};
    if (!this.state.keepExistingStartDate) {
      updatedData.availability = this.state.publishStartDate;
    }
    if (!this.state.keepExistingEndDate) {
      updatedData.expiry = this.state.publishEndDate || null;
    }
    if (!this.state.keepExistingAirDate) {
      updatedData.broadcastAirDate = this.state.broadcastAirDate || null;
    }
    if (!this.state.keepExistingClassificationsValue) {
      updatedData.classificationCode = this.state.classificationCode || null;
    }

    if (this.state.applyDrmValueToAll) {
      updatedData.video = { drm: true };
    }

    if (this.state.removeDrmValueForAll) {
      updatedData.video = { drm: false };
    }

    if (this.shouldUseReschedule(this.props.selectedItems)) {
      const rescheduleAndSaveAction = rescheduleAndSaveActions[this.props.modelName];
      rescheduleAndSaveAction(
        this.props.selectedItems.map((item) => item.id),
        updatedData
      );
    } else {
      const editAction = editActions[this.props.modelName];
      editAction(
        this.props.selectedItems.map((item) => item.id),
        updatedData
      );
    }
  }

  getArchivedCount(selectedItems) {
    return selectedItems.filter((item) => item.state === 'ARCHIVED').length;
  }

  getValidationErrors() {
    const validationErrors = [];

    if (moment(this.state.publishStartDate).isAfter(moment(this.state.publishEndDate))) {
      validationErrors.push('Publish End Date must be after Publish Start Date.');
    }

    if (this.state.publishStartDate && !this.isValidDate(this.state.publishStartDate)) {
      validationErrors.push('Publish Start Date is not a valid date format.');
    }

    if (this.state.publishEndDate && !this.isValidDate(this.state.publishEndDate)) {
      validationErrors.push('Publish End Date is not a valid date format.');
    }

    if (this.state.broadcastAirDate && !this.isValidDate(this.state.broadcastAirDate)) {
      validationErrors.push('Broadcast Air Date is not a valid date format.');
    }

    return validationErrors;
  }

  handleDateChange(publishStartDate, publishEndDate) {
    if (this.isValidDate(publishStartDate)) {
      this.setState({ publishStartDate: moment(publishStartDate, VALID_DATE_FORMATS, true).toISOString() });
    } else {
      this.setState({ publishStartDate }); // Push whatever changes the user submitted to state, will run validations on submit
    }

    if (this.isValidDate(publishEndDate)) {
      this.setState({ publishEndDate: moment(publishEndDate, VALID_DATE_FORMATS, true).toISOString() });
    } else {
      this.setState({ publishEndDate }); // Push whatever changes the user submitted to state, will run validations on submit
    }
  }

  handleAirDateChange(broadcastAirDate) {
    if (this.isValidDate(broadcastAirDate)) {
      this.setState({ broadcastAirDate: moment(broadcastAirDate, VALID_DATE_FORMATS, true).toISOString() });
    } else {
      this.setState({ broadcastAirDate }); // Push whatever changes the user submitted to state, will run validations on submit
    }
  }

  handleClassificationCodeChange(event) {
    if (event.target.value !== 0) {
      this.setState({ classificationCode: event.target.value });
    } else {
      this.setState({ classificationCode: null });
    }
  }

  handleKeepExistingStartDateChange = (value) => {
    if (value === true) {
      this.setState({ publishStartDate: '', keepExistingStartDate: true });
    } else {
      this.setState({ keepExistingStartDate: false });
    }
  };

  handleKeepExistingEndDateChange = (value) => {
    if (value === true) {
      this.setState({ publishEndDate: '', keepExistingEndDate: true });
    } else {
      this.setState({ keepExistingEndDate: false });
    }
  };

  handleKeepExistingAirDateChange = (value) => {
    if (value === true) {
      this.setState({ broadcastAirDate: '', keepExistingAirDate: true });
    } else {
      this.setState({ keepExistingAirDate: false });
    }
  };

  handleKeepExistingDrmValue = (value) => {
    this.setState({
      keepExistingDrmValue: value,
      applyDrmValueToAll: !value,
      removeDrmValueForAll: !value,
    });
  };

  handleKeepExistingClassificationChange = (value) => {
    if (value === true) {
      this.setState({ classificationCode: null, keepExistingClassificationsValue: true });
    } else {
      this.setState({ keepExistingClassificationsValue: false });
    }
  };

  handleApplyDrmValueToAll = (value) => {
    this.setState({
      applyDrmValueToAll: value,
      keepExistingDrmValue: !value,
      removeDrmValueForAll: !value,
    });
  };

  handleRemoveDrmValueForAll = (value) => {
    this.setState({
      removeDrmValueForAll: value,
      applyDrmValueToAll: !value,
      keepExistingDrmValue: !value,
    });
  };

  isValidDate(date) {
    return moment(date, VALID_DATE_FORMATS, true).isValid();
  }

  isDisabled() {
    if (!this.props.selectedItems || this.props.selectedItems.length < 1) return true;
    const archivedCount = this.getArchivedCount(this.props.selectedItems);
    return archivedCount && archivedCount !== this.props.selectedItems.length;
  }

  shouldUseReschedule(selectedItems) {
    const archivedCount = this.getArchivedCount(selectedItems);
    return archivedCount > 0;
  }

  getStartDateChangeString = () => {
    if (this.state.keepExistingStartDate) return 'Keep existing value';
    return `Update to ${moment(this.state.publishStartDate).format('DD-MM-YY HH:mm')}`;
  };

  getEndDateChangeString = () => {
    if (this.state.keepExistingEndDate) return 'Keep existing value';
    if (!this.state.keepExistingEndDate && !this.state.publishEndDate) return 'Set to no end date.';
    return `Update to ${moment(this.state.publishEndDate).format('DD-MM-YY HH:mm')}`;
  };

  getAirDateChangeString = () => {
    if (this.state.keepExistingAirDate) return 'Keep existing value';
    if (!this.state.keepExistingAirDate && !this.state.broadcastAirDate) return 'Set to no air date.';
    return `Update to ${moment(this.state.broadcastAirDate).format('DD-MM-YY HH:mm')}`;
  };

  getDrmChangeString = () => {
    if (this.state.applyDrmValueToAll) return 'Is set to true';
    if (this.state.removeDrmValueForAll) return 'Is set to false';
    return 'Keep existing value';
  };

  getClassificationChangeString = () => {
    if (this.state.keepExistingClassificationsValue) return 'Keep existing value';
    if (!this.state.keepExistingClassificationsValue && !this.state.classificationCode)
      return 'Set to no classification.';
    return `Update to ${this.state.classificationCode}`;
  };

  renderConfirmationContent = () => {
    const modelText =
      this.props.selectedItems.length > 1 ? this.props.modelName : this.props.modelName.slice(0, -1);
    const actionText = this.shouldUseReschedule(this.props.selectedItems) ? 'reschedule' : 'update';
    return (
      <div style={{ fontSize: '15px' }}>
        <p>{`You are about to ${actionText} ${this.props.selectedItems.length} ${modelText}, and will apply the following changes:`}</p>
        <ul>
          <li>
            <strong>Start Date: </strong>
            {this.getStartDateChangeString()}
          </li>
          <li>
            <strong>End Date: </strong>
            {this.getEndDateChangeString()}
          </li>
          <li>
            <strong>Air Date: </strong>
            {this.getAirDateChangeString()}
          </li>
          <li>
            <strong>DRM: </strong>
            {this.getDrmChangeString()}
          </li>
          <li>
            <strong>Classification: </strong>
            {this.getClassificationChangeString()}
          </li>
        </ul>
        <p>Are you sure you want to continue?</p>
      </div>
    );
  };

  render() {
    return (
      <ModalItem
        ref="bulkActionBarBulkEditButton"
        className={`editModal btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        component={'span'}
        title="Bulk Edit"
        form={
          <PublishingScheduleForm
            classifications={this.props.classifications}
            confirmAction={this.onConfirm}
            confirmationContent={this.renderConfirmationContent()}
            onDateChange={this.handleDateChange}
            onAirDateChange={this.handleAirDateChange}
            onClassificationCodeChange={this.handleClassificationCodeChange}
            publishStartDate={this.state.publishStartDate}
            publishEndDate={this.state.publishEndDate}
            broadcastAirDate={this.state.broadcastAirDate}
            keepExistingStartDate={this.state.keepExistingStartDate}
            keepExistingEndDate={this.state.keepExistingEndDate}
            keepExistingAirDate={this.state.keepExistingAirDate}
            keepExistingDrmValue={this.state.keepExistingDrmValue}
            keepExistingClassificationsValue={this.state.keepExistingClassificationsValue}
            applyDrmValueToAll={this.state.applyDrmValueToAll}
            removeDrmValueForAll={this.state.removeDrmValueForAll}
            handleKeepExistingStartDateChange={this.handleKeepExistingStartDateChange}
            handleKeepExistingEndDateChange={this.handleKeepExistingEndDateChange}
            handleKeepExistingAirDateChange={this.handleKeepExistingAirDateChange}
            handleKeepExistingDrmValue={this.handleKeepExistingDrmValue}
            handleKeepExistingClassificationChange={this.handleKeepExistingClassificationChange}
            handleApplyDrmValueToAll={this.handleApplyDrmValueToAll}
            handleRemoveDrmValueForAll={this.handleRemoveDrmValueForAll}
            validationErrors={this.getValidationErrors()}
            modelName={this.props.modelName}
            selectedItems={this.props.selectedItems}
            willUseReschedule={this.shouldUseReschedule(this.props.selectedItems)}
            resetForm={this.onResetForm}
          />
        }
      />
    );
  }
}

BulkEditButton.propTypes = {
  classifications: PropTypes.array,
  editEpisodeData: PropTypes.func,
  editClipSchedules: PropTypes.func,
  rescheduleAndSaveEpisodes: PropTypes.func,
  rescheduleAndSaveClips: PropTypes.func,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
};
