import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import GenreForm from 'src/scripts/components/Genre/internal/GenreForm';
import { getGenreById } from 'src/scripts/actions/genre';

export class GenreFormLoader extends React.Component {
  componentDidMount() {
    if (this.props.genreId) {
      this.props.getGenreById(this.props.genreId);
    }
  }

  render() {
    return (
      <ViewLoader loading={this.props.genreId && !this.props.genre.id}>
        <GenreForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

GenreFormLoader.propTypes = {
  genreId: PropTypes.number,
  genre: PropTypes.object,
  getGenreById: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const genreForm = state.genreForm;
  return {
    genre: genreForm.genre,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGenreById: (genreId) => dispatch(getGenreById(genreId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenreFormLoader);
