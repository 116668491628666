import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { MenuItem, DropdownButton, Button } from 'react-bootstrap';
import history from 'src/scripts/lib/history';
import * as browser from 'src/scripts/lib/browser';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { TV_PATH } from 'src/scripts/lib/accessController';

const navigationList = [
  { modelName: 'episodes', name: 'Episodes' },

  { modelName: 'clips', name: 'Clips' },

  { modelName: 'ingestJobs', name: 'Ingest Jobs' },
];

export class VideoListNavigation extends React.Component {
  constructor() {
    super();
    this.transitionToVideoPage = this.transitionToVideoPage.bind(this);
  }

  transitionToVideoPage(modelName) {
    browser.setItem('modelName', modelName);
    history.push({ pathname: `/${TV_PATH}/videos/${modelName}`, query: { page: 1 } });
  }

  render() {
    const menuTitle = _.find(navigationList, { modelName: this.props.currentVideoType });

    const breadcrumbs = [
      'All Videos',
      <DropdownButton
        className="mi9-breadcrumb__dropdown-button"
        bsStyle="link"
        title={menuTitle.name}
        activeKey={this.props.currentVideoType}
        id="list-menu"
        data-pw="video-list-dropdown-menu"
      >
        {navigationList.map((navItem, key) => {
          return (
            <MenuItem
              key={key}
              eventKey={navItem.modelName}
              onSelect={this.transitionToVideoPage.bind(null, navItem.modelName)}
              data-pw={`video-list-dropdown-menu-item-${navItem.name.toLowerCase().replace(/ /g, '-')}`}
            >
              {navItem.name}
            </MenuItem>
          );
        })}
      </DropdownButton>,
    ];

    const rightComponentList = [
      <Button
        className="pull-right refresh-button"
        bsSize="small"
        onClick={this.props.refresh}
        key={Date.now}
      >
        <i className="fa fa-refresh"></i>
      </Button>,
    ];

    return <ContextNavigation breadcrumbList={breadcrumbs} rightComponentList={rightComponentList} />;
  }
}

VideoListNavigation.propTypes = {
  refresh: PropTypes.func,
  currentVideoType: PropTypes.string,
};

export default VideoListNavigation;
