import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import TVSeriesForm from 'src/scripts/components/TVSeries/internal/TVSeriesForm';
import { getTVSeriesFormInitialData } from 'src/scripts/actions/tvSeries';

export class TVSeriesFormLoader extends React.Component {
  componentDidMount() {
    this.props.getTVSeriesFormInitialData(this.props.tvSeriesId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <TVSeriesForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

TVSeriesFormLoader.propTypes = {
  tvSeriesId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getTVSeriesFormInitialData: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const tvSeriesForm = state.tvSeriesForm;
  return {
    initialFormDataLoaded: tvSeriesForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTVSeriesFormInitialData: (tvSeriesId) => dispatch(getTVSeriesFormInitialData(tvSeriesId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TVSeriesFormLoader);
