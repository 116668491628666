import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Pagination, Table } from 'react-bootstrap';
import ColumnChooser from 'src/scripts/components/ColumnChooser';
import PaginationLocation from 'src/scripts/components/ItemList/PaginationLocation';
import history from 'src/scripts/lib/history';
import * as dateFormatter from 'src/scripts/lib/dateFormatter';
import { formatTimeInMiliseconds } from 'src/scripts/lib/timeFormatter';
import { humanizeString } from 'src/scripts/lib/stringFormatter';
import { updateSort, resetItemList, updateFilter } from 'src/scripts/actions/itemList';
import {
  addToSelected,
  replaceSelected,
  removeFromSelected,
  deselectAll,
} from 'src/scripts/actions/bulkAction';
import BulkActionBar, { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import { getVideoUploadMetaData } from 'src/scripts/reducers/video';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { ExportCatalogButton } from 'src/scripts/components/ItemList/buttons/ExportCatalogButton';
import { ClearFiltersButton } from 'src/scripts/components/ItemList/buttons/ClearFiltersButton';
import { keys } from 'src/scripts/lib/validation';
import LoadingIcon from 'src/scripts/components/LoadingIcon';

export const ActionButtonTypes = {
  EXPORT: 'EXPORT',
  CLEAR: 'CLEAR',
};

function calculateNumberOfPaginationItems(totalNumberOfItems, pageSize) {
  return Math.ceil(totalNumberOfItems / pageSize);
}

export class ItemList extends React.Component {
  constructor(props) {
    super(props);
    this.getItems = this.getItems.bind(this);
    this.handleSearchKeyUp = this.handleSearchKeyUp.bind(this);
    this.updateSearchValue = this.updateSearchValue.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.resetSearchInput = this.resetSearchInput.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.state = {
      searchValue: '',
      appliedSearchValue: '',
      loading: false,
    };
  }

  componentDidMount() {
    const defaultSort = this.props.defaultSort || null;
    this.props.resetItemList();
    const filters = {};
    if (defaultSort) {
      this.props.updateSort({
        ascending: defaultSort.slice(0, 1) === '+',
        sortBy: defaultSort.slice(1, defaultSort.length),
      });
    }
    if (this.props.defaultFilter) {
      filters[this.props.defaultFilter.name] = this.props.defaultFilter.value;
      this.props.updateFilter(this.props.defaultFilter);
    }
    this.props.getItems({ sort: defaultSort, filters });
  }

  componentWillUpdate(nextProps) {
    if (this.props.list.pageData !== nextProps.list.pageData) {
      const selectedToSync = [];
      this.props.selected.forEach((selectedItem) => {
        selectedToSync.push(_.find(nextProps.list.pageData, (newItem) => newItem.id === selectedItem.id));
      });
      if (selectedToSync) {
        this.props.replaceSelected(selectedToSync);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetItemList();
  }

  clearSearchInput() {
    if (this.props.hasSearch) {
      this.refs.searchBar.value = '';
      this.setState({
        searchValue: '',
        appliedSearchValue: '',
        loading: true,
      });
    }
  }

  resetSearchInput() {
    this.clearSearchInput();
    this.getItems({ sort: null, filters: this.props.filters });
  }

  async getItems(params) {
    // To Do: Page prop control the offset and Pagination of the List components by running this function we make sure that the offset and the Page always match.
    // The will be remove after Pagination Refactor
    if (this.props.list.pagination && this.props.list.pagination.activePage !== 1) {
      history.push({ pathname: history.location.pathname, query: { page: 1 } });
    }

    const { sort, filters } = params;

    this.setState((prevState) => {
      return {
        appliedSearchValue: prevState.searchValue,
      };
    });
    const searchValue = await this.getSearchValue();

    this.setState({
      loading: true,
    });

    await this.props.getItems({
      sort: sort || this.props.sort,
      offset: 0,
      ...(searchValue && this.props.hasSearch ? { search: searchValue } : {}),
      filters: filters || this.props.filters,
    });

    this.setState({
      loading: false,
    });
  }

  getSearchValue() {
    return this.refs.searchBar ? this.refs.searchBar.value : '';
  }

  selectPage(page) {
    history.push({ pathname: history.location.pathname, query: { page } });
    this.props.deselectAll();
  }

  isItemSelected(itemId) {
    const alreadySelected = _.find(this.props.selected, (item) => item.id === itemId);
    if (alreadySelected) {
      return true;
    }
    return false;
  }

  isLoading() {
    return this.state.loading;
  }

  hasActions() {
    return this.props.actionButtons && this.props.actionButtons.length;
  }

  hasBulkActions() {
    return this.props.bulkActionButtons && this.props.bulkActionButtons.length;
  }

  hasToolbar() {
    return this.hasActions() || this.hasBulkActions() || this.props.hasSearch;
  }

  async clearFilters() {
    this.clearSearchInput();
    await this.props.clearFilters();
    this.setState({
      loading: false,
    });
  }

  areAllSelected(items) {
    if (items.length === this.props.selected.length && items.length > 0) {
      return true;
    }
    return false;
  }

  toggleSelection(item) {
    if (this.isItemSelected(item.id)) {
      this.props.removeFromSelected(item.id);
    } else {
      this.props.addToSelected([item]);
    }
  }

  toggleSelectAll(items) {
    if (this.areAllSelected(items)) {
      this.props.deselectAll();
    } else {
      this.props.replaceSelected(items);
    }
  }

  handleSearchKeyUp(event) {
    const key = event.which;
    if (key === keys.ENTER) {
      this.getItems({ sort: this.props.sort, filters: this.props.filters });
    }
  }

  updateSearchValue() {
    this.setState({
      searchValue: this.refs.searchBar.value,
    });
  }

  renderSearchBar() {
    return this.props.hasSearch ? (
      <div className="search-bar-container">
        <div className="search-bar">
          <button
            id="search-button"
            onClick={this.getItems}
            disabled={this.isLoading()}
            data-pw="search-button"
          >
            <i className="fa fa-search"></i>
          </button>
          <input
            ref="searchBar"
            className="search-bar-input"
            placeholder="Search..."
            onKeyUp={this.handleSearchKeyUp}
            onChange={this.updateSearchValue}
            disabled={this.isLoading()}
            data-pw="search-bar-input"
          />
          <button
            id="clear-button"
            onClick={this.resetSearchInput}
            className={this.state.searchValue ? '' : 'hidden'}
            disabled={this.isLoading()}
          >
            <i className="fa fa-close"></i>
          </button>
        </div>
      </div>
    ) : (
      ''
    );
  }

  renderBulkActionButtons() {
    return this.hasBulkActions() ? (
      <BulkActionBar
        modelName={this.props.modelName}
        buttons={this.props.bulkActionButtons}
        seasonId={this.props.seasonId}
      />
    ) : (
      ''
    );
  }

  getStatusIconClassNameByValue(item, dataField) {
    const statusIconGuideOptionsFromParent = this.props.statusIconStylingGuide;

    if (!this.props.statusIconStylingGuide) return null;

    for (const styleGuideGroup of statusIconGuideOptionsFromParent) {
      const dataFieldKey = styleGuideGroup.key;

      for (const styleGuideOptionCondition of styleGuideGroup.conditions) {
        const valueToCheck = item[dataFieldKey];

        if (valueToCheck && valueToCheck === styleGuideOptionCondition.value && dataField === dataFieldKey) {
          return styleGuideOptionCondition.className;
        }
      }
    }

    return null;
  }

  renderActionButtons() {
    return this.hasActions() ? (
      <div className="actions-container">
        <div className="action-bar">
          <ButtonToolbar className="tv-button-toolbar">
            <ButtonGroup className="action-bar-button-group">
              {this.renderDownloadButton()}
              {this.renderClearFiltersButton()}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>
    ) : (
      ''
    );
  }

  renderSelectAllCheckbox(items) {
    return (
      <th className="item-list-header capitalize bulk-actions-select-header">
        <input
          ref="bulkActionsSelectAll"
          className="bulk-actions-select-all"
          type="checkbox"
          checked={this.areAllSelected(items)}
          onChange={this.toggleSelectAll.bind(this, items)}
        />
      </th>
    );
  }

  renderToolbar() {
    return (
      this.hasToolbar() && (
        <div className="toolbar">
          {this.renderSearchBar()}
          <div className="buttons-container">
            {this.renderBulkActionButtons()}
            {this.renderActionButtons()}
          </div>
        </div>
      )
    );
  }

  renderLoadingIcon() {
    return (
      this.isLoading() && (
        <div className="item-list-loading-icon">
          <LoadingIcon className="fa-4x" loading />
        </div>
      )
    );
  }

  renderCheckbox(item) {
    return (
      <td>
        <input
          className="bulk-actions-item-checkbox"
          type="checkbox"
          checked={this.isItemSelected(item.id)}
          onChange={this.toggleSelection.bind(this, item)}
        />
      </td>
    );
  }

  renderDownloadButton() {
    return this.props.actionButtons.includes(ActionButtonTypes.EXPORT) ? (
      <ExportCatalogButton modelName={this.props.modelName} />
    ) : null;
  }

  renderClearFiltersButton() {
    return this.props.actionButtons.includes(ActionButtonTypes.CLEAR) ? (
      <ClearFiltersButton
        clearFilters={this.clearFilters}
        filters={this.props.filters}
        defaultFilter={this.props.defaultFilter}
        searchValue={this.state.searchValue}
        appliedSearchValue={this.state.appliedSearchValue}
      />
    ) : null;
  }

  render() {
    const paginationOptions = this.props.list.pagination;
    let paginationComponent = '';
    if (paginationOptions) {
      paginationComponent = (
        <div className="bottom-container text-center">
          <PaginationLocation
            totalCount={this.props.list.totalCount}
            activePage={paginationOptions.activePage}
            pageSize={paginationOptions.pageSize}
          />
          <div className="text-center" ref="pagination">
            <Pagination
              id="pagination"
              prev
              next
              first
              last
              ellipsis
              bsStyle="primary"
              bsSize="small"
              items={calculateNumberOfPaginationItems(this.props.list.totalCount, paginationOptions.pageSize)}
              maxButtons={5}
              activePage={paginationOptions.activePage}
              onSelect={(event, selectedEvent) => {
                this.selectPage(selectedEvent.eventKey);
              }}
            />
          </div>
        </div>
      );
    }

    const getHeaderValue = (header, item, rowIndex) => {
      let dataValue;
      if (header.dataFormat) {
        dataValue = header.dataFormat(item, rowIndex);
      } else {
        dataValue = _.get(item, header.dataField);
        if (dataValue === undefined || dataValue === null) {
          return '';
        }
      }
      switch (header.dataType) {
        case 'date':
          dataValue = dateFormatter.format(dataValue);
          break;
        case 'duration':
          dataValue = formatTimeInMiliseconds(dataValue);
          break;
        case 'humanize':
          dataValue = _.isString(dataValue) ? humanizeString(dataValue) : dataValue;
          break;
        default:
      }
      return dataValue;
    };

    const columnFilter = (child) => {
      const column = _.find(
        this.props.visibleColumns,
        (visibleColumn) => visibleColumn.name === child.props.label
      );
      return this.props.visibleColumns.length > 0 && column && column.visible;
    };

    let content;
    let empty;
    if (this.props.list.pageData.length) {
      content = this.props.list.pageData.map((item, rowIndex) => {
        return (
          <tr key={`dataRow${rowIndex}`} ref={item.ID} className={'item-list-row'} data-pw={item.name}>
            {this.hasBulkActions() ? this.renderCheckbox(item) : null}
            {React.Children.toArray(this.props.children)
              .filter(this.props.disableColumnChooser ? () => true : columnFilter)
              .map((child, index) => {
                const stateClassName = this.getStatusIconClassNameByValue(item, child.props.dataField);

                return (
                  <td
                    id={
                      child.props.id
                        ? `${child.props.id}-${rowIndex}`
                        : `${child.props.label.toLowerCase().replace(/ /g, '-')}-${rowIndex}`
                    }
                    key={`data${index}`}
                    className={stateClassName ? `${stateClassName} white-text` : ''}
                    data-pw={`${child.props.label.toLowerCase().replace(/ /g, '-')}`}
                  >
                    {getHeaderValue(child.props, item, rowIndex)}
                  </td>
                );
              })}
          </tr>
        );
      });
    } else {
      empty = <div className="empty-item-list text-muted">There are no items to display.</div>;
    }
    return (
      <div className={`item-list-conatiner ${this.props.invisible && 'invisible'}`}>
        {this.renderToolbar()}
        {this.renderLoadingIcon()}
        <div
          ref="itemList"
          className={`item-list${this.hasToolbar() ? ' toolbar-enabled' : ''}${
            this.props.hasSearch ? ' search-bar-enabled' : ''
          }`}
        >
          {this.props.disableColumnChooser ? (
            ''
          ) : (
            <ColumnChooser
              id="column-chooser"
              availableColumns={React.Children.toArray(this.props.children).map((child) => child.props.label)}
            />
          )}
          <Table id={this.props.id} responsive striped hover>
            <thead>
              <tr ref="header" className="header">
                {this.hasBulkActions() ? this.renderSelectAllCheckbox(this.props.list.pageData) : null}
                {React.Children.toArray(this.props.children)
                  .filter(this.props.disableColumnChooser ? () => true : columnFilter)
                  .map((child) => React.cloneElement(child, { getItems: this.getItems }))}
              </tr>
            </thead>
            <tbody ref="content">{content}</tbody>
          </Table>
          {empty}
          {paginationComponent}
        </div>
      </div>
    );
  }
}

ItemList.defaultProps = {
  visibleColumns: [],
};

ItemList.propTypes = {
  visibleColumns: PropTypes.arrayOf(PropTypes.object),
  list: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  disableColumnChooser: PropTypes.bool,
  children: PropTypes.any,
  getItems: PropTypes.func.isRequired,
  invisible: PropTypes.bool,
  updateSort: PropTypes.func,
  resetItemList: PropTypes.func,
  defaultSort: PropTypes.string,
  id: PropTypes.string,
  modelName: PropTypes.string,
  bulkActionButtons: PropTypes.arrayOf(PropTypes.oneOf(Object.values(BulkActionButtonTypes))),
  actionButtons: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ActionButtonTypes))),
  selected: PropTypes.array,
  addToSelected: PropTypes.func,
  replaceSelected: PropTypes.func,
  removeFromSelected: PropTypes.func,
  deselectAll: PropTypes.func,
  videoUploadMetaData: PropTypes.object,
  defaultFilter: PropTypes.object,
  updateFilter: PropTypes.func,
  clearFilters: PropTypes.func,
  filters: PropTypes.object,
  sort: PropTypes.string,
  hasSearch: PropTypes.bool,
  seasonId: PropTypes.number,
  statusIconStylingGuide: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    visibleColumns: state.columnChooser.columns,
    selected: state.bulkActions.selected,
    videoUploadMetaData: getVideoUploadMetaData(state),
    filters: state.itemList.filters,
    sort: state.itemList.sort,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSort: ({ sortBy, ascending }) => dispatch(updateSort({ sortBy, ascending })),
    updateFilter: (filter) => dispatch(updateFilter(filter)),
    resetItemList: () => dispatch(resetItemList()),
    addToSelected: (item) => dispatch(addToSelected(item)),
    replaceSelected: (items) => dispatch(replaceSelected(items)),
    removeFromSelected: (itemId) => dispatch(removeFromSelected(itemId)),
    deselectAll: () => dispatch(deselectAll()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemList);
