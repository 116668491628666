import * as liveEventGroupsVmsApi from '../../scripts/actions/apiWrappers/vmsApi/common/liveEventGroups';
import { closeModal } from 'src/scripts/actions/modalItem';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
export const DELETE_LIVE_EVENT_GROUP_SUCCEEDED = 'DELETE_LIVE_EVENT_GROUP_SUCCEEDED';

const LIVE_EVENT_GROUP_DEFAULT_HIGHLIGHT_COLOUR = '#02C7E1';

function filterProps(liveEventGroup) {
  return {
    name: liveEventGroup.name,
    subtitle: liveEventGroup.subtitle,
    position: liveEventGroup.position,
    ctvPosition: liveEventGroup.ctvPosition,
    description: liveEventGroup.description,
    seoPageTitle: liveEventGroup.seoPageTitle,
    seoDescription: liveEventGroup.seoDescription,
    images: liveEventGroup.images,
    producerNotes: liveEventGroup.producerNotes,
    // LIVE_EVENT_GROUP_DEFAULT_HIGHLIGHT_COLOUR added if highlightColour is empty when creating or updating
    // live event group
    highlightColour: liveEventGroup.highlightColour || LIVE_EVENT_GROUP_DEFAULT_HIGHLIGHT_COLOUR,
    isChannelGroup: liveEventGroup.isChannelGroup,
    ...(liveEventGroup.liveEvents && { liveEventIds: liveEventGroup.liveEvents.map((event) => event.id) }),
  };
}

export function createLiveEventGroup(liveEventGroup) {
  return liveEventGroupsVmsApi.createLiveEventGroup(filterProps(liveEventGroup));
}

export function updateLiveEventGroup(liveEventGroup) {
  return liveEventGroupsVmsApi.updateLiveEventGroup(filterProps(liveEventGroup), liveEventGroup.id);
}

export function getLiveEventGroupById(liveEventGroupId) {
  return liveEventGroupsVmsApi.getLiveEventGroupById(liveEventGroupId);
}

export function deleteLiveEventGroupAndCloseConfirmation(liveEventGroupId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({ type: DELETE_LIVE_EVENT_GROUP_SUCCEEDED, id: liveEventGroupId });
      dispatch(closeModal());
    };

    const onError = (response) =>
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });

    return liveEventGroupsVmsApi.deleteLiveEventGroup(liveEventGroupId).then(onSuccess, onError);
  };
}
