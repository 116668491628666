import React from 'react';
import PropTypes from 'prop-types';
import PapaParse from 'papaparse';
import { fromSecondsToHoursMinutesSecondsMs, convertTimeCodeToSeconds } from 'src/scripts/lib/timeFormatter';
import { fileReader } from 'src/scripts/lib/browser';

class UploadCuePointCsv extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fileUploadError: null };
  }

  papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  validateCsvInput = (csvInput) => {
    csvInput.forEach((row) => {
      if (!row.hasOwnProperty('PLAYLIST_NAME')) {
        throw new Error('Missing Column Header PLAYLIST_NAME.');
      }
      if (row.PLAYLIST_NAME === null) {
        throw new Error("Cue Point's Value Cannot Be Empty.");
      }
      if (typeof row.PLAYLIST_NAME !== 'string') {
        throw new Error("Cue Point's Value Should Be A String.");
      }
    });
  };

  handleCsvInput = (csvInput) => {
    csvInput.splice(0, 2);
    this.validateCsvInput(csvInput);
    const cuePoints = csvInput.map((cuePoint) => {
      const cuePointInSeconds = convertTimeCodeToSeconds(cuePoint.PLAYLIST_NAME);
      const cuePointInTimeCode = fromSecondsToHoursMinutesSecondsMs(cuePointInSeconds);
      return { time: cuePointInTimeCode };
    });
    if (cuePoints[0].time !== '0:0:0') {
      cuePoints.splice(0, 0, { time: '0:0:0' });
    }
    return cuePoints;
  };

  onUpload = (e) => {
    this.setState({
      fileUploadError: null,
    });
    const reader = fileReader();
    if (e.target.files.length > 0) {
      reader.onload = (event) => {
        const csvData = PapaParse.parse(
          event.target.result,
          Object.assign(this.papaparseOptions, {
            encoding: 'UTF-8',
          })
        );
        try {
          const cuePoints = this.handleCsvInput(csvData.data);
          this.props.updateEpisode({
            cuePoints,
          });
        } catch (err) {
          this.setState({
            fileUploadError: err.message,
          });
        }
      };
      reader.readAsText(e.target.files[0], 'UTF-8');
    }
  };

  render() {
    return (
      <div className="csv-reader-input">
        <label htmlFor="upload-csv">CuePoints</label>
        <input
          className="csv-reader"
          ref="uploadCuePointCSV"
          type="file"
          id="upload-csv"
          accept=".csv"
          onChange={(e) => this.onUpload(e)}
          onClick={(e) => {
            e.target.value = null;
          }}
          disabled={this.props.disabled}
        />
        {this.state.fileUploadError && (
          <div className="cuepoint-upload">
            <i className="fa fa-exclamation-triangle warning-icon" />{' '}
            {`${this.state.fileUploadError} Cue Points Will Not Be Updated`}
          </div>
        )}
      </div>
    );
  }
}

UploadCuePointCsv.propTypes = {
  updateEpisode: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default UploadCuePointCsv;
