import _ from 'lodash';
import { fromSecondsToHoursMinutesSecondsMs } from 'src/scripts/lib/timeFormatter';
import * as dateFormatter from 'src/scripts/lib/dateFormatter';
import { humanizeString } from 'src/scripts/lib/stringFormatter';
import {
  getContentOrDashes,
  getBoolDisplayValue,
  getCaptionFileNameFromCaptionsS3Key,
} from 'src/scripts/lib/stringFormatter';
import { formatTimeInMiliseconds } from 'src/scripts/lib/timeFormatter';

function transformDateToDisplay(dateField) {
  if (!dateField) {
    return getContentOrDashes(dateField);
  }
  return dateFormatter.format(dateField);
}

function transformCuePoints(cuePoints) {
  const cuePointsList = cuePoints.split(',');
  if (!cuePointsList.length) {
    return getContentOrDashes(cuePoints);
  }

  return cuePointsList.map((item) => fromSecondsToHoursMinutesSecondsMs(item)).join(', ');
}

export function transformIngestJobForDisplay(ingestJob) {
  const tvSeriesDetails = [
    { label: 'Tv Series Name', value: getContentOrDashes(ingestJob.tvSeriesName) },
    { label: 'Country of Origin', value: getContentOrDashes(ingestJob.countryOfOrigin) },
    { label: 'Genre ', value: getContentOrDashes(ingestJob.genre) },
  ];
  const seasonDetails = [
    { label: 'Season Name', value: getContentOrDashes(ingestJob.seasonName) },
    { label: 'Season Number', value: getContentOrDashes(ingestJob.seasonNumber) },
    { label: 'SeasonYear ', value: getContentOrDashes(ingestJob.seasonYear) },
    { label: 'Catalog Code', value: getContentOrDashes(ingestJob.catalogCode) },
    { label: 'Channel', value: getContentOrDashes(ingestJob.network) },
  ];
  let cuePoints = _.map(ingestJob.video.cuePoints, 'time');
  cuePoints = cuePoints.join(', ');
  const episodeDetails = [
    { label: 'Episode number', value: getContentOrDashes(ingestJob.number) },
    { label: 'Episode name', value: getContentOrDashes(ingestJob.name) },
    { label: 'Description ', value: getContentOrDashes(ingestJob.description) },
    { label: 'Tags', value: getContentOrDashes(ingestJob.keywords) },
    { label: 'Content Series', value: getContentOrDashes(ingestJob.contentSeries) },
    { label: 'DRM', value: getBoolDisplayValue(ingestJob.video.drm) },
    { label: 'Available only in Australia', value: getBoolDisplayValue(ingestJob.geoBlocking) },
    { label: 'Start Date', value: transformDateToDisplay(ingestJob.availability) },
    { label: 'End Date', value: transformDateToDisplay(ingestJob.expiry) },
    { label: 'Classification', value: getContentOrDashes(ingestJob.classificationCode) },
    { label: 'Material Key', value: getContentOrDashes(ingestJob.materialKey) },
    { label: 'Broadcast Air Date', value: transformDateToDisplay(ingestJob.broadcastAirDate) },
  ];

  const videoCloudError = ingestJob.video.transcodingFailureMessage
    ? ` : ${ingestJob.video.transcodingFailureMessage}`
    : '';
  const videoDetails = [
    { label: 'Video Cue Points', value: transformCuePoints(cuePoints) },
    { label: 'Video (VMS) Reference ID', value: getContentOrDashes(ingestJob.video.referenceId) },
    {
      label: 'Brightcove Video Cloud Status',
      value: `${getContentOrDashes(humanizeString(ingestJob.video.status))}${videoCloudError}`,
    },
    { label: 'Video Brightcove ID', value: getContentOrDashes(ingestJob.video.brightcoveId) },
    {
      label: 'Video Source File',
      value: getContentOrDashes(ingestJob.video.videoFileName),
      download: ingestJob.video.videoFileName ? true : undefined,
    },
    { label: 'Video Duration', value: formatTimeInMiliseconds(ingestJob.video.duration) },
    {
      label: 'Caption Source File',
      value: getContentOrDashes(getCaptionFileNameFromCaptionsS3Key(ingestJob.video.captionsS3Key)),
      downloadCaptions: ingestJob.video.captionsS3Key ? true : undefined,
    },
  ];

  return {
    tvSeriesDetails,
    seasonDetails,
    episodeDetails,
    videoDetails,
  };
}
