import {
  SAVE_GENRE_SUCCEEDED,
  SAVE_GENRE_FAILED,
  DELETE_GENRE_SUCCEEDED,
  GET_GENRE_SUCCEEDED,
  GET_GENRE_FAILED,
} from '../actions/genre';

const initialState = {
  genre: {},
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GENRE_FAILED:
    case SAVE_GENRE_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case GET_GENRE_SUCCEEDED:
    case SAVE_GENRE_SUCCEEDED:
      return {
        ...state,
        genre: action.data.genre,
      };

    case DELETE_GENRE_SUCCEEDED:
      return { ...initialState };

    default:
  }
  return state;
}
