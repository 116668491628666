import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Input } from 'react-bootstrap';

export default class AvailabilityPanel extends React.Component {
  static propTypes = {
    liveEvent: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleHideStrapChange = () => {
    this.props.onChange({
      hideStrap: !this.props.liveEvent.hideStrap,
    });
  };

  render() {
    return (
      <Panel header="Availability" bsStyle="primary">
        <Input
          type="checkbox"
          label="Hide strap"
          groupClassName="form-inline"
          onClick={this.handleHideStrapChange}
          checked={this.props.liveEvent.hideStrap}
        />
      </Panel>
    );
  }
}
