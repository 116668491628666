import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import ModalItem from 'src/scripts/components/ModalItem';
import { updateCategoryBreadcrumb } from 'src/scripts/actions/breadcrumb';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import SubcategoryFormLoader from 'src/scripts/components/Subcategory/SubcategoryFormLoader';
import { getSubcategories, deleteSubcategoryAndCloseConfirmation } from 'src/scripts/actions/subcategory';

export class SubcategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.getSubcategories = this.getSubcategories.bind(this);
  }

  componentDidMount() {
    this.props.updateCategoryBreadcrumb(Number(this.props.params.id));
  }

  componentDidUpdate() {
    if (
      this.props.savedSubcategory &&
      this.props.savedSubcategory.categoryId === Number(this.props.params.id)
    ) {
      this.getSubcategories();
    }
  }

  getSubcategories() {
    return this.props.getSubcategories(Number(this.props.params.id));
  }

  render() {
    const actionList = (subcategoryItem) => {
      return (
        <NavDropdownMenu
          key={subcategoryItem.id}
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          className="pull-left"
          id={`subcategory-item-button-${subcategoryItem.id}`}
        >
          <ModalItem
            key={subcategoryItem.id}
            title="Edit Subcategory"
            form={<SubcategoryFormLoader subcategoryId={subcategoryItem.id} />}
          />
          <ModalItem
            title="Delete Subcategory"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${subcategoryItem.name}" subcategory. Are you sure you wish to proceed?`}
                ok={this.props.deleteSubcategoryAndCloseConfirmation.bind(this, subcategoryItem.id)}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        </NavDropdownMenu>
      );
    };
    const list = {
      pageData: this.props.subcategories,
      totalCount: this.props.totalCount,
    };

    const breadcrumbs = [<a href="/#/network/categories">Categories</a>, this.props.categoryName];

    return (
      <div>
        <ContextNavigation breadcrumbList={breadcrumbs} />
        <ItemList id="list-subcategory" list={list} disableColumnChooser getItems={this.getSubcategories}>
          <ItemListHeader
            label="Name"
            dataField="name"
            dataFormat={(subcategory) => {
              return (
                <a
                  href={`#/network/categories/${subcategory.partOfCategory.id}/subcategories/${subcategory.id}/clips`}
                >
                  {subcategory.name}
                </a>
              );
            }}
          />
          <ItemListHeader label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader label="Actions" dataFormat={(subcategoryItem) => actionList(subcategoryItem)} />
        </ItemList>
      </div>
    );
  }
}

SubcategoryList.propTypes = {
  totalCount: PropTypes.number,
  categoryName: PropTypes.string,
  subcategories: PropTypes.array,
  params: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getSubcategories: PropTypes.func,
  deleteSubcategoryAndCloseConfirmation: PropTypes.func,
  updateCategoryBreadcrumb: PropTypes.func,
  savedSubcategory: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    subcategories: state.subcategoryList.subcategories,
    totalCount: state.subcategoryList.totalCount,
    categoryName: state.breadcrumb.categoryName,
    savedSubcategory: state.subcategoryForm.savedSubcategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubcategories: (subcategoryId) => dispatch(getSubcategories(subcategoryId)),
    deleteSubcategoryAndCloseConfirmation: (subcategoryId) =>
      dispatch(deleteSubcategoryAndCloseConfirmation(subcategoryId)),
    updateCategoryBreadcrumb: (categoryId) => dispatch(updateCategoryBreadcrumb(categoryId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubcategoryList);
