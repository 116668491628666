import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Input, Button, Panel, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import { getSeasonsForForm } from 'src/scripts/actions/season';
import { showConfirmationModal } from 'src/scripts/actions/confirmation';
import {
  saveEpisode,
  updateEpisode,
  updateEpisodeWithImportData,
  rescheduleAndSaveEpisode,
} from 'src/scripts/actions/episode';
import { showFormValidation } from 'src/scripts/actions/form';
import {
  replaceEpisodeForIngestJob,
  checkAndImportEpisodeForIngestJob,
} from 'src/scripts/actions/ingestJobs';
import { isUserInputNumeric } from 'src/scripts/lib/validation';
import AdCuepointsPicker from 'src/scripts/components/AdCuepointsPicker';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import { isVideoPreviouslyUploaded } from 'src/scripts/components/Video/videoStatus';
import {
  isUploadEnabled,
  isDrmEditable,
  isEditEnabled,
  isTvSeriesSelectChangeable,
  isSeasonSelectChangeable,
  isAutoActivateEditable,
  isWatermarkEnabledEditable,
} from 'src/scripts/lib/workflowActionsProvider';
import PublishingSchedule from 'src/scripts/components/PublishingSchedule';
import TagList from 'src/scripts/components/TagList';
import ImageUpload from 'src/scripts/components/ImageUpload';
import { hoursMinutesSecondstoSeconds } from 'src/scripts/lib/cuepointsConverter';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';
import { TAGS, IMAGES } from 'src/scripts/lib/resourceGroups';
import VideoUpload from 'src/scripts/components/VideoUpload';
import CaptionUpload from 'src/scripts/components/CaptionUpload';
import { EPISODE_IMAGE_REF, EPISODE_FEATURED_THUMBNAIL_IMAGE_REF } from 'src/scripts/lib/imageRefs';
import { isFormValid } from 'src/scripts/lib/formValidation';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/episodeForm';
import { uploadCaptionToS3 } from 'src/scripts/actions/ui/caption';
import {
  getCaptionUploadPercentage,
  isCaptionUploading,
  getCaptionUploadErrorMessage,
} from 'src/scripts/reducers/ui/caption/uploads';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import ContentIdSection from 'src/scripts/components/Episode/internal/ContentIdSection';
import MaterialKeyMatchModal from 'src/scripts/components/Episode/internal/MaterialKeyMatchModal';
import SeasonSelect from '../../SeasonSelect';
import UploadCuePointCsv from 'src/scripts/components/Episode/internal/UploadCuePointCsv';
import OverwriteEpisodeModal from '../../IngestJob/internal/OverwriteEpisodeModal';
import RecommendedCharacterCountPopover from '../../RecommendedCharacterCountPopover';
import { FEATURED_THUMBNAIL, EPISODE_THUMBNAIL } from 'src/scripts/lib/imageTypes';
import Dropdown from '../../Dropdown';
import transcodingProfileCodes from 'src/scripts/constants/transcodingProfileCodes';
import EpisodeLicensePeriod from './LicensePeriod';

export class EpisodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.onEpisodeChange = this.onEpisodeChange.bind(this);
    this.onSeasonChange = this.onSeasonChange.bind(this);
    this.onTVSeriesChange = this.onTVSeriesChange.bind(this);
    this.onVideoFileChange = this.onVideoFileChange.bind(this);
    this.onBroadcastDateChange = this.onBroadcastDateChange.bind(this);
    this.onMaterialKeyChange = this.onMaterialKeyChange.bind(this);
    this.onPhoenixEpisodeIdentifierChange = this.onPhoenixEpisodeIdentifierChange.bind(this);
    this.onAutoActivateChange = this.onAutoActivateChange.bind(this);
    this.onClassificationCodeChange = this.onClassificationCodeChange.bind(this);
    this.onDrmChange = this.onDrmChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.onCuePointsChange = this.onCuePointsChange.bind(this);
    this.onPublishingScheduleChange = this.onPublishingScheduleChange.bind(this);
    this.onGeoBlockingChange = this.onGeoBlockingChange.bind(this);
    this.onWatermarkEnabledChange = this.onWatermarkEnabledChange.bind(this);
    this.saveAndUpload = this.saveAndUpload.bind(this);
    this.save = this.save.bind(this);
    this.confirmSave = this.confirmSave.bind(this);
    this.isUploadDisabled = this.isUploadDisabled.bind(this);
    this.getVideoAlerts = this.getVideoAlerts.bind(this);
    this.onCaptionsIngestEnabledChange = this.onCaptionsIngestEnabledChange.bind(this);
    this.isEpisodeUsingSeasonDefaults = this.isEpisodeUsingSeasonDefaults.bind(this);
    this.replaceEpisodeForIngestJob = this.replaceEpisodeForIngestJob.bind(this);
    this.isZypeMrssFeedEnabled = this.onZypeMrssFeedEnabledChange.bind(this);
    this.fastChannelLicensingStartDate = this.onFastChannelLicensingStartDateChange.bind(this);
    this.fastChannelLicensingEndDate = this.onFastChannelLicensingEndDateChange.bind(this);
    this.state = {
      showMaterialKeyMatchModal: false,
      matchedMaterialKey: '',
      formEdited: false,
    };
  }

  componentDidMount() {
    if (this.props.episode.video.file) {
      const matchedMaterialKey = this.findMaterialKeyInFilename(this.props.episode.video.file.name);
      if (matchedMaterialKey) {
        this.setState({ matchedMaterialKey, showMaterialKeyMatchModal: true }); // eslint-disable-line
      }
    }
  }

  materialKeyImportData = (data) => {
    this.props.updateEpisodeWithImportData({
      materialKey: this.state.matchedMaterialKey,
      ...data,
    });
  };

  findMaterialKeyInFilename = (filename) => {
    const regexp = /(.+_[0-9]+_\w)/;
    const result = regexp.exec(filename);
    if (!result) return null;
    return result[0];
  };

  showMaterialKeyMatchModal = (matchedMaterialKey) => {
    this.setState({ showMaterialKeyMatchModal: true, matchedMaterialKey });
  };

  hideMaterialKeyMatchModal = () => {
    this.setState({ showMaterialKeyMatchModal: false });
  };

  updateEpisodeAndFlagEdit = (...args) => {
    this.setState({ formEdited: true });
    this.props.updateEpisode(...args);
  };

  onTVSeriesChange(tvSeriesId) {
    this.updateEpisodeAndFlagEdit({
      tvSeriesId,
      seasonId: 0,
    });
    tvSeriesId && this.props.getSeasons({ tvSeriesId });
  }

  onSeasonChange(seasonId) {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === seasonId);
    let changedData = { seasonId };
    if (selectedSeason) {
      changedData = {
        ...changedData,
        geoBlocking: selectedSeason.episodesGeoBlocked,
        isZypeMrssFeedEnabled: selectedSeason.isZypeMrssFeedEnabled,
        fastChannelLicensingStartDate: selectedSeason.fastChannelLicensingStartDate,
        fastChannelLicensingEndDate: selectedSeason.fastChannelLicensingEndDate,
      };
    }
    if (!this.isMatchMode()) {
      changedData = {
        ...changedData,
        watermarkEnabled: selectedSeason && selectedSeason.episodesWatermarkEnabled,
      };
    }
    this.updateEpisodeAndFlagEdit(changedData);
  }

  onEpisodeChange() {
    let number = null;
    if (this.refs.episodeNumber.getValue()) {
      number = parseInt(this.refs.episodeNumber.getValue(), 10);
    }
    this.updateEpisodeAndFlagEdit({
      number,
    });
  }

  onVideoFileChange(file) {
    this.updateEpisodeAndFlagEdit({
      video: {
        ...this.props.episode.video,
        file,
      },
    });
  }

  onCaptionFileChange = (event) => {
    const uploadedCaptionFile = event.target.files[0];
    if (uploadedCaptionFile) {
      this.props.uploadCaptionToS3(uploadedCaptionFile).then((newCaptionsS3Key) =>
        this.updateEpisodeAndFlagEdit({
          video: {
            ...this.props.episode.video,
            captionsS3Key: newCaptionsS3Key,
          },
        })
      );
    }
  };

  onVideoPreviewEnabledChange = (event) => {
    this.updateEpisodeAndFlagEdit({
      videoPreviewEnabled: event.target.checked,
    });
  };

  onCaptionsIngestEnabledChange = (event) => {
    this.updateEpisodeAndFlagEdit({
      video: {
        ...this.props.episode.video,
        captionsIngestEnabled: event.target.checked,
      },
    });
  };

  onBroadcastDateChange(date) {
    this.updateEpisodeAndFlagEdit({
      broadcastAirDate: date,
    });
  }

  onMaterialKeyChange(event) {
    this.updateEpisodeAndFlagEdit({
      materialKey: event.target.value,
    });
  }

  onPhoenixEpisodeIdentifierChange(event) {
    this.updateEpisodeAndFlagEdit({
      phoenixEpisodeIdentifier: event.target.value,
    });
  }

  onAutoActivateChange(event) {
    this.updateEpisodeAndFlagEdit({
      autoActivate: event.target.checked,
    });
  }

  onDrmChange(event) {
    this.updateEpisodeAndFlagEdit({
      video: {
        ...this.props.episode.video,
        drm: event.target.checked,
      },
    });
  }

  onDescriptionChange(event) {
    this.updateEpisodeAndFlagEdit({
      description: event.target.value,
    });
  }

  onNameChange(event) {
    this.updateEpisodeAndFlagEdit({
      name: event.target.value,
    });
  }

  onClassificationCodeChange(event) {
    this.updateEpisodeAndFlagEdit({
      classificationCode: !isDefaultOptionSelected(this.refs.videoClassification) ? event.target.value : null,
    });
  }

  onTagsChange(tags) {
    this.updateEpisodeAndFlagEdit({
      tags,
    });
  }

  onCuePointsChange(cuePoints) {
    this.updateEpisodeAndFlagEdit({
      cuePoints,
    });
  }

  onAdsEnabledChange = (value) => {
    this.props.updateEpisode({
      adsEnabled: value,
    });
  };

  onPublishingScheduleChange(startDate, endDate) {
    this.updateEpisodeAndFlagEdit({
      availability: startDate,
      expiry: endDate,
    });
  }

  onGeoBlockingChange(event) {
    this.updateEpisodeAndFlagEdit({
      geoBlocking: event.target.checked,
    });
  }

  onWatermarkEnabledChange(event) {
    this.updateEpisodeAndFlagEdit({
      watermarkEnabled: event.target.checked,
    });
  }

  onZypeMrssFeedEnabledChange = (event) => {
    this.updateEpisodeAndFlagEdit({
      isZypeMrssFeedEnabled: event.target.checked,
    });
  };

  onFastChannelLicensingStartDateChange = (date) => {
    this.updateEpisodeAndFlagEdit({
      fastChannelLicensingStartDate: date || null,
    });
  };

  onFastChannelLicensingEndDateChange = (date) => {
    this.updateEpisodeAndFlagEdit({
      fastChannelLicensingEndDate: date || null,
    });
  };

  onProducerNotesInput(event) {
    this.updateEpisodeAndFlagEdit({
      producerNotes: event.target.value,
    });
  }

  onTranscodingProfileChange = (event) => {
    this.updateEpisodeAndFlagEdit({
      transcodingProfile: event.target.value,
    });
  };

  getVideoAlerts() {
    if (isVideoPreviouslyUploaded(this.props.episode.video.status) && !this.props.isReschedule) {
      return [`Video Cloud Status: ${this.props.episode.video.status}`];
    }
    return [];
  }

  getFramePickerVideoUrl() {
    const selectedVideoFile = _.get(this.props, 'episode.video.file');
    const existingVideoS3Url = _.get(this.props, 'episode.video.signedS3Url');
    return selectedVideoFile ? URL.createObjectURL(selectedVideoFile) : existingVideoS3Url;
  }

  confirmSave(event) {
    event.preventDefault();
    this.save();
  }

  formHasEdits = () => {
    return this.state.formEdited || this.props.imageUpload.imageId || this.props.imageUpload.uploading;
  };

  cancel = (event) => {
    event.preventDefault();
    if (this.formHasEdits()) {
      this.props.showConfirmationModal(
        'Are you sure you want to close the form? Any unsaved changes will be lost.',
        this.props.close
      );
    } else {
      this.props.close();
    }
  };

  saveAndUpload(event) {
    event.preventDefault();
    const descriptions = [];
    if (this.props.episode.autoActivate) {
      descriptions.push(
        'You are going to save an episode with auto activate enabled. This means the episode will be transition directly to IN USE state once finished transcoding.'
      );
    }
    if (this.props.episode.availability < this.props.episode.broadcastAirDate) {
      descriptions.push('Start Date is before Broadcast Air Date');
    }
    if (descriptions.length > 0) {
      return this.props.showConfirmationModal(descriptions, this.save);
    }
    if (this.props.isEpisodeDraggedandDropped) {
      this.props.close();
    }
    return this.save();
  }

  isEpisodeUsingSeasonDefaults() {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.episode.seasonId);
    if (!selectedSeason) return false;
    if (
      !this.props.episode.contentIdChannel ||
      !this.props.episode.contentIdMatchPolicy ||
      !this.props.episode.contentIdUsagePolicy
    ) {
      return false;
    }
    return (
      this.props.episode.contentIdChannel === selectedSeason.defaultContentIdChannel &&
      this.props.episode.contentIdMatchPolicy === selectedSeason.defaultContentIdMatchPolicy &&
      this.props.episode.contentIdUsagePolicy === selectedSeason.defaultContentIdUsagePolicy
    );
  }

  getEpisodeData() {
    const episodeName = this.refs.episodeName;
    const episode = {
      id: this.props.episode.id,
      name: this.props.episode.name || episodeName.getInputDOMNode().placeholder,
      tvSeriesId: this.props.episode.tvSeriesId,
      description: this.props.episode.description,
      seasonId: this.props.episode.seasonId,
      number: this.props.episode.number,
      materialKey: this.props.episode.materialKey,
      phoenixEpisodeIdentifier: this.props.episode.phoenixEpisodeIdentifier,
      broadcastAirDate: this.props.episode.broadcastAirDate,
      availability: this.props.episode.availability,
      expiry: this.props.episode.expiry,
      tags: this.props.episode.tags,
      classificationCode: this.props.episode.classificationCode,
      autoActivate: this.props.episode.autoActivate,
      geoBlocking: this.props.episode.geoBlocking,
      watermarkEnabled: this.props.episode.watermarkEnabled,
      uploadToYoutube: this.props.episode.uploadToYoutube,
      contentIdChannel: this.props.episode.contentIdChannel,
      contentIdUsagePolicy: this.props.episode.contentIdUsagePolicy,
      contentIdMatchPolicy: this.props.episode.contentIdMatchPolicy,
      contentIdExcludeTimes: this.props.episode.contentIdExcludeTimes,
      videoPreviewEnabled: this.props.episode.videoPreviewEnabled,
      adsEnabled: this.props.episode.adsEnabled,
      producerNotes: this.props.episode.producerNotes,
      // uploadedImages array to support future requirement of adding more images to episodes
      uploadedImages: [],
      video: {
        duration: this.props.episode.video.duration,
        drm: this.props.episode.video.drm,
        cuePoints:
          (this.props.episode.cuePoints && hoursMinutesSecondstoSeconds(this.props.episode.cuePoints)) || [],
        captionsIngestEnabled: this.props.episode.video.captionsIngestEnabled,
      },
      transcodingProfile: this.props.episode.transcodingProfile,
      isZypeMrssFeedEnabled: this.props.episode.isZypeMrssFeedEnabled,
      fastChannelLicensingStartDate: this.props.episode.fastChannelLicensingStartDate,
      fastChannelLicensingEndDate: this.props.episode.fastChannelLicensingEndDate,
    };

    if (this.isEpisodeUsingSeasonDefaults()) {
      episode.contentIdChannel = null;
      episode.contentIdUsagePolicy = null;
      episode.contentIdMatchPolicy = null;
    }

    if (this.props.episode.masterSeriesName) {
      episode.masterSeriesName = this.props.episode.masterSeriesName;
    }

    if (this.props.episode.videoId) {
      episode.videoId = this.props.episode.videoId;
    }

    if (this.props.episode.video.file) {
      episode.video.file = this.props.episode.video.file;
    }

    if (this.props.imageUpload.imageId) {
      episode.imageId = this.props.imageUpload.imageId;
    }

    if (this.props.featuredImageUpload && this.props.featuredImageUpload.imageId) {
      episode.uploadedImages.push({
        id: this.props.featuredImageUpload.imageId,
        type: this.props.featuredImageUpload.imageType,
      });
    }

    if (this.props.episode.video && this.props.episode.video.captionsS3Key) {
      episode.video.captionsS3Key = this.props.episode.video.captionsS3Key;
    }

    return episode;
  }

  save() {
    const episode = this.getEpisodeData();

    if (!isFormValid(episode, getValidationRules(this.props.episode.state))) {
      this.props.showFormValidation();
      return;
    }

    if (this.props.isReschedule) {
      this.props.rescheduleAndSaveEpisode(episode);
    } else if (this.isMatchMode()) {
      this.props.checkAndImportEpisodeForIngestJob(this.props.ingestJobId, episode);
    } else {
      this.props.saveEpisode(episode);
    }
  }

  replaceEpisodeForIngestJob() {
    this.props.replaceEpisodeForIngestJob(
      this.props.ingestJobId,
      this.props.duplicateEpisode.id,
      this.getEpisodeData()
    );
  }

  parseDateFromInput(dateValue) {
    return dateValue || null;
  }

  isErrorMessageDisplayed() {
    return this.props.errorMessage;
  }

  isSubmitDisabled() {
    return this.props.imageUpload.uploading || this.props.saving;
  }

  isSavingDisabled() {
    if (this.isMatchMode() || this.props.isReschedule) {
      return false;
    }
    return !isEditEnabled(this.props.episode);
  }

  isUploadDisabled() {
    return (
      !(
        (this.props.episode.state === 'ARCHIVED' && this.props.isReschedule) ||
        isUploadEnabled(this.props.episode)
      ) || this.isMatchMode()
    );
  }

  isCaptionEnabledButtonDisabled = () => {
    return !isUploadEnabled(this.props.episode);
  };

  isMatchMode() {
    return !!this.props.ingestJobId;
  }

  isSeasonGeoblockedByDefault() {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.episode.seasonId);
    return selectedSeason && selectedSeason.episodesGeoBlocked;
  }

  isSeasonEpisodesWatermarkEnabledByDefault() {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.episode.seasonId);
    return selectedSeason && selectedSeason.episodesWatermarkEnabled;
  }

  isSeasonAdsEnabledByDefault = () => {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.episode.seasonId);
    return selectedSeason && selectedSeason.defaultAdsEnabled;
  };

  showOverwriteEpisodeForm() {
    if (this.isMatchMode() && !!this.props.duplicateEpisode) {
      return (
        <OverwriteEpisodeModal
          duplicateEpisode={this.props.duplicateEpisode}
          episode={this.getEpisodeData()}
          onReplace={this.replaceEpisodeForIngestJob}
        />
      );
    }
    return null;
  }

  recommendedNameCharacterCount = 30;
  recommendedDescriptionCharacterCount = 179;

  render() {
    const seasonSelectDisabled = !this.props.episode.tvSeriesId;
    const nameCharacterCount = this.props.episode.name ? this.props.episode.name.length : 0;
    const descriptionCharacterCount = this.props.episode.description
      ? this.props.episode.description.length
      : 0;

    return (
      <form className="form" onSubmit={this.saveAndUpload} ref="episodeForm" data-pw="episode-form">
        <MaterialKeyMatchModal
          isOpen={this.state.showMaterialKeyMatchModal}
          onClose={this.hideMaterialKeyMatchModal}
          materialKey={this.state.matchedMaterialKey}
          importData={this.materialKeyImportData}
        />
        <div>
          {this.isSavingDisabled() && (
            <Alert bsStyle="warning">Saving is disabled while brightcove ingestion is in progress</Alert>
          )}
          <Panel header="Library" bsStyle="primary" defaultExpanded eventKey="1">
            <Input
              type="select"
              label="Library Name"
              disabled
              className="select-library"
              ref="selectLibrary"
              id="select-library"
              labelClassName="required"
            >
              <option value="TV">TV</option>
            </Input>
            <TVSeriesSelect
              ref="selectTVSeries"
              initialTvSeries={this.props.episode.partOfSeries}
              onTVSeriesChange={this.onTVSeriesChange}
              disabled={!isTvSeriesSelectChangeable(this.props.episode)}
              hasValidationErrors={!!this.props.validationErrors.tvSeriesId}
            />
            <SeasonSelect
              ref="selectSeason"
              onSeasonChange={this.onSeasonChange}
              disabled={seasonSelectDisabled || !isSeasonSelectChangeable(this.props.episode)}
              hasValidationErrors={!!this.props.validationErrors.seasonId}
              tvSeriesId={this.props.episode.tvSeriesId}
              seasonId={this.props.episode.seasonId}
              seasons={this.props.seasons}
              required
            />
            <Input
              type="text"
              label="Episode Number"
              placeholder="Enter an episode number"
              ref="episodeNumber"
              id="text-episode-number"
              labelClassName="required"
              onChange={this.onEpisodeChange}
              value={this.props.episode.number}
              onKeyPress={isUserInputNumeric}
              bsStyle={this.props.validationErrors.number ? 'error' : null}
              data-pw="text-episode-number"
            />
          </Panel>
          <Panel
            header="Source Files"
            bsStyle="primary"
            eventKey="2"
            className={this.props.episode.state === 'ARCHIVED' && !this.props.isReschedule ? 'disabled' : ''}
          >
            <VideoUpload
              isDisabled={this.isUploadDisabled}
              onChange={this.onVideoFileChange}
              alerts={this.getVideoAlerts()}
              selectedFileName={_.get(this.props, 'episode.video.file.name')}
            />
            <Input
              type="checkbox"
              label="Video Preview Enabled"
              ref="videoPreviewEnabled"
              id="video-preview-enabled"
              groupClassName={
                this.props.episode.video.file || this.props.episode.video.videoFileName
                  ? 'form-inline'
                  : 'hide'
              }
              onChange={this.onVideoPreviewEnabledChange}
              checked={this.props.episode.videoPreviewEnabled}
              disabled={this.isUploadDisabled()}
            />
            <CaptionUpload
              isDisabled={
                this.isUploadDisabled || (this.props.episode.state === 'ARCHIVED' && !this.props.isReschedule)
              }
              onChange={this.onCaptionFileChange}
              isUploading={this.props.isCaptionUploading}
              uploadPercentage={this.props.captionUploadPercentage}
              errorMessage={this.props.captionUploadErrorMessage}
            />
            <Input
              type="checkbox"
              label="Captions Enabled"
              ref="captionsIngestEnabled"
              id="check-episode-ingest-captions"
              groupClassName={this.props.episode.video.captionsS3Key ? 'form-inline' : 'hide'}
              onChange={this.onCaptionsIngestEnabledChange}
              checked={this.props.episode.video.captionsIngestEnabled}
              disabled={
                this.isCaptionEnabledButtonDisabled() ||
                (this.props.episode.state === 'ARCHIVED' && !this.props.isReschedule)
              }
            />

            <UploadCuePointCsv
              updateEpisode={this.updateEpisodeAndFlagEdit}
              disabled={this.props.episode.state === 'ARCHIVED' && !this.props.isReschedule}
            />

            <AdCuepointsPicker
              ref="cuePoints"
              mandatoryPreRoll
              onChange={this.onCuePointsChange}
              cuePoints={this.props.episode.cuePoints}
              disabled={this.props.episode.state === 'ARCHIVED' && !this.props.isReschedule}
            />
            <Panel header="Video Transcoding Profile">
              <Dropdown
                items={transcodingProfileCodes}
                isOptionValueNull={false}
                label="Transcoding Profile"
                onChange={this.onTranscodingProfileChange}
                currentValue={this.props.episode.transcodingProfile}
              />
            </Panel>
          </Panel>
          <Panel bsStyle="primary" header="Artwork">
            <ImageUpload
              id="image-episode-thumbnail"
              label="Episode Thumbnail Image"
              resourceGroup={IMAGES.TV}
              imageUploadRef={EPISODE_IMAGE_REF}
              imageType={EPISODE_THUMBNAIL}
              showFramePicker
              framePickerVideoUrl={this.getFramePickerVideoUrl()}
            />
            <hr />
            <ImageUpload
              id="image-featured-thumbnail"
              label="Featured Thumbnail Image"
              resourceGroup={IMAGES.TV}
              imageUploadRef={EPISODE_FEATURED_THUMBNAIL_IMAGE_REF}
              imageType={FEATURED_THUMBNAIL}
            />
          </Panel>
          <Panel header="Metadata" bsStyle="primary" expanded={false} eventKey="3">
            <div className="form-group">
              <label ref="episodeNameLabel">Name</label>
              <div
                id="name-character-limit-label"
                className={`pull-right ${
                  nameCharacterCount > this.recommendedNameCharacterCount ? 'character-count-warning' : ''
                }`}
              >
                <span ref="charCount">
                  {nameCharacterCount} / {this.recommendedNameCharacterCount}
                </span>
                <RecommendedCharacterCountPopover />
              </div>
              <Input
                type="text"
                ref="episodeName"
                id="text-episode-name"
                placeholder={`Episode ${this.props.episode.number}`}
                maxLength="100"
                onChange={this.onNameChange}
                value={this.props.episode.name}
                bsStyle={this.props.validationErrors.name ? 'error' : null}
                autoComplete="off"
                data-pw="text-episode-name"
              />
            </div>
            <div className="form-group">
              <label ref="episodeDescriptionLabel">Description</label>
              <div
                id="description-character-limit-label"
                className={`pull-right ${
                  descriptionCharacterCount > this.recommendedDescriptionCharacterCount
                    ? 'character-count-warning'
                    : ''
                }`}
              >
                <span ref="charCount">
                  {descriptionCharacterCount} / {this.recommendedDescriptionCharacterCount}
                </span>
                <RecommendedCharacterCountPopover />
              </div>
              <Input
                type="textarea"
                ref="episodeDescription"
                id="text-episode-description"
                maxLength="2000"
                onChange={this.onDescriptionChange}
                value={this.props.episode.description}
                data-pw="text-episode-description"
              />
            </div>
            <TagList
              ref="tagList"
              tags={this.props.episode.tags}
              onChange={this.onTagsChange}
              resourceGroup={TAGS.TV}
            />
          </Panel>
          <Panel header="Geoblocking" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label={`Episodes Available only in Australia. ${
                this.props.episode.seasonId
                  ? `(Season Default: ${this.isSeasonGeoblockedByDefault() ? 'true' : 'false'})`
                  : ''
              }`}
              ref="geoBlocking"
              id="check-episode-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onGeoBlockingChange}
              checked={this.props.episode.geoBlocking}
            />
          </Panel>
          <Panel header="Watermark" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label={`Enable Watermark for Episodes. ${
                this.props.episode.seasonId
                  ? `(Season Default: ${
                      this.isSeasonEpisodesWatermarkEnabledByDefault() ? 'Enabled' : 'Disabled'
                    })`
                  : ''
              }`}
              ref="watermarkEnabled"
              id="check-episode-watermark-enabled"
              groupClassName="form-inline"
              onChange={this.onWatermarkEnabledChange}
              checked={this.props.episode.watermarkEnabled}
              disabled={!isWatermarkEnabledEditable(this.props.episode) || !!this.isMatchMode()}
            />
          </Panel>
          <Panel header="Settings" bsStyle="primary" expanded={false} eventKey="4">
            <Input
              type="checkbox"
              label="DRM"
              ref="videoDrm"
              id="video-drm"
              className="videoDrm"
              groupClassName="form-inline"
              onChange={this.onDrmChange}
              checked={this.props.episode.video.drm}
              disabled={!isDrmEditable(this.props.episode)}
            />
            <label>Ad Settings</label>
            <Input
              type="radio"
              label={`Use season default ${
                this.props.episode.seasonId
                  ? `(Season Default: ${this.isSeasonAdsEnabledByDefault() ? 'Enabled' : 'Disabled'})`
                  : ''
              }`}
              ref="episodeAdsEnabledUseDefaultInput"
              onClick={() => this.onAdsEnabledChange(null)}
              checked={this.props.episode.adsEnabled === null}
            />
            <Input
              type="radio"
              label="Show advertising"
              ref="episodeShowAdsEnabledInput"
              onClick={() => this.onAdsEnabledChange(true)}
              checked={this.props.episode.adsEnabled === true}
            />
            <Input
              type="radio"
              label="No advertising"
              ref="episodeNoAdsEnabledInput"
              onClick={() => this.onAdsEnabledChange(false)}
              checked={this.props.episode.adsEnabled === false}
            />
            <Input
              type="select"
              label="Classification"
              ref="videoClassification"
              id="select-classification"
              className="videoClassification"
              onChange={this.onClassificationCodeChange}
              value={this.props.episode.classificationCode}
            >
              <option value="0">Select a classification</option>
              {this.props.classifications.map((classification) => {
                return (
                  <option key={classification.code} value={classification.code}>
                    {classification.code} - {classification.name}
                  </option>
                );
              })}
            </Input>
            <div className="field-with-btn">
              <div className="field-with-btn-input-wrapper">
                <Input
                  type="text"
                  label="Material Key"
                  ref="episodeMaterialKey"
                  id="text-material-key"
                  maxLength="20"
                  onChange={this.onMaterialKeyChange}
                  value={this.props.episode.materialKey}
                />
              </div>
              <Button
                className="field-with-btn-button"
                bsStyle="primary"
                disabled={!this.props.episode.materialKey}
                onClick={() => this.showMaterialKeyMatchModal(this.props.episode.materialKey)}
                ref="materialKeyImportButton"
              >
                Import Episode Data from Phoenix
              </Button>
            </div>
            <Input
              type="text"
              label="Episode Identifier"
              ref="phoenixEpisodeIdentifier"
              maxLength="100"
              onChange={this.onPhoenixEpisodeIdentifierChange}
              value={this.props.episode.phoenixEpisodeIdentifier}
            />
            <div className={`form-group ${this.props.validationErrors.broadcastAirDate ? 'error' : ''}`}>
              <label>Broadcast Air Date</label>
              <DateTimePicker
                ref="broadcastAirDate"
                id="broadcast-air-date"
                className="broadcastAirDate"
                onChange={this.onBroadcastDateChange}
                value={this.props.episode.broadcastAirDate}
              />
            </div>
          </Panel>
          <PublishingSchedule
            ref="publishingSchedule"
            onChange={this.onPublishingScheduleChange}
            publishStartDate={this.props.episode.availability}
            publishEndDate={this.props.episode.expiry}
            validationErrors={{
              publishStartDate: this.props.validationErrors.availability,
              publishEndDate: this.props.validationErrors.expiry,
            }}
          />
          <Panel header="Workflow" bsStyle="primary">
            <Input
              type="checkbox"
              label="Activate after Ingestion"
              ref="autoActivate"
              id="auto-activate"
              className="autoActivate"
              groupClassName="form-inline form__danger-input"
              onChange={this.onAutoActivateChange}
              checked={this.props.episode.autoActivate}
              disabled={!isAutoActivateEditable(this.props.episode)}
            />
          </Panel>
          <Panel header="Content ID" bsStyle="primary">
            <ContentIdSection
              ref="contentIdSection"
              episode={this.props.episode}
              updateEpisode={this.updateEpisodeAndFlagEdit}
              seasons={this.props.seasons}
              validationErrors={this.props.validationErrors}
              episodeIsUsingSeasonDefaults={this.isEpisodeUsingSeasonDefaults()}
            />
          </Panel>
          <Panel header="FAST Channel MRSS Feed" bsStyle="primary">
            <div className="mrssCheckBoxContainer">
              <Input
                type="checkbox"
                label="Include in MRSS Feed"
                ref="zypeMrssFeed"
                id="zypeMrssFeed"
                onChange={this.onZypeMrssFeedEnabledChange}
                checked={this.props.episode.isZypeMrssFeedEnabled}
                disabled={!this.props.episode.id}
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Popover>
                    {this.props.episode.id ? (
                      <div>Enable this to include the episode in the FAST Channel MRSS Feed.</div>
                    ) : (
                      <div>
                        This is inherited from Season for this episode, it can be udpated later by editing the
                        episode.
                      </div>
                    )}
                  </Popover>
                }
              >
                <i className="fa fa-info-circle"></i>
              </OverlayTrigger>
            </div>
          </Panel>
          <EpisodeLicensePeriod
            onFastChannelLicensingStartDateChange={this.onFastChannelLicensingStartDateChange}
            onFastChannelLicensingEndDateChange={this.onFastChannelLicensingEndDateChange}
            fastChannelLicensingStartDate={this.props.episode.fastChannelLicensingStartDate}
            fastChannelLicensingEndDate={this.props.episode.fastChannelLicensingEndDate}
            validationErrors={this.props.validationErrors}
            episode={this.props.episode}
          />
          <Panel header="Notes" bsStyle="primary">
            <Input
              id="producer-notes-input"
              type="textarea"
              label="Producer Notes"
              ref="producerNotes"
              onInput={(e) => this.onProducerNotesInput(e)}
              value={this.props.episode.producerNotes}
            />
          </Panel>
        </div>
        {this.isErrorMessageDisplayed() && (
          <FormErrorMessage className="episode-server-error-message" message={this.props.errorMessage} />
        )}
        <div className="form__submit-button-bar">
          <Button
            ref="cancelButton"
            id="video-modal-close"
            type="button"
            className="form__button"
            onClick={this.cancel}
          >
            Close
          </Button>
          {this.isSavingDisabled() ? (
            ''
          ) : (
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          )}
        </div>
        {this.showOverwriteEpisodeForm()}
      </form>
    );
  }
}

EpisodeForm.propTypes = {
  close: PropTypes.func,
  episode: PropTypes.object,
  ingestJobId: PropTypes.number,
  seasons: PropTypes.array,
  classifications: PropTypes.array,
  errorMessage: PropTypes.string,
  imageUpload: PropTypes.object,
  featuredImageUpload: PropTypes.object,
  saveEpisode: PropTypes.func,
  updateEpisode: PropTypes.func,
  updateEpisodeWithImportData: PropTypes.func,
  getSeasons: PropTypes.func,
  replaceEpisodeForIngestJob: PropTypes.func,
  checkAndImportEpisodeForIngestJob: PropTypes.func,
  duplicateEpisode: PropTypes.object,
  saving: PropTypes.bool,
  isReschedule: PropTypes.bool, // This should be remove when we have proper video states and a save method should be passed.
  rescheduleAndSaveEpisode: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
  uploadCaptionToS3: PropTypes.func,
  isCaptionUploading: PropTypes.bool,
  captionUploadPercentage: PropTypes.number,
  captionUploadErrorMessage: PropTypes.string,
  isEpisodeDraggedandDropped: PropTypes.bool,
  isZypeMrssFeedEnabled: PropTypes.bool,
};

function mapStateToProps(state) {
  const episodeForm = state.episodeForm;
  return {
    episode: episodeForm.episode,
    tvSeries: episodeForm.tvSeries,
    seasons: episodeForm.seasons,
    classifications: episodeForm.classifications,
    errorMessage: episodeForm.errorMessage,
    duplicateEpisode: episodeForm.duplicateEpisode,
    saving: episodeForm.saving,
    imageUpload: state.imageUploads[EPISODE_IMAGE_REF],
    featuredImageUpload: state.imageUploads[EPISODE_FEATURED_THUMBNAIL_IMAGE_REF],
    validationErrors: episodeForm.validationErrors,
    captionUploadPercentage: getCaptionUploadPercentage(state),
    isCaptionUploading: isCaptionUploading(state),
    captionUploadErrorMessage: getCaptionUploadErrorMessage(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveEpisode: (...args) => dispatch(saveEpisode(...args)),
    updateEpisode: (...args) => dispatch(updateEpisode(...args)),
    updateEpisodeWithImportData: (data) => dispatch(updateEpisodeWithImportData(data)),
    getSeasons: (...args) => dispatch(getSeasonsForForm(...args)),
    replaceEpisodeForIngestJob: (...args) => dispatch(replaceEpisodeForIngestJob(...args)),
    checkAndImportEpisodeForIngestJob: (...args) => dispatch(checkAndImportEpisodeForIngestJob(...args)),
    rescheduleAndSaveEpisode: (...args) => dispatch(rescheduleAndSaveEpisode(...args)),
    showConfirmationModal: (...args) => dispatch(showConfirmationModal(...args)),
    showFormValidation: () => dispatch(showFormValidation()),
    uploadCaptionToS3: (captionFilePointer) => dispatch(uploadCaptionToS3(captionFilePointer)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeForm);
