import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import moment from 'moment';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText, FormatBoolean } from 'src/scripts/components/Season/DetailsView/Formatters';
import { getEpisodeDisplayNameExample } from 'src/scripts/lib/episodeDisplayNames';
import environment from 'config/environment';
import { DASH_FOR_EMPTY_CONTENT } from 'src/scripts/constants/common';

const getSportTypeDisplayName = (season) => {
  return season.sportTypeDisplayName || (season.sportType && season.sportType.name);
};

const MainPanel = ({ season }) => {
  return (
    <Panel header="Main">
      <DetailsRowItem label="TV Series">
        <FormatText>{season.partOfSeries.name}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Season Name">
        <FormatText>{season.name}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Description">
        <FormatText>{season.description}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Season Number">
        <FormatText>{season.seasonNumber}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Classification Rating">
        <FormatText>{season.classificationRating}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Season Year">
        <FormatText>{season.seasonYear}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Episode Display Name Format">
        <FormatText>{getEpisodeDisplayNameExample(season.episodeDisplayNameFormat)}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Catalog Code">
        <FormatText>{season.catalogCode}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="All Catalog Codes">
        <FormatText>{season.allCatalogCodes && season.allCatalogCodes.join(', ')}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="On Air Schedule">
        <FormatText>{season.onAirScheduleDate}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Channel">
        <FormatText>{season.channel && season.channel.name}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Boxset">
        <FormatBoolean>{season.isBoxSet}</FormatBoolean>
      </DetailsRowItem>
      <DetailsRowItem label="Boxset Publish Date">
        <FormatText>
          {season.publishedDate ? moment(season.publishedDate).format('DD-MM-YYYY') : null}
        </FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Sport Type">
        <FormatText>{season.sportType && season.sportType.name}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem label="Sport Type Display Name">
        <FormatText>{getSportTypeDisplayName(season)}</FormatText>
      </DetailsRowItem>
      {environment.featureFlags.nineVideoFeaturesEnabled ? (
        <DetailsRowItem id="season-content-flag" label="Content Flag">
          {season.contentFlag || DASH_FOR_EMPTY_CONTENT}
        </DetailsRowItem>
      ) : null}
    </Panel>
  );
};

MainPanel.propTypes = {
  season: PropTypes.object,
};

export default MainPanel;
