import _ from 'lodash';
import * as requestHandler from './requestHandler';
const PREFIX = 'api/v1/libraries/network';

export function createNetworkClip(clip) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/subcategories/${clip.subcategoryId}/clips`,
    JSON.stringify(clip)
  );
}

export function updateNetworkClip(clip) {
  return requestHandler.send('PATCH', `${PREFIX}/clips/${clip.id}`, JSON.stringify(clip));
}

export function expireNetworkClip(clipId) {
  return requestHandler.send('POST', `${PREFIX}/clips/${clipId}/expired`);
}

export function getAllNetworkClips(params) {
  return requestHandler.send('GET', `${PREFIX}/clips`, params);
}

export function deleteNetworkClip(networkClipId) {
  return requestHandler.send('DELETE', `${PREFIX}/clips/${networkClipId}`);
}

export function getNetworkClips(params) {
  return requestHandler.send(
    'GET',
    `${PREFIX}/subcategories/${params.subcategoryId}/clips`,
    _.omit(params, 'subcategoryId')
  );
}

export function getNetworkClipById(networkClipId) {
  return requestHandler.send('GET', `${PREFIX}/clips/${networkClipId}`);
}

export function getSubcategories(categoryId) {
  return requestHandler.send('GET', `${PREFIX}/categories/${categoryId}/subcategories`);
}

export function getSubcategoryById(subcategoryId) {
  return requestHandler.send('GET', `${PREFIX}/subcategories/${subcategoryId}`);
}

export function updateSubcategory(subcategory) {
  return requestHandler.send(
    'PATCH',
    `${PREFIX}/subcategories/${subcategory.id}`,
    JSON.stringify(subcategory)
  );
}

export function createCategory(payload) {
  return requestHandler.send('POST', `${PREFIX}/categories`, JSON.stringify(payload));
}

export function getCategories() {
  return requestHandler.send('GET', `${PREFIX}/categories`);
}

export function updateCategory(category) {
  return requestHandler.send('PATCH', `${PREFIX}/categories/${category.id}`, JSON.stringify(category));
}

export function deleteCategory(categoryId) {
  return requestHandler.send('DELETE', `${PREFIX}/categories/${categoryId}`);
}

export function getCategoryById(categoryId) {
  return requestHandler.send('GET', `${PREFIX}/categories/${categoryId}`);
}

export function deleteSubcategory(subcategoryId) {
  return requestHandler.send('DELETE', `${PREFIX}/subcategories/${subcategoryId}`);
}

export function createSubcategory(subcategory) {
  return requestHandler.send(
    'POST',
    `${PREFIX}/categories/${subcategory.categoryId}/subcategories`,
    JSON.stringify(subcategory)
  );
}

export function rescheduleNetworkClip(clip) {
  return requestHandler.send('POST', `${PREFIX}/clips/${clip.id}/rescheduled`, JSON.stringify(clip));
}

export function notifyWorkflowAction(action, id) {
  return requestHandler.send('POST', `${PREFIX}/clips/${id}/${action}`, JSON.stringify({}));
}

export function getNetworkThirdPartyProviders() {
  return requestHandler.send('GET', `${PREFIX}/clips/third-party-providers`);
}
