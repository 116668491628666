import { GENRE_IMAGE, GENRE_FEATURED_IMAGE, GENRE_FEATURED_POSTER_IMAGE } from 'src/scripts/lib/imageTypes';

export const genreImageTypeData = [
  {
    type: GENRE_IMAGE,
    header: 'Genre Image',
    wrapperClassName: 'genre-detail-image-wrapper genre-image-default',
  },
  {
    type: GENRE_FEATURED_IMAGE,
    header: 'Featured Image',
    wrapperClassName: 'genre-detail-image-wrapper',
  },
  {
    type: GENRE_FEATURED_POSTER_IMAGE,
    header: 'Featured Poster Image',
    wrapperClassName: 'genre-detail-image-wrapper genre-image-poster',
  },
];
