import { OverlayTrigger, Popover } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

const NotesWithTooltip = ({ notes, emptyText }) => {
  const tooltip = <Popover id="notes-popover">{notes || emptyText}</Popover>;
  return (
    <OverlayTrigger placement="right" overlay={tooltip}>
      <i
        className="fa fa-info-circle"
        style={notes ? { color: '#737373', cursor: 'pointer' } : { color: '#E5E5E5', cursor: 'arrow' }}
        data-pw={'notes-tooltip--icon'}
      ></i>
    </OverlayTrigger>
  );
};

NotesWithTooltip.propTypes = {
  notes: PropTypes.string,
  emptyText: PropTypes.string,
};

export default NotesWithTooltip;
