import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap';
import LoadingIcon from 'src/scripts/components/LoadingIcon';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import { getTVSeries } from 'src/scripts/actions/tvSeries';
import {
  pushToTvSeriesToUseCollection,
  removeFromTvSeriesToUseCollection,
} from 'src/scripts/actions/collection';
import ActiveEpisodeState from 'src/scripts/components/Collection/ActiveEpisodeState';
import { LABELS } from '../../../../constants/common';

export const mapStateToProps = (state) => ({
  tvSeriesToUseCollection: state.collectionForm.tvSeriesToUseCollection,
});

export const mapDispatchToProps = (dispatch) => ({
  getTvSeriesUsingCollection: (collectionId) =>
    dispatch(getTVSeries({ recommendedCollectionId: collectionId, includeActiveEpisodeStates: true })),
  pushToTvSeriesToUseCollection: (tvSeries) => dispatch(pushToTvSeriesToUseCollection(tvSeries)),
  removeFromTvSeriesToUseCollection: (tvSeries) => dispatch(removeFromTvSeriesToUseCollection(tvSeries)),
});

export class CollectionUsage extends React.Component {
  static propTypes = {
    collectionId: PropTypes.number.isRequired,
    tvSeriesToUseCollection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    getTvSeriesUsingCollection: PropTypes.func.isRequired,
    pushToTvSeriesToUseCollection: PropTypes.func.isRequired,
    removeFromTvSeriesToUseCollection: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedSeries: null,
    };
  }

  componentDidMount() {
    this.props
      .getTvSeriesUsingCollection(this.props.collectionId)
      .always(() => this.setState({ isLoading: false }));
  }

  renderLoader = () => (
    <div>
      <LoadingIcon loading /> Loading...{' '}
    </div>
  );

  renderTvSeriesToUseCollection = () => (
    <div className="collection-usage__list-container">
      <ListGroup className="collection-usage__scroll-container">
        {this.props.tvSeriesToUseCollection.length ? (
          this.props.tvSeriesToUseCollection.map((tvSeries) => (
            <ListGroupItem key={tvSeries.id} className="collection-form-recommended-tv-series-field">
              <div className="name">{tvSeries.name}</div>
              <ActiveEpisodeState
                activeEpisodeCount={tvSeries.activeEpisodeCount}
                isFutureActive={tvSeries.isFutureActive}
              />
              <div className="remove-btn" onClick={() => this.onRemoveSeriesClick(tvSeries)}>
                <i
                  className="remove-btn-icon fa fa-minus-circle"
                  aria-hidden="true"
                  style={{ color: '#c0392b' }}
                ></i>
              </div>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem>No TV Series found</ListGroupItem>
        )}
      </ListGroup>
    </div>
  );

  captureTvSeriesSelectRef = (ref) => {
    this.tvSeriesSelectRef = ref;
  };

  changeSelectedTvSeries = (_, selectedSeries) => {
    this.setState({ selectedSeries });
  };

  onAddSeriesClick = () => {
    if (this.tvSeriesSelectRef) this.tvSeriesSelectRef.clearTvSeries();
    const seriesAlreadyInList = !!this.props.tvSeriesToUseCollection.find(
      (series) => series.id === this.state.selectedSeries.id
    );
    if (seriesAlreadyInList) return;
    this.props.pushToTvSeriesToUseCollection(this.state.selectedSeries);
  };

  onRemoveSeriesClick = (tvSeries) => {
    this.props.removeFromTvSeriesToUseCollection(tvSeries);
  };

  renderAddSeriesField = () => (
    <div className="collection-form-add-series-field">
      <div className="tv-series-select">
        <TVSeriesSelect
          ref={this.captureTvSeriesSelectRef}
          hideHeader
          required={false}
          onTVSeriesChange={this.changeSelectedTvSeries}
          includeActiveEpisodeStates
        />
      </div>
      <div className="add-btn" onClick={this.onAddSeriesClick}>
        <i className="add-btn-icon fa fa-plus" aria-hidden="true"></i>
      </div>
    </div>
  );

  render() {
    return (
      <Panel header={`TV Series using this as ${LABELS.RECOMMENDED_COLLECTION}`} bsStyle="primary">
        {this.state.isLoading ? this.renderLoader() : this.renderTvSeriesToUseCollection()}
        {this.renderAddSeriesField()}
      </Panel>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionUsage);
