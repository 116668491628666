export const TV_LIVE_EVENT_VIDEO_GROUPS = [
  { name: 'Shortform - Entertainment', id: 'ShortformEntertainment' },
  { name: 'Shortform - Sport', id: 'ShortformSport' },
  { name: 'Shortform - News', id: 'ShortformNews' },
  { name: 'Manually mapped', id: 'manuallyMapped' },
];

export const NETWORK_LIVE_EVENT_VIDEO_GROUPS = [
  { name: 'Entertainment', id: 'NetworkEntertainment' },
  { name: 'Lifestyle', id: 'NetworkLifestyle' },
  { name: 'News and Current Affairs', id: 'NetworkNewsAndCurrentAffairs' },
  { name: 'Sports', id: 'NetworkSports' },
  { name: 'Travel', id: 'NetworkTravel' },
  { name: 'Manually mapped', id: 'manuallyMapped' },
];
