import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Input, Button, Panel, Alert } from 'react-bootstrap';
import { getEpisodes } from 'src/scripts/actions/episode';
import { getSeasonsForForm } from 'src/scripts/actions/season';
import AdCuepointsPicker from 'src/scripts/components/AdCuepointsPicker';
import PublishingSchedule from 'src/scripts/components/PublishingSchedule';
import TagList from 'src/scripts/components/TagList';
import ImageUpload from 'src/scripts/components/ImageUpload';
import { showConfirmationModal, hideConfirmationModal } from 'src/scripts/actions/confirmation';
import { saveClip, updateClip, rescheduleAndSaveClip } from 'src/scripts/actions/clip';
import { isVideoPreviouslyUploaded } from 'src/scripts/components/Video/videoStatus';
import {
  isUploadEnabled,
  isDrmEditable,
  isWatermarkEnabledEditable,
  isEditEnabled,
  isContentSeriesEditable,
  isTvSeriesSelectChangeable,
  isSeasonSelectChangeable,
  isAutoActivateEditable,
} from 'src/scripts/lib/workflowActionsProvider';
import { hoursMinutesSecondstoSeconds } from 'src/scripts/lib/cuepointsConverter';
import { TAGS, IMAGES } from 'src/scripts/lib/resourceGroups';
import VideoUpload from 'src/scripts/components/VideoUpload';
import { CLIP_IMAGE_REF } from 'src/scripts/lib/imageRefs';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/clipForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import SeoMetadataForClips from 'src/scripts/components/SeoMetadataForClips';
import SeasonSelect from '../../SeasonSelect';
import { TV } from 'src/scripts/lib/libraries';
import MultiImageUpload from '../../MultiImageUpload';
import { CLIP_FEATURED_IMAGE } from 'src/scripts/lib/imageTypes.js';
import Dropdown from '../../Dropdown';
import transcodingProfileCodes from 'src/scripts/constants/transcodingProfileCodes';

export class ClipForm extends React.Component {
  constructor(props) {
    super(props);

    this.contentSeriesUnselected = 'contentSeriesUnselected';
  }

  onTVSeriesChange = (tvSeriesId) => {
    this.props.updateClip({
      tvSeriesId,
      seasonId: 0,
      episodeId: 0,
      oztamEpisodeRelationship: 'ASSOCIATED',
    });
    if (tvSeriesId) {
      this.props.getSeasons({ tvSeriesId });
    }
  };

  onSeasonChange = (seasonId) => {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === seasonId);
    this.props.updateClip({
      seasonId,
      episodeId: 0,
      contentSeries: this.contentSeriesUnselected,
      oztamEpisodeRelationship: 'ASSOCIATED',
      geoBlocking: selectedSeason && selectedSeason.clipsGeoBlocked,
      watermarkEnabled: selectedSeason && selectedSeason.clipsWatermarkEnabled,
    });
    if (seasonId) {
      this.props.getEpisodes({ seasonId });
    }
  };

  onEpisodeChange = () => {
    const episodeId = Number(this.refs.selectEpisode.getValue());
    this.props.updateClip({
      episodeId,
    });
    if ((!this.props.clip.oztamEpisodeRelationship && episodeId) || !episodeId) {
      this.props.updateClip({
        oztamEpisodeRelationship: 'ASSOCIATED',
      });
    }
  };

  onEpisodeRelationshipChange = () => {
    this.props.updateClip({
      oztamEpisodeRelationship: this.refs.selectEpisodeRelationship.getValue(),
    });
  };

  onNameChange = () => {
    this.props.updateClip({
      name: this.refs.clipName.getValue(),
    });
  };

  onDescriptionChange = () => {
    this.props.updateClip({
      description: this.refs.clipDescription.getValue(),
    });
  };

  onTagsChange = (tags) => {
    this.props.updateClip({
      tags,
    });
  };

  onContentSeriesChange = () => {
    this.props.updateClip({
      contentSeries: this.refs.contentSeries.getValue(),
    });
  };

  onDrmChange = () => {
    this.props.updateClip({
      drm: this.refs.videoDrm.getChecked(),
    });
  };

  onCuePointsChange = (cuePoints) => {
    this.props.updateClip({
      cuePoints,
    });
  };

  onPublishingScheduleChange = (publishStartDate, publishEndDate) => {
    this.props.updateClip({
      availability: publishStartDate,
      expiry: publishEndDate,
    });
  };

  onVideoFileChange = (file) => {
    this.props.updateClip({
      file,
    });
  };

  onAutoActivateChange = (event) => {
    this.props.updateClip({
      autoActivate: event.target.checked,
    });
  };

  onGeoBlockingChange = (event) => {
    this.props.updateClip({
      geoBlocking: event.target.checked,
    });
  };

  onWatermarkEnabledChange = (event) => {
    this.props.updateClip({
      watermarkEnabled: event.target.checked,
    });
  };

  onAdsEnabledChange = (value) => {
    this.props.updateClip({
      adsEnabled: value,
    });
  };

  onProducerNotesInput = (event) => {
    this.props.updateClip({
      producerNotes: event.target.value,
    });
  };

  onTranscodingProfileChange = (event) => {
    this.props.updateClip({
      transcodingProfile: event.target.value,
    });
  };

  onOK = () => {
    this.props.hideConfirmationModal();
    this.save();
  };

  getImagesWithTypes = (images, types) => {
    return images ? images.filter((image) => types.includes(image.type)) : [];
  };

  getVideoAlerts = () => {
    if (
      isVideoPreviouslyUploaded(this.props.clip.video && this.props.clip.video.status) &&
      !this.props.isReschedule
    ) {
      return [`Video Cloud Status: ${this.props.clip.video.status}`];
    }
    return [];
  };

  getFramePickerVideoUrl = () => {
    const selectedVideoFile = _.get(this.props, 'clip.file');
    const existingVideoS3Url = _.get(this.props, 'clip.video.signedS3Url');
    return selectedVideoFile ? URL.createObjectURL(selectedVideoFile) : existingVideoS3Url;
  };

  isSubmitDisabled = () => {
    const imagesUploading = _.pickBy(this.props.multiImageUpload, (image) => image.uploading);
    const areImagesUploading = imagesUploading ? _.keys(imagesUploading).length > 0 : false;

    return this.props.imageUpload.uploading || this.props.saving || areImagesUploading;
  };

  isSavingDisabled = () => {
    if (this.props.isReschedule) {
      return false;
    }
    return !isEditEnabled(this.props.clip);
  };

  isSeasonSelectDisabled = () => {
    return !this.props.clip.tvSeriesId || !isSeasonSelectChangeable(this.props.clip);
  };

  isEpisodeSelectDisabled = () => {
    return !this.props.clip.seasonId;
  };

  isEpisodeRelationshipSelectHidden = () => {
    return !this.props.clip.episodeId;
  };

  isContentSeriesDisabled = () => {
    return !this.props.clip.seasonId || !isContentSeriesEditable(this.props.clip);
  };

  isAutoActivateConfirmationDisplayed = () => {
    return this.props.autoActivateConfirmationDisplayed;
  };

  saveAndUpload = (event) => {
    event.preventDefault();
    if (this.props.clip.autoActivate) {
      const description = [
        'You are going to save a clip with auto activate enabled. This means the clip will be transition directly to IN USE state once finished transcoding.',
      ];
      return this.props.showConfirmationModal(description, this.onOK);
    }
    return this.save();
  };

  isUploadDisabled = () => {
    return !(
      (this.props.clip.state === 'ARCHIVED' && this.props.isReschedule) ||
      isUploadEnabled(this.props.clip)
    );
  };

  isSeasonGeoblockedByDefault = () => {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.clip.seasonId);
    return selectedSeason && selectedSeason.clipsGeoBlocked;
  };

  save = () => {
    const uploadedImages = [];

    for (const key of Object.keys(this.props.multiImageUpload)) {
      if (!this.props.multiImageUpload[key].isImageRemoved) {
        uploadedImages.push({
          id: this.props.multiImageUpload[key].imageId,
          type: this.props.multiImageUpload[key].type,
        });
      }
    }

    const clip = {
      ...this.props.clip,
      video: {
        drm: this.props.clip.drm,
        cuePoints:
          (this.props.clip.cuePoints && hoursMinutesSecondstoSeconds(this.props.clip.cuePoints)) || [],
      },
      availability: this.props.clip.availability,
      expiry: this.props.clip.expiry,
      autoActivate: this.props.clip.autoActivate,
      adsEnabled: this.props.clip.adsEnabled,
      images: uploadedImages,
      transcodingProfile: this.props.clip.transcodingProfile,
    };
    if (!this.props.clip.episodeId) {
      clip.episodeId = null;
      clip.oztamEpisodeRelationship = null;
    } else {
      clip.oztamEpisodeRelationship = this.props.clip.oztamEpisodeRelationship;
    }
    if (this.props.clip.contentSeries === this.contentSeriesUnselected) {
      clip.contentSeries = '';
    }
    const videoFile = this.props.clip.file;
    if (videoFile) {
      clip.video.file = videoFile;
    }

    if (this.props.imageUpload.imageId) {
      clip.imageId = this.props.imageUpload.imageId;
    }

    if (clip.partOfSeries) {
      delete clip.partOfSeries;
    }

    if (!isFormValid(clip, getValidationRules(this.props.clip.state))) {
      this.props.showFormValidation();
      return;
    }
    if (this.props.isReschedule) {
      this.props.rescheduleAndSaveClip(clip);
    } else {
      this.props.saveClip(clip);
    }
  };

  isSeasonClipsWatermarkEnabledByDefault = () => {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.clip.seasonId);
    return selectedSeason && selectedSeason.clipsWatermarkEnabled;
  };

  isSeasonAdsEnabledByDefault = () => {
    const selectedSeason = _.find(this.props.seasons, (season) => season.id === this.props.clip.seasonId);
    return selectedSeason && selectedSeason.defaultAdsEnabled;
  };

  render() {
    const seasonForCurrentClip = this.props.seasons.filter(({ id }) => this.props.clip.seasonId === id)[0];
    const currentlyAvailableContentSeries =
      (seasonForCurrentClip && seasonForCurrentClip.availableContentSeries) || [];
    const defaultContentSeriesSelectText = currentlyAvailableContentSeries.length
      ? 'Select a Content Series'
      : 'No Content Series Available';
    const contentSeriesInput = (
      <Input
        type="select"
        label="Content Series"
        ref="contentSeries"
        id="select-content-series"
        disabled={this.isContentSeriesDisabled()}
        onChange={this.onContentSeriesChange}
        value={this.props.clip.contentSeries}
      >
        <option key={this.contentSeriesUnselected} value={this.contentSeriesUnselected}>
          {defaultContentSeriesSelectText}
        </option>
        {currentlyAvailableContentSeries.map((contentSeries) => {
          return <option key={contentSeries}>{contentSeries}</option>;
        })}
      </Input>
    );
    return (
      <form className="form" onSubmit={this.saveAndUpload} ref="clipForm" data-pw="clip-form">
        <div>
          {this.isSavingDisabled() ? (
            <Alert bsStyle="warning">Saving is disabled while brightcove ingestion is in progress</Alert>
          ) : (
            ''
          )}
          <Panel header="Library" bsStyle="primary" defaultExpanded eventKey="1">
            <Input
              type="select"
              label="Library Name"
              disabled
              className="select-library"
              ref="selectLibrary"
              id="select-library"
              labelClassName="required"
            >
              <option value="TV">TV</option>
            </Input>
            <TVSeriesSelect
              ref="selectTVSeries"
              initialTvSeries={this.props.clip.partOfSeries}
              onTVSeriesChange={this.onTVSeriesChange}
              disabled={!isTvSeriesSelectChangeable(this.props.clip)}
              hasValidationErrors={!!this.props.validationErrors.tvSeriesId}
            />
            <SeasonSelect
              ref="selectSeason"
              onSeasonChange={this.onSeasonChange}
              disabled={this.isSeasonSelectDisabled()}
              hasValidationErrors={!!this.props.validationErrors.seasonId}
              tvSeriesId={this.props.clip.tvSeriesId}
              seasonId={this.props.clip.seasonId}
              seasons={this.props.seasons}
              required
            />
            <Input
              type="select"
              label="Episode"
              ref="selectEpisode"
              id="select-episode"
              disabled={this.isEpisodeSelectDisabled()}
              onChange={this.onEpisodeChange}
              value={this.props.clip.episodeId}
              data-pw="select-episode"
            >
              <option value="0">Select an episode</option>
              {this.props.episodes.map((episode) => {
                return (
                  <option key={episode.id} value={episode.id}>
                    {episode.name}
                  </option>
                );
              })}
            </Input>
            <Input
              type="select"
              label="Episode Relationship"
              ref="selectEpisodeRelationship"
              groupClassName={`${this.isEpisodeRelationshipSelectHidden() ? 'hidden' : ''}`}
              onChange={this.onEpisodeRelationshipChange}
              value={this.props.clip.oztamEpisodeRelationship}
            >
              {this.props.episodeRelationships.map((relationship) => {
                return (
                  <option key={relationship.value} value={relationship.value}>
                    {relationship.label}
                  </option>
                );
              })}
            </Input>
          </Panel>
          <Panel header="Source Files" bsStyle="primary" eventKey="2">
            <VideoUpload
              isDisabled={() => this.isUploadDisabled()}
              onChange={this.onVideoFileChange}
              alerts={this.getVideoAlerts()}
              selectedFileName={_.get(this.props, 'clip.file.name')}
            />
            <AdCuepointsPicker
              mandatoryPreRoll
              cuePoints={this.props.clip.cuePoints}
              onChange={this.onCuePointsChange}
              disabled={this.props.clip.state === 'ARCHIVED' && !this.props.isReschedule}
            />
            <Panel header="Video Transcoding Profile">
              <Dropdown
                items={transcodingProfileCodes}
                isOptionValueNull={false}
                label="Transcoding Profile"
                onChange={this.onTranscodingProfileChange}
                currentValue={this.props.clip.transcodingProfile}
              />
            </Panel>
            <ImageUpload
              id="image-clip"
              resourceGroup={IMAGES.TV}
              imageUploadRef={CLIP_IMAGE_REF}
              showFramePicker
              framePickerVideoUrl={this.getFramePickerVideoUrl()}
            />
            <MultiImageUpload
              resourceGroup={IMAGES.TV}
              imageTypes={[CLIP_FEATURED_IMAGE]}
              limit={1}
              existingImages={this.getImagesWithTypes(this.props.clip.images, [CLIP_FEATURED_IMAGE])}
            />
          </Panel>
          <Panel header="Metadata" bsStyle="primary" expanded={false} eventKey="3">
            <Input
              labelClassName="required"
              type="text"
              label="Name"
              ref="clipName"
              id="text-clip-name"
              maxLength="100"
              onChange={this.onNameChange}
              value={this.props.clip.name}
              bsStyle={this.props.validationErrors.name ? 'error' : null}
              data-pw="text-clip-name"
            />
            <Input
              type="textarea"
              label="Description"
              ref="clipDescription"
              id="text-clip-description"
              maxLength="2000"
              onChange={this.onDescriptionChange}
              value={this.props.clip.description}
              data-pw="text-clip-description"
            />
            <TagList
              ref="tagList"
              onChange={this.onTagsChange}
              tags={this.props.clip.tags}
              resourceGroup={TAGS.TV}
            />
            {contentSeriesInput}
          </Panel>
          <SeoMetadataForClips updateClip={this.props.updateClip} clip={this.props.clip} clipType={TV} />
          <Panel header="Geoblocking" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label={`Clips Available only in Australia. ${
                this.props.clip.seasonId
                  ? `(Season Default: ${this.isSeasonGeoblockedByDefault() ? 'true' : 'false'})`
                  : ''
              }`}
              ref="geoBlocking"
              id="check-clip-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onGeoBlockingChange}
              checked={this.props.clip.geoBlocking}
            />
          </Panel>
          <Panel header="Watermark" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label={`Enable Watermark for Clips. ${
                this.props.clip.seasonId
                  ? `(Season Default: ${
                      this.isSeasonClipsWatermarkEnabledByDefault() ? 'Enabled' : 'Disabled'
                    })`
                  : ''
              }`}
              ref="watermarkEnabled"
              id="check-clip-watermark-enabled"
              groupClassName="form-inline"
              disabled={!isWatermarkEnabledEditable(this.props.clip)}
              onChange={this.onWatermarkEnabledChange}
              checked={this.props.clip.watermarkEnabled}
            />
          </Panel>
          <Panel ref="settingsPanel" header="Settings" bsStyle="primary" expanded={false} eventKey="4">
            <Input
              type="checkbox"
              label="DRM"
              ref="videoDrm"
              id="video-drm"
              className="videoDrm"
              groupClassName="form-inline"
              disabled={!isDrmEditable(this.props.clip)}
              onChange={this.onDrmChange}
              checked={this.props.clip.drm}
            />
            <label>Ad Settings</label>
            <Input
              type="radio"
              label={`Use season default ${
                this.props.clip.seasonId
                  ? `(Season Default: ${this.isSeasonAdsEnabledByDefault() ? 'Enabled' : 'Disabled'})`
                  : ''
              }`}
              ref="clipAdsEnabledUseDefaultInput"
              onClick={() => this.onAdsEnabledChange(null)}
              checked={this.props.clip.adsEnabled === null}
            />
            <Input
              type="radio"
              label="Show advertising"
              ref="clipShowAdsEnabledInput"
              onClick={() => this.onAdsEnabledChange(true)}
              checked={this.props.clip.adsEnabled === true}
            />
            <Input
              type="radio"
              label="No advertising"
              ref="clipNoAdsEnabledInput"
              onClick={() => this.onAdsEnabledChange(false)}
              checked={this.props.clip.adsEnabled === false}
            />
          </Panel>
          <PublishingSchedule
            ref="publishingSchedule"
            publishStartDate={this.props.clip.availability}
            publishEndDate={this.props.clip.expiry}
            onChange={this.onPublishingScheduleChange}
            rightsRanges={[
              { amount: 3, type: 'months', text: '3 months' },
              { amount: 6, type: 'months', text: '6 months' },
              { amount: 1, type: 'years', text: '1 year' },
            ]}
            validationErrors={{
              publishStartDate: this.props.validationErrors.availability,
              publishEndDate: this.props.validationErrors.expiry,
            }}
          />
          <Panel header="Workflow" bsStyle="primary">
            <Input
              type="checkbox"
              label="Activate after Ingestion"
              ref="autoActivate"
              id="auto-activate"
              className="autoActivate"
              groupClassName="form-inline form__danger-input"
              onChange={this.onAutoActivateChange}
              checked={this.props.clip.autoActivate}
              disabled={!isAutoActivateEditable(this.props.clip)}
            />
          </Panel>
          <Panel header="Notes" bsStyle="primary">
            <Input
              id="producer-notes-input"
              type="textarea"
              label="Producer Notes"
              ref="producerNotes"
              onInput={(e) => this.onProducerNotesInput(e)}
              value={this.props.clip.producerNotes}
            />
          </Panel>
        </div>
        <div></div>
        {this.props.errorMessage && (
          <FormErrorMessage className="clip-server-error-message" message={this.props.errorMessage} />
        )}
        <div className="form__submit-button-bar">
          <Button
            id="video-modal-close"
            type="button"
            ref="closeButton"
            className="form__button"
            onClick={this.props.close}
          >
            Close
          </Button>
          {this.isSavingDisabled() ? (
            ''
          ) : (
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          )}
        </div>
      </form>
    );
  }
}

ClipForm.propTypes = {
  close: PropTypes.func,
  clip: PropTypes.object,
  seasons: PropTypes.array,
  episodes: PropTypes.array,
  episodeRelationships: PropTypes.array,
  getSeasons: PropTypes.func,
  getEpisodes: PropTypes.func,
  updateClip: PropTypes.func,
  errorMessage: PropTypes.string,
  imageUpload: PropTypes.object,
  saveClip: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  hideConfirmationModal: PropTypes.func,
  autoActivateConfirmationDisplayed: PropTypes.bool,
  isReschedule: PropTypes.bool, // This should be remove when we have proper video states and a save method should be passed
  rescheduleAndSaveClip: PropTypes.func,
  saving: PropTypes.bool,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
  multiImageUpload: PropTypes.object,
};

function mapStateToProps(state) {
  const clipForm = state.clipForm;
  return {
    clip: clipForm.clip,
    seasons: clipForm.seasons,
    episodes: clipForm.episodes,
    episodeRelationships: clipForm.episodeRelationships,
    errorMessage: clipForm.errorMessage,
    autoActivateConfirmationDisplayed: clipForm.autoActivateConfirmationDisplayed,
    saving: clipForm.saving,
    imageUpload: state.imageUploads[CLIP_IMAGE_REF],
    validationErrors: state.clipForm.validationErrors,
    multiImageUpload: state.multiImageUpload,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSeasons: (props) => dispatch(getSeasonsForForm(props)),
    getEpisodes: (props) => dispatch(getEpisodes(props)),
    updateClip: (clip) => dispatch(updateClip(clip)),
    saveClip: (clip) => dispatch(saveClip(clip)),
    showConfirmationModal: (...args) => dispatch(showConfirmationModal(...args)),
    hideConfirmationModal: () => dispatch(hideConfirmationModal()),
    rescheduleAndSaveClip: (...args) => dispatch(rescheduleAndSaveClip(...args)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClipForm);
