import * as adminApi from 'src/scripts/actions/apiWrappers/vmsApi/admin';
import * as tvVmsApi from './apiWrappers/vmsApi/tv';

import { closeModal } from 'src/scripts/actions/modalItem';

import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { getCdnNameList } from '../lib/cdnConfiguration';

export const GET_CHANNELS_SUCCEEDED = 'GET_CHANNELS_SUCCEEDED';
export const GET_CHANNELS_FAILED = 'GET_CHANNELS_FAILED';
export const TOGGLE_SSAI_FAILED = 'TOGGLE_SSAI_FAILED';
// Action to update channel after toggle
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const UPDATE_MARKET_CHANNEL = 'UPDATE_MARKET_CHANNEL';
export const TOGGLE_MARKET_EXPANDED = 'TOGGLE_MARKET_EXPANDED';
// Action to update UI while channel is been updated
export const UPDATING_CHANNEL_STYLING = 'UPDATING_CHANNEL_STYLING';
export const UPDATE_CHANNEL_STYLING_SUCCEEDED = 'UPDATE_CHANNEL_STYLING_SUCCEEDED';
export const UPDATE_CHANNEL_STYLING_FAILED = 'UPDATE_CHANNEL_STYLING_FAILED';
export const UPDATE_CHANNEL_STYLING_RESET = 'UPDATE_CHANNEL_STYLING_RESET';

export function masqueradeAsChannel(marketChannel) {
  return {
    /**
     *  - Set a field(isMarket) to indicate the current row is a Market;
     */
    isMarket: true,
    id: marketChannel.id,
    name: marketChannel.market.name,
    fullHdEnabled:
      typeof marketChannel.properties.fullHdEnabled === 'undefined' ||
      !!marketChannel.properties.fullHdEnabled,
    startOverEnabled:
      typeof marketChannel.properties.startOverEnabled === 'undefined' ||
      !!marketChannel.properties.startOverEnabled,
    fhdActiveStream: marketChannel.properties.fhdActiveStream,
    slug: marketChannel.slug,
    /**
     * cdn is added here to pass JSON SCHEMA validation used by Channels
     */
    cdn: {},
  };
}

export function expandMarkets(channels, marketExpanded) {
  return channels
    .filter((channel) => !channel.isMarket)
    .flatMap((channel) => {
      if (!marketExpanded[channel.slug]) {
        return [channel];
      }

      /**
       * Here the returned channels will have corresponding
       * markets expanded and sorted as extra entries in the array
       * so that the listing page will have all the markets
       * listed under each Channel row.
       */
      const markets = channel.marketChannels
        .map(masqueradeAsChannel)
        .sort((a, b) => a.name.localeCompare(b.name));

      return [channel, ...markets];
    });
}

export function getChannels(marketExpanded = {}) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CHANNELS_SUCCEEDED,
        data: {
          channels: expandMarkets(data.channels, marketExpanded),
        },
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_CHANNELS_FAILED,
        error: response.responseJSON ? response.responseJSON.message : 'error',
      });
    };
    return adminApi.getChannels({ withMarketChannels: true }).then(onSuccess, onError);
  };
}

export function toggleMarketExpanded(channel) {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MARKET_EXPANDED, data: channel });
  };
}

export function listChannels(params) {
  return adminApi.getChannels(params);
}

const updateChannelAndCloseModal = (dispatch, channel) => {
  dispatch({ type: UPDATE_CHANNEL, channel });
  closeModal()(dispatch);
};

export const closeChannelModal = () => {
  return (dispatch) => {
    closeModal()(dispatch);
    dispatch({ type: UPDATE_CHANNEL_STYLING_RESET });
  };
};

export const updateChannelStyling = (channelId, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATING_CHANNEL_STYLING });

    tvVmsApi.updateChannel(channelId, { ...data }).then(
      ({ channel }) => {
        dispatch({
          type: UPDATE_CHANNEL_STYLING_SUCCEEDED,
        });
        dispatch({ type: UPDATE_CHANNEL, channel });
        closeChannelModal()(dispatch);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Something went wrong. Please try again later';
        dispatch({ type: UPDATE_CHANNEL_STYLING_FAILED, error: errorMessage });
      }
    );
  };
};

const updateMarketChannelAndCloseModal = (dispatch, marketChannel) => {
  dispatch({ type: UPDATE_MARKET_CHANNEL, marketChannel });
  closeModal()(dispatch);
};

export function switchToPrimary(channelId, reason) {
  return (dispatch) => {
    adminApi.switchChannelToPrimary(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error switching to primary encoder';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function switchToBackup(channelId, reason) {
  return (dispatch) => {
    adminApi.switchChannelToBackup(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error switching to backup encoder';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableSsai(channelId, reason) {
  return (dispatch) => {
    adminApi.enableSsaiForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling ssai';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableSsai(channelId, reason) {
  return (dispatch) => {
    adminApi.disableSsaiForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling ssai';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableDrm(channelId, reason) {
  return (dispatch) => {
    adminApi.enableDrmForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling drm';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableDrm(channelId, reason) {
  return (dispatch) => {
    adminApi.disableDrmForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling drm';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableFullHd(channelId, reason) {
  return (dispatch) => {
    adminApi.enableFullHdForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling full hd';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableFullHdMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.enableFullHdForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling full hd';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableFullHd(channelId, reason) {
  return (dispatch) => {
    adminApi.disableFullHdForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling full hd';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableFullHdMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.disableFullHdForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling full hd';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableStartOver(channelId, reason) {
  return (dispatch) => {
    adminApi.enableStartOverForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling start over';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableStartOverMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.enableStartOverForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error enabling start over';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableStartOver(channelId, reason) {
  return (dispatch) => {
    adminApi.disableStartOverForChannel(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling start over';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableStartOverMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.disableStartOverForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON ? error.responseJSON.message : 'Error disabling start over';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function switchFhdToPrimaryMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.switchFhdToPrimaryForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error switching stream to Primary';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function switchFhdToBackupMarket(marketChannelId, reason) {
  return (dispatch) => {
    adminApi.switchFhdToBackupForMarketChannel(marketChannelId, reason).then(
      (res) => {
        updateMarketChannelAndCloseModal(dispatch, res.marketChannel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error switching stream to Backup';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

/**
 * @param channelId {number}
 * @param cdnName {string}
 * @param toggleOn {boolean}
 * @param reason {string}
 */
export function toggleCdn(channelId, cdnName, toggleOn, reason) {
  return (dispatch) => {
    if (!getCdnNameList().includes(cdnName)) {
      return dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: `CDN name(${cdnName}) is unsupported.`,
      });
    }

    return adminApi.toggleCdnForChannel(channelId, cdnName, toggleOn, reason).then(
      (res) => updateChannelAndCloseModal(dispatch, res.channel),
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : `Error ${toggleOn ? 'enabling' : 'disabling'} ${cdnName}`;
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

/**
 * @param channelId {number}
 * @param cdnName {string}
 * @param toggleOn {boolean}
 * @param reason {string}
 */
export function toggleCdnTokenisation(channelId, cdnName, toggleOn, reason) {
  return (dispatch) => {
    if (!getCdnNameList().includes(cdnName)) {
      return dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: `CDN Tokenisation for name(${cdnName}) is unsupported.`,
      });
    }

    return adminApi.toggleCdnTokenisationForChannel(channelId, cdnName, toggleOn, reason).then(
      (res) => updateChannelAndCloseModal(dispatch, res.channel),
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : `Error ${toggleOn ? 'enabling' : 'disabling'} ${cdnName} Tokenisation`;
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function enableManifestFiltering(channelId, reason) {
  return (dispatch) => {
    adminApi.enableManifestFiltering(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error enabling Manifest Filtering';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}

export function disableManifestFiltering(channelId, reason) {
  return (dispatch) => {
    adminApi.disableManifestFiltering(channelId, reason).then(
      (res) => {
        updateChannelAndCloseModal(dispatch, res.channel);
      },
      (error) => {
        const errorMessage = error.responseJSON
          ? error.responseJSON.message
          : 'Error disabling Manifest Filtering';
        dispatch({
          type: CONFIRMATION_ACTION_FAILED,
          error: errorMessage,
        });
      }
    );
  };
}
