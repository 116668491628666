import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { MenuItem, DropdownButton } from 'react-bootstrap';
import history from 'src/scripts/lib/history';
import * as browser from 'src/scripts/lib/browser';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class SeasonMediaItemNavigation extends React.Component {
  constructor() {
    super();
    this.transitionToMediaItemPage = this.transitionToMediaItemPage.bind(this);
  }

  transitionToMediaItemPage(seasonVideoType) {
    browser.setItem('seasonVideoType', seasonVideoType);
    const tvSeriesId = Number(this.props.tvSeriesId);
    const seasonId = Number(this.props.seasonId);
    history.push({
      state: null,
      pathname: `/${TV_PATH}/tv-series/${tvSeriesId}/seasons/${seasonId}/${seasonVideoType}`,
      query: { page: 1 },
    });
  }

  render() {
    const navigationList = [
      { modelName: 'episodes', name: 'Episodes' },

      { modelName: 'clips', name: 'Clips' },
    ];
    const menuTitle = _.find(navigationList, { modelName: this.props.currentVideoType });
    const breadcrumbs = [
      <a href={`/#/${TV_PATH}/tv-series`}>TV Series</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.tvSeriesId}`}>{this.props.tvSeriesName}</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.tvSeriesId}/seasons`}>Seasons</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.tvSeriesId}/seasons/${this.props.seasonId}`}>
        {this.props.seasonName}
      </a>,
      <DropdownButton
        className="mi9-breadcrumb__dropdown-button"
        bsStyle="link"
        title={menuTitle.name}
        activeKey={this.props.currentVideoType}
        id="list-menu"
      >
        {navigationList.map((navItem, key) => {
          return (
            <MenuItem
              key={key}
              eventKey={navItem.modelName}
              onSelect={this.transitionToMediaItemPage.bind(null, navItem.modelName)}
            >
              {navItem.name}
            </MenuItem>
          );
        })}
      </DropdownButton>,
    ];

    return <ContextNavigation breadcrumbList={breadcrumbs} />;
  }
}

SeasonMediaItemNavigation.propTypes = {
  currentVideoType: PropTypes.string,
  tvSeriesId: PropTypes.string,
  tvSeriesName: PropTypes.string,
  seasonId: PropTypes.string,
  seasonName: PropTypes.string,
};

export default SeasonMediaItemNavigation;
