import React from 'react';
import { Input } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Dropdown = ({ items, isOptionValueNull, label, onChange, currentValue, ...rest }) => (
  <Input type="select" onChange={onChange} value={currentValue} {...rest}>
    {isOptionValueNull && <option value="">{`Select a ${label}`}</option>}
    {items.map(({ code, name }) => {
      return (
        <option key={code} value={code}>
          {code.toUpperCase()} - {name}
        </option>
      );
    })}
  </Input>
);

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isOptionValueNull: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  currentValue: PropTypes.any,
};

export default Dropdown;
