import { required, atLeastOneNonSpace } from 'src/scripts/lib/formValidation/generalValidators';

export const requiredDropdownSelect = () => (rule, formState) => {
  const dropdownSelection = formState[rule.field];
  if (!dropdownSelection || dropdownSelection === 'unselected') {
    return `${rule.label} is a required field.`;
  }
  return null;
};

export const requiredDropdownSelectIfGenreCollection = () => (rule, formState) => {
  const collectionType = formState.type;
  if (collectionType === 'GENRE') {
    return requiredDropdownSelect()(rule, formState);
  }
  return null;
};

const validationRules = [
  { field: 'name', label: 'Collection Name', customValidators: [required(), atLeastOneNonSpace()] },
  { field: 'type', label: 'Collection Type', customValidators: [requiredDropdownSelect()] },
  {
    field: 'genreId',
    label: 'Collection Genre',
    customValidators: [requiredDropdownSelectIfGenreCollection()],
  },
];

const getValidationRules = () => validationRules;

export default getValidationRules;
