import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';

export class FileDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      enterCounter: 0,
      dropPromptModalOpen: false,
      files: [],
    };
  }

  onDragEnter(e) {
    e.preventDefault();
    this.setState({
      enterCounter: this.state.enterCounter + 1,
    });
  }

  onDragLeave(e) {
    e.preventDefault();
    this.setState({
      enterCounter: this.state.enterCounter - 1,
    });
  }

  onDragOver(e) {
    e.preventDefault();
  }

  onDrop(e) {
    e.preventDefault();
    this.setState({
      enterCounter: 0,
    });
    const validFiles = Array.from(e.dataTransfer.files).filter((file) => {
      return !this.props.fileTypePrefix || _.startsWith(file.type, this.props.fileTypePrefix);
    });
    if (this.props.dropPromptForm) {
      this.setState({ files: validFiles, dropPromptModalOpen: true });
    } else {
      validFiles.forEach((file) => this.props.onDrop(file));
    }
    return false;
  }

  onDropPromptModalConfirm = (confirmArgs) => {
    this.state.files.forEach((file) => this.props.onDrop(file, confirmArgs));
    this.setState({ files: [], dropPromptModalOpen: false });
  };

  onDropPromptModalClose = () => {
    this.setState({ files: [], dropPromptModalOpen: false });
  };

  render() {
    const DropPromptForm = this.props.dropPromptForm;
    return (
      <div
        className={this.state.enterCounter > 0 ? 'file-drop-area-dragging' : ''}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        ref="fileDropArea"
      >
        {this.props.children}
        <div className="file-drop-base" onDragEnter={this.onDragEnter} onDrop={this.onDrop}></div>
        <div className="file-drop-invite">
          <i className="fa fa-arrow-up file-drop-invite-arrow"></i>
          <div className="file-drop-message">Drop {this.props.fileTypePrefix} files here</div>
        </div>
        <div className="file-drop-overlay"></div>
        <div className="file-drop-overlay-border"></div>
        {DropPromptForm ? (
          <Modal show={this.state.dropPromptModalOpen} onHide={this.onDropPromptModalClose} backdrop="static">
            <DropPromptForm onConfirm={this.onDropPromptModalConfirm} onClose={this.onDropPromptModalClose} />
          </Modal>
        ) : null}
      </div>
    );
  }
}

FileDropZone.propTypes = {
  onDrop: PropTypes.func,
  dropPromptForm: PropTypes.node,
  fileTypePrefix: PropTypes.string,
  children: PropTypes.node,
};

export default FileDropZone;
