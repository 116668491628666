import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import LoadingIcon from 'src/scripts/components/LoadingIcon';
import { DEBOUNCE_TIME } from '../constants/common';

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: this.props.suggestions ? this.props.suggestions : [],
    };
    this.onInputChanged = this.onInputChanged.bind(this);
    this.onSuggestionClicked = this.onSuggestionClicked.bind(this);
    this.loadSuggestions = _.debounce(this.props.loadSuggestions, DEBOUNCE_TIME);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      suggestions: nextProps.suggestions ? nextProps.suggestions : [],
    });
  }

  onInputChanged(event) {
    if (!event.target) {
      return;
    }
    const newValue = event.target.value;
    const input = newValue.trim();
    if (this.props.validateInput && !this.props.validateInput(input)) {
      return;
    }

    if (!input.length || input.length >= 2) {
      this.loadSuggestions(newValue);
    }
  }

  onSuggestionClicked(event) {
    const newValue = event.target.firstChild.textContent;
    this.props.loadSuggestions('');
    this.props.onSuggestionSelected(newValue);
  }

  renderSuggestions() {
    if (!this.state.suggestions.length) {
      return null;
    }
    return (
      <ul id="suggestList" className="autocomplete__suggestions" ref="suggestions" role="listbox">
        {this.renderSuggestionsList(this.state.suggestions)}
      </ul>
    );
  }

  renderCount(count) {
    return <span className="pull-right suggestion-count">{count}</span>;
  }

  renderSuggestionsList(suggestions) {
    return suggestions.map((suggestion, suggestionIndex) => {
      const suggestionRef = `suggestion-${suggestionIndex}`;
      return (
        <li
          id={suggestionIndex}
          className="autocomplete__suggestion"
          role="option"
          ref={suggestionRef}
          key={suggestionRef}
          onClick={this.onSuggestionClicked}
        >
          <span>{suggestion.value}</span>
          {suggestion.count && this.renderCount(suggestion.count)}
        </li>
      );
    });
  }

  render() {
    return (
      <div>
        <input
          {...this.props.inputAttributes}
          type="text"
          className="autocomplete__input"
          autoComplete="off"
          role="combobox"
          ref="input"
          onChange={this.onInputChanged}
        ></input>
        <LoadingIcon loading={this.props.loading} />
        {this.renderSuggestions()}
      </div>
    );
  }
}

Autocomplete.propTypes = {
  inputAttributes: PropTypes.object,
  suggestions: PropTypes.array,
  loading: PropTypes.bool,
  onSuggestionSelected: PropTypes.func,
  loadSuggestions: PropTypes.func,
  validateInput: PropTypes.func,
};

export default Autocomplete;
