import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatText } from 'src/scripts/components/Season/DetailsView/Formatters';

const AdConfigurationPanel = ({ season }) => {
  return (
    <Panel header="Ad Configuration">
      <DetailsRowItem id="gender-skew" label="Gender Skew">
        <FormatText>{season.genderSkew}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem id="ad-content-group" label="Ad Content Group">
        <FormatText>{season.contentGroup}</FormatText>
      </DetailsRowItem>
      <DetailsRowItem id="available-content-series" label="Available Content Series for Clips">
        <FormatText>{season.availableContentSeries && season.availableContentSeries.join(', ')}</FormatText>
      </DetailsRowItem>
    </Panel>
  );
};

AdConfigurationPanel.propTypes = {
  season: PropTypes.object,
};

export default AdConfigurationPanel;
