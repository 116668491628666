const getUploadStatusByVideoId = (videoId, uploadMetaData) => {
  if (uploadMetaData) {
    const videoSpecificUploadMetaData = uploadMetaData[videoId];
    if (videoSpecificUploadMetaData) {
      return videoSpecificUploadMetaData.overriddenStatus;
    }
  }
  return null;
};

export const videoStatusDataFormatHandler = (videoItem, uploadMetaData) => {
  if (videoItem.video === null || !videoItem.video) return 'No Video';
  const videoUploadStatus = getUploadStatusByVideoId(videoItem.video.id, uploadMetaData);
  if (videoUploadStatus) {
    return videoUploadStatus;
  }
  return videoItem.video.status || 'No Video';
};
