const DEBOUNCE_TIME = 500;

const DASH_FOR_EMPTY_CONTENT = '---';

const IMAGE_NOT_FOUND_URL = '/images/not-found.png';

const LABELS = {
  RECOMMENDED_COLLECTION: 'Recommended Collection',
};

export { DEBOUNCE_TIME, LABELS, DASH_FOR_EMPTY_CONTENT, IMAGE_NOT_FOUND_URL };
