import { combineReducers } from 'redux';

import navigation from './navigation';
import screen from './screen';
import caption from './caption';

const rootUIReducer = combineReducers({
  navigation,
  screen,
  caption,
});

export default rootUIReducer;
