import {
  required,
  dateFormat,
  isAfter,
  notBeforeToday,
} from 'src/scripts/lib/formValidation/generalValidators';

export const isAfterStartDate = () =>
  isAfter({ field: 'availability', label: 'Start Date & Time' }, ['expiry']);

export const mustSelectDropdownOption = () => (rule, formState) =>
  formState[rule.field] === 0 ? `${rule.label} requires a selection` : null;

const getValidationRules = (state) => {
  return [
    { field: 'tvSeriesId', label: 'TV Series', customValidators: [required(), mustSelectDropdownOption()] },
    { field: 'seasonId', label: 'Season', customValidators: [required(), mustSelectDropdownOption()] },
    { field: 'name', label: 'Name', customValidators: required() },
    { field: 'availability', label: 'Start Date & Time', customValidators: [required(), dateFormat()] },
    {
      field: 'expiry',
      label: 'End Date & Time',
      customValidators: [
        dateFormat(),
        isAfterStartDate(),
        // We don't want to force the user to enter a new end date when updating an archived record.
        state !== 'ARCHIVED' ? notBeforeToday(['expiry']) : null,
      ].filter((x) => x !== null),
    },
  ];
};

export default getValidationRules;
