import { addNotification as notify } from 'reapop';
import history from 'src/scripts/lib/history';
import { TV_PATH, NETWORK_PATH } from 'src/scripts/lib/accessController';

const defaultErrorOptions = {
  title: 'Error',
  status: 'error',
  position: 'br',
  buttons: [{ name: 'Close' }],
};

export function errorNotification(options) {
  if (!options.message) {
    throw new Error("Missing required option: 'message'");
  }
  return notify({
    ...defaultErrorOptions,
    ...options,
  });
}

export function workflowErrorNotification(message, actionType, modelName, itemId) {
  const modelNameMap = {
    clip: 'Clip',
    episode: 'Episode',
    networkClip: 'Clip',
    ingestJob: 'Ingest Job',
  };

  const errorType = {
    delete: 'Delete Error',
    archive: 'Archive Error',
    transcode: 'Transcoding Error',
    expire: 'Expire Error',
  };

  const onClickPath = {
    clip: `/${TV_PATH}/tv-series/id/seasons/id/clips/${itemId}`,
    episode: `/${TV_PATH}/tv-series/id/seasons/id/episodes/${itemId}`,
    networkClip: `/${NETWORK_PATH}/categories/id/subcategories/id/clips/${itemId}`,
    ingestJob: `/${TV_PATH}/videos/ingestJobs/${itemId}`,
  };

  const viewButton = {
    name: 'View',
    primary: true,
    onClick: (event) => {
      event.stopPropagation();
      history.push({ state: null, pathname: onClickPath[modelName] });
    },
  };

  const closeButton = {
    name: 'Close',
    primary: false,
  };

  return errorNotification({
    title: `${modelNameMap[modelName]} - ${errorType[actionType]}`,
    message,
    buttons: [viewButton, closeButton],
  });
}
