import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Row, Col, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import environment from 'config/environment';
import ViewLoader from 'src/scripts/components/ViewLoader';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { isDefaultImage } from 'src/scripts/lib/util';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { getTvSeriesAndAssociatedData } from 'src/scripts/actions/tvSeries';
import history from 'src/scripts/lib/history';
import { TV_PATH } from 'src/scripts/lib/accessController';
import TVSeriesActions from './TVSeriesActions';
import * as dateFormatter from 'src/scripts/lib/dateFormatter';
import { DASH_FOR_EMPTY_CONTENT, IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import { getContentOrDashes } from 'src/scripts/lib/stringFormatter';
import PreviewCollectionItems from './internal/PreviewCollectionItems';

const halfWidthColumn = 6;
const fullWidthColumn = 12;

export class TVSeriesDetailsView extends React.Component {
  constructor() {
    super();
    this.state = {
      previewCollection: false,
    };
  }

  componentDidMount() {
    const tvSeriesId = this.props.params.id;
    this.props.getTVSeries(tvSeriesId);
  }

  customCollectionsExist = () => {
    return this.props.customCollections && this.props.customCollections.length > 0;
  };

  getImageUrl = () => {
    const tvSeriesLatestSeason = this.props.tvSeries.containsSeason[0];
    if (tvSeriesLatestSeason && tvSeriesLatestSeason.image) {
      return isDefaultImage(tvSeriesLatestSeason.image)
        ? IMAGE_NOT_FOUND_URL
        : tvSeriesLatestSeason.image.url;
    }
    return IMAGE_NOT_FOUND_URL;
  };

  getSecondaryGenres = () => {
    if (this.props.tvSeries.secondaryGenres.length) {
      const secondaryGenres = this.props.tvSeries.secondaryGenres.map(
        (secondaryGenre) => secondaryGenre.name
      );
      return secondaryGenres.join(', ');
    }
    return DASH_FOR_EMPTY_CONTENT;
  };

  getTags = () => {
    if (this.props.tvSeries.tags.length) {
      const tags = this.props.tvSeries.tags.map((tag) => {
        return tag.name ? tag.name : tag;
      });
      return tags.join(', ');
    }
    return DASH_FOR_EMPTY_CONTENT;
  };

  getCountryOfOrigin = () => {
    return this.props.tvSeries.countryOfOrigin || DASH_FOR_EMPTY_CONTENT;
  };

  getRecommendedCollectionName = () => {
    const recommendedCollectionId = this.props.tvSeries.recommendedCollectionId;
    if (this.customCollectionsExist()) {
      if (recommendedCollectionId || recommendedCollectionId === 0) {
        const recommendedCollection = this.props.customCollections.find(
          (collection) => collection.id === recommendedCollectionId
        );
        return recommendedCollection.name;
      }
    }
    return DASH_FOR_EMPTY_CONTENT;
  };

  getProductionCompany = () => {
    return this.props.tvSeries.productionCompany
      ? this.props.tvSeries.productionCompany
      : DASH_FOR_EMPTY_CONTENT;
  };

  getContentFlag = () => {
    return this.props.tvSeries.contentFlag ? this.props.tvSeries.contentFlag : DASH_FOR_EMPTY_CONTENT;
  };

  getDateCreated = () => {
    return dateFormatter.format(this.props.tvSeries.createdAt);
  };

  getCustomCollectionsUsingTvSeries = () => {
    const customCollectionsUsingTheTvSeries = [];
    if (this.customCollectionsExist()) {
      this.props.customCollections.forEach((collection) => {
        const tvSeries = collection.tvSeries
          ? collection.tvSeries.find((collectionTvSeries) => collectionTvSeries.id === this.props.tvSeries.id)
          : null;
        if (tvSeries) {
          customCollectionsUsingTheTvSeries.push(collection);
        }
      });
    }
    return customCollectionsUsingTheTvSeries;
  };

  renderRecommendedCollectionName = () => {
    if (this.props.tvSeries.recommendedCollectionId) {
      return (
        <a href={`#/tv/collections/${this.props.tvSeries.recommendedCollectionId}`}>
          {this.getRecommendedCollectionName()}
        </a>
      );
    }
    return this.getRecommendedCollectionName();
  };

  handleClick = () => {
    this.setState({ previewCollection: true });
  };

  renderMetadataPanel = () => {
    return (
      <Panel header="Metadata" collapsible defaultExpanded>
        <DetailsRowItem id="tv-series-name" label="Name">
          {this.props.tvSeries.name}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-description" label="Description">
          {getContentOrDashes(this.props.tvSeries.description)}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-marketing-description" label="Marketing Description">
          {this.props.tvSeries.marketingDescription
            ? this.props.tvSeries.marketingDescription.split('\n').map((line, i) => (
                <div key={i}>
                  {' '}
                  {line} <br />{' '}
                </div>
              ))
            : DASH_FOR_EMPTY_CONTENT}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-tags" label="Tags">
          {this.getTags()}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-genre" label="Genre">
          {this.props.tvSeries.genre.name}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-secondary-genres" label="Secondary Genres">
          {this.getSecondaryGenres()}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-country-of-origin" label="Country Of Origin">
          {this.getCountryOfOrigin()}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-recommended-collection" label="Recommended Collection">
          {this.renderRecommendedCollectionName()}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-content-segment" label="Content Segment">
          {this.props.tvSeries.contentSegment}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-production-company" label="Production Company">
          {this.getProductionCompany()}
        </DetailsRowItem>
        {environment.featureFlags.nineVideoFeaturesEnabled ? (
          <DetailsRowItem id="tv-series-content-flag" label="Content Flag">
            {this.getContentFlag()}
          </DetailsRowItem>
        ) : null}
        <DetailsRowItem id="tv-series-show-site-link" label="Showsite Link">
          {this.props.tvSeries.showSiteLink ? (
            <a href={this.props.tvSeries.showSiteLink} target="_blank" rel="noopener noreferrer">
              {this.props.tvSeries.showSiteLink}
            </a>
          ) : (
            DASH_FOR_EMPTY_CONTENT
          )}
        </DetailsRowItem>
        <DetailsRowItem id="tv-series-date-created" label="Date Created">
          {this.getDateCreated()}
        </DetailsRowItem>
      </Panel>
    );
  };

  renderSeasonsPanel = () => {
    return (
      <Panel id="seasons-panel" header="Seasons" collapsible defaultExpanded>
        <DetailsRowItem id="tv-series-number-of-seasons" label="Number of Seasons">
          {<span id="tv-series-seasons-length">{`${this.props.tvSeries.containsSeason.length} `}</span>}
          {
            <a id="tv-series-view-seasons" href={`#/${TV_PATH}/tv-series/${this.props.params.id}/seasons`}>
              (View Seasons)
            </a>
          }
        </DetailsRowItem>
      </Panel>
    );
  };

  renderCollectionsPanel = () => {
    const customCollectionsUsingTvSeries = this.getCustomCollectionsUsingTvSeries();
    return customCollectionsUsingTvSeries.length > 0 ? (
      <Panel id="recommended-collection-use-panel" header="Recommended Collection Use">
        <ListGroup>
          {customCollectionsUsingTvSeries.map((collection) => {
            return (
              <div className="recommended-collection-use-list-wrapper">
                <ListGroupItem className="collections-using-tv-series-list-item">
                  {collection.name}
                </ListGroupItem>
              </div>
            );
          })}
        </ListGroup>
      </Panel>
    ) : (
      <Panel id="recommended-collection-use-panel" header="Recommended Collection Use">
        <span className="no-collections-using-tv-series-text">No Collections using this TV Series</span>
      </Panel>
    );
  };

  renderNotesPanel = () => {
    return (
      <Panel id="notes-panel" header="Notes" collapsible defaultExpanded>
        <DetailsRowItem id="producer-notes" label="Producer Notes">
          {getContentOrDashes(this.props.tvSeries.producerNotes)}
        </DetailsRowItem>
      </Panel>
    );
  };

  renderLatestSeasonImage = () => {
    return (
      <div className="details-row">
        <img id="tv-series-display-image" className="details-image" src={this.getImageUrl()} />
        <span className="details-image-caption">Latest Season Image</span>
      </div>
    );
  };

  renderCollectionsPreview = () => {
    return (
      <Col md={fullWidthColumn}>
        <Panel header="Collection Preview" collapsible defaultExpanded>
          <PreviewCollectionItems
            collectionId={this.props.tvSeries.recommendedCollectionId}
            previewMode={'large'}
          />
        </Panel>
      </Col>
    );
  };

  renderTVSeriesDetailsView = () => {
    if (this.props.tvSeries && this.props.tvSeries.genre) {
      return [
        <ContextNavigation
          breadcrumbList={[<a href="/#/tv/tv-series">TV Series</a>, this.props.tvSeries.name]}
        />,
        <Row className="details-toolbar">
          <TVSeriesActions
            displayType="button-bar"
            tvSeries={this.props.tvSeries}
            afterDeletion={() => {
              history.push({ state: null, pathname: `/${TV_PATH}/tv-series` }, { query: { page: 1 } });
            }}
          />
        </Row>,
        <Row>
          <Col md={halfWidthColumn}>
            {this.renderMetadataPanel()}
            {this.renderSeasonsPanel()}
            {this.renderCollectionsPanel()}
            {this.renderNotesPanel()}
          </Col>
          <Col md={halfWidthColumn}>{this.renderLatestSeasonImage()}</Col>
          {this.props.tvSeries.recommendedCollectionId && (
            <Col md={fullWidthColumn}>
              <Button className="collection-preview-button" bsStyle="primary" onClick={this.handleClick}>
                Preview Collection
              </Button>
            </Col>
          )}
          {this.state.previewCollection && this.renderCollectionsPreview()}
        </Row>,
      ];
    }
    return null;
  };

  render() {
    return (
      <ViewLoader loading={!this.props.tvSeries} error={this.props.error}>
        {this.renderTVSeriesDetailsView()}
      </ViewLoader>
    );
  }
}

TVSeriesDetailsView.propTypes = {
  params: PropTypes.object,
  tvSeries: PropTypes.object,
  error: PropTypes.object,
  getTVSeries: PropTypes.function,
  customCollections: PropTypes.array,
};

const mapStateToProps = (state) => ({
  tvSeries: state.tvSeriesDetailsView.tvSeries,
  customCollections: state.tvSeriesDetailsView.customCollections,
  error: state.tvSeriesDetailsView.error,
});

const mapDispatchToProps = (dispatch) => ({
  getTVSeries: (tvSeriesId) => dispatch(getTvSeriesAndAssociatedData(tvSeriesId, false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TVSeriesDetailsView);
