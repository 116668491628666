import * as authService from 'src/scripts/actions/apiWrappers/authService';
import * as userVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/user';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOAD_USER_SUCCESSFUL = 'LOAD_USER_SUCCESSFUL';
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED';

export function loadUser(callback) {
  return (dispatch) => {
    return userVmsApi
      .getLoggedInUser()
      .then((loggedInUser) => {
        dispatch({
          type: LOAD_USER_SUCCESSFUL,
          loggedInUser,
        });
        callback();
      })
      .fail((err) => {
        dispatch({
          type: LOAD_USER_FAILED,
          reason: err,
        });
        callback(err);
      });
  };
}

export function login(callback) {
  return (dispatch) => {
    const accessToken = authService.getCachedToken();
    if (accessToken) {
      dispatch({
        type: LOGIN_SUCCESSFUL,
        accessToken,
      });
      return loadUser(callback)(dispatch);
    }
    return callback();
  };
}

export function loginToAzure() {
  authService.loginToAzure();
}

export function handleAzureCallback() {
  return authService.handleAzureCallback();
}
