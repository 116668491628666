import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import DetailsRowItem from 'src/scripts/components/MediaItem/DetailsRowItem';
import { FormatBoolean } from 'src/scripts/components/Season/DetailsView/Formatters';

const WatermarkPanel = ({ season }) => {
  return (
    <Panel header="Watermark">
      <DetailsRowItem label="Enable Watermark on Episodes">
        <FormatBoolean>{season.episodesWatermarkEnabled}</FormatBoolean>
      </DetailsRowItem>
      <DetailsRowItem label="Enable Watermark on Clips">
        <FormatBoolean>{season.clipsWatermarkEnabled}</FormatBoolean>
      </DetailsRowItem>
    </Panel>
  );
};

WatermarkPanel.propTypes = {
  season: PropTypes.object,
};

export default WatermarkPanel;
