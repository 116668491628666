import moment from 'moment';
import 'moment-duration-format';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import VideoAutoQc, { mergeCompatibleDurations } from '../../lib/videoAutoQc';

function formatTimestamp(timestamp) {
  return moment.duration(timestamp, 'seconds').format('hh:mm:ss', { trim: false });
}

class VideoAutoQcNavigator extends Component {
  constructor(props) {
    super(props);
    this.videoAutoQc = new VideoAutoQc(props.videoAutoQc);
    this.rows = mergeCompatibleDurations(this.videoAutoQc.getSilents(), this.videoAutoQc.getBlacks());
  }

  shouldRender() {
    return this.videoAutoQc.isFailed() && this.rows.length > 0;
  }

  /**
   * @param row{Duration|AutoQcMerged}
   * @param index{number}
   */
  renderRow(row, index) {
    return (
      <tr key={index} className={'video-auto-qc-list-row'}>
        <td className={'table-value'}>
          <button className={'btn btn-primary'} onClick={() => this.props.setVideoTime(row.getStart(true))}>
            {formatTimestamp(row.getStart())}
          </button>
        </td>
        <td className={'table-value'}>
          <button className={'btn btn-primary'} onClick={() => this.props.setVideoTime(row.getEnd(true))}>
            {formatTimestamp(row.getEnd())}
          </button>
        </td>
        <td className={'table-value'}>{formatTimestamp(row.getDuration())}</td>
        <td className={'table-value video-auto-qc-fail-reason'}>{`No ${row.getLabel()} detected`}</td>
      </tr>
    );
  }

  render() {
    if (!this.shouldRender()) {
      return null;
    }

    return (
      <div className={'details-row row'}>
        <div className={'details-key row'}>{'Auto QC Navigator'}</div>
        <div className={'details-value row'}>
          <Table bordered condensed className={'details-table video-auto-qc-navigation-table'}>
            <tbody>
              <tr className={'details-table-label-column'}>
                <th className={'time-value-header'}>{'Start Point'}</th>
                <th className={'time-value-header'}>{'End Point'}</th>
                <th className={'time-value-header'}>{'Duration'}</th>
                <th className={'time-value-header'}>{'Reason'}</th>
              </tr>
              {this.rows.map((row, index) => this.renderRow(row, index))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

VideoAutoQcNavigator.propTypes = {
  videoAutoQc: PropTypes.object,
  setVideoTime: PropTypes.func,
};

export default VideoAutoQcNavigator;
