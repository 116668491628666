import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import CategoryForm from 'src/scripts/components/Category/internal/CategoryForm';
import { getCategoryById } from 'src/scripts/actions/category';

export class CategoryFormLoader extends React.Component {
  componentDidMount() {
    if (this.props.categoryId) {
      this.props.getCategoryById(this.props.categoryId);
    }
  }

  render() {
    return (
      <ViewLoader loading={this.props.categoryId && !this.props.category.id}>
        <CategoryForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

CategoryFormLoader.propTypes = {
  categoryId: PropTypes.number,
  category: PropTypes.object,
  getCategoryById: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const categoryForm = state.categoryForm;
  return {
    category: categoryForm.category,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCategoryById: (categoryId) => dispatch(getCategoryById(categoryId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFormLoader);
