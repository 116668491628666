import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

import { formatDateStringToISOStringOrNull, composeDateFilterString } from 'src/scripts/lib/dateFormatter';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import DateTimePicker from 'src/scripts/components/DateTimePicker';

class Date extends React.Component {
  constructor(props) {
    super(props);
    this.state = { from: '', to: '' };
    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.revision !== nextProps.revision) {
      this.onClear();
    }
  }

  onSearch() {
    const composedDateFilterString = composeDateFilterString(this.state.from, this.state.to);
    this.props.applyFilter(composedDateFilterString);
  }

  onClear() {
    this.setState({ from: '', to: '' }, () => this.props.applyFilter(null));
  }

  onFromChange(date) {
    const preprocessedDate = formatDateStringToISOStringOrNull(date);
    this.setState({ from: preprocessedDate || date });
  }

  onToChange(date) {
    const preprocessedDate = formatDateStringToISOStringOrNull(date);
    this.setState({ to: preprocessedDate || date });
  }

  render() {
    return (
      <NavDropdownMenu
        className="date-dropdown"
        buttonClass="btn btn-default btn-sm"
        eventKey={1}
        bsStyle="info"
        component="span"
        iconClass={this.props.isActiveDateFilter ? 'fa-calendar-check-o icon-dropdown__active' : 'fa-filter'}
        classToExcludeFromClose="rdt"
        alignLeft={this.props.alignLeft}
      >
        <div className="form-group">
          <DateTimePicker
            className="date-time-picker__from-date"
            ref="fromDate"
            onChange={this.onFromChange}
            placeholder="From date"
            value={this.state.from}
          />
        </div>
        <div className="form-group">
          <DateTimePicker
            ref="toDate"
            onChange={this.onToChange}
            placeholder="To date"
            value={this.state.to}
          />
        </div>
        <div className="form__submit-button-bar">
          <Button
            type="button"
            ref="clearButton"
            className="form__button form__button-clear"
            bsStyle="default"
            onClick={this.onClear}
          >
            Clear
          </Button>
          <Button
            type="button"
            ref="searchButton"
            className="form__button form__button-search"
            bsStyle="primary"
            onClick={this.onSearch}
          >
            Search
          </Button>
        </div>
      </NavDropdownMenu>
    );
  }
}

Date.propTypes = {
  revision: PropTypes.number,
  applyFilter: PropTypes.func,
  alignLeft: PropTypes.bool,
  isActiveDateFilter: PropTypes.bool,
};

export default Date;
