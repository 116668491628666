import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { TV_PATH } from 'src/scripts/lib/accessController';

class TVSeriesSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tvSeries: this.props.initialTvSeries,
    };
    this.onTVSeriesChange = this.onTVSeriesChange.bind(this);
    this.loadTvSeries = this.loadTvSeries.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (!this.props.initialTvSeries) return;
    if (!oldProps.initialTvSeries || oldProps.initialTvSeries.id !== this.props.initialTvSeries.id) {
      this.setState({ tvSeries: this.props.initialTvSeries }); // eslint-disable-line
    }
  }

  onTVSeriesChange(tvSeries) {
    const tvSeriesId = tvSeries ? parseInt(tvSeries.id, 10) : null;
    this.setState({
      tvSeries,
    });
    this.props.onTVSeriesChange(tvSeriesId, tvSeries);
  }

  clearTvSeries = () => {
    return new Promise((resolve) => {
      this.setState(() => ({ tvSeries: null }), resolve);
    });
  };

  loadTvSeries(input) {
    const param = !!input ? { name: `like:${input}` } : {};
    if (this.props.includeActiveEpisodeStates) {
      param.includeActiveEpisodeStates = true;
    }
    return tvVmsApi.getTVSeries(param).then((result) => {
      return { options: result.tvSeries };
    });
  }

  renderLabel = () => (
    <label
      ref="tvSeriesSelectHeaderLabel"
      className={`control-label${this.props.required ? ' required' : ''}`}
    >
      TV Series
    </label>
  );

  renderTVSeriesViewLink = () => {
    return this.state.tvSeries && this.state.tvSeries.id ? (
      <a
        ref="tvSeriesSelectHeaderLink"
        href={`/#/${TV_PATH}/tv-series/${this.state.tvSeries.id}/seasons`}
        target="_blank"
        className="hyperlink"
      >
        Go to TV Series View
      </a>
    ) : null;
  };

  render() {
    return (
      <div
        className={`tv-series-select form-group ${this.props.hasValidationErrors ? 'error' : ''}`}
        data-pw="select-tv-series"
      >
        {!this.props.hideHeader && this.renderLabel()}
        {!this.props.hideHeader && this.renderTVSeriesViewLink()}
        <Select.Async
          autoload={false}
          cache={false}
          multi={false}
          name="tvSeries-field-name"
          placeholder="Type to search TV Series"
          noResultsText="No TV Series found"
          deleteRemoves
          backspaceRemoves={false}
          value={this.state.tvSeries}
          loadOptions={this.loadTvSeries}
          onChange={this.onTVSeriesChange}
          ref="selectTVSeries"
          id="select-tvSeries"
          valueKey="id"
          labelKey="name"
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

TVSeriesSelect.propTypes = {
  disabled: PropTypes.bool,
  initialTvSeries: PropTypes.object,
  onTVSeriesChange: PropTypes.func,
  hasValidationErrors: PropTypes.bool,
  required: PropTypes.bool,
  hideHeader: PropTypes.bool,
  includeActiveEpisodeStates: PropTypes.bool,
};

export const defaultProps = {
  hasValidationErrors: false,
  required: true,
  hideHeader: false,
  includeActiveEpisodeStates: false,
};

TVSeriesSelect.defaultProps = defaultProps;

export default TVSeriesSelect;
