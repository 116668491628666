import PropTypes from 'prop-types';
import React from 'react';
import ConfirmableActionButton from 'src/scripts/components/ConfirmableActionButton';
import { isBrightcoveVideoCloudRetranscodeAvailable } from 'src/scripts/lib/bulkWorkflowActionsProvider';
import { IN_PROGRESS } from 'src/scripts/reducers/bulkActions';
import { EPISODE, CLIP, NETWORK_CLIP } from 'src/scripts/lib/modelNames';

export class Retranscode extends React.Component {
  formatConfirmationString(actionName, modelName, selectedCount) {
    if (this.props.bulkActionStatus === IN_PROGRESS) {
      return 'There is currently a bulk action in progress. Please wait until the current action completes before starting a new one.';
    }
    const mapModelNameToString = {
      [CLIP]: 'clip',
      [EPISODE]: 'episode',
      [NETWORK_CLIP]: 'clip',
    };
    const pluralisedModelName = `${mapModelNameToString[modelName]}${selectedCount > 1 ? 's' : ''}`;
    return `You are about to ${actionName} ${selectedCount} ${pluralisedModelName}. Are you sure you want to continue?`;
  }

  isDisabled() {
    return (
      this.props.selectedItems.length < 1 ||
      !isBrightcoveVideoCloudRetranscodeAvailable(this.props.modelName, this.props.selectedItems)
    );
  }

  render() {
    const mapRetranscodeAction = {
      [EPISODE]: this.props.retranscodeEpisodes,
      [CLIP]: this.props.retranscodeClips,
      [NETWORK_CLIP]: this.props.retranscodeNetworkClips,
    };

    return (
      <ConfirmableActionButton
        ref="retranscodeButton"
        className="retranscode-btn"
        buttonClass={`retranscode-btn btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        buttonLabel="Re-Transcode"
        confirmationText={this.formatConfirmationString(
          're-transcode',
          this.props.modelName,
          this.props.selectedItems.length
        )}
        action={mapRetranscodeAction[this.props.modelName].bind(
          this,
          this.props.selectedItems.map((item) => item.video.id)
        )}
        immediateCloseOnConfirm
        isConfirmDisabled={this.props.bulkActionStatus === IN_PROGRESS}
      />
    );
  }
}

Retranscode.propTypes = {
  retranscodeEpisodes: PropTypes.func,
  retranscodeClips: PropTypes.func,
  retranscodeNetworkClips: PropTypes.func,
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  bulkActionStatus: PropTypes.string,
};

export default Retranscode;
