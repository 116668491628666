import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import * as youtubeVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/youtube';

export default class PolicySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      options: [],
    };
  }

  componentDidMount() {
    return this.getSelectOptions();
  }

  async getSelectOptions() {
    this.setState({ loading: true });
    const policies = await youtubeVmsApi.getPolicies();
    const policyOptions = policies.map((policy) => ({ value: policy.name, label: policy.name }));
    this.setState({ options: policyOptions, loading: false });
  }

  render() {
    return (
      <Select
        isLoading={this.state.loading}
        options={this.state.options}
        value={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        menuStyle={{ maxHeight: '100px' }}
      />
    );
  }
}

PolicySelect.defaultProps = {
  placeholder: 'Select…',
};

PolicySelect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.boolean,
};
