import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import DetailsView from 'src/scripts/components/MediaItem/DetailsView';
import {
  getNetworkClip,
  deleteNetworkClipAndCloseConfirmation,
  saveNetworkClip,
} from 'src/scripts/actions/networkClip';
import NetworkClipFormLoader from 'src/scripts/components/NetworkClip/NetworkClipFormLoader';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import { transcodeBrightcoveVideoCloud } from 'src/scripts/actions/video';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { getNetworkClipWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';

export class NetworkClipDetailsView extends React.Component {
  constructor() {
    super();
    this.getClipId = this.getClipId.bind(this);
    this.getNetworkClip = this.getNetworkClip.bind(this);
  }

  getNetworkClip() {
    return this.props.getNetworkClip(this.getClipId());
  }
  getClipId() {
    return Number(this.props.clip.id);
  }

  getBreadcrumbList() {
    const breadcrumbList = [
      <a href="/#/network/categories">Categories</a>,
      <a href={`/#/network/categories/${this.props.clip.partOfCategory.id}/subcategories`}>
        {this.props.clip.partOfCategory.name}
      </a>,
      <a
        href={`/#/network/categories/${this.props.clip.partOfCategory.id}/subcategories/${this.props.clip.partOfSubcategory.id}/clips`}
      >
        {this.props.clip.partOfSubcategory.name}
      </a>,
      this.props.clip.name,
    ];
    return breadcrumbList;
  }

  render() {
    const videoCurrentlyUploading = !!(
      this.props.uploadMetaData && this.props.uploadMetaData[this.props.clip.video.id]
    );

    return (
      <DetailsView
        ref="detailsView"
        workflowActions={
          <WorkflowActions
            getWorkflowActions={getNetworkClipWorkflowActions}
            model={this.props.clip}
            resource="networkClip"
            videoCurrentlyUploading={videoCurrentlyUploading}
            editForm={<NetworkClipFormLoader clipId={Number(this.props.clip.id)} />}
            delete={this.props.deleteNetworkClipAndCloseConfirmation.bind(this, this.props.clip.id)}
            save={this.props.saveNetworkClip}
            isButton
          />
        }
        transcodeBrightcoveVideoCloud={this.props.transcodeBrightcoveVideoCloud.bind(
          this,
          VIDEOS.NETWORK,
          this.props.clip.video.id,
          this.getNetworkClip
        )}
        model={this.props.clip}
        resource="networkClip"
        resourceGroup={VIDEOS.NETWORK}
        breadcrumbList={this.getBreadcrumbList()}
        disableEdit={false}
      />
    );
  }
}

NetworkClipDetailsView.propTypes = {
  getNetworkClip: PropTypes.func,
  deleteNetworkClipAndCloseConfirmation: PropTypes.func,
  history: PropTypes.object,
  clip: PropTypes.object,
  uploadMetaData: PropTypes.object,
  disableReingestVideoCloud: PropTypes.bool,
  transcodeBrightcoveVideoCloud: PropTypes.func,
  saveNetworkClip: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    uploadMetaData: state.video.uploadMetaData,
    disableReingestVideoCloud: state.video.disableReingestVideoCloud,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNetworkClip: (networkClipId) => dispatch(getNetworkClip(networkClipId)),
    deleteNetworkClipAndCloseConfirmation: (networkClipId) =>
      dispatch(deleteNetworkClipAndCloseConfirmation(networkClipId)),
    transcodeBrightcoveVideoCloud: (resourceGroup, videoId, resource) =>
      dispatch(transcodeBrightcoveVideoCloud(resourceGroup, videoId, resource)),
    saveNetworkClip: (clip) => dispatch(saveNetworkClip(clip)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClipDetailsView);
