import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as videosVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/videos';
import * as pagination from 'src/scripts/lib/pagination';
import { closeModal } from 'src/scripts/actions/modalItem';
import { CLOSE_MODAL } from 'src/scripts/actions/modalItem';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { DELETE_EPISODE_SUCCEEDED } from 'src/scripts/actions/episode';

export const GET_ALL_INGEST_JOBS_SUCCEEDED = 'GET_ALL_INGEST_JOBS_SUCCEEDED';
export const GET_ALL_INGEST_JOBS_FAILED = 'GET_ALL_INGEST_JOBS_FAILED';
export const GET_EPISODE_FOR_INGEST_JOB_SUCCEEDED = 'GET_EPISODE_FOR_INGEST_JOB_SUCCEEDED';
export const GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_SUCCEEDED =
  'GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_SUCCEEDED';
export const GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_FAILED =
  'GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_FAILED';
export const OVERWRITEABLE_DUPLICATE_EPISODE_FOUND = 'OVERWRITEABLE_DUPLICATE_EPISODE_FOUND';
export const GET_DUPLICATE_EPISODE_FAILED = 'GET_DUPLICATE_EPISODE_FAILED';
export const IMPORT_EPISODE_STARTED = 'IMPORT_EPISODE_STARTED';
export const IMPORT_EPISODE_SUCCEEDED = 'IMPORT_EPISODE_SUCCEEDED';
export const IMPORT_EPISODE_FAILED = 'IMPORT_EPISODE_FAILED';
export const DELETE_INGEST_JOB_SUCCEEDED = 'DELETE_INGEST_JOB_SUCCEEDED';
export const REPLACE_EPISODE_IN_PROGRESS = 'REPLACE_EPISODE_IN_PROGRESS';

export function getAllIngestJobs(params = {}) {
  return (dispatch) => {
    const offset = params.offset ? params.offset : 0;
    const sort = params.sort ? params.sort : undefined;
    const search = params.search ? params.search : null;
    const request = {
      offset,
      sort,
      limit: pagination.pageSize,
      search,
      ...params.filters,
    };
    const onSuccess = (data) => {
      dispatch({
        type: GET_ALL_INGEST_JOBS_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_ALL_INGEST_JOBS_FAILED,
        error: response.responseJSON ? response.responseJSON.message : 'error',
      });
    };
    return tvVmsApi.getAllIngestJobs(request).then(onSuccess, onError);
  };
}

export function getEpisodeForIngestJob(ingestJobId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_EPISODE_FOR_INGEST_JOB_SUCCEEDED,
        data,
      });
    };
    return tvVmsApi.getEpisodeForIngestJob(ingestJobId).then(onSuccess);
  };
}

export function importEpisodeForIngestJob(ingestJobId, episodeValues) {
  return (dispatch) => {
    const onSuccess = (importData) => {
      dispatch({
        type: IMPORT_EPISODE_SUCCEEDED,
        data: importData,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: IMPORT_EPISODE_FAILED,
        error: response.responseJSON.message,
      });
    };

    dispatch({
      type: IMPORT_EPISODE_STARTED,
    });

    return tvVmsApi.importEpisodeForIngestJob(ingestJobId, episodeValues).then(onSuccess, onError);
  };
}

export function replaceEpisodeForIngestJob(ingestJobId, episodeId, newEpisodeValues) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_EPISODE_SUCCEEDED,
        id: episodeId,
      });
      return importEpisodeForIngestJob(ingestJobId, newEpisodeValues)(dispatch);
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    dispatch({
      type: REPLACE_EPISODE_IN_PROGRESS,
    });
    return tvVmsApi.deleteEpisode(episodeId).then(onSuccess, onError);
  };
}

export function checkAndImportEpisodeForIngestJob(ingestJobId, episodeValues) {
  return (dispatch) => {
    const request = {
      seasonId: episodeValues.seasonId,
      number: episodeValues.number,
    };
    // eslint-disable-next-line consistent-return
    const onSuccess = (data) => {
      const duplicateEpisode = data.episodes[0];
      if (duplicateEpisode && duplicateEpisode.state === 'ARCHIVED') {
        dispatch({
          type: OVERWRITEABLE_DUPLICATE_EPISODE_FOUND,
          data,
        });
      } else {
        return importEpisodeForIngestJob(ingestJobId, episodeValues)(dispatch);
      }
    };
    return tvVmsApi.getAllEpisodes(request).then(onSuccess);
  };
}

export function getEpisodeForIngestJobAndAssociatedData(ingestJobId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_EPISODE_FOR_INGEST_JOB_AND_FORM_LISTS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi
      .getEpisodeForIngestJob(ingestJobId)
      .then((data) => {
        const tvSeriesId = data.episode.tvSeriesId;
        if (!tvSeriesId) {
          return data;
        }
        return tvVmsApi.getTVSeriesById(tvSeriesId).then((tvSeriesRes) => {
          data.episode.partOfSeries = tvSeriesRes.tvSeries;
          return data;
        });
      })
      .then((data) => {
        const tvSeriesId = data.episode.tvSeriesId;
        if (!tvSeriesId) {
          data.seasons = [];
          return data;
        }
        return tvVmsApi.getSeasons({ tvSeriesId }).then((seasonRes) => {
          data.seasons = seasonRes.seasons;
          return data;
        });
      })
      .then((data) => {
        return videosVmsApi.getSignedVideoSourceUrl(VIDEOS.TV, data.ingestJob.video.id).then((res) => {
          data.ingestJob.video.signedS3Url = res.url;
          return data;
        });
      })
      .then(onSuccess, onError);
  };
}

export function deleteIngestJob(ingestJobId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_INGEST_JOB_SUCCEEDED,
        id: ingestJobId,
      });
      dispatch({
        type: CLOSE_MODAL,
      });
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteIngestJob(ingestJobId).then(onSuccess, onError);
  };
}
