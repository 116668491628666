import _ from 'lodash';
import moment from 'moment';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_CLIP_STARTED,
  SAVE_CLIP_SUCCEEDED,
  SAVE_CLIP_FAILED,
  GET_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED,
  GET_CLIP_AND_ASSOCIATED_DATA_FAILED,
  CLIP_UPDATED,
  GET_CLIP_FORM_INITIAL_DATA_SUCCEEDED,
} from 'src/scripts/actions/clip';
import { FORM_GET_SEASONS_SUCCEEDED } from 'src/scripts/actions/season';
import { GET_EPISODES_SUCCEEDED } from 'src/scripts/actions/episode';
import { secondsToHoursMinutesSeconds } from 'src/scripts/lib/cuepointsConverter';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/clipForm';
import {
  shallowFormatValidDatesToISOString,
  shallowSetEmptyDateFieldsToNull,
} from 'src/scripts/reducers/helpers';

export const initialState = {
  clip: {
    cuePoints: [{ time: '0:0:0' }],
    geoBlocking: true,
    watermarkEnabled: true,
    tags: [],
    adsEnabled: null,
    producerNotes: null,
    transcodingProfile: 'hd',
  },
  seasons: [],
  episodes: [],
  episodeRelationships: [
    { value: 'ASSOCIATED', label: 'Associated' },
    { value: 'DERIVED', label: 'Derived' },
  ],
  showValidationErrors: false,
  validationErrors: {},
  errorMessage: null,
  autoActivateConfirmationDisplayed: false,
  saving: false,
  initialFormDataLoaded: false,
};

function getTvSeriesId(clip) {
  let tvSeriesId;
  if (clip.partOfSeries) {
    tvSeriesId = clip.partOfSeries.id;
  }
  if (clip.tvSeriesId) {
    tvSeriesId = clip.tvSeriesId;
  }
  return tvSeriesId ? parseInt(tvSeriesId, 10) : undefined;
}

function getSeasonId(clip) {
  let seasonId;
  if (clip.partOfSeason) {
    seasonId = clip.partOfSeason.id;
    delete clip.partOfSeason;
  }
  if (clip.seasonId) {
    seasonId = clip.seasonId;
  }
  return seasonId ? parseInt(seasonId, 10) : undefined;
}

function getEpisodeId(clip) {
  let episodeId;
  if (clip.partOfEpisode) {
    episodeId = clip.partOfEpisode.id;
    delete clip.partOfEpisode;
  }
  if (clip.episodeId) {
    episodeId = clip.episodeId;
  }
  return episodeId ? parseInt(episodeId, 10) : undefined;
}

function preprocessClip(clip) {
  const tvSeriesId = getTvSeriesId(clip);
  const seasonId = getSeasonId(clip);
  const episodeId = getEpisodeId(clip);
  if (tvSeriesId) {
    clip.tvSeriesId = tvSeriesId;
  }
  if (seasonId) {
    clip.seasonId = seasonId;
  }
  if (episodeId) {
    clip.episodeId = episodeId;
  }
  if (clip.tags) {
    clip.tags = _.map(clip.tags, 'name');
  }
  if (clip.video) {
    clip.drm = clip.video.drm;
    clip.cuePoints = secondsToHoursMinutesSeconds(clip.video.cuePoints);
  }
  if (clip.state === 'ARCHIVED') {
    clip.autoActivate = false;
  }
  return clip;
}

function setAvailabilityToNow(state) {
  return {
    ...state,
    clip: {
      ...state.clip,
      availability: moment().format(),
    },
  };
}

const preprocessClipDateFields = (clip, validationErrorsObject) => {
  const dateFields = ['availability', 'expiry'];
  let updatedIncomingClip = shallowFormatValidDatesToISOString(clip, dateFields, validationErrorsObject);
  updatedIncomingClip = shallowSetEmptyDateFieldsToNull(updatedIncomingClip, dateFields);
  return updatedIncomingClip;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CLIP_AND_ASSOCIATED_DATA_SUCCEEDED:
      return {
        ...state,
        ...action.data,
        clip: preprocessClip(action.data.clip),
      };

    case GET_CLIP_AND_ASSOCIATED_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case CLIP_UPDATED:
      const incomingClip = { ...state.clip, ...action.clip };
      const validationErrorsObject = getValidationErrors(
        incomingClip,
        getValidationRules(incomingClip.state)
      );
      const updatedIncomingClip = preprocessClipDateFields(incomingClip, validationErrorsObject);

      if (state.showValidationErrors) {
        const errorMessage = getValidationErrorMessage(validationErrorsObject);
        return {
          ...state,
          clip: updatedIncomingClip,
          validationErrors: validationErrorsObject,
          errorMessage,
        };
      }
      return {
        ...state,
        clip: updatedIncomingClip,
      };

    case GET_EPISODES_SUCCEEDED:
      return {
        ...state,
        episodes: action.data.episodes,
      };

    case FORM_GET_SEASONS_SUCCEEDED:
      return {
        ...state,
        seasons: action.data.seasons,
      };

    case SAVE_CLIP_STARTED:
      return { ...state, saving: true };

    case SAVE_CLIP_FAILED:
      return { ...state, saving: false, errorMessage: action.error };

    case SAVE_CLIP_SUCCEEDED:
      return { ...state, saving: false };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.clip, getValidationRules(state.clip.state));
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return setAvailabilityToNow(initialState);

    case GET_CLIP_FORM_INITIAL_DATA_SUCCEEDED:
      return { ...state, initialFormDataLoaded: true };

    default:
  }

  return state;
}
