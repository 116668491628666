import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'src/scripts/lib/history';
import { resetView } from 'src/scripts/actions/mediaItemDetailsView';
import { getClip } from 'src/scripts/actions/clip';
import ViewLoader from 'src/scripts/components/ViewLoader';
import ClipDetailsView from 'src/scripts/components/Clip/internal/ClipDetailsView';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class ClipDetailsViewLoader extends React.Component {
  componentDidMount() {
    this.props.resetView();
    this.props.getClip(this.props.params.clipId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.clipDetailsView.clip && this.props.clipDetailsView.clip) {
      this.navigateToClipList();
      return;
    }
    if (nextProps.disableReingestVideoCloud !== this.props.disableReingestVideoCloud) {
      this.props.getClip(Number(this.props.params.clipId));
    }
    if (nextProps.params.clipId !== this.props.params.clipId) {
      this.props.getClip(Number(nextProps.params.clipId));
    }
  }

  navigateToClipList() {
    const tvSeriesId = this.props.clipDetailsView.clip.partOfSeries.id;
    const seasonId = this.props.clipDetailsView.clip.partOfSeason.id;
    const pathname = `/${TV_PATH}/tv-series/${tvSeriesId}/seasons/${seasonId}/clips`;
    history.replace({ state: null, pathname, query: { page: 1 } });
  }

  render() {
    return (
      <ViewLoader
        loading={
          !this.props.clipDetailsView.clip ||
          Number(this.props.params.clipId) !== this.props.clipDetailsView.clip.id
        }
        errorMessage={this.props.clipDetailsView.errorMessage}
      >
        <ClipDetailsView clip={this.props.clipDetailsView.clip} />
      </ViewLoader>
    );
  }
}

ClipDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  resetView: PropTypes.func,
  getClip: PropTypes.func,
  clipDetailsView: PropTypes.object,
  disableReingestVideoCloud: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    clipDetailsView: state.clips.detailsView,
    disableReingestVideoCloud: state.mediaItemDetailsView.disableReingestVideoCloud,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetView: () => dispatch(resetView()),
    getClip: (clipId) => dispatch(getClip(clipId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClipDetailsViewLoader);
