import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Input } from 'react-bootstrap';
import { NETWORK } from 'src/scripts/lib/libraries';
import { Creatable } from 'react-select';

export class MonetisationPanel extends React.Component {
  onVideoGroupChange = (event) => {
    const videoGroupId = event.target.value;
    this.props.onChange({
      videoGroupId,
    });
  };

  onSsaiEnabledChange = () => {
    this.props.onChange({
      ssaiEnabled: this.refs.ssaiEnabled.getChecked(),
    });
  };

  onPreRollChange = () => {
    this.props.onChange({
      preRoll: this.refs.preRoll.getChecked(),
    });
  };

  onLiveSportsChange = () => {
    this.props.onChange({
      isSports: this.refs.liveSports.getChecked(),
    });
  };

  onContentTargetingCategoriesChange = (options) => {
    const optionValues = options.map((opt) => opt.value);
    this.props.onChange({
      contentTargetingCategories: optionValues.length > 0 ? optionValues : null,
    });
  };

  buildContentTargetingCategoriesInputValues = () => {
    return (this.props.liveEvent.contentTargetingCategories || []).map((value) => ({ label: value, value }));
  };

  render() {
    const enableClientSidePreroll = (
      <Input
        type="checkbox"
        label="Enable client side pre-roll"
        ref="preRoll"
        id="check-pre-roll"
        groupClassName="form-inline"
        onChange={this.onPreRollChange}
        checked={this.props.liveEvent.preRoll}
      />
    );
    return (
      <Panel header="Monetisation" bsStyle="primary">
        <Input
          type="select"
          labelClassName="required"
          label="Live Event Video Group"
          ref="selectVideoGroup"
          disabled={this.props.liveEvent.id}
          value={this.props.liveEvent.videoGroupId}
          onChange={this.onVideoGroupChange}
        >
          {this.props.videoGroups.map((videoGroup) => {
            return (
              <option value={videoGroup.id} key={videoGroup.id}>
                {videoGroup.name}
              </option>
            );
          })}
        </Input>
        {this.props.library === NETWORK && enableClientSidePreroll}
        <Input
          type="checkbox"
          label="Enable server side ad insertion"
          ref="ssaiEnabled"
          id="check-ssai-enabled"
          groupClassName="form-inline"
          onChange={this.onSsaiEnabledChange}
          disabled={this.props.library === NETWORK}
          checked={this.props.liveEvent.ssaiEnabled}
          data-pw="check-ssai-enabled"
        />
        <Input
          type="checkbox"
          label="Live Sports"
          ref="liveSports"
          groupClassName="form-inline"
          onChange={this.onLiveSportsChange}
          checked={this.props.liveEvent.isSports}
        />
        <label ref="contentTargetingCategories">Categories</label>
        <Creatable
          multi
          value={this.buildContentTargetingCategoriesInputValues()}
          placeholder="Content Targeting Categories"
          arrowRenderer={() => null}
          menuContainerStyle={{ display: 'none' }}
          onChange={this.onContentTargetingCategoriesChange}
          ref="contentTargetingCategoriesInput"
        />
      </Panel>
    );
  }
}

MonetisationPanel.propTypes = {
  liveEvent: PropTypes.object.isRequired,
  videoGroups: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  library: PropTypes.string.isRequired,
};

export default MonetisationPanel;
