import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import history from 'src/scripts/lib/history';
import DetailsView from 'src/scripts/components/MediaItem/DetailsView';
import { getEpisode, deleteEpisodeAndCloseConfirmation, saveEpisode } from 'src/scripts/actions/episode';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import { getEpisodeWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import { transcodeBrightcoveVideoCloud } from 'src/scripts/actions/video';
import { tvStatesAndAllowedActions } from 'src/scripts/lib/workflowActionsProvider';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class EpisodeDetailsView extends React.Component {
  constructor() {
    super();
    this.redirectToEpisodesList = this.redirectToEpisodesList.bind(this);
    this.refreshDetails = this.refreshDetails.bind(this);
  }

  getBreadcrumbList() {
    const breadcrumbList = [
      <a href={`/#/${TV_PATH}/tv-series`}>TV Series</a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.episode.partOfSeries.id}`}>
        {this.props.episode.partOfSeries && this.props.episode.partOfSeries.name}
      </a>,
      <a href={`/#/${TV_PATH}/tv-series/${this.props.episode.partOfSeries.id}/seasons`}>Seasons</a>,
      <a
        href={`/#/${TV_PATH}/tv-series/${this.props.episode.partOfSeries.id}/seasons/${this.props.episode.partOfSeason.id}/episodes`}
      >
        {this.props.episode.partOfSeason && this.props.episode.partOfSeason.name}
      </a>,
      this.props.episode.name,
    ];
    return breadcrumbList;
  }

  refreshDetails() {
    return this.props.getEpisode(this.props.episode.id);
  }

  redirectToEpisodesList() {
    const tvSeriesId = Number(this.props.episode.partOfSeries.id);
    const seasonId = Number(this.props.episode.partOfSeason.id);
    history.replace({
      state: null,
      pathname: `/${TV_PATH}/tv-series/${tvSeriesId}/seasons/${seasonId}/episodes`,
      query: { page: 1 },
    });
  }

  isEditDisabled() {
    return !_.includes(tvStatesAndAllowedActions[this.props.episode.state], 'edit');
  }

  render() {
    const videoCurrentlyUploading = !!(
      this.props.uploadMetaData && this.props.uploadMetaData[this.props.episode.video.id]
    );

    return (
      <DetailsView
        ref="detailsView"
        workflowActions={
          <WorkflowActions
            getWorkflowActions={getEpisodeWorkflowActions}
            model={this.props.episode}
            resource="episode"
            disableWorkflow={this.props.disableWorkflow}
            videoCurrentlyUploading={videoCurrentlyUploading}
            editForm={<EpisodeFormLoader episodeId={Number(this.props.episode.id)} />}
            delete={this.props.deleteEpisodeAndCloseConfirmation.bind(
              this,
              this.props.episode.id,
              this.props.episode.partOfSeason.id,
              this.redirectToEpisodesList
            )}
            save={this.props.saveEpisode}
            isButton
          />
        }
        transcodeBrightcoveVideoCloud={this.props.transcodeBrightcoveVideoCloud.bind(
          this,
          VIDEOS.TV,
          this.props.episode.video.id,
          this.refreshDetails
        )}
        model={this.props.episode}
        resource="episode"
        resourceGroup={VIDEOS.TV}
        disableEdit={this.isEditDisabled()}
        breadcrumbList={this.getBreadcrumbList()}
      />
    );
  }
}

EpisodeDetailsView.propTypes = {
  disableWorkflow: PropTypes.bool,
  getEpisode: PropTypes.func,
  deleteEpisodeAndCloseConfirmation: PropTypes.func,
  history: PropTypes.object,
  episode: PropTypes.object,
  uploadMetaData: PropTypes.object,
  transcodeBrightcoveVideoCloud: PropTypes.func,
  saveEpisode: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    disableWorkflow: state.episodes.disableWorkflow,
    uploadMetaData: state.video.uploadMetaData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEpisode: (episodeId) => dispatch(getEpisode(episodeId)),
    deleteEpisodeAndCloseConfirmation: (episodeId, seasonId, onSuccess) =>
      dispatch(deleteEpisodeAndCloseConfirmation(episodeId, seasonId, onSuccess)),
    transcodeBrightcoveVideoCloud: (resourceGroup, videoId, onSuccess) =>
      dispatch(transcodeBrightcoveVideoCloud(resourceGroup, videoId, onSuccess)),
    saveEpisode: (episode) => dispatch(saveEpisode(episode)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeDetailsView);
