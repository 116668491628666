import Rx from 'rx';

import _ from 'lodash';

import xml2js from 'xml2js';

function rxAjax(options) {
  return Rx.Observable.create((subscriber) => {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          subscriber.onNext({
            xhr,
            body: xhr.response,
          });
          subscriber.onCompleted();
        } else {
          subscriber.onError(new Error(`Http status=${xhr.status}`));
        }
      }
    };
    xhr.onerror = (err) => {
      return subscriber.onError(err);
    };
    xhr.open(options.method || 'GET', options.url, true);
    if (!options.headers) {
      options.headers = {};
    }
    _.forEach(options.headers, (value, name) => {
      return xhr.setRequestHeader(name, value);
    });
    return xhr.send(options.body || null);
  });
}

function s3RestApi(options) {
  return rxAjax(options).flatMap((res) => {
    return Rx.Observable.fromNodeCallback(xml2js.parseString)(res.body)
      .catch(Rx.Observable.just(void 0))
      .map((jsonData) => {
        res.data = jsonData;
        return res;
      });
  });
}

export function createUpload(signedUrlForCreateMultipartUpload) {
  return s3RestApi({
    method: 'POST',
    url: signedUrlForCreateMultipartUpload,
  }).map((res) => {
    return {
      uploadId: res.data.InitiateMultipartUploadResult.UploadId[0],
    };
  });
}

export function uploadPart(signedUrlForUploadPart, partBlob) {
  return s3RestApi({
    method: 'PUT',
    url: signedUrlForUploadPart,
    body: partBlob,
  }).map((res) => {
    return res.xhr.getResponseHeader('ETag');
  });
}

export function completeUpload(signedUrlForCompleteMultipartUpload, partsInfo) {
  const builder = new xml2js.Builder();
  const completeObject = {
    CompleteMultipartUpload: {
      Part: partsInfo,
    },
  };
  const compeletionXml = builder.buildObject(completeObject);
  return s3RestApi({
    method: 'POST',
    url: signedUrlForCompleteMultipartUpload,
    body: compeletionXml,
    headers: {
      'Content-Type': ' ',
    },
  }).map((res) => {
    return res.body;
  });
}
