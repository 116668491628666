import _ from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Well, Input, Panel, Alert } from 'react-bootstrap';

function hasDuplicates(items) {
  const nonEmptyValues = _.chain(items)
    .map((item) => item.value)
    .compact()
    .value();
  const uniqueItems = _.uniq(nonEmptyValues);
  return nonEmptyValues.length !== uniqueItems.length;
}

class MultiInputList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputItems: this.props.items || [],
      onChange: this.props.onChange || (() => {}),
    };
    this.removeInputItem = this.removeInputItem.bind(this);
    this.addInputItem = this.addInputItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      inputItems: nextProps.items || [],
    });
  }

  getItems() {
    return this.state.inputItems;
  }

  clearItems() {
    const clearedItems = [];
    this.state.onChange(clearedItems);
    this.setState({
      inputItems: clearedItems,
    });
  }

  addInputItem() {
    const updatedInputItems = this.state.inputItems.slice();
    updatedInputItems.push({ value: '' });
    this.state.onChange(updatedInputItems);
    this.setState({
      inputItems: updatedInputItems,
      duplicatesEntered: hasDuplicates(updatedInputItems),
    });
  }

  removeInputItem(index) {
    const updatedInputItems = this.state.inputItems.slice();
    updatedInputItems.splice(index, 1);
    this.state.onChange(updatedInputItems);
    this.setState({
      inputItems: updatedInputItems,
      duplicatesEntered: hasDuplicates(updatedInputItems),
    });
  }

  handleInputChange(index, event) {
    const userInput = event.target.value;
    if (userInput && !this.props.validate(userInput)) {
      return;
    }
    const updatedInputItems = this.state.inputItems.slice();
    const newItem = { value: userInput };
    updatedInputItems[index] = newItem;
    this.state.onChange(updatedInputItems);
    this.setState({
      inputItems: updatedInputItems,
      duplicatesEntered: hasDuplicates(updatedInputItems),
    });
  }

  render() {
    return (
      <Panel
        header={this.props.title}
        id={this.props.id}
        className={classNames(
          'multi-input-list',
          this.props.hidden && 'hidden',
          this.props.disabled && 'disabled'
        )}
      >
        <Well
          className={classNames('input-item-container', this.state.inputItems.length === 0 ? 'hidden' : '')}
        >
          {this.state.inputItems.map((item, index) => {
            const fieldRef = `itemInputField${index}`;
            const fieldId = this.props.id ? `text-${this.props.id}-${index}` : undefined;
            const disabled = item.editable === false;
            return (
              <Input
                placeholder={this.props.emptyItemMessage}
                addonBefore={index + 1}
                type="text"
                ref={fieldRef}
                key={index}
                id={fieldId}
                value={item.value}
                disabled={disabled || this.props.disabled}
                onChange={this.handleInputChange.bind(null, index)}
                buttonAfter={
                  <Button
                    ref={`removeInputItemButton${index}`}
                    bsStyle="link"
                    disabled={disabled}
                    onClick={this.removeInputItem.bind(null, index)}
                  >
                    <i className="fa fa-trash-o"></i>
                  </Button>
                }
              />
            );
          })}
        </Well>
        <Alert
          bsStyle="warning"
          ref="duplicateWarningMessage"
          className={this.state.duplicatesEntered === true ? '' : 'hidden'}
        >
          Warning: Duplicates will be removed.
        </Alert>
        {!this.props.disabled && (
          <Button
            disabled={this.state.inputItems.length >= this.props.maxItems}
            onClick={this.addInputItem}
            className="add-input-item"
            id={this.props.id ? `add-button-${this.props.id}` : undefined}
          >
            {this.props.addButtonText}
          </Button>
        )}
      </Panel>
    );
  }
}

MultiInputList.propTypes = {
  hidden: PropTypes.bool,
  addButtonText: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func,
  maxItems: PropTypes.number,
  emptyItemMessage: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};
export default MultiInputList;
