import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';

export function getThumbnailImageUrl(image) {
  if (!image) return IMAGE_NOT_FOUND_URL;
  if (image.resizedImageUrls && image.resizedImageUrls.w272) {
    return image.resizedImageUrls.w272;
  }
  return image.url;
}

export const getImageUrlForTvSeriesCatalog = (tvSeriesItem, imageType) => {
  let imageUrl = '';
  if (tvSeriesItem.containsSeason) {
    tvSeriesItem.containsSeason.images.forEach((image) => {
      if (image.type === imageType && image.alt !== 'Default Image') {
        imageUrl = image.url;
      }
    });
  }
  return imageUrl;
};
