import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { resetView } from 'src/scripts/actions/mediaItemDetailsView';
import { getEpisode } from 'src/scripts/actions/episode';
import ViewLoader from 'src/scripts/components/ViewLoader';
import history from 'src/scripts/lib/history';
import EpisodeDetailsView from 'src/scripts/components/Episode/internal/EpisodeDetailsView';
import { TV_PATH } from 'src/scripts/lib/accessController';

export class EpisodeDetailsViewLoader extends React.Component {
  componentDidMount() {
    this.props.resetView();
    this.props.getEpisode(this.props.params.episodeId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.episodeDetailsView.episode && this.props.episodeDetailsView.episode) {
      this.navigateToEpisodeList();
      return;
    }
    if (nextProps.disableReingestVideoCloud !== this.props.disableReingestVideoCloud) {
      this.props.getEpisode(Number(this.props.params.episodeId));
    }
    if (nextProps.params.episodeId !== this.props.params.episodeId) {
      this.props.getEpisode(Number(nextProps.params.episodeId));
    }
  }

  navigateToEpisodeList() {
    const tvSeriesId = this.props.episodeDetailsView.episode.partOfSeries.id;
    const seasonId = this.props.episodeDetailsView.episode.partOfSeason.id;
    const pathname = `/${TV_PATH}/tv-series/${tvSeriesId}/seasons/${seasonId}/episodes`;
    history.replace({ state: null, pathname, query: { page: 1 } });
  }

  render() {
    return (
      <ViewLoader
        loading={
          !this.props.episodeDetailsView.episode ||
          Number(this.props.params.episodeId) !== this.props.episodeDetailsView.episode.id
        }
        errorMessage={this.props.episodeDetailsView.errorMessage}
      >
        <EpisodeDetailsView episode={this.props.episodeDetailsView.episode} />
      </ViewLoader>
    );
  }
}

EpisodeDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  resetView: PropTypes.func,
  getEpisode: PropTypes.func,
  episodeDetailsView: PropTypes.object,
  disableReingestVideoCloud: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    episodeDetailsView: state.episodes.detailsView,
    disableReingestVideoCloud: state.mediaItemDetailsView.disableReingestVideoCloud,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetView: () => dispatch(resetView()),
    getEpisode: (episodeId) => dispatch(getEpisode(episodeId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeDetailsViewLoader);
