import {
  IMAGE_UPLOAD_STARTED,
  IMAGE_UPLOAD_COMPLETED,
  IMAGE_UPLOAD_CLEAR,
  IMAGE_UPLOAD_PROGRESS,
  IMAGE_UPLOAD_FAILED,
} from 'src/scripts/actions/imageUpload';

export const initialState = {
  uploading: false,
  imageId: null,
  uploadPercentage: null,
  uploadError: null,
  s3Path: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IMAGE_UPLOAD_COMPLETED:
      return {
        ...state,
        uploading: false,
        imageId: action.imageId,
        uploadPercentage: 100,
        imageType: action.imageType,
        s3Path: action.s3Path,
      };

    case IMAGE_UPLOAD_STARTED:
      return {
        ...state,
        uploading: true,
        imageId: null,
        uploadPercentage: 0,
        uploadError: null,
        imageType: action.imageType,
      };

    case IMAGE_UPLOAD_CLEAR:
      return {
        ...state,
        uploading: false,
        imageId: null,
        uploadPercentage: null,
      };

    case IMAGE_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadPercentage: Math.round((action.partNumber / action.numberOfParts) * 100),
      };
    case IMAGE_UPLOAD_FAILED:
      return {
        ...state,
        uploadError: action.errorMessage,
      };

    default:
      return state;
  }
}
