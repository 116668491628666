import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Input } from 'react-bootstrap';

export class SeoMetadata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
    this.onSelect = this.onSelect.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onPageTitleChange = this.onPageTitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  onSelect(event) {
    if (this.state.checked !== event.target.checked) {
      this.setState({
        checked: event.target.checked,
      });
    }
  }

  onNameChange(event) {
    this.props.onChange({
      seoTvSeries: event.target.value,
    });
  }

  onPageTitleChange(event) {
    this.props.onChange({
      seoPageTitle: event.target.value,
    });
  }

  onDescriptionChange(event) {
    this.props.onChange({
      seoDescription: event.target.value,
    });
  }

  renderEditableFields = () => (
    <div>
      <Input
        type="text"
        label="TVSeries(H1)"
        ref="tvSeries"
        onChange={this.onNameChange}
        value={this.props.season.seoTvSeries}
      />
      <Input
        type="text"
        label="Page Title"
        ref="pageTitle"
        onChange={this.onPageTitleChange}
        value={this.props.season.seoPageTitle}
      />
      <Input
        type="text"
        label="Description"
        ref="name"
        onChange={this.onDescriptionChange}
        value={this.props.season.seoDescription}
      />
    </div>
  );

  render() {
    return (
      <Panel header="SEO Metadata" bsStyle="primary" eventKey="1">
        <div>
          <Input
            type="checkbox"
            label="Edit SEO Metadata"
            ref="editSeoMetadata"
            groupClassName="form-inline"
            className="edit_seo"
            onChange={this.onSelect}
            checked={this.state.checked}
          />
          {this.state.checked && this.renderEditableFields()}
        </div>
      </Panel>
    );
  }
}

SeoMetadata.propTypes = {
  season: PropTypes.object,
  onChange: PropTypes.func,
  updateSeason: PropTypes.func,
};

export default SeoMetadata;
