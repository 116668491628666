import * as tvVmsApi from './apiWrappers/vmsApi/tv';
import { closeModal } from 'src/scripts/actions/modalItem';
export const GET_COLLECTIONS_SUCCEEDED = 'GET_COLLECTIONS_SUCCEEDED';
export const GET_COLLECTIONS_FAILED = 'GET_COLLECTIONS_FAILED';
export const DELETE_COLLECTION_SUCCEEDED = 'DELETE_COLLECTION_SUCCEEDED';
export const CONFIRMATION_ACTION_FAILED = 'CONFIRMATION_ACTION_FAILED';
export const GET_COLLECTION_SUCCEEDED = 'GET_COLLECTION_SUCCEEDED';
export const GET_COLLECTION_FAILED = 'GET_COLLECTION_FAILED';
export const UPDATE_COLLECTION_FORM = 'UPDATE_COLLECTION_FORM';
export const SAVE_COLLECTION_SUCCEEDED = 'SAVE_COLLECTION_SUCCEEDED';
export const SAVE_COLLECTION_FAILED = 'SAVE_COLLECTION_FAILED';
export const PUSH_TO_TV_SERIES_TO_USE_COLLECTION = 'PUSH_TO_TV_SERIES_TO_USE_COLLECTION';
export const REMOVE_FROM_TV_SERIES_TO_USE_COLLECTION = 'REMOVE_FROM_TV_SERIES_TO_USE_COLLECTION';
export const PUBLISH_COLLECTION_SUCCEEDED = 'PUBLISH_COLLECTION_SUCCEEDED';

export const getCollections = (params = {}) => {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit;
  const sort = params.sort ? params.sort : '-number';
  const type = params.type;
  const status = params.status;
  const workflow = params.workflow;
  const includeTvSeriesState = params.includeTvSeriesState;
  const includeRecommendingTvSeriesState = params.includeRecommendingTvSeriesState;
  const excludeTvSeriesEntities = params.excludeTvSeriesEntities;
  const request = {
    offset,
    limit,
    sort,
    type,
    includeTvSeriesState,
    includeRecommendingTvSeriesState,
    excludeTvSeriesEntities,
    status,
    workflow,
    ...params.filters,
  };

  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_COLLECTIONS_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_COLLECTIONS_FAILED,
        error: response.responseJSON.message,
      });
    };

    return tvVmsApi.getCollections(request).then(onSuccess, onError);
  };
};

export const getCollectionById = (collectionId, params) => {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_COLLECTION_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_COLLECTION_FAILED,
        error: response.responseJSON.message,
      });
    };

    return tvVmsApi.getCollectionById(collectionId, params).then(onSuccess, onError);
  };
};

export const updateCollectionForm = (collection) => ({
  type: UPDATE_COLLECTION_FORM,
  collection,
});

export const saveCollection = (
  collection,
  seriesToAddAsRecommendedCollection = [],
  seriesToRemoveAsRecommendedCollection = []
) => {
  return async (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_COLLECTION_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_COLLECTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    try {
      await Promise.all(
        seriesToAddAsRecommendedCollection.map((id) =>
          tvVmsApi.updateTVSeries({ id, recommendedCollectionId: collection.id })
        )
      );
      await Promise.all(
        seriesToRemoveAsRecommendedCollection.map((id) =>
          tvVmsApi.updateTVSeries({ id, recommendedCollectionId: null })
        )
      );
      const result = await tvVmsApi.updateCollection(collection);
      onSuccess(result);
    } catch (err) {
      onError(err);
    }
  };
};

export const deleteCollectionAndCloseConfirmation = (collectionId) => {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_COLLECTION_SUCCEEDED,
        id: collectionId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteCollectionById(collectionId).then(onSuccess, onError);
  };
};

export const publishCollectionAndCloseConfirmation = (collectionId) => {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: PUBLISH_COLLECTION_SUCCEEDED,
        id: collectionId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.publishCollection(collectionId).then(onSuccess, onError);
  };
};

export const pushToTvSeriesToUseCollection = (tvSeries) => ({
  type: PUSH_TO_TV_SERIES_TO_USE_COLLECTION,
  data: { tvSeries },
});

export const removeFromTvSeriesToUseCollection = (tvSeries) => ({
  type: REMOVE_FROM_TV_SERIES_TO_USE_COLLECTION,
  data: { tvSeries },
});
