import PropTypes from 'prop-types';
import React from 'react';

class Tag extends React.Component {
  render() {
    return (
      <label className="tag-list__tag">
        {this.props.name}
        <input className="tag-list__button" type="button" value="x" onClick={this.props.onDismiss} />
      </label>
    );
  }
}

Tag.propTypes = {
  onDismiss: PropTypes.func,
  name: PropTypes.string,
};

export default Tag;
