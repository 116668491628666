import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

class RightsWindow extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(amount, time) {
    const momentDate = moment(this.props.publishStartDate);
    const newDate = momentDate.add(amount, time);
    this.props.onChange(newDate.toISOString());
  }

  render() {
    const ranges = this.props.rightsRanges;
    return (
      <div className="rights-window">
        <span>Rights Window</span>
        {_.map(
          ranges,
          function (range, index) {
            return (
              <Button
                className={`rangeBtn${index}`}
                onClick={this.onClick.bind(null, range.amount, range.type)}
                data-pw={`rights-button-${range.text.toLowerCase().replace(/ /g, '-')}`}
              >
                {range.text}
              </Button>
            );
          }.bind(this)
        )}
      </div>
    );
  }
}

RightsWindow.propTypes = {
  publishStartDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rightsRanges: PropTypes.array,
};

export default RightsWindow;
