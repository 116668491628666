import { formatTimeInMiliseconds } from 'src/scripts/lib/timeFormatter';
import { getImageUrlForTvSeriesCatalog } from 'src/scripts/lib/imageUtil';

export const CLIP_FIELDS = [
  'id',
  'name',
  'description',
  'seasonId',
  'state',
  'createdAt',
  'updatedAt',
  'availability',
  'expiry',
  'watermarkEnabled',
  'geoBlocking',
  {
    label: 'tags',
    value: (item) => item.tags.map((x) => x.name).toString(),
    default: '',
    stringify: true,
  },
  'partOfEpisode.classificationCode',
  'partOfEpisode.materialKey',
  'partOfEpisode.broadcastAirDate',
  'partOfSeason.name',
  'partOfSeason.seasonNumber',
  'partOfSeason.catalogCode',
  'partOfSeason.episodeDisplayNameFormat',
  'video.referenceId',
  'video.brightcoveId',
  'video.status',
  'video.drm',
  {
    label: 'Video Duration',
    value: (item) => formatTimeInMiliseconds(item.video.duration),
    default: '',
  },
  'partOfSeries.name',
  'partOfSeries.countryOfOrigin',
  'partOfSeries.genreId',
  'partOfSeries.genre.name',
  'partOfSeries.contentSegment',
  'partOfSeries.productionCompany',
  'partOfEpisode.hasCaptions',
];

export const EPISODE_FIELDS = [
  'id',
  'name',
  'description',
  'seasonId',
  'number',
  'state',
  'createdAt',
  'updatedAt',
  'availability',
  'expiry',
  'watermarkEnabled',
  'geoBlocking',
  'classificationCode',
  'materialKey',
  'oztamMediaId',
  'broadcastAirDate',
  'partOfSeason.name',
  'partOfSeason.seasonNumber',
  'partOfSeason.catalogCode',
  'partOfSeason.episodeDisplayNameFormat',
  'partOfSeason.createdAt',
  'partOfSeason.updatedAt',
  'video.referenceId',
  'video.brightcoveId',
  'video.drm',
  {
    label: 'Video Duration',
    value: (item) => formatTimeInMiliseconds(item.video.duration),
    default: '',
  },
  {
    label: 'Video CuePoints',
    value: (item) => item.video.cuePoints.map((x) => x.time).toString(),
    default: '',
    stringify: true,
  },
  {
    label: 'Number Of VideoCuePoints',
    value: (item) => item.video.cuePoints.map((x) => x.time).length,
    default: '',
    stringify: true,
  },
  'video.captionsIngestEnabled',
  'video.status',
  'partOfSeries.name',
  'partOfSeries.countryOfOrigin',
  'partOfSeries.genreId',
  'partOfSeries.genre.name',
  {
    label: 'Secondary Genres',
    value: (item) => item.partOfSeries.secondaryGenres.map((x) => x.name).toString(),
    default: '',
    stringify: true,
  },
  'partOfSeries.contentSegment',
  'partOfSeries.productionCompany',
  'partOfSeries.createdAt',
  'partOfSeries.updatedAt',
  'hasCaptions',
  'displayName',
];

export const NETWORK_CLIP_FIELDS = [
  'id',
  'name',
  'description',
  'state',
  'createdAt',
  'updatedAt',
  'availability',
  'expiry',
  'geoBlocking',
  {
    label: 'tags',
    value: (item) => item.tags.map((x) => x.name).toString(),
    default: '',
    stringify: true,
  },
  'fullSlug',
  'headline',
  'longDescription',
  'preRoll',
  'classificationCode',
  'producerNotes',
  'thirdPartyProvider',
  'contentOriginType',
  'contentOriginSeasonId',
  'contentOriginSeason.name',
  'contentOriginSeason.partOfSeries.id',
  'contentOriginSeason.partOfSeries.name',
  'contentSeries',
  'partOfCategory.createdAt',
  'partOfCategory.id',
  'partOfCategory.name',
  'partOfCategory.sitecoreCategoryTag',
  'partOfCategory.slug',
  'partOfSubcategory.availableContentSeries',
  'partOfSubcategory.contentGroup',
  'partOfSubcategory.geoBlocked',
  'partOfSubcategory.id',
  'partOfSubcategory.name',
  'partOfSubcategory.slug',
  'video.referenceId',
  'video.brightcoveId',
  'video.status',
  'video.drm',
  {
    label: 'Video Duration',
    value: (item) => formatTimeInMiliseconds(item.video.duration),
    default: '',
  },
];

export const COLLECTIONS_FIELDS = [
  'id',
  'name',
  'type',
  'createdAt',
  'updatedAt',
  'containsFutureInactiveTvSeries',
  'containsFutureInactiveRecommendingTvSeries',
  {
    label: 'tvSeries.CollectionTVSeries.rank',
    value: (item) => (item.tvSeries ? item.tvSeries.CollectionTVSeries.rank + 1 : null),
    default: '',
  },
  'tvSeries.name',
  'tvSeries.CollectionTVSeries.createdAt',
];

export const STREAMS_FIELDS = [
  'id',
  'cdn',
  'url',
  'marketSlug',
  'channelSlug',
  'streamType',
  {
    label: 'ssaiEnabled',
    value: (item) => (item.ssaiEnabled ? 'Yes' : 'No'),
    default: '',
  },
  {
    label: 'drmEnabled',
    value: (item) => (item.drmEnabled ? 'Yes' : 'No'),
    default: '',
  },
  {
    label: 'fullHdEnabled',
    value: (item) => (item.fullHdEnabled ? 'Yes' : 'No'),
    default: '',
  },
  {
    label: 'startOverEnabled',
    value: (item) => (item.startOverEnabled ? 'Yes' : 'No'),
    default: '',
  },
  'createdAt',
  'updatedAt',
];

const getAllCatalogCodes = (tvSeriesItem) => {
  if (!tvSeriesItem.containsSeason || !tvSeriesItem.containsSeason.allCatalogCodes) return '';
  return tvSeriesItem.containsSeason.allCatalogCodes.toString();
};

const renderSeasonFieldsLabelAndValue = () => {
  const seasonFields = [
    'containsSeason.name',
    'containsSeason.description',
    {
      label: 'Card Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'card'),
      default: '',
      stringify: true,
    },
    {
      label: 'Background Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'background'),
      default: '',
      stringify: true,
    },
    {
      label: 'Logo Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'logo'),
      default: '',
      stringify: true,
    },
    {
      label: 'Horizontal Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'horizontal_a'),
      default: '',
      stringify: true,
    },
    {
      label: 'Square Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'square'),
      default: '',
      stringify: true,
    },
    {
      label: 'Featured Background Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'featured_background'),
      default: '',
      stringify: true,
    },
    {
      label: 'Featured Card Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'featured_card'),
      default: '',
      stringify: true,
    },
    {
      label: 'CTV Carousel Image',
      value: (item) => getImageUrlForTvSeriesCatalog(item, 'ctv_carousel'),
      default: '',
      stringify: true,
    },
    'containsSeason.episodeActiveCount',
    'containsSeason.clipActiveCount',
    'containsSeason.slug',
    'containsSeason.seasonNumber',
    'containsSeason.classificationRating',
    'containsSeason.seasonYear',
    'containsSeason.contentFlag',
    'containsSeason.catalogCode',
    {
      label: 'season.allCatalogCodes',
      value: (item) => getAllCatalogCodes(item),
      default: '',
    },
    'containsSeason.isBoxSet',
    {
      label: 'season.isBoxSet.publishDate',
      value: 'containsSeason.publishedDate',
    },
    'containsSeason.channelId',
    'containsSeason.channel.name',
    {
      label: 'season.showAdvertising',
      value: 'containsSeason.defaultAdsEnabled',
    },
    'containsSeason.episodeDisplayNameFormat',
    'containsSeason.clipsGeoBlocked',
    'containsSeason.episodesGeoBlocked',
    'containsSeason.clipsWatermarkEnabled',
    'containsSeason.episodesWatermarkEnabled',
    'containsSeason.genderSkew',
    'containsSeason.contentGroup',
    'containsSeason.seoTvSeries',
    'containsSeason.seoPageTitle',
    'containsSeason.seoDescription',
    'containsSeason.defaultContentIdChannel',
    'containsSeason.defaultContentIdUsagePolicy',
    'containsSeason.defaultContentIdMatchPolicy',
    'containsSeason.contentIdOwnershipStrategy',
    'containsSeason.contentIdOwnershipCountries',
    {
      label: 'season.classifications.type',
      value: (item) =>
        item.containsSeason && item.containsSeason.classifications
          ? item.containsSeason.classifications.map((x) => x.type).toString()
          : '',
      default: '',
      stringify: true,
    },
    {
      label: 'season.classifications.digitalExclusive',
      value: (item) =>
        item.containsSeason && item.containsSeason.classifications
          ? item.containsSeason.classifications.map((x) => x.digitalExclusive).toString()
          : '',
      default: '',
      stringify: true,
    },
    {
      label: 'season.classifications.incrementalSpend',
      value: (item) =>
        item.containsSeason && item.containsSeason.classifications
          ? item.containsSeason.classifications.map((x) => x.incrementalSpend).toString()
          : '',
      default: '',
      stringify: true,
    },
    'containsSeason.seriesPageLiveRailEnabled',
    'containsSeason.seriesPageLiveRailDisplayName',
    'containsSeason.seriesPageEpisodesRailEnabled',
    'containsSeason.seriesPageEpisodesRailDisplayName',
    'containsSeason.seriesPageClipsRailEnabled',
    'containsSeason.seriesPageClipsRailDisplayName',
    'containsSeason.onAirScheduleDate',
    'containsSeason.createdAt',
    'containsSeason.updatedAt',
  ];
  return seasonFields.map((seasonField) => {
    if (typeof seasonField === 'string') {
      return {
        label: `season.${seasonField.split('.')[1]}`,
        value: seasonField,
      };
    }
    return seasonField;
  });
};

const TVSERIES_BASE_FIELDS = [
  'id',
  'name',
  'description',
  'marketingDescription',
  'slug',
  'countryOfOrigin',
  'contentFlag',
  'showSiteLink',
  'createdAt',
  'updatedAt',
  'recommendedCollectionId',
  'recommendedCollectionName',
  'genre.name',
  {
    label: 'Secondary Genres',
    value: (item) => (item.secondaryGenres ? item.secondaryGenres.map((x) => x.name).toString() : ''),
    default: '',
    stringify: true,
  },
  'contentSegment',
  'productionCompany',
  'useSitecoreCmsSource',
];

const TVSERIES_SEASON_FIELDS = renderSeasonFieldsLabelAndValue();

export const TVSERIES_FIELDS = TVSERIES_BASE_FIELDS.concat(TVSERIES_SEASON_FIELDS);
