// Image refs must be unique
export const LIVE_EVENT_IMAGE_REF = 'liveEventImage';
export const LIVE_EVENT_LOGO_REF = 'liveEventLogo';

export const CLIP_IMAGE_REF = 'clipImage';

export const EPISODE_IMAGE_REF = 'episodeImage';
export const EPISODE_FEATURED_THUMBNAIL_IMAGE_REF = 'episodeFeaturedThumbnailImage';

export const GENRE_IMAGE_REF = 'genreImage';
export const GENRE_FEATURED_IMAGE_REF = 'genreFeaturedImage';
export const GENRE_FEATURED_POSTER_IMAGE_REF = 'genreFeaturedPosterImage';

export const NETWORK_CLIP_IMAGE_REF = 'networkClipImage';

export const SEASON_IMAGE_LANDSCAPE_REF = 'seasonImageLandscape';
export const SEASON_IMAGE_SQUARE_REF = 'seasonImageSquare';

export const LIVE_EVENT_GROUP_IMAGE = 'liveEventGroupImage';
export const LIVE_EVENT_GROUP_BACKGROUND_IMAGE = 'liveEventGroupBackgroundImage';
export const LIVE_EVENT_GROUP_LOGO = 'liveEventGroupImage';
