import React from 'react';
import PropTypes from 'prop-types';

export const FormatText = ({ children }) => {
  if (children) {
    return <span style={{ 'white-space': 'pre-line' }}>{children}</span>;
  }
  return <span>---</span>;
};

FormatText.propTypes = {
  children: PropTypes.string,
};

export const FormatBoolean = ({ children }) => {
  if (!children) return <span>No</span>;
  return <span>Yes</span>;
};

FormatBoolean.propTypes = {
  children: PropTypes.bool,
};
