import PropTypes from 'prop-types';
import React from 'react';

const StatefulFontAwesomeIcon = (props) => {
  const successClassnames = ['fa-check-circle'];
  const failureClassnames = ['fa-exclamation-circle'];
  const baseClassnames = ['fa'];

  let iconStyle;
  let classnamesToRender;

  switch (props.successful) {
    case true: {
      iconStyle = { color: 'green' };
      classnamesToRender = baseClassnames.concat(successClassnames);
      break;
    }
    case false: {
      iconStyle = { color: 'red' };
      classnamesToRender = baseClassnames.concat(failureClassnames);
      break;
    }
    default: {
      iconStyle = { color: 'red' };
      classnamesToRender = baseClassnames.concat(failureClassnames);
    }
  }

  switch (props.size) {
    case 'small':
      classnamesToRender.push('fa-lg');
      break;
    case 'medium':
      classnamesToRender.push('fa-2x');
      break;
    case 'large':
      classnamesToRender.push('fa-3x');
      break;
    default:
      classnamesToRender.push('fa-lg');
  }

  return <i className={classnamesToRender.join(' ')} style={iconStyle}></i>;
};

StatefulFontAwesomeIcon.propTypes = {
  successful: PropTypes.bool,
  size: PropTypes.string,
};

StatefulFontAwesomeIcon.defaultProps = {
  successful: false,
  size: 'small',
};

export default StatefulFontAwesomeIcon;
