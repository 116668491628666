import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { hideConfirmationModal } from 'src/scripts/actions/confirmation';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';

export class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
  }

  onOk(reason) {
    this.props.hideConfirmationModal();
    return this.props.ok(reason);
  }

  render() {
    return !this.props.confirmationModalDisplayed ? null : (
      <div className="static-modal">
        <Modal show backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title ref="modalTitle">
              <i className="fa fa-exclamation-triangle warning-icon" /> Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body ref="modalBody">
            <ConfirmationDialog
              ref="confirmationModal"
              invitation={this.props.descriptions}
              ok={this.onOk}
              close={this.props.hideConfirmationModal}
              buttonClass="danger"
              buttonLabel="Confirm"
              reasonRequired={this.props.reasonRequired}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

ConfirmationModal.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.string),
  hideConfirmationModal: PropTypes.func,
  ok: PropTypes.func,
  confirmationModalDisplayed: PropTypes.bool,
  reasonRequired: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    descriptions: state.confirmation.descriptions,
    confirmationModalDisplayed: state.confirmation.confirmationModalDisplayed,
    ok: state.confirmation.ok,
    reasonRequired: state.confirmation.reasonRequired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideConfirmationModal: () => dispatch(hideConfirmationModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
