import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';
import DetailsList from 'src/scripts/components/MediaItem/DetailsList';

class DetailsFieldSet extends React.Component {
  render() {
    let row = null;
    if (this.props.value) {
      row = <Row className="details-value">{this.props.value}</Row>;
    } else {
      row = <DetailsList itemList={this.props.list} />;
    }

    return (
      <Row className="details-row">
        <Row className="details-key">{this.props.label}</Row>
        {row}
      </Row>
    );
  }
}

DetailsFieldSet.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.any,
  list: PropTypes.array,
  videoId: PropTypes.number,
};

export default DetailsFieldSet;
