export function loadScript(url, callback) {
  const el = window.document.createElement('script');
  let loaded = false;
  el.onload = el.onreadystatechange = () => {
    if ((el.readyState && el.readyState !== 'complete' && el.readyState !== 'loaded') || loaded) {
      return false;
    }
    el.onload = el.onreadystatechange = null;
    callback();
    loaded = true;
    return null;
  };
  el.async = true;
  el.src = url;
  window.document
    .getElementsByTagName('head')[0]
    .insertBefore(el, window.document.getElementsByTagName('head').firstChild);
}
