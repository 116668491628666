import PropTypes from 'prop-types';
import React from 'react';

class TV extends React.Component {
  render() {
    return this.props.children;
  }
}

TV.propTypes = {
  children: PropTypes.object,
};

export default TV;
