import React from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

export class ClearFiltersButton extends React.Component {
  constructor(props) {
    super(props);
    this.hasNoAdditionalFiltersApplied = this.hasNoAdditionalFiltersApplied.bind(this);
  }

  getAppliedFilters(filters) {
    const appliedFilters = {};
    for (const filterName in filters) {
      if (filters[filterName]) {
        appliedFilters[filterName] = filters[filterName];
      }
    }
    return appliedFilters;
  }

  getCheckboxFilterValuesFromString(checkboxFilterString) {
    return checkboxFilterString.replace('in:', '').split(',');
  }

  hasOnlyDefaultCheckboxFiltersApplied(currentFilterValue, defaultFilterValue) {
    const currentCheckboxFilterValues = this.getCheckboxFilterValuesFromString(currentFilterValue);
    const defaultCheckboxFilterValues = this.getCheckboxFilterValuesFromString(defaultFilterValue);
    if (currentCheckboxFilterValues.length === defaultCheckboxFilterValues.length) {
      return !_.difference(defaultCheckboxFilterValues, currentCheckboxFilterValues).length;
    }
    return false;
  }

  hasOnlyDefaultFilterApplied(currentFilter, defaultFilter) {
    const currentFilterName = Object.keys(currentFilter)[0];
    if (currentFilterName === defaultFilter.name) {
      const currentFilterValue = Object.values(currentFilter)[0];
      const defaultFilterValue = defaultFilter.value;
      if (this.isCheckboxFilterValue(currentFilterValue) && this.isCheckboxFilterValue(defaultFilterValue)) {
        return this.hasOnlyDefaultCheckboxFiltersApplied(currentFilterValue, defaultFilterValue);
      }
      return currentFilterValue === defaultFilterValue;
    }
    return false;
  }

  isCheckboxFilterValue(filterValue) {
    return !!filterValue.match(/(in:)([^,]*)(,?([^,]*))*/g);
  }

  hasSearchValue() {
    return this.props.searchValue || this.props.appliedSearchValue;
  }

  hasNoAdditionalFiltersApplied() {
    const appliedFilters = this.getAppliedFilters(this.props.filters);
    const numberOfAppliedFilters = Object.keys(appliedFilters).length;
    const defaultFilter = this.props.defaultFilter;

    if (this.hasSearchValue()) {
      return false;
    }
    if (!numberOfAppliedFilters && !defaultFilter) {
      return true;
    }
    if (defaultFilter && numberOfAppliedFilters === 1) {
      return this.hasOnlyDefaultFilterApplied(appliedFilters, defaultFilter);
    }
    return false;
  }

  render() {
    return (
      <Button
        ref="button"
        bsSize="small"
        className="clear-filters-btn"
        disabled={this.hasNoAdditionalFiltersApplied()}
        onClick={this.props.clearFilters}
      >
        Clear Filters
      </Button>
    );
  }
}

ClearFiltersButton.propTypes = {
  clearFilters: PropTypes.func,
  filters: PropTypes.object,
  defaultFilter: PropTypes.object,
  searchValue: PropTypes.string,
  appliedSearchValue: PropTypes.string,
};

export default ClearFiltersButton;
