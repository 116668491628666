import { DELETE_LIVE_EVENT_GROUP_SUCCEEDED } from 'src/scripts/actions/liveEventGroup';
import { immutableRemoveById } from 'src/scripts/lib/util';

export const initialState = {
  liveEventGroups: [],
  count: 0,
  errorMessage: null,
  offset: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_LIVE_EVENT_GROUP_SUCCEEDED:
      return {
        ...state,
        liveEventGroups: immutableRemoveById(state.liveEventGroups, action.id),
      };

    default:
      return state;
  }
}
