import * as requestHandler from '../requestHandler';
import { VIDEOS } from 'src/scripts/lib/resourceGroups';
import { getPrefix } from './endpointPrefixResolver';

// exported only to simplify testing
export function validateResourceGroup(resourceGroup) {
  switch (resourceGroup) {
    case VIDEOS.TV:
    case VIDEOS.NETWORK:
      return;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}

export function getSignedVideoSourceUrl(resourceGroup, videoId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send(
    'POST',
    `${getPrefix(resourceGroup)}/videos/${videoId}/download`,
    JSON.stringify({ setContentDispositionHeader: true })
  );
}

export function getSignedCaptionSourceUrl(resourceGroup, videoId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/videos/${videoId}/captions-download`);
}

export function notifyUploadCompletion(resourceGroup, payload, sourceUrl) {
  validateResourceGroup(resourceGroup);
  const params = {
    uploadType: payload.uploadType,
    sourceUrl,
  };
  return requestHandler.send(
    'PATCH',
    `${getPrefix(resourceGroup)}/videos/${payload.videoId}/uploaded-video-file`,
    JSON.stringify(params)
  );
}

export function startTranscode(resourceGroup, videoId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send('POST', `${getPrefix(resourceGroup)}/videos/${videoId}/transcode`);
}

export function transcodeBrightcoveVideoCloud(resourceGroup, videoId) {
  validateResourceGroup(resourceGroup);
  return requestHandler.send(
    'PATCH',
    `${getPrefix(resourceGroup)}/videos/${videoId}/ingest-videocloud`,
    JSON.stringify({})
  );
}

export function deleteCaption(videoId) {
  return requestHandler.send('DELETE', `${getPrefix(VIDEOS.TV)}/videos/${videoId}/captions`);
}
