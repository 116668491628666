import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { closeModal } from 'src/scripts/actions/modalItem';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';

export const GET_TV_SERIES_LIST_SUCCEEDED = 'GET_TV_SERIES_LIST_SUCCEEDED';
export const GET_TV_SERIES_LIST_FAILED = 'GET_TV_SERIES_LIST_FAILED';
export const SAVE_TV_SERIES_FAILED = 'SAVE_TV_SERIES_FAILED';
export const SAVE_TV_SERIES_SUCCEEDED = 'SAVE_TV_SERIES_SUCCEEDED';
export const GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED = 'GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED';
export const GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED = 'GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED';
export const TV_SERIES_UPDATED = 'TV_SERIES_UPDATED';
export const DELETE_TV_SERIES_SUCCEEDED = 'DELETE_TV_SERIES_SUCCEEDED';
export const GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED = 'GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED';
export const GET_TV_SERIES_FORM_INITIAL_DATA_FAILED = 'GET_TV_SERIES_FORM_INITIAL_DATA_FAILED';
export const GET_TV_SERIES_BY_ID_SUCCEEDED = 'GET_TV_SERIES_BY_ID_SUCCEEDED';
export const GET_TV_SERIES_BY_ID_FAILED = 'GET_TV_SERIES_BY_ID_FAILED';
export const GET_COUNTRIES_OF_ORIGIN_SUCCEEDED = 'GET_COUNTRIES_OF_ORIGIN_SUCCEEDED';
export const GET_COUNTRIES_OF_ORIGIN_FAILED = 'GET_COUNTRIES_OF_ORIGIN_FAILED';
export const GET_CONTENT_SEGMENTS_SUCCEEDED = 'GET_CONTENT_SEGMENTS_SUCCEEDED';
export const GET_CONTENT_SEGMENTS_FAILED = 'GET_CONTENT_SEGMENTS_FAILED';
export const GET_PRODUCTION_COMPANIES_SUCCEEDED = 'GET_PRODUCTION_COMPANIES_SUCCEEDED';
export const GET_PRODUCTION_COMPANIES_FAILED = 'GET_PRODUCTION_COMPANIES_FAILED';

export function getTVSeries(params = {}) {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit;
  const sort = params.sort ? params.sort : undefined;
  const request = {
    offset,
    limit,
    sort,
    ...params.filters,
  };

  if (params.recommendedCollectionId) {
    request.recommendedCollectionId = params.recommendedCollectionId;
  }

  if (params.includeActiveEpisodeStates === true) {
    request.includeActiveEpisodeStates = true;
  }

  return (dispatch) => {
    const onSuccess = (data) => {
      return dispatch({
        type: GET_TV_SERIES_LIST_SUCCEEDED,
        data,
        offset,
      });
    };
    const onError = () => {
      return dispatch({
        type: GET_TV_SERIES_LIST_FAILED,
      });
    };
    return tvVmsApi
      .getTVSeries(request)
      .then((data) => {
        return tvVmsApi.getCollections({ type: 'CUSTOM' }).then(({ collections }) => ({
          ...data,
          customCollections: collections.sort((a, b) => a.name.localeCompare(b.name)),
        }));
      })
      .then(onSuccess, onError);
  };
}

export function saveTVSeries(payload) {
  return (dispatch) => {
    const saveMethod = payload.id ? tvVmsApi.updateTVSeries : tvVmsApi.createTVSeries;
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_TV_SERIES_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      return dispatch({
        type: SAVE_TV_SERIES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(payload).then(onSuccess, onError);
  };
}

export function updateTvSeries(tvSeries) {
  return {
    type: TV_SERIES_UPDATED,
    tvSeries,
  };
}

export function getTvSeriesAndAssociatedData(tvSeriesId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED,
        data,
      });
    };
    const onError = (error) => {
      dispatch({
        type: GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED,
        error: error.responseJSON.message,
      });
    };
    return Promise.all([
      tvVmsApi.getTVSeriesById(tvSeriesId),
      tvVmsApi.getGenres(),
      tvVmsApi.getCollections({ type: 'CUSTOM' }),
      tvVmsApi.getContentSegments(),
      tvVmsApi.getProductionCompanies(),
    ])
      .then(
        ([
          { tvSeries },
          { genres },
          { collections: customCollections },
          contentSegments,
          productionCompanies,
        ]) => ({
          tvSeries,
          genres,
          customCollections,
          contentSegments,
          productionCompanies,
        })
      )
      .then(onSuccess, onError);
  };
}

export function deleteTVSeries(tvSeriesId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_TV_SERIES_SUCCEEDED,
        id: tvSeriesId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.deleteTVSeries(tvSeriesId).then(onSuccess, onError);
  };
}

export function getContentSegments() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_CONTENT_SEGMENTS_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_CONTENT_SEGMENTS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getContentSegments().then(onSuccess, onError);
  };
}

export function getProductionCompanies() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_PRODUCTION_COMPANIES_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_PRODUCTION_COMPANIES_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getProductionCompanies().then(onSuccess, onError);
  };
}

export function getTVSeriesFormInitialData(tvSeriesId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      if (tvSeriesId) {
        dispatch({
          type: GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED,
        });
      } else {
        dispatch({
          type: GET_TV_SERIES_FORM_INITIAL_DATA_SUCCEEDED,
          data,
        });
      }
    };
    const onError = () => {
      dispatch({
        type: GET_TV_SERIES_FORM_INITIAL_DATA_FAILED,
      });
    };

    if (tvSeriesId) {
      return dispatch(getTvSeriesAndAssociatedData(tvSeriesId)).then(onSuccess);
    }

    return Promise.all([
      tvVmsApi.getGenres(),
      tvVmsApi.getCollections({ type: 'CUSTOM' }),
      tvVmsApi.getContentSegments(),
      tvVmsApi.getProductionCompanies(),
    ])
      .then(([{ genres }, { collections: customCollections }, contentSegments, productionCompanies]) => ({
        genres,
        customCollections: customCollections.sort((a, b) => a.name.localeCompare(b.name)),
        contentSegments,
        productionCompanies,
      }))
      .then(onSuccess, onError);
  };
}

export function getCountriesOfOrigin() {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_COUNTRIES_OF_ORIGIN_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_COUNTRIES_OF_ORIGIN_FAILED,
        error: response.responseJSON.message,
      });
    };
    return tvVmsApi.getCountriesOfOrigin().then(onSuccess, onError);
  };
}
