import {
  connect as pusherConnect,
  disconnect as pusherDisconnect,
  subscribe as pusherSubscribe,
} from './pusher';
import {
  connect as apiGatewayConnect,
  disconnect as apiGatewayDisconnect,
  subscribe as apiGatewaySubscribe,
} from './apiGateway';

import environment from 'config/environment';

const connect = environment.featureFlags.usingPusher ? pusherConnect : apiGatewayConnect;
const disconnect = environment.featureFlags.usingPusher ? pusherDisconnect : apiGatewayDisconnect;
const subscribe = environment.featureFlags.usingPusher ? pusherSubscribe : apiGatewaySubscribe;

export { connect, disconnect, subscribe };
