import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ActiveEpisodeState from 'src/scripts/components/Collection/ActiveEpisodeState';

// eslint-disable-next-line
class TvSeriesField extends Component {
  static propTypes = {
    rank: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    className: PropTypes.string,
    domRef: PropTypes.func,
    activeEpisodeCount: PropTypes.number.isRequired,
    isFutureActive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor() {
    super();
    this.state = {
      isDragging: false,
    };
  }

  onMouseDown = () => {
    this.setState({ isDragging: true }, () => {
      document.addEventListener('dragend', this.handleDragEnd);
    });
  };

  handleDragEnd = () => {
    this.setState({ isDragging: false }, document.removeEventListener('dragend', this.handleDragEnd));
  };

  handleClick = () => {
    this.setState({ isDragging: false });
  };

  addClassIfDragging = () => {
    return this.state.isDragging ? 'dragging' : '';
  };

  render() {
    return (
      <div
        onClick={this.handleClick}
        ref={this.props.domRef}
        className={`collection-form-tv-series-field ${this.props.className} ${this.addClassIfDragging()}`}
        onMouseDown={this.onMouseDown}
      >
        <div className="rank">{this.props.rank}</div>
        <div className="name">{this.props.name}</div>
        <ActiveEpisodeState
          activeEpisodeCount={this.props.activeEpisodeCount}
          isFutureActive={this.props.isFutureActive}
        />
        <div className="remove-btn" onClick={this.props.onRemove}>
          <i className="remove-btn-icon fa fa-minus-circle" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}

export default TvSeriesField;
