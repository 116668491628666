import {
  GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED,
  GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED,
  SAVE_TV_SERIES_SUCCEEDED,
} from 'src/scripts/actions/tvSeries';

export const initialState = {
  tvSeries: null,
  errorMessage: null,
  customCollections: [],
  allCustomCollections: [],
};

function preProcessTvSeries(tvSeries) {
  if (tvSeries.secondaryGenres) {
    tvSeries.secondaryGenresIds = tvSeries.secondaryGenres.map((secondaryGenre) => secondaryGenre.id);
  }
  return tvSeries;
}

const updateStateIfRequired = (state, action) => {
  if (state.tvSeries && state.tvSeries.id === action.data.tvSeries.id) {
    return {
      ...state,
      tvSeries: preProcessTvSeries(action.data.tvSeries),
    };
  }
  return state;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TV_SERIES_AND_ASSOCIATED_DATA_SUCCEEDED:
      return {
        ...state,
        tvSeries: preProcessTvSeries(action.data.tvSeries),
        customCollections: action.data.customCollections,
        errorMessage: null,
      };

    case GET_TV_SERIES_AND_ASSOCIATED_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case SAVE_TV_SERIES_SUCCEEDED:
      return updateStateIfRequired(state, action);

    default:
      return state;
  }
}
