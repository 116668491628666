import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line
const getFlexDirection = (props) => {
  if (props.column) return 'column';
  if (props.row) return 'row';
};

const FlexboxWrapper = (props) => {
  return (
    <div className="flexContainer" style={{ display: 'flex', flexDirection: getFlexDirection(props) }}>
      {props.children}
    </div>
  );
};

FlexboxWrapper.propTypes = {
  row: PropTypes.bool,
  column: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FlexboxWrapper.defaultProps = {
  row: true,
};

export default FlexboxWrapper;
