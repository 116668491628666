import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Input } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';

class BulkEditScheduleDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.onPublishStartDateChange = this.onPublishStartDateChange.bind(this);
    this.onPublishEndDateChange = this.onPublishEndDateChange.bind(this);
    this.onBroadcastAirDateChange = this.onBroadcastAirDateChange.bind(this);
  }

  onPublishStartDateChange(startDate) {
    this.props.onChange(startDate, this.props.publishEndDate);
  }

  onPublishEndDateChange(endDate) {
    this.props.onChange(this.props.publishStartDate, endDate);
  }

  onBroadcastAirDateChange(airDate) {
    this.props.onAirDateChange(airDate);
  }

  render() {
    return (
      <Panel
        id="panel-publishing-schedule"
        header="Publishing Schedule"
        bsStyle="primary"
        expanded={false}
        eventKey="4"
        className="schedulePanel"
      >
        <div
          ref="publishStartDateWrapper"
          className={`form-group ${this.props.validationErrors.publishStartDate ? 'error' : ''}`}
        >
          <label>Start Date & Time</label>
          <Input
            type="radio"
            id="set-start-date-radio"
            onClick={() => this.props.handleKeepExistingStartDateChange(false)}
            checked={!this.props.keepExistingStartDate}
            label={
              <div style={{ marginTop: '-7px' }}>
                <DateTimePicker
                  ref="publishStartDate"
                  id="start-date-picker"
                  disabled={this.props.keepExistingStartDate}
                  onChange={this.onPublishStartDateChange}
                  value={this.props.publishStartDate}
                  placeholder="Enter start date"
                />
              </div>
            }
          />
          <Input
            type="radio"
            id="keep-existing-start-date-radio"
            label="Keep existing"
            checked={this.props.keepExistingStartDate}
            onClick={() => this.props.handleKeepExistingStartDateChange(true)}
          />
        </div>
        <div
          ref="publishEndDateWrapper"
          className={`form-group ${this.props.validationErrors.publishEndDate ? 'error' : ''}`}
        >
          <label>End Date & Time</label>
          <Input
            type="radio"
            id="set-end-date-radio"
            onClick={() => this.props.handleKeepExistingEndDateChange(false)}
            checked={!this.props.keepExistingEndDate}
            label={
              <div style={{ marginTop: '-7px' }}>
                <DateTimePicker
                  ref="publishEndDate"
                  id="end-date-picker"
                  disabled={this.props.keepExistingEndDate}
                  onChange={this.onPublishEndDateChange}
                  value={this.props.publishEndDate}
                  placeholder="Enter end date"
                />
              </div>
            }
          />
          {!this.props.hideKeepExistingEndDateInput ? (
            <Input
              type="radio"
              id="keep-existing-end-date-radio"
              label="Keep existing"
              checked={this.props.keepExistingEndDate}
              onClick={() => this.props.handleKeepExistingEndDateChange(true)}
            />
          ) : null}
        </div>
        <div
          ref="broadcastAirDateWrapper"
          className={`form-group ${this.props.validationErrors.broadcastAirDate ? 'error' : ''}`}
        >
          <label>Air Date & Time</label>
          <Input
            type="radio"
            id="set-air-date-radio"
            onClick={() => this.props.handleKeepExistingAirDateChange(false)}
            checked={!this.props.keepExistingAirDate}
            label={
              <div style={{ marginTop: '-7px' }}>
                <DateTimePicker
                  ref="broadcastAirDate"
                  id="air-date-picker"
                  disabled={this.props.keepExistingAirDate}
                  onChange={this.onBroadcastAirDateChange}
                  value={this.props.broadcastAirDate}
                  placeholder="Enter air date"
                />
              </div>
            }
          />
          {!this.props.hideKeepExistingAirDateInput ? (
            <Input
              type="radio"
              id="keep-existing-air-date-radio"
              label="Keep existing"
              checked={this.props.keepExistingAirDate}
              onClick={() => this.props.handleKeepExistingAirDateChange(true)}
            />
          ) : null}
        </div>
      </Panel>
    );
  }
}

BulkEditScheduleDatePicker.propTypes = {
  publishStartDate: PropTypes.string,
  publishEndDate: PropTypes.string,
  broadcastAirDate: PropTypes.string,
  keepExistingStartDate: PropTypes.boolean,
  keepExistingEndDate: PropTypes.boolean,
  keepExistingAirDate: PropTypes.boolean,
  handleKeepExistingStartDateChange: PropTypes.func,
  handleKeepExistingEndDateChange: PropTypes.func,
  handleKeepExistingAirDateChange: PropTypes.func,
  hideKeepExistingEndDateInput: PropTypes.boolean,
  hideKeepExistingAirDateInput: PropTypes.boolean,
  onChange: PropTypes.func,
  onAirDateChange: PropTypes.func,
  validationErrors: PropTypes.object,
};

BulkEditScheduleDatePicker.defaultProps = {
  validationErrors: {},
};

export default BulkEditScheduleDatePicker;
