import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Input, Button, Panel } from 'react-bootstrap';
import Select from 'react-select';
import { getSubcategoriesForForm } from 'src/scripts/actions/subcategory';
import PublishingSchedule from 'src/scripts/components/PublishingSchedule';
import TagList from 'src/scripts/components/TagList';
import ImageUpload from 'src/scripts/components/ImageUpload';
import {
  updateNetworkClip,
  saveNetworkClip,
  rescheduleAndSaveClip,
  saveAndPublishNetworkClip,
  getThirdPartyProviders,
} from 'src/scripts/actions/networkClip';
import { isNetworkUploadEnabled, networkStateConsts } from 'src/scripts/lib/workflowActionsProvider';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';
import autoPublishRegions from 'src/scripts/lib/autoPublishRegions';
import { TAGS, IMAGES } from 'src/scripts/lib/resourceGroups';
import ExternalFeed from '../ExternalFeed';
import VideoUpload from 'src/scripts/components/VideoUpload';
import { NETWORK_CLIP_IMAGE_REF } from 'src/scripts/lib/imageRefs';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/networkClipForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import ContentOriginPanel from 'src/scripts/components/NetworkClip/internal/ContentOriginPanel';
import { getSeasonsForForm } from 'src/scripts/actions/season';
import {
  THIRD_PARTY_ORIGIN_TYPE,
  TV_ORIGIN_TYPE,
  isContentOriginOfType,
} from 'src/scripts/lib/contentOriginTypes';
import SeoMetadataForClips from 'src/scripts/components/SeoMetadataForClips';
import { NETWORK } from 'src/scripts/lib/libraries';

export class NetworkClipForm extends React.Component {
  constructor(props) {
    super(props);
    this.contentSeriesUnselected = 'contentSeriesUnselected';
    const mode = this.props.clip.id ? 'edit' : 'add';
    this.state = { mode };
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onContentSeriesChange = this.onContentSeriesChange.bind(this);
    this.onSubcategoryChange = this.onSubcategoryChange.bind(this);
    this.isSubcategorySelectDisabled = this.isSubcategorySelectDisabled.bind(this);
    this.onPreRollChange = this.onPreRollChange.bind(this);
    this.onPublishingScheduleChange = this.onPublishingScheduleChange.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.onExternalFeedOptionsChange = this.onExternalFeedOptionsChange.bind(this);
    this.onVideoFileChange = this.onVideoFileChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onProducerNotesChange = this.onProducerNotesChange.bind(this);
    this.onClassificationCodeChange = this.onClassificationCodeChange.bind(this);
    this.onGeoBlockingChange = this.onGeoBlockingChange.bind(this);
    this.save = this.save.bind(this);
    this.saveAndUpload = this.saveAndUpload.bind(this);
    this.isUploadDisabled = this.isUploadDisabled.bind(this);
    this.onAutoPublishRegionsChange = this.onAutoPublishRegionsChange.bind(this);
  }

  onNameChange() {
    this.props.updateNetworkClip({
      name: this.refs.clipName.getValue(),
    });
  }

  onProducerNotesChange() {
    this.props.updateNetworkClip({
      producerNotes: this.refs.clipProducerNotes.getValue(),
    });
  }

  onClassificationCodeChange(event) {
    this.props.updateNetworkClip({
      classificationCode: !isDefaultOptionSelected(this.refs.videoClassification) ? event.target.value : null,
    });
  }

  onCategoryChange() {
    const categoryId = Number(this.refs.selectCategory.getValue());

    this.props.updateNetworkClip({
      categoryId,
      subcategoryId: 0,
    });
    if (categoryId) {
      this.props.getSubcategories(categoryId);
    }
  }

  onSubcategoryChange() {
    const subcategoryId = Number(this.refs.selectSubcategory.getValue());
    const selectedSubcategory = _.find(
      this.props.subcategories,
      (subcategory) => subcategory.id === subcategoryId
    );
    this.props.updateNetworkClip({
      subcategoryId,
      contentSeries: this.contentSeriesUnselected,
      geoBlocking: selectedSubcategory && selectedSubcategory.geoBlocked,
    });
  }

  onDescriptionChange() {
    this.props.updateNetworkClip({
      description: this.refs.clipDescription.getValue(),
    });
  }

  onTagsChange(tags) {
    this.props.updateNetworkClip({
      tags,
    });
  }

  onExternalFeedOptionsChange(externalFeedOptions) {
    this.props.updateNetworkClip({
      externalFeedOptions,
    });
  }

  onContentSeriesChange() {
    this.props.updateNetworkClip({
      contentSeries: this.refs.contentSeries.getValue(),
    });
  }

  onPreRollChange() {
    this.props.updateNetworkClip({
      preRoll: this.refs.preRoll.getChecked(),
    });
  }

  onRequireReviewChange = () => {
    this.props.updateNetworkClip({
      requireReview: this.refs.requireReview.getChecked(),
    });
  };

  onPublishingScheduleChange(publishStartDate, publishEndDate) {
    this.props.updateNetworkClip({
      availability: publishStartDate,
      expiry: publishEndDate,
    });
  }

  onVideoFileChange(file) {
    this.props.updateNetworkClip({
      file,
    });
  }

  onAutoPublishRegionsChange(event) {
    const regionValuesArray = event.map((region) => region.value);
    this.props.updateNetworkClip({
      autoPublishRegions: regionValuesArray,
    });
  }

  onGeoBlockingChange(event) {
    this.props.updateNetworkClip({
      geoBlocking: event.target.checked,
    });
  }

  getFramePickerVideoUrl() {
    const selectedVideoFile = _.get(this.props, 'clip.file');
    const existingVideoS3Url = _.get(this.props, 'clip.video.signedS3Url');
    return selectedVideoFile ? URL.createObjectURL(selectedVideoFile) : existingVideoS3Url;
  }

  isSubmitDisabled() {
    return this.props.imageUpload.uploading || this.props.saving;
  }

  isSubcategorySelectDisabled() {
    return !this.props.clip.categoryId;
  }

  isContentSeriesDisabled() {
    return !this.props.clip.subcategoryId;
  }

  isSubcategoryGeoblockedByDefault() {
    const selectedSubcategory = _.find(
      this.props.subcategories,
      (subcategory) => subcategory.id === this.props.clip.subcategoryId
    );
    return selectedSubcategory && selectedSubcategory.geoBlocked;
  }

  saveAndUpload(event) {
    event.preventDefault();
    this.save();
  }

  getClipValuesForSave = () => {
    const clip = {
      ...this.props.clip,
      video: {},
      availability: this.props.clip.availability,
      expiry: this.props.clip.expiry,
      tags: this.props.clip.tags.slice(),
      autoPublishRegions: this.props.clip.autoPublishRegions,
      requireReview: this.props.clip.requireReview,
    };
    if (this.props.clip.contentSeries === this.contentSeriesUnselected) {
      clip.contentSeries = '';
    }
    const videoFile = this.props.clip.file;
    if (videoFile) {
      clip.video.file = videoFile;
    }
    if (this.props.imageUpload.imageId) {
      clip.imageId = this.props.imageUpload.imageId;
    }
    if (clip.externalFeedOptions) {
      clip.tags.push(
        ...clip.externalFeedOptions
          .filter((option) => {
            return option.checked;
          })
          .map((option) => {
            return option.value;
          })
      );
      delete clip.externalFeedOptions;
    }
    if (!isContentOriginOfType(clip.contentOriginType, THIRD_PARTY_ORIGIN_TYPE)) {
      clip.thirdPartyProvider = null;
    }
    if (!isContentOriginOfType(clip.contentOriginType, TV_ORIGIN_TYPE)) {
      clip.contentOriginTVSeriesId = null;
      clip.contentOriginSeasonId = null;
    }
    return clip;
  };

  save() {
    const clip = this.getClipValuesForSave();

    if (!isFormValid(clip, getValidationRules(this.props.clip.state))) {
      this.props.showFormValidation();
      return;
    }

    if (this.props.isReschedule) {
      this.props.rescheduleAndSaveClip(clip);
    } else {
      this.props.saveNetworkClip(clip);
    }
  }

  saveAndPublish = () => {
    const clip = this.getClipValuesForSave();
    if (!isFormValid(clip, getValidationRules(this.props.clip.state))) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveAndPublishNetworkClip(clip);
  };

  isUploadDisabled() {
    return !(
      (this.props.clip.state === 'ARCHIVED' && this.props.isReschedule) ||
      isNetworkUploadEnabled(this.props.clip)
    );
  }

  render() {
    const subcategoryForCurrentClip = this.props.subcategories.filter(
      ({ id }) => this.props.clip.subcategoryId === id
    )[0];
    const currentlyAvailableContentSeries =
      (subcategoryForCurrentClip && subcategoryForCurrentClip.availableContentSeries) || [];
    const defaultContentSeriesSelectText = currentlyAvailableContentSeries.length
      ? 'Select a Content Series'
      : 'No Content Series Available';
    const contentSeriesInput = (
      <Input
        type="select"
        label="Content Series"
        ref="contentSeries"
        id="select-content-series"
        disabled={this.isContentSeriesDisabled()}
        onChange={this.onContentSeriesChange}
        value={this.props.clip.contentSeries}
      >
        <option key={this.contentSeriesUnselected} value={this.contentSeriesUnselected}>
          {defaultContentSeriesSelectText}
        </option>
        {currentlyAvailableContentSeries.map((contentSeries) => {
          return <option key={contentSeries}>{contentSeries}</option>;
        })}
      </Input>
    );
    return (
      <form className="form" onSubmit={this.saveAndUpload} ref="clipForm">
        <div>
          <Panel id="panel-library" header="Library" bsStyle="primary" defaultExpanded eventKey="1">
            <Input
              type="select"
              label="Category"
              ref="selectCategory"
              id="select-category"
              labelClassName="required"
              disabled={this.state.mode === 'edit'}
              onChange={this.onCategoryChange}
              value={this.props.clip.categoryId}
              bsStyle={this.props.validationErrors.categoryId ? 'error' : null}
            >
              <option value="0">Select a Category</option>
              {this.props.categories.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </Input>

            <Input
              type="select"
              label="Subcategory"
              ref="selectSubcategory"
              id="select-sub-category"
              labelClassName="required"
              disabled={this.isSubcategorySelectDisabled()}
              onChange={this.onSubcategoryChange}
              value={this.props.clip.subcategoryId}
              bsStyle={this.props.validationErrors.subcategoryId ? 'error' : null}
            >
              <option value="0">Select a Subcategory</option>
              {this.props.subcategories.map((subcategory) => {
                return (
                  <option key={subcategory.id} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                );
              })}
            </Input>
          </Panel>
          <Panel id="panel-meta-data" header="Metadata" bsStyle="primary" expanded={false} eventKey="3">
            <Input
              labelClassName="required"
              type="text"
              label="Name"
              ref="clipName"
              id="text-name"
              maxLength="100"
              onChange={this.onNameChange}
              value={this.props.clip.name}
              bsStyle={this.props.validationErrors.name ? 'error' : null}
            />
            <Input
              type="textarea"
              label="Description"
              ref="clipDescription"
              id="text-description"
              maxLength="2000"
              help="Hint: The description will be used as the video caption."
              onChange={this.onDescriptionChange}
              value={this.props.clip.description}
            />
            <TagList
              ref="tagList"
              onChange={this.onTagsChange}
              tags={this.props.clip.tags}
              resourceGroup={TAGS.NETWORK}
              includeTagCount
            />
            <ExternalFeed
              options={this.props.clip.externalFeedOptions}
              onChange={this.onExternalFeedOptionsChange}
            />
            {contentSeriesInput}
            <Input
              type="select"
              label="Classification"
              ref="videoClassification"
              id="select-classification"
              className="videoClassification"
              onChange={this.onClassificationCodeChange}
              value={this.props.clip.classificationCode}
            >
              <option value="0">NA - Not Applicable</option>
              {this.props.classifications.map((classification) => {
                return (
                  <option key={classification.code} value={classification.code}>
                    {classification.code} - {classification.name}
                  </option>
                );
              })}
            </Input>
            <Input
              type="textarea"
              label="Producer Notes"
              ref="clipProducerNotes"
              id="text-producer-notes"
              maxLength="2000"
              onChange={this.onProducerNotesChange}
              value={this.props.clip.producerNotes}
            />
            <label className="control-label">Auto Publish to 9News Regions</label>
            <Select
              multi
              label="Auto-Publish Regions"
              name="form-field-name"
              ref="autoPublishRegions"
              value={this.props.clip.autoPublishRegions}
              onChange={this.onAutoPublishRegionsChange}
              options={autoPublishRegions.map((regionName) => {
                return { value: regionName, label: regionName };
              })}
            />
          </Panel>
          <SeoMetadataForClips
            updateClip={this.props.updateNetworkClip}
            clip={this.props.clip}
            clipType={NETWORK}
          />
          <Panel id="panel-source-files" header="Source Files" bsStyle="primary" eventKey="2">
            <VideoUpload
              isDisabled={this.isUploadDisabled}
              onChange={this.onVideoFileChange}
              selectedFileName={_.get(this.props, 'clip.file.name')}
            />
            <ImageUpload
              id="image-network-clip"
              resourceGroup={IMAGES.NETWORK}
              imageUploadRef={NETWORK_CLIP_IMAGE_REF}
              showFramePicker
              framePickerVideoUrl={this.getFramePickerVideoUrl()}
            />
            <Input
              type="checkbox"
              label="Enable pre-roll"
              ref="preRoll"
              id="check-pre-roll"
              groupClassName="form-inline"
              onChange={this.onPreRollChange}
              checked={this.props.clip.preRoll}
              disabled={this.props.clip.state === 'ARCHIVED' && !this.props.isReschedule}
            />
          </Panel>
          <Panel header="Geoblocking" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label={`Clips Available only in Australia. ${
                this.props.clip.subcategoryId
                  ? `(Subcategory Default: ${
                      this.isSubcategoryGeoblockedByDefault() ? 'Geoblocked' : 'Not Geoblocked'
                    })`
                  : ''
              }`}
              ref="geoBlocking"
              id="check-clip-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onGeoBlockingChange}
              checked={this.props.clip.geoBlocking}
            />
          </Panel>
          <ContentOriginPanel
            onChange={this.props.updateNetworkClip}
            clip={this.props.clip}
            thirdPartyProviders={this.props.thirdPartyProviders}
            getThirdPartyProviders={this.props.getThirdPartyProviders}
            seasons={this.props.seasons}
            getSeasons={this.props.getSeasons}
            validationErrors={this.props.validationErrors}
          />
          <PublishingSchedule
            ref="publishingSchedule"
            publishStartDate={this.props.clip.availability}
            publishEndDate={this.props.clip.expiry}
            onChange={this.onPublishingScheduleChange}
            rightsRanges={[
              { amount: 3, type: 'days', text: '3 days' },
              { amount: 1, type: 'years', text: '1 year' },
            ]}
            validationErrors={{
              publishStartDate: this.props.validationErrors.availability,
              publishEndDate: this.props.validationErrors.expiry,
            }}
          />
          {![networkStateConsts.READY_FOR_REVIEW, networkStateConsts.IN_USE].includes(
            this.props.clip.state
          ) ? (
            <Panel header="Workflow" bsStyle="primary">
              <Input
                type="checkbox"
                label="Require review before publishing"
                ref="requireReview"
                groupClassName="form-inline"
                onChange={this.onRequireReviewChange}
                checked={this.props.clip.requireReview}
              />
            </Panel>
          ) : null}
        </div>
        {this.props.errorMessage && (
          <FormErrorMessage className="clip-server-error-message" message={this.props.errorMessage} />
        )}
        <div className="form__submit-button-bar">
          <Button
            id="video-modal-close"
            type="button"
            ref="closeButton"
            className="form__button"
            onClick={this.props.close}
          >
            Close
          </Button>
          <Button
            ref="submitButton"
            id="video-modal-submit"
            type="submit"
            className="form__button"
            bsStyle="primary"
            disabled={this.isSubmitDisabled()}
            data-pw="video-modal-submit"
          >
            Save
          </Button>
          {this.props.clip.state === networkStateConsts.READY_FOR_REVIEW ? (
            <Button
              ref="saveAndPublishButton"
              id="video-modal-submit"
              className="form__button"
              bsStyle="primary"
              onClick={this.saveAndPublish}
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save and Publish
            </Button>
          ) : null}
        </div>
      </form>
    );
  }
}

NetworkClipForm.propTypes = {
  close: PropTypes.func,
  clip: PropTypes.object,
  category: PropTypes.array,
  categories: PropTypes.array,
  subcategories: PropTypes.array,
  getSubcategories: PropTypes.func,
  updateNetworkClip: PropTypes.func,
  rescheduleAndSaveClip: PropTypes.func,
  saveAndPublishNetworkClip: PropTypes.func,
  errorMessage: PropTypes.string,
  imageUpload: PropTypes.object,
  saveNetworkClip: PropTypes.func,
  classifications: PropTypes.array,
  saving: PropTypes.bool,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
  isReschedule: PropTypes.bool,
  autoPublishRegions: PropTypes.array,
  thirdPartyProviders: PropTypes.array,
  getThirdPartyProviders: PropTypes.func,
  seasons: PropTypes.array,
  getSeasons: PropTypes.func,
};

function mapStateToProps(state) {
  const clipForm = state.networkClipForm;
  return {
    clip: clipForm.clip,
    category: clipForm.category,
    categories: clipForm.categories,
    subcategories: clipForm.subcategories,
    errorMessage: clipForm.errorMessage,
    saving: clipForm.saving,
    imageUpload: state.imageUploads[NETWORK_CLIP_IMAGE_REF],
    classifications: clipForm.classifications,
    validationErrors: clipForm.validationErrors,
    thirdPartyProviders: clipForm.thirdPartyProviders,
    seasons: clipForm.seasons,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubcategories: (categoryId) => dispatch(getSubcategoriesForForm(categoryId)),
    updateNetworkClip: (clip) => dispatch(updateNetworkClip(clip)),
    saveNetworkClip: (clip) => dispatch(saveNetworkClip(clip)),
    rescheduleAndSaveClip: (clip) => dispatch(rescheduleAndSaveClip(clip)),
    saveAndPublishNetworkClip: (clip) => dispatch(saveAndPublishNetworkClip(clip)),
    showFormValidation: () => dispatch(showFormValidation()),
    getThirdPartyProviders: () => dispatch(getThirdPartyProviders()),
    getSeasons: (props) => dispatch(getSeasonsForForm(props)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClipForm);
