import PropTypes from 'prop-types';
import React from 'react';
import { IN_PROGRESS, COMPLETED } from 'src/scripts/reducers/bulkActions';

class BulkActionProgressBar extends React.Component {
  constructor(props) {
    super(props);
    const isBulkActionInProgress = this.props.status === IN_PROGRESS;
    this.state = {
      isVisible: isBulkActionInProgress,
      isTransitionAnimationActive: isBulkActionInProgress,
      progressBarWidth: this.getProgressBarWidth(
        this.props.status,
        this.props.processed,
        this.props.unprocessed
      ),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status !== IN_PROGRESS && nextProps.status === IN_PROGRESS) {
      this.setState({
        isVisible: true,
        isTransitionAnimationActive: true,
        progressBarWidth: this.getProgressBarWidth(
          nextProps.status,
          nextProps.processed,
          nextProps.unprocessed
        ),
      });
    } else if (this.props.status === IN_PROGRESS && nextProps.status !== IN_PROGRESS) {
      this.fadeOutAndReset();
    } else {
      this.setState({
        progressBarWidth: this.getProgressBarWidth(
          nextProps.status,
          nextProps.processed,
          nextProps.unprocessed
        ),
      });
    }
  }

  getProgressBarWidth(status, processed, unprocessed) {
    const totalItemCount = processed.length + unprocessed.length;
    const actionStartedWidth = 100 / totalItemCount / 2;
    const progressWidth = (processed.length / totalItemCount) * 100;

    if (!totalItemCount) {
      return 0;
    } else if (status === IN_PROGRESS && processed.length === 0) {
      return actionStartedWidth;
    } else if (
      status === IN_PROGRESS ||
      (status === COMPLETED && this.state && this.state.isTransitionAnimationActive)
    ) {
      return progressWidth;
    }
    return 0;
  }

  fadeOutAndReset() {
    this.setState({
      isVisible: false,
    });
    setTimeout(() => {
      this.setState({
        isTransitionAnimationActive: false,
        progressBarWidth: 0,
      });
    }, 3000); // 3000ms delay to match 2s opacity transition from styles/blocks/bulk-action-progress-bar.styl
  }

  render() {
    const completedSectionStyle = {
      width: `${this.state.progressBarWidth}%`,
    };

    return (
      <div className={`ba-prog-bar${this.state.isVisible ? '' : ' fade-out'}`}>
        <div
          className={`ba-prog-bar-completed${this.state.isTransitionAnimationActive ? ' animate-width' : ''}`}
          style={completedSectionStyle}
        ></div>
      </div>
    );
  }
}

BulkActionProgressBar.propTypes = {
  processed: PropTypes.array,
  unprocessed: PropTypes.array,
  status: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default BulkActionProgressBar;
