import React, { Component } from 'react';
import { ViewLoader } from 'src/scripts/components/ViewLoader';
import { listStreams, STREAM_TYPES, updateStream, CDNS, RELEASE_CHANNELS } from '../../actions/stream';
import { listMarkets } from '../../actions/market';
import { listChannels } from '../../actions/channel';
import StreamView from './StreamView';
import { sortBy } from 'lodash';

const streamPrimaryBackupConfigs = {
  'channel-9': {
    sydney: {
      primary: {
        akamai: {
          hls: {
            url: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/syd/ch9/hls/r1/index.m3u8',
          },
          dash: {
            url: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/syd/ch9/dash/r1/index.mpd',
          },
        },
        yospace: {
          hls: {
            url: 'https://csm-e-nineau-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-syd-ch9-hls-r19.m3u8',
            yospaceStreamId: '616361704',
            yofb: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/syd/ch9/hls/r1/index.m3u8',
          },
          dash: {
            url: 'https://csm-e-nineaudash-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-syd-ch9-dash-r13.mpd',
            yospaceStreamId: '616071599',
            yofb: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/syd/ch9/dash/r1/index.mpd',
          },
        },
      },
      backup: {
        akamai: {
          hls: {
            url: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/syd/ch9/hls/r2/index.m3u8',
          },
          dash: {
            url: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/syd/ch9/dash/r2/index.mpd',
          },
        },
        yospace: {
          hls: {
            url: 'https://csm-e-nineau-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-syd-ch9-hls-r19-b.m3u8',
            yospaceStreamId: '631004438',
            yofb: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/syd/ch9/hls/r2/index.m3u8',
          },
          dash: {
            url: 'https://csm-e-nineaudash-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-syd-ch9-dash-r13-b.mpd',
            yospaceStreamId: '631004545',
            yofb: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/syd/ch9/dash/r2/index.mpd',
          },
        },
      },
    },
    brisbane: {
      primary: {
        akamai: {
          hls: {
            url: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/bne/ch9/hls/r1/index.m3u8',
          },
          dash: {
            url: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/bne/ch9/dash/r1/index.mpd',
          },
        },
        yospace: {
          hls: {
            url: 'https://csm-e-nineau-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-bne-ch9-hls-r13.m3u8',
            yospaceStreamId: '616071589',
            yofb: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/bne/ch9/hls/r1/index.m3u8',
          },
          dash: {
            url: 'https://csm-e-nineaudash-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-bne-ch9-dash-r13.mpd',
            yospaceStreamId: '616071585',
            yofb: 'https://9now-livestreams-v2.akamaized.net/prod/simulcast/bne/ch9/dash/r1/index.mpd',
          },
        },
      },
      backup: {
        akamai: {
          hls: {
            url: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/bne/ch9/hls/r2/index.m3u8',
          },
          dash: {
            url: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/bne/ch9/dash/r2/index.mpd',
          },
        },
        yospace: {
          hls: {
            url: 'https://csm-e-nineau-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-bne-ch9-hls-r13-b.m3u8',
            yospaceStreamId: '632071680',
            yofb: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/bne/ch9/hls/r2/index.m3u8',
          },
          dash: {
            url: 'https://csm-e-nineaudash-eb.tls1.yospace.com/csm/extlive/nnaprd01,prod-simulcast-bne-ch9-dash-r13-b.mpd',
            yospaceStreamId: '632068538',
            yofb: 'https://9now-livestreams-v2-b.akamaized.net/prod/simulcast/bne/ch9/dash/r2/index.mpd',
          },
        },
      },
    },
  },
};

class StreamController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { count: 0, streams: [] },
      markets: [],
      channels: [],
      streamTypes: [STREAM_TYPES.dash, STREAM_TYPES.hls],
      cdns: Object.values(CDNS),
      releaseChannels: Object.values(RELEASE_CHANNELS),
      loadingError: null,
      actionErrorMessage: '',
      actionSuccessMessage: '',
    };
  }

  async componentDidMount() {
    await this.loadMarkets();
    await this.loadChannels();
  }

  loadMarkets = async () => {
    try {
      const { markets } = await listMarkets();
      this.setState({
        markets,
      });
    } catch (e) {
      this.setState({ loadingError: e.message });
    }
  };

  loadChannels = async () => {
    try {
      const { channels } = await listChannels({
        activeOnly: true,
      });
      this.setState({
        channels,
      });
    } catch (e) {
      this.setState({ loadingError: e.message });
    }
  };

  loadData = async (params = {}) => {
    try {
      const data = await listStreams(params);
      data.streams = sortBy(data.streams, [
        'marketSlug',
        'channelSlug',
        'streamType',
        'ssaiEnabled',
        'drmEnabled',
        'fullHdEnabled',
        'tokenisationEnabled',
        'cdn',
        'releaseChannel',
      ]);
      this.setState({ data });
    } catch (e) {
      this.setState({ loadingError: e.message });
    }
  };

  switchPrimaryBackupForChannel9SydneyOrBrisbane = async (
    channelSlug,
    marketSlug,
    primaryOrBackup,
    reason
  ) => {
    this.setState({ actionErrorMessage: '', actionSuccessMessage: '' });
    if (channelSlug !== 'channel-9' || (marketSlug !== 'sydney' && marketSlug !== 'brisbane')) {
      this.setState({
        actionErrorMessage: `Not channel-9(sydney) or channel-9(brisbane): ${channelSlug}(${marketSlug}) ${primaryOrBackup}`,
      });
      return;
    }

    const streams = this.state.data.streams.filter((stream) => {
      return (
        stream.channelSlug === channelSlug &&
        stream.marketSlug === marketSlug &&
        !stream.drmEnabled &&
        !!stream.fullHdEnabled &&
        stream.cdn === 'Akamai'
      );
    });

    if (streams.length !== 4) {
      this.setState({
        actionErrorMessage: 'Unable to find all four streams. Please check the integrity of data.',
      });
      return;
    }

    Promise.all(
      streams.map(async (stream) => {
        const yospaceOrAkamai = stream.ssaiEnabled ? 'yospace' : 'akamai';
        const payload =
          streamPrimaryBackupConfigs[stream.channelSlug][stream.marketSlug][primaryOrBackup][yospaceOrAkamai][
            stream.streamType
          ];
        if (!payload) {
          throw new Error(
            `Payload not found for ${stream.channelSlug}(${stream.marketSlug}) ${primaryOrBackup}/${yospaceOrAkamai}/${stream.streamType}`
          );
        }
        return updateStream(stream.id, {
          ...payload,
          reason,
          feature: primaryOrBackup,
          toggleOn: true,
        });
      })
    )
      .then(() => {
        this.loadData();
        this.setState({
          actionSuccessMessage: `${channelSlug}(${marketSlug}) has been successfully switched to ${primaryOrBackup}.`,
        });
      })
      .catch((e) => {
        const message =
          e.message || (e.responseJSON && e.responseJSON.message) || 'An unknown error occurred.';
        this.setState({ actionErrorMessage: message });
      });
  };

  render = () => (
    <ViewLoader errorMessage={this.state.loadingError}>
      <StreamView
        actionSuccessMessage={this.state.actionSuccessMessage}
        actionErrorMessage={this.state.actionErrorMessage}
        data={this.state.data}
        listStreams={this.loadData}
        markets={this.state.markets}
        channels={this.state.channels}
        streamTypes={this.state.streamTypes}
        cdns={this.state.cdns}
        releaseChannels={this.state.releaseChannels}
        switchPrimaryBackupForChannel9SydneyOrBrisbane={this.switchPrimaryBackupForChannel9SydneyOrBrisbane}
      />
    </ViewLoader>
  );
}

export default StreamController;
