import _ from 'lodash';
import moment from 'moment';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import { getAvailableBulkWorkflowActions, isEditEnabled } from 'src/scripts/lib/workflowActionsProvider';
import { vmsApiActionEndpointMap as tvVmsApiActionEndpointMap } from 'src/scripts/actions/workflow/tvWorkflow.js';
import { vmsApiActionEndpointMap as networkVmsApiActionEndpointMap } from 'src/scripts/actions/workflow/networkWorkflow.js';
import { doBulkAction } from 'src/scripts/actions/bulkAction';

import {
  BULK_ACTION_EPISODE_UPDATED,
  BULK_ACTION_CLIP_UPDATED,
  BULK_ACTION_NETWORK_CLIP_UPDATED,
} from 'src/scripts/actions/bulkAction';

const canDownloadVideoFromS3 = (videoItem) =>
  videoItem.videoFileName !== null && videoItem.s3SourceFolder !== null;
export const canDownloadEpisodeVideoFromS3 = (episode) => canDownloadVideoFromS3(episode.video);
export const canDownloadClipVideoFromS3 = (clip) => canDownloadVideoFromS3(clip.video);

export function isEditAvailable(selectedItems) {
  return _.every(selectedItems, (videoItem) => {
    return isEditEnabled(videoItem);
  });
}

export function isItemInBulkActionQueue(
  bulkActionStatus,
  actionType,
  bulkActionResourceType,
  unprocessed,
  itemResourceType,
  item
) {
  const idToCheck = actionType === 'transcode' ? item.videoId : item.id;
  return (
    bulkActionStatus === 'IN_PROGRESS' &&
    bulkActionResourceType === itemResourceType &&
    _.includes(unprocessed, idToCheck)
  );
}

function isVideoExpired(videoItem) {
  const rightNow = moment();
  return videoItem.expiry && moment(videoItem.expiry).isBefore(rightNow);
}

function executeSingleWorkflowAction(action, modelName) {
  return (id, onSuccess, onError) => {
    const workflowActionOnSuccess = (res) => {
      return onSuccess.call(null, res);
    };
    if (modelName === 'networkClip') {
      return networkVmsApi
        .notifyWorkflowAction(networkVmsApiActionEndpointMap[action], id)
        .then(workflowActionOnSuccess, onError);
    }
    return tvVmsApi
      .notifyWorkflowAction(tvVmsApiActionEndpointMap[action], modelName.toLowerCase(), id)
      .then(workflowActionOnSuccess, onError);
  };
}

export function executeBulkWorkflowAction(action, resourceIds, modelName) {
  const successActionMap = {
    clip: BULK_ACTION_CLIP_UPDATED,
    episode: BULK_ACTION_EPISODE_UPDATED,
    networkClip: BULK_ACTION_NETWORK_CLIP_UPDATED,
  };
  return doBulkAction(
    resourceIds,
    executeSingleWorkflowAction(action, modelName),
    action,
    modelName,
    successActionMap[modelName]
  );
}

export function isBrightcoveVideoCloudRetranscodeAvailable(modelName, selectedVideos) {
  const illegalVideoStatuses = [
    'NEW',
    'UPLOADED',
    'CREATING_IN_BRIGHTCOVE',
    'CREATED_IN_BRIGHTCOVE',
    'TRANSCODING',
  ];
  if (modelName === 'networkClips') {
    return _.every(selectedVideos, (videoItem) => {
      return (
        !_.includes(illegalVideoStatuses, videoItem.video.status) &&
        videoItem.video.videoFileName &&
        !isVideoExpired(videoItem)
      );
    });
  }
  return _.every(selectedVideos, (videoItem) => {
    return (
      !_.includes(illegalVideoStatuses, videoItem.video.status) &&
      videoItem.video.videoFileName &&
      _.includes(getAvailableBulkWorkflowActions(selectedVideos), 'edit') &&
      !isVideoExpired(videoItem)
    );
  });
}
