import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  getNetworkClips,
  deleteNetworkClipAndCloseConfirmation,
  saveNetworkClip,
} from 'src/scripts/actions/networkClip';
import { updateCategoryBreadcrumb, updateSubcategoryBreadcrumb } from 'src/scripts/actions/breadcrumb';
import * as pagination from 'src/scripts/lib/pagination';
import history from 'src/scripts/lib/history';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import NetworkClipFormLoader from 'src/scripts/components/NetworkClip/NetworkClipFormLoader';
import FileDropZone from 'src/scripts/components/FileDropZone';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import { getNetworkClipWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import { NETWORK_CLIP } from 'src/scripts/lib/modelNames';
import CopyButton from 'src/scripts/components/CopyButton';
import { formatClipPropertiesToCopy } from 'src/scripts/lib/stringFormatter';
import { getSubcategoryById } from 'src/scripts/actions/subcategory';
import { resetItemList } from 'src/scripts/actions/itemList';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';

const { VIEW, EDIT, RESCHEDULE, RETRANSCODE, EXPIRE, ARCHIVE, DELETE } = BulkActionButtonTypes;
const { CLEAR } = ActionButtonTypes;

export class NetworkClip extends React.Component {
  constructor(props) {
    super(props);
    this.getNetworkClips = this.getNetworkClips.bind(this);
    this.onFileDropped = this.onFileDropped.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.page !== prevProps.page ||
      (this.props.savedClip && this.props.savedClip.subcategoryId === Number(this.props.params.subcategoryId))
    ) {
      this.getNetworkClips();
    }
  }

  onFileDropped(file) {
    const clip = {
      name: file.name,
      subcategoryId: Number(this.props.params.subcategoryId),
      availability: new Date(),
      geoBlocking: this.props.subcategory.geoBlocked,
      video: { file },
      requireReview: true,
    };
    return this.props.saveNetworkClip(clip);
  }

  getNetworkClips(params = {}) {
    const subcategoryId = Number(this.props.params.subcategoryId);
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    return this.props.getNetworkClips({
      subcategoryId,
      offset,
      limit: pagination.pageSize,
      sort: params.sort || this.props.sort,
      filters: params.filters || this.props.filters,
    });
  }

  loadData() {
    const categoryId = Number(this.props.params.id);
    const subcategoryId = Number(this.props.params.subcategoryId);

    if (!categoryId) {
      return history.replace({ state: null, pathname: '/network/categories', query: { page: 1 } });
    }
    if (!subcategoryId) {
      return history.replace({
        state: null,
        pathname: `/network/categories/${categoryId}/subcategories`,
        query: { page: 1 },
      });
    }

    this.props.getSubcategoryById(subcategoryId);
    this.props.updateCategoryBreadcrumb(categoryId);
    this.props.updateSubcategoryBreadcrumb(subcategoryId);
    return null;
  }

  clearFilters() {
    return this.props.resetItemList();
  }

  render() {
    const list = {
      pageData: this.props.clips,
      totalCount: this.props.count,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };
    const breadcrumbs = [
      <a href="/#/network/categories">Categories</a>,
      <a href={`/#/network/categories/${this.props.params.id}/subcategories`}>{this.props.categoryName}</a>,
      this.props.subcategoryName,
    ];
    const getViewClipHref = (clip) => {
      return `#/network/categories/${clip.partOfCategory.id}/subcategories/${clip.partOfSubcategory.id}/clips/${clip.id}`;
    };
    const getActionList = (clip, rowIndex) => {
      return (
        <WorkflowActions
          getWorkflowActions={getNetworkClipWorkflowActions}
          model={clip}
          resource="networkClip"
          editForm={<NetworkClipFormLoader clipId={Number(clip.id)} />}
          delete={this.props.deleteNetworkClipAndCloseConfirmation.bind(this, clip.id)}
          save={this.props.saveNetworkClip}
          viewLink={getViewClipHref(clip)}
          index={rowIndex}
        />
      );
    };

    return (
      <FileDropZone onDrop={this.onFileDropped} fileTypePrefix="video">
        <ContextNavigation breadcrumbList={breadcrumbs} />
        <ItemList
          id="list-network-clip"
          list={list}
          getItems={this.getNetworkClips}
          defaultSort="-createdAt"
          modelName={NETWORK_CLIP}
          bulkActionButtons={[VIEW, EDIT, RESCHEDULE, RETRANSCODE, EXPIRE, ARCHIVE, DELETE]}
          actionButtons={[CLEAR]}
          clearFilters={this.clearFilters}
        >
          <ItemListHeader
            label="Image"
            dataField="image"
            dataFormat={(clip) => {
              return (
                <span className="row-image-span">
                  <img
                    ref="img"
                    className="row-image"
                    src={(clip.image && clip.image.url) || IMAGE_NOT_FOUND_URL}
                    alt={(clip.image && clip.image.alt) || ''}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader
            sort
            filter
            label="Name"
            dataField="name"
            dataFormat={(clip) => {
              return <a href={getViewClipHref(clip)}>{clip.name}</a>;
            }}
          />
          <ItemListHeader label="Duration" dataField="video.duration" dataType="duration" />
          <ItemListHeader label="Workflow Status" dataField="state" dataType="humanize" />
          <ItemListHeader filter dataField="video.brightcoveId" label="Brightcove ID" />
          <ItemListHeader
            sort
            dataField="availability"
            label="Start Date"
            dataType="date"
            filter={{ type: 'date' }}
          />
          <ItemListHeader
            sort
            dataField="expiry"
            label="End Date"
            dataType="date"
            filter={{ type: 'date' }}
          />
          <ItemListHeader sort label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader
            label="Copy to Clipboard"
            dataFormat={(clipItem) => {
              const textToCopy = formatClipPropertiesToCopy(clipItem);
              return <CopyButton textToCopy={textToCopy} />;
            }}
          />
          <ItemListHeader label="Actions" dataFormat={(clip, rowIndex) => getActionList(clip, rowIndex)} />
        </ItemList>
      </FileDropZone>
    );
  }
}

NetworkClip.propTypes = {
  params: PropTypes.object,
  page: PropTypes.number,
  clips: PropTypes.array,
  offset: PropTypes.number,
  count: PropTypes.number,
  errorMessage: PropTypes.string,
  categoryName: PropTypes.string,
  subcategoryName: PropTypes.string,
  sort: PropTypes.string,
  filters: PropTypes.object,
  getNetworkClips: PropTypes.func,
  deleteNetworkClipAndCloseConfirmation: PropTypes.func,
  updateCategoryBreadcrumb: PropTypes.func,
  updateSubcategoryBreadcrumb: PropTypes.func,
  saveNetworkClip: PropTypes.func,
  savedClip: PropTypes.object,
  getSubcategoryById: PropTypes.func,
  subcategory: PropTypes.object,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    clips: state.networkClips.clips,
    offset: state.networkClips.offset,
    count: state.networkClips.count,
    errorMessage: state.networkClips.errorMessage,
    categoryName: state.breadcrumb.categoryName,
    subcategoryName: state.breadcrumb.subcategoryName,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
    savedClip: state.networkClips.savedClip,
    subcategory: state.networkClips.subcategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getNetworkClips: (pageData) => dispatch(getNetworkClips(pageData)),
    getSubcategoryById: (subcategoryId) => dispatch(getSubcategoryById(subcategoryId)),
    deleteNetworkClipAndCloseConfirmation: (networkClipId) =>
      dispatch(deleteNetworkClipAndCloseConfirmation(networkClipId)),
    updateCategoryBreadcrumb: (categoryId) => dispatch(updateCategoryBreadcrumb(categoryId)),
    updateSubcategoryBreadcrumb: (subcategoryId) => dispatch(updateSubcategoryBreadcrumb(subcategoryId)),
    saveNetworkClip: (clip) => dispatch(saveNetworkClip(clip)),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkClip);
