import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ButtonToolbar, Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import PapaParse from 'papaparse';
import { ViewLoader } from '../../ViewLoader.jsx';
import { fileReader } from 'src/scripts/lib/browser';
import { createOlympicLiveEvent } from '../../../actions/apiWrappers/vmsApi/common/liveEvents.js';
import { getGenres } from '../../../actions/apiWrappers/vmsApi/tv.js';
import {
  getLiveEventsMetadataFromCsvData,
  CSV_TABLE_HEADER_OFFSET,
  INVALID_CSV_FILE_CONTENT_ERROR,
  INVALID_FILE_ERROR,
  FETCH_GENRE_ERROR,
  getColumnNameToIndicesMap,
  isCsvFileHeaderValid,
  validateLiveEventMetadata,
} from './utils.js';

const INITIAL_STATE = {
  errors: [],
  isLoading: false,
  invalidFileError: null,
  liveEventsToUpload: [],
  selectedFile: {},
  uploadFinished: false,
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};
export class CsvImportLiveEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      fetchGenreError: null,
      genresMap: {},
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isLoading: true });

    getGenres().then(
      (data) => {
        const genresMap = {};
        data.genres.forEach((genre) => {
          genresMap[genre.name.toLowerCase().trim()] = genre.id;
        });
        this.setState({ genresMap, isLoading: false });
      },
      () => {
        this.setState({ fetchGenreError: FETCH_GENRE_ERROR, isLoading: false });
      }
    );
  }

  uploadLiveEvents = async () => {
    if (
      this.state.liveEventsToUpload.length === 0 ||
      this.state.fetchGenreError ||
      this.state.invalidFileError
    ) {
      return;
    }
    this.setState({ isLoading: true });
    const schemaValidationErrors = validateLiveEventMetadata(this.state.liveEventsToUpload);
    if (schemaValidationErrors.length > 0) {
      this.setState({ errors: schemaValidationErrors });
    } else {
      const liveEventsToUpload = this.state.liveEventsToUpload;
      const liveEventPromises = liveEventsToUpload.map((liveEvent) => createOlympicLiveEvent(liveEvent));
      const results = await Promise.allSettled(liveEventPromises);
      const errors = results
        .filter((result) => result.status === 'rejected')
        .map((result, index) => ({
          index,
          status: result.status,
          externalId: liveEventsToUpload[index].externalId,
          error:
            (result.reason && result.reason.responseJSON && result.reason.responseJSON.message) ||
            `Error: live event external Id:${liveEventsToUpload[index].externalId}`,
        }));
      this.setState({ uploadFinished: true, errors });
    }
    this.setState({ isLoading: false });
  };

  updateLiveEventmetadataGenreIds = (liveEventsMetadata) => {
    const getGenreId = (genreName) => {
      if (!genreName) {
        return null;
      }
      return this.state.genresMap[genreName.toLowerCase().trim()] || null;
    };

    liveEventsMetadata.forEach((liveEventMetadata) => {
      if (liveEventMetadata.genreName) {
        liveEventMetadata.genreId = getGenreId(liveEventMetadata.genreName);
      }
      if (liveEventMetadata.upNextEvent.genreName) {
        liveEventMetadata.upNextEvent.genreId = getGenreId(liveEventMetadata.upNextEvent.genreName);
      }
    });
  };

  onDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length > 0) {
      this.setState({ invalidFileError: INVALID_FILE_ERROR });
      return;
    }

    if (rejectedFiles.length === 0 && this.state.invalidFileError) {
      this.setState({ invalidFileError: null });
    }

    if (this.state.selectedFile && this.state.selectedFile.name !== acceptedFiles[0].name) {
      this.setState(INITIAL_STATE);
    }

    const reader = fileReader();

    reader.onload = (event) => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(papaparseOptions, {
          encoding: 'UTF-8',
        })
      );
      const csvHeader = csvData.data[1];
      const columnNameToIndicesMap = getColumnNameToIndicesMap(csvHeader);
      const isCsvFileDataValid = isCsvFileHeaderValid(columnNameToIndicesMap.mandatoryfields);
      if (!isCsvFileDataValid) {
        this.setState({ invalidFileError: INVALID_CSV_FILE_CONTENT_ERROR });
      } else {
        const firstRowNumberOfCsvInputData = 2;
        const inputData = csvData.data.slice(firstRowNumberOfCsvInputData);
        const liveEventsMetadata = getLiveEventsMetadataFromCsvData(columnNameToIndicesMap, inputData);
        this.updateLiveEventmetadataGenreIds(liveEventsMetadata);
        this.setState({ liveEventsToUpload: liveEventsMetadata, selectedFile: acceptedFiles[0] });
      }
    };
    reader.readAsText(acceptedFiles[0], 'UTF-8');
  }

  render() {
    const disableUserAction = this.state.fetchGenreError || this.state.isLoading;
    return (
      <div>
        <Modal show backdrop="static">
          <Modal.Header className="modal-title">
            <Modal.Title>CSV Bulk Event Import</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.uploadFinished && (
              <section className="csv-import-live-events">
                <div className="modal-header-title">
                  <h4>Import Options</h4>
                </div>
                <Dropzone
                  accept=".csv"
                  multiple={false}
                  onDrop={this.onDrop.bind(this)}
                  disabled={disableUserAction}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="select-file-container" {...getRootProps()}>
                      <input aria-label="csv-file-input" {...getInputProps()} disabled={disableUserAction} />
                      <span>Or Drop file here</span>
                    </section>
                  )}
                </Dropzone>
                <section className="selected-file-info-container">
                  <p>Supported file types: CSV</p>
                  <p>Limit of 50 rows</p>
                </section>
                {this.state.invalidFileError && (
                  <span className="supported-files-error">{this.state.invalidFileError}</span>
                )}
              </section>
            )}
            {this.state.uploadFinished && <h4>Import Complete</h4>}
            <section className="csv-import-selected-file-contaier">
              <p className="selected-file">{this.state.selectedFile.name}</p>
              {this.state.errors.length > 0 && (
                <section className="errors">
                  {this.state.errors.map((error) => (
                    <span key={error.externalId}>
                      {`Row - ${CSV_TABLE_HEADER_OFFSET + error.index} with external Id: ${
                        error.externalId
                      } has errors: ${error.error}`}
                    </span>
                  ))}
                </section>
              )}
            </section>
          </Modal.Body>
          <Modal.Footer>
            {this.state.uploadFinished && (
              <span className="successful-message">
                {`${this.state.liveEventsToUpload.length - this.state.errors.length} of ${
                  this.state.liveEventsToUpload.length
                } events updated`}
              </span>
            )}
            <ViewLoader loading={this.state.isLoading}>
              {this.state.uploadFinished && (
                <section className="successful-message-container">
                  <span className="successful-tick">
                    <i className="fa fa-check-circle"></i>
                  </span>
                </section>
              )}
            </ViewLoader>
            {this.state.fetchGenreError && (
              <section className="footer-error-message-container">
                <span>{this.state.fetchGenreError}</span>
              </section>
            )}
            <ButtonToolbar className="pull-right toolbar-buttons">
              <Button onClick={() => this.props.onCancel()}>Close</Button>
              {!this.state.uploadFinished && (
                <Button
                  bsStyle="primary"
                  onClick={() => this.uploadLiveEvents()}
                  disabled={disableUserAction}
                >
                  Import
                </Button>
              )}
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CsvImportLiveEvents.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default CsvImportLiveEvents;
