import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ViewLoader from 'src/scripts/components/ViewLoader';
import RoleForm from 'src/scripts/components/Admin/internal/RoleForm';
import { getRoleFormInitialData } from 'src/scripts/actions/role';

export class RoleFormLoader extends React.Component {
  componentDidMount() {
    this.props.getRoleFormInitialData(this.props.roleId);
  }

  render() {
    return (
      <ViewLoader loading={!this.props.initialFormDataLoaded}>
        <RoleForm close={this.props.close} />
      </ViewLoader>
    );
  }
}

RoleFormLoader.propTypes = {
  roleId: PropTypes.number,
  initialFormDataLoaded: PropTypes.bool,
  getRoleFormInitialData: PropTypes.func,
  close: PropTypes.func,
};

function mapStateToProps(state) {
  const roleForm = state.roleForm;
  return {
    initialFormDataLoaded: roleForm.initialFormDataLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoleFormInitialData: (roleId) => dispatch(getRoleFormInitialData(roleId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleFormLoader);
