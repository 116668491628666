import React from 'react';
import PropTypes from 'prop-types';
import { Input, Panel } from 'react-bootstrap';
import { NETWORK } from 'src/scripts/lib/libraries';

export class SeoMetadataForClips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  onSeoTitleChange = (event) => {
    this.props.updateClip({
      seoTitle: event.target.value,
    });
  };

  onUrlTitleChange = (event) => {
    this.props.updateClip({
      urlTitle: event.target.value,
    });
  };

  onHeadlineChange = (event) => {
    this.props.updateClip({
      headline: event.target.value,
    });
  };

  onLongDescriptionChange = (event) => {
    this.props.updateClip({
      longDescription: event.target.value,
    });
  };

  renderCollapsibleIcon = () => {
    return (
      <div className="seo-header" onClick={this.onClick}>
        Seo Metadata
        <div className="collapsible">
          {this.state.open ? (
            <i className="fa fa-minus-circle" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <Panel id="panel-seo-metadata" header={this.renderCollapsibleIcon()} bsStyle="primary" collapsible>
          <Input
            id="seo-title-input"
            type="text"
            label="SEO Title"
            ref="seoTitle"
            maxLength="256"
            onChange={this.onSeoTitleChange}
            value={this.props.clip.seoTitle}
            placeholder="Enter an SEO Title"
          />
          <Input
            id="url-title-input"
            type="text"
            label="URL Title"
            ref="urlTitle"
            maxLength="256"
            onChange={this.onUrlTitleChange}
            value={this.props.clip.urlTitle}
            placeholder="Enter a URL Title"
          />
          <Input
            id="headline-input"
            type="text"
            label="Headline"
            ref="headline"
            maxLength="256"
            onChange={this.onHeadlineChange}
            value={
              this.props.clipType === NETWORK && this.props.clip.name
                ? this.props.clip.name
                : this.props.clip.headline
            }
            placeholder={
              this.props.clipType === NETWORK && this.props.clip.name
                ? this.props.clip.name
                : 'Enter a headline'
            }
            disabled={this.props.clipType === NETWORK}
          />
          <Input
            id="long-description-input"
            type="textarea"
            label="Long Description"
            ref="longDescription"
            maxLength="3000"
            onChange={this.onLongDescriptionChange}
            value={this.props.clip.longDescription}
            placeholder="Enter a long description"
          />
        </Panel>
      </div>
    );
  }
}

SeoMetadataForClips.propTypes = {
  clip: PropTypes.object,
  updateClip: PropTypes.func,
  clipType: PropTypes.string,
};

export default SeoMetadataForClips;
