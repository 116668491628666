import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  SAVE_CATEGORY_FAILED,
  SAVE_CATEGORY_SUCCEEDED,
  UPDATE_CATEGORY,
  GET_CATEGORY_SUCCEEDED,
  GET_CATEGORY_FAILED,
} from 'src/scripts/actions/category';

import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/categoryForm';

export const initialState = {
  category: {},
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_SUCCEEDED:
      return {
        ...state,
        ...action.data,
      };

    case GET_CATEGORY_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case SAVE_CATEGORY_FAILED:
      return { ...state, errorMessage: action.error };

    case SAVE_CATEGORY_SUCCEEDED:
      return { ...state, errorMessage: null };

    case UPDATE_CATEGORY:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.category, ...action.category },
          getValidationRules()
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          category: {
            ...state.category,
            ...action.category,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        category: {
          ...state.category,
          ...action.category,
        },
      };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.category, getValidationRules());
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
