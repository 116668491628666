import PropTypes from 'prop-types';
import React from 'react';
import LoadingIcon from 'src/scripts/components/LoadingIcon';
import { downloadUrl } from 'src/scripts/lib/downloadFile';

export class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.getMediaSource = this.getMediaSource.bind(this);
    this.state = {
      downloadInitiated: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.signedMediaSourceUrl.url && this.state.downloadInitiated) {
      this.setState({
        downloadInitiated: false,
      });
      this.download(nextProps.signedMediaSourceUrl.url);
    }
  }

  getMediaSource() {
    if (this.props.signedMediaSourceUrl.url) {
      downloadUrl(this.props.signedMediaSourceUrl.url);
    } else {
      this.setState({
        downloadInitiated: true,
      });
      this.props.getSignedMediaSourceUrl();
    }
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-link download-file"
          onClick={this.getMediaSource}
          disabled={this.props.disabled}
        >
          {this.props.fileName}
        </button>
        <LoadingIcon loading={this.state.downloadInitiated} />
        {this.props.signedMediaSourceUrl.errorMessage ? (
          <span className="text-danger">{this.props.signedMediaSourceUrl.errorMessage}</span>
        ) : (
          ''
        )}
      </div>
    );
  }
}

DownloadButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  signedMediaSourceUrl: PropTypes.object.isRequired,
  getSignedMediaSourceUrl: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DownloadButton.defaultProps = {
  disabled: false,
};

export default DownloadButton;
