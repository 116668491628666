import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input, Panel, Button, Alert } from 'react-bootstrap';
import _ from 'lodash';

import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import TvSeriesField from 'src/scripts/components/Collection/internal/EditCollectionForm/TvSeriesField';
import SortableDragDropList from 'src/scripts/components/SortableDragDropList';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import { isFormValid } from 'src/scripts/lib/formValidation';
import { showFormValidation } from 'src/scripts/actions/form';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/collectionForm';
import { updateCollectionForm, saveCollection } from 'src/scripts/actions/collection';
import { showConfirmationModal } from 'src/scripts/actions/confirmation';
import * as util from 'src/scripts/lib/util';
import CollectionUsage from 'src/scripts/components/Collection/internal/EditCollectionForm/CollectionUsage';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import { CUSTOM_COLLECTION_TYPE } from 'src/scripts/lib/collectionTypes';
import {
  getCollectionWorkflow,
  workflowColours,
  hasUnpublishedTvSeriesListChanges,
} from '../../../../lib/collection';

const mapStateToProps = (state) => ({
  validationErrors: state.collectionForm.validationErrors,
  collection: state.collectionForm.collection,
  errorMessage: state.collectionForm.errorMessage,
  tvSeriesUsingCollection: state.collectionForm.tvSeriesUsingCollection,
  tvSeriesToUseCollection: state.collectionForm.tvSeriesToUseCollection,
});

const mapDispatchToProps = (dispatch) => ({
  updateCollectionForm: (collection) => dispatch(updateCollectionForm(collection)),
  saveCollection: (collection, seriesToAddAsRecommendedIds, seriesToRemoveAsRecommendedIds) =>
    dispatch(saveCollection(collection, seriesToAddAsRecommendedIds, seriesToRemoveAsRecommendedIds)),
  showFormValidation: () => dispatch(showFormValidation()),
  showConfirmationModal: (descriptions, onProceed) =>
    dispatch(showConfirmationModal(descriptions, onProceed)),
});

export class EditCollectionForm extends Component {
  constructor() {
    super();
    this.state = {
      highlightedTvSeries: null,
      selectedTvSeries: null,
      tvSeriesSelectRef: null,
      initialCollectionDraftSeries: null,
    };
  }

  componentWillMount() {
    this.setState({ initialCollectionDraftSeries: this.props.collection.draftTvSeries });
  }

  onNameChange = (event) => this.props.updateCollectionForm({ name: event.target.value });

  onProgrammingNotesChange = (event) =>
    this.props.updateCollectionForm({ programmingNotes: event.target.value });

  onRevert = () =>
    this.props.updateCollectionForm({
      draftTvSeries: this.props.collection.tvSeries,
    });

  onUndoDraftTvSeriesChanges = () =>
    this.props.updateCollectionForm({
      draftTvSeries: this.state.initialCollectionDraftSeries,
    });

  onAddSeriesClick = () => {
    this.addTvSeriesToCollectionForm(this.state.selectedTvSeries)
      .then(this.scrollToEndOfList)
      .then(this.removeTvSeriesHighlighting)
      .then(this.removeSelectedTvSeries)
      .then(this.clearTvSeriesSelectInput);
  };

  getTvSeriesByIdAsPromise = (tvSeriesId, includeActiveEpisodeStates) =>
    new Promise((resolve, reject) => {
      const params = { includeActiveEpisodeStates };
      return tvVmsApi.getTVSeriesById(tvSeriesId, params).then(({ tvSeries }) => resolve(tvSeries), reject);
    });

  isTvSeriesInCollectionForm = (tvSeries) => {
    return Boolean(_.find(this.props.collection.draftTvSeries, (item) => item.id === tvSeries.id));
  };

  moveTvSeriesItem = (sourceIdx, targetIdx) => {
    const updatedTvSeriesList = util.moveItemInList(
      this.props.collection.draftTvSeries,
      sourceIdx,
      targetIdx
    );
    this.props.updateCollectionForm({
      ...this.props.collection,
      draftTvSeries: updatedTvSeriesList,
    });
  };

  removeTvSeriesItem = (itemIdx) => {
    const updatedTvSeriesList = util.removeItemInList(this.props.collection.draftTvSeries, itemIdx);
    this.props.updateCollectionForm({
      ...this.props.collection,
      draftTvSeries: updatedTvSeriesList,
    });
  };

  addTvSeriesHighlighting = (tvSeriesId) => {
    return new Promise((resolve) => {
      this.setState(
        () => ({
          highlightedTvSeries: tvSeriesId,
        }),
        resolve
      );
    });
  };

  removeTvSeriesHighlighting = () => {
    return new Promise((resolve) => {
      this.setState(
        () => ({
          highlightedTvSeries: null,
        }),
        resolve
      );
    });
  };

  removeSelectedTvSeries = () => {
    return new Promise((resolve) => {
      this.setState(
        () => ({
          selectedTvSeries: null,
        }),
        resolve
      );
    });
  };

  addTvSeriesItem = (item) => {
    const updatedTvSeriesList = this.props.collection.draftTvSeries.concat(item);
    this.props.updateCollectionForm({
      ...this.props.collection,
      draftTvSeries: updatedTvSeriesList,
    });
  };

  isNameFieldEditable = () => {
    switch (this.props.collection.type) {
      case 'GENRE':
        return false;
      case 'DEFAULT':
        return false;
      default:
        return true;
    }
  };

  getSeriesToRemoveAsRecommended = () => {
    const seriesToRemove = this.props.tvSeriesUsingCollection.filter(
      (usingSeries) =>
        !this.props.tvSeriesToUseCollection.find((toUseSeries) => usingSeries.id === toUseSeries.id)
    );
    return seriesToRemove;
  };

  getSeriesToAddAsRecommended = () => {
    const seriesToAdd = this.props.tvSeriesToUseCollection.filter(
      (toUseSeries) =>
        !this.props.tvSeriesUsingCollection.find((usingSeries) => toUseSeries.id === usingSeries.id)
    );
    return seriesToAdd;
  };

  saveCollectionAndGetCollections = () => {
    if (!isFormValid(this.props.collection, getValidationRules())) {
      this.props.showFormValidation();
      return Promise.resolve();
    }
    const collectionFormattedForApi = {
      ...this.props.collection,
      draftTvSeriesIds: this.props.collection.draftTvSeries.map((tvSeries) => tvSeries.id),
    };
    delete collectionFormattedForApi.tvSeries; // We delete the nested TV Series etc, otherwise the request is uneccessarily massive.
    delete collectionFormattedForApi.draftTvSeries; // We delete the nested TV Series etc, otherwise the request is uneccessarily massive.
    const seriesToAddAsRecommendedIds = this.getSeriesToAddAsRecommended().map((series) => series.id);
    const seriesToRemoveAsRecommendedIds = this.getSeriesToRemoveAsRecommended().map((series) => series.id);
    return this.props
      .saveCollection(collectionFormattedForApi, seriesToAddAsRecommendedIds, seriesToRemoveAsRecommendedIds)
      .then(() => this.props.afterUpdate());
  };

  scrollToHighlightedTvSeries = () => {
    for (const tvSeriesId in this.tvSeriesFieldRefs) {
      if (Number(tvSeriesId) === this.state.highlightedTvSeries) {
        if (this.tvSeriesFieldRefs.hasOwnProperty(tvSeriesId)) {
          this.SortableDragDropListRef.scrollTop = this.tvSeriesFieldRefs[tvSeriesId].offsetTop;
        }
      }
    }
  };

  getCollectionUpdatesWarning = () => {
    const seriesToAddAsRecommended = this.getSeriesToAddAsRecommended();
    const seriesToAddWithExistingRecommendedCollection = seriesToAddAsRecommended.filter(
      (series) => series.recommendedCollectionId
    );
    const seriesToAddWithNoExistingRecommendedCollection = seriesToAddAsRecommended.filter(
      (series) => !series.recommendedCollectionId
    );
    const seriesToRemoveAsRecommended = this.getSeriesToRemoveAsRecommended();

    const renderWarning = (warningText, seriesArray) => {
      return (
        <div>
          <strong>{warningText}</strong>
          <ul>
            {seriesArray.map((series) => (
              <li>{series.name}</li>
            ))}
          </ul>
        </div>
      );
    };

    const getSeriesToRemoveWarning = () => {
      if (seriesToRemoveAsRecommended.length) {
        return renderWarning('Removed this custom collection from:', seriesToRemoveAsRecommended);
      }
      return null;
    };

    const getSeriesToAddWithExistingRecommendedCollectionWarning = () => {
      if (seriesToAddWithExistingRecommendedCollection.length > 0) {
        return renderWarning(
          'Overwrote the recommended collection for, and added this custom collection to:',
          seriesToAddWithExistingRecommendedCollection
        );
      }
      return null;
    };

    const getSeriesToAddWithNoExistingRecommendedCollectionWarning = () => {
      if (seriesToAddWithNoExistingRecommendedCollection.length > 0) {
        return renderWarning(
          'Added this custom collection to:',
          seriesToAddWithNoExistingRecommendedCollection
        );
      }
      return null;
    };

    if (seriesToAddAsRecommended.length || seriesToRemoveAsRecommended.length) {
      return (
        <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
          {getSeriesToRemoveWarning()}
          {getSeriesToAddWithExistingRecommendedCollectionWarning()}
          {getSeriesToAddWithNoExistingRecommendedCollectionWarning()}
        </div>
      );
    }
    return null;
  };

  getConfirmationText = () => {
    return (
      <div>
        <p>Are you sure you would like to save your changes and update this Collection?</p>
        {this.getCollectionUpdatesWarning()}
      </div>
    );
  };

  showConfirmationAndHandleSave = () => {
    this.props.showConfirmationModal(this.getConfirmationText(), this.saveCollectionAndGetCollections);
  };

  addTvSeriesToCollectionForm = async (tvSeriesId) => {
    const includeActiveEpisodeStates = true;
    const tvSeries = await this.getTvSeriesByIdAsPromise(tvSeriesId, includeActiveEpisodeStates);
    if (this.isTvSeriesInCollectionForm(tvSeries)) {
      await this.addTvSeriesHighlighting(tvSeries.id);
      await this.scrollToHighlightedTvSeries();
      throw new Error(`TV Series ${tvSeries.name} already exists in this list`);
    }
    return this.addTvSeriesItem(tvSeries);
  };

  hasAtLeastOneTvSeries = () => {
    return this.props.collection.draftTvSeries.length;
  };

  hasUnsavedTvSeriesListChanges = () => {
    const initialSeriesIds = this.state.initialCollectionDraftSeries.map((series) => series.id);
    const currentSeriesIds = this.props.collection.draftTvSeries.map((series) => series.id);
    return !_.isEqual(initialSeriesIds, currentSeriesIds);
  };

  isRevertable = () => {
    return hasUnpublishedTvSeriesListChanges(this.props.collection);
  };

  isUndoable = () => {
    return this.hasUnsavedTvSeriesListChanges();
  };

  changeSelectedTvSeries = (tvSeriesId) => {
    this.setState({ selectedTvSeries: tvSeriesId });
  };

  clearTvSeriesSelectInput = () => {
    this.tvSeriesSelectRef.clearTvSeries();
  };

  scrollToEndOfList = () => {
    this.SortableDragDropListRef.scrollTop = this.SortableDragDropListRef.scrollHeight;
  };

  collectTvSeriesFieldRefs = (tvSeriesId, ref) => {
    if (!this.tvSeriesFieldRefs) {
      this.tvSeriesFieldRefs = {};
    }
    this.tvSeriesFieldRefs[tvSeriesId] = ref;
  };

  captureSortableDragDropListRef = (ref) => {
    this.SortableDragDropListRef = ref;
  };

  captureTvSeriesSelectRef = (ref) => {
    this.tvSeriesSelectRef = ref;
  };

  handleDragDropCardRender = (index, card) => (
    <TvSeriesField
      domRef={(ref) => this.collectTvSeriesFieldRefs(card.id, ref)}
      className={this.state.highlightedTvSeries === card.id ? 'highlighted' : ''}
      rank={index}
      name={card.name}
      activeEpisodeCount={card.activeEpisodeCount}
      isFutureActive={card.isFutureActive}
      onRemove={() => {
        if (this.state.highlightedTvSeries === card.id) this.removeTvSeriesHighlighting();
        this.removeTvSeriesItem(index);
      }}
    />
  );

  renderAddSeriesField = () => (
    <div className="collection-form-add-series-field">
      <div className="tv-series-select">
        <TVSeriesSelect
          ref={this.captureTvSeriesSelectRef}
          hideHeader
          required={false}
          onTVSeriesChange={this.changeSelectedTvSeries}
        />
      </div>
      <div className="add-btn" onClick={this.onAddSeriesClick}>
        <i className="add-btn-icon fa fa-plus" aria-hidden="true"></i>
      </div>
    </div>
  );

  renderNameField = () => (
    <Input
      disabled={!this.isNameFieldEditable()}
      labelClassName="required"
      type="text"
      label="Collection Name"
      placeholder="Collection name"
      maxLength="100"
      onChange={this.onNameChange}
      value={this.props.collection.name}
      bsStyle={this.props.validationErrors.name && 'error'}
    />
  );

  renderProgrammingNotesField = () => (
    <Input
      type="textarea"
      label="Programming Notes"
      placeholder="Programming notes"
      maxLength="2000"
      onChange={this.onProgrammingNotesChange}
      value={this.props.collection.programmingNotes}
    />
  );

  renderTypeField = () => (
    <Input
      disabled
      type="text"
      label="Collection Type"
      placeholder="Collection Type"
      maxLength="100"
      value={this.props.collection.type}
      bsStyle={this.props.validationErrors.name && 'error'}
    />
  );

  renderEmptyTvSeriesAlert = () => (
    <Alert bsStyle="warning">There are currently no TV Series in this Collection.</Alert>
  );

  renderAlreadyExistsError = () => (
    <Alert bsStyle="warning">The TV Series you are trying to add already exists in the list.</Alert>
  );

  renderDraggableDraftTvSeriesFields = () => {
    const collectionWorkflow = getCollectionWorkflow(
      this.props.collection,
      this.hasUnsavedTvSeriesListChanges()
    );
    const collectionWorkflowColor = workflowColours[collectionWorkflow];
    return (
      <Panel
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>TV Series List</div>
            <div
              style={{
                padding: '3px 10px',
                fontSize: '14px',
                borderRadius: '4px',
                backgroundColor: collectionWorkflowColor,
              }}
            >
              {collectionWorkflow}
            </div>
          </div>
        }
        bsStyle="primary"
        className={hasUnpublishedTvSeriesListChanges(this.props.collection) ? 'bg-light-grey' : ''}
      >
        {this.hasAtLeastOneTvSeries() ? (
          <SortableDragDropList
            domRef={this.captureSortableDragDropListRef}
            list={this.props.collection.draftTvSeries}
            renderCard={this.handleDragDropCardRender}
            moveCard={this.moveTvSeriesItem}
          />
        ) : (
          this.renderEmptyTvSeriesAlert()
        )}
        {this.renderAddSeriesField()}
        {this.state.highlightedTvSeries && this.renderAlreadyExistsError()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '10px' }}>
          {this.isRevertable() ? <Button onClick={this.onRevert}>Revert to Published</Button> : null}
          {this.isUndoable() ? <Button onClick={this.onUndoDraftTvSeriesChanges}>Undo Changes</Button> : null}
        </div>
      </Panel>
    );
  };

  renderCloseAndSaveButtons = () => (
    <div className="modal-footer">
      <Button type="button" className="form__button collection-form-close-btn" onClick={this.props.close}>
        Close
      </Button>
      <Button
        className="form__button collection-form-save-btn"
        bsStyle="primary"
        onClick={this.showConfirmationAndHandleSave}
      >
        Save
      </Button>
    </div>
  );

  renderSaveCollectionError = () => (
    <FormErrorMessage className="collection-form-save-error" message={this.props.errorMessage} />
  );

  renderCollectionUsage = () => {
    if (this.props.collection.type === CUSTOM_COLLECTION_TYPE) {
      return <CollectionUsage collectionId={this.props.collection.id} />;
    }
    return null;
  };

  render() {
    return (
      <div className="collection-form">
        <form className="form" ref="categoryForm" data-pw="edit-collection-form">
          {this.renderNameField()}
          {this.renderTypeField()}
          {this.renderProgrammingNotesField()}
          {this.renderDraggableDraftTvSeriesFields()}
          {this.renderCollectionUsage()}
          {this.renderCloseAndSaveButtons()}
          {this.props.errorMessage && this.renderSaveCollectionError()}
        </form>
      </div>
    );
  }
}

EditCollectionForm.propTypes = {
  updateCollectionForm: PropTypes.func,
  collection: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    programmingNotes: PropTypes.string,
    tvSeries: PropTypes.array.isRequired,
    draftTvSeries: PropTypes.array.isRequired,
  }),
  tvSeriesUsingCollection: PropTypes.array,
  tvSeriesToUseCollection: PropTypes.array,
  validationErrors: PropTypes.object,
  saveCollection: PropTypes.func,
  close: PropTypes.func,
  errorMessage: PropTypes.string,
  showFormValidation: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  afterUpdate: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCollectionForm);
