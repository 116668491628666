import 'babel-polyfill';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, Redirect } from 'react-router';
import history from 'src/scripts/lib/history';
import * as userActions from 'src/scripts/actions/user';
import App from 'src/scripts/components/App';
import TV from 'src/scripts/containers/TV';
import LIVE from 'src/scripts/containers/LIVE';
import ADMIN from 'src/scripts/containers/ADMIN';
import NETWORK from 'src/scripts/containers/NETWORK';
import EpisodeList from 'src/scripts/components/Video/EpisodeList';
import ClipList from 'src/scripts/components/Video/ClipList';
import NetworkClipList from 'src/scripts/components/Video/NetworkClipList';
import IngestJobList from 'src/scripts/components/Video/IngestJobList';
import TvSeries from 'src/scripts/components/TVSeries';
import TVSeriesDetailsView from './components/TVSeries/TVSeriesDetailsView';
import Season from 'src/scripts/components/Season';
import SeasonDetails from 'src/scripts/components/Season/DetailsView';
import Episode from 'src/scripts/components/Episode';
import EpisodeDetailsViewLoader from 'src/scripts/components/Episode/EpisodeDetailsViewLoader';
import ClipDetailsViewLoader from 'src/scripts/components/Clip/ClipDetailsViewLoader';
import NetworkClipDetailsViewLoader from 'src/scripts/components/NetworkClip/NetworkClipDetailsViewLoader';
import Clip from 'src/scripts/components/Clip';
import RoleList from 'src/scripts/components/Admin/RoleList';
import GenreList from 'src/scripts/components/Genre/GenreList';
import GenreDetailsView from 'src/scripts/components/Genre/GenreDetailsView';
import ResourceBrowser from 'src/scripts/components/ResourceBrowser';
import AccessDenied from 'src/scripts/components/AccessDenied';
import LoginFailed from 'src/scripts/components/LoginFailed';
import NotFound from 'src/scripts/components/NotFound';
import IngestJobDetailsViewLoader from 'src/scripts/components/IngestJob/IngestJobDetailsViewLoader';
import ChannelList from 'src/scripts/components/Channel/ChannelList';
import LiveEventList from 'src/scripts/components/LiveEvent/LiveEventList';
import LiveEventGroupList from 'src/scripts/components/LiveEvent/LiveEventGroupList';
import LiveEventDetailsViewLoader from 'src/scripts/components/LiveEvent/LiveEventDetailsViewLoader';
import LiveEventGroupDetailsViewLoader from 'src/scripts/components/LiveEvent/LiveEventGroupDetailsViewLoader';
import CategoryList from 'src/scripts/components/Category/CategoryList';
import SubcategoryList from 'src/scripts/components/Subcategory/SubcategoryList';
import NetworkClip from 'src/scripts/components/NetworkClip';
import CollectionList from 'src/scripts/components/Collection/CollectionList';
import CollectionDetailsView from 'src/scripts/components/Collection/CollectionDetailsView';
import DeviceController from 'src/scripts/components/Admin/DeviceController';
import StreamController from './components/Admin/StreamController';
import MediaLiveChannelController from './components/Admin/MediaLiveChannelController';
import configureStore from 'src/scripts/stores/create';
import {
  checkAccessToPath,
  TV_PATH,
  LIVE_PATH,
  ADMIN_PATH,
  NETWORK_PATH,
  CHANNELS_PATH,
  EVENTS_PATH,
  EVENT_GROUPS_PATH,
  DEVICES_PATH,
  STREAMS_PATH,
  MEDIA_LIVE_CHANNELS_PATH,
} from 'src/scripts/lib/accessController';
import * as browser from 'src/scripts/lib/browser';

const store = configureStore();

const isCallbackFromMainWindow = userActions.handleAzureCallback();

const isRequestRedirectFromAzure = (pathname) => {
  return _.startsWith(pathname, '/id_token=');
};

const onLogin = (routerCallback) => {
  return () => {
    routerCallback();
  };
};

const isErrorRequestFromAzure = (pathname) => {
  return _.startsWith(pathname, '/error=access_denied');
};

const login = (nextState, replace, callback) => {
  // this code is executed before azure token is processed by adal library,
  // so we want to avoid creating the App at this step
  if (isRequestRedirectFromAzure(nextState.location.pathname)) {
    return;
  }

  if (isErrorRequestFromAzure(nextState.location.pathname)) {
    replace({
      pathname: '/login-failed',
    });
    callback();
    return;
  }

  if (!store.getState().login.loggedInUser) {
    store.dispatch(userActions.login(onLogin(callback)));
  } else {
    callback();
  }
};

const checkPermission = (nextState, replace) => {
  // if there is no access token, we need to let the user navigate to the APP which will display the login component
  if (!store.getState().login.accessToken) {
    return;
  }

  if (!checkAccessToPath(store.getState().login.loggedInUser, nextState.location.pathname)) {
    replace({
      pathname: '/access-denied',
    });
  }
};

const redirectBasedOnPermission = (nextState, replace) => {
  // if there is no access token, we need to let the user navigate to the APP which will display the login component
  if (!store.getState().login.accessToken) {
    return;
  }

  const redirectPaths = [`/${TV_PATH}/videos`, '/network/videos', '/channels/list', '/admin/roles'];
  for (let i = 0; i < redirectPaths.length; i++) {
    if (checkAccessToPath(store.getState().login.loggedInUser, redirectPaths[i])) {
      replace({
        pathname: redirectPaths[i],
      });
      return;
    }
  }
  replace({
    pathname: '/access-denied',
  });
};

const onEnterVideoRoute = (nextState, replace) => {
  const modelName = browser.getItem('modelName');
  if (!modelName) {
    replace({ pathname: `/${TV_PATH}/videos/episodes` });
    return;
  }
  replace({ pathname: `/${TV_PATH}/videos/${modelName}` });
};

const onEnterSeasonRoute = (nextState, replace) => {
  const seasonVideoType = browser.getItem('seasonVideoType');
  if (!seasonVideoType) {
    replace({ pathname: `${nextState.location.pathname}/episodes` });
    return;
  }
  replace({ pathname: `${nextState.location.pathname}/${seasonVideoType}` });
};

if (isCallbackFromMainWindow) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
        <Redirect from="/" to="/landing" />
        <Route path="login-failed" component={LoginFailed} />
        <Route path="/" component={App} onEnter={login}>
          <Route path="landing" onEnter={redirectBasedOnPermission} />
          <Route path={TV_PATH} component={TV} onEnter={checkPermission}>
            <Route path="tv-series" component={ResourceBrowser}>
              <Route path=":id" component={TVSeriesDetailsView} />
              <Route path=":id/seasons/:seasonId/episodes/:episodeId" component={EpisodeDetailsViewLoader} />
              <Route path=":id/seasons/:seasonId/clips/:clipId" component={ClipDetailsViewLoader} />
              <Route path=":id/seasons" component={Season} />
              <Route path=":id/seasons/:seasonId" component={ResourceBrowser} onEnter={onEnterSeasonRoute} />
              <Route path=":id/seasons/:seasonId/episodes" component={Episode} />
              <Route path=":id/seasons/:seasonId/clips" component={Clip} />
              <Route path=":id/seasons/:seasonId/details" component={SeasonDetails} />
              <IndexRoute component={TvSeries} />
            </Route>
            <Route path="videos" component={ResourceBrowser}>
              <IndexRoute onEnter={onEnterVideoRoute} />
              <Route path="episodes" component={EpisodeList} />
              <Route path="clips" component={ClipList} />
              <Route path="ingestJobs" component={IngestJobList} />
              <Route path="ingestJobs/:ingestJobId" component={IngestJobDetailsViewLoader} />
            </Route>
            <Route path="genres" component={ResourceBrowser}>
              <IndexRoute component={GenreList} />
              <Route path=":genreId" component={GenreDetailsView} />
            </Route>
            <Route path="collections" component={ResourceBrowser}>
              <IndexRoute component={CollectionList} />
              <Route path=":collectionId" component={CollectionDetailsView} />
            </Route>
          </Route>
          <Route path={LIVE_PATH} component={LIVE} onEnter={checkPermission}>
            <Route component={ResourceBrowser}>
              <Route path={MEDIA_LIVE_CHANNELS_PATH} component={MediaLiveChannelController} />
              <Route path={`:library/${EVENTS_PATH}`} component={LiveEventList} />
              <Route path={`:library/${EVENTS_PATH}/:liveEventId`} component={LiveEventDetailsViewLoader} />
              <Route path={`:library/${EVENT_GROUPS_PATH}`} component={LiveEventGroupList} />
              <Route
                path={`:library/${EVENT_GROUPS_PATH}/:liveEventGroupId`}
                component={LiveEventGroupDetailsViewLoader}
              />
            </Route>
          </Route>
          <Route path={ADMIN_PATH} component={ADMIN} onEnter={checkPermission}>
            <Route path="roles" component={RoleList} />
            <Route path={CHANNELS_PATH} component={ChannelList} />
            <Route path={DEVICES_PATH} component={DeviceController} />
            <Route path={STREAMS_PATH} component={StreamController} />
          </Route>
          <Route path={NETWORK_PATH} component={NETWORK} onEnter={checkPermission}>
            <Route component={ResourceBrowser}>
              <Route path="videos" component={NetworkClipList} />
              <Route path="categories" component={CategoryList} />
              <Route path="categories/:id/subcategories" component={SubcategoryList} />
              <Route path="categories/:id/subcategories/:subcategoryId/clips" component={NetworkClip} />
              <Route
                path="categories/:id/subcategories/:subcategoryId/clips/:clipId"
                component={NetworkClipDetailsViewLoader}
              />
            </Route>
          </Route>
          <Route path="access-denied" component={AccessDenied} />
          <Route path="*" component={NotFound} />
        </Route>
      </Router>
    </Provider>,
    document.getElementById('app-container')
  );
}
