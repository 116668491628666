import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import BulkEditScheduleDatePicker from 'src/scripts/components/BulkAction/buttons/BulkEditButton/BulkEditScheduleDatePicker';
import BulkEditClassification from 'src/scripts/components/BulkAction/buttons/BulkEditButton/BulkEditClassification';
import BulkEditDrm from 'src/scripts/components/BulkAction/buttons/BulkEditButton/BulkEditDrm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import { EPISODE } from 'src/scripts/lib/modelNames';

export default class PublishingScheduleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formView: 'DATA_ENTRY',
      validationErrors: [],
    };
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
    this.renderValidationWarning = this.renderValidationWarning.bind(this);
  }

  onCancelClick() {
    this.props.close();
    this.props.resetForm();
  }

  onConfirmClick() {
    this.props.confirmAction();
    this.props.close();
    this.props.resetForm();
  }

  isSaveDisabled() {
    if (
      this.props.keepExistingEndDate &&
      this.props.keepExistingStartDate &&
      this.props.keepExistingAirDate &&
      this.props.keepExistingDrmValue &&
      this.props.keepExistingClassificationsValue
    )
      return true;
    if (!this.props.keepExistingStartDate && !this.props.publishStartDate) return true;
    if (this.props.validationErrors.length > 0) return true;
    return false;
  }

  renderValidationWarning() {
    if (this.props.validationErrors.length) {
      return <FormErrorMessage message={this.props.validationErrors.join(' ')} />;
    }
    return null;
  }

  render() {
    if (this.state.formView === 'CONFIRMATION') {
      return (
        <div>
          <p ref="confirmationText">{this.props.confirmationContent}</p>
          <div className="form__submit-button-bar">
            <Button
              className="form__button"
              ref="backButton"
              onClick={() => this.setState({ formView: 'DATA_ENTRY' })}
            >
              Back
            </Button>
            <Button
              className="form__button"
              ref="confirmButton"
              bsStyle="primary"
              onClick={this.onConfirmClick}
            >
              Confirm
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <BulkEditScheduleDatePicker
          publishStartDate={this.props.publishStartDate}
          publishEndDate={this.props.publishEndDate}
          broadcastAirDate={this.props.broadcastAirDate}
          keepExistingStartDate={this.props.keepExistingStartDate}
          keepExistingEndDate={this.props.keepExistingEndDate}
          keepExistingAirDate={this.props.keepExistingAirDate}
          hideKeepExistingEndDateInput={this.props.willUseReschedule}
          hideKeepExistingAirDateInput={this.props.willUseReschedule}
          handleKeepExistingStartDateChange={this.props.handleKeepExistingStartDateChange}
          handleKeepExistingEndDateChange={this.props.handleKeepExistingEndDateChange}
          handleKeepExistingAirDateChange={this.props.handleKeepExistingAirDateChange}
          onChange={this.props.onDateChange}
          onAirDateChange={this.props.onAirDateChange}
        />
        {this.props.modelName === EPISODE && (
          <BulkEditDrm
            keepExistingDrmValue={this.props.keepExistingDrmValue}
            applyDrmValueToAll={this.props.applyDrmValueToAll}
            removeDrmValueForAll={this.props.removeDrmValueForAll}
            handleKeepExistingDrmValue={this.props.handleKeepExistingDrmValue}
            handleApplyDrmValueToAll={this.props.handleApplyDrmValueToAll}
            handleRemoveDrmValueForAll={this.props.handleRemoveDrmValueForAll}
          />
        )}
        {this.props.modelName === EPISODE && (
          <BulkEditClassification
            classifications={this.props.classifications}
            keepExistingClassificationsValue={this.props.keepExistingClassificationsValue}
            onClassificationCodeChange={this.props.onClassificationCodeChange}
            handleKeepExistingClassificationChange={this.props.handleKeepExistingClassificationChange}
          />
        )}
        {this.renderValidationWarning()}
        <div className="form__submit-button-bar">
          <Button className="form__button" ref="closeButton" onClick={this.onCancelClick}>
            Cancel
          </Button>
          <Button
            className="form__button"
            ref="saveButton"
            bsStyle="primary"
            disabled={this.isSaveDisabled()}
            onClick={() => this.setState({ formView: 'CONFIRMATION' })}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

PublishingScheduleForm.propTypes = {
  classifications: PropTypes.array,
  close: PropTypes.func, // Prop is injected by ModalItem component
  confirmationContent: PropTypes.element,
  confirmAction: PropTypes.func,
  onDateChange: PropTypes.func,
  onAirDateChange: PropTypes.func,
  onClassificationCodeChange: PropTypes.func,
  publishStartDate: PropTypes.string,
  publishEndDate: PropTypes.string,
  broadcastAirDate: PropTypes.string,
  keepExistingStartDate: PropTypes.bool,
  keepExistingEndDate: PropTypes.bool,
  keepExistingAirDate: PropTypes.bool,
  keepExistingDrmValue: PropTypes.bool,
  keepExistingClassificationsValue: PropTypes.bool,
  removeDrmValueForAll: PropTypes.bool,
  applyDrmValueToAll: PropTypes.bool,
  handleKeepExistingStartDateChange: PropTypes.func,
  handleKeepExistingEndDateChange: PropTypes.func,
  handleKeepExistingAirDateChange: PropTypes.func,
  handleKeepExistingClassificationChange: PropTypes.func,
  handleKeepExistingDrmValue: PropTypes.func,
  handleApplyDrmValueToAll: PropTypes.func,
  handleRemoveDrmValueForAll: PropTypes.func,
  validationErrors: PropTypes.arrayOf(PropTypes.string),
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  willUseReschedule: PropTypes.bool,
  resetForm: PropTypes.func,
};
