import {
  GET_EPISODES_SUCCEEDED,
  GET_EPISODE_SUCCEEDED,
  GET_EPISODE_FAILED,
  DELETE_EPISODE_SUCCEEDED,
  SAVE_EPISODE_SUCCEEDED,
} from 'src/scripts/actions/episode';
import {
  WORKFLOW_STATE_CHANGE_SUCCEEDED,
  WORKFLOW_STATE_CHANGE_STARTED,
  WORKFLOW_STATE_CHANGE_FAILED,
} from 'src/scripts/actions/workflow/tvWorkflow';
import { REAL_TIME_EPISODE_UPDATED } from 'src/scripts/actions/realTimeNotification';
import { BULK_ACTION_EPISODE_UPDATED, BULK_ACTION_EPISODE_DELETED } from 'src/scripts/actions/bulkAction';
import { immutableRemoveById, immutableUpdateArrayItem } from 'src/scripts/lib/util';

import { UPLOAD_FAILED, UPLOAD_COMPLETED } from 'src/scripts/actions/video';
import { CAPTION_DELETE_FLOW_COMPLETED } from 'src/scripts/actions/ui/caption';

export const defaultState = {
  list: [],
  offset: 0,
  count: 0,
  disableWorkflow: false,
  savedEpisode: null,
  detailsView: {
    episode: null,
    errorMessage: null,
  },
};

function isCurrentEpisodeSaved(currentEpisode, savedEpisode) {
  return currentEpisode && savedEpisode.id === currentEpisode.id;
}

function isEqualToCurrentEpisodeId(id, currentEpisode) {
  return currentEpisode && id === currentEpisode.id;
}

function removeCaptionFromEpisode(episodeState) {
  return {
    ...episodeState,
    video: {
      ...episodeState.video,
      captionsS3Key: null,
    },
  };
}

function isCurrentEpisodeMatchingByVideoId(episodeState, videoId) {
  return episodeState.video.id === videoId;
}

function removeCaptionFromEpisodeIfMatchingByVideoId(episodeState, videoId) {
  if (isCurrentEpisodeMatchingByVideoId(episodeState, videoId)) {
    return removeCaptionFromEpisode(episodeState);
  }
  return episodeState;
}

const episodeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case CAPTION_DELETE_FLOW_COMPLETED:
      return {
        ...state,
        detailsView: {
          ...state.detailsView,
          episode: removeCaptionFromEpisodeIfMatchingByVideoId(
            state.detailsView.episode,
            action.payload.videoId
          ),
        },
      };
    case GET_EPISODES_SUCCEEDED:
      return {
        ...state,
        list: action.data.episodes,
        offset: action.offset,
        count: action.data.count,
        savedEpisode: null,
      };
    case SAVE_EPISODE_SUCCEEDED:
      if (isCurrentEpisodeSaved(state.detailsView.episode, action.data.episode)) {
        return {
          ...state,
          savedEpisode: action.data.episode,
          detailsView: {
            episode: action.data.episode,
            errorMessage: null,
          },
        };
      }
      return { ...state, savedEpisode: action.data.episode };

    case GET_EPISODE_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          episode: action.data.episode,
          errorMessage: null,
        },
        savedEpisode: null,
      };

    case GET_EPISODE_FAILED:
      return {
        ...state,
        detailsView: {
          episode: null,
          errorMessage: action.error,
        },
        savedEpisode: null,
      };

    case WORKFLOW_STATE_CHANGE_SUCCEEDED:
      return {
        ...state,
        disableWorkflow: false,
        detailsView: {
          episode: action.data.episode,
          errorMessage: null,
        },
      };
    case WORKFLOW_STATE_CHANGE_STARTED:
      return { ...state, disableWorkflow: true };
    case WORKFLOW_STATE_CHANGE_FAILED:
      return { ...state, disableWorkflow: false };

    case UPLOAD_FAILED:
      return {
        ...state,
        list: state.list.map((episode) => {
          if (episode.video.id === action.videoId) {
            return {
              ...episode,
              video: { ...episode.video, status: 'Upload Failed' },
            };
          }
          return episode;
        }),
      };

    case UPLOAD_COMPLETED:
      return {
        ...state,
        list: state.list.map((episode) => {
          if (episode.video.id === action.videoId) {
            return {
              ...episode,
              video: { ...episode.video, status: 'Uploaded' },
            };
          }
          return episode;
        }),
      };

    case DELETE_EPISODE_SUCCEEDED:
      return {
        ...state,
        list: immutableRemoveById(state.list, action.id),
        detailsView: {
          ...state.detailsView,
          episode: isEqualToCurrentEpisodeId(action.id, state.detailsView.episode)
            ? null
            : state.detailsView.episode,
        },
      };
    case BULK_ACTION_EPISODE_DELETED:
      const updatedList = immutableRemoveById(state.list, action.data.id);
      return {
        ...state,
        list: updatedList,
        detailsView: {
          ...state.detailsView,
          episode: isEqualToCurrentEpisodeId(action.data.id, state.detailsView.episode)
            ? null
            : state.detailsView.episode,
        },
        count: state.list.length === updatedList.length ? state.count : --state.count,
      };
    case BULK_ACTION_EPISODE_UPDATED:
      return {
        ...state,
        detailsView: {
          ...state.detailsView,
          episode: isEqualToCurrentEpisodeId(action.data.episode.id, state.detailsView.episode)
            ? action.data.episode
            : state.detailsView.episode,
        },
        list: immutableUpdateArrayItem(state.list, action.data.episode),
      };
    case REAL_TIME_EPISODE_UPDATED:
      return { ...state, list: immutableUpdateArrayItem(state.list, action.data) };
    default:
      return state;
  }
};

export default episodeReducer;
