import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import { closeModal } from 'src/scripts/actions/modalItem';
import { getCategories } from 'src/scripts/actions/category';
import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';

export const SAVE_SUBCATEGORY_FAILED = 'SAVE_SUBCATEGORY_FAILED';
export const SAVE_SUBCATEGORY_SUCCEEDED = 'SAVE_SUBCATEGORY_SUCCEEDED';
export const SAVE_SUBCATEGORY_STARTED = 'SAVE_SUBCATEGORY_STARTED';
export const UPDATE_SUBCATEGORY = 'UPDATE_SUBCATEGORY';
export const GET_SUBCATEGORIES_SUCCEEDED = 'GET_SUBCATEGORIES_SUCCEEDED';
export const GET_SUBCATEGORIES_FAILED = 'GET_SUBCATEGORIES_FAILED';
export const FORM_GET_SUBCATEGORIES_SUCCEEDED = 'FORM_GET_SUBCATEGORIES_SUCCEEDED';
export const FORM_GET_SUBCATEGORIES_FAILED = 'FORM_GET_SUBCATEGORIES_FAILED';
export const DELETE_SUBCATEGORY_SUCCEEDED = 'DELETE_SUBCATEGORY_SUCCEEDED';
export const GET_SUBCATEGORY_SUCCEEDED = 'GET_SUBCATEGORY_SUCCEEDED';
export const GET_SUBCATEGORY_FAILED = 'GET_SUBCATEGORY_FAILED';
export const GET_SUBCATEGORY_FORM_INITIAL_DATA_SUCCEEDED = 'GET_SUBCATEGORY_FORM_INITIAL_DATA_SUCCEEDED';

export function getSubcategories(
  categoryId,
  successAction = GET_SUBCATEGORIES_SUCCEEDED,
  failAction = GET_SUBCATEGORIES_FAILED
) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: successAction,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: failAction,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getSubcategories(categoryId).then(onSuccess, onError);
  };
}

export function getSubcategoriesForForm(categoryId) {
  return getSubcategories(categoryId, FORM_GET_SUBCATEGORIES_SUCCEEDED, FORM_GET_SUBCATEGORIES_FAILED);
}

export function saveSubcategory(payload) {
  return (dispatch) => {
    const saveMethod = payload.id ? networkVmsApi.updateSubcategory : networkVmsApi.createSubcategory;
    dispatch({
      type: SAVE_SUBCATEGORY_STARTED,
    });
    const onSuccess = (response) => {
      dispatch({
        type: SAVE_SUBCATEGORY_SUCCEEDED,
        data: response,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_SUBCATEGORY_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(payload).then(onSuccess, onError);
  };
}

export function updateSubcategory(subcategory) {
  return {
    type: UPDATE_SUBCATEGORY,
    subcategory,
  };
}

export function getSubcategoryById(subcategoryId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_SUBCATEGORY_SUCCEEDED,
        data,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_SUBCATEGORY_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.getSubcategoryById(subcategoryId).then(onSuccess, onError);
  };
}

export function deleteSubcategoryAndCloseConfirmation(subcategoryId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_SUBCATEGORY_SUCCEEDED,
        id: subcategoryId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return networkVmsApi.deleteSubcategory(subcategoryId).then(onSuccess, onError);
  };
}

export function getSubcategoryFormInitialData(subcategoryId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: GET_SUBCATEGORY_FORM_INITIAL_DATA_SUCCEEDED,
      });
    };
    if (subcategoryId) {
      return dispatch(getSubcategoryById(subcategoryId))
        .then(() => {
          return dispatch(getCategories());
        })
        .then(onSuccess);
    }
    return dispatch(getCategories()).then(onSuccess);
  };
}
