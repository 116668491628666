import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  getLiveEventById,
  deleteLiveEventAndCloseConfirmation,
  stopLiveEventStreamAndCloseConfirmation,
  startLiveEventStreamAndCloseConfirmation,
  launchInstantLiveEventAndCloseConfirmation,
  endInstantLiveEventAndCloseConfirmation,
  updateLiveEventStatus,
} from 'src/scripts/actions/liveEvent';
import history from 'src/scripts/lib/history';
import ViewLoader from 'src/scripts/components/ViewLoader';
import LiveEventDetails from 'src/scripts/components/LiveEvent/internal/LiveEventDetails';

export class LiveEventDetailsViewLoader extends React.Component {
  componentDidMount() {
    this.props.getLiveEventById(this.props.params.library, this.props.params.liveEventId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.liveEventDetailsView.liveEvent && !nextProps.liveEventDetailsView.liveEvent) {
      history.replace({ state: null, pathname: `/live/${this.props.params.library.toLowerCase()}/events` });
    }
  }

  componentDidUpdate() {
    if (this.props.savedLiveEvent && this.props.savedLiveEvent.id === Number(this.props.params.liveEventId)) {
      this.props.getLiveEventById(this.props.params.library, this.props.params.liveEventId);
    }
  }

  render() {
    return (
      <ViewLoader
        loading={
          !this.props.liveEventDetailsView.liveEvent ||
          Number(this.props.params.liveEventId) !== this.props.liveEventDetailsView.liveEvent.id
        }
        errorMessage={this.props.liveEventDetailsView.errorMessage}
      >
        <LiveEventDetails
          liveEvent={this.props.liveEventDetailsView.liveEvent}
          deleteLiveEvent={this.props.deleteLiveEvent}
          updateLiveEventStatus={this.props.updateLiveEventStatus}
          stopLiveEventStream={this.props.stopLiveEventStream}
          startLiveEventStream={this.props.startLiveEventStream}
          launchInstantLiveEvent={this.props.launchInstantLiveEvent}
          endInstantLiveEvent={this.props.endInstantLiveEvent}
        />
      </ViewLoader>
    );
  }
}

LiveEventDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  getLiveEventById: PropTypes.func.isRequired,
  deleteLiveEvent: PropTypes.func.isRequired,
  liveEventDetailsView: PropTypes.object.isRequired,
  savedLiveEvent: PropTypes.object,
  updateLiveEventStatus: PropTypes.func,
  stopLiveEventStream: PropTypes.func,
  startLiveEventStream: PropTypes.func,
  launchInstantLiveEvent: PropTypes.func,
  endInstantLiveEvent: PropTypes.func,
};

export function mapStateToProps(state) {
  return {
    savedLiveEvent: state.liveEvents.savedLiveEvent,
    liveEventDetailsView: state.liveEvents.detailsView,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getLiveEventById: (library, liveEventId) => dispatch(getLiveEventById(library, liveEventId)),
    deleteLiveEvent: (library, liveEventId) =>
      dispatch(deleteLiveEventAndCloseConfirmation(library, liveEventId)),
    stopLiveEventStream: (library, liveEventId) =>
      dispatch(stopLiveEventStreamAndCloseConfirmation(library, liveEventId)),
    startLiveEventStream: (library, liveEventId) =>
      dispatch(startLiveEventStreamAndCloseConfirmation(library, liveEventId)),
    launchInstantLiveEvent: (library, liveEventId) =>
      dispatch(launchInstantLiveEventAndCloseConfirmation(library, liveEventId)),
    endInstantLiveEvent: (library, liveEventId) =>
      dispatch(endInstantLiveEventAndCloseConfirmation(library, liveEventId)),
    updateLiveEventStatus: () => dispatch(updateLiveEventStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveEventDetailsViewLoader);
