import { combineReducers } from 'redux';

import videos from './videos';
import uploads from './uploads';

const rootCaptionReducer = combineReducers({
  videos,
  uploads,
});

export default rootCaptionReducer;
