import { reset as resetForm } from 'src/scripts/actions/form';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function openModal(id) {
  return (dispatch) => {
    dispatch(resetForm());
    dispatch({
      type: OPEN_MODAL,
      id,
    });
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch(resetForm());
    dispatch({
      type: CLOSE_MODAL,
    });
  };
}
