import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Modal, ButtonToolbar, Button, Panel } from 'react-bootstrap';
import { DASH_FOR_EMPTY_CONTENT } from 'src/scripts/constants/common';
import { TV_PATH } from 'src/scripts/lib/accessController';
import moment from 'moment';
import { clearDuplicateEpisode } from '../../../actions/episode';
import { formatTimeInMiliseconds } from 'src/scripts/lib/timeFormatter';

export class OverwriteEpisodeModal extends React.Component {
  constructor(props) {
    super(props);
    this.onReplace = this.onReplace.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onReplace() {
    this.props.clearDuplicateEpisode();
    return this.props.onReplace();
  }

  onClose() {
    return this.props.clearDuplicateEpisode();
  }

  renderEpisodeDetailsRow(rowLabel, rowValue, valueLink) {
    return (
      <div className="details-row row">
        <div className="details-key row">{rowLabel}</div>
        <div className="details-value row">
          {valueLink ? (
            <a href={valueLink} target="_blank">
              {rowValue}
            </a>
          ) : (
            rowValue
          )}
        </div>
      </div>
    );
  }

  renderEpisodeDetails(episode) {
    return (
      <div>
        {this.renderEpisodeDetailsRow('Episode Name', episode.name)}
        {this.renderEpisodeDetailsRow('Material Key', episode.materialKey || DASH_FOR_EMPTY_CONTENT)}
        {this.renderEpisodeDetailsRow(
          'Video Duration',
          episode.video.duration ? formatTimeInMiliseconds(episode.video.duration) : DASH_FOR_EMPTY_CONTENT
        )}
        {this.renderEpisodeDetailsRow('Start Date', moment(episode.availability).format('lll'))}
        {this.renderEpisodeDetailsRow(
          'End Date',
          episode.expiry ? moment(episode.expiry).format('lll') : DASH_FOR_EMPTY_CONTENT
        )}
      </div>
    );
  }

  renderEpisodeLinks() {
    const duplicateEpisode = this.props.duplicateEpisode;
    return (
      <div ref="episodeLinks">
        {this.renderEpisodeDetailsRow(
          'TV Series',
          duplicateEpisode.partOfSeries.name,
          `/#/${TV_PATH}/tv-series/${duplicateEpisode.partOfSeries.id}/seasons`
        )}
        {this.renderEpisodeDetailsRow(
          'Season',
          duplicateEpisode.partOfSeason.name,
          `/#/${TV_PATH}/tv-series/${duplicateEpisode.partOfSeries.id}/seasons/${duplicateEpisode.partOfSeason.id}/episodes`
        )}
        {this.renderEpisodeDetailsRow(
          'Episode',
          duplicateEpisode.name,
          `/#/${TV_PATH}/tv-series/${duplicateEpisode.partOfSeries.id}/seasons/${duplicateEpisode.partOfSeason.id}/episodes/${duplicateEpisode.id}`
        )}
      </div>
    );
  }

  renderEpisodeComparisonTable() {
    return (
      <div>
        <table className="table" ref="episodeComparisonTable">
          <tbody>
            <tr>
              <th>CURRENT (ARCHIVED)</th>
              <th>NEW</th>
            </tr>
            <tr>
              <td>{this.renderEpisodeDetails(this.props.duplicateEpisode)}</td>
              <td>{this.renderEpisodeDetails(this.props.episode)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div ref="overwriteEpisodeModal" className="static-modal">
        <Modal show backdrop="static">
          <Modal.Header className="modal-warning">
            <Modal.Title id="modalTitle">
              <i className="fa fa-exclamation-triangle warning-icon" /> Duplicate Episode Found
            </Modal.Title>
          </Modal.Header>
          <Modal.Body id="modalBody">
            <Panel header="Episode Details" bsStyle="primary">
              {this.renderEpisodeLinks()}
            </Panel>
            <Panel header="Comparison">{this.renderEpisodeComparisonTable()}</Panel>
            <span>
              An ARCHIVED episode already exists with the same episode number. Would you like to replace the
              ARCHIVED episode with the following data?
            </span>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar className="pull-right toolbar-buttons">
              <Button onClick={this.onClose} ref="cancelButton">
                Cancel
              </Button>
              <Button bsStyle="primary" onClick={this.onReplace} ref="replaceButton">
                Replace
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

OverwriteEpisodeModal.propTypes = {
  episode: PropTypes.object,
  duplicateEpisode: PropTypes.object,
  clearDuplicateEpisode: PropTypes.func,
  onReplace: PropTypes.func,
  onClose: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    clearDuplicateEpisode: () => dispatch(clearDuplicateEpisode()),
  };
}

export default connect(null, mapDispatchToProps)(OverwriteEpisodeModal);
