import _debounce from 'lodash/debounce';
import { screenWidthResize } from 'src/scripts/actions/ui/screen';

export const createDebouncedResizeHandler = (storeObj, windowObj, debounceDelay) =>
  _debounce(() => {
    storeObj.dispatch(screenWidthResize(windowObj.innerWidth));
  }, debounceDelay);

export const screenStoreEnhancer =
  (currentStoreCreator) =>
  (reducer, initialState, browserWindow = window) => {
    const store = currentStoreCreator(reducer, initialState);
    const resizeHandler = createDebouncedResizeHandler(store, browserWindow, 150);
    browserWindow.addEventListener('resize', resizeHandler);
    return store;
  };
