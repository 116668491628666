import {
  GET_LIVE_EVENTS_SUCCEEDED,
  GET_LIVE_EVENTS_FAILED,
  GET_LIVE_EVENT_SUCCEEDED,
  GET_LIVE_EVENT_FAILED,
  DELETE_LIVE_EVENT_SUCCEEDED,
  SAVE_LIVE_EVENT_SUCCEEDED,
  UPDATE_LIVE_EVENT_STATUS,
  UPDATE_LIVE_EVENT_STATUSES,
  STOP_LIVE_EVENT_STREAM_SUCCEEDED,
  START_LIVE_EVENT_STREAM_SUCCEEDED,
  LAUNCH_INSTANT_LIVE_EVENT_SUCCEEDED,
  END_INSTANT_LIVE_EVENT_SUCCEEDED,
} from 'src/scripts/actions/liveEvent';
import { immutableRemoveById } from 'src/scripts/lib/util';
import { calculateLiveEventStatus, includeLiveEventStatuses } from 'src/scripts/lib/liveEventStatus';
import { classificationCodeNameMap } from '../constants/classificationCodes';

export const initialState = {
  liveEvents: [],
  count: 0,
  offset: 0,
  errorMessage: null,
  savedLiveEvent: null,
  detailsView: {
    liveEvent: null,
    errorMessage: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_LIVE_EVENT_SUCCEEDED:
      return {
        ...state,
        savedLiveEvent: action.data.liveEvent,
      };
    case GET_LIVE_EVENTS_SUCCEEDED:
      return {
        ...state,
        liveEvents: includeLiveEventStatuses(action.data.liveEvents),
        offset: action.offset,
        count: action.data.count,
        savedLiveEvent: null,
        errorMessage: null,
      };
    case GET_LIVE_EVENTS_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };
    case GET_LIVE_EVENT_SUCCEEDED:
      // GenreId is undefined for network events and
      // only available for tv live event
      const currentEventGenre = action.data.genres.find(
        (genreObj) => genreObj.id === action.data.liveEvent.genreId
      );
      const upNextEventGenre = action.data.genres.find((genreObj) => {
        const upNextEventGenreId =
          action.data.liveEvent.upNextEvent && action.data.liveEvent.upNextEvent.genreId;
        return genreObj.id === upNextEventGenreId;
      });

      const upNextEvent = action.data.liveEvent.upNextEvent;
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...action.data.liveEvent,
            status: calculateLiveEventStatus(action.data.liveEvent),
            genre: currentEventGenre && currentEventGenre.name,
            classificationCodeName: classificationCodeNameMap[action.data.liveEvent.classificationCode],
            upNextEvent: {
              ...action.data.liveEvent.upNextEvent,
              genre: upNextEventGenre && upNextEventGenre.name,
              classificationCodeName:
                classificationCodeNameMap[upNextEvent && upNextEvent.classificationCode],
            },
          },
          errorMessage: null,
        },
        savedLiveEvent: null,
      };
    case GET_LIVE_EVENT_FAILED:
      return {
        ...state,
        detailsView: {
          liveEvent: null,
          errorMessage: action.error,
        },
      };
    case DELETE_LIVE_EVENT_SUCCEEDED:
      if (state.detailsView.liveEvent && state.detailsView.liveEvent.id === action.id) {
        return {
          ...state,
          liveEvents: immutableRemoveById(state.liveEvents, action.id),
          detailsView: {
            liveEvent: null,
            errorMessage: null,
          },
        };
      }
      return {
        ...state,
        liveEvents: immutableRemoveById(state.liveEvents, action.id),
      };
    case STOP_LIVE_EVENT_STREAM_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...action.data.liveEvent,
            status: calculateLiveEventStatus(action.data.liveEvent),
          },
          errorMessage: null,
        },
      };
    case START_LIVE_EVENT_STREAM_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...action.data.liveEvent,
            status: calculateLiveEventStatus(action.data.liveEvent),
          },
          errorMessage: null,
        },
      };
    case LAUNCH_INSTANT_LIVE_EVENT_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...action.data.liveEvent,
            status: calculateLiveEventStatus(action.data.liveEvent),
          },
          errorMessage: null,
        },
      };
    case END_INSTANT_LIVE_EVENT_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...action.data.liveEvent,
            status: calculateLiveEventStatus(action.data.liveEvent),
          },
          errorMessage: null,
        },
      };
    case UPDATE_LIVE_EVENT_STATUS:
      return {
        ...state,
        detailsView: {
          liveEvent: {
            ...state.detailsView.liveEvent,
            status: calculateLiveEventStatus(state.detailsView.liveEvent),
          },
        },
      };
    case UPDATE_LIVE_EVENT_STATUSES:
      return {
        ...state,
        liveEvents: includeLiveEventStatuses(state.liveEvents),
      };
    default:
      return state;
  }
}
