import { getStreams, patchStream } from './apiWrappers/vmsApi/admin';

const YoUpParam = 'yo.up';
const YoApParam = 'yo.ap';

export const STREAM_TYPES = {
  hls: 'hls',
  dash: 'dash',
};

export const CDNS = {
  Akamai: 'Akamai',
  CloudFront: 'CloudFront',
};

export const RELEASE_CHANNELS = {
  Stable: 'STABLE',
  Beta: 'BETA',
  Experimental: 'EXPERIMENTAL',
};

function parseToPlayableUrl(stream) {
  if (!stream.ssaiEnabled || !stream.youp || !stream.yoap) {
    return stream.url;
  }

  // Using URLSearchParams to handle query params instead of URL constructor
  // to provide compatibility to browsers that do not support URL constructor (e.g. IE)
  const urlParts = stream.url.split('?');
  const baseUrl = urlParts[0];
  const queryParams = new URLSearchParams(urlParts[1] || '');

  queryParams.set(YoUpParam, stream.youp);
  queryParams.set(YoApParam, stream.yoap);

  const url = `${baseUrl}?${queryParams.toString()}`;

  return decodeURIComponent(url.toString());
}

function formatDataToDisplay(streams) {
  return streams.map((stream) => ({
    ...stream,
    url: parseToPlayableUrl(stream),
  }));
}

export async function listStreams(params) {
  const { streams, count } = await getStreams(params);

  if (streams && streams.length) {
    return {
      count,
      streams: formatDataToDisplay(streams),
    };
  }
  return {
    count,
    streams,
  };
}

export function updateStream(streamId, info) {
  return patchStream(streamId, info);
}
