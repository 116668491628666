import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class SimpleMessage extends Component {
  constructor(props) {
    super(props);
    this.defaultClassName = 'mi9-simple-message';
  }

  render() {
    return (
      <div ref={'simpleMessage'} className={`${this.defaultClassName} ${this.props.className || ''}`}>
        {this.props.message}
      </div>
    );
  }
}

SimpleMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};
