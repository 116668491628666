import {
  GET_EPISODE_FOR_INGEST_JOB_SUCCEEDED,
  DELETE_INGEST_JOB_SUCCEEDED,
  GET_ALL_INGEST_JOBS_SUCCEEDED,
  IMPORT_EPISODE_SUCCEEDED,
} from 'src/scripts/actions/ingestJobs';
import { BULK_ACTION_INGEST_JOB_DELETED } from 'src/scripts/actions/bulkAction';
import { REAL_TIME_INGEST_JOB_UPDATED } from 'src/scripts/actions/realTimeNotification';
import { immutableRemoveById, immutableUpdateArrayItem } from 'src/scripts/lib/util';

export const initialState = {
  list: [],
  totalCount: 0,
  offset: 0,
  detailsView: {
    ingestJob: null,
    episode: null,
    xmlSource: null,
  },
};

function preprocessIngestJob(itemList) {
  const resItemList = [];
  itemList.forEach(function (item) {
    const resItem = { ...item };
    resItem.partOfSeries = {};
    resItem.partOfSeason = {};
    resItemList.push(resItem);
  });
  return resItemList;
}

function isEqualToCurrentIngestJobId(id, currentIngestJob) {
  return currentIngestJob && id === currentIngestJob.id;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INGEST_JOBS_SUCCEEDED:
      return {
        ...state,
        list: preprocessIngestJob(action.data.ingestJobs),
        totalCount: action.data.count,
        offset: action.offset,
      };

    case DELETE_INGEST_JOB_SUCCEEDED:
      return {
        ...state,
        list: immutableRemoveById(state.list, action.id),
        detailsView: isEqualToCurrentIngestJobId(action.id, state.detailsView.ingestJob)
          ? { ...initialState.detailsView }
          : { ...state.detailsView },
      };
    case BULK_ACTION_INGEST_JOB_DELETED:
      const updatedList = immutableRemoveById(state.list, action.data.id);
      return {
        ...state,
        list: updatedList,
        detailsView: isEqualToCurrentIngestJobId(action.data.id, state.detailsView.ingestJob)
          ? { ...initialState.detailsView }
          : { ...state.detailsView },
        totalCount: state.list.length === updatedList.length ? state.totalCount : --state.totalCount,
      };
    case REAL_TIME_INGEST_JOB_UPDATED:
      return { ...state, list: immutableUpdateArrayItem(state.list, action.data) };

    case IMPORT_EPISODE_SUCCEEDED:
      return { ...state, detailsView: { ...initialState.detailsView } };

    case GET_EPISODE_FOR_INGEST_JOB_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          episode: action.data.episode,
          ingestJob: action.data.ingestJob,
          xmlSource: action.data.xmlSource,
        },
      };

    default:
      return state;
  }
}
