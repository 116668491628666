import moment from 'moment';
import { REMOTE_EVENT } from 'src/scripts/lib/liveEventTypes';
import { INSTANT_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';

export const scheduledRemoteEventPermittedActions = ['edit', 'delete'];

export const instantRemoteEventPermittedActions = {
  OUTSIDE_OF_STREAMING_DATES: ['launch', 'edit', 'delete'],
  PROMOTING: ['end', 'edit'],
  STREAMING: ['end', 'edit'],
  ERROR: ['end', 'delete'],
  ENDED_WITH_ERROR: ['delete'],
};

function isStreaming(liveEvent) {
  return (
    moment(liveEvent.availabilityStartDate).isSameOrBefore(moment()) &&
    moment().isSameOrBefore(moment(liveEvent.availabilityEndDate))
  );
}

function isPromoting(liveEvent) {
  return (
    moment(liveEvent.promoStartDate).isSameOrBefore(moment()) &&
    moment().isSameOrBefore(moment(liveEvent.availabilityStartDate))
  );
}

function isEndedWithError(liveEvent) {
  return liveEvent.scheduleType === INSTANT_EVENT && liveEvent.errorMessage && !isStreaming(liveEvent);
}

function getStreamingStatus(liveEvent) {
  if (isEndedWithError(liveEvent)) {
    return 'ENDED_WITH_ERROR';
  }
  if (liveEvent.errorMessage) {
    return 'ERROR';
  }
  if (liveEvent.errorMessage && !isStreaming(liveEvent)) {
    return 'ENDED';
  }
  if (isPromoting(liveEvent)) {
    return 'PROMOTING';
  }
  if (!isStreaming(liveEvent)) {
    return 'OUTSIDE_OF_STREAMING_DATES';
  }
  if (liveEvent.stopped) {
    return 'STOPPED';
  }
  return 'STREAMING';
}

function getPermittedActions(liveEvent) {
  const workflowState = getStreamingStatus(liveEvent);
  if (liveEvent.scheduleType === INSTANT_EVENT) {
    if (liveEvent.eventType === REMOTE_EVENT) {
      return instantRemoteEventPermittedActions[workflowState];
    }
  }
  return scheduledRemoteEventPermittedActions;
}

export function isEditEnabled(liveEvent) {
  const permittedActions = getPermittedActions(liveEvent);
  return permittedActions.indexOf('edit') !== -1;
}

export function isDeleteEnabled(liveEvent) {
  const permittedActions = getPermittedActions(liveEvent);
  return permittedActions.indexOf('delete') !== -1;
}

export function isLaunchEnabled(liveEvent) {
  const permittedActions = getPermittedActions(liveEvent);
  return permittedActions.indexOf('launch') !== -1;
}

export function isEndEnabled(liveEvent) {
  const permittedActions = getPermittedActions(liveEvent);
  return permittedActions.indexOf('end') !== -1;
}
