import PropTypes from 'prop-types';
import React from 'react';
import ModalItem from 'src/scripts/components/ModalItem';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';

export default class MatchEpisodeModal extends React.Component {
  render() {
    if (this.props.ingestJob && this.props.ingestJob.state === 'MATCHING_REQUIRED') {
      return (
        <ModalItem
          component="div"
          form={<EpisodeFormLoader ingestJobId={this.props.ingestJob.id} />}
          className="matchEpisode pull-right details-action-button btn btn-default"
          title="Match Episode"
        />
      );
    }
    return null;
  }
}

MatchEpisodeModal.propTypes = {
  ingestJob: PropTypes.object,
};
