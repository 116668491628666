import PropTypes from 'prop-types';
import React from 'react';
import LoadingIcon from 'src/scripts/components/LoadingIcon';

export class ViewLoader extends React.Component {
  render() {
    if (this.props.errorMessage) {
      return <div className="empty-item-list text-muted view-loader-error">{this.props.errorMessage}</div>;
    }
    if (this.props.loading) {
      return (
        <div className="view-loader">
          <LoadingIcon loading />
        </div>
      );
    }
    return <div>{this.props.children}</div>;
  }
}

ViewLoader.propTypes = {
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.any,
};

export default ViewLoader;
