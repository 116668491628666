import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { getPhoenixEpisodeDataByMaterialKey } from 'src/scripts/actions/apiWrappers/vmsApi/tv';

class MaterialKeyMatchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFromPhoenix: null,
      loading: false,
      error: null,
    };
  }

  isSubmitDisabled = () => {
    if (this.state.error) return true;
    if (this.state.loading) return true;
    return false;
  };

  fetchPhoenixDataForMaterialKey = async () => {
    try {
      this.setState({
        dataFromPhoenix: null,
        loading: true,
        error: null,
      });
      const data = await getPhoenixEpisodeDataByMaterialKey(this.props.materialKey);
      this.setState({ loading: false, dataFromPhoenix: data });
    } catch (err) {
      this.setState({ error: err, loading: false });
    }
  };

  renderHeader = () => {
    if (this.state.loading) {
      return (
        <div id="matching-material-key-header">
          Attempting to match data for material key: <strong>{this.props.materialKey}</strong>
        </div>
      );
    }
    if (this.state.dataFromPhoenix) {
      return (
        <div id="matching-material-key-header">
          Successfully matched data for material key: <strong>{this.props.materialKey}</strong>
        </div>
      );
    }
    return (
      <div id="matching-material-key-header">
        Unable to match data for material key: <strong>{this.props.materialKey}</strong>
      </div>
    );
  };

  renderMatchedData = () => {
    if (this.state.dataFromPhoenix) {
      const dataFromPhoenix = this.state.dataFromPhoenix;
      const tvSeries = dataFromPhoenix.partOfSeries;
      const season = dataFromPhoenix.partOfSeason;
      const episodeNumber = dataFromPhoenix.number;
      const episodeIdentifier = dataFromPhoenix.phoenixEpisodeIdentifier;
      const classification = dataFromPhoenix.classificationCode;
      const description = dataFromPhoenix.description;
      const availability = dataFromPhoenix.availability;
      const expiry = dataFromPhoenix.expiry;
      const broadcastAirDate = dataFromPhoenix.broadcastAirDate;
      const drm = dataFromPhoenix.drm;
      const masterSeriesName = dataFromPhoenix.masterSeriesName;
      return (
        <pre id="data-summary">
          {tvSeries ? (
            <div>
              TV Series: <strong>{tvSeries.name}</strong>
            </div>
          ) : null}
          {masterSeriesName ? (
            <div>
              Master Series Name: <strong>{masterSeriesName}</strong>
            </div>
          ) : null}
          {season ? (
            <div>
              Season: <strong>{season.name}</strong>
            </div>
          ) : null}
          {description ? (
            <div>
              Description: <strong>{description}</strong>
            </div>
          ) : null}
          {episodeNumber ? (
            <div>
              Episode Number: <strong>{episodeNumber}</strong>
            </div>
          ) : null}
          {episodeIdentifier ? (
            <div>
              Episode Identifier: <strong>{episodeIdentifier}</strong>
            </div>
          ) : null}
          {classification ? (
            <div>
              Classification: <strong>{classification}</strong>
            </div>
          ) : null}
          {availability ? (
            <div>
              Availability: <strong>{availability}</strong>
            </div>
          ) : null}
          {expiry ? (
            <div>
              Expiry: <strong>{expiry}</strong>
            </div>
          ) : null}
          {broadcastAirDate ? (
            <div>
              Broadcast Air Date: <strong>{broadcastAirDate}</strong>
            </div>
          ) : null}
          <div>
            DRM: <strong>{drm ? 'Yes' : 'No'}</strong>
          </div>
        </pre>
      );
    }
    if (this.state.error) {
      return <pre>Unable to match episode</pre>;
    }
    return <pre>Loading...</pre>;
  };

  componentDidUpdate(oldProps) {
    if (!oldProps.isOpen && this.props.isOpen && this.props.materialKey) {
      this.fetchPhoenixDataForMaterialKey();
    }
  }

  importData = async () => {
    await this.props.importData(this.state.dataFromPhoenix);
    this.props.onClose();
  };

  render() {
    return (
      <Modal show={this.props.isOpen} onHide={this.props.onClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title ref="modalTitle">Matching Material Key</Modal.Title>
        </Modal.Header>
        <Modal.Body ref="modalBody">
          <div id="some-body">{this.renderHeader()}</div>
          <div style={{ marginTop: '10px' }}>{this.renderMatchedData()}</div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="pull-right toolbar-buttons">
            <Button onClick={this.props.onClose} id="cancel-button">
              Cancel
            </Button>
            <Button
              bsStyle="primary"
              onClick={this.importData}
              disabled={this.isSubmitDisabled()}
              id="import-data-button"
            >
              Import Episode Data
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MaterialKeyMatchModal;

MaterialKeyMatchModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  materialKey: PropTypes.string,
  importData: PropTypes.func,
};
