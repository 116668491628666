import * as networkVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/network';
import { workflowErrorNotification } from 'src/scripts/actions/eventNotification';
import { getNetworkClip, getNetworkClips } from 'src/scripts/actions/networkClip';
import { closeModal } from 'src/scripts/actions/modalItem';
import constants from 'src/scripts/constants';

export const NETWORK_CLIP_UPDATED = 'NETWORK_CLIP_UPDATED';

export const WORKFLOW_STATE_CHANGE_SUCCEEDED = 'WORKFLOW_STATE_CHANGE_SUCCEEDED';
export const WORKFLOW_STATE_CHANGE_STARTED = 'WORKFLOW_STATE_CHANGE_STARTED';
export const WORKFLOW_STATE_CHANGE_FAILED = 'WORKFLOW_STATE_CHANGE_FAILED';

function refresh(resource, offset) {
  const payload = {
    subcategoryId: resource.subcategoryId,
    offset,
    limit: constants.pagination.pageSize,
  };
  return getNetworkClips(payload);
}

export const vmsApiActionEndpointMap = {
  archive: 'archived',
  publish: 'approve-and-activate',
};

function executeWorkflowAction(action, resource, offset, resourceName) {
  return (dispatch) => {
    dispatch({ type: WORKFLOW_STATE_CHANGE_STARTED });
    const onSuccess = (workflowActionResult) => {
      dispatch({
        type: WORKFLOW_STATE_CHANGE_SUCCEEDED,
        data: workflowActionResult,
      });
      dispatch(closeModal());
      dispatch(refresh(resource, offset, resourceName));
    };
    const onError = (response) => {
      dispatch({
        type: WORKFLOW_STATE_CHANGE_FAILED,
        data: resource,
      });
      dispatch(workflowErrorNotification(response.responseJSON.message, action, 'networkClip', resource.id));
    };
    networkVmsApi.notifyWorkflowAction(vmsApiActionEndpointMap[action], resource.id).then(onSuccess, onError);
  };
}

function executeExpireAction(networkClip) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(getNetworkClip(networkClip.id));
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch(
        workflowErrorNotification(response.responseJSON.message, 'expire', 'networkClip', networkClip.id)
      );
    };
    networkVmsApi.expireNetworkClip(networkClip.id).then(onSuccess, onError);
  };
}

export function expire(networkClip) {
  return executeExpireAction(networkClip);
}

export function archive(networkClip, offset) {
  return executeWorkflowAction('archive', networkClip, offset);
}

export function publish(networkClip, offset) {
  return executeWorkflowAction('publish', networkClip, offset);
}
