import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import * as pagination from 'src/scripts/lib/pagination';
import { getGenres } from 'src/scripts/actions/genre';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import ActionViewGenre, { getLinkToGenreDetailsView } from './actions/View';
import ActionEditGenre from './actions/Edit';
import ActionDeleteGenre from './actions/Delete';

export class GenreList extends React.Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page || !!this.props.savedGenre) {
      this.loadData();
    }
  }

  loadData() {
    const offset = pagination.calculateOffset(this.props.page);
    return this.props.getGenres({
      offset,
      limit: pagination.pageSize,
    });
  }

  render() {
    const actionList = (genreItem) => {
      return (
        <NavDropdownMenu
          key={genreItem.id}
          component="div"
          buttonClass="btn btn-default"
          title={<i className="fa fa-ellipsis-v"></i>}
          id={`genre-item-button-${genreItem.id}`}
        >
          <ActionViewGenre genre={genreItem} />
          <ActionEditGenre displayType={'text'} genre={genreItem} />
          <ActionDeleteGenre displayType={'text'} genre={genreItem} />
        </NavDropdownMenu>
      );
    };
    const list = {
      pageData: this.props.genres,
      totalCount: this.props.totalCount,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };
    return (
      <div>
        <ol className="mi9-breadcrumb breadcrumb">
          <li>Genres</li>
        </ol>
        <ItemList id="list-genre" list={list} disableColumnChooser getItems={this.loadData}>
          <ItemListHeader
            label="Image"
            dataField="image"
            dataFormat={(genre) => {
              return (
                <span className="row-image-span">
                  <img
                    ref="img"
                    className="row-image"
                    src={(genre.image && genre.image.url) || IMAGE_NOT_FOUND_URL}
                    alt={(genre.image && genre.image.alt) || ''}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader
            dataField="name"
            label="Name"
            dataFormat={(genre) => <a href={getLinkToGenreDetailsView(genre)}>{genre.name}</a>}
          />
          <ItemListHeader dataField="createdAt" dataType="date" label="Date Created" />
          <ItemListHeader label="Actions" dataFormat={(genreItem) => actionList(genreItem)} />
        </ItemList>
      </div>
    );
  }
}

GenreList.propTypes = {
  page: PropTypes.number,
  offset: PropTypes.number,
  totalCount: PropTypes.number,
  genres: PropTypes.array,
  params: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getGenres: PropTypes.func,
  savedGenre: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    genres: state.genreList.genres,
    totalCount: state.genreList.totalCount,
    offset: state.genreList.offset,
    savedGenre: state.genreList.savedGenre,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getGenres: (pageData) => dispatch(getGenres(pageData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenreList);
