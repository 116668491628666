import moment from 'moment';

export function formatTimeInMiliseconds(time) {
  if (!time) return '';
  return moment()
    .startOf('day')
    .seconds(time / 1000)
    .format('H:mm:ss');
}

export function formatDurationFromMiliseconds(timeInMs) {
  const duration = moment.duration(timeInMs);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const formattedTimeElements = [days, hours, minutes, seconds].map((durationItem) => {
    if (!durationItem) {
      return '00';
    } else if (durationItem < 10) {
      return `0${durationItem}`;
    }
    return durationItem;
  });

  return formattedTimeElements.join(':');
}

export function fromSecondsToHoursMinutesSecondsMs(cuepointInSeconds) {
  const hours = Math.floor(cuepointInSeconds / 3600);
  const hoursInSeconds = hours * 3600;
  const minutes = Math.floor((cuepointInSeconds - hoursInSeconds) / 60);
  const minutesInSeconds = minutes * 60;
  const seconds = (cuepointInSeconds * 1000 - hoursInSeconds * 1000 - minutesInSeconds * 1000) / 1000; // have to multiply each number so the math is performed only with integers to avoid javascript floating point precision errors

  return [hours, minutes, seconds].join(':');
}

export function fromHoursMinutesSecondsMsToSeconds(hoursMinutesSecondsMs) {
  const timeValueList = hoursMinutesSecondsMs.split(':').map((unit) => Number(unit));
  const secondConversionMultipliers = [3600, 60, 1];
  const totalSeconds = timeValueList.reduce((previousValue, currentValue, currentIndex) => {
    return previousValue + (currentValue * secondConversionMultipliers[currentIndex] || 0);
  }, 0);
  return totalSeconds;
}

function ifExists(value) {
  return Number(value);
}

export function convertTimeCodeToSeconds(timeCode) {
  const frameRate = 25;
  const cuePointArray = timeCode.split(':');
  const hoursToSeconds = ifExists(cuePointArray[0]) ? Number(cuePointArray[0]) * 60 * 60 : 0;
  const minutesToSeconds = ifExists(cuePointArray[1]) ? Number(cuePointArray[1]) * 60 : 0;
  const seconds = ifExists(cuePointArray[2]) ? Number(cuePointArray[2]) : 0;
  const frameRateInSeconds = ifExists(cuePointArray[3]) ? Number(cuePointArray[3]) / frameRate : 0;
  return (hoursToSeconds + minutesToSeconds + seconds + frameRateInSeconds).toFixed(3);
}

export function secondsToCuePointTimeDisplayFormat(timeInSeconds) {
  const pad = (num, size) => {
    return `000${num}`.slice(size * -1);
  };
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  const milliseconds = time.slice(-3);
  const cuePointTimeDisplay = `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
    milliseconds,
    3
  )}`;

  return cuePointTimeDisplay;
}
