export default [
  { code: 'P', name: "Preschool children's" },
  { code: 'C', name: "Children's" },
  { code: 'G', name: 'General' },
  { code: 'PG', name: 'Parental Guidance' },
  { code: 'M', name: 'Mature' },
  { code: 'MA', name: 'Mature Audience' },
  { code: 'AV', name: 'Adult Violence' },
  { code: 'X', name: 'Not suitable for Broadcasting' },
  { code: 'NA', name: 'Not applicable' },
  { code: 'U', name: 'Unclassified' },
  { code: 'TB', name: 'To Be Classified' },
];

export const classificationCodeNameMap = {
  P: "Preschool children's",
  C: "Children's",
  G: 'General',
  PG: 'Parental Guidance',
  M: 'Mature',
  MA: 'Mature Audience',
  AV: 'Adult Violence',
  X: 'Not suitable for Broadcasting',
  NA: 'Not applicable',
  U: 'Unclassified',
  TB: 'To Be Classified',
};
