import * as requestHandler from '../requestHandler';

const PREFIX = 'api/v1/live/tv';
const RESOURCE_NAME = 'event-groups';
const PATH = `${PREFIX}/${RESOURCE_NAME}`;

export async function getLiveEventGroups(params, signal) {
  const response = await requestHandler.sendWithFetch('GET', PATH, params, signal);
  return await response.json();
}

export function getLiveEventGroupById(liveEventGroupId) {
  return requestHandler.send('GET', `${PATH}/${liveEventGroupId}`);
}

export function deleteLiveEventGroup(liveEventGroupId) {
  return requestHandler.send('DELETE', `${PATH}/${liveEventGroupId}`);
}

export function createLiveEventGroup(liveEventGroup) {
  return requestHandler.send('POST', PATH, JSON.stringify(liveEventGroup));
}

export function updateLiveEventGroup(liveEventGroup, liveEventGroupId) {
  return requestHandler.send('PATCH', `${PATH}/${liveEventGroupId}`, JSON.stringify(liveEventGroup));
}
