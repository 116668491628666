import { TV_PATH } from 'src/scripts/lib/accessController';
import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap';

export function getLinkToGenreDetailsView(genre) {
  return `#/${TV_PATH}/genres/${genre.id}`;
}

export default class View extends React.Component {
  render() {
    return (
      <MenuItem ref={'view-genre'} key={'view-genre'} href={getLinkToGenreDetailsView(this.props.genre)}>
        View Genre
      </MenuItem>
    );
  }
}

View.propTypes = {
  genre: PropTypes.object.isRequired,
};
