import { IMAGES, VIDEOS, TAGS, LIVE_EVENTS } from 'src/scripts/lib/resourceGroups';

const TV_PREFIX = 'api/v1/libraries/tv';
const NETWORK_PREFIX = 'api/v1/libraries/network';
const TV_LIVE_EVENTS_PREFIX = 'api/v1/live/tv/events';
const NETWORK_LIVE_EVENTS_PREFIX = 'api/v1/live/network/events';

export function getPrefix(resourceGroup) {
  switch (resourceGroup) {
    case IMAGES.TV:
    case VIDEOS.TV:
    case TAGS.TV:
      return TV_PREFIX;
    case IMAGES.NETWORK:
    case VIDEOS.NETWORK:
    case TAGS.NETWORK:
      return NETWORK_PREFIX;
    case IMAGES.TV_LIVE_EVENTS:
      return TV_LIVE_EVENTS_PREFIX;
    case IMAGES.NETWORK_LIVE_EVENTS:
      return NETWORK_LIVE_EVENTS_PREFIX;
    case LIVE_EVENTS.TV_LIVE_EVENTS:
      return TV_LIVE_EVENTS_PREFIX;
    case LIVE_EVENTS.NETWORK_LIVE_EVENTS:
      return NETWORK_LIVE_EVENTS_PREFIX;
    default:
      throw new Error(`Resource group ${resourceGroup} is not supported`);
  }
}
