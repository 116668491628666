import Q from 'q';
import _ from 'lodash';
import * as browser from 'src/scripts/lib/browser';
import { anySizeAllowedSizeCheck } from './processImage';
import { IMAGE_ANY } from '../../scripts/constants/imageSizes';

export const ANY_ASPECT_RATIO = 'ANY';
export const IMAGE_MIN_WIDTH = 1024;
export const IMAGE_MIN_HEIGHT = 576;
export const ASPECT_RATIO_THRESHOLD = 16 / 9;
const SUPPORTED_FILE_TYPES = ['image/jpeg', 'image/png'];

export function getImage(imageFile) {
  const deferred = Q.defer();

  if (!imageFile || !_.includes(imageFile.type, 'image/')) {
    deferred.reject();
  } else {
    const fileReader = browser.fileReader();

    fileReader.onloadend = () => {
      const image = browser.image();

      image.onload = () => {
        deferred.resolve(image);
      };

      image.src = fileReader.result;
    };

    fileReader.readAsDataURL(imageFile);
  }

  return deferred.promise;
}

function validateFileType(fileType, supportedFileTypes) {
  return _.includes(supportedFileTypes, fileType);
}

export function getImageRatio(width, height) {
  return (width / height).toFixed(1);
}

function validRatio(image, ratioThreshold) {
  if (ratioThreshold === ANY_ASPECT_RATIO) {
    return true;
  }

  const ratio = getImageRatio(image.width, image.height);
  return ratio === ratioThreshold.toFixed(1);
}

function validDimensions(image, { minWidth, minHeight }) {
  return image.width >= minWidth && image.height >= minHeight;
}

export function validateAndDetermineImageSize(image, acceptedImageSizes) {
  const ratio = getImageRatio(image.width, image.height);
  let imageSize = acceptedImageSizes.find((size) => size.ratio.toFixed(1) === ratio);
  const anyImageSizeAllowed = anySizeAllowedSizeCheck(acceptedImageSizes);

  if (!imageSize && anyImageSizeAllowed) {
    imageSize = acceptedImageSizes.find((size) => size.name === IMAGE_ANY);
  }

  if (!imageSize) {
    throw new Error('Image file ratio is invalid.');
  }

  if (!anyImageSizeAllowed && !validDimensions(image, imageSize)) {
    throw new Error('Image resolution is too low.');
  }

  return imageSize;
}

export function validate(
  image,
  fileType,
  {
    minWidth = IMAGE_MIN_WIDTH,
    minHeight = IMAGE_MIN_HEIGHT,
    ratioThreshold = ASPECT_RATIO_THRESHOLD,
    supportedFileTypes = SUPPORTED_FILE_TYPES,
  } = {}
) {
  if (!validDimensions(image, { minWidth, minHeight }) || !validRatio(image, ratioThreshold)) {
    throw new Error('Image file ratio is invalid.');
  }

  if (!validateFileType(fileType, supportedFileTypes)) {
    throw new Error('Image file type is not supported.');
  }
}
