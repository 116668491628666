import {
  CAPTION_DELETE_STARTED,
  CAPTION_DELETE_SUCCEEDED,
  CAPTION_DELETE_FAILED,
  CAPTION_STATE_RESET,
  CAPTION_DELETE_FLOW_COMPLETED,
} from 'src/scripts/actions/ui/caption';

const initialVideoMap = {};
const initialVideoCaptionState = {
  isDeleting: false,
  hasJustBeenDeleted: false,
  hasJustFailedToBeDeleted: false,
  errorMessage: '',
};
const generateInitialCaptionState = () => ({ ...initialVideoCaptionState });

const addVideoToVideoMap = (state, videoId) => ({
  ...state,
  [videoId]: generateInitialCaptionState(),
});

const validateVideoExists = (state, videoId) => !!state[videoId];

const updateCaptionToDeleting = (state, videoId) => {
  let updatedState = { ...state };
  if (!validateVideoExists(state, videoId)) {
    updatedState = addVideoToVideoMap(state, videoId);
  }
  updatedState[videoId] = {
    ...updatedState[videoId],
    isDeleting: true,
  };
  return updatedState;
};

const updateCaptionToNotDeleting = (state, videoId) => {
  const updatedState = { ...state };
  updatedState[videoId] = {
    ...updatedState[videoId],
    isDeleting: false,
  };
  return updatedState;
};

const updateCaptionToHasJustBeenDeleted = (state, videoId) => {
  const updatedState = { ...state };
  updatedState[videoId] = {
    ...updatedState[videoId],
    hasJustBeenDeleted: true,
    hasJustFailedToBeDeleted: false,
  };
  return updatedState;
};

const updateCaptionToHasJustFailedToBeDeleted = (state, videoId) => {
  const updatedState = { ...state };
  updatedState[videoId] = {
    ...updatedState[videoId],
    hasJustFailedToBeDeleted: true,
    hasJustBeenDeleted: false,
  };
  return updatedState;
};

const updateCaptionWithErrorMessage = (state, videoId, errorMessage) => {
  const updatedState = { ...state };
  updatedState[videoId] = {
    ...updatedState[videoId],
    errorMessage,
  };
  return updatedState;
};

const resetCaptionState = (state, videoId) => {
  const updatedState = { ...state };
  updatedState[videoId] = generateInitialCaptionState();
  return updatedState;
};

const removeCaptionFromVideoMap = (state, videoId) => {
  const updatedState = { ...state };
  delete updatedState[videoId];
  return updatedState;
};

const videoCaptionReducer = (state = initialVideoMap, action) => {
  switch (action.type) {
    case CAPTION_DELETE_STARTED:
      return updateCaptionToDeleting(state, action.payload.videoId);
    case CAPTION_DELETE_SUCCEEDED:
      return updateCaptionToNotDeleting(
        updateCaptionToHasJustBeenDeleted(state, action.payload.videoId),
        action.payload.videoId
      );
    case CAPTION_DELETE_FAILED: {
      let composedState = { ...state };
      composedState = updateCaptionToHasJustFailedToBeDeleted(composedState, action.payload.videoId);
      composedState = updateCaptionToNotDeleting(composedState, action.payload.videoId);
      composedState = updateCaptionWithErrorMessage(
        composedState,
        action.payload.videoId,
        action.payload.errorMessage
      );
      return composedState;
    }
    case CAPTION_STATE_RESET:
      return resetCaptionState(state, action.payload.videoId);
    case CAPTION_DELETE_FLOW_COMPLETED:
      return removeCaptionFromVideoMap(state, action.payload.videoId);
    default:
      return state;
  }
};

export default videoCaptionReducer;

// State selectors
export const getCaptionIsDeletingState = (state, videoId) =>
  state.ui.caption.videos[videoId] && !!state.ui.caption.videos[videoId].isDeleting;
export const getCaptionHasJustBeenDeletedState = (state, videoId) =>
  state.ui.caption.videos[videoId] && !!state.ui.caption.videos[videoId].hasJustBeenDeleted;
export const getCaptionHasJustFailedToDeleteState = (state, videoId) =>
  state.ui.caption.videos[videoId] && !!state.ui.caption.videos[videoId].hasJustFailedToBeDeleted;
export const getCaptionErrorMessage = (state, videoId) => {
  if (state.ui.caption.videos[videoId] && state.ui.caption.videos[videoId].errorMessage) {
    return state.ui.caption.videos[videoId].errorMessage;
  }
  return '';
};
