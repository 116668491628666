import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'react-bootstrap';
import { updateCategory, saveCategory } from 'src/scripts/actions/category';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/categoryForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

export class CategoryForm extends React.Component {
  constructor(props) {
    super(props);

    this.saveCategory = this.saveCategory.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onSelectSitecoreCategoryChange = this.onSelectSitecoreCategoryChange.bind(this);
  }

  onNameChange(event) {
    this.props.updateCategory({
      name: event.target.value,
    });
  }

  onSelectSitecoreCategoryChange(event) {
    this.props.updateCategory({
      sitecoreCategoryTag: !isDefaultOptionSelected(this.refs.selectSitecoreCategory)
        ? event.target.value
        : null,
    });
  }

  saveCategory(event) {
    event.preventDefault();
    const category = {
      id: this.props.category.id,
      name: this.props.category.name,
      sitecoreCategoryTag: this.props.category.sitecoreCategoryTag,
    };
    if (!isFormValid(category, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveCategory(category);
  }

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.saveCategory} ref="categoryForm">
          <Input
            labelClassName="required"
            type="text"
            label="Category Name"
            placeholder="Category name"
            ref="categoryName"
            id="text-category-name"
            maxLength="100"
            onChange={this.onNameChange}
            value={this.props.category.name}
            bsStyle={this.props.validationErrors.name ? 'error' : null}
          />
          <Input
            type="select"
            label="Sitecore category"
            ref="selectSitecoreCategory"
            id="select-sitecore-category"
            onChange={this.onSelectSitecoreCategoryChange}
            value={this.props.category.sitecoreCategoryTag}
            disabled={!!this.props.category.id}
          >
            <option value="0"></option>
            <option value="au_ninemsnentertainment">Entertainment</option>
            <option value="au_ninemsnlifestyle">Lifestyle</option>
            <option value="au_news">News</option>
            <option value="au_wwos">Sport</option>
          </Input>
          {this.props.errorMessage && (
            <FormErrorMessage className="category-server-error-message" message={this.props.errorMessage} />
          )}
          <div className="modal-footer">
            <Button
              id="modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

CategoryForm.propTypes = {
  close: PropTypes.func,
  category: PropTypes.object,
  errorMessage: PropTypes.string,
  validationErrors: PropTypes.object,
  saveCategory: PropTypes.func,
  updateCategory: PropTypes.func,
  showFormValidation: PropTypes.func,
};

function mapStateToProps(state) {
  const categoryForm = state.categoryForm;
  return {
    validationErrors: categoryForm.validationErrors,
    category: categoryForm.category,
    errorMessage: categoryForm.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCategory: (category) => dispatch(updateCategory(category)),
    saveCategory: (category) => dispatch(saveCategory(category)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
