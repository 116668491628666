import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import RightsWindow from 'src/scripts/components/RightsWindow';

class PublishingSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.onPublishStartDateChange = this.onPublishStartDateChange.bind(this);
    this.onPublishEndDateChange = this.onPublishEndDateChange.bind(this);
  }

  onPublishStartDateChange(startDate) {
    this.props.onChange(startDate, this.props.publishEndDate);
  }

  onPublishEndDateChange(endDate) {
    this.props.onChange(this.props.publishStartDate, endDate);
  }

  render() {
    return (
      <Panel
        id="panel-publishing-schedule"
        header="Publishing Schedule"
        bsStyle="primary"
        expanded={false}
        eventKey="4"
        hidden={this.props.hidden}
        className="schedulePanel"
      >
        <div
          ref="publishStartDateWrapper"
          className={`form-group ${this.props.validationErrors.publishStartDate ? 'error' : ''}`}
        >
          <label className="required">Start Date & Time</label>
          <DateTimePicker
            ref="publishStartDate"
            id="start-date"
            onChange={this.onPublishStartDateChange}
            value={this.props.publishStartDate}
            placeholder="Enter start date"
          />
        </div>
        <div
          ref="publishEndDateWrapper"
          className={`form-group ${this.props.validationErrors.publishEndDate ? 'error' : ''}`}
        >
          <label>End Date & Time</label>
          {!this.props.rightsRanges || this.props.rightsRanges.lenght === 0 ? null : (
            <RightsWindow
              publishStartDate={this.props.publishStartDate}
              onChange={this.onPublishEndDateChange}
              rightsRanges={this.props.rightsRanges}
            />
          )}
          <DateTimePicker
            ref="publishEndDate"
            id="end-date"
            value={this.props.publishEndDate}
            onChange={this.onPublishEndDateChange}
            placeholder="Enter end date"
          />
        </div>
      </Panel>
    );
  }
}

PublishingSchedule.propTypes = {
  hidden: PropTypes.bool,
  publishStartDate: PropTypes.string,
  publishEndDate: PropTypes.string,
  onChange: PropTypes.func,
  rightsRanges: PropTypes.array,
  validationErrors: PropTypes.object,
};

export const defaultProps = {
  validationErrors: {},
};

PublishingSchedule.defaultProps = defaultProps;

export default PublishingSchedule;
