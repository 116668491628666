import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loginToAzure } from 'src/scripts/actions/user';

export class Login extends React.Component {
  render() {
    return (
      <div className="login">
        <div className="container text-center">
          <div className="container-fluid text-center">
            <h1 className="header-title">
              <a href="/#/">
                <img className="logo" src={'/images/vms_logo_small.png'} />
              </a>
              <b>Welcome to VMS</b>
            </h1>
            <h4 className="subheader">
              {' '}
              Nine's Video Management System for <b>9Now</b> and <b>Nine Network</b> websites{' '}
            </h4>
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="thumbnail">
                  <i className="fa fa-cloud-upload fa-5x text-success" aria-hidden="true"></i>
                  <div className="caption">
                    <h3>Ingest </h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="thumbnail">
                  <i className="fa fa-pencil-square-o fa-5x text-info" aria-hidden="true"></i>
                  <div className="caption">
                    <h3>Manage </h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="thumbnail">
                  <i className="fa fa-file-video-o fa-5x text-warning" aria-hidden="true"></i>
                  <div className="caption">
                    <h3>Publish </h3>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div>
              <button
                type="link"
                className="btn btn-primary login-button "
                ref="loginButton"
                id="home-login-button"
                onClick={loginToAzure}
                data-pw="home-login-button"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
};

export default connect(null)(Login);
