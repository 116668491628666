import {
  isEmptyOrIsValidUrl,
  mustMatchRegex,
  required,
} from 'src/scripts/lib/formValidation/generalValidators';

const validationRules = [
  { field: 'name', label: 'Series Name', customValidators: required() },
  { field: 'genreId', label: 'Genre', customValidators: required() },
  {
    field: 'marketingDescription',
    label: 'Marketing Description',
    customErrorMessage:
      'Marketing description should consist of at most four lines, each of which has at most thirty characters.',
    customValidators: mustMatchRegex(/^([^\n]{0,30}\n){0,3}([^\n]{0,30}$)/),
  },
  {
    field: 'showSiteLink',
    label: 'Showsite Link',
    customErrorMessage: 'Please enter a valid URL for the show site link.',
    customValidators: isEmptyOrIsValidUrl(),
  },
];

const getValidationRules = () => validationRules;
export default getValidationRules;
