import {
  BREADCRUMB_TV_SERIES_UPDATE_FINISHED,
  BREADCRUMB_SEASON_UPDATE_FINISHED,
  BREADCRUMB_CATEGORY_UPDATE_FINISHED,
  BREADCRUMB_SUBCATEGORY_UPDATE_FINISHED,
} from 'src/scripts/actions/breadcrumb';

export const initialState = {
  tvSeriesName: '',
  tvSeriesId: '',
  seasonName: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BREADCRUMB_TV_SERIES_UPDATE_FINISHED:
      return {
        ...state,
        tvSeriesName: action.tvSeries.name,
        tvSeriesId: action.tvSeries.id,
      };

    case BREADCRUMB_CATEGORY_UPDATE_FINISHED:
      return {
        ...state,
        categoryName: action.category.name,
      };

    case BREADCRUMB_SUBCATEGORY_UPDATE_FINISHED:
      return {
        ...state,
        subcategoryName: action.subcategory.name,
      };

    case BREADCRUMB_SEASON_UPDATE_FINISHED:
      const seasonName = action.seasons
        .filter((season) => {
          return season.id === action.seasonId;
        })
        .map((season) => {
          return season.name;
        });

      return {
        ...state,
        seasonName: seasonName[0],
      };

    default:
  }
  return state;
}
