const channelMediaLiveStreamNameMappings = {
  'channel-9': {
    Adelaide: 'prod-simulcast-adl-ch9',
    Brisbane: 'prod-simulcast-bne-ch9',
    Melbourne: 'prod-simulcast-mel-ch9',
    Perth: 'prod-simulcast-per-ch9',
    Sydney: 'prod-simulcast-syd-ch9',
  },
};

export function getMediaLiveChannelName(channel, market, streamType) {
  const channelNameSuffix = streamType === 'Primary' ? 'r1' : 'r2';
  return `${channelMediaLiveStreamNameMappings[channel][market]}-${channelNameSuffix}`;
}
