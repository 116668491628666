const CDN_AKAMAI = 'Akamai';
const CDN_CLOUDFRONT = 'CloudFront';
const CDN_LIST = [CDN_AKAMAI, CDN_CLOUDFRONT];

const KEY_ACTIVATED = 'activated';
const DEFAULT_ACTIVATED = false;

const KEY_WEIGHTING = 'weighting';
const DEFAULT_WEIGHTING = 100;
const WEIGHTING_MIN = 0;
const WEIGHTING_MAX = 100;

export const CDN_SCHEMA = {
  type: 'object',
  properties: {
    [KEY_ACTIVATED]: {
      type: 'boolean',
    },
    [KEY_WEIGHTING]: {
      type: 'integer',
      minimum: WEIGHTING_MIN,
      maximum: WEIGHTING_MAX,
    },
  },
  additionalProperties: true,
};

export const CDN_LIST_SCHEMA = {
  type: 'object',
  properties: CDN_LIST.reduce(
    (result, key) => ({
      ...result,
      [key]: CDN_SCHEMA,
    }),
    {}
  ),
  additionalProperties: false,
};

export function getCdnNameList() {
  return CDN_LIST;
}

export function getAllowedPropertyKeys() {
  return [KEY_ACTIVATED, KEY_WEIGHTING];
}

export function getDefaultProperties() {
  return {
    [KEY_ACTIVATED]: DEFAULT_ACTIVATED,
    [KEY_WEIGHTING]: DEFAULT_WEIGHTING,
  };
}

export function getCdnSchema() {
  return CDN_SCHEMA;
}

export function getCdnListSchema() {
  return CDN_LIST_SCHEMA;
}

export function sanitiseWeighting(weighting) {
  return Math.min(100, Math.max(0, Number(weighting)));
}
