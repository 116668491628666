import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ChannelSelect from 'src/scripts/components/Youtube/ChannelSelect';
import PolicySelect from 'src/scripts/components/Youtube/PolicySelect';
import { allCountries } from 'src/scripts/lib/countries';

const countryOptions = allCountries.map((country) => ({ label: country.name, value: country.alpha2 }));

export default class DefaultContentIdSection extends React.Component {
  constructor(props) {
    super(props);
    this.onDefaultContentIdChannelUpdate = this.onDefaultContentIdChannelUpdate.bind(this);
    this.onDefaultContentIdUsagePolicyUpdate = this.onDefaultContentIdUsagePolicyUpdate.bind(this);
    this.onDefaultContentIdMatchPolicyUpdate = this.onDefaultContentIdMatchPolicyUpdate.bind(this);
    this.onContentIdOwnershipStrategyUpdate = this.onContentIdOwnershipStrategyUpdate.bind(this);
    this.onContentIdOwnershipCountriesUpdate = this.onContentIdOwnershipCountriesUpdate.bind(this);
  }

  onDefaultContentIdChannelUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateSeason({ defaultContentIdChannel: updateValue });
  }

  onDefaultContentIdUsagePolicyUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateSeason({ defaultContentIdUsagePolicy: updateValue });
  }

  onDefaultContentIdMatchPolicyUpdate(selected) {
    const updateValue = selected ? selected.value : null;
    this.props.updateSeason({ defaultContentIdMatchPolicy: updateValue });
  }

  onContentIdOwnershipStrategyUpdate(selected) {
    const ownershipStrategyUpdateValue = selected ? selected.value : null;
    const updateData = { contentIdOwnershipStrategy: ownershipStrategyUpdateValue };
    if (ownershipStrategyUpdateValue === 'worldwide') updateData.contentIdOwnershipCountries = null;
    this.props.updateSeason(updateData);
  }

  onContentIdOwnershipCountriesUpdate(values) {
    this.props.updateSeason({ contentIdOwnershipCountries: values.map((item) => item.value) });
  }

  getFormGroupClassName(fieldName) {
    return `form-group ${this.props.validationErrors[fieldName] ? 'error' : null}`;
  }

  anyDefaultsSet() {
    if (
      this.props.season.defaultContentIdChannel ||
      this.props.season.defaultContentIdUsagePolicy ||
      this.props.season.defaultContentIdMatchPolicy
    )
      return true;
    return false;
  }

  renderContentIdOwnershipCountriesPicker = () => {
    if (!this.props.season.contentIdOwnershipStrategy) return null;
    if (this.props.season.contentIdOwnershipStrategy === 'worldwide') return null;
    return (
      <Select
        multi
        ref="ownershipCountriesSelect"
        placeholder="Select countries for ownership strategy"
        value={this.props.season.contentIdOwnershipCountries}
        onChange={this.onContentIdOwnershipCountriesUpdate}
        options={countryOptions}
        clearable={false}
        bsStyle={this.props.validationErrors.contentIdOwnershipCountries ? 'error' : null}
        menuStyle={{ maxHeight: '100px' }}
      />
    );
  };

  render() {
    return (
      <div>
        <div className={this.getFormGroupClassName('defaultContentIdChannel')}>
          <label
            ref="channelSelectLabel"
            className={`control-label ${this.anyDefaultsSet() ? 'required' : ''}`}
          >
            Channel
          </label>
          <ChannelSelect
            ref="channelSelect"
            placeholder="Select a channel"
            value={this.props.season.defaultContentIdChannel}
            onChange={this.onDefaultContentIdChannelUpdate}
          />
        </div>
        <div className={this.getFormGroupClassName('defaultContentIdUsagePolicy')}>
          <label
            ref="usagePolicySelectLabel"
            className={`control-label ${this.anyDefaultsSet() ? 'required' : ''}`}
          >
            Usage Policy
          </label>
          <PolicySelect
            ref="usagePolicySelect"
            placeholder="Select a usage policy"
            value={this.props.season.defaultContentIdUsagePolicy}
            onChange={this.onDefaultContentIdUsagePolicyUpdate}
          />
        </div>
        <div className={this.getFormGroupClassName('defaultContentIdMatchPolicy')}>
          <label
            ref="matchPolicySelectLabel"
            className={`control-label ${this.anyDefaultsSet() ? 'required' : ''}`}
          >
            Match Policy
          </label>
          <PolicySelect
            ref="matchPolicySelect"
            placeholder="Select a match policy"
            value={this.props.season.defaultContentIdMatchPolicy}
            onChange={this.onDefaultContentIdMatchPolicyUpdate}
          />
        </div>
        <div className="form-group">
          <label className="control-label">Ownership</label>
          <Select
            ref="ownershipStrategySelect"
            placeholder="Select an ownership strategy"
            value={this.props.season.contentIdOwnershipStrategy}
            onChange={this.onContentIdOwnershipStrategyUpdate}
            options={[
              { label: 'Own this content worldwide', value: 'worldwide' },
              { label: 'Own this content in:', value: 'inclusion' },
              { label: 'Own this content everywhere except:', value: 'exclusion' },
            ]}
            bsStyle={this.props.validationErrors.contentIdOwnershipStrategy ? 'error' : null}
            menuStyle={{ maxHeight: '100px' }}
          />
          <br />
          <div className={this.getFormGroupClassName('contentIdOwnershipCountries')}>
            {this.renderContentIdOwnershipCountriesPicker()}
          </div>
        </div>
      </div>
    );
  }
}

DefaultContentIdSection.propTypes = {
  updateSeason: PropTypes.func,
  season: PropTypes.object,
  validationErrors: PropTypes.object,
};
