import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { DASH_FOR_EMPTY_CONTENT } from 'src/scripts/constants/common';
import { secondsToCuePointTimeDisplayFormat } from '../../lib/timeFormatter';

class CuePointNavigator extends React.Component {
  isCuePointPreRoll = (cuePoint) => {
    return cuePoint.time === 0;
  };

  isCuePointsEmpty = () => {
    const cuePoints = this.props.videoCuePoints;
    if (cuePoints && cuePoints.length > 0) {
      if (cuePoints.length === 1 && this.isCuePointPreRoll(cuePoints[0])) {
        return true;
      }
      return false;
    }

    return true;
  };

  isTimeGreaterThanVideoDuration = (timeInSeconds) => {
    if (this.props.videoDuration && this.props.videoDuration > 0) {
      if (timeInSeconds > this.props.videoDuration) {
        return true;
      }
    }
    return false;
  };

  getTimeDisplayValue = (timeInSeconds) => {
    if (timeInSeconds >= 0) {
      return secondsToCuePointTimeDisplayFormat(timeInSeconds);
    }

    return null;
  };

  getCuePointTimeValues = (cuePointTime) => {
    const tenSecondsBeforeCuePointTime = cuePointTime - 10;
    const fiveSecondsBeforeCuePointTime = cuePointTime - 5;
    const fiveSecondsAfterCuePointTime = cuePointTime + 5;
    const tenSecondsAfterCuePointTime = cuePointTime + 10;
    const isCuePointOutOfVideoDuration = this.isTimeGreaterThanVideoDuration(cuePointTime);

    // if the cue point itself is outside of the video duration, set all the other display values to null
    const cuePointTimeValues = {
      tenSecondsBefore: {
        timeInSeconds: tenSecondsBeforeCuePointTime,
        displayValue: isCuePointOutOfVideoDuration
          ? null
          : this.getTimeDisplayValue(tenSecondsBeforeCuePointTime),
        isOutsideVideoDuration: this.isTimeGreaterThanVideoDuration(tenSecondsBeforeCuePointTime),
      },
      fiveSecondsBefore: {
        timeInSeconds: fiveSecondsBeforeCuePointTime,
        displayValue: isCuePointOutOfVideoDuration
          ? null
          : this.getTimeDisplayValue(fiveSecondsBeforeCuePointTime),
        isOutsideVideoDuration: this.isTimeGreaterThanVideoDuration(fiveSecondsBeforeCuePointTime),
      },
      cuePointTime: {
        timeInSeconds: cuePointTime,
        displayValue: this.getTimeDisplayValue(cuePointTime),
        isOutsideVideoDuration: isCuePointOutOfVideoDuration,
        isCuePointTime: true,
      },
      fiveSecondsAfter: {
        timeInSeconds: fiveSecondsAfterCuePointTime,
        displayValue: isCuePointOutOfVideoDuration
          ? null
          : this.getTimeDisplayValue(fiveSecondsAfterCuePointTime),
        isOutsideVideoDuration: this.isTimeGreaterThanVideoDuration(fiveSecondsAfterCuePointTime),
      },
      tenSecondsAfter: {
        timeInSeconds: tenSecondsAfterCuePointTime,
        displayValue: isCuePointOutOfVideoDuration
          ? null
          : this.getTimeDisplayValue(tenSecondsAfterCuePointTime),
        isOutsideVideoDuration: this.isTimeGreaterThanVideoDuration(tenSecondsAfterCuePointTime),
      },
    };

    return cuePointTimeValues;
  };

  renderEmptyContentSpan() {
    return <span>{DASH_FOR_EMPTY_CONTENT}</span>;
  }

  renderButtonOrSpan(time) {
    if (!time.displayValue) {
      return this.renderEmptyContentSpan();
    }

    if (time.isOutsideVideoDuration) {
      // if the cue point itself is out of the video duration, show the time of the cuepoint as a span
      if (time.isCuePointTime) {
        return <span>{time.displayValue}</span>;
      }

      return this.renderEmptyContentSpan();
    }

    return (
      <button
        className={`btn ${time.isCuePointTime ? 'btn-primary' : 'btn-default'}`}
        onClick={() => this.props.setVideoTime(time.timeInSeconds)}
      >
        {time.displayValue}
      </button>
    );
  }

  renderCuePointRows = () => {
    return this.props.videoCuePoints.map((cuePoint, index) => {
      const cuePointTimeInSeconds = cuePoint.time;
      const cuePointTimeValues = this.getCuePointTimeValues(cuePointTimeInSeconds);
      return (
        <tr key={index} className="cue-point-list-row">
          <td className="table-value cue-point-number">
            <span>{index + 1}</span>
          </td>
          <td className="table-value ten-seconds-before-cue-point-time">
            {this.renderButtonOrSpan(cuePointTimeValues.tenSecondsBefore)}
          </td>
          <td className="table-value five-seconds-before-cue-point-time">
            {this.renderButtonOrSpan(cuePointTimeValues.fiveSecondsBefore)}
          </td>
          <td className="table-value cue-point-time">
            {this.renderButtonOrSpan(cuePointTimeValues.cuePointTime)}
          </td>
          <td className="table-value five-seconds-after-cue-point-time">
            {this.renderButtonOrSpan(cuePointTimeValues.fiveSecondsAfter)}
          </td>
          <td className="table-value ten-seconds-after-cue-point-time">
            {this.renderButtonOrSpan(cuePointTimeValues.tenSecondsAfter)}
          </td>
        </tr>
      );
    });
  };

  renderEmptyRow = () => {
    return (
      <tr className="cue-point-list-empty-row">
        <td>{this.renderEmptyContentSpan()}</td>
        <td>{this.renderEmptyContentSpan()}</td>
        <td>{this.renderEmptyContentSpan()}</td>
        <td>{this.renderEmptyContentSpan()}</td>
        <td>{this.renderEmptyContentSpan()}</td>
        <td>{this.renderEmptyContentSpan()}</td>
      </tr>
    );
  };

  render() {
    return (
      <div className="details-row row">
        <div className="details-key row">Cue Point Navigator</div>
        <div className="details-value row">
          <Table bordered condensed className="details-table cue-point-navigation-table">
            <tbody>
              <tr className="details-table-label-column">
                <th className="cue-point-number-header">#</th>
                <th className="time-value-header">- 10 sec</th>
                <th className="time-value-header">- 5 sec</th>
                <th className="time-value-header">Cue Point</th>
                <th className="time-value-header">+ 5 sec</th>
                <th className="time-value-header">+ 10 sec</th>
              </tr>
              {this.isCuePointsEmpty() ? this.renderEmptyRow() : this.renderCuePointRows()}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

CuePointNavigator.propTypes = {
  videoCuePoints: PropTypes.array,
  setVideoTime: PropTypes.func,
  videoDuration: PropTypes.number,
};

export default CuePointNavigator;
