import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Panel, Input, Button } from 'react-bootstrap';
import { getEpisodes } from 'src/scripts/actions/episode';

export class AssignEpisodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      episodeId: '',
      oztamEpisodeRelationship: 'ASSOCIATED',
    };
  }

  componentWillMount() {
    const seasonId = this.props.seasonId;
    if (seasonId) {
      this.props.getEpisodes({ seasonId });
    }
  }

  resetForm = () => {
    this.setState({
      episodeId: '',
      oztamEpisodeRelationship: '',
    });
  };

  onCancelClick = () => {
    this.props.close();
    this.resetForm();
  };

  onEpisodeChange = (event) => {
    const episodeId = Number(event.target.value);
    this.setState({
      episodeId,
    });
  };

  onEpisodeRelationChange = (event) => {
    const oztamEpisodeRelationship = event.target.value;
    this.setState({
      oztamEpisodeRelationship,
    });
  };

  onSave = () => {
    const clipIds = this.props.selectedItems.map((selectedItem) => {
      return selectedItem.id;
    });
    const episodeData = {
      episodeId: this.state.episodeId,
      oztamEpisodeRelationship: this.state.oztamEpisodeRelationship,
    };
    this.props.assignClipsToEpisode(clipIds, episodeData);
    this.props.close();
  };

  isSaveDisabled = () => {
    if (!this.state.episodeId) {
      return true;
    }
    return false;
  };

  renderEpisodeRelationshipInput = () => {
    return (
      <Input
        id="select-episode-relationship"
        type="select"
        label="Episode Relationship"
        ref="selectEpisodeRelationship"
        value={this.state.oztamEpisodeRelationship}
        onChange={this.onEpisodeRelationChange}
      >
        {this.props.episodeRelationships.map((relationship) => {
          return (
            <option key={relationship.value} value={relationship.value}>
              {relationship.label}
            </option>
          );
        })}
      </Input>
    );
  };

  render() {
    return (
      <div>
        <Panel
          id="panel-assign-episode"
          header="Assign Episode"
          bsStyle="primary"
          expanded={false}
          eventKey="4"
          className="schedulePanel"
        >
          <Input
            id="select-episode"
            type="select"
            label="Episode"
            ref="selectEpisode"
            value={this.state.episodeId}
            onChange={this.onEpisodeChange}
          >
            <option value="0" selected={!this.props.episodes}>
              Select an Episode
            </option>
            {this.props.episodes.map((episode) => {
              return (
                <option key={episode.id} value={episode.id}>
                  {episode.name}
                </option>
              );
            })}
          </Input>
          {this.state.episodeId && this.renderEpisodeRelationshipInput()}
          <div className="form__submit-button-bar">
            <Button
              id="cancel-button"
              className="form__button"
              ref="closeButton"
              onClick={this.onCancelClick}
            >
              Cancel
            </Button>
            <Button
              id="save-button"
              className="form__button"
              ref="saveButton"
              bsStyle="primary"
              disabled={this.isSaveDisabled()}
              onClick={this.onSave}
            >
              Save
            </Button>
          </div>
        </Panel>
      </div>
    );
  }
}

AssignEpisodeForm.propTypes = {
  seasonId: PropTypes.number,
  getEpisodes: PropTypes.func,
  episodes: PropTypes.array,
  episodeRelationships: PropTypes.array,
  selectedItems: PropTypes.array,
  assignClipsToEpisode: PropTypes.func,
  close: PropTypes.func, // Prop is injected by ModalItem component
};

export function mapStateToProps(state) {
  return {
    episodes: state.clipForm.episodes,
    episodeRelationships: state.clipForm.episodeRelationships,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    getEpisodes: (seasonId) => dispatch(getEpisodes(seasonId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignEpisodeForm);
