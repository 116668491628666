import PropTypes from 'prop-types';
import React from 'react';
import { Panel, Input } from 'react-bootstrap';
import moment from 'moment';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import { SCHEDULED_EVENT, INSTANT_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';

export class SchedulingPanel extends React.Component {
  constructor(props) {
    super(props);

    this.onPromoStartDateChange = this.onPromoStartDateChange.bind(this);
    this.onAvailabilityStartDateChange = this.onAvailabilityStartDateChange.bind(this);
    this.onAvailabilityEndDateChange = this.onAvailabilityEndDateChange.bind(this);
    this.isAvailabilityStartDateInvalid = this.isAvailabilityStartDateInvalid.bind(this);
    this.isAvailabilityEndDateInvalid = this.isAvailabilityEndDateInvalid.bind(this);
    this.onScheduleTypeChange = this.onScheduleTypeChange.bind(this);
    this.renderPromoStartDateField = this.renderPromoStartDateField.bind(this);
  }

  onPromoStartDateChange(promoStartDate) {
    this.props.onChange({ promoStartDate });
  }

  onAvailabilityStartDateChange(availabilityStartDate) {
    this.props.onChange({ availabilityStartDate });
  }

  onAvailabilityEndDateChange(availabilityEndDate) {
    this.props.onChange({ availabilityEndDate });
  }

  onScheduleTypeChange(event) {
    this.props.onChange({
      scheduleType: event.target.value,
    });
  }

  onPreviewChange = () => {
    this.props.onChange({
      preview: this.refs.preview.getChecked(),
    });
  };

  isAvailabilityStartDateInvalid(selectedAvailabilityStartDate) {
    const promoStartDate = moment(this.props.liveEvent.promoStartDate);
    if (selectedAvailabilityStartDate.isBefore(promoStartDate.startOf('day'))) {
      return 'Cannot be before promo start date';
    }
    return false;
  }

  isAvailabilityEndDateInvalid(selectedAvailabilityEndDate) {
    const availabilityStartDate = moment(this.props.liveEvent.availabilityStartDate);
    if (moment(selectedAvailabilityEndDate).isBefore(availabilityStartDate.startOf('day'))) {
      return 'Cannot be before or the same as availability start date';
    }
    return false;
  }

  renderScheduleTypeSelectionField() {
    return (
      <div>
        <label>Schedule Type</label>
        <Input
          type="radio"
          value={SCHEDULED_EVENT}
          label="Scheduled Event"
          ref="scheduledEventRadio"
          checked={this.props.liveEvent.scheduleType === SCHEDULED_EVENT}
          onClick={this.onScheduleTypeChange}
          disabled={!!this.props.liveEvent.id}
        />
        <Input
          type="radio"
          label="Instant Event"
          ref="instantEventRadio"
          value={INSTANT_EVENT}
          checked={this.props.liveEvent.scheduleType === INSTANT_EVENT}
          onClick={this.onScheduleTypeChange}
          disabled={!!this.props.liveEvent.id}
        />
      </div>
    );
  }

  renderPromoStartDateField() {
    if (this.props.liveEvent.scheduleType === SCHEDULED_EVENT) {
      return (
        <div
          ref="promoStartDateWrapper"
          className={`form-group ${this.props.formValidationErrors.promoStartDate ? 'error' : ''}`}
        >
          <label className="required">Promo Start Date & Time</label>
          <DateTimePicker
            ref="promoStartDate"
            onChange={this.onPromoStartDateChange}
            value={this.props.liveEvent.promoStartDate}
          />
        </div>
      );
    }
    return null;
  }

  renderAvailabilityStartDateField() {
    if (this.props.liveEvent.scheduleType === SCHEDULED_EVENT) {
      return (
        <div
          ref="availabilityStartDateWrapper"
          className={`form-group ${this.props.formValidationErrors.availabilityStartDate ? 'error' : ''}`}
        >
          <label className="required">Availability Start Date & Time</label>
          <DateTimePicker
            ref="availabilityStartDate"
            isInvalidDate={this.isAvailabilityStartDateInvalid}
            onChange={this.onAvailabilityStartDateChange}
            value={this.props.liveEvent.availabilityStartDate}
          />
        </div>
      );
    }
    return null;
  }

  renderAvailabilityEndDateField() {
    if (this.props.liveEvent.scheduleType === SCHEDULED_EVENT) {
      return (
        <div
          ref="availabilityEndDateWrapper"
          className={`form-group ${this.props.formValidationErrors.availabilityEndDate ? 'error' : ''}`}
        >
          <label className="required">Availability End Date & Time</label>
          <DateTimePicker
            ref="availabilityEndDate"
            isInvalidDate={this.isAvailabilityEndDateInvalid}
            onChange={this.onAvailabilityEndDateChange}
            value={this.props.liveEvent.availabilityEndDate}
          />
        </div>
      );
    }
    return null;
  }

  showPreviewButton() {
    // eslint-disable-next-line
    return envVars.APP_ENV && envVars.APP_ENV !== 'uat';
  }

  renderPreviewDataField() {
    return (
      <Input
        type="checkbox"
        label="Preview on Staging"
        ref="preview"
        groupClassName="form-inline"
        onChange={this.onPreviewChange}
        checked={this.props.liveEvent.preview}
        data-pw="preview-on-staging"
      />
    );
  }

  render() {
    return (
      <Panel header="Scheduling" bsStyle="primary">
        {this.renderScheduleTypeSelectionField()}
        {this.renderPromoStartDateField()}
        {this.renderAvailabilityStartDateField()}
        {this.renderAvailabilityEndDateField()}
        {this.showPreviewButton() ? this.renderPreviewDataField() : null}
      </Panel>
    );
  }
}

SchedulingPanel.propTypes = {
  formValidationErrors: PropTypes.object,
  liveEvent: PropTypes.object,
  onChange: PropTypes.func,
};

export default SchedulingPanel;
