import {
  fromHoursMinutesSecondsMsToSeconds,
  fromSecondsToHoursMinutesSecondsMs,
} from 'src/scripts/lib/timeFormatter';

export function hoursMinutesSecondstoSeconds(cuepoints) {
  if (!cuepoints) {
    return [];
  }
  return cuepoints
    .filter(({ time }) => time)
    .map(({ time }) => ({ time: fromHoursMinutesSecondsMsToSeconds(time) }));
}

export function secondsToHoursMinutesSeconds(cuepoints) {
  if (!cuepoints) {
    return [];
  }
  return cuepoints.map(({ time }) => ({ time: fromSecondsToHoursMinutesSecondsMs(time) }));
}
