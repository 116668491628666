import Select from 'react-select';
import React from 'react';
import PropTypes from 'prop-types';

const ALL_OPTION_KEY = '*';
const ALL_OPTION_VALUE = '- Select All -';
export const optionSelectAll = { value: ALL_OPTION_KEY, label: ALL_OPTION_VALUE };

function selectAllOptionClicked(selectedValues) {
  return selectedValues.length && selectedValues[selectedValues.length - 1].value === ALL_OPTION_KEY;
}

class MultiDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.onMultiDropdownChange = this.onMultiDropdownChange.bind(this);
  }

  getBaseOptions() {
    return this.props.filter.options.map(([key, value]) => ({ value: key, label: value }));
  }

  areAllOptionsSelected() {
    if (!this.props.multiDropdownSelectedItems) {
      return false;
    }

    return this.getBaseOptions().length === this.props.multiDropdownSelectedItems.length;
  }

  onMultiDropdownChange(selectedValues) {
    const values = selectAllOptionClicked(selectedValues) ? this.getBaseOptions() : selectedValues;
    const selectedItems = values.map((item) => item.value).join(',');
    this.props.applyFilter(selectedItems.length > 0 ? `in:${selectedItems}` : null);

    return this.props.multiDropdownOnChange(values);
  }

  includeSelectAllOption() {
    return (
      !!this.props.hasSelectAllOption && this.getBaseOptions().length > 0 && !this.areAllOptionsSelected()
    );
  }

  getOptions() {
    return (this.includeSelectAllOption() ? [optionSelectAll] : []).concat(this.getBaseOptions());
  }

  render() {
    return (
      <Select
        multi
        className={this.props.filterClassName}
        value={this.props.multiDropdownSelectedItems}
        onChange={this.onMultiDropdownChange}
        options={this.getOptions()}
      />
    );
  }
}

MultiDropdown.propTypes = {
  applyFilter: PropTypes.func,
  multiDropdownSelectedItems: PropTypes.array,
  multiDropdownOnChange: PropTypes.func,
  filter: PropTypes.any,
  filterClassName: PropTypes.string,
  hasSelectAllOption: PropTypes.bool,
};

export default MultiDropdown;
