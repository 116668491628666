import PropTypes from 'prop-types';
import React from 'react';

import VideoPlayer from 'src/scripts/components/Video/VideoPlayer';

class VideoPreview extends React.Component {
  componentDidMount() {
    this.props.getSignedVideoSourceUrl();
    this.props.getSignedCaptionSourceUrl && this.props.getSignedCaptionSourceUrl();
  }

  componentWillReceiveProps(nextProps) {
    const getCaptionMethodWasUpdated = () => {
      return (
        this.props.getSignedCaptionSourceUrl === null &&
        typeof nextProps.getSignedCaptionSourceUrl === 'function'
      );
    };
    if (getCaptionMethodWasUpdated()) {
      nextProps.getSignedCaptionSourceUrl();
    }
  }

  render() {
    return (
      <div>
        {this.props.signedVideoSourceUrl.url ? (
          <VideoPlayer
            ref="videoPlayer"
            url={this.props.signedVideoSourceUrl.url}
            captionUrl={this.props.signedCaptionSourceUrl && this.props.signedCaptionSourceUrl.url}
            cuePoints={this.props.videoCuePoints}
            currentTime={this.props.currentTime}
          />
        ) : (
          ''
        )}
        {this.props.signedVideoSourceUrl.errorMessage ? (
          <span className="text-danger">Video preview: {this.props.signedVideoSourceUrl.errorMessage}</span>
        ) : (
          ''
        )}
      </div>
    );
  }
}

VideoPreview.propTypes = {
  videoCuePoints: PropTypes.array,
  signedVideoSourceUrl: PropTypes.object.isRequired,
  getSignedVideoSourceUrl: PropTypes.func.isRequired,
  signedCaptionSourceUrl: PropTypes.object,
  getSignedCaptionSourceUrl: PropTypes.func,
  currentTime: PropTypes.number,
};

VideoPreview.defaultProps = {
  signedCaptionSourceUrl: null,
  getSignedCaptionSourceUrl: null,
};

export default VideoPreview;
