import { RESET_FORM } from 'src/scripts/actions/form';

import {
  CAPTION_UPLOAD_SUCCEEDED,
  CAPTION_UPLOAD_STARTED,
  CAPTION_UPLOAD_FAILED,
  CAPTION_UPLOAD_PROGRESS,
  CAPTION_UPLOAD_RESET,
} from 'src/scripts/actions/ui/caption';

const initialCaptionState = {
  isUploading: false,
  uploadPercentage: null,
  errorMessage: '',
};

const setCaptionToUploading = (captionState) => ({
  ...captionState,
  isUploading: true,
});

const setCaptionToNotUploading = (captionState) => ({
  ...captionState,
  isUploading: false,
});

const setCaptionUploadingPercentage = (captionState, updatedUploadPercentage) => ({
  ...captionState,
  uploadPercentage: updatedUploadPercentage,
});

const calculateCurrentUploadPercentage = (partNumber, numberOfParts) =>
  Math.round((partNumber / numberOfParts) * 100);

const setCaptionUploadToFailed = (captionState, errorMessage) => ({
  ...captionState,
  errorMessage,
});

const resetCaptionUploadState = () => ({ ...initialCaptionState });

const captionUploadReducer = (state = initialCaptionState, action) => {
  let updatedState;

  switch (action.type) {
    case CAPTION_UPLOAD_STARTED: {
      updatedState = { ...state };
      updatedState = setCaptionToUploading(updatedState);
      updatedState = setCaptionUploadingPercentage(updatedState, 0);
      return { ...updatedState };
    }
    case CAPTION_UPLOAD_PROGRESS: {
      const updatedUploadPercentage = calculateCurrentUploadPercentage(
        action.partNumber,
        action.numberOfParts
      );
      return setCaptionUploadingPercentage(state, updatedUploadPercentage);
    }
    case CAPTION_UPLOAD_FAILED:
      return setCaptionUploadToFailed(state, action.errorMessage);
    case CAPTION_UPLOAD_SUCCEEDED: {
      updatedState = { ...state };
      updatedState = setCaptionUploadingPercentage(updatedState, 100);
      updatedState = setCaptionToNotUploading(updatedState);
      return { ...updatedState };
    }
    case CAPTION_UPLOAD_RESET:
    case RESET_FORM:
      return resetCaptionUploadState();
    default:
      return state;
  }
};

export default captionUploadReducer;

// State selectors
export const getCaptionUploadPercentage = (state) => state.ui.caption.uploads.uploadPercentage;
export const isCaptionUploading = (state) => state.ui.caption.uploads.isUploading;
export const getCaptionUploadErrorMessage = (state) => state.ui.caption.uploads.errorMessage;
