import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import ModalItem from 'src/scripts/components/ModalItem';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import LiveEventGroupFormLoader from 'src/scripts/components/LiveEvent/LiveEventGroupFormLoader';
import { getLiveEventGroups } from '../../actions/apiWrappers/vmsApi/common/liveEventGroups';
import { deleteLiveEventGroupAndCloseConfirmation } from 'src/scripts/actions/liveEventGroup';
import history from 'src/scripts/lib/history';
import NotesWithTooltip from '../NotesWithTooltip';
import * as pagination from 'src/scripts/lib/pagination';
import ErrorMessageAlert from '../ErrorMessageAlert';
import environment from 'config/environment';

export class LiveEventGroupList extends React.Component {
  abortController = null;

  constructor(props) {
    super(props);
    this.state = {
      liveEventGroups: {
        count: 0,
        liveEventGroups: [],
      },
      errorMessage: null,
    };
    this.loadData = this.loadData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isNaN(prevProps.page) && this.props.page !== prevProps.page) {
      this.loadData();
    }
  }

  getLiveEventGroups = async function (sort = this.props.sort, offset, filters = this.props.filters) {
    const abortMsg = 'Cancelling existing request, to send new request with new parameters.';

    // Check if there's an ongoing request, cancel it if exists
    if (this.abortController) {
      this.abortController.abort(new Error(abortMsg));
    }

    // Create a new AbortController for the current request
    this.abortController = new window.AbortController();
    const signal = this.abortController.signal;

    try {
      // Use middleware function to make the request
      const res = await getLiveEventGroups(
        {
          limit: pagination.pageSize,
          offset:
            offset !== null && offset !== undefined ? offset : pagination.calculateOffset(this.props.page),
          sort,
          ...filters,
        },
        signal
      );
      this.setState({ liveEventGroups: res, errorMessage: null });
      this.abortController = null;
    } catch (error) {
      // This is expected if multiple calls are made at the same time. Most of the time the error will return the abort Msg error
      // however there are instances where the Error produced is an Abort Error where the error.message does not match the abortMsg
      // there for the OR is use to catch both scenarios.
      if (error.message === abortMsg || error.name === 'AbortError') {
        return;
      }
      this.setState({
        liveEventGroups: {
          count: 0,
          liveEventGroups: [],
        },
        errorMessage:
          'Failed to get Live Event Groups. Please try again. Contact Curation Tools team if issue persists.',
      });
      console.error('Unexpected error:', error);
    }
  };

  loadData() {
    this.getLiveEventGroups();
  }

  editGroupModal(liveEventGroup) {
    return (
      <ModalItem
        title="Edit Live Event Group"
        form={<LiveEventGroupFormLoader liveEventGroup={liveEventGroup} onSuccess={() => this.loadData()} />}
      />
    );
  }

  deleteGroupModal(liveEventGroup) {
    return (
      <ModalItem
        title="Delete Live Event Group"
        form={
          <ConfirmationDialog
            invitation={`Clicking Delete will permanently delete "${liveEventGroup.name}" live event group. Proceed?`}
            ok={() => this.props.deleteLiveEventGroupAndCloseConfirmation(liveEventGroup.id)}
            buttonClass="danger"
            buttonLabel="Delete"
          />
        }
      />
    );
  }

  getAllowedActions(liveEventGroup) {
    const actions = [this.editGroupModal(liveEventGroup)];
    if (liveEventGroup.liveEvents.length === 0) {
      actions.push(this.deleteGroupModal(liveEventGroup));
    }
    return actions;
  }

  actionList = (liveEventGroup) => {
    return (
      <NavDropdownMenu
        key={liveEventGroup.id}
        component="div"
        buttonClass="btn btn-default"
        title={<i className="fa fa-ellipsis-v"></i>}
        className="pull-left"
        id={`live-event-group-item-button-${liveEventGroup.id}`}
      >
        {this.getAllowedActions(liveEventGroup)}
      </NavDropdownMenu>
    );
  };

  render = () => {
    return (
      <div>
        <ContextNavigation breadcrumbList={['TV Live Event Groups']} />
        {this.state.errorMessage && <ErrorMessageAlert>{this.state.errorMessage}</ErrorMessageAlert>}
        <ItemList
          id="list-live-event-group"
          list={{
            pageData: this.state.liveEventGroups.liveEventGroups,
            totalCount: this.state.liveEventGroups.count,
            pagination: {
              pageSize: pagination.pageSize,
              activePage: this.props.page,
            },
          }}
          defaultSort="+position"
          filters={this.props.filters}
          getItems={({ sort, offset, filters }) => this.getLiveEventGroups(sort, offset, filters)}
        >
          <ItemListHeader
            dataField="name"
            sort
            dataFormat={(liveEventGroup) => (
              <a
                onClick={() =>
                  history.push({
                    state: null,
                    pathname: `/live/tv/event-groups/${liveEventGroup.id}`,
                  })
                }
              >
                {liveEventGroup.name}
              </a>
            )}
            filter
            label="Name"
          />
          <ItemListHeader label="Subtitle" dataField="subtitle" />
          <ItemListHeader
            label="Notes"
            dataFormat={(liveEventGroup) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip
                    notes={liveEventGroup.producerNotes}
                    emptyText="No Notes for this Event Group"
                  />
                </div>
              );
            }}
          />
          {/* TODO: (https://nine.atlassian.net/browse/SCT-482)
          Remove these featureFlags once we address the performance issues with sorting in vms-api*/}
          <ItemListHeader
            label="CTV Position"
            dataField="ctvPosition"
            sort={environment.featureFlags.showSortingByCtvPosition}
          />
          <ItemListHeader
            label="Position"
            dataField="position"
            sort={environment.featureFlags.showSortingByPosition}
          />
          <ItemListHeader label="Actions" dataFormat={this.actionList} />
        </ItemList>
      </div>
    );
  };
}

LiveEventGroupList.propTypes = {
  liveEventGroups: PropTypes.object,
  deleteLiveEventGroupAndCloseConfirmation: PropTypes.func,
  page: PropTypes.number,
  sort: PropTypes.string,
  filters: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    sort: state.itemList.sort,
    filters: state.itemList.filters,
    liveEventGroups: state.liveEventGroups,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteLiveEventGroupAndCloseConfirmation: (liveEventGroupId) =>
      dispatch(deleteLiveEventGroupAndCloseConfirmation(liveEventGroupId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveEventGroupList);
