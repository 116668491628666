export const SORT_UPDATED = 'SORT_UPDATED';
export const FILTER_UPDATED = 'FILTER_UPDATED';
export const RESET_ITEM_LIST = 'RESET_ITEM_LIST';

export function updateSort({ sortBy, ascending }) {
  return {
    type: SORT_UPDATED,
    sortBy,
    ascending,
  };
}

export function updateFilter({ name, value }) {
  return {
    type: FILTER_UPDATED,
    name,
    value,
  };
}

export function resetItemList() {
  return {
    type: RESET_ITEM_LIST,
  };
}
