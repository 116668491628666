import React from 'react';
import PropTypes from 'prop-types';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import { isDefaultImage } from 'src/scripts/lib/util';
import { getCollectionById } from 'src/scripts/actions/apiWrappers/vmsApi/tv';

const getLatestSeason = (seasons) => {
  return seasons.reduce((latestSeason, currentSeason) => {
    if (latestSeason.seasonNumber > currentSeason.seasonNumber) {
      return latestSeason;
    }
    return currentSeason;
  });
};

const getLatestActiveSeason = (seasons) => {
  const activeSeasons = seasons.filter((season) => season.episodeActiveCount > 0);
  if (activeSeasons.length) {
    return getLatestSeason(activeSeasons);
  }
  return null;
};

const getImageUrl = (tvSeriesItem) => {
  const imageNotFound = IMAGE_NOT_FOUND_URL;
  if (tvSeriesItem.containsSeason.length) {
    const tvSeriesLastSeason = getLatestActiveSeason(tvSeriesItem.containsSeason);
    if (tvSeriesLastSeason && tvSeriesLastSeason.image) {
      return isDefaultImage(tvSeriesLastSeason.image) ? imageNotFound : tvSeriesLastSeason.image.url;
    }
  }
  return imageNotFound;
};

const COLLECTION_PARAMS = {
  includeTvSeriesStates: true,
};

class PreviewCollectionItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionData: null,
      loading: false,
      error: null,
    };
  }

  getCollectionById = async () => {
    try {
      this.setState({
        collectionData: null,
        loading: true,
        error: null,
      });
      const data = await getCollectionById(this.props.collectionId, COLLECTION_PARAMS);
      this.setState({ loading: false, collectionData: data });
    } catch (err) {
      this.setState({ error: err, loading: false });
    }
  };

  componentDidMount() {
    this.getCollectionById();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collectionId !== this.props.collectionId) {
      this.getCollectionById();
    }
  }

  renderTvSeriesPreview = (tvSeries) => {
    return (
      <div className="collection-preview-wrapper">
        {tvSeries &&
          tvSeries.map((series) => {
            if (series.activeEpisodeCount > 0 && series.isFutureActive === true) {
              const imageUrl = getImageUrl(series);
              return (
                <div className="tv-series-preview">
                  <img className={`tv-series-preview-image-${this.props.previewMode}`} src={imageUrl} />
                  <a className="tv-series-preview-link" href={`#/tv/tv-series/${series.id}/seasons`}>
                    {series.name}
                  </a>
                </div>
              );
            }
            return null;
          })}
      </div>
    );
  };

  renderCollectionPreviewItems = () => {
    if (this.state.loading) {
      return <div className="preview-collection-message">{'Loading...'}</div>;
    }
    if (this.state.error) {
      return <div className="preview-collection-message">{'Unable To Load Collections Preview'}</div>;
    }

    const tvSeries = this.state.collectionData ? this.state.collectionData.tvSeries : null;
    if (!this.state.loading && tvSeries && tvSeries.every((series) => series.activeEpisodeCount === 0)) {
      return <div className="preview-collection-message">{'No Active TV Series'}</div>;
    }

    return this.renderTvSeriesPreview(tvSeries);
  };

  render() {
    return <div>{this.renderCollectionPreviewItems()}</div>;
  }
}

PreviewCollectionItems.propTypes = {
  collectionId: PropTypes.number,
  previewMode: PropTypes.string,
};

export default PreviewCollectionItems;
