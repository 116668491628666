import React from 'react';
import PropTypes from 'prop-types';
import ModalItem from 'src/scripts/components/ModalItem';
import GenreFormLoader from 'src/scripts/components/Genre/GenreFormLoader';

export default class Edit extends React.Component {
  getClassName() {
    return this.props.displayType === 'button' ? 'btn btn-default' : '';
  }

  getComponent() {
    return this.props.displayType === 'button' ? 'span' : '';
  }

  render() {
    return (
      <ModalItem
        key={this.props.genre.id}
        title={'Edit Genre'}
        className={this.getClassName()}
        component={this.getComponent()}
        form={<GenreFormLoader genreId={this.props.genre.id} />}
      />
    );
  }
}

Edit.propTypes = {
  genre: PropTypes.object.isRequired,
  displayType: PropTypes.string.isRequired,
};
