import { immutableRemoveById } from 'src/scripts/lib/util';
import {
  GET_TV_SERIES_LIST_SUCCEEDED,
  GET_TV_SERIES_LIST_FAILED,
  DELETE_TV_SERIES_SUCCEEDED,
  SAVE_TV_SERIES_SUCCEEDED,
  GET_COUNTRIES_OF_ORIGIN_SUCCEEDED,
  GET_COUNTRIES_OF_ORIGIN_FAILED,
} from 'src/scripts/actions/tvSeries';
import { GET_COLLECTIONS_SUCCEEDED } from 'src/scripts/actions/collection';
import { GET_GENRES_SUCCEEDED } from 'src/scripts/actions/genre';

export const initialState = {
  customCollections: [],
  tvSeries: [],
  tvSeriesCount: 0,
  offset: 0,
  errorMessage: null,
  savedTvSeries: null,
  genres: [],
  countriesOfOrigin: [],
  useSitecoreCmsSource: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TV_SERIES_LIST_SUCCEEDED:
      return {
        ...state,
        ...action.data,
        tvSeries: action.data.tvSeries,
        tvSeriesCount: action.data.count,
        offset: action.offset,
        savedTvSeries: null,
      };

    case GET_TV_SERIES_LIST_FAILED:
      return { ...state, errorMessage: action.error, savedTvSeries: null };

    case GET_COLLECTIONS_SUCCEEDED:
      return {
        ...state,
        customCollections: action.data.collections,
      };

    case DELETE_TV_SERIES_SUCCEEDED:
      return { ...state, tvSeries: immutableRemoveById(state.tvSeries, action.id) };

    case SAVE_TV_SERIES_SUCCEEDED:
      return { ...state, savedTvSeries: action.data.tvSeries };

    case GET_GENRES_SUCCEEDED:
      return { ...state, genres: action.data.genres };

    case GET_COUNTRIES_OF_ORIGIN_SUCCEEDED:
      return {
        ...state,
        countriesOfOrigin: action.data,
      };

    case GET_COUNTRIES_OF_ORIGIN_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    default:
      return state;
  }
}
