import * as liveEventsVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/common/liveEvents';
import * as tvVmsApi from 'src/scripts/actions/apiWrappers/vmsApi/tv';
import { closeModal } from 'src/scripts/actions/modalItem';
import * as pagination from 'src/scripts/lib/pagination';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import { LIVE_EVENTS } from 'src/scripts/lib/resourceGroups';

import { CONFIRMATION_ACTION_FAILED } from 'src/scripts/actions/confirmation';

export const GET_LIVE_EVENTS_SUCCEEDED = 'GET_LIVE_EVENTS_SUCCEEDED';
export const GET_LIVE_EVENTS_FAILED = 'GET_LIVE_EVENTS_FAILED';
export const GET_LIVE_EVENT_SUCCEEDED = 'GET_LIVE_EVENT_SUCCEEDED';
export const GET_LIVE_EVENT_FAILED = 'GET_LIVE_EVENT_FAILED';
export const LIVE_EVENT_UPDATED = 'LIVE_EVENT_UPDATED';
export const SAVE_LIVE_EVENT_STARTED = 'SAVE_LIVE_EVENT_STARTED';
export const SAVE_LIVE_EVENT_SUCCEEDED = 'SAVE_LIVE_EVENT_SUCCEEDED';
export const SAVE_LIVE_EVENT_FAILED = 'SAVE_LIVE_EVENT_FAILED';
export const DELETE_LIVE_EVENT_SUCCEEDED = 'DELETE_LIVE_EVENT_SUCCEEDED';
export const UPDATE_LIVE_EVENT_STATUS = 'UPDATE_LIVE_EVENT_STATUS';
export const UPDATE_LIVE_EVENT_STATUSES = 'UPDATE_LIVE_EVENT_STATUSES';
export const STOP_LIVE_EVENT_STREAM_SUCCEEDED = 'STOP_LIVE_EVENT_STREAM_SUCCEEDED';
export const START_LIVE_EVENT_STREAM_SUCCEEDED = 'START_LIVE_EVENT_STREAM_SUCCEEDED';
export const GET_LIVE_EVENT_FORM_INITIAL_DATA_SUCCEEDED = 'GET_LIVE_EVENT_FORM_INITIAL_DATA_SUCCEEDED';
export const LAUNCH_INSTANT_LIVE_EVENT_SUCCEEDED = 'LAUNCH_INSTANT_LIVE_EVENT_SUCCEEDED';
export const END_INSTANT_LIVE_EVENT_SUCCEEDED = 'END_INSTANT_LIVE_EVENT_SUCCEEDED';
export const LIVE_EVENT_GET_GENRES_FAILED = 'LIVE_EVENT_GET_GENRES_FAILED';

// exported only to simplify testing
export function getResourceGroup(library) {
  switch (library.toLowerCase()) {
    case TV.toLowerCase():
      return LIVE_EVENTS.TV_LIVE_EVENTS;
    case NETWORK.toLowerCase():
      return LIVE_EVENTS.NETWORK_LIVE_EVENTS;
    default:
      throw new Error(`Library ${library} is not supported`);
  }
}

export function getLiveEvents(library, params = {}) {
  return (dispatch) => {
    const { sort, status, offset = 0, ...filters } = params;
    const limit = pagination.pageSize;
    const onSuccess = (data) => {
      dispatch({
        type: GET_LIVE_EVENTS_SUCCEEDED,
        data,
        offset,
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_LIVE_EVENTS_FAILED,
        error: response.responseJSON.message,
      });
    };
    return liveEventsVmsApi
      .getLiveEvents(getResourceGroup(library), { offset, limit, sort, status, ...filters })
      .then(onSuccess, onError);
  };
}

export function getLiveEventById(library, liveEventId) {
  return (dispatch) => {
    let genres = [];
    const onSuccess = (data) => {
      dispatch({
        type: GET_LIVE_EVENT_SUCCEEDED,
        data: { ...data, genres },
      });
    };

    const onError = (response) => {
      dispatch({
        type: GET_LIVE_EVENT_FAILED,
        error: response.responseJSON.message,
      });
    };
    // we want to show genre for TV live events only
    // network live event do not have genre
    if (library.toLowerCase() === TV.toLowerCase()) {
      return tvVmsApi.getGenres().then(
        (data) => {
          genres = data.genres;
          liveEventsVmsApi.getLiveEventById(getResourceGroup(library), liveEventId).then(onSuccess, onError);
        },
        () => {
          liveEventsVmsApi.getLiveEventById(getResourceGroup(library), liveEventId).then(onSuccess, onError);
        }
      );
      // eslint-disable-next-line no-else-return
    } else {
      return liveEventsVmsApi
        .getLiveEventById(getResourceGroup(library), liveEventId)
        .then(onSuccess, onError);
    }
  };
}

export function getLiveEventAndAssociatedData(library, liveEventId) {
  const loadTVData = library === TV;
  // eslint-disable-next-line consistent-return
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_LIVE_EVENT_FORM_INITIAL_DATA_SUCCEEDED,
        data: { ...data, library },
      });
    };
    const onGenresFetchError = (error) => {
      dispatch({
        type: LIVE_EVENT_GET_GENRES_FAILED,
        error: (error.responseJSON && error.responseJSON.message) || 'Failed to load genres',
      });
    };

    if (!liveEventId) {
      if (loadTVData) {
        tvVmsApi.getGenres().then((data) => {
          return onSuccess({ genres: data.genres });
        }, onGenresFetchError);
      } else {
        return onSuccess({});
      }
    } else if (loadTVData) {
      tvVmsApi.getGenres().then((data) => {
        return liveEventsVmsApi.getLiveEventById(getResourceGroup(library), liveEventId).then((liveEvent) => {
          if (liveEvent.liveEvent.seasonId) {
            const tvSeriesId = liveEvent.liveEvent.partOfSeries.id;
            return tvVmsApi
              .getSeasons({ tvSeriesId })
              .then((seasons) => ({ ...seasons, ...liveEvent, genres: data.genres }))
              .then(onSuccess);
          }
          return onSuccess({ ...liveEvent, genres: data.genres });
        });
      }, onGenresFetchError);
    } else {
      return liveEventsVmsApi.getLiveEventById(getResourceGroup(library), liveEventId).then(onSuccess);
    }
  };
}

export function updateLiveEvent(liveEvent) {
  return {
    type: LIVE_EVENT_UPDATED,
    liveEvent,
  };
}

export function saveLiveEvent(library, payload) {
  return (dispatch) => {
    dispatch({
      type: SAVE_LIVE_EVENT_STARTED,
    });
    const saveMethod = payload.id ? liveEventsVmsApi.updateLiveEvent : liveEventsVmsApi.createLiveEvent;
    const onSuccess = (data) => {
      dispatch({
        type: SAVE_LIVE_EVENT_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: SAVE_LIVE_EVENT_FAILED,
        error: response.responseJSON.message,
      });
    };
    return saveMethod(getResourceGroup(library), payload).then(onSuccess, onError);
  };
}

export function deleteLiveEventAndCloseConfirmation(library, liveEventId) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch({
        type: DELETE_LIVE_EVENT_SUCCEEDED,
        id: liveEventId,
      });
      dispatch(closeModal());
    };

    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };

    return liveEventsVmsApi.deleteLiveEvent(getResourceGroup(library), liveEventId).then(onSuccess, onError);
  };
}

export function stopLiveEventStreamAndCloseConfirmation(library, liveEventId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: STOP_LIVE_EVENT_STREAM_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return liveEventsVmsApi
      .stopLiveEventStream(getResourceGroup(library), liveEventId)
      .then(onSuccess, onError);
  };
}

export function startLiveEventStreamAndCloseConfirmation(library, liveEventId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: START_LIVE_EVENT_STREAM_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return liveEventsVmsApi
      .startLiveEventStream(getResourceGroup(library), liveEventId)
      .then(onSuccess, onError);
  };
}

export function launchInstantLiveEventAndCloseConfirmation(library, liveEventId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: LAUNCH_INSTANT_LIVE_EVENT_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return liveEventsVmsApi
      .launchInstantLiveEvent(getResourceGroup(library), liveEventId)
      .then(onSuccess, onError);
  };
}

export function endInstantLiveEventAndCloseConfirmation(library, liveEventId) {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: END_INSTANT_LIVE_EVENT_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: CONFIRMATION_ACTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return liveEventsVmsApi
      .endInstantLiveEvent(getResourceGroup(library), liveEventId)
      .then(onSuccess, onError);
  };
}

export function updateLiveEventStatus() {
  return {
    type: UPDATE_LIVE_EVENT_STATUS,
  };
}

export function updateLiveEventStatuses() {
  return {
    type: UPDATE_LIVE_EVENT_STATUSES,
  };
}
