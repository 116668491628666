import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';

class VideoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dragHighlightActive: false };
  }

  setDragHighlightActive = (e) => {
    e.preventDefault();
    this.setState({ dragHighlightActive: true });
  };

  setDragHighlightInactive = (e) => {
    e.preventDefault();
    this.setState({ dragHighlightActive: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    this.setState({ dragHighlightActive: false });
    const file = e.dataTransfer.files[0];
    if (!file.type.includes('video/')) return;
    this.props.onChange(file);
  };

  handleOnChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    this.props.onChange(file);
  };

  render() {
    return (
      <div style={{ marginBottom: '15px' }}>
        {!this.props.alerts ? null : (
          <div>
            {this.props.alerts.map((alert, index) => {
              return (
                <Alert key={index} bsStyle="info">
                  {alert}
                </Alert>
              );
            })}
          </div>
        )}
        <label style={{ display: 'block' }}>Video</label>
        <div
          ref="fakeFileDropArea"
          style={{ display: 'flex', alignItems: 'center' }}
          onDragLeave={this.setDragHighlightInactive}
          onDragEnd={this.setDragHighlightInactive}
          onDragOver={this.setDragHighlightActive}
          onDrop={this.handleDrop}
        >
          <Button
            ref="fakeFileInputBtn"
            bsSize="small"
            className={this.state.dragHighlightActive ? 'drag-active' : ''}
            disabled={this.props.isDisabled()}
            onClick={() => this.refs.file.click()}
          >
            Choose file
          </Button>
          <div ref="selectedFileName" style={{ marginLeft: '15px' }}>
            {this.props.selectedFileName || ''}
          </div>
        </div>
        <input
          style={{ display: 'none' }}
          type="file"
          label="Video"
          ref="file"
          id="video-file"
          accept=".mp4,.mov,.avi"
          className="videoFile"
          disabled={this.props.isDisabled()}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

VideoUpload.propTypes = {
  isDisabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.string),
  selectedFileName: PropTypes.string,
};

export default VideoUpload;
