import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button } from 'react-bootstrap';
import { updateGenre, saveGenre } from 'src/scripts/actions/genre';
import ImageUpload from 'src/scripts/components/ImageUpload';
import { IMAGES } from 'src/scripts/lib/resourceGroups';
import { IMAGE_RESTRICTIONS } from 'src/scripts/lib/genreImageRestrictions';
import {
  GENRE_IMAGE_REF,
  GENRE_FEATURED_IMAGE_REF,
  GENRE_FEATURED_POSTER_IMAGE_REF,
} from 'src/scripts/lib/imageRefs';
import { GENRE_IMAGE, GENRE_FEATURED_IMAGE, GENRE_FEATURED_POSTER_IMAGE } from 'src/scripts/lib/imageTypes';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/genreForm';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';

export class GenreForm extends React.Component {
  constructor(props) {
    super(props);

    this.saveGenre = this.saveGenre.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSeoTitleChange = this.onSeoTitleChange.bind(this);
    this.onSeoDescriptionChange = this.onSeoDescriptionChange.bind(this);
  }

  onNameChange(event) {
    this.props.updateGenre({
      name: event.target.value,
    });
  }

  onDescriptionChange(event) {
    this.props.updateGenre({
      description: event.target.value,
    });
  }

  onSeoTitleChange(event) {
    this.props.updateGenre({
      seoTitle: event.target.value,
    });
  }

  onSeoDescriptionChange(event) {
    this.props.updateGenre({
      seoDescription: event.target.value,
    });
  }

  isSubmitDisabled() {
    return (
      (this.props.genreImageUpload && this.props.genreImageUpload.uploading) ||
      (this.props.genreFeaturedImageUpload && this.props.genreFeaturedImageUpload.uploading) ||
      (this.props.genreFeaturedPosterImageUpload && this.props.genreFeaturedPosterImageUpload.uploading)
    );
  }

  findImageByType(images, type) {
    return images ? images.find((image) => image.type === type) : undefined;
  }

  saveGenre(event) {
    event.preventDefault();
    const genre = {
      id: this.props.genre.id,
      name: this.props.genre.name,
      description: this.props.genre.description,
      seoTitle: this.props.genre.seoTitle,
      seoDescription: this.props.genre.seoDescription,
      uploadedImages: [],
    };

    if (this.props.genreImageUpload && this.props.genreImageUpload.imageId) {
      genre.imageId = this.props.genreImageUpload.imageId;
    }

    if (this.props.genreFeaturedImageUpload && this.props.genreFeaturedImageUpload.imageId) {
      genre.uploadedImages.push({
        id: this.props.genreFeaturedImageUpload.imageId,
        type: this.props.genreFeaturedImageUpload.imageType,
      });
    }

    if (this.props.genreFeaturedPosterImageUpload && this.props.genreFeaturedPosterImageUpload.imageId) {
      genre.uploadedImages.push({
        id: this.props.genreFeaturedPosterImageUpload.imageId,
        type: this.props.genreFeaturedPosterImageUpload.imageType,
      });
    }

    if (!isFormValid(genre, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveGenre(genre);
  }

  render() {
    return (
      <div>
        <form className="form" onSubmit={this.saveGenre} ref="genreForm" data-pw="genre-form">
          <Input
            labelClassName="required"
            type="text"
            label="Genre Name"
            placeholder="Genre name"
            ref="genreName"
            maxLength="40"
            onChange={this.onNameChange}
            value={this.props.genre.name}
            bsStyle={this.props.validationErrors.name ? 'error' : null}
            data-pw="genre-name"
          />
          <Input
            type="textarea"
            label="Genre Description"
            placeholder="Genre Description"
            ref="genreDescription"
            maxLength="2048"
            onChange={this.onDescriptionChange}
            value={this.props.genre.description}
            bsStyle={this.props.validationErrors.description ? 'error' : null}
            data-pw="genre-description"
          />
          <Input
            labelClassName="required"
            type="text"
            label="Seo Title"
            placeholder="Genre Seo Title"
            ref="genreSeoTitle"
            maxLength="255"
            onChange={this.onSeoTitleChange}
            value={this.props.genre.seoTitle}
            bsStyle={this.props.validationErrors.seoTitle ? 'error' : null}
            data-pw="genre-seo-title"
          />
          <Input
            labelClassName="required"
            type="textarea"
            label="Seo Description"
            placeholder="Genre Seo Description"
            ref="genreSeoDescription"
            maxLength="2048"
            onChange={this.onSeoDescriptionChange}
            value={this.props.genre.seoDescription}
            bsStyle={this.props.validationErrors.seoDescription ? 'error' : null}
            data-pw="genre-seo-description"
          />
          <ImageUpload
            label="Genre Image"
            restrictions={IMAGE_RESTRICTIONS.IMAGE}
            existingImage={this.props.genre.image}
            resourceGroup={IMAGES.TV}
            imageUploadRef={GENRE_IMAGE_REF}
            imageType={GENRE_IMAGE}
          />
          <ImageUpload
            label="Featured Genre Image"
            restrictions={IMAGE_RESTRICTIONS.FEATURED}
            existingImage={this.findImageByType(this.props.genre.images, GENRE_FEATURED_IMAGE)}
            resourceGroup={IMAGES.TV}
            imageUploadRef={GENRE_FEATURED_IMAGE_REF}
            imageType={GENRE_FEATURED_IMAGE}
          />
          <ImageUpload
            label="Featured Genre Poster Image"
            restrictions={IMAGE_RESTRICTIONS.FEATURED_POSTER}
            existingImage={this.findImageByType(this.props.genre.images, GENRE_FEATURED_POSTER_IMAGE)}
            resourceGroup={IMAGES.TV}
            imageUploadRef={GENRE_FEATURED_POSTER_IMAGE_REF}
            imageType={GENRE_FEATURED_POSTER_IMAGE}
          />
          {this.props.errorMessage && (
            <FormErrorMessage className="genre-server-error-message" message={this.props.errorMessage} />
          )}
          <div className="modal-footer">
            <Button
              id="video-modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

GenreForm.propTypes = {
  close: PropTypes.func,
  genre: PropTypes.object,
  errorMessage: PropTypes.string,
  updateGenre: PropTypes.func,
  saveGenre: PropTypes.func,
  genreImageUpload: PropTypes.object,
  genreFeaturedImageUpload: PropTypes.object,
  genreFeaturedPosterImageUpload: PropTypes.object,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
};

function mapStateToProps(state) {
  const genreForm = state.genreForm;
  return {
    genre: genreForm.genre,
    errorMessage: genreForm.errorMessage,
    genreImageUpload: state.imageUploads[GENRE_IMAGE_REF],
    genreFeaturedImageUpload: state.imageUploads[GENRE_FEATURED_IMAGE_REF],
    genreFeaturedPosterImageUpload: state.imageUploads[GENRE_FEATURED_POSTER_IMAGE_REF],
    validationErrors: state.genreForm.validationErrors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateGenre: (genre) => dispatch(updateGenre(genre)),
    saveGenre: (genre) => dispatch(saveGenre(genre)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenreForm);
