import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';

class ExpireModal extends React.Component {
  render() {
    if (this.props.model.expiry && moment(this.props.model.expiry).isBefore(moment())) {
      return null;
    }
    return (
      <ModalItem
        title="Expire"
        ref="expire"
        component={this.props.isButton ? 'span' : ''}
        className={this.props.isButton ? 'btn btn-default' : ''}
        form={
          <ConfirmationDialog
            invitation="Clicking Expire will set the expiry date of this video to now and deactivate it. Are you sure you wish to proceed?"
            ok={this.props.action}
            buttonClass="danger"
            buttonLabel="Expire"
          />
        }
      />
    );
  }
}

ExpireModal.propTypes = {
  model: PropTypes.object.isRequired,
  action: PropTypes.func,
  isButton: PropTypes.bool,
};

export default ExpireModal;
