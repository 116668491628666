import PropTypes from 'prop-types';
import React from 'react';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import ModalItem from 'src/scripts/components/ModalItem';
import ClipFormLoader from 'src/scripts/components/Clip/ClipFormLoader';
import NetworkClipFormLoader from 'src/scripts/components/NetworkClip/NetworkClipFormLoader';
import { getAvailableBulkWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import { isItemInBulkActionQueue } from 'src/scripts/lib/bulkWorkflowActionsProvider';
import { CLIP, EPISODE, NETWORK_CLIP } from 'src/scripts/lib/modelNames';

export class EditButton extends React.Component {
  getEditForm() {
    const editForms = {
      [EPISODE]: <EpisodeFormLoader episodeId={Number(this.props.selectedItems[0].id)} />,
      [CLIP]: <ClipFormLoader clipId={Number(this.props.selectedItems[0].id)} />,
      [NETWORK_CLIP]: <NetworkClipFormLoader clipId={Number(this.props.selectedItems[0].id)} />,
    };
    return editForms[this.props.modelName];
  }

  isSingleSelectedInBulkActionsQueue() {
    return isItemInBulkActionQueue(
      this.props.bulkActionStatus,
      this.props.processingActionType,
      this.props.processingModelName,
      this.props.unprocessed,
      this.props.modelName.slice(0, -1),
      this.props.selectedItems[0]
    );
  }

  isClipEpisodeBulkActionDisabled(buttonName) {
    return (
      getAvailableBulkWorkflowActions(this.props.selectedItems, this.props.modelName).indexOf(buttonName) ===
      -1
    );
  }

  isDisabled() {
    if ([CLIP, EPISODE, NETWORK_CLIP].includes(this.props.modelName)) {
      return (
        this.props.selectedItems.length !== 1 ||
        this.isClipEpisodeBulkActionDisabled('edit') ||
        this.isSingleSelectedInBulkActionsQueue()
      );
    }
    return this.props.selectedItems.length !== 1 || this.isSingleSelectedInBulkActionsQueue();
  }

  render() {
    return (
      <ModalItem
        ref="bulkActionBarEditButton"
        className={`editModal btn btn-sm btn-default ${this.isDisabled() ? 'disabled' : ''}`}
        component={'span'}
        title="Edit"
        form={this.props.selectedItems.length ? this.getEditForm() : null}
      />
    );
  }
}

EditButton.propTypes = {
  modelName: PropTypes.string,
  selectedItems: PropTypes.array,
  bulkActionStatus: PropTypes.string,
  unprocessed: PropTypes.array,
  processingActionType: PropTypes.string,
  processingModelName: PropTypes.string,
};

export default EditButton;
