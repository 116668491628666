import _ from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import { GET_ALL_EPISODES_SUCCEEDED, GET_ALL_EPISODES_FAILED } from 'src/scripts/actions/episode';
import { GET_ALL_CLIPS_SUCCEEDED, GET_ALL_CLIPS_FAILED } from 'src/scripts/actions/clip';
import { RESET_ITEM_LIST } from 'src/scripts/actions/itemList';
import {
  GET_ALL_NETWORK_CLIPS_SUCCEEDED,
  GET_ALL_NETWORK_CLIPS_FAILED,
} from 'src/scripts/actions/networkClip';
import { UPLOAD_STARTED, UPLOAD_COMPLETED, UPLOAD_FAILED, UPLOAD_PROGRESS } from 'src/scripts/actions/video';
import {
  REAL_TIME_EPISODE_UPDATED,
  REAL_TIME_CLIP_UPDATED,
  REAL_TIME_NETWORK_CLIP_UPDATED,
} from 'src/scripts/actions/realTimeNotification';
import {
  BULK_ACTION_NETWORK_CLIP_UPDATED,
  BULK_ACTION_CLIP_UPDATED,
  BULK_ACTION_EPISODE_UPDATED,
  BULK_ACTION_CLIP_DELETED,
  BULK_ACTION_NETWORK_CLIP_DELETED,
  BULK_ACTION_EPISODE_DELETED,
} from 'src/scripts/actions/bulkAction';

import { immutableRemoveById, immutableUpdateArrayItem } from 'src/scripts/lib/util';

export const initialState = {
  list: [],
  type: null,
  totalCount: 0,
  errorMessage: null,
  uploadMetaData: {},
  currentlyUploadingVideos: 0,
};

export const EPISODE_TYPE = 'EPISODE_TYPE';
export const CLIP_TYPE = 'CLIP_TYPE';
export const NETWORK_CLIP_TYPE = 'NETWORK_CLIP_TYPE';

function getOverriddenStatus(videoId, uploadMetaData) {
  const metadata = uploadMetaData[videoId];
  return metadata ? metadata.overriddenStatus : null;
}

function overrideStatus(videoId, status, uploadMetaData) {
  if (!uploadMetaData[videoId]) {
    uploadMetaData[videoId] = {};
  }
  uploadMetaData[videoId].overriddenStatus = status;
}

function getUploadedPartCount(videoId, uploadMetaData) {
  const metadata = uploadMetaData[videoId];
  let result = 0;
  if (metadata) {
    result = metadata.partCount ? metadata.partCount : 0;
  }
  return result;
}

function setUploadedPartCount(videoId, partCount, uploadMetaData) {
  if (uploadMetaData[videoId] === null) {
    uploadMetaData[videoId] = {};
  }
  uploadMetaData[videoId].partCount = partCount;
}

function selectStatusForVideo(video, uploadMetaData) {
  return getOverriddenStatus(video.id, uploadMetaData) || video.status;
}

function transformVideosForDisplay(itemList, uploadMetaData) {
  return itemList.map((item) => {
    return _.merge({}, item, {
      video: {
        ...item.video,
        status: selectStatusForVideo(item.video, uploadMetaData),
      },
    });
  });
}

function updateState(currentState) {
  return {
    ...currentState,
    list: transformVideosForDisplay(currentState.list, currentState.uploadMetaData),
  };
}

function removeUploadVideoFromMetadata(videoId, uploadMetaData) {
  if (uploadMetaData[videoId]) {
    delete uploadMetaData[videoId];
  }
}

function decrementCurrentlyUploadingVideos(currentlyUploadingVideos) {
  const uploadingVideos = currentlyUploadingVideos - 1;
  return uploadingVideos < 0 ? 0 : uploadingVideos;
}

function getRealTimeUpdatedState(state, updateData, type) {
  if (state.type === type) {
    return { ...state, list: immutableUpdateArrayItem(state.list, updateData) };
  }
  return state;
}

function removeFromListIfMatchingType(state, id, type) {
  if (state.type === type) {
    return { ...state, list: immutableRemoveById(state.list, id), totalCount: --state.totalCount };
  }
  return state;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EPISODES_SUCCEEDED:
      return {
        ...state,
        list: action.data.episodes,
        totalCount: action.data.count,
        offset: action.offset,
        type: EPISODE_TYPE,
      };

    case GET_ALL_EPISODES_FAILED:
      return { ...state, list: [], totalCount: 0, errorMessage: action.error, type: EPISODE_TYPE };

    case GET_ALL_CLIPS_SUCCEEDED:
      return {
        ...state,
        list: action.data.clips,
        totalCount: action.data.count,
        offset: action.offset,
        type: CLIP_TYPE,
      };

    case GET_ALL_CLIPS_FAILED:
      return { ...state, list: [], totalCount: 0, errorMessage: action.error, type: CLIP_TYPE };

    case GET_ALL_NETWORK_CLIPS_SUCCEEDED:
      return {
        ...state,
        list: action.data.clips,
        totalCount: action.data.count,
        offset: action.offset,
        type: NETWORK_CLIP_TYPE,
      };

    case GET_ALL_NETWORK_CLIPS_FAILED:
      return { ...state, list: [], totalCount: 0, errorMessage: action.error, type: NETWORK_CLIP_TYPE };

    case RESET_ITEM_LIST:
      return {
        ...initialState,
        uploadMetaData: state.uploadMetaData,
      };

    case UPLOAD_STARTED:
      let updatedState = _cloneDeep(state);
      overrideStatus(action.videoId, 'Uploading: 0%', updatedState.uploadMetaData);
      return {
        ...updateState(updatedState),
        currentlyUploadingVideos: updatedState.currentlyUploadingVideos + 1,
      };

    case UPLOAD_COMPLETED:
      updatedState = _cloneDeep(state);
      overrideStatus(action.videoId, 'Uploaded', updatedState.uploadMetaData);
      updatedState = {
        ...updateState(updatedState),
        currentlyUploadingVideos: decrementCurrentlyUploadingVideos(updatedState.currentlyUploadingVideos),
      };
      removeUploadVideoFromMetadata(action.videoId, updatedState.uploadMetaData);
      return updatedState;

    case UPLOAD_FAILED:
      updatedState = _cloneDeep(state);
      overrideStatus(action.videoId, 'Upload Failed', updatedState.uploadMetaData);
      updatedState = {
        ...updateState(updatedState),
        currentlyUploadingVideos: decrementCurrentlyUploadingVideos(updatedState.currentlyUploadingVideos),
      };
      removeUploadVideoFromMetadata(action.videoId, updatedState.uploadMetaData);
      return updatedState;

    case UPLOAD_PROGRESS:
      const uploadedParts = getUploadedPartCount(action.videoId, state.uploadMetaData) + 1;
      const clonedState = _cloneDeep(state);
      setUploadedPartCount(action.videoId, uploadedParts, clonedState.uploadMetaData);
      const uploadPercentage = Math.round((uploadedParts / action.numberOfParts) * 100);
      overrideStatus(action.videoId, `Uploading: ${uploadPercentage}%`, clonedState.uploadMetaData);
      return updateState(clonedState);

    case BULK_ACTION_EPISODE_UPDATED:
      return getRealTimeUpdatedState(state, action.data.episode, EPISODE_TYPE);
    case BULK_ACTION_CLIP_UPDATED:
      return getRealTimeUpdatedState(state, action.data.clip, CLIP_TYPE);
    case BULK_ACTION_NETWORK_CLIP_UPDATED:
      return getRealTimeUpdatedState(state, action.data.clip, NETWORK_CLIP_TYPE);

    case BULK_ACTION_CLIP_DELETED:
      return removeFromListIfMatchingType(state, action.data.id, CLIP_TYPE);
    case BULK_ACTION_NETWORK_CLIP_DELETED:
      return removeFromListIfMatchingType(state, action.data.id, NETWORK_CLIP_TYPE);
    case BULK_ACTION_EPISODE_DELETED:
      return removeFromListIfMatchingType(state, action.data.id, EPISODE_TYPE);

    case REAL_TIME_EPISODE_UPDATED:
      return getRealTimeUpdatedState(state, action.data, EPISODE_TYPE);
    case REAL_TIME_CLIP_UPDATED:
      return getRealTimeUpdatedState(state, action.data, CLIP_TYPE);
    case REAL_TIME_NETWORK_CLIP_UPDATED:
      return getRealTimeUpdatedState(state, action.data, NETWORK_CLIP_TYPE);

    default:
      return state;
  }
}

export const getVideoUploadMetaData = (state) =>
  (state.video && !_isEmpty(state.video.uploadMetaData) && state.video.uploadMetaData) || null;
