import rootReducer from 'src/scripts/reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { screenStoreEnhancer } from 'src/scripts/stores/enhancers';

export default function configureStore() {
  const middleware = [thunk];
  const createStoreEnhancers = () => {
    // eslint-disable-next-line
    if (envVars.APP_ENV === 'dev' || envVars.APP_ENV === 'int' || envVars.APP_ENV === 'uat') {
      return composeWithDevTools(screenStoreEnhancer, applyMiddleware(...middleware));
    }
    return compose(screenStoreEnhancer, applyMiddleware(...middleware));
  };
  const store = createStore(rootReducer, createStoreEnhancers());
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('src/scripts/reducers', () => {
      const nextRootReducer = require('src/scripts/reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
