import Promise from 'bluebird';

import * as tvVmsApi from './apiWrappers/vmsApi/tv';
import { closeModal } from 'src/scripts/actions/modalItem';
export const UPDATE_ADD_COLLECTION_FORM = 'UPDATE_ADD_COLLECTION_FORM';
export const GET_AVAILABLE_GENRES_SUCCEEDED = 'GET_AVAILABLE_GENRES_SUCCEEDED';
export const GET_AVAILABLE_GENRES_FAILED = 'GET_AVAILABLE_GENRES_FAILED';
export const CREATE_COLLECTION_SUCCEEDED = 'CREATE_COLLECTION_SUCCEEDED';
export const CREATE_COLLECTION_FAILED = 'CREATE_COLLECTION_FAILED';

const createCollectionAsPromise = (collection) =>
  new Promise((resolve, reject) => {
    tvVmsApi.createCollection(collection).then(resolve, reject);
  });

const getGenres = () =>
  new Promise((resolve, reject) => {
    tvVmsApi.getGenres().then(resolve, reject);
  });

const getGenreCollections = () =>
  new Promise((resolve, reject) => {
    tvVmsApi.getCollections({ type: 'GENRE' }).then(resolve, reject);
  });

export const updateAddCollectionForm = (collection) => ({
  type: UPDATE_ADD_COLLECTION_FORM,
  collection,
});

export const createCollection = (collection) => {
  return (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: CREATE_COLLECTION_SUCCEEDED,
        data,
      });
      dispatch(closeModal());
    };
    const onError = (response) => {
      dispatch({
        type: CREATE_COLLECTION_FAILED,
        error: response.responseJSON.message,
      });
    };
    return createCollectionAsPromise(collection).then(onSuccess).catch(onError);
  };
};

export const getAvailableGenres = () => {
  return async (dispatch) => {
    const onSuccess = (data) => {
      dispatch({
        type: GET_AVAILABLE_GENRES_SUCCEEDED,
        data,
      });
    };
    const onError = (response) => {
      dispatch({
        type: GET_AVAILABLE_GENRES_FAILED,
        error: response.statusText,
      });
    };

    try {
      const genresResult = await getGenres();
      const genres = genresResult.genres;
      const genreCollectionsResult = await getGenreCollections();
      const usedGenreIds = genreCollectionsResult.collections.map((collection) => collection.genreId);
      const availableGenres = genres.filter((genre) => {
        return !usedGenreIds.includes(genre.id);
      });
      onSuccess(availableGenres);
    } catch (err) {
      onError(err);
    }
  };
};
