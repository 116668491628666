import {
  GET_CLIPS_SUCCEEDED,
  GET_CLIP_SUCCEEDED,
  GET_CLIP_FAILED,
  DELETE_CLIP_SUCCEEDED,
  SAVE_CLIP_SUCCEEDED,
} from 'src/scripts/actions/clip';

import {
  WORKFLOW_STATE_CHANGE_SUCCEEDED,
  WORKFLOW_STATE_CHANGE_STARTED,
  WORKFLOW_STATE_CHANGE_FAILED,
} from 'src/scripts/actions/workflow/tvWorkflow';
import { REAL_TIME_CLIP_UPDATED } from 'src/scripts/actions/realTimeNotification';
import { BULK_ACTION_CLIP_UPDATED, BULK_ACTION_CLIP_DELETED } from 'src/scripts/actions/bulkAction';
import { immutableRemoveById, immutableUpdateArrayItem } from 'src/scripts/lib/util';

import { UPLOAD_FAILED, UPLOAD_COMPLETED } from 'src/scripts/actions/video';

export const defaultState = {
  list: [],
  offset: 0,
  count: 0,
  savedClip: null,
  detailsView: {
    clip: null,
    errorMessage: null,
  },
};

function isCurrentClipSaved(currentClip, savedClip) {
  return currentClip && savedClip.id === currentClip.id;
}

function isEqualToCurrentClipId(id, currentClip) {
  return currentClip && id === currentClip.id;
}

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_CLIPS_SUCCEEDED:
      return {
        ...state,
        list: action.data.clips,
        offset: action.offset,
        count: action.data.count,
        savedClip: null,
      };

    case WORKFLOW_STATE_CHANGE_SUCCEEDED:
      return {
        ...state,
        disableWorkflow: false,
        detailsView: {
          clip: action.data.clip,
          errorMessage: null,
        },
      };

    case WORKFLOW_STATE_CHANGE_STARTED:
      return { ...state, disableWorkflow: true };

    case WORKFLOW_STATE_CHANGE_FAILED:
      return { ...state, disableWorkflow: false };

    case GET_CLIP_SUCCEEDED:
      return {
        ...state,
        detailsView: {
          clip: action.data.clip,
          errorMessage: null,
        },
        savedClip: null,
      };

    case GET_CLIP_FAILED:
      return {
        ...state,
        detailsView: {
          clip: null,
          errorMessage: action.error,
        },
        savedClip: null,
      };

    case UPLOAD_FAILED:
      return {
        ...state,
        list: state.list.map((clip) => {
          if (clip.video.id === action.videoId) {
            return {
              ...clip,
              video: { ...clip.video, status: 'Upload Failed' },
            };
          }
          return clip;
        }),
      };

    case UPLOAD_COMPLETED:
      return {
        ...state,
        list: state.list.map((clip) => {
          if (clip.video.id === action.videoId) {
            return {
              ...clip,
              video: { ...clip.video, status: 'Uploaded' },
            };
          }
          return clip;
        }),
      };

    case DELETE_CLIP_SUCCEEDED:
      return {
        ...state,
        list: immutableRemoveById(state.list, action.id),
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.id, state.detailsView.clip) ? null : state.detailsView.clip,
        },
      };

    case BULK_ACTION_CLIP_DELETED:
      const updatedList = immutableRemoveById(state.list, action.data.id);
      return {
        ...state,
        list: updatedList,
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.data.id, state.detailsView.clip)
            ? null
            : state.detailsView.clip,
        },
        count: state.list.length === updatedList.length ? state.count : --state.count,
      };

    case SAVE_CLIP_SUCCEEDED:
      if (isCurrentClipSaved(state.detailsView.clip, action.data.clip)) {
        return {
          ...state,
          savedClip: action.data.clip,
          detailsView: {
            clip: action.data.clip,
            errorMessage: null,
          },
        };
      }
      return { ...state, savedClip: action.data.clip };

    case BULK_ACTION_CLIP_UPDATED:
      return {
        ...state,
        detailsView: {
          ...state.detailsView,
          clip: isEqualToCurrentClipId(action.data.clip.id, state.detailsView.clip)
            ? action.data.clip
            : state.detailsView.clip,
        },
        list: immutableUpdateArrayItem(state.list, action.data.clip),
      };

    case REAL_TIME_CLIP_UPDATED:
      return { ...state, list: immutableUpdateArrayItem(state.list, action.data) };

    default:
      return state;
  }
}
