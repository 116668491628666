import PropTypes from 'prop-types';
import React from 'react';
import {
  deleteLiveEventGroupAndCloseConfirmation,
  getLiveEventGroupById,
} from '../../actions/liveEventGroup';
import ViewLoader from 'src/scripts/components/ViewLoader';
import history from 'src/scripts/lib/history';
import LiveEventGroupDetails from 'src/scripts/components/LiveEvent/internal/LiveEventGroupDetails';
import { connect } from 'react-redux';

export class LiveEventGroupDetailsViewLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      errorMessage: null,
      liveEventGroup: {},
    };

    this.onDeletingSuccess = this.onDeletingSuccess.bind(this);
    this.onEditingSuccess = this.onEditingSuccess.bind(this);
  }

  componentDidMount() {
    getLiveEventGroupById(this.props.params.liveEventGroupId).then(
      (data) => {
        this.setState({
          loading: false,
          errorMessage: null,
          liveEventGroup: data.liveEventGroup,
        });
      },
      (response) => this.setState({ errorMessage: response.responseJSON.errorMessage })
    );
  }

  onEditingSuccess(data) {
    this.setState({ liveEventGroup: data });
  }

  onDeletingSuccess() {
    this.props.deleteLiveEventGroupAndCloseConfirmation(this.state.liveEventGroup.id);
    history.push({
      state: null,
      pathname: '/live/tv/event-groups',
    });
  }

  render = () => (
    <ViewLoader loading={this.state.loading} errorMessage={this.state.errorMessage}>
      <LiveEventGroupDetails
        liveEventGroup={this.state.liveEventGroup}
        onDeletingSuccess={this.onDeletingSuccess}
        onEditingSuccess={this.onEditingSuccess}
      />
    </ViewLoader>
  );
}

LiveEventGroupDetailsViewLoader.propTypes = {
  params: PropTypes.object.isRequired,
  deleteLiveEventGroupAndCloseConfirmation: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    deleteLiveEventGroupAndCloseConfirmation: (liveEventGroupId) =>
      dispatch(deleteLiveEventGroupAndCloseConfirmation(liveEventGroupId)),
  };
}

export default connect(null, mapDispatchToProps)(LiveEventGroupDetailsViewLoader);
